import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import back from '../../Images/back.png';
import search from '../../Images/Leading-icon.png';
import Filter from '../../Images/filtericon.svg';
import EmptySearch from '../../Images/emptySearch.svg';
import usePagination from '../../hooks/usePagination';
import Table from '../../constants/Table';
import Modal from '../../reusable/Modal';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import dayjs, { Dayjs } from 'dayjs';
import DatePickerElement from '../../reusable/Forms/DatePickerElement';
import Lottie from 'lottie-react';
import loadingState from '../../Images/loading.json';
import hospitalService from '../../services/hospitalService';
import { useQuery } from 'react-query';
import TimeComponent, {
    formatDateDmy,
    standardAmountFormat,
} from '../../utils/helpers';
interface rowType {
    _id: string;
    billId: any;
}
const BillHistories = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = React.useState(false);
    const [buttonEnabled, setButton] = useState(false);
    const [billCount, setBillCount] = useState<number>(0);
    const [billHistories, setBillHistories] = useState<any[]>([]);
    const [rangeValues, setRangeValues] = useState([0, 100]);
    const [selectedModeButton, setSelectedButton] = useState<string | null>(null);
    const [queryParam, setQueryParam] = useState<string>('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedFromDate, setSelectedFromDate] = useState<Dayjs | null>(
        dayjs('')
    );
    const [selectedToDate, setSelectedToDate] = useState<Dayjs | null>(
        dayjs('')
    );
    const [isBillHistory, setIsBillHistory] = useState<boolean>(false);

    const goBack = () => {
        navigate(-1);
    };
    const { data, isError, isLoading } = useQuery(
        'bills',
        hospitalService.getBills
    );
    useEffect(() => {
        if (data?.billing) {
            setBillCount(data.billing.length);
            setBillHistories(data.billing);
            setIsBillHistory(true);
        } else {
            setIsBillHistory(false);
        }
    }, [data]);

    // Update the rangeValues state when the slider values change
    const handleRangeChange = (values) => {
        setRangeValues(values);
        setButton(true);

    };
    const trackStyle = { backgroundColor: 'red' };
    const handleStyle = {
        borderColor: 'red',
        backgroundColor: 'red',
    };
    const railStyle = { backgroundColor: '#EDE0DE' };

    const closeModal = () => {
        setShowModal(false);
        setButton(false)
    };
    const handleOptionChange = (option: any) => {
        setSelectedOption(option);
        setButton(true);
    };
    const handleModeButtonClick = (value: string) => {
        setSelectedButton(value);
        setButton(true);

    };
    const setModal = () => {
        setShowModal(true);
    };
    const handleSubmit = (e: React.FormEvent) => {
        setShowModal(false);
        setButton(false);
    };
    //Pagination
    const { page, limit, Pagination } = usePagination({
        page: 1,
        limit: 10,
        total: billHistories?.length,
    });

    const paginatedRows = billHistories?.slice(
        (page - 1) * limit,
        page * limit
    );

    if (isLoading) {
        return (
            <div className="flex justify-center items-center py-32">
                <Lottie
                    animationData={loadingState}
                    loop={true}
                    className="lottie"
                />
            </div>
        );
    }

    if (isError) {
        return <div>Error loading bill, please refresh your page</div>;
    }
    const handleClearAll = () => {
       setButton(false)
        setSelectedFromDate(null)
        setSelectedToDate(null)
        setSelectedButton(null)
        setRangeValues([0, 100])
        setSelectedOption(null)
        setQueryParam('')
    }

    const billHistoryHeader = [
        {
            title: 'S/N',
            render: (row: any) => (
                <div className="py-[6px] text-[12px] flex items-center gap-2 font-medium text-light_gray text-center">
                    <p className="text-xs font-medium">
                        {billHistories?.findIndex(
                            (user: any) => user._id === row._id
                        ) + 1}
                    </p>
                </div>
            ),
        },
        {
            title: 'Bill Id',
            render: (row: any) => <p>{row.billId}</p>,
        },
        {
            title: 'Patient Name',
            render: (row: any) => (
                <>
                    <div>
                        <p className="truncate w-32 font-medium text-lightBrown text-sm ">
                            {`${row.patient?.firstName} ${row.patient?.lastName}`}
                        </p>
                        <p className="text-lightBrown100 text-xs font-medium">
                            {row.patientTmrNumber}
                        </p>
                    </div>
                </>
            ),
        },
        {
            title: 'Phone Number',
            render: (row: any) => <p>{row.patient?.phoneNumber}</p>,
        },

        {
            title: 'Total amount',
            render: (row: any) => (
                <p className="">
                    {standardAmountFormat(row.totalAmount, row.currency)}
                </p>
            ),
        },
        {
            title: 'Status',
            render: (row: any) => (
                <div
                    className={`flex items-start justify-center p-3 rounded w-fit ${
                        row.status === 'pending'
                            ? 'bg-[#FFDAD5] text-red-500'
                            : 'bg-[#1D1B2014] text-lightBrown'
                    }`}
                >
                    <div
                        className={`w-[6px] h-[6px] rounded-full mr-2 mt-[6px] ${
                            row.status === 'pending'
                                ? 'bg-[#C00010]'
                                : 'bg-[#8BBEA0]'
                        }`}
                    />
                    <p className="text-xs">{row.status}</p>
                </div>
            ),
        },

        {
            title: 'Date Issued',
            render: (row: any) => <p> {formatDateDmy(row.createdAt)}</p>,
        },
        {
            title: 'Time',
            render: (row: any) => (
                <p className="capitalize font-medium text-lightBrown text-xs">
                    {' '}
                    <TimeComponent time={row.createdAt} />
                </p>
            ),
        },
    ];

    return (
        <Fragment>
            <div className="w-full flex flex-col md:px-8 py-4 px-2 ">
                <div className="flex gap-2 items-center ">
                    <button onClick={goBack}>
                        <img src={back} alt="" />
                    </button>
                    <h3 className="font-bold sm:text-[28px] text-[20px]">
                        Bill History
                    </h3>
                </div>
                <div className="bg-white p-4 rounded-2xl">
                    <div className="flex flex-col-reverse sm:flex-row items-center justify-between gap-4 w-full px-4">
                        <div className="flex items-center space-x-3">
                            <h2 className="md:text-2xl text-sm font-semibold text-dark">
                                Total Bills
                            </h2>
                            <button
                                type="button"
                                className="md:text-[22px] text-sm bg-red py-1 text-white px-1 md:px-[11.64px] rounded-[290.909px]"
                            >
                                {billCount}
                            </button>
                        </div>
                        <div>
                            <div className="flex  justify-between">
                                <div className=" flex items-center gap-2 border bg-[#F5F6F7] border-[#F5F6F7] rounded-[28px] sm:max-w-[328px]">
                                    <img src={search} alt="search" />
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        //   value={searchQuery}
                                        //   onChange={(e) => setSearchQuery(e.target.value)}
                                        className="w-full outline-none font-medium text-[14px] bg-transparent text-[#534341] border-transparent focus:ring-0"
                                    />
                                </div>
                                <div className="flex mr-4 ml-4 items-center">
                                    <img
                                        onClick={setModal}
                                        src={Filter}
                                        alt="filterIcon"
                                        className="cursor-pointer h-[24px] w-[24px]"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-[9px] flex flex-col px-4 py-3 overflow-x-auto scrollbar-hide">
                        {isBillHistory && billHistories.length !== 0 ? (
                            <>
                                <Table
                                    rows={paginatedRows}
                                    headers={billHistoryHeader}
                                    allowRowClick
                                    showHead={true}
                                    onRowClick={(row: rowType) =>
                                        navigate(
                                            `/hospital/billing/bill-invoice/${row.billId}`
                                        )
                                    }
                                />
                                <Pagination />
                            </>
                        ) : (
                            <div className="flex flex-col items-center justify-center text-center my-[70px] ">
                                <img
                                    src={EmptySearch}
                                    className="w-[15rem]"
                                    alt="empty"
                                />
                                <p className="mt-6 text-base font-medium font-[Montserrat]">
                                    Your Bill History is empty.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Modal
                isVisible={showModal}
                onClose={() => closeModal()}
                onSave={handleSubmit}
                buttonEnabled={buttonEnabled}
                setButton={setButton}
                saveText="Save"
                closeText="Close"
                showCloseButtonBorder={false}
                clearAll = {true}
                onClearAll={handleClearAll}
            >
                <div className=" ">
                    <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2 sm:max-w-[458px]">
                        <h2 className="font-semibold text-2xl mb-10">
                            Filter By
                        </h2>

                        <div className="mb-5">
                        <h2 className="font-medium text-base text-dark100 mb-3">
                                Status
                            </h2>
                            <div className="md:flex items-center sm:space-x-3 space-y-1 md:space-y-0 ml-4 ">
                        {[ 'Paid', 'Outstanding'].map((mode) => (
                            <button
                                key={mode}
                                type="button"
                                className={`px-4 border-[1px] text-lightBrown border-lightBrown100 w-full font-medium text-sm py-[6px] rounded-lg ${
                                    selectedModeButton === mode ? 'bg-[#1D1B2014]' : ''
                                }`}
                                onClick={() => handleModeButtonClick(mode)}
                            >
                                {mode}
                            </button>
                        ))}
                    </div>
                            <h2 className="font-medium text-base text-dark100 my-4">
                                Amount
                            </h2>
                            <Slider
                                range
                                min={0}
                                max={100}
                                value={rangeValues}
                                onChange={handleRangeChange}
                                trackStyle={[trackStyle]}
                                handleStyle={[handleStyle, handleStyle]}
                                railStyle={railStyle}
                            />

                            <div className="md:flex md:space-x-6 space-y-1 md:space-y-0 ml-4 mt-4">
                                <button
                                    type="button"
                                    className="flex items-center justify-start text-base font-normal border border-dark100 py-[4px] rounded-[8px]  w-[191px] h-[56px]"
                                >
                                    <span className="text-dark100 text-base font-normal px-2">
                                        {rangeValues[0]}
                                    </span>
                                </button>
                                <button
                                    type="button"
                                    className="flex justify-start items-center text-base font-normal border border-dark100 py-[4px] rounded-[8px]  w-[191px] h-[56px]"
                                >
                                    <span className="text-dark100 text-base font-normal px-2">
                                        {rangeValues[1]}k
                                    </span>
                                </button>
                            </div>
                        </div>

                        <div className="mb-5">
                            <h2 className="font-medium text-base text-dark100 mb-3">
                                Date
                            </h2>
                            <div className="md:flex md:space-x-6 space-y-1 md:space-y-0 ml-4">
                                <DatePickerElement
                                    label="From"
                                    value={selectedFromDate}
                                    onChange={(date) =>{
                                   
                                        setSelectedFromDate(date)
        setButton(true);

                                        
    } }
                                />
                                <DatePickerElement
                                    label="To"
                                    value={selectedToDate}
                                    onChange={(date) =>{ setSelectedToDate(date)
                                        setButton(true)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="">
                            <h2 className="font-medium text-base text-dark100 mb-3">
                                Order
                            </h2>
                            <div className="ml-4">
                                <label className="flex items-center space-x-2 cursor-pointer mb-2">
                                    <input
                                        type="checkbox"
                                        className="p-[2px] rounded-sm cursor-pointer"
                                        checked={selectedOption === 'ascending'}
                                        onChange={() =>
                                            handleOptionChange('ascending')
                                            
                                        }
                                    />
                                    <span className="text-base font-normal">
                                        Ascending
                                    </span>
                                </label>
                                <label className="flex items-center space-x-2 cursor-pointer pt-2 ">
                                    <input
                                        type="checkbox"
                                        className="p-[2px] rounded-sm  cursor-pointer"
                                        checked={
                                            selectedOption === 'descending'
                                        }
                                        onChange={() =>
                                            handleOptionChange('descending')
                                        }
                                    />
                                    <span className="text-base font-normal">
                                        Descending
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </Fragment>
    );
};

export default BillHistories;
