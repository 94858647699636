import HospitalLogo from '../../../reusable/HospitalLogo';

import Layout from '../Layout';
import processing from './../../../Images/hourglass.svg';
import { useNavigate } from 'react-router-dom';
import ButtomLogo from '../../../reusable/ButtomLogo';
import { usePharmacyAccountStore } from '../../../store/pharmacy/auth';
import { pharmacyRoutes } from '../../../utils/pharmacyRouteNames';

const AppProcessing = () => {
    const navigate = useNavigate();
    const pharmacyAccount = usePharmacyAccountStore((state) => state);

    //logout function
    const handleLogout = () => {
        sessionStorage.removeItem('pharmacy-account-store'); // clear auth store
        navigate(pharmacyRoutes.healthID, { replace: true });
    };

    return (
        <Layout>
            <div className="flex items-center justify-center flex-col w-full py-8 overflow-x-hidden">
                <div className="mx-auto flex flex-col items-center justify-center gap-4">
                    <HospitalLogo
                        logo={pharmacyAccount.pharmacy.logo}
                        text={pharmacyAccount.pharmacy.tagline}
                        title={pharmacyAccount.pharmacy.name}
                    />

                    <div className="flex flex-col items-center border rounded-xl bg-[#FFf] py-[40px] px-[60px] mt-7 max-w-[592px]">
                        <h3 className="font-semibold text-[#201A19] text-center text-[32px] ">
                            Application Submitted
                        </h3>
                        <p className="text-center text-dark100 text-[14px] max-w-[334px]">
                            We will send you an email concerning your
                            application status within 24 hours.
                        </p>
                        <div className="my-8">
                            <img src={processing} alt="" />
                        </div>
                    </div>

                    <div className=" mt-6 flex justify-center items-center ">
                        <button
                            type="button"
                            onClick={handleLogout}
                            className={`text-sm font-semibold flex justify-center items-center 
                            text-Tmred
                            `}
                        >
                            Logout
                        </button>
                    </div>
                    
                </div>
                <div className="ml-auto mt-10 w-full flex justify-end">
                <ButtomLogo/>
                </div>
            </div>
        </Layout>
    );
};

export default AppProcessing;
