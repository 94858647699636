import React, { useEffect, useState } from 'react';
import Input from '../../../../reusable/Forms/Input';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import hospitalService from '../../../../services/hospitalService';
import { useMutation } from 'react-query';
import { Select, MenuItem, InputLabel, FormControl, TextField, Modal, List, ListItem, ListItemText, Box, Button, Typography } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { labTests } from '../../../../utils/LapTestData';


interface LabTest {
    testName: string;
    group: string;
    type: string;
    
}


type InputProps = {
    patientTmrNumber: string;
    testName: string;
    group: string;
    type: string;
    amount: string | number;
};

interface PatientProfileModalProps {
    tmrNum: string;
    closeModal: () => void;
    refetch:any
}

const PatientProfileModal: React.FC<PatientProfileModalProps> = ({ closeModal, tmrNum,refetch }) => {
    const {
        formState: { isDirty, isValid },
        handleSubmit,
        control,
        reset,
        setValue,
        
    } = useForm<InputProps>();
    const [filteredTests, setFilteredTests] = useState<LabTest[]>(labTests);
    const [searchTerm, setSearchTerm] = useState('');
    const [open, setOpen] = useState(false);
    const [selectedTest, setSelectedTest] = useState<LabTest | null>(null);

    const mutation = useMutation((data: InputProps) => hospitalService.createLabTest(data));

    useEffect(() => {
        if (mutation.isSuccess) {
            let response: any = mutation.data;
            toast.success(response.data.message);
            
            setTimeout(()=>{
                closeModal();
                refetch()
            },1000)
        }
    }, [mutation.isSuccess, mutation.data]);

    useEffect(() => {
        if (mutation.isError) {
            let err: any = mutation.error;
            // console.log('is error', err);
        }
    }, [mutation.isError, mutation.error]);

    const handleClearAll = () => {
        reset({
            patientTmrNumber: '',
            testName: '',
            group: '',
            type: '',
            amount: '',
        });
    };

    const onSubmitForm: SubmitHandler<InputProps> = (data) => {
        const labTestData = {
            ...data,
            patientTmrNumber: tmrNum,
        };
        // console.log(data.patientTmrNumber);
        mutation.mutate(labTestData);
        handleClearAll();
    };

    const onInvalid = (errors) => console.error(errors);

  
    useEffect(() => {
        if (selectedTest) {
            setValue('group', selectedTest.group);
            setValue('type', selectedTest.type);
            
        }
    }, [selectedTest, setValue]);

    const handleSearch = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        setFilteredTests(labTests.filter(test => test.testName.toLowerCase().includes(value.toLowerCase())));
    };

    const handleSelectTest = (test: LabTest) => {
        setSelectedTest(test);
    };
    const resetFields = () => {
        setValue('group', '');
        setValue('type', '');
        setValue('amount', '');
        setSelectedTest(null);
    };
    
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        resetFields();
        setOpen(false);
    };
    

    const handleAddTest = () => {
        if (selectedTest) {
            setValue('testName', selectedTest.testName);
            setValue('group', selectedTest.group);
            setValue('type', selectedTest.type);
            setOpen(false);
        }
    };
    

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-[#2826265E] bg-opacity-75 z-50">
            <ToastContainer />
            <form
                onSubmit={handleSubmit(onSubmitForm, onInvalid)}
                noValidate
                className="bg-white p-2 mx-1 sm:mx-0 sm:p-6 rounded-[28px] w-full max-w-[556px]"
            >
                <h6 className="text-2xl font-semibold text-lightBrown">New Lab Test</h6>
                <p className="font-normal text-sm text-dark100 my-3">Kindly fill the information</p>
                
                <Controller
                    name="testName"
                    rules={{ required: 'Test Name is required' }}
                    control={control}
                    render={({ field }) => (
                        <div>
                           <TextField
    label="Test Name"
    {...field}
    value={field.value || ''}
    onClick={handleOpen}
    fullWidth
   
/>

<Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
>
    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 4  }}>
        <Typography variant="h6" component="h2">Select Test Name</Typography>
        <TextField
            label="Search Test Name"
            value={searchTerm}
            onChange={handleSearch}
            fullWidth
            margin="normal"
        />
        <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
        {filteredTests.length === 0 ? (
                <Typography variant="body2" color="text.secondary">
                    Search not found
                </Typography>
            ):( 
            <List>
                {filteredTests.map((test, index) => (
                    <ListItem
                        key={index}
                        onClick={() => handleSelectTest(test)}
                        sx={{ 
                            '&:hover': { backgroundColor: '#EDE0DE' }, 
                            backgroundColor: selectedTest?.testName === test.testName ? '#EDE0DE' : 'white' ,
                            cursor:'pointer'
                        }}
                        
                    >
                        <ListItemText primary={test.testName} />
                    </ListItem>
                ))}
            </List>
            )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button onClick={handleClose} variant="contained">Cancel</Button>
            <Button onClick={handleAddTest} variant="contained" color="primary" disabled={!selectedTest}>
                Add Test
            </Button>
        </Box>
    </Box>
</Modal>

                        </div>
                    )}
                />

                <div className="my-6 flex justify-between space-x-5">
                    <div className="w-full">
                        <Controller
                            name="group"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <FormControl fullWidth sx={{ minWidth: 120 }} size="small">
                                    <InputLabel id="group-label">Group</InputLabel>
                                    <Select labelId="group-label" id="group" label="Group" {...field} disabled>
                                        <MenuItem value={field.value}>{field.value}</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        />
                    </div>
                    <div className="w-full">
                        <Controller
                            name="type"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <FormControl fullWidth sx={{ minWidth: 120 }} size="small">
                                    <InputLabel id="type-label">Type</InputLabel>
                                    <Select labelId="type-label" id="type" label="Type" {...field} disabled>
                                        <MenuItem value={field.value}>{field.value}</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        />
                    </div>
                </div>

                <div className="mt-5"></div>
                <Controller
                    name="amount"
                    rules={{
                        required: 'Amount is required',
                    }}
                    control={control}
                    render={({ field }) => <Input label="Amount" {...field} type='number' />}
                />

                <div className="flex justify-end space-x-6  mt-6">
                    <button
                        onClick={closeModal}
                        className="py-[10px] px-6 border-[1px] rounded-lg text-Tmred text-sm font-medium border-lightBrown100"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        disabled={!isDirty || !isValid}
                        className={` py-[10px] px-6 rounded-lg text-sm font-medium
                             ${
                                 !isDirty || !isValid || mutation.isLoading
                                     ? 'bg-lightGray cursor-not-allowed'
                                     : ' bg-Tmred text-white hover:cursor-pointer'
                             }
                            `}
                    >
                        Add Test
                    </button>
                </div>
            </form>
        </div>
    );
};

export default PatientProfileModal;
