import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import DashboardLayout from '../Dashboard/DashboardLayout';
import CustomizedProgressBars from '../../../reusable/Forms/ProgressBar';
import { BsChevronLeft } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import PatientSubPlan from './PatientSubPlan';

const NewPatientSubscription = () => {
    const location = useLocation();
    const submittedData = location.state?.submittedData || null;
    const navigate = useNavigate();
    console.log(submittedData);
    return (
        <Fragment>
            <Helmet>
                <title>TMR | New Patient Subscription</title>
            </Helmet>
            <ToastContainer />
            <DashboardLayout>
                <div className="my-4 mx-1 sm:mx-6">
                    <div
                        className="flex gap-4 items-center cursor-pointer"
                        onClick={() => navigate(-1)}
                    >
                        <BsChevronLeft className="text-dark100 w-4 h-4 " />
                        <h1 className="font-semibold md:text-[28px] text-lg  text-lightBrown">
                            New Patient
                        </h1>
                    </div>
                    <div className="py-1">
                        <p className="flex items-end justify-end text-right font-medium text-[16px] pb-1">
                            2 out of 2
                        </p>
                        <CustomizedProgressBars value={100} />
                    </div>
                    <div className="flex flex-col  sm:gap-5 mt-12 ">
                        <div className="flex flex-col w-full bg-white rounded-2xl p-4">
                            <p className="text-center text-[#201A19] rounded-tl-2xl rounded-tr-2xl py-2 text-[22px] font-normal tracking-wide bg-lightTmrRed w-[stretch]">
                                Subscription plan
                            </p>
                        </div>
                        <PatientSubPlan submittedData={submittedData} />
                    </div>
                </div>
            </DashboardLayout>
        </Fragment>
    );
};

export default NewPatientSubscription;
