import React from 'react';
// import icon from '../../Images/Vector (3).png'
interface LayoutProps {
    children: React.ReactNode;
}
function Layout({ children }: LayoutProps) {
    return (
        <div className="h-[100vh] w-full customBg">
            <div className="">{children}</div>
        </div>
    );
}

export default Layout;
