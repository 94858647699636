import React, { useState, useRef, useEffect } from 'react';
import profile from '../../../../Images/profile.png';
import arrow from '../../../../Images/arrow-up.jpg';
import { useHospitalAuthStore } from '../../../../store/hospital/auth';

interface ImageUploadProps {
    onImageChange: (image: any) => void;
    selectedImageProp: string | ArrayBuffer | null;
    className?:any
}

const ImageUpload: React.FC<ImageUploadProps> = ({
    onImageChange,
    selectedImageProp,
    className
}) => {
      const hospitalAuthStore = useHospitalAuthStore((state) => state.hospital);
    const [selectedImage, setSelectedImage] = useState<
        string | ArrayBuffer | null
    >(selectedImageProp || profile);
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (selectedImageProp && selectedImageProp !== selectedImage) {
            setSelectedImage(selectedImageProp);
        }
    }, [selectedImageProp, selectedImage]);

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file:any = e.target.files?.[0];
    
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
                onImageChange(file); // Pass the selected image file to the parent component
            };
            reader.readAsDataURL(file);
        } else {
            // Use the image from the backend or the default profile image
            const backendImage = hospitalAuthStore.logo || profile;
            setSelectedImage(backendImage);
            onImageChange(backendImage); // Pass the image to the parent component
        }
    };
    
    
    

    const handleUploadButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <div className="flex  flex-col items-center ">
            <div className={`relative  w-40 h-40 overflow-hidden my-5 ${className}`}>
                {/* {selectedImage && typeof selectedImage === 'string' && (
                    <img
                        src={hospitalAuthStore.logo}
                        alt="Selected"
                        className="w-full h-full object-cover"
                    />
                )} */}
                 {selectedImage && typeof selectedImage === 'string' ? (
        <img
            src={selectedImage}
            alt="Selected"
            className="w-full h-full object-cover"
        />
                    ) : (
                        <img
                            src={profile}
                            alt="Default"
                            className="w-full h-full object-cover"
                        />
                    )}
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    ref={fileInputRef}
                    className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                />
            </div>

            <button
                onClick={handleUploadButtonClick}
                className="flex justify-center rounded-lg items-center py-2 border border-[#857371] w-full"
            >
                <img src={arrow} alt="" />
                <span className="text-Tmred text-xs font-medium ml-2">
                    Upload
                </span>
            </button>
        </div>
    );
};

export default ImageUpload;
