import React, { useState } from 'react'
import Input from '../../../reusable/Forms/Input';
import { IoCloseOutline } from 'react-icons/io5';
interface iFormData {
   drugName: any;
    dosage: any;
    sideEffect :any;
    doctorsNote:any
}
const MedicationCard = ({closeModal}) => {
    const [formData, setFormData] = useState<iFormData>({
        drugName: '',
         dosage: '',
         sideEffect:'',
         doctorsNote:''
     });
    
    
    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setFormData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleChangeTextarea = (
        e: React.ChangeEvent<HTMLTextAreaElement>
      ) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }))
      }
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-[#2826265E] bg-opacity-75 z-50 ">
            <div className="p-4 flex justify-between flex-col text-left bg-[#fff] rounded-[28px]">
            <div className='py-4 flex w-full items-center justify-between'>
                        <p className="font-semibold sm:text-[24px] text-[18px] text-[#201A19]">
                            Medication
                        </p>
                        <button onClick={()=>closeModal()}>
                            <IoCloseOutline size={'20px'} />
                        </button>
                    </div>
           <form >
                        <div className="flex md:flex-row flex-col md:gap-5 justify-between mb-2">
                            <Input
                                disabled={true}
                                name="drugName"
                                label="Drug Name"
                                value={formData.drugName}
                                onChange={handleChange}
                                className="w-full border border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider my-4"
                            />
                            
                        </div>
                        <div className="flex md:flex-row flex-col md:gap-5 justify-between my-2">
                        <textarea
                        disabled={true}
                                name="dosage"
                                placeholder="Dosage"
                                required={true}
                                value={formData.dosage}
                                onChange={handleChangeTextarea}
                                className="w-full border h-[112px] border-[#857371] p-4 rounded-[8px] text-[16px] tracking-wider my-2"
                            />
                           <textarea
                           disabled={true}
                                name="sideEffect"
                                placeholder="Side Effect"
                                required={true}
                                value={formData.sideEffect}
                                onChange={handleChangeTextarea}
                                className="w-full border h-[112px] border-[#857371] p-4 rounded-[8px] text-[16px] tracking-wider my-2"
                            />

                            
                        </div>
                        <textarea
                        disabled={true}
                                name="doctorsNote"
                                placeholder="Doctors Note"
                                required={true}
                                value={formData.doctorsNote}
                                onChange={handleChangeTextarea}
                                className="w-full border h-[112px] border-[#857371] p-4 rounded-[8px] text-[16px] tracking-wider my-2"
                            />


             </form>
             </div>
        </div>
  )
}

export default MedicationCard
