import React from 'react'
import DashboardLayout from '../Dashboard/DashboardLayout';

import { Helmet } from 'react-helmet'
import OrderDetails from '../../../components/PharmacyComponent/Order/OrderDetails';
function OrderDetail() {
  return (
    <DashboardLayout>
    <Helmet>
        <title>
           TMR | Order Details
        </title>
    </Helmet>
    <div className="">
        <OrderDetails/>
    </div>
</DashboardLayout>
  )
}

export default OrderDetail