import { useState } from 'react';
import usePagination from '../../hooks/usePagination';
import Table from '../../constants/Table';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import loadingState from '../../Images/loading.json';
import { useQuery } from 'react-query';
import EmptySearch from '../../Images/emptySearch.svg';
import Empty from '../../Images/patientEmpty.png';
import hospitalService from '../../services/hospitalService';
import { useEffect } from 'react';
import { usePatientStore } from '../../store/hospital/auth';
import { formatDateDmy } from '../../utils/helpers';
import { hospitalRoutes } from '../../utils/routeNames';
import dayjs from 'dayjs';
interface rowType {
    _id: string;
    tmrNumber?: string;
}

const TotalPatients = ({ searchTerm, filterOptions }: any) => {
    const navigate = useNavigate();
    const setPatientCount = usePatientStore((state) => state.setPatientCount);
    const [isData, setIsData] = useState(false);

    const { data, isError, isLoading } = useQuery(
        'patients',
        hospitalService.getPatients
    );

    let patient = data?.patients || [];

    if (filterOptions.gender) {
        patient = patient.filter((p) => p.gender === filterOptions.gender);
    }
    if (filterOptions.fromDate && filterOptions.toDate) {
        console.log('filterOptions in date', filterOptions);
        const fromDate = dayjs(filterOptions.fromDate);
        const toDate = dayjs(filterOptions.toDate);
        patient = patient.filter((p) => {
            const createdAtDate = dayjs(p.createdAt);
            return (
                createdAtDate?.isAfter(fromDate) &&
                createdAtDate?.isBefore(toDate)
            );
        });
    }

    if (filterOptions.order === 'ascending' || !filterOptions.order) {
        patient = patient.sort((a, b) =>
            dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? 1 : -1
        );
    }
    if (filterOptions.order === 'descending') {
        patient = patient.sort((a, b) =>
            dayjs(b.createdAt).isAfter(dayjs(a.createdAt)) ? 1 : -1
        );
    }

    useEffect(() => {
        if (data?.patients) {
            setIsData(true);
            const patients = patient;
            setPatientCount(patients?.length || 0);
        } else {
            setIsData(false);
        }
    }, [data, setPatientCount, patient]);

    const filteredpatient = patient?.filter(
        (user: any) =>
            user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.lastName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const { page, limit, Pagination } = usePagination({
        page: 1,
        limit: 10,
        total: filteredpatient?.length,
    });

    const paginatedRows = filteredpatient?.slice(
        (page - 1) * limit,
        page * limit
    );
    if (isLoading) {
        return (
            <div className="flex justify-center items-center py-32">
                <Lottie
                    animationData={loadingState}
                    loop={true}
                    className="lottie"
                />
            </div>
        );
    }
    if (isError) {
        return <div>Error loading Patients, please refresh your page</div>;
    }
    const recordSearchTableHeaders = [
        {
            title: 'S/N',
            render: (row: any) => (
                <p className="text-xs font-medium">
                    {patient?.findIndex((user: any) => user._id === row._id) + 1}
                </p>
            ),
        },
        {
            title: 'Patient Name',
            render: (row: any) => (
                <div className="flex items-center space-x-2">
                    <img
                        className="object-cover h-[40px] w-[40px] rounded-full"
                        style={{
                            boxShadow:
                                'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
                        }}
                        src={row.image}
                        alt=""
                    />
                    <div>
                        <p className="text-sm font-medium text-lightBrown truncate w-full max-w-[100px]">
                            {row.firstName} {row.lastName}
                        </p>
                        <p className="text-xs font-medium text-lightBrown100">
                            {row.number}
                        </p>
                    </div>
                </div>
            ),
        },
        {
            title: 'Gender',
            render: (row: any) => (
                <p className="text-xs font-medium text-lightBrown100 ">
                    {row.gender}
                </p>
            ),
        },
        {
            title: 'Phone Number',
            render: (row: any) => (
                <p className="text-xs font-medium text-lightBrown100">
                    {row.phoneNumber}
                </p>
            ),
        },

        {
            title: 'Address',
            render: (row: any) => (
                <p className="font-medium text-xs truncate w-32 text-lightBrown100 ">
                    {row.address}
                </p>
            ),
        },
        {
            title: 'Date Registered',
            render: (row: any) => (
                <p className="text-xs font-medium text-lightBrown100 ">
                    {' '}
                    {formatDateDmy(row.createdAt)}
                </p>
            ),
        },
        // {
        //     title: '',
        //     render: (row: any) => (
        //         <MdMoreVert className="text-xl text-lightBrown100" />
        //     ),
        // },
    ];
    return (
        <div className="w-full bg-white">
            <div className="gap-[2rem] block md:grid">
                <div className="w-full grid gap-x-[30px] self-start md:mb-[0] mb-[2rem]">
                    <div className="pt-[10px]  py-[20px] overflow-x-auto">
                        {isData === true ? (
                            <>
                                {searchTerm ? (
                                    <>
                                        {filteredpatient &&
                                        filteredpatient.length > 0 ? (
                                            <>
                                                <Table
                                                    rows={paginatedRows}
                                                    headers={
                                                        recordSearchTableHeaders
                                                    }
                                                    showHead={true}
                                                    allowRowClick
                                                    onRowClick={(
                                                        row: rowType
                                                    ) =>
                                                        navigate(
                                                            `${hospitalRoutes.patient}/${row.tmrNumber}`
                                                        )
                                                    }
                                                />
                                                <Pagination />
                                            </>
                                        ) : (
                                            // Display a message when there are no matching patients
                                            <div className="flex flex-col items-center justify-center text-center my-[70px] ">
                                                <img src={Empty} alt="" />
                                                <p className="mt-6 text-base font-medium font-[Montserrat]">
                                                    Your patient list is empty.{' '}
                                                    <br />
                                                    Begin by adding patient
                                                    information <br />
                                                    to manage your healthcare
                                                    records.
                                                </p>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    // Display all patients when there is no search term
                                    <>
                                        {patient && patient.length > 0 ? (
                                            <>
                                                <Table
                                                    rows={paginatedRows}
                                                    headers={
                                                        recordSearchTableHeaders
                                                    }
                                                    showHead={true}
                                                    allowRowClick
                                                    onRowClick={(
                                                        row: rowType
                                                    ) =>
                                                        navigate(
                                                            `/hospital/patient/${row.tmrNumber}`
                                                        )
                                                    }
                                                />
                                                <Pagination />
                                            </>
                                        ) : (
                                            // Display a message when the list of patients is empty
                                            <div className="flex flex-col items-center justify-center text-center my-[70px] ">
                                                <img
                                                    src={EmptySearch}
                                                    alt="empty"
                                                />
                                                <p className="mt-6 text-base font-medium font-[Montserrat]">
                                                    No patients found with the
                                                    selected filters.
                                                </p>
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            // Display loading animation while data is being fetched
                            <div className="flex justify-center items-center py-32">
                                <Lottie
                                    animationData={loadingState}
                                    loop={true}
                                    className="lottie"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TotalPatients;
