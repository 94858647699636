import React, { useState } from 'react';
import Modal from '../../reusable/Modal';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import dayjs, { Dayjs } from 'dayjs';
import DatePickerElement from '../../reusable/Forms/DatePickerElement';
const FilterBill = ({
    showModal,
    closeModal,
    handleSubmit,
    buttonEnabled,
    setButton,
    selectedOption,
    handleOptionChange,
}) => {
    const [rangeValues, setRangeValues] = useState([0, 100]);
    const [clickedButton, setClickedButton] = useState(null);
    const [clickedButton2, setClickedButton2] = useState(null);
    const [selectedFromDate, setSelectedFromDate] = useState<Dayjs | null>(
        dayjs('')
    );
    const [selectedToDate, setSelectedToDate] = useState<Dayjs | null>(
        dayjs('')
    );
    const handleButtonClick = (button) => {
        setClickedButton(button);
    };
    const handleButtonClick2 = (button) => {
        setClickedButton2(button);
    };
    // Update the rangeValues state when the slider values change
    const handleRangeChange = (values) => {
        setRangeValues(values);
    };
    const trackStyle = { backgroundColor: 'red' };
    const handleStyle = {
        borderColor: 'red',
        backgroundColor: 'red',
    };
    const railStyle = { backgroundColor: '#EDE0DE' };
    const buttons = [
        'All',
        'Diagnosis',
        'Appointment',
        'Top Up',
        'Sent',
        'Withdraw',
    ];
    const userType = ['Sender', 'Receiver'];
    return (
        <div>
            <Modal
                isVisible={showModal}
                onClose={() => closeModal()}
                onSave={handleSubmit}
                buttonEnabled={buttonEnabled}
                setButton={setButton}
                saveText="Save"
                closeText="Close"
                showCloseButtonBorder={false}
            >
                <div className=" ">
                    <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2 sm:max-w-[458px]">
                        <h2 className="font-semibold text-2xl mb-10">
                            Filter By
                        </h2>
                        <div className=" my-9">
                            <h2 className="font-medium text-base text-dark100 mb-3">
                                Bill Type
                            </h2>
                            <div className="grid grid-cols-2 md:grid-cols-3 gap-3 max-w-[458px]">
                                {buttons.map((button, index) => (
                                    <button
                                        key={index}
                                        // type="button"
                                        className={`px-2 border-[1px] text-center w-full font-medium text-[14px] py-[6px] rounded-lg hover:border-Tmred ${
                                            clickedButton === button
                                                ? 'border-Tmred text-[#000]'
                                                : 'border-[#49454F1F] text-[#857371]'
                                        }`}
                                        onClick={() =>
                                            handleButtonClick(button)
                                        }
                                    >
                                        {button}
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div className=" my-9">
                            <h2 className="font-medium text-base text-dark100 mb-3">
                                User Type
                            </h2>
                            <div className="flex items-center w-full gap-3">
                                {userType.map((button, index) => (
                                    <button
                                        key={index}
                                        type="button"
                                        className={`px-2 border-[1px] w-full font-medium text-sm cursor-pointer py-[6px] rounded-lg hover:border-Tmred ${
                                            clickedButton2 === button
                                                ? 'border-Tmred text-[#000]'
                                                : 'border-[#49454F1F] text-[#857371]'
                                        }`}
                                        onClick={() =>
                                            handleButtonClick2(button)
                                        }
                                    >
                                        {button}
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div className="mb-5">
                            <h2 className="font-medium text-base text-dark100 mb-3">
                                Amount
                            </h2>
                            <Slider
                                range
                                min={0}
                                max={100}
                                value={rangeValues}
                                onChange={handleRangeChange}
                                trackStyle={[trackStyle]}
                                handleStyle={[handleStyle, handleStyle]}
                                railStyle={railStyle}
                            />

                            <div className="md:flex md:space-x-6 space-y-1 md:space-y-0 ml-4 mt-4">
                                <button
                                    type="button"
                                    className="flex items-center justify-start text-base font-normal border border-dark100 py-[4px] rounded-[8px]  w-[191px] h-[56px]"
                                >
                                    <span className="text-dark100 text-base font-normal px-2">
                                        {rangeValues[0]}
                                    </span>
                                </button>
                                <button
                                    type="button"
                                    className="flex justify-start items-center text-base font-normal border border-dark100 py-[4px] rounded-[8px]  w-[191px] h-[56px]"
                                >
                                    <span className="text-dark100 text-base font-normal px-2">
                                        {rangeValues[1]}k
                                    </span>
                                </button>
                            </div>
                        </div>

                        <div className="mb-5">
                            <h2 className="font-medium text-base text-dark100 mb-3">
                                Date
                            </h2>
                            <div className="md:flex gap-3 md:space-x-6 space-y-1 md:space-y-0 ml-4 ">
                                <DatePickerElement
                                    label="From"
                                    value={selectedFromDate}
                                    onChange={(date) =>
                                        setSelectedFromDate(date)
                                    }
                                />
                                <DatePickerElement
                                    label="To"
                                    value={selectedToDate}
                                    onChange={(date) => setSelectedToDate(date)}
                                />
                            </div>
                        </div>
                        <div className="">
                            <h2 className="font-medium text-base text-dark100 mb-3">
                                Order
                            </h2>
                            <div className="ml-4">
                                <label className="flex items-center space-x-2 cursor-pointer mb-2">
                                    <input
                                        type="checkbox"
                                        className="p-[2px] rounded-sm cursor-pointer"
                                        checked={selectedOption === 'ascending'}
                                        onChange={() =>
                                            handleOptionChange('ascending')
                                        }
                                    />
                                    <span className="text-base font-normal">
                                        Ascending
                                    </span>
                                </label>
                                <label className="flex items-center space-x-2 cursor-pointer pt-2 ">
                                    <input
                                        type="checkbox"
                                        className="p-[2px] rounded-sm  cursor-pointer"
                                        checked={
                                            selectedOption === 'descending'
                                        }
                                        onChange={() =>
                                            handleOptionChange('descending')
                                        }
                                    />
                                    <span className="text-base font-normal">
                                        Descending
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default FilterBill;
