// import react from 'react';
import image from '../../../Images/PharmacyImages/EmptyInventory.svg';
const AppointmentEmptyState: React.FC = () => {
    return (
        <div className="flex flex-col justify-center items-center mt-28">
            <img src={image} alt="emptyImage" />
            <p className="text-center text-[#534341] font-medium pt-2">
            Inventory list is empty. 
            </p>
        </div>
    );
};

export default AppointmentEmptyState;