// import { useState } from 'react';
import usePagination from '../../../hooks/usePagination';
import Table from '../../../constants/Table';
import { useNavigate } from 'react-router-dom';
import { formatDateDmy, standardAmountFormat } from '../../../utils/helpers';
import image from '../../../Images/EmptyAmbulance.svg';
import FleetEmptyState from './FleetEmptyState';
import { useHospitalAuthStore } from '../../../store/hospital/auth';

interface rowType {
    _id: string;
}
const FleetData = ({ fleet, searchQuery }: any) => {
    const currency = useHospitalAuthStore((state) => state.hospital.currency);
    const navigate = useNavigate();
 console.log(fleet)
    const filteredfleets = fleet?.filter(
        (row: any) =>
            row.hospitalUser?.firstName
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
            row.hospitalUser?.lastName
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
            row.ambulanceType.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row.status.toLowerCase().includes(searchQuery.toLowerCase()) 
    );

    //Pagination
    const { page, limit, Pagination } = usePagination({
        page: 1,
        limit: 10,
        total: fleet?.length,
    });

    const paginatedRows = filteredfleets?.slice(
        (page - 1) * limit,
        page * limit
    );

   

    const fleetTableHeaders = [
        {
            title: 'S/N',
            render: (row: any) => (
                <p>
                    {fleet?.findIndex(
                        (user: any) => user._id === row._id
                    ) + 1}
                </p>
            ),
        },
        {
            title: 'Ambulance ID',
            render: (row: any) => <p>{row.licenseNumber}</p>,
        },
        {
            title: 'Type of Ambulance',
            render: (row: any) => (
                <>
                    <div>
                        <p className="truncate w-32 font-medium text-lightBrown text-sm ">
                            {`${row.ambulanceType}`}
                        </p>
                        
                    </div>
                </>
            ),
        },
       

        {
            title: 'Amount',
            render: (row: any) => (
                <p className="">
                    {standardAmountFormat(row.amount, currency)}
                </p>
            ),
            
        },

        {
            title: 'Status',
            render: (row: any) => (
                <div
                className={`flex items-start justify-center p-2 rounded 
                    ${
                        row.status === 'On Duty'
                            ? 'bg-[#FDDFA6] text-lightBrown'
                            : ''
                    } 
                    ${
                        row.status === 'Available'
                            ? 'bg-[#E2EEE7] text-lightBrown'
                            : ''
                    }
                    ${
                        row.status === 'Not Available'
                            ? 'bg-[#FDDFA6] text-lightBrown'
                            : ''
                    }
                `}
            >
                <div
                    className={`w-[6px] h-[6px] rounded-full mr-2 mt-[6px] 
                        ${row.status === 'On Duty' ? 'bg-lightBrown' : ''}
                        ${row.status === 'Available' ? 'bg-[#3A975F]' : ''}
                        ${row.status === 'Not Available' ? 'bg-lightBrown' : ''}
                    }`}
                />
                <p className="text-xs">{row.status}</p>
            </div>
            ),
        },
        {
            title: 'Date Created',
            render: (row: any) => <p> {formatDateDmy(row.createdAt)}</p>,
        },
        
        
    ];
    return (
        <div className="w-full pt-5">
            {!fleet?.length ? (
                <FleetEmptyState />
            ) : filteredfleets?.length === 0 ? ( // Check if filteredfleets is empty
                <>
                    {/* <Table headers={fleetTableHeaders} showHead={true} /> */}
                    <div className="flex flex-col items-center justify-center text-center my-[70px] ">
                        <img src={image} alt="" />
                        <p className="mt-6 text-base font-medium font-[Montserrat]">
                            The search result is currently empty.
                        </p>
                    </div>
                </>
            ) : (
                <div className="gap-[2rem] block md:grid">
                    <div className="w-full grid gap-x-[30px] self-start md:mb-[0] mb-[2rem] overflow-x-auto scrollbar-hide">
                        <Table
                            rows={paginatedRows}
                            headers={fleetTableHeaders}
                            showHead={true}
                            allowRowClick
                            onRowClick={(row: rowType) =>
                                navigate(
                                    `/hospital/ambulance/fleet/ambulance-profile/${row._id}`
                                )
                            }
                        />
                        <Pagination />
                    </div>
                </div>
            )}
        </div>
    );
};

export default FleetData;
