export  const permission = [
    {
        id: 1,
        perm: 'Inventory',
    },
    {
        id: 2,
        perm: 'Admin',
    },
    {
        id: 3,
        perm: 'Order',
    },
   
    {
        id: 4,
        perm: 'Setting',
    },
    {
        id: 5,
        perm: 'Pharmacy Wallet',
    },
   
];