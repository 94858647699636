import React, { useEffect, useState } from 'react'
import Input from '../../reusable/Forms/Input';
import { standardAmountFormat } from '../../utils/helpers';
import {  useMutation } from 'react-query';
import hospitalService from '../../services/hospitalService';
import { toast } from 'react-toastify';
import OTPModal from './OTPModal';
// import Lottie from 'lottie-react';
// import loadingState from '../../Images/loading2.json';
// import Refresh from '../../Images/Refresh';
import { useHospitalAuthStore } from '../../store/hospital/auth';

function HospitalSend({walletBalance,refetch, currency}) {
    const [isloading, setIsLoading] = useState(false);
    const [buttonEnabled, setButton] = useState(false);
    const [buttonEnabled2, setButton2] = useState(false);
    const [Purpose, setPurpose] = useState<string>('');
    const [otpModal, setOtpModal] = useState<boolean>(false);
    const [buttonOTPEnabled, setOTPButton] = useState(false);
    const [OTPLoading, setIsOTPLoading] = useState(false);
    const [fetchName, setFetchName] =useState(false)
    const hospitalAuthStore = useHospitalAuthStore((state) => state);
    const currentHospitalTmrId= hospitalAuthStore?.hospital?.tmrId
    // console.log({currentHospitalTmrId})
    const [validationMessage, setValidationMessage] = useState('');
    const [formData, setFormData] = useState({
        Hospital_Name:'',
        Hospital_ID: '',
        Amount: '',
      
    });
    const onClose = () => {
        setOtpModal(false);
    };
    useEffect(() => {
        if (
            formData.Amount !== '' &&
            formData.Hospital_ID !== '' &&
            formData.Hospital_Name !== '' && Purpose !== ''
        ) {
            setButton(true);
        }
    }, [Purpose, formData.Amount, formData.Hospital_ID, formData.Hospital_Name]);
    
    const handlePurposeChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setPurpose(e.target.value);
    };

   
   
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
        if (name === 'Hospital_ID') {
            setButton2(value.length >= 6 && value !== currentHospitalTmrId);
        }
        if (name === 'Hospital_ID') {
            if (value.length >= 6 && value === currentHospitalTmrId) {
                setValidationMessage('You cannot send money to your Hospital.');
                setButton2(false);
            } else {
                setValidationMessage('');
                setButton2(value.length >= 6);
            }
        }
    };

    const validateTmrId = useMutation((data: any) =>
        hospitalService.validateTMRID(data)
    );
     useEffect(() => {
        if (validateTmrId.isSuccess) {
            setFetchName(false);
            setButton2(false)
            const response = validateTmrId.data;
            const message = response.data?.message;
            const hospitalName = response.data?.payload?.data?.name; 
            toast.success(message);
            setFormData((prevData) => ({
                ...prevData,
                Hospital_Name: hospitalName,
            }));
        }
    }, [validateTmrId.isSuccess, validateTmrId.data]);

    useEffect(() => {
        if (validateTmrId.isError) {
            setFetchName(false);
            setButton2(false)
            let err: any = validateTmrId.error;
            toast.error(err.response?.data?.error?.message || 'error');
            setFormData((prevData) => ({
                ...prevData,
                Hospital_Name: '',
            }));
        }
    }, [validateTmrId.isError, validateTmrId.error]);

    const handleBlur = async () => {
        setFetchName(true);
        setButton2(false)
        try {
            await validateTmrId.mutateAsync({ tmrId: formData.Hospital_ID });
        } catch (error) {
            setFetchName(false);
        }
    };

    const mutation = useMutation(({ data, recipientType }: any) =>
        hospitalService.initiateSendStaffMoney(data, recipientType)
    );
    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            toast.success(mutation.data?.data.message);
            setTimeout(() => {
                toast.dismiss();
            }, 5000);
            setFormData({
                Hospital_Name:'',
                Hospital_ID: '',
                Amount: '',
            });
            setPurpose('');
            setButton(false);
            setOtpModal(true);
        }
    }, [mutation.isSuccess, mutation.data]);

    useEffect(() => {
        if (mutation.isError) {
            setIsLoading(false);
            let err: any = mutation.error;
            toast(err.response.data.error.message);
            setTimeout(() => {
                toast.dismiss();
            }, 2000);
        }
    }, [mutation.isError, mutation.error]);
    const handlesubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setButton(false);
        try {
            setIsLoading(true);
            const paymentData = {
                amount: formData.Amount,
                recipientId: formData.Hospital_ID,
                purpose: Purpose,
            };
            const recipientType = 'hospital';  
            await mutation.mutateAsync({ data: paymentData, recipientType });
        } catch (error) {
            console.error('Error sending money:', error);
        } finally {
            setIsLoading(false);
        }
    };
    const handleOTPSubmit = async (otp: string) => {
        setButton(false);
        setOTPButton(false);
        try {
            setIsOTPLoading(true);
            const withdrawData = {
                otp: otp,
            };
            const recipientType = 'hospital';  
            await otpmutation.mutateAsync( { data: withdrawData, recipientType });
        } catch (error) {
            console.error('Error withdrawing:', error);
        } finally {
            setIsOTPLoading(false);
        }
    };
    const otpmutation = useMutation(({ data, recipientType }: any) =>
        hospitalService.completeSendStaffMoney(data, recipientType)
    );
    useEffect(() => {
        if (otpmutation.isSuccess) {
            setIsOTPLoading(false);
            const response = otpmutation.data;
            toast.success(response.data.message);
            setTimeout(() => {
                toast.dismiss();
                 refetch()
            }, 2000);
            setOtpModal(false);
            setOTPButton(false);
        }
    }, [otpmutation.isSuccess, otpmutation.data]);

    useEffect(() => {
        if (otpmutation.isError) {
            setIsOTPLoading(false);
            let err: any = otpmutation.error;

            toast.error(err.response?.data?.error?.message || 'error');
        } else {
            // console.log('error');
        }
    }, [otpmutation.isError, otpmutation.error]);
  return (
    <div> <div className="max-w-[60%] w-full p-8 rounded-2xl bg-[#FFFFFF] mb-5">
    <div className="flex flex-col md:flex-row items-center justify-between">
        <div>
            <p className=" font-normal text-[#000000] text-sm ">
                Available Balance
            </p>
            <p className="text-[#000] text-[20px] font-semibold">
                <span>
                    
                    {isloading
                        ? '0'
                        : standardAmountFormat(walletBalance,currency)}
                </span>
            </p>
        </div>
    </div>
    <div>
        <form
            action=""
            onSubmit={handlesubmit}
            className="flex flex-col gap-3 sm:gap-8"
        >
            <div className=""></div>
            <Input
                name="Amount"
                label="Enter Amount"
                value={formData.Amount}
                onChange={handleChange}
                type="number"
            />
<div className="relative">
            <Input
                name="Hospital_ID"
                label="Hospital TMR ID"
                value={
                    formData.Hospital_ID
                }
                onChange={handleChange}
                
                className="w-full border border-[#857371] p-4 rounded-[8px] text-[16px] tracking-wider my-4 mt-10"
            />
    <div className={`absolute right-3 ${validationMessage ? 'top-[34%]' : 'top-1/2'} transform -translate-y-1/2 flex items-center`}>
<button onClick={handleBlur} disabled={!buttonEnabled2} className={`${fetchName && 'text-[#000]' } ${!buttonEnabled2 ? 'cursor-not-allowed  text-[#2320281f] ' :'cursor-pointer text-[#000]'} p-2 rounded-lg bg-[#1D1B201F]`}>
    {fetchName ? 'Checking...':'Check'}
</button>
</div>
{validationMessage && (
                            <p className="text-red-500 text-sm mt-2">{validationMessage}</p>
                        )}
            </div>
            
            <div >
    <Input
        name="Hospital_Name"
        label="Hospital Name"
        value={formData.Hospital_Name}
        disabled
        className="w-full border border-[#857371] p-4 pr-12 rounded-[8px] text-[16px] tracking-wider my-4 mt-10"
    />
   
</div>

            <textarea
                        name="reason"
                        placeholder="Reason"
                        value={Purpose}
                        onChange={handlePurposeChange}
                        className="w-full border h-[150px] border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider my-4"
                    ></textarea>

            

            <div className="py-8">
                <button
                    type="submit"
                    className={`w-full h-[40px] flex justify-center rounded-lg items-center text-[#9d9b9b] font-semibold  hover:cursor-not-allowed transition-all duration-500  ${
                        buttonEnabled
                            ? 'bg-Tmred text-white hover:cursor-pointer '
                            : 'bg-[#1D1B201F] '
                    } `}
                >
                    {isloading
                        ? 'Sending...'
                        : 'Send Money'}
                </button>
            </div>
        </form>
        {otpModal && (
                        <OTPModal
                            isLoading={OTPLoading}
                            setButton={setOTPButton}
                            buttonEnabled={buttonOTPEnabled}
                            closeModal={onClose}
                            handleSubmit={handleOTPSubmit}
                        />
                    )}
    </div>
</div></div>
  )
}

export default HospitalSend