import { useEffect, useState } from 'react';
import hospitalService from '../../services/hospitalService';
import logger from '../../utils/logger';
import { useNotificationStore } from '../../store/hospital/auth';
import NotificationLists from './NotificationLists';
import Tabs from './Tabs';
import nothing from '../../Images/emptyNotification.png';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import loadingState from '../../Images/loading.json';
import Lottie from 'lottie-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type DeleteNotificationProps = {
    notificationId: string;
};
const ActiveState = () => {
    const [selectedNotifications, setSelectedNotifications] = useState<
        string[]
    >([]);
    const [allCheckboxesSelected, setAllCheckboxesSelected] = useState(false);
    const unreadLength = useNotificationStore((state) => state.unreadLength);

    const setNotificationLength = useNotificationStore(
        (state) => state.setNotificationLength
    );
    const setUnreadLength = useNotificationStore(
        (state) => state.setUnreadLength
    );

    const queryClient = useQueryClient();

    const [empty, setEmpty] = useState(false);
    const [loading, setLoading] = useState(false);
    const { data, isError, isLoading } = useQuery(
        'notification',
        hospitalService.notification
    );

    const mutation = useMutation<string[], any, string[]>(
        (notificationIds: string[]) =>
            hospitalService.deleteNotifications(notificationIds)
    );
    const mutationMark = useMutation<string[], any, string[]>(
        (notificationIds: string[]) =>
            hospitalService.markAsRead(notificationIds)
    );

    useEffect(() => {
        if (mutationMark.isSuccess) {
            console.log('Notifications marked as read:', mutationMark.data);
            queryClient.invalidateQueries('notification', { exact: true });
        }
        setLoading(false);
    }, [mutationMark.isSuccess, mutationMark.data]);

    const handleMarkAsRead = () => {
        setLoading(true);
        if (selectedNotifications.length > 0) {
            console.log('Selected Notifications:', selectedNotifications);
            mutationMark.mutate(selectedNotifications);
        }
        setSelectedNotifications([]);
        setAllCheckboxesSelected(false);
        toast.success('Marked as Read');
        setTimeout(() => {
            toast.dismiss();
        }, 2000);
    };

    useEffect(() => {
        if (data && data.notifications.length === 0) {
            setEmpty(true);
        } else {
            setEmpty(false);
        }

        setNotificationLength(data?.notifications?.length || 0);
        setUnreadLength(
            (data?.notifications || []).filter(
                (notification) => !notification.isRead
            ).length
        );
    }, [data, setNotificationLength, setUnreadLength]);

    useEffect(() => {
        if (mutation.isSuccess) {
            setLoading(false);
            queryClient.invalidateQueries('notification', { exact: true });
        }
    }, [mutation.isSuccess, queryClient]);

    useEffect(() => {
        if (mutation.isError) {
            setLoading(false);
            let err: any = mutation.error;
            const errorMessage =
                err.response?.data?.error?.message || 'An error occurred';
            console.error(`Error: ${errorMessage}`);
        }
    }, [mutation.isError, mutation.error]);

    const handleDeleteNotification = ({
        notificationId,
    }: DeleteNotificationProps) => {
        setLoading(true);
        mutation.mutate([notificationId]);
        toast.success('Deleted successfully');
        setTimeout(() => {
            toast.dismiss();
        }, 2000);
        console.log('Deleting notification with ID:', notificationId);
    };

    const handleCheckboxChange = (notificationId: string) => {
        setSelectedNotifications((prevSelected) => {
            if (prevSelected.includes(notificationId)) {
                return prevSelected.filter((id) => id !== notificationId);
            } else {
                return [...prevSelected, notificationId];
            }
        });
    };
    const handleDelete = () => {
        if (selectedNotifications.length > 0) {
            console.log('Selected Notifications:', selectedNotifications);
            mutation.mutate(selectedNotifications);
        }
        setSelectedNotifications([]);
        setAllCheckboxesSelected(false);
    };
    const handleCancel = () => {
        setSelectedNotifications([]);
        setAllCheckboxesSelected(false);
    };

    const markAll = () => {
        const allNotificationIds =
            data?.notifications.map((notification) => notification._id) || [];
        setSelectedNotifications(allNotificationIds);
        setAllCheckboxesSelected(true);
    };
    const unMarkAll = () => {
        setSelectedNotifications([]);
        setAllCheckboxesSelected(false);
    };

    if (isLoading) {
        return (
            <div className="grid justify-center py-20">
                <Lottie
                    animationData={loadingState}
                    loop={true}
                    className="lottie"
                />
            </div>
        );
    }

    if (isError) {
        return <div>Error loading specialty, please refresh your page</div>;
    }

    logger(data);

    const renderContent = (notifications, isRead) => {
        const filteredNotifications = notifications.filter(
            (notification) =>
                isRead === undefined || notification.isRead === !isRead
        );

        return filteredNotifications.length === 0 ? (
            <div className="grid justify-center text-center py-20">
                <img src={nothing} alt="no notification" />
                <p className="mt-4 text-[22px] text-dark100">
                    Notification is empty
                </p>
            </div>
        ) : (
            <NotificationLists
                notifications={filteredNotifications}
                handleDeleteNotification={handleDeleteNotification}
                handleMarkAsRead={handleMarkAsRead}
                selectedNotifications={selectedNotifications}
                handleDelete={handleDelete}
                handleCheckboxChange={handleCheckboxChange}
                handleCancel={handleCancel}
                allCheckboxesSelected={allCheckboxesSelected}
            />
        );
    };

    const tabs = [
        {
            title: 'All',
            content: renderContent(data?.notifications, undefined),
        },
        {
            title: 'Unread',
            content: renderContent(data?.notifications, true),
        },
        {
            title: 'Read',
            content: renderContent(data?.notifications, false),
        },
    ];

    return (
        <div className="mt-7 sm:mt-0">
            <ToastContainer />
            <div className=" bg-[#fff]  p-2 rounded-2xl overflow-x-auto sm:overflow-hidden">
                <div className="mb-2">
                    <h6 className="text-[28px] font-semibold text-lightBrown">
                        Notifications
                    </h6>
                    <div className="flex justify-between items-center">
                        <p className="text-base font-normal text-black">
                            You have {unreadLength} unread notifications
                        </p>
                        <button
                            onClick={
                                allCheckboxesSelected ? unMarkAll : markAll
                            }
                            type="button"
                            className="text-sm font-medium text-brown bg-lightPink100 px-6 py-[10px] rounded-md"
                        >
                            {allCheckboxesSelected ? 'Unmark All' : 'Mark All'}
                        </button>
                    </div>
                </div>
                {loading ? (
                    <div className="grid justify-center py-20">
                        <Lottie
                            animationData={loadingState}
                            loop={true}
                            className="lottie"
                        />
                    </div>
                ) : (
                    <Tabs tabs={tabs} />
                )}
            </div>
        </div>
    );
};

export default ActiveState;
