import {
  Activity,
  MouseSquare,
  PresentionChart,
  WalletMoney,
} from 'iconsax-react';
import { useEffect, useState } from 'react';
import androidi from '../../Images/android.svg';
import iosi from '../../Images/ios.svg';
import Profile from './Profile';
import CircularProgress from './CircularProgress';

const ProfileResults = ({ad}) => {


  
  const [AdsDetails, setAdsDetails] = useState<any>({});
  

  
  
  
  useEffect(() => {
    if (ad?.ads) {
        setAdsDetails(ad?.ads);
        
        
     
        console.log(AdsDetails)
    }
  }, [AdsDetails, ad]);

  const ads = [
    
    {
      name: 'Ad Spend',
      adsCount: AdsDetails?.fee || 0,
      icon: (
        <WalletMoney
          size="24"
          color="#C00010"
          className="bg-[#F6DADA] p-1 rounded-lg"
        />
      ),
    },
    {
      name: 'Click Rate',
      adsCount: ad?.analytics?.clickRate || 0,
      icon: (
        <MouseSquare
          size="24"
          color="#C00010"
          className="bg-[#F6DADA] p-1 rounded-lg"
        />
      ),
    },
    {
      name: 'Conversions',
      adsCount: ad?.analytics?.conversion || 0,
      icon: (
        <PresentionChart
          size="24"
          color="#C00010"
          className="bg-[#F6DADA] p-1 rounded-lg"
        />
      ),
    },
    {
      name: 'Impressions',
      adsCount:ad?.analytics?.impression || 0,
      icon: (
        <Activity
          size="24"
          color="#C00010"
          className="bg-[#F6DADA] p-1 rounded-lg"
        />
      ),
    },
  ];
  const ios = ad?.analytics?.deviceCounts?.iOS || 0;
  const android = ad?.analytics?.deviceCounts?.android || 0;
  const total = ios + android;
  const iosPercent = (ios / total) * 100;
  const androidPercent = (android / total) * 100;
  return (
    <div>
      <Profile ads={AdsDetails}/>
      
      <div>
        <h2 className="font-bold text-xl my-6">Overview</h2>
       
        <div className="flex flex-wrap gap-6">
          {ads?.map((ad, index) => {
            return (
              <div
                key={index}
                className="border border-[#D8C2BF] max-w-[310px] w-full rounded-xl p-3">
                <span> {ad?.icon}</span>
                <>
                  <p className="text-sm font-medium text-customBlack my-2">
                    {ad.name}
                  </p>
                  <span className="text-[22px] font-normal text-customBlack">
                    {ad.adsCount}
                  </span>
                </>
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <h2 className="font-bold text-xl mt-6 mb-3">Gender</h2>
        <div className="flex items-center gap-3">
        <CircularProgress
      percentage={
        ad?.analytics?.genderCounts
          ? (ad.analytics.genderCounts.male /
              (ad.analytics.genderCounts.male +
                ad.analytics.genderCounts.female)) *
            100
          : 50 
      }
      maleColor="#775652"
      femaleColor="#FFB4AB"
    />
          <div>
            <div className="flex items-center mb-5">
              <span className=" bg-[#775652] rounded-full border w-[10px] h-[10px] "></span>
              <div className="flex items-center gap-2">
                <p className="font-medium text-sm text-customBlack">Male</p>
                <p className="font-semibold text-base text-black">{ad?.analytics?.genderCounts?.male || 0}</p>
              </div>
            </div>
            <div className="flex items-center">
              <span className=" bg-[#FFB4AB] rounded-full border w-[10px] h-[10px]"></span>
              <div className="flex items-center gap-2">
                <p className="font-medium text-sm text-customBlack">Female</p>
                <p className="font-semibold text-base text-black">{ad?.analytics?.genderCounts?.female || 0}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2 className="font-bold text-xl my-6">Device Users</h2>
        <div className="flex justify-between w-full mb-3 mt-4 border-t py-4 border-[#0000004D]">
          <div className=" text-sm text-[#534341] flex items-center gap-2 font-medium">
            <img src={androidi} alt="icon" />
            <p>Android</p>
          </div>
          <div className=" text-sm text-[#534341] font-medium flex items-center gap-2">
            <p>ios</p>
            <img src={iosi} alt="icon" />
          </div>
        </div>
        <div className="relative bg-[#FAFAFB] w-full h-[25px]">
          <div
            className="left-0 bg-Tmred rounded-l-full h-full absolute "
            style={{ width: `${androidPercent || 0}%` }}></div>
          <div
            className="right-0 bg-[#FDDFA6] rounded-r-full  h-full absolute"
            style={{ width: `${iosPercent || 0}%` }}></div>
        </div>
        <div className="flex justify-between w-full mt-3">
          <span className="font-semibold text-base">{android || 0}</span>
          <span className="font-semibold text-base">{ios || 0}</span>
        </div>
      </div>
    </div>
  );
};
export default ProfileResults;
