// import react from 'react';
import image from '../../Images/EmptystateIMG.png';
const AppointmentEmptyState: React.FC = () => {
    return (
        <div className="flex flex-col justify-center items-center mt-28">
            <img src={image} alt="emptyImage" />
            <p className="text-center text-[#534341] font-medium pt-2">
                No appointments scheduled. <br /> You'll see all
                appointments listed here once they are scheduled.
            </p>
        </div>
    );
};

export default AppointmentEmptyState;