import React, { Fragment, useState, useEffect } from 'react';
import {
    addTimeToDate,
    compareDate,
    formatDatesDmy,
} from '../../../utils/helpers';
import { hospitalRoutes } from '../../../utils/routeNames';
import { Video } from 'iconsax-react';
import { AppointmentDetailsProps } from './appointment';
import MarkAppointmentModal from './PatientModals/MarkAppointmentModal';
import hospitalService from '../../../services/hospitalService';
import { toast, ToastContainer } from 'react-toastify';
import { FaCircleCheck } from 'react-icons/fa6';

const AppointmentCard = ({
    refetch,
    ...appointmentDetails
}: AppointmentDetailsProps) => {
    const [showMarkAppointmentModal, setShowMarkAppointmentModal] =
        useState<boolean>(false);
    const [showMarkAppointmentBtn, setShowMarkAppointmentBtn] =
        useState<boolean>(true);
    const [allowCall, setAllowCall] = useState<boolean>(false);
    const [appointmentStatus, setAppointmentStatus] = useState<string>('');
    const [confirmationStatus, setConfirmationStatus] =
        useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const startCall = (e: any) => {
        e.preventDefault();
        const newTab = window.open(
            hospitalRoutes.startCall + appointmentDetails._id,
            '_blank'
        );
        if (newTab) {
            newTab.focus();
        }
    };

    useEffect(() => {
        if (
            appointmentDetails.status === 'pending' &&
            appointmentDetails.confirmed
        ) {
            setConfirmationStatus(true);
        }
    }, [appointmentDetails.status, appointmentDetails.confirmed]);

    useEffect(() => {
        // console.log(appointmentDetails.mode === 'telemedicine')
        // console.log(appointmentDetails.appointmentDate, appointmentDetails.appointmentTime)
        // console.log(addTimeToDate(
        //     appointmentDetails.appointmentDate,
        //     appointmentDetails.appointmentTime
        // ));
        // console.log(compareDate(
        //     addTimeToDate(
        //         appointmentDetails.appointmentDate,
        //         appointmentDetails.appointmentTime
        //     )
        // ))
        if(appointmentDetails.mode === 'telemedicine' &&
            appointmentDetails.confirmed &&
            appointmentDetails.status === 'pending' &&
            compareDate(
                addTimeToDate(
                    appointmentDetails.appointmentDate,
                    appointmentDetails.appointmentTime
                )
            )
        ) {
            setAllowCall(true);
        } else {
            setAllowCall(false);
        }
                            
    }, [appointmentDetails.mode, appointmentDetails.confirmed, appointmentDetails.status, appointmentDetails.appointmentDate, appointmentDetails.appointmentTime])

    useEffect(() => {
        setAppointmentStatus(
            appointmentDetails.status === 'pending' &&
                appointmentDetails.confirmed
                ? 'Confirmed'
                : appointmentDetails.status
        );
        if (appointmentDetails.status === 'completed' && confirmationStatus) {
            setShowMarkAppointmentBtn(false);
        }
    }, [appointmentDetails.status, appointmentDetails.confirmed]);

    const closeModal = () => {
        setShowMarkAppointmentModal(false);
    };
    const handleMarkAppointment = () => {
        setShowMarkAppointmentModal(true);
    };
    const handleConfirm = () => {
        setIsSubmitting(true);
        hospitalService
            .markAppointmentCompleted(appointmentDetails._id) // TODO: change this to useMutation
            .then((res) => {
                toast.success('Appointment marked as completed successfully');
                refetch && refetch();
                setIsSubmitting(false);
                closeModal();
            })
            .catch((err) => {
                toast.error(err);
            });
    };

    useEffect(() => {
        let timeoutHandle;
        if (allowCall) {
            const dateString = String(appointmentDetails.appointmentDate).split(
                'T'
            )[0];
            const appointmentDate = new Date(
                `${dateString} ${appointmentDetails.appointmentTime}`
            );
            const appointmentTime = appointmentDate.getTime();
            const currentDate = new Date();
            const currentTime = currentDate.getTime();
            const timeDiff = appointmentTime - currentTime;
            if (
                appointmentDate.setHours(0, 0, 0, 0) ===
                currentDate.setHours(0, 0, 0, 0)
            ) {
                timeoutHandle = setTimeout(() => {
                    refetch && refetch();
                }, timeDiff);
            }
        }
        return () => {
            clearTimeout(timeoutHandle);
        };
    }, [allowCall, appointmentDetails.appointmentTime, appointmentDetails.appointmentDate]);

    return (
        <Fragment>
            <ToastContainer />
            <div
                className={`flex flex-col md:flex-row gap-3 justify-between py-8 ${
                    appointmentDetails.status === 'cancelled' &&
                    'opacity-25 hover:cursor-not-allowed'
                }`}
            >
                <div className="flex flex-col gap-4">
                    <PatientFormat
                        text1={'Date:'}
                        text2={`${formatDatesDmy(
                            appointmentDetails.appointmentDate
                        )}`}
                    />
                    <div className="flex gap-8">
                        <PatientFormat
                            text1="Time:"
                            text2={appointmentDetails.appointmentTime}
                        />
                    </div>
                    <div>
                        <p className=" text-[14px] font-normal text-[#201A19]">
                            Doctor Assigned:
                        </p>
                        <div className="flex items-center space-x-1 w-full gap-2">
                            {appointmentDetails?.doctor?.firstName ? (
                                <>
                                    <div className="w-[98px] h-[98px] flex">
                                        <img
                                            src={
                                                appointmentDetails?.doctor
                                                    ?.image
                                            }
                                            alt="Doctor"
                                            className="w-[80px] h-[70px] rounded-full"
                                        />
                                    </div>

                                    <div className="flex flex-col w-full gap-2">
                                        <h2 className="font-medium text-[#201A19] text-sm sm:text-[16px]">
                                            {
                                                appointmentDetails?.doctor
                                                    ?.firstName
                                            }{' '}
                                            {
                                                appointmentDetails?.doctor
                                                    ?.lastName
                                            }
                                        </h2>

                                        <p className="text-[#857371] text-[14px] font-medium">
                                            {appointmentDetails?.doctor
                                                ?.specialties
                                                ? appointmentDetails?.doctor
                                                      ?.specialties[0]?.name
                                                : ''}
                                        </p>
                                    </div>
                                </>
                            ) : (
                                <p className="text-[16px] font-medium text-[#201A19]">
                                    No Doctor Assigned
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="flex gap-2 flex-col">
                        <p className=" text-[14px] font-medium text-[#857371]">
                            Status:
                        </p>
                        <button
                            type="button"
                            className="w-[150px] text-gray-900 gap-3 bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-full text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 me-2 mb-2"
                        >
                            {appointmentDetails.status === 'completed' ? (
                                <FaCircleCheck style={{ color: '#009000' }} />
                            ) : (
                                <svg
                                    className="w-4 h-4 me-2 -ms-1 text-[#626890]"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
                                        fill="#534341"
                                    />
                                    <path
                                        d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z"
                                        fill="#534341"
                                    />
                                </svg>
                            )}
                            {appointmentStatus}
                        </button>
                    </div>
                </div>

                <div className="flex flex-col gap-4">
                    <div className="flex items-center gap-8 ">
                        <PatientFormat
                            text1={'Mode:'}
                            text2={appointmentDetails.mode}
                        />

                        {allowCall && (
                                <button
                                    onClick={startCall}
                                    className={`text-Tmred py-1 px-5 border rounded-lg inline-flex items-center text-[16px] font-medium ${
                                        allowCall
                                            ? 'text-Tmred border-Tmred'
                                            : ' border-[#1D1B201F] text-[#1d1b2048]'
                                    }`}
                                >
                                    <Video size={18} />
                                    <span className="ml-2">Start Call</span>
                                </button>
                            )}
                    </div>

                    {showMarkAppointmentBtn && confirmationStatus && (
                        <div>
                            <button
                                className={`flex gap-2 items-center border py-2 px-4 rounded-lg `}
                                onClick={handleMarkAppointment}
                                disabled={!confirmationStatus}
                            >
                                <span className="text-[16px]">
                                    Mark appointment as Completed
                                </span>
                            </button>
                        </div>
                    )}
                    <PatientFormat
                        text1="Reason:"
                        text2={appointmentDetails.reason}
                    />

                    <div>
                        <p className=" text-[14px] font-medium text-[#857371]">
                            Other Services
                        </p>
                        <div className="flex gap-2">
                            {appointmentDetails?.additionalServices?.map(
                                (service: any, index) => (
                                    <button
                                        key={index}
                                        type="button"
                                        className="text-xs font-medium text-[#1d1b205f] mr-1 mb-1 border-[1px] border-[#1d1b2048] py-[6px] px-3 rounded-lg"
                                    >
                                        {service.name || ''}
                                    </button>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <MarkAppointmentModal
                showModal={showMarkAppointmentModal}
                onClose={() => closeModal()}
                onSave={handleConfirm}
                buttonEnabled={!isSubmitting}
                patientFirstName={appointmentDetails?.patient?.firstName}
                patientLastName={appointmentDetails?.patient?.lastName}
                isSubmitting={isSubmitting}
            />
        </Fragment>
    );
};

export default AppointmentCard;
interface Props {
    text1: any;
    text2: any;
}

export const PatientFormat: React.FC<Props> = ({ text1, text2 }) => {
    return (
        <div className="flex flex-col gap-2 ">
            <p className=" text-[14px] font-medium text-[#857371]">{text1}</p>
            <p className="text-[16px] font-medium text-[#201A19] max-w-[302px]">
                {text2}
            </p>
        </div>
    );
};
