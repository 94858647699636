import { useState } from 'react';
import usePagination from '../../hooks/usePagination';
import Table from '../../constants/Table';
import { useNavigate } from 'react-router-dom';
import AppointmentEmptyState from './AppointmentEmptyState';
import { formatDateDmy } from '../../utils/helpers';
import { hospitalRoutes } from '../../utils/routeNames';
import image from '../../Images/userMgmt.png';

interface rowType {
    _id: string;
}
const AppointmentData = ({ appointment, searchQuery }: any) => {
    const [showPanel, setShowPanel] = useState<boolean>(false);
    const navigate = useNavigate();
    const filteredAppointments = appointment?.filter(
        (row: any) =>
            row.patient?.firstName
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
            row.patient?.lastName
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
            row.mode.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row.status.toLowerCase().includes(searchQuery.toLowerCase()) ||
            (row.doctor?.name &&
                row.doctor?.name
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase()))
    );

    //Pagination
    const { page, limit, Pagination } = usePagination({
        page: 1,
        limit: 10,
        total: appointment?.length,
    });

    const paginatedRows = filteredAppointments?.slice(
        (page - 1) * limit,
        page * limit
    );

    const handlePanel = () => {
        setShowPanel(!showPanel);
    };

    const appointmentTableHeaders = [
        {
            title: 'S/N',
            render: (row: any) => (
                <p>
                    {appointment?.findIndex(
                        (user: any) => user._id === row._id
                    ) + 1}
                </p>
            ),
        },
        {
            title: 'Patient Name',
            render: (row: any) => (
                <div className="flex items-center gap-x-[10px]">
                    {row.patient ? (
                        <div>
                            <p className="truncate w-32 text-lightBrown text-sm ">
                                {`${row.patient.firstName} ${row.patient.lastName}`}
                            </p>
                            <p className="truncate w-32 text-lightBrown100 text-xs">
                                {row.patientTmrNumber}
                            </p>
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
            ),
        },
        {
            title: '',
            render: (row: any) => (
                <div className="">
                    {row.patient ? (
                        <div>
                            <p className="w-14 text-lightBrown text-sm flex justify-end">
                                {row.confirmed && row.status === 'pending' ? (
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M15.3504 7.9999C15.3504 3.94061 12.0597 0.649902 8.00039 0.649902C3.9411 0.649902 0.650391 3.94061 0.650391 7.9999C0.650391 12.0592 3.9411 15.3499 8.00039 15.3499C12.0597 15.3499 15.3504 12.0592 15.3504 7.9999Z"
                                            stroke="#3A975F"
                                            strokeWidth="1.2"
                                            strokeMiterlimit="10"
                                        />
                                        <path
                                            d="M10.7037 6.23223L7.16816 9.76777L5.40039 8"
                                            stroke="#3A975F"
                                            strokeWidth="1.2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                ) : (
                                    ''
                                )}
                            </p>
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
            ),
        },
        {
            title: 'Assigned Doctor',
            render: (row: any) => (
                <div className="flex items-center gap-x-[10px]">
                    <div>
                        <p className="truncate w-32 text-lightBrown text-sm ">
                            {row?.doctor?.firstName} {row?.doctor?.lastName}
                        </p>
                        <p className="truncate w-32 text-lightBrown100 text-xs">
                            {row?.doctor?.specialties
                                ? row?.doctor?.specialties[0]?.name
                                : ''}
                        </p>
                    </div>
                </div>
            ),
        },
        { title: 'Mode', render: (row: any) => <p>{row.mode}</p> },
        {
            title: 'Status',
            render: (row: any) => (
                <div
                    className={`flex items-start justify-center p-2 rounded 
                        ${
                            row.status === 'pending'
                                ? 'bg-[#1D1B2014] text-lightBrown'
                                : ''
                        } 
                        ${
                            row.status === 'completed'
                                ? 'bg-[#E2EEE7] text-green-500'
                                : ''
                        }
                        ${
                            row.status === 'cancelled'
                                ? 'bg-[#FFDAD5] text-red-500'
                                : ''
                        }
                    `}
                >
                    <div
                        className={`w-[6px] h-[6px] rounded-full mr-2 mt-[6px] 
                            ${row.status === 'pending' ? 'bg-[#615f60]' : ''}
                            ${row.status === 'completed' ? 'bg-[#3A975F]' : ''}
                            ${row.status === 'cancelled' ? 'bg-[#C00010]' : ''}
                        }`}
                    />
                    <p className="text-xs">{row.status}</p>
                </div>
            ),
        },
        {
            title: 'Time',
            render: (row: any) => (
                <p className="text-xs text-[#534341]">{row.appointmentTime}</p>
            ),
        },
        {
            title: 'Appointment Date',
            render: (row: any) => <p> {formatDateDmy(row.appointmentDate)}</p>,
        },
        // {
        //     title: '',
        //     render: (row: any) => (
        //         <div

        //         >
        //             <img onClick={handlePanel}  src={icon} alt="" />
        //             <div className={`${showPanel ? 'bg-blue-500 right-20 w-[200px] absolute h-[232px]' : 'hidden' }`}>

        //             </div>
        //         </div>
        //     ),
        // },
    ];
    return (
        <div className="w-full pt-5">
            {!appointment?.length ? (
                <AppointmentEmptyState />
            ) : filteredAppointments?.length === 0 ? ( // Check if filteredAppointments is empty
                <>
                    {/* <Table headers={appointmentTableHeaders} showHead={true} /> */}
                    <div className="flex flex-col items-center justify-center text-center my-[70px] ">
                        <img src={image} alt="" />
                        <p className="mt-6 text-base font-medium font-[Montserrat]">
                            The search result is currently empty.
                        </p>
                    </div>
                </>
            ) : (
                <div className="gap-[2rem] block md:grid">
                    <div className="w-full grid gap-x-[30px] self-start md:mb-[0] mb-[2rem] overflow-x-auto scrollbar-hide">
                        <Table
                            rows={paginatedRows}
                            headers={appointmentTableHeaders}
                            showHead={true}
                            allowRowClick
                            onRowClick={(row: rowType) =>
                                navigate(
                                    `${hospitalRoutes.patientAppointment}${row._id}`
                                )
                            }
                        />
                        <Pagination />
                    </div>
                </div>
            )}
        </div>
    );
};

export default AppointmentData;
