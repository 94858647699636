import React from 'react';


type propType = {
    closeModal:any;
    leavePage:any;

};
const Exit: React.FC<propType> = ({
    closeModal,leavePage
}) => {
    
const handleExit = (e:any)=>{
    e.preventDefault()
    leavePage()
}    

const handleClose = (e:any)=>{
    e.preventDefault()
  closeModal()
}
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-[#2826265E] bg-opacity-75 z-50">

            <div className="bg-white p-6 rounded-[28px] w-fit m-2  sm:m-0 relative overflow-x-auto">
            <div className="flex items-center justify-between mb-8">
                    <div className="flex flex-col gap-2">
                        <h6 className="text-[22px] lg:text-[32px] font-medium text-lightBrown">
                        Leave Page Confirmation
                        </h6>
                        <p className='text-[14px] font-normal text-[#534341] flex-wrap max-w-[438px]'>
                        Are you sure you want to leave this page? Any information entered for the patient creation process will not be saved. </p>
                    </div>
                   
                </div>
                
                  <div className='flex items-center gap-4 justify-center'>
                    <button onClick={handleClose} className='text-[#201A19] flex-1  h-[40px] font-semibold text-[14px] justify-center mt-4'>
                    Continue process 
                    </button>
                    <button
                        onClick={handleExit}
                        className={`w-full h-[40px] flex-1 justify-center rounded-lg items-center font-semibold mt-4 transition-all duration-500 bg-Tmred text-white hover:cursor-pointer 
                                
                        } `}
                    >
                      Leave page
                    </button>

                </div>
            </div>
            
         
              
         
        </div>
    );
};
export default Exit;
