import React, { Fragment, useEffect, useState } from 'react';
import Layout from '../Layout';
// import avatar from '../../../Images/avatar.png';
import DoctorAvatar from '../../../reusable/DoctorAvatar';
import Passcode from '../../../reusable/Passcode';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useScreenTimeOut } from '../../../store/hospital/auth';

import ErrorState from '../../../reusable/ErrorState';
import BlockButton from '../../../reusable/BlockButton';
import HospitalAuthHeader from '../../../reusable/HospitalAuthHeader';
import { ToastContainer, toast } from 'react-toastify';
import { pharmacyRoutes } from '../../../utils/pharmacyRouteNames';
import { usePharmacyAuthStore } from '../../../store/pharmacy/auth';
import { pharmacyService } from '../../../services/pharmacyService';

interface propType {
    code: string;
}

type CodeConfirmProps = {
    code: string;
    confirmCode?: string;
};

const LockcodeConfirm: React.FC<propType> = ({ code }) => {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const pharmacyAuthStore = usePharmacyAuthStore((state) => state);
    const [confirmCode, setConfirmCode] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const navigate = useNavigate();
    const mutation = useMutation((data: CodeConfirmProps) =>
        pharmacyService.createPasscode(data, pharmacyAuthStore.token)
    );
    useEffect(() => {
        if (confirmCode.length === 4) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [confirmCode]);

    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            let response: any = mutation.data;
            toast.success(
                response.data.message || 'Passcode created successfully'
            );
            navigate(pharmacyRoutes.dashboard);
        }
    }, [mutation.isSuccess, mutation.data]);

    useEffect(() => {
        if (mutation.isError) {
            setIsLoading(false);
            let err: any = mutation.error;
            setErrorMessage(err.response.data.error.message);
        } else {
            setErrorMessage('');
        }
    }, [mutation.isError, mutation.error]);

    const submitLockcode = (e: any) => {
        e.preventDefault();
        if (code !== confirmCode) {
            setErrorMessage('Password and Confirm Password do not match');
            return;
        }
        setIsLoading(true);

        const passCodeData = {
            code,
        };
        mutation.mutate(passCodeData);
    };

    //logout function
    const clearStore = usePharmacyAuthStore((state) => state.clearToken);
    const timeOutStore = useScreenTimeOut();

    const handleLogout = () => {
        clearStore();
        sessionStorage.removeItem('pharmacy-auth-store'); // clear auth store
        timeOutStore.setIsTimeOut(false); // reset timeout store
        navigate(pharmacyRoutes.healthID, { replace: true });
    };

    return (
        <Fragment>
            <ToastContainer />
            <Layout>
                <div className=" flex items-center justify-center flex-col w-full  py-8 overflow-x-hidden ">
                    <div className="flex flex-col items-center">
                        <HospitalAuthHeader
                            logo={pharmacyAuthStore.pharmacy.logo}
                            text={pharmacyAuthStore.pharmacy.tagline}
                            title={pharmacyAuthStore.pharmacy.name}
                        />

                        <div className="flex flex-col justify-center items-center  md:mb-[80px]">
                            <div className="flex flex-col justify-center border border-[#00000033] bg-white rounded-[25px] px-[40px] sm:px-[80px] py-[40px] max-w-[592px] mb-10">
                                <div className="flex flex-col items-center">
                                    <h1 className=" font-semibold text-[32px] text-center ">
                                        Repeat Lock Passcode
                                    </h1>

                                    <p className="font-normal text-[#534341] text-[14px] text-center tracking-widest mb-4">
                                        Enter a unique passcode that you can
                                        remember
                                    </p>
                                    {errorMessage && (
                                        <ErrorState message={errorMessage} />
                                    )}
                                    <DoctorAvatar
                                        name={`${pharmacyAuthStore.user.firstName} ${pharmacyAuthStore.user.lastName}`}
                                        img={pharmacyAuthStore.user.image}
                                    />

                                    <p className=" text-[16px] tracking-wide text-center">
                                        Screen lock activates after{' '}
                                        <span className=" text-[22px] font-semibold">
                                            5mins
                                        </span>{' '}
                                        of inactivity
                                    </p>
                                    <form onSubmit={submitLockcode}>
                                        <Passcode
                                            passcode={confirmCode}
                                            setPasscode={setConfirmCode}
                                        />
                                        <BlockButton
                                            text="Proceed"
                                            disabled={buttonDisabled}
                                            type="submit"
                                            isLoading={isLoading}
                                        />
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center items-center ">
                            <button
                                type="button"
                                onClick={handleLogout}
                                className={`text-sm font-semibold flex justify-center items-center 
                            text-Tmred
                            `}
                            >
                                Logout
                            </button>
                        </div>
                    </div>
                </div>
            </Layout>
        </Fragment>
    );
};

export default LockcodeConfirm;
