import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';

interface TimePickerElementProps {
    label?: string;
    value: dayjs.Dayjs | null;
    onChange: (date: dayjs.Dayjs | null) => void;
}

const TimePickerElement: React.FC<TimePickerElementProps> = ({
    label,
    value,
    onChange,
}) => {
    return (
        <div className="w-full">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker label={label} value={value} onChange={onChange} />
            </LocalizationProvider>
        </div>
    );
};

export default TimePickerElement;
