import React, { ReactNode, MouseEvent } from 'react';

type ModalProps = {
  isVisible: boolean;
  onClose: any;
  children: ReactNode;
  buttonEnabled: boolean;
  setButton: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: any;
  closeText: string;
  saveText: string;
  showCloseButtonBorder?: boolean;
  isLoading?: boolean;
  loadingText?: string;
};

const Modal: React.FC<ModalProps> = ({
  isVisible,
  onClose,
  children,
  buttonEnabled,
  setButton,
  onSave,
  closeText,
  saveText,
  showCloseButtonBorder = false,
  isLoading = false,
  loadingText = 'Loading...',
}) => {
  if (!isVisible) return null;

  const handleClose = (e: MouseEvent<HTMLDivElement>) => {
    if ((e.target as HTMLDivElement).id === 'wrapper') {
      onClose();
    }
  };

  const handleSave = async () => {
    setButton(true);
    await onSave();
    setButton(false);
  };

  return (
    <div
      className="fixed z-10 inset-0 flex justify-center items-center bg-[#2826265E] bg-opacity-20"
      id="wrapper"
      onClick={handleClose}
    >
      <div className="max-w-[560px] sm:max-w-[744px] mx-auto flex flex-col">
        <div className="bg-white p-[24px] rounded-[28px] border max-h-[80vh] overflow-y-auto">
          {children}
          <div className="mb-2 mt-14 flex flex-col sm:flex-row gap-6 ml-auto justify-end">
            <button
              className={`w-auto h-[40px] text-[14px] flex p-2 sm:px-3 rounded-lg items-center text-center justify-center text-[#C00010] ${
                showCloseButtonBorder
                  ? 'border hover:border-[#C00010] hover:border-2 rounded-lg border-[#C00010]'
                  : 'hover:border hover:border-[#C00010]'
              }`}
              onClick={() => onClose()}
            >
              {closeText}
            </button>
            <button
              className={`w-auto h-[40px] p-2 sm:px-4 text-[14px] flex justify-center rounded-lg items-center text-[#9d9b9b] font-semibold transition-all duration-500 ${
                buttonEnabled
                  ? 'bg-Tmred text-white hover:cursor-pointer '
                  : 'bg-[#1D1B201F] '
              } `}
              onClick={handleSave}
              disabled={!buttonEnabled || isLoading}
            >
              {isLoading ? loadingText : saveText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
