import React, { useEffect, useState } from 'react';

import Filter from '../../../Images/filtericon.svg';
import usePagination from '../../../hooks/usePagination';
import Table from '../../../constants/Table';
import { useNavigate } from 'react-router-dom';
import empty from '../../../Images/no-transaction.svg';
import search from '../../../Images/Leading-icon.png';
import Image from '../../../Images/noEvent.png';
import FilterBill from './FilterBill';
import { useQuery } from 'react-query';

import Lottie from 'lottie-react';
import loadingState from '../../../Images/loading.json';
import {
    formatDateDmy,
    standardAmountFormat,
    TimeComponent,
} from '../../../utils/helpers';
import back from '../../../Images/back.png';
import { ArrowUp, ArrowDown } from 'iconsax-react';
import { usePharmacyAuthStore } from '../../../store/pharmacy/auth';
import { pharmacyService } from '../../../services/pharmacyService';

const TransactionHistory = () => {
    const [emptyState, setEmptyState] = useState(true);
    const [showModal, setShowModal] = React.useState(false);
    const [buttonEnabled, setButton] = useState(false);
    const pharmacyStore = usePharmacyAuthStore((state) => state.pharmacy);
    const [walletType, setWalletType] = useState('');
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };
    console.log(window.location.href);
    useEffect(() => {
        // if (hospitalStore.userType === 'superadmin') {
        setWalletType('pharmacy');
        // } else {
        //     setWalletType('personal');
        // }
    }, []);

    //API CALL
    const { data, isLoading, isError } = useQuery(
        ['transactions', walletType],
        () => pharmacyService.getWalletTransaction(walletType)
    );
    const transactions = data?.transaction?.slice(0, 10);

    useEffect(() => {
        console.log(transactions);
        if (transactions && transactions.length === 0) {
            setEmptyState(true);
        } else {
            setEmptyState(false);
        }
    }, [transactions]);

    //Pagination
    const { page, limit, Pagination } = usePagination({
        page: 1,
        limit: 10,
        total: transactions?.length,
    });

    const paginatedRows = transactions?.slice((page - 1) * limit, page * limit);

    const closeModal = () => {
        setShowModal(false);
    };
    const handleSubmit = (e: React.FormEvent) => {
        console.log('Form submitted!');
        setShowModal(false);
        setButton(false);
    };
    const setModal = () => {
        setShowModal(true);
    };
    const [selectedOption, setSelectedOption] = useState(null);
    const handleOptionChange = (option: any) => {
        setSelectedOption(option);
    };
    const upcomingTableHeaders = [
        {
            title: 'Type',
            render: (row: any) => (
                <div className="py-[6px] text-[12px] flex items-center gap-2 font-medium text-light_gray text-center">
                    <img
                        src={row.type}
                        alt=""
                        className="bg-gray-200 rounded-full"
                    />

                    <p className="font-medium !text-[#201A19] text-sm  flex items-center gap-3">
                        {row.transactionType === 'credit' ? (
                            <div className="flex h-10 w-10 shrink-0 grow-0 items-center justify-center rounded-full bg-[#DDDDDD]">
                                <ArrowDown size={18} />
                            </div>
                        ) : (
                            <div className="flex h-10 w-10 shrink-0 grow-0 items-center justify-center rounded-full bg-[#DDDDDD] ">
                                <ArrowUp size={18} />
                            </div>
                        )}
                        {row.transactionType}
                    </p>
                </div>
            ),
        },
        {
            title: 'Sender/Receiver',
            render: (row: any) => (
                <p className=" font-medium text-deep_black text-sm text-center" style={{ width: '130px', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                    {row.transactionDetails?.senderName || '-'}
                </p>
            ),
        },
        {
            title: 'Transaction Id',
            render: (row: any) => (
                <div className="flex items-center gap-x-[10px]">
                    <div>
                        <p className="!text-lightBrown200 text-[12px] font-medium">
                            {row.transactionId}
                        </p>
                    </div>
                </div>
            ),
        },
        {
            title: 'Narration',
            render: (row: any) => (
                <p className="!text-lightBrown text-xs font-medium " style={{ width: '130px', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                    {row.narration}
                </p>
            ),
        },
        // {
        //     title: 'Receiver',
        //     render: (row: any) => (
        //         <div className="flex items-center gap-x-[10px]">
        //             <img
        //                 className="object-contain h-[40px] w-[40px] rounded-full text-sm font-medium !text-lightBrown"
        //                 style={{
        //                     boxShadow:
        //                         'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
        //                 }}
        //                 src={row.reciever}
        //                 alt=""
        //             />{' '}
        //             <div>
        //                 <p className="truncate w-24 font-medium text-deep_black text-sm ">
        //                     {row.to}
        //                 </p>
        //                 <p className="!text-lightBrown200 text-[12px] font-medium">
        //                     {row.receiverID}
        //                 </p>
        //             </div>
        //         </div>
        //     ),
        // },

        {
            title: 'Amount',
            render: (row: any) => (
                <div>
                    <p style={{ width: '100px', whiteSpace: 'normal', wordWrap: 'break-word' }}
                        className={`${
                            row.transactionType === 'credit'
                                ? '!text-[#3A975F]'
                                : '!text-[#201A19]'
                        } text-xs font-medium`}
                    >
                        {standardAmountFormat(
                            row.amount,
                            pharmacyStore.currency
                        )}
                    </p>
                </div>
            ),
        },

        {
            title: 'Date',
            render: (row: any) => (
                <p className="capitalize font-medium text-lightBrown text-xs">
                    {' '}
                    {formatDateDmy(row.updatedAt)}
                </p>
            ),
        },
        {
            title: 'Time',
            render: (row: any) => (
                <p className="capitalize font-medium text-lightBrown text-xs">
                    {' '}
                    <TimeComponent time={row.createdAt} />
                </p>
            ),
        },
    ];
    if (isLoading) {
        return (
            <div className="flex justify-center items-center py-32">
                {' '}
                <Lottie
                    animationData={loadingState}
                    loop={true}
                    className="lottie"
                />
            </div>
        );
    }
    if (isError) {
        return <div>Error fetching invoice</div>;
    }
    return (
        <div className="w-full flex flex-col md:px-8 py-4 px-2 ">
            <div className="flex gap-2 items-center ">
                <button onClick={goBack}>
                    <img src={back} alt="" />
                </button>
                <h3 className="font-bold sm:text-[28px] text-[20px]">
                    Transaction History
                </h3>
            </div>
            <div className="mt-6 px-4 py-4 rounded-2xl flex-1  bg-white md:overflow-hidden">
                <div className="flex justify-between sm:flex-row flex-col items-center mb-4">
                    <h2 className="text-black font-bold text-md md:text-xl">
                        All Wallet Transactions
                    </h2>

                    <div>
                        <div className="flex  justify-between">
                            <div className=" flex items-center gap-2 border bg-[#F5F6F7] border-[#F5F6F7] rounded-[28px] sm:max-w-[328px]">
                                <img src={search} alt="search" />
                                <input
                                    type="text"
                                    placeholder="Search"
                                    //   value={searchQuery}
                                    //   onChange={(e) => setSearchQuery(e.target.value)}
                                    className="w-full font-medium text-[14px] bg-transparent text-[#534341] border-transparent outline-none focus:ring-0"
                                />
                            </div>
                            <div
                                className="flex mr-4 ml-4 items-center"
                                onClick={setModal}
                            >
                                <img
                                    src={Filter}
                                    alt="filterIcon"
                                    className="cursor-pointer h-[24px] w-[24px]"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {emptyState ? (
                    <div className="flex flex-col justify-center items-center my-20">
                        <img src={Image} alt="empty" />
                        <p className="mt-4 text-sm font-medium text-dark100">
                            No Recent Wallet transaction
                        </p>
                    </div>
                ) : (
                    <div className="mt-[13px] border-t-[1px] flex flex-col px-4 py-3 ">
                        {!emptyState ? (
                            <>
                                <Table
                                    rows={paginatedRows}
                                    headers={upcomingTableHeaders}
                                    allowRowClick
                                    showHead={true}
                                    // onRowClick={() =>
                                    //     navigate(`/hospital/billing/bill-history`)
                                    // }
                                />
                                <Pagination />
                            </>
                        ) : (
                            <>
                                <img src={empty} alt="" />
                            </>
                        )}
                    </div>
                )}

                <FilterBill
                    showModal={showModal}
                    closeModal={closeModal}
                    handleSubmit={handleSubmit}
                    buttonEnabled={buttonEnabled}
                    setButton={setButton}
                    selectedOption={selectedOption}
                    handleOptionChange={handleOptionChange}
                />
            </div>
        </div>
    );
};
export default TransactionHistory;
