import React from 'react'
import { Helmet } from 'react-helmet'
import DashboardLayout from '../../Dashboard/DashboardLayout'
import EditStaff from '../../../../components/Ambulance/FleetMgmt/EditStaff'

function EditStaf() {
  return (
    <DashboardLayout>
    <Helmet>
        <title>
           TMR | Ambulance Staff Edit
        </title>
    </Helmet>
    <div className="">
       
       <EditStaff/>
    </div>
</DashboardLayout>
  )
}

export default EditStaf