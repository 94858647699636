import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { TextField } from '@mui/material';

interface RadioButtonsGroupProps {
    label: string;
    options: { value: string; label: string }[];
    onChange: (value: string) => void;
    value: string;
    customOptionChecked?: boolean; 
    customDuration?:any;
    handleCustomDurationChange?:any;
    isCustomSelected?:any
}

const RadioButtonsGroup: React.FC<RadioButtonsGroupProps> = ({
    label,
    options,
    onChange,
    value,
    customOptionChecked,
    customDuration,
    handleCustomDurationChange,
    isCustomSelected
}) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange((event.target as HTMLInputElement).value);
    };

    return (
        <FormControl>
            <RadioGroup
                name="controlled-radio-buttons-group"
                row
                value={value}
                onChange={handleChange}
                color="primary"
                style={{
                    display:'flex',
                }}
                
            >
                {options.map((option) => (
                    <div
                        key={option.value}
                        style={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '8px',
                            paddingLeft: '0px',
                            marginRight: '12px',
                            marginBottom: '12px',
                            textAlign: 'center',
                        }}
                    >
                        <FormControlLabel
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                            labelPlacement="start"
                            checked={
                                option.value === value ||
                                (customOptionChecked && option.value === 'custom')
                            }
                        />
                    </div>

                ))}
                     <TextField
                            variant="outlined"
                            type="number"
                            name="customDuration"
                            value={customDuration}
                            label="Custom Time (mins)"
                            
                            onChange={handleCustomDurationChange}
                            disabled={!isCustomSelected}
                            className=""
                            id="customDurationInput"
                        />
            </RadioGroup>
        </FormControl>
    );
};

export default RadioButtonsGroup;
