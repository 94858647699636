import React from 'react';
import DashboardLayout from '../Dashboard/DashboardLayout';
import CreateBills from '../../../components/Billing/CreateBills';
import {Helmet} from 'react-helmet'


const CreateBill = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Create bill
                </title>
            </Helmet>
            <div className="">
                <CreateBills />
            </div>
        </DashboardLayout>
    );
};

export default CreateBill;
