import React from 'react';

const LogoutModal = ({
  isVisible,
  onClose,
  children,
  buttonEnabled,
  setButton,
  onSave,
  closeText,
  saveText,
  showCloseButtonBorder,
}) => {
  if (!isVisible) return null;

  const handleClose = (e) => {
    if (e.target.id === 'wrapper') {
      onClose();
    }
  };

  return (
    <div
      className={`fixed z-10 inset-0 flex justify-center items-center bg-[#27272756] opacity-100 ${
        isVisible ? 'opacity-100' : 'opacity-0'
      }transition-opacity`}
      id="wrapper"
      onClick={handleClose}
    >
      <div className="w-[312px] sm:max-w-[744px] mx-auto flex flex-col">
        <div className="bg-white opacity-100 p-[24px] rounded-[28px] border max-h-[80vh] overflow-y-auto">
          {children}
          <div className="mb-2 mt-[24px] flex flex-col sm:flex-row gap-6 ml-auto justify-end">
            <button
              className={`w-auto h-[40px] text-[14px] flex p-2 sm:px-3 rounded-lg items-center text-center justify-center text-[#C00010] ${
                showCloseButtonBorder
                  ? 'border hover:border-[#C00010] hover:border-2 rounded-lg border-[#C00010]'
                  : 'hover:border hover:border-[#C00010]'
              }`}
              onClick={() => onClose()}
            >
                <p className='text-[14px] font-medium text-black'>
                {closeText}

                </p>

            </button>
            <button
              className={`w-auto h-[40px] p-2 sm:px-4 text-[14px] flex justify-center rounded-lg items-center text-[#9d9b9b] font-semibold  hover:cursor-not-allowed transition-all duration-500  ${
                buttonEnabled
                  ? 'bg-Tmred text-white hover:cursor-pointer '
                  : 'bg-[#1D1B201F] '
              } `}
              onClick={onSave}
            >
                <p className='text-[14px] font-medium text-white'>
                {saveText}

                </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;
