import React from 'react'
import ActiveState from './ActiveState';
import { pharmacyRoutes } from '../../../utils/pharmacyRouteNames';
import { useNavigate } from 'react-router-dom';
import { Add } from 'iconsax-react';

const Orders = () => {
    const navigate= useNavigate()
  return (
    <div className=' flex flex-col md:px-8 py-6 px-2 w-full'>
        <div className="flex justify-between items-center">
                <p className="font-bold sm:text-[28px] text-[20px]">
                  Orders
                </p>
                <button
                      onClick={()=>navigate(pharmacyRoutes.createOrder)}
                      className={`w-[181px] h-[40px] p-2 sm:px-4 text-[16px]  flex justify-center rounded-lg items-center font-semibold gap-2  transition-all duration-500 bg-Tmred text-white hover:cursor-pointer `}
                    
                     
                  >
                   <Add size="24" color="#FFFFFF"/>
                      Create Order
                  </button>
            </div>
            
      <ActiveState />
    </div>
  )
}

export default Orders
