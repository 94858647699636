
import React from 'react';
import search from '../../Images/Leading-icon.png';
import Modal from '../../reusable/Modal';
const SpecialtyModal = ({
    showModal,
    onClose,
    onSave,
    buttonEnabled3,
    setButton3,
    setFormData3,
    formData3,
    filteredSpecializations,
    handleSpecializationChange,
}) => {
    return (
        <Modal
            isVisible={showModal}
            onClose={onClose}
            onSave={() => onSave(formData3.selectedSpecialization?._id)}
            buttonEnabled={buttonEnabled3}
            setButton={setButton3}
            saveText="Add specialty "
            closeText="Cancel"
            showCloseButtonBorder={true}
        >
            <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2 ">
                <h2 className="font-semibold text-2xl">
                Select Specialty
                </h2>
                <div className=" lg:min-h-[376px] lg:max-w-[482px]  rounded-2xl flex flex-col sm:flex-row gap-6 items-center">
                   
                    <div className="py-4 px-2 lg:min-h-[300px] lg:min-w-[343px] bg-[#fff] rounded-2xl flex items-center flex-col">
                        <div className="mb-5 flex items-center gap-2 rounded w-full bg-[#F8F8F8]">
                            <img src={search} alt="search" />
                            <input
                                type="text"
                                placeholder="Search specialty "
                                value={formData3.specialtySearch}
                                onChange={handleSpecializationChange}
                                className="w-full font-normal text-[16px] bg-transparent text-[#534341] border-transparent outline-none focus:ring-0"
                            />
                        </div>
                        <div className="max-h-[300px] w-full overflow-y-auto bg-[#F8F8F8] pt-3">
                            {filteredSpecializations.length === 0 ? (
                                <p className="text-center text-gray-500 py-2">
                                    Search does not match
                                </p>
                            ) :(filteredSpecializations.map((row, index) => (
                                <div
                                    key={index}
                                    className={`flex py-2 px-4 items-center gap-8 cursor-pointer ${
                                        formData3.selectedSpecialty &&
                                        formData3.selectedSpecialty._id ===
                                            row._id
                                            ? 'bg-[#EDE0DE] rounded-tl-2xl rounded-bl-2xl'
                                            : 'hover:bg-[#EDE0DE] '
                                    } justify-between mb-4 font-normal text-[12px] text-[#201A19] `}
                                    onClick={() => {
                                        setFormData3((prevData: any) => ({
                                            ...prevData,
                                            selectedSpecialty:
                                                prevData.selectedSpecialty &&
                                                prevData.selectedSpecialty
                                                    ._id ===
                                                    row._id
                                                    ? null
                                                    : row,
                                        }));
                                        setButton3(true);
                                    }}
                                    style={{ lineHeight: '24px' }}
                                >
                                    <div className="flex flex-col">
                                        <div className="flex items-center ">
                                            
                                            <div>
                                                <p className="truncate w-[226px] font-normal text-deep_black text-sm ">
                                               {row.name} 
                                                </p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            )))}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SpecialtyModal;
