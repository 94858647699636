import { useEffect, useState, memo } from 'react';
import medicationImage from '../../..//Images/Medication.png';
import BlockButton from '../../../reusable/BlockButton';
import { Add } from 'iconsax-react';
import { AppointmentDetailsProps } from './appointment';
import { addTimeToDate, compareDate } from '../../../utils/helpers';
import MedicationModal from '../../../reusable/MedicationModal';
import MedicationCard from './MedicationCard';
import patientService from '../../../services/patientService';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import Lottie from 'lottie-react';
import loadingState from '../../../Images/loading.json';
import ViewDrugModal from './PatientModals/ViewDrugModal';

type MedicationReportProps = {
    [key: string]: any;
}

type MedicationProps = {
    [key: string]: any;
}[];

const MedicalReport = ({ ...appointmentDetails }: AppointmentDetailsProps) => {
    const [isLoading, setIsLoading] = useState(false);

    const [canCreateMedicalReport, setCanCreateMedicalReport] = useState<boolean>(false);
    const [medicalReport, setMedicalReport] = useState<MedicationReportProps>({});
    const [medications, setMedications] = useState<MedicationProps>([]);
    const [isMedicationModalOpen, setIsMedicationModalOpen] = useState<boolean>(false);
    const [isMedicationCardOpen, setIsMedicationCardOpen] = useState<boolean>(false);
    const [viewDrugModal, setViewDrugModal] = useState<boolean>(false);
    const [specificDrug, setSpecificDrug] = useState([])
    const [buttonEnabled, setIsButtonEnabled] = useState<boolean>(false);
    const [isLoadingMedication, setIsLoadingMedication] = useState<boolean>(false);
    const [formData, setFormData] = useState({
        observation: '',
        treatment: '',
        medicalVerdict: '',
    });
    const closeModal = () => {
        setIsMedicationModalOpen(false);
    };
    const closeCardModal = () => {
        setIsMedicationCardOpen(false);
    };
    const closeViewDrugModal = () => {
        setViewDrugModal(false);
    };

    useEffect(() => {
        if (appointmentDetails?.appointmentDate) {
            const canCreateMedicalReportBool = appointmentDetails.status === 'pending' &&
                appointmentDetails.confirmed === true &&
                compareDate( addTimeToDate(appointmentDetails.appointmentDate, appointmentDetails.appointmentTime) )
            setCanCreateMedicalReport(canCreateMedicalReportBool);
        }
    }, [appointmentDetails]);

    const { data, refetch } = useQuery(['medications', appointmentDetails.patientTmrNumber, appointmentDetails._id ] , () =>
        patientService.getAllMedications(appointmentDetails.patientTmrNumber, appointmentDetails._id)
    )

    useEffect(() => {
        if(data){
            setMedicalReport(data.medicalReport);
            setMedications(data.medications);
            setIsLoadingMedication(false);
        }
    }, [data])

    const mutation = useMutation((data: any) =>
        patientService.createMedicalReport(data)
    );

    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            const response = mutation.data;
            const message = response.data.message;
            toast.success(message);
            setFormData({
                observation: '',
                treatment: '',
                medicalVerdict: '',
            });
            // make API request to get the new medical report and medication
            refetch();
            setTimeout(() => { // TODO: add a clean up function
                toast.dismiss();
            }, 3000);
        }
    }, [mutation.isSuccess]);

    useEffect(() => {
        if (mutation.isError) {
            setIsLoading(false);
            let err: any = mutation.error;
            toast.error(err.response?.data?.error?.message);
        }
    }, [mutation.isError, mutation.error]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        setIsButtonEnabled(false);
        setIsLoading(true);
        const dataToSend = {
            patientTmrNumber: appointmentDetails.patientTmrNumber,
            appointmentId: appointmentDetails._id, 
            observation: formData.observation,
            treatment: formData.treatment,
            verdicts: formData.medicalVerdict,
        };
        mutation.mutate(dataToSend);
    };

    useEffect(() => {
        if (
            formData.observation !== '' &&
            formData.treatment !== '' &&
            formData.medicalVerdict !== ''
        ) {
            setIsButtonEnabled(true);
        } else {
            setIsButtonEnabled(false);
        }
    }, [formData.medicalVerdict, formData.observation, formData.treatment]);

    const handleChangeTextarea = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleViewDrug = (drug: any) => {
        setSpecificDrug(drug)
        setViewDrugModal(true)
    }

    return (
        <div className="w-full p-4 bg-[#fff] h-fit rounded-[20px]">
            <div className="bg-lightTmrRed rounded-[20px] p-4 mb-4">
                <p className="font-normal sm:text-[22px] text-[18px] text-[#201A19]">
                    Medical Report
                </p>
            </div>
            <div
                className={`flex justify-between flex-col text-left w-full`}
            >
                <div>
                    <h3 className="font-semibold sm:text-[24px] text-[18px] text-[#201A19]">
                        Diagnosis
                    </h3>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-col md:gap-3 justify-between pb-4">
                        <textarea
                            name="observation"
                            placeholder="Observation"
                            required={true}
                            value={ medicalReport?.diagnosis?.observation || formData.observation }
                            onChange={handleChangeTextarea}
                            disabled={appointmentDetails.status !== 'pending' || medicalReport?.id || appointmentDetails.confirmed !== true }
                            className="w-full border h-[112px] border-[#857371] p-4 rounded-[8px] text-[16px] tracking-wider my-2"
                        />

                        <textarea
                            name="treatment"
                            placeholder="Treatment"
                            required={true}
                            value={ medicalReport?.diagnosis?.treatment || formData.treatment }
                            onChange={handleChangeTextarea}
                            disabled={appointmentDetails.status !== 'pending' || medicalReport?.id || appointmentDetails.confirmed !== true }
                            className="w-full border h-[112px] border-[#857371] p-4 rounded-[8px] text-[16px] tracking-wider my-2"
                        />

                        <textarea
                            name="medicalVerdict"
                            placeholder="Medical Verdict"
                            required={true}
                            value={ medicalReport?.diagnosis?.verdicts || formData.medicalVerdict }
                            onChange={handleChangeTextarea}
                            disabled={appointmentDetails.status !== 'pending' || medicalReport?.id || appointmentDetails.confirmed !== true}
                            className="w-full border h-[50px] border-[#857371] p-4 rounded-[8px] text-[16px] tracking-wider my-2"
                        />
                    </div>
                    <BlockButton
                        text="Save"
                        disabled={!buttonEnabled || !canCreateMedicalReport}
                        type="submit"
                        isLoading={isLoading}
                        className={`font-light ${
                            isLoading ||
                            !canCreateMedicalReport ||
                            !buttonEnabled
                                ? 'bg-[#1D1B201F] text-[#201A19] hover:cursor-not-allowed'
                                : 'bg-[#C00010] text-white'
                        } rounded-[8px] px-3 sm:pr-[24px] sm:px-[16px] sm:py-[10px] py-2 text-[16px] w-full`}
                    />
                </form>
                {appointmentDetails.status !== 'cancelled' && (
                    <>
                        <div className="flex items-center justify-between w-full py-4">
                            <h3 className="font-semibold sm:text-[24px] text-[18px] text-[#201A19]">
                                Medication
                            </h3>
                            <button
                                className={`flex items-center gap-2 
                                ${
                                    appointmentDetails.confirmed !== true  && 'opacity-25 pointer-events-none'
                                }
                                `}
                                onClick={() => setIsMedicationModalOpen(true)}
                            >
                                <Add size="24" color="#C00010" />
                                <span className="text-[#C00010] font-normal text-[16px]">
                                    Add
                                </span>
                            </button>
                        </div>
                        <div>
                            { isLoadingMedication ? (
                                <div className="flex justify-center items-center py-32">
                                    <Lottie
                                        animationData={loadingState}
                                        loop={true}
                                        className="lottie"
                                    />
                                </div>
                            ) : medications.length > 0 ? (
                                medications.map((medicationItem, index) => (
                                    <div
                                        key={index}
                                        className="flex items-center justify-between w-full max-h-[304px] overflow-y-auto mb-4"
                                    >
                                        <div className="flex items-center gap-3">
                                            <div className="w-[77px] h-[72px] bg-[#FFDAD5] flex items-center justify-center rounded-lg">
                                                <img
                                                    src={medicationImage}
                                                    alt="medication"
                                                />
                                            </div>
                                            <div className="flex flex-col gap-3">
                                                <p className="font-semibold text-[16px]">
                                                    {
                                                        medicationItem
                                                            .prescriptions
                                                            .drugName
                                                    }
                                                </p>
                                                <p className="truncate w-[143px] font-normal text-[12px]">
                                                    Dosage:{' '}
                                                    {
                                                        medicationItem
                                                            .prescriptions
                                                            .dosage
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        <button
                                            onClick={ () => handleViewDrug(medicationItem)}
                                            className="text-Tmred font-semibold text-[12px] text-center"
                                        >
                                            View
                                        </button>
                                    </div>
                                ))
                            ) : (
                                <p className="font-normal text-[16px] text-center">
                                    No medication added
                                </p>
                            )}
                        </div>
                    </>
                )}
            </div>
                {isMedicationModalOpen && (
                    <MedicationModal
                        closeModal={closeModal}
                        appointmentDetails={appointmentDetails}
                        medicalReportId={medicalReport?.id}
                        refetch = {refetch}
                    />
                )}
                {isMedicationCardOpen && (
                    <MedicationCard closeModal={closeCardModal} />
                )}
                {viewDrugModal && (
                    <ViewDrugModal closeModal={closeViewDrugModal} medications={specificDrug} />
                )}
        </div>
    );
};

export default memo(MedicalReport);
