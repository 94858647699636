import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    // MenuItem,
    // Select,
    TextField,
} from '@mui/material';
import AppointmentSchedulling from './AppointmentSchedulling';
import Services from './Services';
import Media from './Media';
import { useHospitalAuthStore } from '../../../store/hospital/auth';
import SelectElement from '../../../reusable/Forms/Select';
import { IoIosArrowBack, IoIosClose } from 'react-icons/io';
import hospitalService from '../../../services/hospitalService';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logger from '../../../utils/logger';
import info from '../../../Images/info-circle.svg';
import { hospitalRoutes } from '../../../utils/routeNames';
import { resolveCountryCode } from '../../../utils/countryState';

interface iFormData {
    Hospital_Name: string;
    Country: string;
    State: string;
    City: string;
    Email: string;
    Address: string;
    Phone_Number: string;
    coordinates: string;
    Specialization: any;
    licenseNumber: string;
    tagLine: string;
    countryCode: any;
    countryStates: any;
    country: any;
}

const Hospital = () => {
    const hospitalAuthStore = useHospitalAuthStore((state) => state.hospital);
    const Tabs = ['Profile', 'Appointment', 'Services', 'Media'];
    const [activeTab, setActiveTab] = useState('Profile');
    const [buttonEnabled, setButton] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [countryCode, setCountryCode] = useState<string>('NG');
    const defaultCountry = resolveCountryCode('NG');
    const mutation = useMutation((data: any) =>
        hospitalService.editHospitalProfile(data)
    );
    const hospitalAccountStore = useHospitalAuthStore((state) => state);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const navigate = useNavigate();
    const [formData, setFormData] = useState<iFormData>({
        Hospital_Name: hospitalAuthStore.name,
        Email: hospitalAuthStore.email,
        Country: hospitalAuthStore.country,
        Phone_Number: hospitalAuthStore.phoneNumber,
        State: hospitalAuthStore.state,
        City: hospitalAuthStore.city,
        Address: hospitalAuthStore.address,
        Specialization:
            hospitalAuthStore.metadata.specialties.length > 0
                ? hospitalAuthStore.metadata.specialties
                : [],
        coordinates: hospitalAuthStore.location.coordinates.join(','),
        licenseNumber: hospitalAuthStore.licenseNumber,
        tagLine: hospitalAuthStore.tagline,
        countryCode,
        countryStates: defaultCountry?.states || [],
        country: defaultCountry?.name || '',
    });
    console.log(formData.State);
    const handleTab = (tab) => {
        setActiveTab(tab);
    };
    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            console.log('success', mutation.data);
            let payload: any = mutation.data;
            hospitalAccountStore.updateHospital(payload.data.payload.data.hospital);
            const data = payload.data;
            logger(data.message);
            // Show success message using Toastify
            toast.success('Profile updated successfully');
            setTimeout(() => {
                toast.dismiss(); // Dismiss the toast after 3000 milliseconds
                navigate(hospitalRoutes.hospital);
            }, 2000);
        }
    }, [mutation.isSuccess, mutation.data]);

    useEffect(() => {
        if (mutation.isError) {
            setIsLoading(false);
            let err: any = mutation.error;
            setErrorMessage(err.response.data.error.message);
            toast.error(err.response.data.error.message);
        } else {
            setErrorMessage('');
        }
    }, [mutation.isError, mutation.error]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setButton(true);
    };
    const handlesubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // console.log(formData);
        const requestBody = {
            tagline: formData.tagLine,
            phoneNumber: formData.Phone_Number,
            state: formData.State,
            city: formData.City,
            address: formData.Address,
            specialties: selectedSpecializations
                .map((specialization) => {
                    const specialty = specialties.find(
                        (s) => s.name === specialization
                    );
                    return specialty?._id || '';
                })
                .join(',\n'),
        };
        mutation.mutate(requestBody);
        setIsLoading(true);
        setButton(false);
    };
    interface Specialty {
        _id: string;
        name: string;
    }
    const formatedSpecialty = formData.Specialization.map((data) => {
        return data.name;
    });
    const [specialties, setSpecialties] = useState<Specialty[]>([]);
    const [specialtiesID, setSpecialtiesID] = useState<any>([]);
    const [selectedSpecializations, setSelectedSpecializations] =
        useState<any>(formatedSpecialty);
    const handleSelectSpecialtyChange = (e: any) => {
        const { name, value } = e.target;
        setSelectedSpecializations((prevSelected) => {
            if (!prevSelected.some((item) => item === value)) {
                return [...prevSelected, value];
            }
            return prevSelected;
        });
        console.log({ value });
        setButton(true);
        const specialtyValue = specialties.find(
            (specialty) => specialty.name === value
        );
        console.log(specialtyValue);
        setSpecialtiesID((prevSelected) => [
            ...prevSelected,
            specialtyValue?._id,
        ]);
    };
    //    console.log(specialtiesID)
    const removeSpecialization = (index: number) => {
        const updatedSpecializations = [...selectedSpecializations];
        updatedSpecializations.splice(index, 1);
        setSelectedSpecializations(updatedSpecializations);
        setButton(true);
    };

    const handleSelectChange = (e: any) => {
        const { name, value } = e.target;

        setButton(true);
        setFormData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
    };
    useEffect(() => {
        // Fetch specialties from the backend when the component mounts
        hospitalService
            .getSpecialties()
            .then((specialtiesData) => {
                if (Array.isArray(specialtiesData.specialty)) {
                    setSpecialties(specialtiesData.specialty);
                } else {
                    console.error(
                        'Specialties data is not an array:',
                        specialtiesData.specialty
                    );
                }
            })
            .catch((error) => {
                console.error('Error fetching specialties:', error);
            });
    }, []);
    const resetToPrevious = () => {
        setFormData((prevData) => ({
            ...prevData,
            tagLine: hospitalAuthStore.tagline,
            Phone_Number: hospitalAuthStore.phoneNumber,
            State: hospitalAuthStore.state,
            City: hospitalAuthStore.city,
            Address: hospitalAuthStore.address,
            coordinates: hospitalAuthStore.location.coordinates.join(','),
            licenseNumber: hospitalAuthStore.licenseNumber,
            Specialization:
                hospitalAuthStore.metadata.specialties.length > 0
                    ? hospitalAuthStore.metadata.specialties
                    : [],
        }));
        setSelectedSpecializations(formatedSpecialty);
        setButton(false);
    };
    const goBack = () => {
        navigate(-1);
    };
    return (
        <Fragment>
            <ToastContainer />
            <div className="px-2 md:px-8 py-6 ">
                {/*Title */}
                {activeTab === 'Profile' && (
                    <div className="flex gap-2 items-center">
                        <button onClick={goBack}>
                            <IoIosArrowBack color="#2D2D2E" size={'24px'} />
                        </button>
                        <h3 className="font-bold sm:text-[28px] text-[20px]">
                            Editing Information
                        </h3>
                    </div>
                )}
                {/* {errorMessage && <ErrorState message={errorMessage} />} */}
                <div className="bg-white px-8 py-[18px] rounded-lg">
                    <div className="border-b-[1px] overflow-x-scroll">
                        <div className="w-[100%] lg:w-[500px] overflow-x-scroll flex justify-between">
                            {Tabs?.map((tab, index) => (
                                <button
                                    key={index}
                                    className={`text-[14px] px-[16px] py-[8px] hover:text-Tmred ${
                                        activeTab === tab
                                            ? 'border-b-[2px] border-Tmred'
                                            : ''
                                    }`}
                                    onClick={() => handleTab(tab)}
                                >
                                    {tab}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="mt-[40px]">
                        {/* Body */}
                        {activeTab === 'Profile' && (
                            <div className="w-full lg:max-w-[733px]">
                                <div className="flex sm:flex-col lg:flex-row items-center justify-between bg-lightPink p-1  sm:p-4 gap-4 rounded-xl mb-6">
                                    <div className="w-full flex gap-4 items-center space-x-1 sm:space-x-3 ">
                                        <img
                                            src={hospitalAuthStore.logo}
                                            alt="hospital logo"
                                            className="rounded-full h-[98px] w-[98px]"
                                        />
                                        <div>
                                            <h6 className="sm:text-2xl text-sm font-medium text-lightBrown">
                                                {hospitalAuthStore.name}
                                            </h6>
                                            <p className="sm:text-sm text-xs my-2 font-medium text-lightBrown ">
                                                {hospitalAuthStore.email}
                                            </p>
                                            <p className="sm:text-sm text-xs font-normal text-lightBrown">
                                                {hospitalAuthStore.tmrId}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="w-full lg:text-center">
                                        <p className="text-sm text-Tmred font-medium">
                                            Tag line
                                        </p>
                                        <p className="mt-2 italic text-xs text-dark200">
                                            {' '}
                                            {hospitalAuthStore.tagline}
                                        </p>
                                    </div>
                                </div>

                                {/* form */}
                                <form action="" onSubmit={handlesubmit}>
                                    <div className=" ">
                                        <div className="flex items-center justify-between w-full py-4">
                                            <h2 className="font-medium text-base sm:text-[22px] text-lightBrown w-full">
                                                Contact Information
                                            </h2>
                                            <span className="border-t h-[1px] max-w-[501px] w-full border-brown100"></span>
                                        </div>
                                        <div className="w-full flex flex-col gap-2 mt-4 sm:pt-6">
                                            <TextField
                                                className="w-full"
                                                variant="outlined"
                                                label="Hospital Name"
                                                name="Hospital_Name"
                                                value={formData.Hospital_Name}
                                                onChange={handleChange}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="w-full flex flex-col gap-2 my-4 sm:pt-6">
                                            <TextField
                                                className="w-full"
                                                variant="outlined"
                                                label="Email Address"
                                                placeholder="Enter e-mail address"
                                                name="Email"
                                                value={formData.Email}
                                                onChange={handleChange}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="w-full flex items-center flex-col sm:flex-row gap-6 mt-4 sm:pt-6">
                                            <TextField
                                                className="w-full"
                                                variant="outlined"
                                                label="TagLine"
                                                name="tagLine"
                                                value={formData.tagLine}
                                                onChange={handleChange}
                                            />
                                            <TextField
                                                className="w-full"
                                                variant="outlined"
                                                label="Phone Number"
                                                name="Phone_Number"
                                                value={formData.Phone_Number}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="w-full flex flex-col sm:flex-row gap-6 mt-4 sm:pt-6">
                                            <TextField
                                                className="w-full"
                                                variant="outlined"
                                                label="Country"
                                                disabled={true}
                                                name="Country"
                                                value={formData.Country}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="w-full flex items-center flex-col sm:flex-row gap-6 mt-4 sm:pt-6">
                                            <div className="w-full sm:flex-1">
                                                {/* <SelectElement
                                                    onChange={
                                                        handleSelectChange
                                                    }
                                                    value={formData.State}
                                                    name="State"
                                                    options={['Ogun', 'lagos']}
                                                /> */}

                                                <FormControl
                                                    fullWidth
                                                    sx={{
                                                        minWidth: ['100%', 120],
                                                    }}
                                                    className="lg:w-full"
                                                    size="small"
                                                >
                                                    <InputLabel
                                                        id="demo-select-small-label"
                                                        htmlFor={'state'}
                                                    >
                                                        {'State'}
                                                    </InputLabel>

                                                    <Select
                                                        labelId="demo-select-small-label"
                                                        id="demo-select-small"
                                                        label="State"
                                                        onChange={
                                                            handleSelectChange
                                                        }
                                                        disabled={true}
                                                        name="State"
                                                        value={formData.State}
                                                        className="w-full p-2 "
                                                    >
                                                        {formData.countryStates.map(
                                                            (state: any) => (
                                                                <MenuItem
                                                                    key={
                                                                        state.code
                                                                    }
                                                                    value={
                                                                        state.name
                                                                    }
                                                                >
                                                                    {state.name}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <TextField
                                                className="w-full sm:flex-1"
                                                variant="outlined"
                                                label="City"
                                                placeholder="Ikeja"
                                                disabled={true}
                                                name="City"
                                                value={formData.City}
                                                onChange={handleChange}
                                            />
                                        </div>

                                        <div className="w-full flex flex-col sm:flex-row gap-6 mt-4 sm:pt-6">
                                            <TextField
                                                className="w-full"
                                                variant="outlined"
                                                // multiline
                                                label="Address"
                                                disabled={true}
                                                name="Address"
                                                value={formData.Address}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="w-full flex flex-col gap-2 mt-4 sm:pt-6">
                                            <TextField
                                                className="w-full"
                                                variant="outlined"
                                                // multiline
                                                label="Coordinates (Long. & Lat.)"
                                                name="coordinates"
                                                value={formData.coordinates}
                                                onChange={handleChange}
                                                disabled={true}
                                            />
                                            <p className="font-normal text-[12px] text-[#534341] w-fit">
                                                Search{' '}
                                                <Link
                                                    to="https://maps.google.com"
                                                    className=" text-Tmred"
                                                    target="_"
                                                    rel="nofollow noindex"
                                                >
                                                    your address{' '}
                                                </Link>{' '}
                                                on Google Maps. Right-click,
                                                copy coordinates, then paste!
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-between w-full py-4 mt-4">
                                            <h2 className="font-medium text-base sm:text-[22px] text-lightBrown w-full">
                                                Professional information
                                            </h2>
                                            <span className="border-t h-[1px] max-w-[501px] w-full border-brown100"></span>
                                        </div>
                                        <div className="w-full flex flex-col gap-2 mt-4 sm:pt-6">
                                            <TextField
                                                className="w-full"
                                                variant="outlined"
                                                // multiline
                                                label="MDCN License number "
                                                name="licenseNumber"
                                                value={formData.licenseNumber}
                                                onChange={handleChange}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="w-full flex md:mb-[40px] flex-col sm:flex-row gap-6 mt-4 sm:pt-6">
                                            <FormControl fullWidth>
                                                <SelectElement
                                                    onChange={
                                                        handleSelectSpecialtyChange
                                                    }
                                                    value={
                                                        formData.Specialization
                                                    }
                                                    name="Specialization"
                                                    options={specialties.map(
                                                        (specialty) =>
                                                            specialty.name
                                                    )}
                                                />

                                                {selectedSpecializations.length >
                                                    0 && (
                                                    <ul className="pt-4 flex items-center gap-2 flex-wrap">
                                                        {selectedSpecializations.map(
                                                            (
                                                                specialization,
                                                                index
                                                            ) => {
                                                                return (
                                                                    <li
                                                                        key={
                                                                            index
                                                                        }
                                                                        className={`flex items-center border gap-3 rounded-lg py-[6px] pr-[8px] pl-[12px]  border-[#4D4544] w-fit`}
                                                                    >
                                                                        <span className="font-normal text-[16px] text-[#201A19]">
                                                                            {' '}
                                                                            {
                                                                                specialization
                                                                            }
                                                                        </span>
                                                                        <button
                                                                            type="button"
                                                                            onClick={() =>
                                                                                removeSpecialization(
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            <IoIosClose
                                                                                size={
                                                                                    '18'
                                                                                }
                                                                            />
                                                                        </button>
                                                                    </li>
                                                                );
                                                            }
                                                        )}
                                                    </ul>
                                                )}
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="bg-[#FFDAD5] flex py-2 px-4 mb-4 mt-8 lg:mt-0 rounded-md gap-4">
                                        <img src={info} alt="info" />
                                        <p className="text-[0.8rem]">
                                            For assistance with updating your
                                            address information, our dedicated
                                            TMR Support team is here to help.
                                            Feel free to reach us at{' '}
                                            <a
                                                className="underline "
                                                href="mailto:support@trackmedrec.com"
                                            >
                                                support@trackmedrec.com
                                            </a>{' '}
                                            for any questions or guidance you
                                            may need.
                                        </p>
                                    </div>
                                    <hr></hr>
                                    <div className="mt-[16px] items-center gap-4 flex justify-end">
                                        <button
                                            type="reset"
                                            disabled={!buttonEnabled}
                                            onClick={resetToPrevious}
                                            className={`border-0 text-[16px]   ${
                                                buttonEnabled
                                                    ? 'text-Tmred hover:cursor-pointer  '
                                                    : 'text-lightGray hover:cursor-not-allowed'
                                            }`}
                                        >
                                            Reset to previous
                                        </button>

                                        <button
                                            disabled={!buttonEnabled}
                                            type="submit"
                                            className={`text-[16px] duration-300 rounded-lg px-6 py-1 ${
                                                buttonEnabled
                                                    ? 'bg-Tmred text-white hover:cursor-pointer '
                                                    : 'bg-[#1D1B201F] text-lightGray hover:cursor-not-allowed'
                                            }`}
                                        >
                                            {isLoading
                                                ? 'Updating...'
                                                : 'Update'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        )}

                        {activeTab === 'Appointment' && (
                            <div>
                                <AppointmentSchedulling />
                            </div>
                        )}
                        {activeTab === 'Services' && (
                            <div>
                                <Services />
                            </div>
                        )}

                        {activeTab === 'Media' && (
                            <div>
                                <Media />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Hospital;
