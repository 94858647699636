import React from 'react';
import DashboardLayout from '../Dashboard/DashboardLayout';
import Voucher from '../../../components/Voucher/Voucher';
import { Helmet } from 'react-helmet';

const VoucherPage = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>TMR | Voucher</title>
            </Helmet>
            <div className="">
                <Voucher walletType="personal" />
            </div>
        </DashboardLayout>
    );
};

export default VoucherPage;
