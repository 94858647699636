import React, { useEffect, useState } from 'react'
import Input from './Forms/Input';
import { toast } from 'react-toastify';
import patientService from '../services/patientService';
import { useMutation } from 'react-query';

interface IMedicationFormData {
   drugName: any;
    dosage: any;
    sideEffect :any;
    doctorsNote:any
}

const MedicationModal = ({closeModal,appointmentDetails, medicalReportId, refetch}) => {
    const [button, setButton] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState<IMedicationFormData>({
        drugName: '',
        dosage: '',
        sideEffect:'',
        doctorsNote:''
    });
    const mutation = useMutation((data: any) =>
        patientService.createMedication(medicalReportId,data)
    );
    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false)
            const response = mutation.data;
            const message = response.data.message;
            toast.success(message);
            setFormData({
                drugName: '',
                dosage: '',
                sideEffect:'',
                doctorsNote:''
            });
            // make API request to get the new medical report and medication
            refetch();
            closeModal();
        }
    }, [mutation.isSuccess]);

    useEffect(() => {
        if (mutation.isError) {
            setIsLoading(false)
            let err: any = mutation.error;
            toast.error(err.response?.data?.error?.message);
        }
    }, [mutation.isError, mutation.error]);
 
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        setButton(false);
        setIsLoading(true)
        const dataToSend = {
            patientTmrNumber: appointmentDetails.patientTmrNumber,
            drugName: formData.drugName,
            dosage: formData.dosage,
            sideEffects: formData.sideEffect,
            doctorsNote: formData.doctorsNote
        };
        mutation.mutate(dataToSend)
    }

    useEffect(() => {
        if (formData.drugName !== '' && formData.dosage !== '' && formData.sideEffect !== '' && formData.doctorsNote !== '') {
            setButton(true);
        } else {
            setButton(false);
        }
    }, [formData.sideEffect, formData.drugName, formData.dosage, formData.doctorsNote]);
    
    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setFormData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleChangeTextarea = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }))
    }

    return (
     <div className="fixed inset-0 flex items-center justify-center bg-[#2826265E] bg-opacity-75 z-50 ">
            <div className="p-4 flex justify-between flex-col text-left bg-[#fff] rounded-[28px]">
                <div className='py-4'>
                    <p className="font-semibold sm:text-[24px] text-[18px] text-[#201A19]">
                        Medication
                    </p>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="flex md:flex-row flex-col md:gap-5 justify-between mb-2">
                        <Input
                            
                            name="drugName"
                            label="Drug Name"
                            value={formData.drugName}
                            onChange={handleChange}
                            className="w-full border border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider my-4"
                        />
                        
                    </div>
                    <div className="flex md:flex-row flex-col md:gap-5 justify-between my-2">
                        <textarea
                            name="dosage"
                            placeholder="Dosage"
                            required={true}
                            value={formData.dosage}
                            onChange={handleChangeTextarea}
                            className="w-full border h-[112px] border-[#857371] p-4 rounded-[8px] text-[16px] tracking-wider my-2"
                        />
                        <textarea
                            name="sideEffect"
                            placeholder="Side Effect"
                            required={true}
                            value={formData.sideEffect}
                            onChange={handleChangeTextarea}
                            className="w-full border h-[112px] border-[#857371] p-4 rounded-[8px] text-[16px] tracking-wider my-2"
                        />
                    </div>
                    <textarea
                        name="doctorsNote"
                        placeholder="Doctor's Note"
                        required={true}
                        value={formData.doctorsNote}
                        onChange={handleChangeTextarea}
                        className="w-full border h-[112px] border-[#857371] p-4 rounded-[8px] text-[16px] tracking-wider my-2"
                    />

                    <div className="flex justify-end space-x-6  mt-6">
                        <button
                            onClick={closeModal}
                            className="py-[10px] px-6 border-[1px] rounded-lg text-Tmred text-sm font-medium border-lightBrown100"
                        >
                            Close
                        </button>
                        <button
                            disabled={!button}
                            className={` py-[10px] px-6 rounded-lg text-sm font-medium  ${
                                button
                                    ? 'bg-Tmred   text-white  '
                                    : 'bg-[#1D1B201F] text-[#999797]'
                            } `}
                        >
                        {isLoading ? 'Submitting...' : 'Save'}  
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default MedicationModal
