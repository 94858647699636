import React from 'react'
import DashboardLayout from '../../Dashboard/DashboardLayout'
import { Helmet } from 'react-helmet'
import RequestProfile from '../../../../components/Ambulance/RequestMgmt/RequestProfile'

function ReqProfile() {
  return (
    <DashboardLayout>
    <Helmet>
        <title>
            TMR | Ambulance Profile
        </title>
    </Helmet>
    <div className="">
      <RequestProfile/>
    </div>
</DashboardLayout>
  )
}

export default ReqProfile