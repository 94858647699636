import { format, isToday, isYesterday } from 'date-fns';
import { useHospitalAuthStore } from '../../store/hospital/auth';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';

const formatDate = (inputDate: Date): string => {
    if (isToday(inputDate)) {
        return 'Today';
    } else if (isYesterday(inputDate)) {
        return 'Yesterday';
    } else {
        return format(inputDate, 'dd-MM-yyyy');
    }
};

interface NotificationListProps {
    handleDeleteNotification: any;
    handleMarkAsRead: any;
    selectedNotifications: any;
    handleDelete: any;
    handleCheckboxChange: any;
    handleCancel: any;
    allCheckboxesSelected: boolean;
    notifications: Array<{
        _id: string;
        message: string;
        title: string;
        createdAt: string;
        isRead: boolean;
    }>;
}

const NotificationLists: React.FC<NotificationListProps> = ({
    notifications,
    handleDeleteNotification,
    handleMarkAsRead,
    selectedNotifications,
    handleDelete,
    handleCheckboxChange,
    handleCancel,
    allCheckboxesSelected,
}) => {
    const hospitalStore = useHospitalAuthStore((state) => state.hospital);
     const isAnySelectedRead = selectedNotifications.some(
         (id) => notifications.find((n) => n._id === id)?.isRead
     );
    return (
        <>
            <div className={`mt-3`}>
                {selectedNotifications.length > 0 && (
                    <div className="bg-lightPink300 py-2 mb-3 px-4 flex justify-between items-center">
                        <p className="text-[22px] text-lightBrown">
                            {selectedNotifications.length} selected
                        </p>
                        <div className="flex space-x-2">
                            <button
                                onClick={handleMarkAsRead}
                                type="button"
                                className={`text-sm font-medium text-white bg-Tmred px-6 py-[10px] rounded-lg ${
                                    isAnySelectedRead
                                        ? 'cursor-not-allowed opacity-80'
                                        : ''
                                }`}
                                disabled={isAnySelectedRead}
                            >
                                As Read
                            </button>
                            <button
                                onClick={handleCancel}
                                type="button"
                                className="text-sm font-medium text-Tmred px-6 py-[10px] border border-lightBrown100 rounded-lg"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleDelete}
                                type="button"
                                className="text-sm font-medium text-Tmred px-6 py-[10px] rounded-lg"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                )}
                {notifications.map((notification) => (
                    <div
                        key={notification._id}
                        className={`mb-6 flex flex-col md:flex-row items-center md:justify-between py-2 px-4 rounded-xl ${
                            !notification.isRead
                                ? 'bg-lightGray300'
                                : 'bg-white'
                        }`}
                    >
                        <div className="flex items-center space-x-4 ">
                            <Box borderColor="grey.300">
                                <Checkbox
                                    checked={
                                        allCheckboxesSelected ||
                                        selectedNotifications.includes(
                                            notification._id
                                        )
                                    }
                                    onChange={() =>
                                        handleCheckboxChange(notification._id)
                                    }
                                />
                            </Box>

                            <img
                                src={hospitalStore.logo}
                                alt="logo"
                                width={40}
                                height={40}
                                className={`rounded-full ${
                                    notification.isRead && 'opacity-60 '
                                }`}
                            />

                            <div
                                className={`${
                                    notification.isRead && 'opacity-60 '
                                }`}
                            >
                                <p className="text-base text-lightBrown font-semibold">
                                    {notification.title}
                                </p>
                                <p className="text-sm text-dark100 overflow-ellipsis font-normal">
                                    {notification.message}
                                </p>
                            </div>
                        </div>
                        <div className={`flex items-center space-x-12 `}>
                            <p
                                className={`text-sm font-medium ${
                                    notification.isRead && 'opacity-60 '
                                }`}
                            >
                                {formatDate(new Date(notification.createdAt))}
                            </p>

                            {!selectedNotifications.includes(
                                notification._id
                            ) && (
                                <button
                                    onClick={() =>
                                        handleDeleteNotification({
                                            notificationId: notification._id,
                                        })
                                    }
                                    type="button"
                                    className={`text-sm font-medium text-Tmred px-6 py-[10px] border border-lightBrown100 rounded-lg ${
                                        handleDeleteNotification.isLoading
                                            ? 'opacity-50 cursor-not-allowed'
                                            : ''
                                    }`}
                                    disabled={
                                        handleDeleteNotification.isLoading
                                    }
                                >
                                    {handleDeleteNotification.isLoading
                                        ? 'Deleting...'
                                        : 'Delete'}
                                </button>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default NotificationLists;
