type BlockButtonProps = {
    text: string;
    className?: string;
    onclick?: () => any;
    disabled?: boolean;
    type: 'button' | 'submit' | 'reset';
    isLoading?: boolean | undefined;
};

const BlockButton = ({
    text,
    className,
    onclick,
    disabled,
    type = 'button',
    isLoading,
}: BlockButtonProps) => {
    const buttonTxt = isLoading ? 'Submitting...' : text;
    const isButtonDisabled = isLoading || disabled;
    return (
        <button
            onClick={onclick}
            disabled={isButtonDisabled}
            className={`w-full h-[40px] flex justify-center rounded-lg items-center text-[#9d9b9b] hover:cursor-not-allowed transition-all duration-500  ${
                isButtonDisabled
                    ? 'bg-[#1D1B201F]'
                    : 'bg-Tmred text-white hover:cursor-pointer'
            }  ${className}`}
            type={type}
        >
            {buttonTxt}
        </button>
    );
};

export default BlockButton;
