import { useState } from 'react';

interface PatientProps {
    preMedicalForm?: any;
}
const AllergiesTab: React.FC<PatientProps> = ({ preMedicalForm }) => {
    const [access, setAccess] = useState(true);

    return (
        <div className="bg-white p-4 max-w-[590px] ">
            {access ? (
                <>
                    <h2 className="text-[22px] font-normal text-lightBrown mb-5">
                        Allergies
                    </h2>
                    <div className=" border-b-[1px] border-brown100">
                        {/* <div className="flex  space-x-2">
                            <p className="text-[11px] font-medium text-dark100">
                                Last Checked:{' '}
                            </p>
                            <p className="text-xs font-medium text-lightBrown">
                                August 10, 2021
                            </p>
                        </div>
                        <div className="flex space-x-2">
                            <p className="text-[11px] font-medium text-dark100">
                                Time:
                            </p>
                            <p className="text-xs font-medium text-lightBrown">
                                2:00pm
                            </p>
                        </div> */}
                    </div>
                    <div className="mt-5">
                        <p className="text-[11px] font-medium text-dark100 mb-2">
                            Allergy History:
                        </p>
                        <ul className="pl-4 list-decimal">
                            {preMedicalForm?.allergies?.map((allergy, index) => (
                                <li
                                    key={index}
                                    className=" text-xs font-medium text-lightBrown py-2"
                                >
                                    {allergy}
                                </li>
                            ))}
                            {/* <li>
                                <p>
                                    Allergy: Penicillin <br /> Reaction:
                                    Previous rash and itching after taking
                                    penicillin-based antibiotics.
                                    <br /> Severity: Moderate
                                </p>
                            </li> */}
                        </ul>
                    </div>
                </>
            ) : (
                <div className="flex justify-center items-center my-20">
                    <button
                        type="button"
                        className="bg-Tmred px-4 py-2 text-white text-base font-normal rounded-lg"
                    >
                        Request Data Access
                    </button>
                </div>
            )}
        </div>
    );
};
export default AllergiesTab;
