import { useEffect, useState } from 'react';
import hospitalService from '../../../services/hospitalService';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { hospitalRoutes } from '../../../utils/routeNames';
import { IoIosClose } from 'react-icons/io';
import {
    Checkbox,
    FormControl,
    ListItemText,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { permission } from '../../../utils/PermisionList';
interface ManageNurModalProps {
    closeModal: () => void;
    refresh: any;
    nurseId: any;
    nurseUser: any;
    nurseName: any;
}
interface Permit {
    permissions: string[];
}

const ManageNurModal: React.FC<ManageNurModalProps> = ({
    closeModal,
    nurseId,
    nurseUser,
    nurseName,
    refresh,
}) => {
    const navigate = useNavigate();
    const [selectedAction, setSelectedAction] = useState('');
    const [showOptions, setShowOptions] = useState(true);
    const [nurseNameInput, setnurseNameInput] = useState('');
    const [isProceedButtonActive, setProceedButtonActive] = useState(false);
    const [selectedPermissions, setSelectedPermissions] = useState<string[]>(
        []
    );
    const [existingPermissions, setExistingPermissions] = useState<string[]>(
        []
    );

    const {
        formState: { isDirty, isValid },
        handleSubmit,
        control,
        reset,
    } = useForm<Permit>();
    // Load initial selected permissions
    useEffect(() => {
        if (nurseUser && nurseUser?.permissions) {
            // convert the permissions to first letter capitalized
            const existingPermission = nurseUser?.permissions.map(
                (permission: string) =>
                    permission === 'EHR'
                        ? permission
                        : permission
                              .split(' ')
                              .map((word) => {
                                  return (
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1).toLowerCase()
                                  );
                              })
                              .join(' ')
            );
            setSelectedPermissions(existingPermission);
            setExistingPermissions(existingPermission);
        }
    }, [nurseUser]);

    const handlePermissionRemove = (indexToRemove: number) => {
        setSelectedPermissions((prev) =>
            prev.filter((_, index) => index !== indexToRemove)
        );
        setProceedButtonActive(true);
    };
    const handleActionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedAction(e.target.value);
        setShowOptions(false);
    };

    const handleCancel = () => {
        setSelectedAction('');
        setShowOptions(true);
        closeModal();
    };
    const handleDoneClick = () => {
        setSelectedAction('');
        setShowOptions(true);
        closeModal();
    };

    const handlePermissionChange = (e: SelectChangeEvent<string[]>) => {
        const {
            target: { value },
        } = e;
        setSelectedPermissions(
            typeof value === 'string' ? value.split(',') : value
        );
        setProceedButtonActive(
            value.length && existingPermissions !== value ? true : false
        );
    };

    const renderContent = () => {
        switch (selectedAction) {
            case 'editGroup':
                return (
                    <div>
                        <p className="text-lightBrown text-sm mb-5 font-medium">
                            Kindly add/remove a user group for this nurse
                        </p>
                        <Controller
                            name="permissions"
                            control={control}
                            defaultValue={[]}
                            render={({ field }) => (
                                <FormControl
                                    fullWidth
                                    sx={{ minWidth: 120 }}
                                    size="small"
                                >
                                    <InputLabel
                                        id="demo-select-small-label"
                                        htmlFor={'permissions'}
                                    >
                                        User Group
                                    </InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        label="User Group"
                                        {...field}
                                        className="w-full p-2"
                                        onChange={handlePermissionChange}
                                        multiple
                                        value={selectedPermissions}
                                        renderValue={(selected) =>
                                            selected.join(', ')
                                        }
                                    >
                                        {permission?.map((pms, index) => (
                                            <MenuItem
                                                className="flex justify-between"
                                                key={index}
                                                value={pms.perm}
                                            >
                                                <ListItemText
                                                    primary={pms.perm}
                                                />
                                                <Checkbox
                                                    name={pms.perm}
                                                    checked={
                                                        selectedPermissions.indexOf(
                                                            pms.perm
                                                        ) > -1
                                                    }
                                                    className="text-end"
                                                />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <ul className="pt-4 flex items-center gap-2 flex-wrap">
                                        {selectedPermissions.map(
                                            (pms, index) => (
                                                <li
                                                    key={index}
                                                    className={`flex items-center border gap-3 rounded-lg py-[6px] pr-[8px] pl-[12px]  border-[#4D4544] w-fit`}
                                                >
                                                    <span className="font-normal text-[16px] text-[#201A19]">
                                                        {pms}
                                                    </span>
                                                    <button
                                                        onClick={() =>
                                                            handlePermissionRemove(
                                                                index
                                                            )
                                                        }
                                                    >
                                                        <IoIosClose
                                                            size={'18'}
                                                        />
                                                    </button>
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </FormControl>
                            )}
                        />
                    </div>
                );
            case 'removenurse':
                return (
                    <div className=" ">
                        <p className="text-lightBrown text-sm font-medium">
                            Removing{' '}
                            <span className="font-semibold">{nurseName}</span>{' '}
                            as a nurse will also remove them from all nurse
                            groups and roles. Enter the name of the nurse you
                            want to remove:
                        </p>
                        <input
                            type="text"
                            name="nurseName"
                            placeholder="nurse Name"
                            required={true}
                            value={nurseNameInput}
                            onChange={(e) => {
                                const inputName = e.target.value;
                                setnurseNameInput(inputName);
                                setProceedButtonActive(
                                    inputName.trim() === nurseName.trim()
                                );
                            }}
                            className="w-full border-[1px] border-dark100 font-normal my-6 p-3 rounded-[8px] text-base tracking-wider focus:border-[1px] focus:outline-none focus:border-dark100"
                        />
                    </div>
                );
            case 'suspendnurse':
                return (
                    <div className=" ">
                        <p className="text-lightBrown text-sm font-medium">
                            This will{' '}
                            {nurseUser.status === 'suspended'
                                ? 'restore'
                                : 'suspend'}{' '}
                            <span className="font-semibold">
                                {nurseUser.firstName} {nurseUser.lastName}
                            </span>{' '}
                            until you{' '}
                            {nurseUser.status === 'suspended'
                                ? 'suspend'
                                : 'unsuspend'}{' '}
                            it. Enter the name of the nurse you want to{' '}
                            {nurseUser.status === 'suspended'
                                ? 'unsuspend'
                                : 'suspend'}
                            :
                        </p>

                        <input
                            type="text"
                            name="nurseName"
                            placeholder="Nurse Name"
                            required={true}
                            value={nurseNameInput}
                            onChange={(e) => {
                                const inputName = e.target.value;
                                setnurseNameInput(inputName);
                                setProceedButtonActive(
                                    inputName.trim() === nurseName.trim()
                                );
                                // setnurseNameInput(e.target.value);
                                // setProceedButtonActive(
                                //     e.target.value.trim() !== ''
                                // );
                            }}
                            className="w-full border-[1px] border-dark100 font-normal my-6 p-3 rounded-[8px] text-base tracking-wider focus:border-[1px] focus:outline-none focus:border-dark100"
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    const handleProceed = () => {
        const fullName = `${nurseUser.firstName} ${nurseUser.lastName}`;
        // console.log(fullName);
        setProceedButtonActive(false);
        const requestBody = {
            permissions: selectedPermissions,
        };
        switch (selectedAction) {
            case 'removenurse':
                if (nurseNameInput.trim() !== fullName.trim()) {
                    break;
                }
                // console.log('Removing nurse with ID:', nurseId);
                hospitalService
                    .removeUser(nurseId)
                    .then((res) => {
                        toast.success(res.data.message, {
                            autoClose: 1000,
                            onClose: () => {
                                setTimeout(() => {
                                    navigate(hospitalRoutes.nurse);
                                }, 500);
                            },
                        });
                    })
                    .catch((error) => {
                        toast.error('Error removing nurse:', error);
                    });

                break;
            case 'suspendnurse':
                if (nurseNameInput.trim() !== fullName.trim()) {
                    break;
                }
                // console.log('Suspending nurse with ID:', nurseId);
                hospitalService
                    .suspendUser(nurseId)
                    .then((res) => {
                        toast.success(res.data.message, {
                            autoClose: 2000,
                            onClose: () => {
                                refresh();
                                closeModal();
                            },
                        });
                    })
                    .catch((error) => {
                        toast.error('Error removing nurse:', error);
                    });

                break;
            case 'editGroup':
                // console.log('Updating user group with ID:', nurseId);
                hospitalService
                    .editUserGroup(nurseId, requestBody)
                    .then((res) => {
                        toast.success(res.data.message);
                        refresh();
                        closeModal();
                    })
                    .catch((error) => {
                        toast.error('Error updating userGroup:', error);
                    });
                break;
            default:
                break;
        }

        // setSelectedAction('');
        // setShowOptions(true);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-[#2826265E] bg-opacity-75 z-50">
            <ToastContainer />
            <div
                className={`bg-white p-6 rounded-[28px] w-full m-2 max-w-[384px] sm:m-0 ${
                    showOptions ? '' : 'max-w-[444px]'
                }`}
            >
                <h2 className="text-2xl font-semibold text-lightBrown mb-3">
                    {selectedAction === 'removenurse'
                        ? 'Remove Nurse'
                        : selectedAction === 'suspendnurse'
                          ? 'Suspend Nurse'
                          : selectedAction === 'editGroup'
                            ? 'Editing User Group'
                            : 'Manage Nurse'}
                </h2>
                <div>
                    {showOptions && (
                        <div>
                            <label className="text-sm font-normal text-dark100">
                                Kindly select the action to be carried out.
                            </label>
                            <div className="flex flex-col mt-6 mb-12">
                                <label className="inline-flex items-center p-2 cursor-pointer mb-3 pb-4 border-b border-solid border-[#CAC4D0] ">
                                    <input
                                        className="cursor-pointer"
                                        type="radio"
                                        value="editGroup"
                                        checked={selectedAction === 'editGroup'}
                                        onChange={handleActionChange}
                                    />
                                    <span className="ml-4 text-base font-semibold text-lightBrown">
                                        Edit User Group
                                    </span>
                                </label>
                                <label className="inline-flex items-center p-2 cursor-pointer ">
                                    <input
                                        className="cursor-pointer"
                                        type="radio"
                                        value="removenurse"
                                        checked={
                                            selectedAction === 'removenurse'
                                        }
                                        onChange={handleActionChange}
                                    />
                                    <span className="ml-4 text-base font-semibold text-lightBrown">
                                        Remove Nurse
                                    </span>
                                </label>
                                <label className="inline-flex items-center p-2 cursor-pointer">
                                    <input
                                        className="cursor-pointer"
                                        type="radio"
                                        value="suspendnurse"
                                        checked={
                                            selectedAction === 'suspendnurse'
                                        }
                                        onChange={handleActionChange}
                                    />
                                    <span className="ml-4 text-base font-semibold text-lightBrown">
                                        {nurseUser.status === 'suspended'
                                            ? 'Unsuspend nurse'
                                            : 'Suspend nurse'}
                                    </span>
                                </label>
                            </div>
                        </div>
                    )}
                    {renderContent()}
                    <div className="flex justify-end space-x-6 py-3 ">
                        <button
                            type="button"
                            onClick={handleCancel}
                            className="py-[10px] px-4 border-[1px] border-lightBrown100 rounded-lg text-Tmred text-sm font-medium"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            onClick={handleProceed}
                            className={` text-sm font-medium py-[10px] px-4 text-lightBrown rounded-lg ${
                                isProceedButtonActive
                                    ? 'bg-Tmred text-white'
                                    : 'bg-[#1D1B201F] cursor-not-allowed'
                            }`}
                            disabled={!isProceedButtonActive}
                        >
                            {selectedAction === 'removenurse'
                                ? 'Remove'
                                : selectedAction === 'suspendnurse'
                                  ? nurseUser.status === 'suspended'
                                      ? 'Unsuspend'
                                      : 'Suspend'
                                  : 'Proceed'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ManageNurModal;
