import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'; // Import DatePicker
import dayjs from 'dayjs';

interface DatePickerElementProps {
    label?: string;
    value: dayjs.Dayjs | null;
    onChange: (date: dayjs.Dayjs | null) => void;
}

const DatePickerElementX: React.FC<DatePickerElementProps> = ({
    label,
    value,
    onChange,
}) => {
    return (
        <div className="w-full">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker 
                    label={label} 
                    value={value} 
                    onChange={onChange} 
                    
                />
            </LocalizationProvider>
        </div>
    );
};

export default DatePickerElementX;