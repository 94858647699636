import {  useRef } from 'react';
import arrow from '../../Images/arrow-up.jpg';
import { useForm, Controller } from 'react-hook-form';

type ImageUploadNursProps = {
    onImageChange: (image: string | ArrayBuffer | null | File) => void;
    selectedImage: string | ArrayBuffer | null | File;
};

const ImageUploadNurs: React.FC<ImageUploadNursProps> = ({
    onImageChange,
    selectedImage,
}) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);
const { control } = useForm();
//   const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//       const file = e.target.files?.[0];

//       if (file) {
//           const reader = new FileReader();
//           reader.onloadend = () => {
//               const imageUrl = URL.createObjectURL(file);
//               console.log('Image URL:', imageUrl);
//               onImageChange(imageUrl); // Pass the URL to the parent component
//           };

//           reader.readAsDataURL(file); // Read the file as a data URL
//       } else {
//           onImageChange(null);
//       }
//   };

const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
            const imageUrl = URL.createObjectURL(file);
            console.log('Image URL:', imageUrl);
            onImageChange(file); // Pass the File object to the parent component
        };

        reader.readAsDataURL(file); // Read the file as a data URL
    } else {
        onImageChange(null);
    }
};

    const handleUploadButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <div className="flex justify-center flex-col items-center bg-white p-4 h-[371px] rounded-[20px]">
            <h2 className="text-[22px] font-semibold text-lightBrown bg-lightPink p-4 rounded-t-[20px]">
                Profile Picture (optional)
            </h2>

            <div className="relative my-[42px] w-40 h-40 overflow-hidden rounded-full border ">
                {selectedImage && typeof selectedImage === 'string' && (
                    <img
                        src={selectedImage}
                        alt="Selected"
                        className="w-full h-full object-cover"
                    />
                )}
                <Controller
                    name="image" // Set the name to match your form data structure
                    control={control}
                    render={({ field }) => (
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            ref={fileInputRef}
                            className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                        />
                    )}
                />
            </div>

            <button
                onClick={handleUploadButtonClick}
                className="flex justify-center rounded-lg items-center py-2 border border-Tmred w-full"
            >
                <img src={arrow} alt="" />
                <span className="text-Tmred text-xs font-medium ml-2">
                    Upload
                </span>
            </button>
        </div>
    );
};

export default ImageUploadNurs;
