import React, { ReactNode } from 'react';

interface TabProps {
    children: ReactNode;
}

const Tab: React.FC<TabProps> = ({ children }) => {
    return <div className="">{children}</div>;
};

export default Tab;
