import { useEffect, useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import Tab from '../Tab';
import Tabs from '../Tabs';
import { useQuery } from 'react-query';
import hospitalService from '../../../services/hospitalService';
import Lottie from 'lottie-react';
import loadingState from '../../../Images/loading.json';
import { useHospitalAuthStore} from '../../../store/hospital/auth';
import FleetData from './FleetData';
import back from '../../../Images/back.png';

const AmbulanceHistories = () => {
    const userStore = useHospitalAuthStore((state) => state.user);
    const [Ambulance, setAmbulance] = useState<any[]>([]);
    const [availableAmbulance, setavailableAmbulance] = useState<any[]>([]);
    const [notAvailableAmbulance, setnotAvailableAmbulance] = useState<any[]>([]);
    const [onDutyAmbulance, setonDutyAmbulance] = useState<any[]>([]);
    const [AmbulanceData, setAmbulanceData] = useState<any>([]);
    const [filteredAmbulanceData, setFilteredAmbulanceData] = useState('');
  
    const navigate = useNavigate();

    const { data, isError, isLoading } = useQuery(
        'Ambulance',
        hospitalService.getAllAmbulance
    );
    if (isError){
        console.log("error", isError)
    }

    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (searchTerm: string) => {
        setSearchQuery(searchTerm);
    };

    const handleFilterQueryParam = (queryParams: string) => {
        hospitalService
            .filterAllAmbulance(queryParams)
            .then((res) => {
                setFilteredAmbulanceData(res);
            })
            .catch((err) => {
                // toast.error()
                console.log(err);
            });
    };

    useEffect(() => {
        setAmbulanceData(data);
        console.log(AmbulanceData)
        if (filteredAmbulanceData) {
            setAmbulanceData(filteredAmbulanceData);
        }
    }, [AmbulanceData, data, filteredAmbulanceData]);

    useEffect(() => {
         if (AmbulanceData?.fleets) {
           
                setAmbulance(AmbulanceData?.fleets);
                const available = AmbulanceData?.fleets?.filter(
                    (row: any) => row.status === 'Available'
                );
                setavailableAmbulance(available);
                const notAvailable = AmbulanceData?.fleets?.filter(
                    (row: any) => row.status === 'Not Available'
                );
                setnotAvailableAmbulance(notAvailable);
                const onDuty = AmbulanceData?.fleets?.filter(
                    (row: any) => row.status === 'On Duty'
                );
                setonDutyAmbulance(onDuty);
            }
        
    }, [AmbulanceData, userStore]);


    

    const tabs = [
        {
            title: 'All',
            content: (
                <Tab>
                    <FleetData
                        fleet={Ambulance}
                        searchQuery={searchQuery}
                    />
                </Tab>
            ),
            count: Ambulance?.length || 0,
        },
        {
            title: 'Available',
            content: (
                <Tab>
                    <FleetData
                         fleet={availableAmbulance}
                        searchQuery={searchQuery}
                    />
                </Tab>
            ),
            count: availableAmbulance?.length || 0,
        },
        {
            title: 'Not Available',
            content: (
                <Tab>
                    <FleetData
                         fleet={notAvailableAmbulance}
                        searchQuery={searchQuery}
                    />
                </Tab>
            ),
            count: notAvailableAmbulance?.length || 0,
        },
        {
            title: 'On Duty',
            content: (
                <Tab>
                    <FleetData
                      fleet={onDutyAmbulance}
                        searchQuery={searchQuery}
                    />
                </Tab>
            ),
            count: onDutyAmbulance?.length || 0,
        },
    ];
  

    const goBack = () => {
        navigate(-1);
    };
    return (
        <div className="mt-7 sm:mt-0 w-full bg-[#F5F5F5] md:px-8 px-1">
            <div className="flex gap-2 items-center ">
                    <button onClick={goBack}>
                        <img src={back} alt="" />
                    </button>
                    <h3 className="font-bold sm:text-[28px] text-[20px]">
                    Viewing Ambulance
                    </h3>
                </div>
            <div className="px-4 py-4 font-700 text-xl text-black border-transparent bg-[#FFFFFF]  p-2 m-4  rounded-2xl overflow-x-auto sm:overflow-hidden">
                {isLoading ? (
                    <div className="flex justify-center items-center py-64">
                        <Lottie
                            animationData={loadingState}
                            loop={true}
                            className="lottie"
                        />
                    </div>
                ) : (
                    <Tabs
                        tabs={tabs}
                        onSearchChange={handleSearchChange}
                        onFilter={handleFilterQueryParam}
                    />
                )}
            </div>
            
        </div>
    );
};

export default AmbulanceHistories;
