import { useState, useEffect, useRef } from 'react';
import BlockButton from '../../../reusable/BlockButton';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import hospitalService from '../../../services/hospitalService';

const BankDetailsModal = ({closeModal , bankDetails, onSaveDetails}) => {

    const modalRef = useRef<HTMLDivElement | null>(null);

    const handleCloseModal = () => {
        closeModal();
        
    };
    const handleOverlayClick = (event: any) => {
        if (
            modalRef.current &&
            !modalRef.current.contains(event.target as Node)
        ) {
            closeModal();
        }
    };
    console.log(bankDetails);
    
    useEffect(() => {
        document.addEventListener('mousedown', handleOverlayClick);

        return () => {
            document.removeEventListener('mousedown', handleOverlayClick);
        };
    }, []);

    const [isLoading, setIsLoading] = useState(false)
    const [isChecked, setIsChecked] = useState(false);
    const [showConfirmDetails, setShowConfirmDetails] = useState
    (false)
    const [isDetailsSaved, setIsDetailsSaved] = useState(false)

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked); 
    };
    const handleProceed = () => {
        setIsLoading(true);
        setTimeout(() => {
            setShowConfirmDetails(true); 
            setIsLoading(false)
        }, 1000);
    };
    const handleSaveDetails = () => {
        setIsLoading(true);
        const userBankDetails = {...bankDetails }
        delete userBankDetails.accountName 
        console.log(userBankDetails)
        hospitalService.addBankDetails(userBankDetails)
        .then((res: any) =>{
            console.log(res);
            toast.success(res.data.message) 
            setIsLoading(false)
            setIsDetailsSaved(true); 
            onSaveDetails(true)
            sessionStorage.setItem('bankDetailsSessionStore', JSON.stringify(bankDetails))
        })
        .catch((error) =>{
            console.log(error);
            toast.error(error.response.data.error.message)
        })
        
        setTimeout(() => {
            closeModal()
        }, 1500);
    };


    return (
        <div className="fixed inset-0 flex items-center justify-center bg-[#2826265E] bg-opacity-75 z-50">
            <div
                ref={modalRef}
                className="bg-white md:p-6 p-2 rounded-[28px] mx-2 "
            >
                <ToastContainer />
                { !showConfirmDetails ? 
                    (

                    <div>
                        <h1 className=" font-bold text-[32px]">Bank Details Disclaimer</h1>
                        <p className="text-[14px] my-5">
                            Dear <span className=" text-Tmred">{}</span>
                        </p>

                        <p className="text-[14px]">By adding your bank account details to the TrackMedRec platform:</p>

                        <ul className=" flex flex-col gap-2 my-4 list-disc ml-4 text-[14px] ">
                            <li className="">
                                Your provided account becomes the exclusive account for withdrawals and payments.
                            </li>
                            <li>
                                Payments or transfers can be made to any staff member's wallet within the TrackMedRec platform.
                            </li>
                            <li>
                                Payments or transfers can be made to any staff member's wallet within the TrackMedRec platform.
                            </li>
                            <li>
                                Ensure accuracy in the provided bank details to avoid inconvenience.
                            </li>
                            <li>
                                Hospital administrators are responsible for managing and updating the account details.
                            </li>
                        </ul>

                        <div className=" flex gap-3">
                            <input 
                                type="checkbox" 
                                name="read" 
                                id="read" 
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                            />
                            <label htmlFor='read' className="text-[12px] my-4">I acknowledge that I have read and understood the disclaimer provided above.</label>
                        </div>
                        <div className=" flex justify-end gap-3">
                            <button
                                className="text-Tmred border-[1px] border-lightBrown100 px-6 py-2 text-base font-normal rounded-lg"
                                onClick={handleCloseModal}
                            >
                                    Cancel
                             </button>

                            <BlockButton 
                                text="Proceed"
                                disabled={!isChecked || isLoading}
                                type="submit"
                                isLoading = {isLoading}
                                onclick={handleProceed}
                                className="!w-auto p-3"
                            />
                        </div>

                    </div>
                    
                    )

                    : 
                    (
                        <div>
                             <h1 className=" font-bold text-[32px]">
                                Confirm Bank Details
                            </h1>

                            <p className="text-[14px] my-5">
                                 Please review and confirm your bank details below.
                            </p>

                            <div className="rounded-[28px] bg-lightGray400 px-6 py-3">
                                <div className="flex justify-between  py-4 border-b-2 border-brown100 items-center">
                                    <span>Bank Name</span>
                                    <h1 className="text-[24px]">
                                        {bankDetails.bankName}
                                    </h1>
                                </div>
                                <div className="flex justify-between  py-4 border-b-2 border-brown100 items-center">
                                    <span>Account Number</span>
                                    <h1 className="text-[24px]">
                                        {bankDetails.accountNumber}
                                    </h1>
                                </div>
                                <div className="flex justify-between gap-8 py-4 items-center">
                                    <span>Account Name</span>
                                    <h1 className="text-[24px]"> {bankDetails.accountName}</h1>
                                </div>
                            </div>
                          

                            <div className=" flex justify-end gap-3 mt-7">
                                <button
                                    className="text-Tmred border-[1px] border-lightBrown100 px-6 py-2 text-base font-normal rounded-lg"
                                    onClick={handleCloseModal}
                                >
                                        Cancel
                                </button>

                                <BlockButton 
                                    text="Save Details"
                                    type="submit"
                                    isLoading= {isLoading}
                                    onclick={handleSaveDetails}
                                    className="!w-auto p-3"
                                />
                        </div>

                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default BankDetailsModal