
import { ArrowLeft2 } from 'iconsax-react';

import { useNavigate,useParams } from 'react-router';
import DetailsTab from './DetailsTab';
import ProfileDetails from './ProfileDetails';
import ProfileResults from './ProfileResults';
import hospitalService from '../../services/hospitalService';
import { useQuery } from 'react-query';
import Lottie from 'lottie-react';
import loadingState from '../../Images/loading.json';
const AdProfile = () => {
  const { id } = useParams();

  const navigate = useNavigate();
const { data, isLoading,  isError } = useQuery(
    ['singleAds', id],
    () =>
        id
            ? hospitalService.getSingleAd(id)
            : Promise.reject('NO ID'),
    {
        enabled: !!id,
    }
  );
  
  console.log(data)
  const tabs = [
    {
      title: 'Details',
      content: <ProfileDetails ad={data} />,
    },
    {
      title: 'Results',
      content: <ProfileResults ad={data}/>,
      disabled: data?.ads?.status !== 'expired',
    },
   
  ]
  const goBack = () => {
    navigate(-1);
};
if(isLoading){
  return(

  
  <div className="flex justify-center items-center py-32">
  {' '}
  <Lottie
      animationData={loadingState}
      loop={true}
      className="lottie"
  />
</div>
  )
}
if(isError){
  return (
    <div className="flex justify-center items-center">
        Error loading details, please refresh your page
    </div>
);
}
  return (
    <div className="w-full bg-[#F5F5F5] h-full px-8 py-6 ">
      <button
        type="button"
        onClick={() => goBack()}
        className="flex items-center space-x-2  cursor-pointer mb-7">
        <ArrowLeft2 size="24" color="#000" />
        <h2 className="font-semibold text-base md:text-[28px] text-customBlack">
          ADs Details
        </h2>
      </button>
      <div className="max-w-[720px] w-full">
      
        <div className=" bg-white  p-2 sm:p-8 rounded-2xl mb-10 lg:mb-0">
          <DetailsTab tabs={tabs} />
        </div>
      </div>
    </div>
  );
};
export default AdProfile;
