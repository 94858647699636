import React, { useEffect, useState } from 'react'
import { IoIosAttach } from 'react-icons/io';
import { IoCloudDownloadOutline } from 'react-icons/io5';
import { SlArrowLeft } from 'react-icons/sl';
import { useLocation, useNavigate } from 'react-router-dom';
import { Content} from './OrderDetails';
import { pharmacyRoutes } from '../../../utils/pharmacyRouteNames';
import { useMutation} from 'react-query';
import { pharmacyService } from '../../../services/pharmacyService';
import { toast } from 'react-toastify';
import { FormControl, FormControlLabel,Radio, RadioGroup } from '@mui/material';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { standardAmountFormat } from '../../../utils/helpers';
import { usePharmacyAuthStore } from '../../../store/pharmacy/auth';
type PaymentProps = {

    paymentMethod: any;
}
const PreviewOrder = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const orderData = location.state;
    const {state} = location
    const {uploadedFile, selectedDrugs } = state;
    console.log('orderData', orderData)
    const [isloading, setIsLoading] = useState(false);
   
    const createOrderMutation = useMutation(
        (data:any) => pharmacyService.createOrder(data),
        
    );
    const {
        formState: { isDirty, isValid },
        handleSubmit,
        control,
        getValues
    } = useForm<any>();

    useEffect(() => {
        if (createOrderMutation.isSuccess) {
            setIsLoading(false);
            let payload: any = createOrderMutation.data;
            console.log('success', payload);
            toast.success(createOrderMutation.data?.data.message);
            setTimeout(() => {
                toast.dismiss();
                navigate(pharmacyRoutes.orders);
            }, 2000);
        }
    }, [createOrderMutation.isSuccess, createOrderMutation.data]);
    useEffect(() => {
        if (createOrderMutation.isError) {
            setIsLoading(false);
            let err: any = createOrderMutation.error;
            toast(err.response.data.error.message);
            setTimeout(() => {
                toast.dismiss();
            }, 2000);
        }
    }, [createOrderMutation.isError, createOrderMutation.error]);
    const goBack = () => {
        navigate(-1);
    };
    const currency = usePharmacyAuthStore((state) => state.pharmacy.currency);

const OnSubmitForm: SubmitHandler <PaymentProps> = (e:any) => {
    // e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();

    // Append customer information
    formData.append('customer', JSON.stringify({
        fullName: orderData.fullName,
        tmrNumber: orderData.TmrNum || '',
        phoneNumber: orderData.phoneNumber,
        email: orderData.email,
        address: orderData.address || '',
    }));

    // Append shipping information
    formData.append('shippingInformation', orderData.shippingAddress || '');

    // Append items
    if (selectedDrugs) {
        formData.append('items', JSON.stringify(selectedDrugs.map(item => ({
            productId: item._id,
            quantity: item.quantity
        }))));
    }

    // Append doctor report type
    if (orderData.uploadedFile) {

    formData.append('doctorReportType', 'image');}
    // Append image or prescriptionId ad doctors report
    if (uploadedFile) {
        formData.append('doctorsReport', uploadedFile);
    }
    if (orderData.prescriptionId) {
        formData.append('prescriptionId', orderData.prescriptionId);
    }

    // Append delivery option
    formData.append('deliveryOption', orderData.shippingMethod || '');

   

    // Append payment method from form state
    formData.append('paymentMethod', getValues('paymentMethod') || '');

    // Submit the form
    createOrderMutation.mutate(formData);
};

// Calculate the total amount for selected drugs
const totalAmount = orderData.selectedDrugs.reduce((acc, drug) => 
    acc + drug.price * drug.quantity, 0).toFixed(2);

// Format the total amount
const formattedAmount = standardAmountFormat(totalAmount);
  return (
    <div className="w-full bg-[#F5F5F5] h-full px-6 py-6">
      <div className="flex items-center space-x-2 mb-6 cursor-pointer">
                <SlArrowLeft className="m-2" onClick={goBack} />
                <h6 className="font-semibold text-[28px] text-lightBrown">
                Preview Order
                </h6>
            </div>
            <form onSubmit={handleSubmit(OnSubmitForm)}>
            <div className="flex flex-col md:flex-row gap-3">
                            <div className="flex flex-col lg:w-2/3">
                                <div className="flex-col py-4 font-700 text-xl text-black border-transparent bg-[#FFFFFF]  rounded-2xl max-w-[742px] flex p-4">
                                    
                                    <div className='flex w-full flex-col  justify-between'>
                                    <div className='flex  my-3 p-4 sm:flex-row flex-col'>
                                        <div className='flex flex-col'>

                                        
                                            <h1 className='text-[20px] my-2 text-[#201A19] font-medium'>Customer Information</h1>
                                            <div className='flex flex-col items-left justify-between'>
                                                <Content
                                                        title='Name:'
                                                        content={orderData.fullName || '-'}
                                                        />
                                                        <Content
                                                        title='Phone Number:'
                                                        content={orderData.phoneNumber || '-'}
                                                        />
                                                        <Content
                                                        title='Email:'
                                                        content={orderData.email || '-'}
                                                        />
                                                        
                                                        
                                                        
                                        </div>
                                        </div>
                                        <div className='flex flex-col'>

                                       
                                    <h1 className='text-[20px] my-2 text-[#201A19] font-medium'>Shipping Information</h1>
                                    
                                        
                                    <div className='flex flex-col items-left justify-between'>
                                    <Content
                                            title='Method:'
                                            content={orderData.shippingMethod || '-'}
                                            />
                                            <Content
                                            title='Address:'
                                            content={orderData.shippingAddress || '-'}
                                            />
                                            
                                            
                                            
                                            
                                </div>
                                </div>
                                    </div>
                                    <div className='flex  p-4'>
                                   
                                    
                                    <div className='flex flex-col'>

                                    
                                    
                                    
                                        
                                    <h1 className='text-[20px]  text-[#201A19] font-medium'>Doctor’s Prescription</h1>
<div className='mt-3 flex justify-between rounded-lg items-center w-[279px] border border-[#EEE7E7] py-2 px-4'>
    <span className='flex gap-2 items-center'>
        <IoIosAttach />
        <p className='text-[#534341] font-medium text-[14px] w-32 truncate'>
            {uploadedFile ? uploadedFile.name : 'No file uploaded'}
        </p>
    </span>
    <button>
        <IoCloudDownloadOutline />
    </button>
</div>

                                    </div>
                                    </div>
                                    </div>
                                    
                                    <div className='p-3 mb-3'>
    <h1 className='text-[#201A19] font-medium text-[20px] py-2'>Item Summary</h1>
    <div className="md:w-full">
        <table className="w-full py-2 px-4">
            <thead className="bg-[#EEE7E7] rounded-2xl">
                <tr>
                    <th className="py-2 px-4 text-center">
                        <p className="text-[10px] text-left text-[#534341]">Item Name</p>
                    </th>
                    <th className="text-center">
                        <p className="text-[10px] text-[#534341]">Doctors Report</p>
                    </th>
                    <th className="text-center">
                        <p className="text-[10px] text-[#534341]">Qty</p>
                    </th>
                    <th className="text-center">
                        <p className="text-[10px] text-[#534341]">Price</p>
                    </th>
                    <th className="text-center">
                        <p className="text-[10px] text-[#534341]">Total</p>
                    </th>
                </tr>
            </thead>
            <tbody>
                {orderData.selectedDrugs && orderData.selectedDrugs.length > 0 ? (
                    orderData.selectedDrugs.map(drug => (
                        <tr key={drug.id}>
                            <td className="py-2 px-4">
                                <p className="text-[12px]">{drug.drug.name}</p>
                            </td>
                            <td className="py-2 px-4 text-center">
                                <p className="text-[12px]">{drug.drug?.doctorReportRequired ? 'Yes' :'No'}</p>
                            </td>
                            <td className="py-2 px-4 text-center">
                                <p className="text-[12px]">{drug.quantity}</p>
                            </td>
                            <td className="py-2 px-4 text-center">
                                <p className="text-[12px]">{standardAmountFormat(drug.price,currency)}</p>
                            </td>
                            <td className="py-2 px-4 text-center">
                                <p className="text-[12px]">{standardAmountFormat((drug.price * drug.quantity),currency)} </p>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td className="py-2 px-4 text-center">
                            <p className="text-[12px]">No drugs selected</p>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>

        
    </div>
</div>

                                </div>
                            </div>
                            <div className="flex flex-col lg:w-1/3 gap-6 ">
                            <div className="flex-col py-4 font-700 text-xl text-black border-transparent bg-[#FFFFFF]  rounded-2xl max-w-[359px] flex p-4">
                            <div className='flex flex-col border-b border-[#D8C2BF] pb-4'>

                                   
<h1 className='text-[20px]  text-[#201A19] font-medium '>Payment Info</h1>
<p className='text-[#201A19] font-medium text-[11px]'>Kindly select a payment method</p>
    
    <div className='flex flex-col items-left justify-between'>
    <div className='flex justify-between items-center'>
                                <Controller
                                    name="paymentMethod"
                                    control={control}
                                    defaultValue=""
                                rules={{ required: 'Payment Method is required' }}
                                    render={({ field }) => (
                                        <FormControl component="fieldset" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '16px' }}>
                                            <RadioGroup {...field} row>
                                                {/* <FormControlLabel value="tmr-wallet" control={<Radio />} label="TMR App" /> */}
                                                <FormControlLabel value="cash" control={<Radio />} label="Cash" />
                                                <FormControlLabel value="card" control={<Radio />} label="Transfer (Pos, Bank Transfer etc)  " />
                                            </RadioGroup>
                                        </FormControl>
                                    )}
                                />
                                </div> 
            
</div>
</div>
                            <h1 className='text-[#201A19] font-medium text-[20px] pt-4 pb-2'>Order Summary</h1>
                              
                               <div className='border-t border-[#D8C2BF] w-full py-2 my-2 flex flex-row items-center justify-between'>
                                    <p className='font-medium text-[12px] text-[#534341]'>Grand Total</p>
                                    <p className='text-[#C00010] font-medium text-[14px] '> {formattedAmount}</p>
                                    </div>
                                
                                
                            <button
                                disabled={!isDirty || !isValid || orderData.selectedDrugs.length === 0 || isloading}
                                type="submit"
                                className={`w-full my-3 px-6 py-2 text-lightBrown text-base font-normal rounded-lg ${
                                   isloading || !isDirty || !isValid || orderData.selectedDrugs.length === 0
                                        ? 'bg-lightGray cursor-not-allowed'
                                        : ' bg-Tmred text-white hover:cursor-pointer'
                                }`}
                            >
                                {isloading ? 'Creating...' : 'Create Order'}
                               

                            </button>
                            </div>
                            </div>
            </div>
            </form>
    </div>
  )
}

export default PreviewOrder



