import { useState, useEffect, useRef } from 'react';
import { useModal } from '../../../../contexts/ModalContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHospitalAuthStore, usePatientDataSearchStore } from '../../../../store/hospital/auth';
import logger from '../../../../utils/logger';
import hospitalService from '../../../../services/hospitalService';
import patientService from '../../../../services/patientService';
import BlockButton from '../../../../reusable/BlockButton';


const HealthRequestModal = ({eNumCondition, result, tmrId} ) => {
    const [isLoading, setIsLoading] = useState(false)
    const patientData = usePatientDataSearchStore((state) => state.searchedPatient)
    const hospitalData = useHospitalAuthStore((state) => state.hospital); 
    const [patientTMR, setPatientTMR] = useState<any>(tmrId)
    const [patientId, setPatientId] = useState<any>(patientData._id)
    const [selectedPatient, setSelectedPatient] = useState(null);
    
    const { closeModal } = useModal();
    
    const modalRef = useRef<HTMLDivElement | null>(null);

    const handleCloseModal = () => {
        closeModal();
    };
    
    const handleOverlayClick = (event: any) => {
        if (
            modalRef.current &&
            !modalRef.current.contains(event.target as Node)
        ) {
            closeModal();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOverlayClick);

        return () => {
            document.removeEventListener('mousedown', handleOverlayClick);
        };
    }, []);

    const createHealthRequest = () =>{
        setIsLoading(true)
        patientService.createHealthRequest({
            patientId: patientId,
            hospitalId: hospitalData.id,
            name: hospitalData.name,
            logo: hospitalData.logo,
            tmrNumber: patientTMR,
            licenseNumber: hospitalData.licenseNumber,
            address: hospitalData.address
        })
        .then((res) =>{
            logger(res?.data?.message)
            toast.success(res?.data?.message)
            setIsLoading(false)
            setTimeout(() => {
                closeModal()
            }, 1000);
        })
        .catch((err) => {
        logger(err.response);
        toast.error(err?.response?.data?.error?.message);
        setIsLoading(false)
        setTimeout(() => {
                closeModal()
            }, 1000);
        })
    }

    const createHRwithEmNumber = () =>{
        setIsLoading(true)
        patientService.createHRwithEmNumber({
            tmrNumber: tmrId,
            condition: eNumCondition,
            result: result,
        })
        .then((res) =>{
            logger(res?.data?.message)
            toast.success(res?.data?.message)
            setIsLoading(false)
            setTimeout(() => {
                closeModal()
            }, 1000);
        })
        .catch((err) => {
        logger(err.response);
        toast.error(err?.response?.data?.error?.message);
        setIsLoading(false)
        setTimeout(() => {
                closeModal()
            }, 1000);
        })
    }

    const handleCreateRequest = () =>{
        result ? createHRwithEmNumber() :
        createHealthRequest()
        
    }

    const handleSetPatient = (patient : any) => {
        console.log(patient); 
        setSelectedPatient(patient._id)
        setPatientTMR(patient.tmrNumber)
        setPatientId(patient._id)
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-[#2826265E] bg-opacity-75 z-50">
            <div
                ref={modalRef}
                className="bg-white w-[500px] md:p-6 p-2 rounded-[28px] mx-2 "
            >
                {patientData.length > 1? 
                    <>
                        <div className="my-4">
                            <h1 className=" text-Tmred text-[24px] font-semibold">{patientData.length} Patient's results </h1>

                            <p className="my-5">Kindly select the patients</p>
                        </div>

                        <div className="flex flex-col gap-4">
                            { patientData.map((patient, index) => (
                                <div className={`flex gap-5 hover:bg-[#EDE0DE] p-5 rounded-lg ${selectedPatient === patient._id ? 'bg-[#EDE0DE]' : ''}`}
                                    onClick={ () => handleSetPatient(patient)}
                                >
                                    <div>
                                        <img src={patient.image} className=" h-16 w-16 rounded-full " alt="" />
                                    </div>
                                    <div className="flex flex-col justify-center">
                                        <h1 className=" text-[18px] font-bold ">
                                            {patient.firstName} {patient.lastName} 
                                        </h1>
                                        <p className="text-[14px]">
                                            {patient.email}
                                        </p>
                                    </div>
                                </div>
                            )) }
                        </div>

                        <div className="flex gap-5 mt-10 justify-end">
                                <button
                                    className="text-Tmred border-[1px] border-lightBrown100 px-6 py-2 text-base font-normal rounded-lg"
                                    onClick={handleCloseModal}
                                >
                                    Cancel
                                </button>

                                <BlockButton
                                    text="Send Health Request"
                                    onclick={handleCreateRequest}
                                    type="submit"
                                    isLoading = {isLoading}
                                    className="!w-auto p-3"
                                />
                        </div>
                    </>
                    :
                    <>
                        <div className=" flex gap-5">
                            <div>
                                <img src={patientData[0]?.image} className=" h-24 w-24 rounded-full " alt="" />
                            </div>
                            <div>
                                <h1 className=" text-[30px] font-bold ">
                                    {patientData[0]?.firstName} {patientData[0]?.lastName} 
                                </h1>
                                <p className="text-[14px]">
                                    {patientData[0]?.email}
                                </p>
                            </div>
                        </div>

                        <div className="flex gap-5 mt-10 justify-end">
                                <button
                                    className="text-Tmred border-[1px] border-lightBrown100 px-6 py-2 text-base font-normal rounded-lg"
                                    onClick={handleCloseModal}
                                >
                                    Cancel
                                </button>

                                <BlockButton
                                    text="Send Health Request"
                                    onclick={handleCreateRequest}
                                    type="submit"
                                    isLoading = {isLoading}
                                    className="!w-auto p-3"
                                />
                        </div>
                    </>
                
                }
                
                
            </div>        
        </div>
    )
}

export default HealthRequestModal