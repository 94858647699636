interface LabTest {
    testName: string;
    group: string;
    type: string;
   ResultsTime?:any;
   Instructions?:any;
   Price?:any
}

// Sample data converted from Excel sheet
export const labTests: LabTest[] = [
    {
      "testName": "Auto-Immune Profile",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "3 hours/24 hours",
      "Instructions": "",
      "Price": "\"₦ 79"
    },
    {
      "testName": "AST (SGOT)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Aspirate Specific Gravity (ASP-SG)",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Aspirate Analysis",
      "group": "Microbiology",
      "type": "Panel",
      "ResultsTime": "24 hours/3 days",
      "Instructions": "",
      "Price": "\"₦ 18"
    },
    {
      "testName": "Aspergillus terreus Ab.IgG M36G",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 27"
    },
    {
      "testName": "Aspergillus niger Ab.IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 27"
    },
    {
      "testName": "Aspergillus fumigatus Ab.IgG",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 32"
    },
    {
      "testName": "Aspergillus Antigen in Serum (Galactomannan Assay)",
      "group": "Infection Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 38"
    },
    {
      "testName": "Aspergillus Antigen in BAL (Galactomannan Assay)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "Arylsulfatase A in Serum",
      "group": "Metabolism",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 49"
    },
    {
      "testName": "Arylsulfatase A in Leukocytes",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 80"
    },
    {
      "testName": "Arthritis Screen",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "3 hours/14 days",
      "Instructions": "",
      "Price": "\"₦ 20"
    },
    {
      "testName": "Arsenic Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Arsenic Urine",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 203"
    },
    {
      "testName": "Arsenic Serum",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 37"
    },
    {
      "testName": "Arsenic Blood",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 37"
    },
    {
      "testName": "Array Based Comparative Genomic Hybridization (Array-CGH)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "8 weeks",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Arabinose*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 256"
    },
    {
      "testName": "Aquaporin 4 IgG Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 71"
    },
    {
      "testName": "Aquaporin 4 Abs (CSF)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 31"
    },
    {
      "testName": "APTT",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 7"
    },
    {
      "testName": "Apolipoprotein E",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "Apolipoprotein B",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Apolipoprotein A2",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Apolipoprotein A1 Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 23"
    },
    {
      "testName": "Apolipoprotein A1",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "APOL1 Gene Analysis",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "56 days",
      "Instructions": "",
      "Price": "\"₦ 490"
    },
    {
      "testName": "Antithrombin III*",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 62"
    },
    {
      "testName": "Antithrombin Ag*",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 43"
    },
    {
      "testName": "Antioxidant Profile",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 317"
    },
    {
      "testName": "Antinuclear Antibodies (ANA)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 42"
    },
    {
      "testName": "Antimony Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 16"
    },
    {
      "testName": "Antimony Urine",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 180"
    },
    {
      "testName": "Anti-Streptolysin O",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 17"
    },
    {
      "testName": "Anti-Phospholipid IgM",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 45"
    },
    {
      "testName": "Anti-Phospholipid IgG",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 45"
    },
    {
      "testName": "Anti-Neuronal Nuclear Antibodies 3 Abs (ANNA III) CSF",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "Anti-Neuronal Nuclear Antibodies 3 Abs (ANNA III)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "Anti-Neuronal Nuclear Ab Type 3 (ANNA type III) ANNA-III abs in serum",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Anti-Neuronal Nuclear Ab Type 2 (ANNA type II) Ri-antigen abs in serum",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Anti-Neuronal Nuclear Ab Type 1 (ANNA type I) Hu D-antigen abs in serum",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Anti-Mullerian Hormone",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 31"
    },
    {
      "testName": "Anti-Factor VIII (Anti Factor 8)*",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 62"
    },
    {
      "testName": "Anti-B2-Glycoprotein IgM Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 49"
    },
    {
      "testName": "Anti-B2-Glycoprotein IgG Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 49"
    },
    {
      "testName": "Anti Factor Xa Activity*",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 43"
    },
    {
      "testName": "Anti DNA'se B",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 49"
    },
    {
      "testName": "Antenatal Screen",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "3 hours/24 hours/14 days",
      "Instructions": "",
      "Price": "\"₦ 73"
    },
    {
      "testName": "Annexin V IgG Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "Angiotensin II*",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 111"
    },
    {
      "testName": "Angiotensin Converting Enzyme CSF",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Angiotensin Converting Enzyme ACE",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Angiotensin Converting Enzyme (ACE) Ins/Del Polymorphism",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "17 days",
      "Instructions": "",
      "Price": "\"₦ 167"
    },
    {
      "testName": "Androstenedione",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "ANDROGEN RECEPTOR TEST",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 407"
    },
    {
      "testName": "\"ANCA (c-ANCA",
      "group": "p-ANCA) Combi-Test\"",
      "type": "Immunology",
      "ResultsTime": "Single Test",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "Anaerobic Culture",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Amyloid A Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "Amylase Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 7"
    },
    {
      "testName": "Amylase Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 7"
    },
    {
      "testName": "Amylase Aspirate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 7"
    },
    {
      "testName": "Amphiphysin 1 Abs CSF (IFT)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "Amphiphysin 1 Abs (IFT)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "Amphiphysin 1 Abs (Blot)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "Amphetamines Serum GCMS",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 103"
    },
    {
      "testName": "Amphetamines Saliva",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Amphetamines Hair",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 150"
    },
    {
      "testName": "Amphetamine Urine",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 17"
    },
    {
      "testName": "Ammonia Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "Ammonia Plasma*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Amlodipine",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Amitriptyline in Urine",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Amitriptyline and metabolites (N-Oxid therapy)",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 100"
    },
    {
      "testName": "Amino Acids in urine*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 57"
    },
    {
      "testName": "Amino Acids in plasma/serum*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 165"
    },
    {
      "testName": "Amikacin Pre",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 58"
    },
    {
      "testName": "Amikacin Post",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 58"
    },
    {
      "testName": "Aluminium Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "Aluminium Urine",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 51"
    },
    {
      "testName": "Aluminium Serum",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 51"
    },
    {
      "testName": "ALT (SGPT)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Alpha-Glucosidase 1",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 86"
    },
    {
      "testName": "Alpha-Galactosidase in Serum*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 51"
    },
    {
      "testName": "Alpha-1-Fetoprotein Aspirate",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Alpha-1-Fetoprotein (Downs)",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Alpha-1-Fetoprotein",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 17"
    },
    {
      "testName": "Alpha-1-Antitrypsin Stool",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 34"
    },
    {
      "testName": "Alpha-1-Antitrypsin Phenotyping",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 104"
    },
    {
      "testName": "Alpha-1-antitrypsin",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 20"
    },
    {
      "testName": "Alpha Iduronidase",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 59"
    },
    {
      "testName": "Alpha - Thalassaemia Gene SEQ",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 744"
    },
    {
      "testName": "Alpha - Thalassaemia Gene MLPA",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 309"
    },
    {
      "testName": "Alkaline Phosphatase Isoenzymes",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 71"
    },
    {
      "testName": "Alkaline Phosphatase (ALP)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Aldosterone/Renin*",
      "group": "Endocrinology",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 137"
    },
    {
      "testName": "Aldosterone and metabolites in urine / 24h",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 41"
    },
    {
      "testName": "Aldosterone",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Aldolase",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 49"
    },
    {
      "testName": "Alcohol Blood",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Albumin Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 11"
    },
    {
      "testName": "Albumin Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Albumin Gradient Profile",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 4"
    },
    {
      "testName": "Albumin Aspirate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Albumin 24hr Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 15"
    },
    {
      "testName": "Albinism Panel",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "42 days",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Albinism Oculocutaneous Type IV (SLC45A2)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "42 days",
      "Instructions": "",
      "Price": "\"₦ 300"
    },
    {
      "testName": "Albinism Oculocutaneous (TYR)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "42 days",
      "Instructions": "",
      "Price": "\"₦ 300"
    },
    {
      "testName": "Albinism Ocular Type I (GPR143)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "42 days",
      "Instructions": "",
      "Price": "\"₦ 208"
    },
    {
      "testName": "Albinism (OCA2)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "42 days",
      "Instructions": "",
      "Price": "\"₦ 810"
    },
    {
      "testName": "Aerobic Culture",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "7 days",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "ADULT SYNDROME TP63",
      "group": "Molecular Genetics",
      "type": "Single Test",
      "ResultsTime": "30 days",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Adult Food Screen",
      "group": "Allergy",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 82"
    },
    {
      "testName": "Adrenal Cortex Abs",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Admission Panel",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 37"
    },
    {
      "testName": "Adiponectin*",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 50"
    },
    {
      "testName": "Adenovirus Stool",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 8"
    },
    {
      "testName": "Adenovirus IgM Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Adenovirus IgG Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Adenovirus DNA CSF",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 146"
    },
    {
      "testName": "Adenovirus DNA",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 294"
    },
    {
      "testName": "Adenosine Deaminase Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 199"
    },
    {
      "testName": "Adenosine Deaminase Aspirate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 26"
    },
    {
      "testName": "Adenosine Deaminase (ADA) CSF",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 26"
    },
    {
      "testName": "ADAMTS-13 Antigen*",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 62"
    },
    {
      "testName": "ADAMTS-13 Antibodies*",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 31"
    },
    {
      "testName": "ADAMTS-13 Activity* (Willebrand factor-cleaving activity)",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 62"
    },
    {
      "testName": "Acute Myeloid Leukemia (AML) Panel (Molecular Analysis)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 529"
    },
    {
      "testName": "Acute Lymphoblastic Leukaemia (ALL) - WT1 quantitative PCR",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 517"
    },
    {
      "testName": "Acute Lymphoblastic Leukaemia (ALL) - TCF3-PBX1 quantitative PCR",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 517"
    },
    {
      "testName": "Acute Lymphoblastic Leukaemia (ALL) - Peripheral Blood Smear",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 19"
    },
    {
      "testName": "Acute Lymphoblastic Leukaemia (ALL) - KMT2A-AFF1 quantitative PCR",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 517"
    },
    {
      "testName": "Acute Lymphoblastic Leukaemia (ALL) - Bone Marrow Aspirate",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 102"
    },
    {
      "testName": "Acute Hepatitis Panel",
      "group": "Serology",
      "type": "Panel",
      "ResultsTime": "24 hours/3 days",
      "Instructions": "",
      "Price": "\"₦ 45"
    },
    {
      "testName": "Active Vitamin B12 Holo-Transcobalamin (holoTC)",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 59"
    },
    {
      "testName": "Activated Protein C Resistance*",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Actin Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "\"ACTH",
      "group": "Adrenocorticotrophic Hormone*\"",
      "type": "Chemistry",
      "ResultsTime": "Single Test",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "ACTH Stimulation (Synacthen)",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 48"
    },
    {
      "testName": "Acid Phosphatase (Prostatic)*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 10"
    },
    {
      "testName": "\"Acid Fast Stain",
      "group": "Other Materials (AFB)\"",
      "type": "Microbiology",
      "ResultsTime": "Single Test",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "Achondroplasia (FGFR3 gene) 2nd tier",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "28 days",
      "Instructions": "",
      "Price": "\"₦ 929"
    },
    {
      "testName": "Achondroplasia (FGFR3 gene) 1st tier",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 319"
    },
    {
      "testName": "Acetylcholine Receptor Ab",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Acetoacetate*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Acetazolamid",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 55"
    },
    {
      "testName": "Buprenorphine in Urine",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 148"
    },
    {
      "testName": "Buprenorphine in Serum",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 148"
    },
    {
      "testName": "Buprenorphine",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 31"
    },
    {
      "testName": "Brucella IgM Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 20"
    },
    {
      "testName": "Brucella IgG Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 20"
    },
    {
      "testName": "BREAST CANCER PANEL. NGS (PROSIGNA-PAM 50)",
      "group": "Molecular Genetics",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Breast Cancer - HER2/NEU (FISH Analysis)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 364"
    },
    {
      "testName": "BRCA 1 & 2 & 16 DNA",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 942"
    },
    {
      "testName": "Brain Natriuretic Peptide B-Type (BNP)*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 27"
    },
    {
      "testName": "BRAF- (V600E) Mutation",
      "group": "Cytogenetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 246"
    },
    {
      "testName": "\"Borrelia IgM ab index",
      "group": "CSF / Serum\"",
      "type": "Serology",
      "ResultsTime": "Single Test",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "\"Borrelia IgG ab index",
      "group": "CSF / Serum\"",
      "type": "Serology",
      "ResultsTime": "Single Test",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "Borrelia DNA (PCR)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 61"
    },
    {
      "testName": "Borrelia Burgdorferi IgM Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "Borrelia Burgdorferi IgG Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "Borrelia burgdorferi DNA in CSF (PCR)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 61"
    },
    {
      "testName": "Borehole Water",
      "group": "ENVIRNOMENTAL",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 263"
    },
    {
      "testName": "Bordetella pertussis IgG Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Bordetella pertussis IgA Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Bordetella pertussis DNA (PCR)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 52"
    },
    {
      "testName": "BOD: Biological Oxygen Demand Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "BNP-NT-Propeptid (pro-BNP)*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 140"
    },
    {
      "testName": "Blue Wellness Test",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "Blood Group Urgent",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "6 hours",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Blood Group and Genotype",
      "group": "Haematology",
      "type": "Panel",
      "ResultsTime": "24 hrs",
      "Instructions": "",
      "Price": "\"₦ 16"
    },
    {
      "testName": "Blood Group",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 4"
    },
    {
      "testName": "Blood Culture Paediatric",
      "group": "Microbiology",
      "type": "Panel",
      "ResultsTime": "7 days",
      "Instructions": "",
      "Price": "\"₦ 18"
    },
    {
      "testName": "Blood Culture Adult",
      "group": "Microbiology",
      "type": "Panel",
      "ResultsTime": "7 days",
      "Instructions": "",
      "Price": "\"₦ 18"
    },
    {
      "testName": "BLOOD CROSSMATCH & SCREENING",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 14"
    },
    {
      "testName": "BIOPSY",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 115"
    },
    {
      "testName": "BIOMARKERS IN SOLID TUMORS. TP53(17p13). FISH",
      "group": "Cytogenetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 434"
    },
    {
      "testName": "\"BIOMARKERS IN SOLID TUMORS - PDGFRA (12",
      "group": "14",
      "type": "18) - HOT SPOT\"",
      "ResultsTime": "Molecular Genetics",
      "Instructions": "Single Test",
      "Price": "14 days"
    },
    {
      "testName": "\"BIOMARKERS IN SOLID TUMORS - NRAS(2",
      "group": "3",
      "type": "4) - HOT SPOT\"",
      "ResultsTime": "Cytogenetics",
      "Instructions": "Single Test",
      "Price": "14 days"
    },
    {
      "testName": "BIOMARKERS IN SOLID TUMORS - MLH1 - METHYLATION",
      "group": "Molecular Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 452"
    },
    {
      "testName": "\"BIOMARKERS IN SOLID TUMORS - KRAS(2",
      "group": "3",
      "type": "4) - HOT SPOT (TISSUE)\"",
      "ResultsTime": "Cytogenetics",
      "Instructions": "Single Test",
      "Price": "14 days"
    },
    {
      "testName": "\"BIOMARKERS IN SOLID TUMORS - IDH1",
      "group": "IDH2 - HOT SPOT (TISSUE)\"",
      "type": "Molecular Genetics",
      "ResultsTime": "Single Test",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "\"BIOMARKERS IN SOLID TUMORS - BRCA1",
      "group": "BRCA2 - NGS (TISSUE)\"",
      "type": "Molecular Genetics",
      "ResultsTime": "Single Test",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "Biochemistry Urine",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Bilirubin Total",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Bilirubin Conjugated",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Bilirubin",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Bile Salts Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 4"
    },
    {
      "testName": "Bile Acids (Choleglycins)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 44"
    },
    {
      "testName": "Bicarbonates (CO2)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Bicarbonate in Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 26"
    },
    {
      "testName": "BHCG Screen",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "6 hours",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "bHCG Aspirate",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "6 hours",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "BHCG (Male)",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "6 hours",
      "Instructions": "",
      "Price": "\"₦ 11"
    },
    {
      "testName": "Beta-Glucosidase",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 91"
    },
    {
      "testName": "Beta-Galactosidase",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 86"
    },
    {
      "testName": "Beta-2-Microglobulin",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Beta-2 Transferrin (Liquorrhoe)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 97"
    },
    {
      "testName": "Beta - Thalassaemia Gene SEQ",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 605"
    },
    {
      "testName": "Beta - Thalassaemia Gene MLPA",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 435"
    },
    {
      "testName": "Beta - HCG Qualitative",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Benzodiazepines urine GCMS",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 159"
    },
    {
      "testName": "Benzodiazepines Urine",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Benzodiazepines Serum",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 159"
    },
    {
      "testName": "Benzene",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 43"
    },
    {
      "testName": "Beckwith-Wiedemann Syndrome MS",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 596"
    },
    {
      "testName": "BCR-ABL Genotyping",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 889"
    },
    {
      "testName": "BCR-ABL DNA Quantification",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 590"
    },
    {
      "testName": "Basic Screen",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "Basic Metabolic Panel",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 12"
    },
    {
      "testName": "Barth Syndrome - TAZ Gene",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "30 days",
      "Instructions": "",
      "Price": "\"₦ 195"
    },
    {
      "testName": "Barbiturates Urine GCMS",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 159"
    },
    {
      "testName": "Barbiturates Urine",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "Barbiturates Serum",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 56"
    },
    {
      "testName": "Band 4 Histology x2",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "5 days",
      "Instructions": "",
      "Price": "\"₦ 103"
    },
    {
      "testName": "Band 4 Histology",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "5 days",
      "Instructions": "",
      "Price": "\"₦ 52"
    },
    {
      "testName": "Band 3 Histology x3",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "5 days",
      "Instructions": "",
      "Price": "\"₦ 113"
    },
    {
      "testName": "Band 3 Histology x2",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "5 days",
      "Instructions": "",
      "Price": "\"₦ 82"
    },
    {
      "testName": "Band 3 Histology",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "5 days",
      "Instructions": "",
      "Price": "\"₦ 41"
    },
    {
      "testName": "Band 2 Histology x3",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "5 days",
      "Instructions": "",
      "Price": "\"₦ 72"
    },
    {
      "testName": "Band 2 Histology x2",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "5 days",
      "Instructions": "",
      "Price": "\"₦ 56"
    },
    {
      "testName": "Band 2 Histology",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "5 days",
      "Instructions": "",
      "Price": "\"₦ 28"
    },
    {
      "testName": "Band 1 Histology x4",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "5 days",
      "Instructions": "",
      "Price": "\"₦ 58"
    },
    {
      "testName": "Band 1 Histology x3",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "5 days",
      "Instructions": "",
      "Price": "\"₦ 52"
    },
    {
      "testName": "Band 1 Histology x2",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "5 days",
      "Instructions": "",
      "Price": "\"₦ 41"
    },
    {
      "testName": "Band 1 Histology",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "5 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Bacteriological Water Analysis Panel 4",
      "group": "ENVIRNOMENTAL",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 84"
    },
    {
      "testName": "Bacteriological Water Analysis Panel 3",
      "group": "ENVIRNOMENTAL",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 69"
    },
    {
      "testName": "Bacteriological Water Analysis Panel 2",
      "group": "ENVIRNOMENTAL",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 69"
    },
    {
      "testName": "Bacteriological Water Analysis Panel 1",
      "group": "ENVIRNOMENTAL",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 49"
    },
    {
      "testName": "Bacteriological Bath/Pool Water Analysis + Legionella",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 108"
    },
    {
      "testName": "Bacteriological Bath/Pool Water Analysis",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 57"
    },
    {
      "testName": "Bacillus cereus Food",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 32"
    },
    {
      "testName": "Babesia microti IgG Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 7"
    },
    {
      "testName": "\"B2-Glycoprotein",
      "group": "IgG/A/M\"",
      "type": "Immunology",
      "ResultsTime": "Single Test",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "B-Non-Hodgkin Lymphoma Panel (B-NHL Specification)",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 536"
    },
    {
      "testName": "B Vitamin Profile",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "\"3 days",
      "Instructions": "14 days\"",
      "Price": ""
    },
    {
      "testName": "Cytotoxic anti-HLA abs",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 174"
    },
    {
      "testName": "Cytoplasmic ANCA (cANCA)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 52"
    },
    {
      "testName": "Cytomegalovirus IgM Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "Cytomegalovirus IgG Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Cytomegalovirus DNA (CSF)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 41"
    },
    {
      "testName": "Cytomegalovirus DNA",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 247"
    },
    {
      "testName": "Cytology Semen",
      "group": "Cytopathology",
      "type": "Single Test",
      "ResultsTime": "7 days",
      "Instructions": "",
      "Price": "\"₦ 13"
    },
    {
      "testName": "\"Cytogenetics Other",
      "group": "Karyotyping\"",
      "type": "Genetics",
      "ResultsTime": "Single Test",
      "Instructions": "21 days",
      "Price": ""
    },
    {
      "testName": "\"Cytogenetics Bone Marrow",
      "group": "Karyotyping\"",
      "type": "Genetics",
      "ResultsTime": "Single Test",
      "Instructions": "21 days",
      "Price": ""
    },
    {
      "testName": "\"Cytogenetics Blood",
      "group": "Karyotyping\"",
      "type": "Genetics",
      "ResultsTime": "Single Test",
      "Instructions": "21 days",
      "Price": ""
    },
    {
      "testName": "Cystine 24hr Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 41"
    },
    {
      "testName": "\"Cysticercosis (Taenia solium) Abs",
      "group": "Westernblot\"",
      "type": "Serology",
      "ResultsTime": "Single Test",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "Cysticercosis (Taenia solium) Abs CSF",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 38"
    },
    {
      "testName": "Cysticercosis (Taenia solium) Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 38"
    },
    {
      "testName": "Cystic Fibrosis Gene Sequencing",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "30 days",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Cystic Fibrosis Gene MLPA",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "30 days",
      "Instructions": "",
      "Price": "\"₦ 604"
    },
    {
      "testName": "Cystic Fibrosis F508 Deletion Gene",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "30 days",
      "Instructions": "",
      "Price": "\"₦ 240"
    },
    {
      "testName": "Cysteine in Leukocytes",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 84"
    },
    {
      "testName": "Cystatin C Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 32"
    },
    {
      "testName": "Cystatin C Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 41"
    },
    {
      "testName": "Cystatin C & eGFR Serum",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 41"
    },
    {
      "testName": "CYP21A2 MLPA",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "45 days",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "CYP17A1 MLPA",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "45 days",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "CYFRA 21-1*",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 96"
    },
    {
      "testName": "Cyclosporine Blood",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 52"
    },
    {
      "testName": "Cyclic Citrullinated Peptide Ab (Anti-CCP)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 41"
    },
    {
      "testName": "Cyanide Free/Total Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "CXCL13 in CSF",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 73"
    },
    {
      "testName": "CV-2 Abs CSF (IFT)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "CV-2 Abs CSF (Blot)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "CV-2 Abs (Blot)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "\"Culture",
      "group": "ID & Sens Water\"",
      "type": "Microbiology",
      "ResultsTime": "Single Test",
      "Instructions": "5 days",
      "Price": ""
    },
    {
      "testName": "\"Culture",
      "group": "ID & Sens Urine\"",
      "type": "Microbiology",
      "ResultsTime": "Single Test",
      "Instructions": "",
      "Price": ""
    },
    {
      "testName": "\"Culture",
      "group": "ID & Sens Throat\"",
      "type": "Microbiology",
      "ResultsTime": "Single Test",
      "Instructions": "",
      "Price": ""
    },
    {
      "testName": "\"Culture",
      "group": "ID & Sens Swab (PUS)\"",
      "type": "Microbiology",
      "ResultsTime": "Single Test",
      "Instructions": "",
      "Price": ""
    },
    {
      "testName": "\"Culture",
      "group": "ID & Sens Stool (Paediatric)\"",
      "type": "Microbiology",
      "ResultsTime": "Single Test",
      "Instructions": "5 days",
      "Price": ""
    },
    {
      "testName": "\"Culture",
      "group": "ID & Sens Stool\"",
      "type": "Microbiology",
      "ResultsTime": "Single Test",
      "Instructions": "",
      "Price": ""
    },
    {
      "testName": "\"Culture",
      "group": "ID & Sens STD\"",
      "type": "Microbiology",
      "ResultsTime": "Single Test",
      "Instructions": "",
      "Price": ""
    },
    {
      "testName": "\"Culture",
      "group": "ID & Sens Products (Food)\"",
      "type": "Microbiology",
      "ResultsTime": "Single Test",
      "Instructions": "5 days",
      "Price": ""
    },
    {
      "testName": "\"Culture",
      "group": "ID & Sens CSF\"",
      "type": "Microbiology",
      "ResultsTime": "Single Test",
      "Instructions": "",
      "Price": ""
    },
    {
      "testName": "\"Culture",
      "group": "ID & Sens Blood Culture\"",
      "type": "Microbiology",
      "ResultsTime": "Single Test",
      "Instructions": "",
      "Price": ""
    },
    {
      "testName": "\"Culture",
      "group": "ID & Sens Bacteria\"",
      "type": "Microbiology",
      "ResultsTime": "Single Test",
      "Instructions": "5 days",
      "Price": ""
    },
    {
      "testName": "\"Culture",
      "group": "ID & Sens Aspirate\"",
      "type": "Microbiology",
      "ResultsTime": "Single Test",
      "Instructions": "",
      "Price": ""
    },
    {
      "testName": "Culture Fungal",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "CT - Provasopressin /Copeptin (ADH)",
      "group": "Endocrinology",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 97"
    },
    {
      "testName": "CSF/SERUM IgG INDEX",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 15"
    },
    {
      "testName": "CSF Analysis",
      "group": "Microbiology",
      "type": "Panel",
      "ResultsTime": "24 hours/3 days",
      "Instructions": "",
      "Price": "\"₦ 20"
    },
    {
      "testName": "Cryptosporidium Stain",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 5"
    },
    {
      "testName": "Cryptosporidium sp Ag",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "Cryptococcus Ag CSF",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "Cryoglobulines",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 68"
    },
    {
      "testName": "Cresol Urine (Toluene)",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 43"
    },
    {
      "testName": "Creatinkinase-Isoenzymes (CK-Isoenzymes)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 65"
    },
    {
      "testName": "Creatinine Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Creatinine Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Creatinine Dialysate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 5"
    },
    {
      "testName": "Creatinine Clearance 24hr Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Creatinine Clearance",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Creatinine Aspirate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Creatine Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Creatine Kinase Total (CK)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 6"
    },
    {
      "testName": "Creatine 24hr Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Coxsackie Virus IgM Ab (Enterovirus)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 26"
    },
    {
      "testName": "Coxsackie Virus IgG Ab (Enterovirus)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 26"
    },
    {
      "testName": "COVID-19 Risk Assessment: Suppressed Immunity",
      "group": "COVID",
      "type": "Panel",
      "ResultsTime": "3 hours/24 hours",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "COVID-19 Risk Assessment: Liver",
      "group": "COVID",
      "type": "Panel",
      "ResultsTime": "3 hours/24 hours",
      "Instructions": "",
      "Price": "\"₦ 40"
    },
    {
      "testName": "COVID-19 Risk Assessment: Kidney",
      "group": "COVID",
      "type": "Panel",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 26"
    },
    {
      "testName": "COVID-19 Risk Assessment: Infection Screening",
      "group": "COVID",
      "type": "Panel",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 14"
    },
    {
      "testName": "COVID-19 Risk Assessment: HIV",
      "group": "COVID",
      "type": "Panel",
      "ResultsTime": "3 hours/24 hours",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "COVID-19 Risk Assessment: Hepatitis B",
      "group": "COVID",
      "type": "Panel",
      "ResultsTime": "3 hours/24 hours/ 14 days",
      "Instructions": "",
      "Price": "\"₦ 51"
    },
    {
      "testName": "COVID-19 Risk Assessment: Diabetes",
      "group": "COVID",
      "type": "Panel",
      "ResultsTime": "3 hours/14 days",
      "Instructions": "",
      "Price": "\"₦ 43"
    },
    {
      "testName": "Cotinine Urine",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 144"
    },
    {
      "testName": "Cotinine Serum",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 144"
    },
    {
      "testName": "Cortisol Saliva Sample 6",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Cortisol Saliva Sample 5",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Cortisol Saliva Sample 4",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Cortisol Saliva Sample 3",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Cortisol Saliva Sample 2",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Cortisol Saliva Sample 1",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Cortisol Random",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 12"
    },
    {
      "testName": "Cortisol Before Dmz",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 13"
    },
    {
      "testName": "Cortisol After Dmz",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 13"
    },
    {
      "testName": "Cortisol (AM/Fasting)",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "3 days",
      "Instructions": "",
      "Price": "\"₦ 12"
    },
    {
      "testName": "Copper Water",
      "group": "Water Analysis",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Copper Urine",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 103"
    },
    {
      "testName": "Copper Serum",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 75"
    },
    {
      "testName": "Copper in Hair (Cu)",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 32"
    },
    {
      "testName": "Copper 24hr Urine*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 18"
    },
    {
      "testName": "Coombs Test",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 6"
    },
    {
      "testName": "Conductivity (Water GmbH)",
      "group": "Water Analysis",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 19"
    },
    {
      "testName": "Concentration Parasites",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 4"
    },
    {
      "testName": "Comprehensive Metabolic Panel",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "Complement C5",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 50"
    },
    {
      "testName": "Complement C4",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 19"
    },
    {
      "testName": "Complement C3d*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 50"
    },
    {
      "testName": "Complement C3",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 20"
    },
    {
      "testName": "Complement C2",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 34"
    },
    {
      "testName": "Complement C1q",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Complement C1 Esterase Inhibitor*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 27"
    },
    {
      "testName": "\"Colour",
      "group": "Odour",
      "type": "Appearance (Water GmbH)\"",
      "ResultsTime": "Water Analysis",
      "Instructions": "Single Test",
      "Price": "14 days"
    },
    {
      "testName": "\"Colorectal Nopolyposis Hereditary Cancer (MSH6",
      "group": "EPCAM)\"",
      "type": "Genetics",
      "ResultsTime": "Single Test",
      "Instructions": "30 days",
      "Price": ""
    },
    {
      "testName": "Colony Count Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 28"
    },
    {
      "testName": "Collapsin Response Mediator Protein 5 Abs (CRMP5L) CSF",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "Collapsin Response Mediator Protein 5 Abs (CRMP5)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Colistin (Polymyxin E) in Serum*",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 159"
    },
    {
      "testName": "Colistin (Polymyxin E) in CSF",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 55"
    },
    {
      "testName": "Cold Agglutinins",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "10 days",
      "Instructions": "",
      "Price": "\"₦ 17"
    },
    {
      "testName": "COL-GENE (ALPORT SYNDROME) / MYH9 (FREELINER SYNDROME)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "30 days",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Coenzyme Q10",
      "group": "Molecular Genetics",
      "type": "Single Test",
      "ResultsTime": "45 days",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Coeliac Panel",
      "group": "Immunology",
      "type": "Panel",
      "ResultsTime": "#N/A",
      "Instructions": "",
      "Price": "\"₦ 130"
    },
    {
      "testName": "COD: Chemical Oxygen Demand Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Cocaine Urine GCMS",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 103"
    },
    {
      "testName": "Cocaine Urine",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Cocaine Serum GCMS",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 100"
    },
    {
      "testName": "Cocaine Saliva",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Cocaine Hair",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 150"
    },
    {
      "testName": "Cobalt Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "Cobalt in blood (Co)",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Clotting Profile",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "3 hrs",
      "Instructions": "",
      "Price": "\"₦ 15"
    },
    {
      "testName": "Clostridium perfringens Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "Clostridium perfringens Spores Food",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 32"
    },
    {
      "testName": "Clostridium perfringens Food",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 26"
    },
    {
      "testName": "Clostridium difficile Toxin PCR",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 146"
    },
    {
      "testName": "Clostridium difficile Toxin A+B",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 31"
    },
    {
      "testName": "Clopidogrel Resistance",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 66"
    },
    {
      "testName": "Clobazam and Metabolites",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 100"
    },
    {
      "testName": "CKMB (Cardiac Reader)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "CKITPC (KIT D816V MUTATION)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "30 days",
      "Instructions": "",
      "Price": "\"₦ 366"
    },
    {
      "testName": "Citrate Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Citrate in 24h Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 55"
    },
    {
      "testName": "Circulation Tumor Cells (ISET)",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 411"
    },
    {
      "testName": "CHRONIC MYELOID LEUKEMIA . BCR/ABL1(T315I) . HOT SPOT",
      "group": "Molecular Genetics",
      "type": "Single Test",
      "ResultsTime": "30 days",
      "Instructions": "",
      "Price": "\"₦ 261"
    },
    {
      "testName": "Chronic Hepatitis Panel",
      "group": "Serology",
      "type": "Panel",
      "ResultsTime": "24 hours/3 days",
      "Instructions": "",
      "Price": "\"₦ 59"
    },
    {
      "testName": "CHROMOSOME 17 - TP53(17p13) - FISH (BLOOD)",
      "group": "Cytogenetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 260"
    },
    {
      "testName": "Chromogranin A*",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 82"
    },
    {
      "testName": "Chromium Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Cholinesterase Mutation (Dibucain Number)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 17"
    },
    {
      "testName": "Cholinesterase",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 31"
    },
    {
      "testName": "Cholesterol HDL",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 4"
    },
    {
      "testName": "Cholesterol Aspirate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 10"
    },
    {
      "testName": "Chlorpyriphos Urine",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 101"
    },
    {
      "testName": "Chlorpyriphos Serum",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 101"
    },
    {
      "testName": "Chlorpromazine",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 43"
    },
    {
      "testName": "Chloride Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 32"
    },
    {
      "testName": "Chloride Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 7"
    },
    {
      "testName": "Chloride Stool",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "Chloride Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Chloride CSF",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 7"
    },
    {
      "testName": "Chloride Aspirate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 7"
    },
    {
      "testName": "Chlamydia trachomatis IgM Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Chlamydia trachomatis IgG Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Chlamydia trachomatis IgA Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 32"
    },
    {
      "testName": "Chlamydia trachomatis DNA",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 50"
    },
    {
      "testName": "Chlamydia trachomatis / Neisseria gonorrhoeae DNA(PCR)",
      "group": "PCR",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 72"
    },
    {
      "testName": "Chikungunya Virus-IgM abs (CHIKV)",
      "group": "Infection Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 28"
    },
    {
      "testName": "Chikungunya Virus-IgG abs (CHIKV)",
      "group": "Infection Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 28"
    },
    {
      "testName": "Chemistry CSF",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 5"
    },
    {
      "testName": "Chemistry Aspirate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 5"
    },
    {
      "testName": "Chemistry & Cell Count CSF",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 7"
    },
    {
      "testName": "Chemistry & Cell Count Aspirate",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 7"
    },
    {
      "testName": "Charcot-Marie-Tooth Disease Type 1A SEQ",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 420"
    },
    {
      "testName": "Charcot-Marie-Tooth Disease Type 1A MLPA",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 235"
    },
    {
      "testName": "Centromere Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 45"
    },
    {
      "testName": "Cell Culture Other",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "30 days",
      "Instructions": "",
      "Price": "\"₦ 128"
    },
    {
      "testName": "Cell Culture Bone Marrow",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "30 days",
      "Instructions": "",
      "Price": "\"₦ 171"
    },
    {
      "testName": "Cell Culture Blood",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "30 days",
      "Instructions": "",
      "Price": "\"₦ 37"
    },
    {
      "testName": "Cell Count Semen",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 4"
    },
    {
      "testName": "Cell Count Aspirate",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "CEA",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "3 days",
      "Instructions": "",
      "Price": "\"₦ 15"
    },
    {
      "testName": "CD4",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 32"
    },
    {
      "testName": "Catecholamines Blood*",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 192"
    },
    {
      "testName": "Catecholamines 24hr Urine",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 219"
    },
    {
      "testName": "CASPR2 Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 32"
    },
    {
      "testName": "Carnitine in serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 99"
    },
    {
      "testName": "Cardiovascular Plus",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "\"24 hrs",
      "Instructions": "14 days\"",
      "Price": ""
    },
    {
      "testName": "Cardiovascular Essential",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "24 hrs",
      "Instructions": "",
      "Price": "\"₦ 34"
    },
    {
      "testName": "Cardiolipin IgM Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 53"
    },
    {
      "testName": "Cardiolipin IgG Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 53"
    },
    {
      "testName": "Cardiolipin IgA/IgG/IgM",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 106"
    },
    {
      "testName": "Cardiolipin IgA Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 53"
    },
    {
      "testName": "Cardiolipin Antibodies",
      "group": "Immunology",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 106"
    },
    {
      "testName": "Cardiac Enzymes",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "3 hours/14 days",
      "Instructions": "",
      "Price": "\"₦ 18"
    },
    {
      "testName": "Carboxyhemoglobin (COHb)",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 55"
    },
    {
      "testName": "Carbohydrate Deficient Transferrin (CDT)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 43"
    },
    {
      "testName": "Carbamazepine (Tegretol)",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 41"
    },
    {
      "testName": "Cannabis urine GCMS",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 103"
    },
    {
      "testName": "Cannabis Urine",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Cannabis Hair",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 150"
    },
    {
      "testName": "Candida albicans DNA (PCR)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 52"
    },
    {
      "testName": "Campylobacter jejuni IgG Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 19"
    },
    {
      "testName": "Campylobacter jejuni IgA Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 19"
    },
    {
      "testName": "Campylobacter Culture",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "CALR (Calreticulin) Gene Mutation",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 529"
    },
    {
      "testName": "Calcium Water",
      "group": "Water Analysis",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "Calcium Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Calcium Sensing Receptor Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 41"
    },
    {
      "testName": "Calcium Dialysate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 5"
    },
    {
      "testName": "Calcium Creatinine Clearance Ratio",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 8"
    },
    {
      "testName": "Calcium Channel Abs (PQ-type)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 120"
    },
    {
      "testName": "Calcium Channel Abs (N-type)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 120"
    },
    {
      "testName": "Calcium Aspirate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Calcium 24hr Urine",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Calcium & Albumin Serum",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Calcitonin*",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Caeruloplasmin (Ceruloplasmin)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 10"
    },
    {
      "testName": "Cadmium Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Cadmium Urine",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 51"
    },
    {
      "testName": "Cadmium Blood",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "CA 72-4",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 45"
    },
    {
      "testName": "CA 50",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 58"
    },
    {
      "testName": "CA 27-29 []",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 677"
    },
    {
      "testName": "CA 19-9",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "3 days",
      "Instructions": "",
      "Price": "\"₦ 17"
    },
    {
      "testName": "CA 15-3",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "3 days",
      "Instructions": "",
      "Price": "\"₦ 17"
    },
    {
      "testName": "CA 125",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "3 days",
      "Instructions": "",
      "Price": "\"₦ 17"
    },
    {
      "testName": "c52-Diclofenac",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 104"
    },
    {
      "testName": "c51 - Lys-Aspirin",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 66"
    },
    {
      "testName": "c51 - Acetylsalicylic Acid (Aspirin) IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "c2 - Penicillin V",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 66"
    },
    {
      "testName": "C1q Binding Immune Complexes",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "C1-Esterase-Inhibitor Protein",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "C1-Esterase-Inhibitor Antibodies",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 31"
    },
    {
      "testName": "C1-Esterase-Inhibitor Activity*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 47"
    },
    {
      "testName": "\"C1-Esterase Inhibitor",
      "group": "Combi Test*\"",
      "type": "Chemistry",
      "ResultsTime": "Panel",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "c1 - Penicillin G",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 66"
    },
    {
      "testName": "C-Reactive Protein (CRP)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 10"
    },
    {
      "testName": "C-Peptide*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "10 days",
      "Instructions": "",
      "Price": "\"₦ 8"
    },
    {
      "testName": "\"Drug Screening in Urine",
      "group": "Without Confirmation Assay\"",
      "type": "Toxicology/Drugs",
      "ResultsTime": "Single Test",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "\"Drug Screening in Urine",
      "group": "Extended",
      "type": "Qualitative\"",
      "ResultsTime": "Drug Monitoring",
      "Instructions": "Single Test",
      "Price": "14 days"
    },
    {
      "testName": "Drug Panel (SCREENING)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 43"
    },
    {
      "testName": "Drug Allergy Test",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 189"
    },
    {
      "testName": "DRIVERS PRE-EMPLOYMENT SCREENING",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 32"
    },
    {
      "testName": "Dopamine Blood",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 84"
    },
    {
      "testName": "Dopamine 24hr Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 73"
    },
    {
      "testName": "DOMESTIC STAFF TESTING",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "24 hours/3 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "DOA PANEL URINE",
      "group": "DRUGS/TOXICOLOGY",
      "type": "Panel",
      "ResultsTime": "7 days",
      "Instructions": "",
      "Price": "\"₦ 20"
    },
    {
      "testName": "DNA SWAB",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 10"
    },
    {
      "testName": "DNA Double Strand Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 45"
    },
    {
      "testName": "Dissolved Solids (Water GmbH)",
      "group": "Water Analysis",
      "type": "Single Test",
      "ResultsTime": "15 days",
      "Instructions": "",
      "Price": "\"₦ 26"
    },
    {
      "testName": "Disseminated Intravascular Coagulation (DIC) Screen",
      "group": "Coagulation",
      "type": "Panel",
      "ResultsTime": "24 hours/14 days",
      "Instructions": "",
      "Price": "\"₦ 56"
    },
    {
      "testName": "Diphtheria Antitoxin Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Dipeptidyl-peptidase-like protein-6 (DPPIS) (IFT)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Dimethylarginin (ADMA)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 157"
    },
    {
      "testName": "Dihydrotestosterone (Androstanolone)",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 37"
    },
    {
      "testName": "Digoxin",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Diclofenac",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 158"
    },
    {
      "testName": "\"Dichlorvos (2",
      "group": "2-dichlorovinyl dimethyl phosphate) DDVP in Palmitic Acid\"",
      "type": "Drug Monitoring",
      "ResultsTime": "Single Test",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "DIAMOND Ladies Panel",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "24 hours/ 3 days",
      "Instructions": "",
      "Price": "\"₦ 178"
    },
    {
      "testName": "Diabetes and Cardiovascular",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "24 hrs",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Dexamethasone Suppression",
      "group": "Endocrinology",
      "type": "Panel",
      "ResultsTime": "3 days",
      "Instructions": "",
      "Price": "\"₦ 26"
    },
    {
      "testName": "Dexamethasone",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 100"
    },
    {
      "testName": "Desmoglein 3 Abs (DSG-3) Pemphigus vulgaris",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Desmoglein 1 Abs (DSG-1) Pemphigus foliace",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Dengue Fever Virus IgM Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 39"
    },
    {
      "testName": "Dengue Fever Virus IgG Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 28"
    },
    {
      "testName": "Dengue Fever Virus DNA",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 146"
    },
    {
      "testName": "Delta-Aminolaevulinic Acid Dehydrated (ALAD) Gene for SNP rs1800435*",
      "group": "Clinical Chemistry",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 436"
    },
    {
      "testName": "Delta-Aminolaevulinic Acid Dehydrated (ALAD) Complete Gene*",
      "group": "Clinical Chemistry",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Delta Aminolaevulinic Acid 24hr Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 49"
    },
    {
      "testName": "Dehydroepiandrosterone Sulfate (DHEAS)",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 17"
    },
    {
      "testName": "Dehydroepiandrosterone (DHEA)",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 23"
    },
    {
      "testName": "\"DDVP (2",
      "group": "2 -Dichlorovinyl Dimethyl Phosphate/Dichlorvos)*\"",
      "type": "Environmental and Occupational Health",
      "ResultsTime": "Single Test",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "d3 - Dermatophagoides microceras IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "\"d205 - nDer p10",
      "group": "House Dust Mite IgE\"",
      "type": "Allergy",
      "ResultsTime": "Single Test",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "\"d203 - nDer p2",
      "group": "House Dust Mite IgE\"",
      "type": "Allergy",
      "ResultsTime": "Single Test",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "\"d202 - nDer p1",
      "group": "House Dust Mite IgE\"",
      "type": "Allergy",
      "ResultsTime": "Single Test",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "d2 - Housedust Mite (Dermatophagoides farinae) IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "d1 - Housedust Mite (Dermatophagoides pteronyssinus) IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "D-Xylose",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 13"
    },
    {
      "testName": "\"D-Dimer Test (X-Oligomers",
      "group": "Fibrin Degradation Products)*\"",
      "type": "Coagulation",
      "ResultsTime": "Single Test",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "D-Dimer (Cardiac Reader)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "D-Arabinitol Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 43"
    },
    {
      "testName": "Extra Nuclear Antigen Ab (ENA) Panel",
      "group": "Immunology",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 73"
    },
    {
      "testName": "ex70-Rodent mix (ex70 screening for rodents)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Everolimus",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 100"
    },
    {
      "testName": "Ethosuximid",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 48"
    },
    {
      "testName": "ESTRONEX TEST (2-/16-Hydroxy Estrogen Ratio in Urine)",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 50"
    },
    {
      "testName": "Estrone Sulfate",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 61"
    },
    {
      "testName": "Estriol (E3)",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 28"
    },
    {
      "testName": "ESSENTIAL KNOW YOUR NUMBERS",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 19"
    },
    {
      "testName": "Eslicarbazepin",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 55"
    },
    {
      "testName": "Escherichia coli Food",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "Erythropoietin",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 39"
    },
    {
      "testName": "Erythrocyte Magnesium",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "32 days",
      "Instructions": "",
      "Price": "\"₦ 18"
    },
    {
      "testName": "Erythrocyte Folate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "Erythrocyte Adenosine Deaminase (eADA)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "20 days",
      "Instructions": "",
      "Price": "\"₦ 451"
    },
    {
      "testName": "Epstein Barr Virus Nuclear Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "Epstein Barr Virus Early IgG Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "Epstein Barr Virus Capsid IgM",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Epstein Barr Virus Capsid IgG",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 23"
    },
    {
      "testName": "Epstein Barr Virus (EBV) Serology",
      "group": "Serology",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Epstein Barr Virus (EBV) DNA (PCR) CSF",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 61"
    },
    {
      "testName": "Epstein Barr Virus (EBV) DNA (PCR)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 103"
    },
    {
      "testName": "Epinephrine Plasma (Adrenaline)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 64"
    },
    {
      "testName": "Epinephrine 24hr Urine (Adrenaline)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 72"
    },
    {
      "testName": "Eosinophilic Cationic Protein (ECP)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 44"
    },
    {
      "testName": "Environmental Surface Swab (10 Swabs)",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Environmental Air Sampling (2 Settle Plates)",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 16"
    },
    {
      "testName": "Entomological Examination",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 58"
    },
    {
      "testName": "Enterovirus RNA CSF (PCR)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 42"
    },
    {
      "testName": "Enteropath E.Coli",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Enterococci Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "Entamoeba histolytica DNA (PCR)",
      "group": "Infection Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 53"
    },
    {
      "testName": "Entamoeba histolytica Ag",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Entamoeba histolytica Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Endomysial IgG Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 47"
    },
    {
      "testName": "Endomysial IgA Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 47"
    },
    {
      "testName": "EML4-ALK Fusion gene",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 488"
    },
    {
      "testName": "EMERALD Ladies Panel",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "24 hours/3 days",
      "Instructions": "",
      "Price": "\"₦ 93"
    },
    {
      "testName": "Electrolytes",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 4"
    },
    {
      "testName": "Electrical Conductivity Water",
      "group": "Water Analysis",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 18"
    },
    {
      "testName": "EGFR mutation",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 502"
    },
    {
      "testName": "Efavirenz",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 148"
    },
    {
      "testName": "\"EDTA- Multi Residue Pesticide",
      "group": "Comp Drug of Abuse",
      "type": "Super Warfarin",
      "ResultsTime": "Alcohol",
      "Instructions": "Cyanide\"",
      "Price": "Allergy"
    },
    {
      "testName": "EDTA Multi Residue Pesticide",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 492"
    },
    {
      "testName": "\"Ecstacy in urine",
      "group": "qualitative (MDMA)\"",
      "type": "Toxicology/Drugs",
      "ResultsTime": "Single Test",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "\"e91-Pigeon serum",
      "group": "feathers",
      "type": "droppings IgG\"",
      "ResultsTime": "Allergy",
      "Instructions": "Single Test",
      "Price": "14 days"
    },
    {
      "testName": "e89 - Turkey feathers (Meleagris gallopavo)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "e85 - Chicken feathers (Gallus domesticus)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "e5 - Dog Dander IgE (Canis familiaris)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "e3 - Horse dander (Equus caballus)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "e1 - Cat Epithelium and Dander IgE (Felis domesticus)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "E.coli/Coliform Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "Fatty Acids",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 68"
    },
    {
      "testName": "\"Familial Adenomatous Polyposis Coli FAP1 (APC)",
      "group": "FAP2 (MUTYH)\"",
      "type": "Genetics",
      "ResultsTime": "Single Test",
      "Instructions": "21 days",
      "Price": ""
    },
    {
      "testName": "Faecal Strep Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 48"
    },
    {
      "testName": "Faecal Pancreatic Elastase*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 131"
    },
    {
      "testName": "Faecal Occult Blood",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 6"
    },
    {
      "testName": "Faecal Calprotectin",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 131"
    },
    {
      "testName": "Factor XIII Activity*",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 52"
    },
    {
      "testName": "Factor XII (Hageman Factor) Activity*",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 55"
    },
    {
      "testName": "Factor XI Activity*",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 55"
    },
    {
      "testName": "Factor X (Stuart Factor) Activity*",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 58"
    },
    {
      "testName": "Factor VIII Activity*",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 76"
    },
    {
      "testName": "Factor VII Activity",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 90"
    },
    {
      "testName": "Factor V Leiden Mutation",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 171"
    },
    {
      "testName": "Factor V Activity",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 90"
    },
    {
      "testName": "Factor IX Activity*",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 90"
    },
    {
      "testName": "Factor II Prothrombin Mutation (20210 A) (PCR)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 62"
    },
    {
      "testName": "\"f98 - Gliadin",
      "group": "nTri a (IgG)\"",
      "type": "Allergy",
      "ResultsTime": "Single Test",
      "Instructions": "",
      "Price": ""
    },
    {
      "testName": "f9 - Rice",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "f89 - Mustard (IgG)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 17"
    },
    {
      "testName": "f83 - Chicken meat (Gallus domesticus)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "f82 - Cheese Mould Type",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "f81 - Cheese Cheddar Type",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "f79 - Gluten IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "f78 - Casein IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "f78 - Casein (IgG)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 17"
    },
    {
      "testName": "\"f77 - Beta-Lactoglobulin",
      "group": "heat stable (IgG)\"",
      "type": "Allergy",
      "ResultsTime": "Single Test",
      "Instructions": "",
      "Price": ""
    },
    {
      "testName": "f77 - beta-Lactoglobulin (nBos d5)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "\"f76 - Alpha-Lactalbumin",
      "group": "heat-labile (IgG)\"",
      "type": "Allergy",
      "ResultsTime": "Single Test",
      "Instructions": "",
      "Price": ""
    },
    {
      "testName": "f76 - alpha-Lactalbumin (nBos d4)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "f75 - Egg yolk (Gallus spp.) IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "f7-Oats (Avena sativa)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "f54-Sweet potato",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 20"
    },
    {
      "testName": "f5-Rye (Secale cereale)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "f44-Strawberry",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 20"
    },
    {
      "testName": "f4 - Wheat (Triticum aestivum)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "f36-Coconut IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "f35-Potato",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 20"
    },
    {
      "testName": "f326 - Sheep Whey",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "f325 - Sheep Milk",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "f314-Snail IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "f300 - Goat Milk",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "f3 - Fish (Cod) (Gadus morhua)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "f286 - Mare's Milk",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "f284 - Turkey meat (Meleagris gallopavo)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "f25 - Tomato",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "f236 - Whey",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "f231 - Milk Boiled",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "f206-Mackerel",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 20"
    },
    {
      "testName": "f204-Trout (Oncorhynchus mykiss)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "f20-Almond",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "Fecal Immunochemical Test* FIT (Hemoglobin / Haptoglobin Complex in Faeces)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "f2 - Cow`s milk (Bos spp.)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "f18-Brazil nut",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "f17-Hazel nut (Corylus avellana) IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "\"f14 - Soya bean (Glycine max",
      "group": "Soja hispida)\"",
      "type": "Allergy",
      "ResultsTime": "Single Test",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "f13-Peanut (Arachis hypogaea)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "f1 - Egg white (IgG)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 17"
    },
    {
      "testName": "f1 - Egg white (Gallus spp.) IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "Gyrate Atrophy of Coroid and Retina (OAT) ORNITHINE KETOACID AMINOTRANFERASE",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "23 days",
      "Instructions": "",
      "Price": "\"₦ 303"
    },
    {
      "testName": "Guardant360 (Monitoring)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "20 days",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Guardant360",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "20 days",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Growth Hormone Suppression",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "24 hours/14 days",
      "Instructions": "",
      "Price": "\"₦ 221"
    },
    {
      "testName": "Growth Hormone Stimulation",
      "group": "Endocrinology",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 243"
    },
    {
      "testName": "Growth Hormone Basal",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Growth Hormone 90 Minutes",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Growth Hormone 60 Minutes",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Growth Hormone 45 Minutes",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Growth Hormone 30 Minutes",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Growth Hormone 180 Minutes",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Growth Hormone 15 Minutes",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Growth Hormone 120 Minutes",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Growth Hormone",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Gonadotropin Releasing Hormone (GnRH)*",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 644"
    },
    {
      "testName": "Gonadotropin (GnRH) Stimulating Test",
      "group": "Endocrinology",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 127"
    },
    {
      "testName": "Gold Wellness Test",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 183"
    },
    {
      "testName": "Glyphosate Urine",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 100"
    },
    {
      "testName": "Glutathione-S-Transferase",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 43"
    },
    {
      "testName": "Glutathione Profile",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 84"
    },
    {
      "testName": "Glutathione Peroxidase",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Glutamate Decarboxylase 65 Ab (GAD)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 110"
    },
    {
      "testName": "Glucose-6-Phosphate Dehydrogenase (G6PD)",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 41"
    },
    {
      "testName": "Glucose Tol Fasting",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Glucose Stat",
      "group": "Screening",
      "type": "Single Test",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Glucose Random",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Glucose Fasting",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Glucose Dialysate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 5"
    },
    {
      "testName": "Glucose CSF",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Glucose Aspirate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Glucose Aspirate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Glucose 90 Minutes",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Glucose 60 Minutes",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Glucose 30 Minutes",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Glucose 180 Minutes",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Glucose 120 Minutes",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Glucagon*",
      "group": "Clinical Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 78"
    },
    {
      "testName": "Glomerular Basal Membrane Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 19"
    },
    {
      "testName": "GLIOMA - (1p36) / (19q13) - FISH",
      "group": "Cytogenetics",
      "type": "Single Test",
      "ResultsTime": "10 days",
      "Instructions": "",
      "Price": "\"₦ 434"
    },
    {
      "testName": "Gliadin peptide IgG Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 47"
    },
    {
      "testName": "Gliadin peptide IgA Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 47"
    },
    {
      "testName": "\"GL1IS - mGluR1 Ab IFA",
      "group": "S (Metabotropic glutamate receptor 1(IFT)\"",
      "type": "Endocrinology",
      "ResultsTime": "Single Test",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "\"GIST - PDGFRA (12",
      "group": "14",
      "type": "18) - HOT SPOT\"",
      "ResultsTime": "Molecular Genetics",
      "Instructions": "Single Test",
      "Price": "25 days"
    },
    {
      "testName": "Gilbert Syndrome",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 124"
    },
    {
      "testName": "Giardia Stool Ag",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 23"
    },
    {
      "testName": "Gentamycin Pre",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Gentamycin Post",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Genital Ulceration Panel",
      "group": "Serology",
      "type": "Panel",
      "ResultsTime": "24 hours/3 days",
      "Instructions": "",
      "Price": "\"₦ 168"
    },
    {
      "testName": "Gastro Tests Phase 1",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "3 hours/24 hours/14 days",
      "Instructions": "",
      "Price": "\"₦ 100"
    },
    {
      "testName": "Gastrin*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 37"
    },
    {
      "testName": "GASTRIC CONTENT- Comp Drug of Abuse and Multi Residue Pesticides",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 726"
    },
    {
      "testName": "GASTRIC CONTENT- Comp Drug of Abuse and Multi Residue Pesticides",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 508"
    },
    {
      "testName": "GASTRIC CONTENT- Comp Drug of Abuse and Multi Residue Pesticides",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 508"
    },
    {
      "testName": "GASTRIC CONTENT Multi Residue Pesticide",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 492"
    },
    {
      "testName": "GASTRIC CONTENT Multi Residue Pesticide",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 344"
    },
    {
      "testName": "GASTRIC CONTENT Multi Residue Pesticide",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 344"
    },
    {
      "testName": "Gardnerella vaginalis DNA (PCR)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Ganglionic Acetylcholine Receptor Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 84"
    },
    {
      "testName": "gamma-Interferon*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 50"
    },
    {
      "testName": "Gamma GT (GGT)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Gamma Aminobutyric Acid 24hr Urine (GABA)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 45"
    },
    {
      "testName": "Galactose-1-Phospho-Uridyl-Transferase",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 194"
    },
    {
      "testName": "Galactose-1-Phosphate",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 37"
    },
    {
      "testName": "Galactose in urine*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 23"
    },
    {
      "testName": "Galactose",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 23"
    },
    {
      "testName": "Galactosaemia Profile",
      "group": "Haematology",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 529"
    },
    {
      "testName": "Galactokinase",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 104"
    },
    {
      "testName": "Gabapentin*",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 100"
    },
    {
      "testName": "Gaba B Receptor Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 23"
    },
    {
      "testName": "g6-Timothy Grass (Phleum pratense)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "g202 - Maize Corn Pollen (Zea Mays) IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "g2-Bermuda Grass (Cynodon dactylon)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "g12 - Cultivated Rye IgE (Secale cereale)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "Hypocretin in CSF",
      "group": "CSF diagnostics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 77"
    },
    {
      "testName": "Hypochondroplasia (FGFR3 gene) 2nd tier",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "28 days",
      "Instructions": "",
      "Price": "\"₦ 929"
    },
    {
      "testName": "Hypochondroplasia (FGFR3 gene) 1st tier",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 189"
    },
    {
      "testName": "\"Hypereosinophilic Syndrome (FIP1L1/PDGFRA",
      "group": "del4q12) FISH\"",
      "type": "Immunology",
      "ResultsTime": "Single Test",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "Hydatid/Echinococcus Abs",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 28"
    },
    {
      "testName": "Hyaluronidase Ab",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 26"
    },
    {
      "testName": "\"hx2-Housedust Mix (Holliester-Stier Labs",
      "group": "der. pteronyssinus",
      "type": "der. farinae",
      "ResultsTime": "cockroach german)\"",
      "Instructions": "Allergy",
      "Price": "Single Test"
    },
    {
      "testName": "hx1-Housedust Mix",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "Human Platelet Antigens (HPA) genotype",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 205"
    },
    {
      "testName": "Human Placental Lactogen (HPL))",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 51"
    },
    {
      "testName": "Human Epididymis Protein 4 (HE4)",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 56"
    },
    {
      "testName": "HTLV II RNA PCR (Qual)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "HTLV I/II IgG Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "HTLV I RNA PCR (Quant)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "HTLV I RNA PCR (Qual)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "\"HSD3B2 Gene (3-Beta-Hydroxysteroid Dehydrogenase 3BHSD",
      "group": "AGS)\"",
      "type": "Genetics",
      "ResultsTime": "Single Test",
      "Instructions": "23 days",
      "Price": ""
    },
    {
      "testName": "HS Omega 3 Index",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 120"
    },
    {
      "testName": "HPV Screen/Genotyping",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 49"
    },
    {
      "testName": "HPV Oncotect",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 103"
    },
    {
      "testName": "Homovanillic Acid in Urine (HVA)",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 63"
    },
    {
      "testName": "Homovanillic Acid in Plasma (HVA)*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 54"
    },
    {
      "testName": "Homovanilic Acid Spot Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "Homogentisic Acid in Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 119"
    },
    {
      "testName": "Homocystine Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 31"
    },
    {
      "testName": "Homocystine Plasma*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 37"
    },
    {
      "testName": "\"HOMA-Index* (Homeostasis Model Assessment",
      "group": "Insulin Resistance)\"",
      "type": "Chemistry",
      "ResultsTime": "Single Test",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "HLAB57 (HLA B*5701 Allele)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 171"
    },
    {
      "testName": "HLA RENAL TYPING LOW RESOLUTION",
      "group": "Genetics",
      "type": "Panel",
      "ResultsTime": "6 weeks",
      "Instructions": "",
      "Price": "\"₦ 619"
    },
    {
      "testName": "HLA Iso-abs screening Class I and II abs",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 143"
    },
    {
      "testName": "HLA BONE MARROW HIGH RESOLUTION",
      "group": "Genetics",
      "type": "Panel",
      "ResultsTime": "6 weeks",
      "Instructions": "",
      "Price": "\"₦ 721"
    },
    {
      "testName": "HLA B27 DNA",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 42"
    },
    {
      "testName": "HIV Stat",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "HIV Resistance & Viral Load*",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 594"
    },
    {
      "testName": "HIV Antigen/Antibody Test",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 14"
    },
    {
      "testName": "HIV 2 RNA (PCR)*",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 427"
    },
    {
      "testName": "HIV 1 RNA (PCR)*",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 182"
    },
    {
      "testName": "HIV 1 & 2 Westernblot",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 123"
    },
    {
      "testName": "Histoplasma Abs (RID)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 38"
    },
    {
      "testName": "Histoplasma Abs (CFT/Aggl)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 38"
    },
    {
      "testName": "Histone Antibody",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Histamine Urine*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 73"
    },
    {
      "testName": "Histamine Plasma*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 73"
    },
    {
      "testName": "Hirsutism Panel - Female",
      "group": "Endocrinology",
      "type": "Panel",
      "ResultsTime": "24 hours/14 days",
      "Instructions": "",
      "Price": "\"₦ 108"
    },
    {
      "testName": "HHV Type 8 IgG Ab (Human Herpes Virus Type 8)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "HHV type 7 DNA in CSF (Human Herpes Virus Type 7 PCR)",
      "group": "Infection Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 53"
    },
    {
      "testName": "HHV type 7 DNA (Human Herpes Virus Type 7 PCR)",
      "group": "Infection Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 53"
    },
    {
      "testName": "HHV type 6 DNA in CSF (Human Herpes Virus Type 6 PCR)",
      "group": "Infection Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 53"
    },
    {
      "testName": "HHV type 6 DNA (Human Herpes Virus Type 6 PCR)",
      "group": "Infection Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 53"
    },
    {
      "testName": "HFE Gene Sequencing",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "30 days",
      "Instructions": "",
      "Price": "\"₦ 97"
    },
    {
      "testName": "\"Hexosaminidase",
      "group": "Total\"",
      "type": "Chemistry",
      "ResultsTime": "Single Test",
      "Instructions": "",
      "Price": ""
    },
    {
      "testName": "Hexosaminidase A",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 86"
    },
    {
      "testName": "Herpes Virus Type 8 DNA",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 146"
    },
    {
      "testName": "Herpes Virus Type 6 IgM Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Herpes Virus Type 6 IgG Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Herpes Simplex Virus Type 2 IgM Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 28"
    },
    {
      "testName": "Herpes Simplex Virus Type 2 IgG Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 28"
    },
    {
      "testName": "Herpes Simplex Virus Type 2 DNA (CSF)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 52"
    },
    {
      "testName": "Herpes Simplex Virus Type 2 DNA",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 72"
    },
    {
      "testName": "Herpes Simplex Virus Type 1 IgM Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 28"
    },
    {
      "testName": "Herpes Simplex Virus Type 1 IgG Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 28"
    },
    {
      "testName": "Herpes Simplex Virus Type 1 DNA (CSF)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 52"
    },
    {
      "testName": "Herpes Simplex Virus Type 1 DNA",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 72"
    },
    {
      "testName": "Herpes Simplex Virus Antibodies (HSV)",
      "group": "Serology",
      "type": "Panel",
      "ResultsTime": "#N/A",
      "Instructions": "",
      "Price": "\"₦ 62"
    },
    {
      "testName": "Herpes Simplex Virus 1 & 2 DNA (PCR)",
      "group": "PCR",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 144"
    },
    {
      "testName": "\"HEREDITARY BREAST",
      "group": "OVARIAN CANCER (BRCA1 & BRCA2 NGS+MLPA)\"",
      "type": "Genetics",
      "ResultsTime": "Single Test",
      "Instructions": "30 days",
      "Price": ""
    },
    {
      "testName": "HER-2 neu Oncogen",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 50"
    },
    {
      "testName": "Hepcidin",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 120"
    },
    {
      "testName": "Hepatitis E IgM Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Hepatitis E IgG Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Hepatitis E DNA (Stool)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 42"
    },
    {
      "testName": "Hepatitis E DNA (EDTA)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 146"
    },
    {
      "testName": "Hepatitis D RNA",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 203"
    },
    {
      "testName": "Hepatitis D IgM Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 40"
    },
    {
      "testName": "Hepatitis D IgG/IgM abs (HDV)",
      "group": "Infection Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 82"
    },
    {
      "testName": "Hepatitis D IgG Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 40"
    },
    {
      "testName": "Hepatitis D Abs",
      "group": "Serology",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 80"
    },
    {
      "testName": "Hepatitis C Virus Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 18"
    },
    {
      "testName": "Hepatitis C Stat",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 8"
    },
    {
      "testName": "Hepatitis C RNA (PCR)*",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 113"
    },
    {
      "testName": "Hepatitis C Genotype + Hepatitis C RNA (PCR)*",
      "group": "PCR",
      "type": "Panel",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 247"
    },
    {
      "testName": "Hepatitis B virus surface Ag Stat",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 6"
    },
    {
      "testName": "Hepatitis B Virus surface Ag Quant",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 9"
    },
    {
      "testName": "Hepatitis B Virus surface Ag",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 16"
    },
    {
      "testName": "Hepatitis B Virus surface Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 9"
    },
    {
      "testName": "Hepatitis B Virus e Ag",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 15"
    },
    {
      "testName": "Hepatitis B Virus e Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "Hepatitis B Virus core IgM Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 12"
    },
    {
      "testName": "Hepatitis B Virus core Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 20"
    },
    {
      "testName": "Hepatitis B Profile",
      "group": "Serology",
      "type": "Panel",
      "ResultsTime": "24 hours/3 days/14 days",
      "Instructions": "",
      "Price": "\"₦ 78"
    },
    {
      "testName": "Hepatitis B Genotype & Drug Resistance",
      "group": "PCR",
      "type": "Panel",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 206"
    },
    {
      "testName": "Hepatitis B Genotype",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 134"
    },
    {
      "testName": "Hepatitis B Drug Resistance",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 146"
    },
    {
      "testName": "Hepatitis B DNA",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 113"
    },
    {
      "testName": "Hepatitis A virus Stat",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Hepatitis A Virus IgM Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 19"
    },
    {
      "testName": "Hepatitis A Virus IgG Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 19"
    },
    {
      "testName": "Hepatitis A RNA (PCR)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 146"
    },
    {
      "testName": "Heparin PF4 complex (HIT type II) Ab",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "10 days",
      "Instructions": "",
      "Price": "\"₦ 68"
    },
    {
      "testName": "Heparin Induced Thrombopenia Test (HIPA)",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "10 days",
      "Instructions": "",
      "Price": "\"₦ 322"
    },
    {
      "testName": "Hemosiderin Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 27"
    },
    {
      "testName": "\"Hemophilia A",
      "group": "genetic test\"",
      "type": "Genetics",
      "ResultsTime": "Single Test",
      "Instructions": "23 days",
      "Price": ""
    },
    {
      "testName": "Hemoglobin in Faeces*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Hemochromatosis Gene Diagnostic (HFE)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 97"
    },
    {
      "testName": "\"Hematological Neoplasms CSF3R . CSF3R(14",
      "group": "17) . Sanger\"",
      "type": "Genetics",
      "ResultsTime": "Single Test",
      "Instructions": "30 days",
      "Price": ""
    },
    {
      "testName": "Helicobacter pylori IgG Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Helicobacter pylori IgA Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Helicobacter pylori Ag Stool",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 34"
    },
    {
      "testName": "Heavy Metal Panel Urine",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 196"
    },
    {
      "testName": "HBB Gene MLPA + Sequencing",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 794"
    },
    {
      "testName": "HbA1C",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 17"
    },
    {
      "testName": "HBA Gene MLPA + Sequencing",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Hb Electrophoresis",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 15"
    },
    {
      "testName": "Haptoglobin",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 27"
    },
    {
      "testName": "\"Hair Analysis",
      "group": "Standard\"",
      "type": "Complementry Medicine",
      "ResultsTime": "Single Test",
      "Instructions": "21 days",
      "Price": ""
    },
    {
      "testName": "\"Hair Analysis",
      "group": "Advanced\"",
      "type": "Complementry Medicine",
      "ResultsTime": "Single Test",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "Hair Analysis DOA Screen",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 358"
    },
    {
      "testName": "Haemophilus ducreyi DNA (PCR)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 257"
    },
    {
      "testName": "Haemoglobin",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Haemochromatosis Type 1",
      "group": "Screening",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 134"
    },
    {
      "testName": "Islet cell abs (ICA)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 27"
    },
    {
      "testName": "Islet cell 512 Ab (IA2)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Iron Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 27"
    },
    {
      "testName": "Iron Studies Panel",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Iron Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 8"
    },
    {
      "testName": "Ionized Calcium",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 6"
    },
    {
      "testName": "Iodine Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 53"
    },
    {
      "testName": "Iodine Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 100"
    },
    {
      "testName": "Iodine in Urine / g crea",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 149"
    },
    {
      "testName": "Iodine in Salt (Food)",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 41"
    },
    {
      "testName": "Iodine Excretion 24hr Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 103"
    },
    {
      "testName": "Intrinsic Factor Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 45"
    },
    {
      "testName": "Interleukin 8*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 80"
    },
    {
      "testName": "Interleukin 6*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 123"
    },
    {
      "testName": "Interleukin 4*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 80"
    },
    {
      "testName": "\"Interleukin 2 Receptor",
      "group": "Soluble*\"",
      "type": "Chemistry",
      "ResultsTime": "Single Test",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "Interleukin 10*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 80"
    },
    {
      "testName": "Interleukin 1-Beta*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 73"
    },
    {
      "testName": "Insulin-like Growth Factor Binding Protein 3 (IGFBP-3)",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Insulin Resistance",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 27"
    },
    {
      "testName": "Insulin Random",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Insulin Fasting",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Insulin auto-abs (human)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 41"
    },
    {
      "testName": "Insulin Allergy test IgE (c 73-Insuline Human)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "Insulin Allergy Test IgE (c 71-Insuline Bovine)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "Insulin 90 Minutes",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Insulin 60 Minutes",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Insulin 30 Minutes",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Insulin 180 Minutes",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Insulin 120 Minutes",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Insulin (Human) Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 39"
    },
    {
      "testName": "INR (PT)",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 9"
    },
    {
      "testName": "Inhibin B",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 62"
    },
    {
      "testName": "Inhibin A",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 59"
    },
    {
      "testName": "Inherited Thrombosis",
      "group": "",
      "type": "Panel",
      "ResultsTime": "#N/A",
      "Instructions": "",
      "Price": "\"₦ 446"
    },
    {
      "testName": "Inhalation Allergen Panel",
      "group": "Allergy",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 110"
    },
    {
      "testName": "Influenza B IgG Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Influenza B IgA Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Influenza B Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Influenza A IgG Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Influenza A IgA Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Influenza A Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Infliximab (TDM)",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 59"
    },
    {
      "testName": "Infectious Gastroenteritis Panel",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 536"
    },
    {
      "testName": "Indirect Coombs + Antibody Titration",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 52"
    },
    {
      "testName": "Indian Ink Stain",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 7"
    },
    {
      "testName": "Immunophenotyping for T-Acute Lymphoblastic Leukaemia (T-ALL)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 905"
    },
    {
      "testName": "Immunophenotyping for B-Acute Lymphoblastic Leukaemia (B-ALL)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 880"
    },
    {
      "testName": "Immunoperoxidase Stain X9",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 278"
    },
    {
      "testName": "Immunoperoxidase Stain X8",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 247"
    },
    {
      "testName": "Immunoperoxidase Stain X7",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 216"
    },
    {
      "testName": "Immunoperoxidase Stain X6",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 185"
    },
    {
      "testName": "Immunoperoxidase Stain X5",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 154"
    },
    {
      "testName": "Immunoperoxidase Stain X4",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 123"
    },
    {
      "testName": "Immunoperoxidase Stain X3",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 93"
    },
    {
      "testName": "Immunoperoxidase Stain X2",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 62"
    },
    {
      "testName": "Immunoperoxidase Stain X12",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 370"
    },
    {
      "testName": "Immunoperoxidase Stain X11",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 339"
    },
    {
      "testName": "Immunoperoxidase Stain X10",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 308"
    },
    {
      "testName": "Immunoperoxidase Stain X1",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 31"
    },
    {
      "testName": "IMMUNOHISTOCHEMISTRY (BIOPSY Renal Skin Panel)",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 328"
    },
    {
      "testName": "Immunoglobulin M",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 37"
    },
    {
      "testName": "Immunoglobulin G-4 (IGG4)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 247"
    },
    {
      "testName": "Immunoglobulin G-3 (IGG3)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 247"
    },
    {
      "testName": "Immunoglobulin G-2 (IGG2)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 247"
    },
    {
      "testName": "Immunoglobulin G-1 (IGG1)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 247"
    },
    {
      "testName": "Immunoglobulin G CSF",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 34"
    },
    {
      "testName": "Immunoglobulin G",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 37"
    },
    {
      "testName": "Immunoglobulin E",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 37"
    },
    {
      "testName": "Immunoglobulin D",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 37"
    },
    {
      "testName": "Immunoglobulin A",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 37"
    },
    {
      "testName": "Immunofixation Urine (Bence Jones)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 62"
    },
    {
      "testName": "Immunofixation Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 48"
    },
    {
      "testName": "IGLON5 IgG Abs in Serum",
      "group": "CSF diagnostics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 101"
    },
    {
      "testName": "IgG Subfraction 4",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 247"
    },
    {
      "testName": "IgG Subfraction 3",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 247"
    },
    {
      "testName": "IgG Subfraction 2",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 247"
    },
    {
      "testName": "IgG Subfraction 1",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 247"
    },
    {
      "testName": "IgG subclasses",
      "group": "Immunology",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 224"
    },
    {
      "testName": "IDH1 codon 140 Mutation",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 146"
    },
    {
      "testName": "IDH1 codon 132 Mutation",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 146"
    },
    {
      "testName": "IDH Mutation Analysis",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 303"
    },
    {
      "testName": "Jo-1 Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 49"
    },
    {
      "testName": "Japanese Encephalitis IgM",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 298"
    },
    {
      "testName": "Japanese Encephalitis IgG",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 298"
    },
    {
      "testName": "JAK2 (V617F) Mutation",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 395"
    },
    {
      "testName": "JAK2 (Exon 12) Mutation",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 474"
    },
    {
      "testName": "Kidney Function",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 7"
    },
    {
      "testName": "Ketone Bodies*",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 192"
    },
    {
      "testName": "Ketamine Urine",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 100"
    },
    {
      "testName": "Ketamine Serum",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 100"
    },
    {
      "testName": "Keratin Ab",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "\"KARYOTYPE",
      "group": "TISSUE\"",
      "type": "Genetics",
      "ResultsTime": "Single Test",
      "Instructions": "20 days",
      "Price": ""
    },
    {
      "testName": "Karotyping - FISH for T-Acute Lymphoblastic Leukaemia (T-ALL)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "25 days",
      "Instructions": "",
      "Price": "\"₦ 211"
    },
    {
      "testName": "Karotyping - FISH for B-Acute Lymphoblastic Leukaemia (B-ALL)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "25 days",
      "Instructions": "",
      "Price": "\"₦ 418"
    },
    {
      "testName": "Kappa Total Light Chain Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Kappa Lymphocytes (+ve B Cells)",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 59"
    },
    {
      "testName": "Kappa Free Light Chains Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Kappa Free Light Chain Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "k86 - Trimellitic Anhydride (TMA) IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "k85 - Chloramin T IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "k82 - Latex IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "k80 - Formaldehyde / Formalin IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "k79 - Phtalic Anhydride IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "k78 - Ethylene Oxide IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "k77 - Isocyanate HDI IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "k76 - Isocyanate MDI IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "k75 - Isocyanate TDI IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "k211 - Methyltetrahydrophtalic Anhydride (MTHA) IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "k209 - Hexahydrophtalic Anhydride (HHPA) IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "Lynch Syndrome",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "44 days",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Lymphoma Immunestatus",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 286"
    },
    {
      "testName": "Lymphocytic Choriomeningitis Virus (LCM) RNA CSF",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 42"
    },
    {
      "testName": "Lymphocytic Choriomeningitis Virus (LCM) IgM Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Lymphocytic Choriomeningitis Virus (LCM) IgG Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Luteinizing Hormone (LH) Basal",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 13"
    },
    {
      "testName": "Luteinizing Hormone (LH) 90 Minutes",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 13"
    },
    {
      "testName": "Luteinizing Hormone (LH) 60 Minutes",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 13"
    },
    {
      "testName": "Luteinizing Hormone (LH) 30 Minutes",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 13"
    },
    {
      "testName": "Luteinizing Hormone (LH) 120 Minutes",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 13"
    },
    {
      "testName": "Luteinizing Hormone (LH)",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 13"
    },
    {
      "testName": "Lupus Test aPTT (LA)*",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 43"
    },
    {
      "testName": "Lupus Anticoagulants + Anti-B2-Glycoprotein IgA Ab",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 74"
    },
    {
      "testName": "Lupus Anticoagulants",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 61"
    },
    {
      "testName": "Lupus Antibody/Inhibitor*",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "Lung Basic Cancer Panel",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 833"
    },
    {
      "testName": "Liver Kidney Microsomal Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 62"
    },
    {
      "testName": "Liver Function Profile",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 12"
    },
    {
      "testName": "Liver Enzymes",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 9"
    },
    {
      "testName": "Lithium",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 12"
    },
    {
      "testName": "Lipoprotein Profile",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 123"
    },
    {
      "testName": "Lipoprotein Profile",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 123"
    },
    {
      "testName": "Lipoprotein Electrophoresis",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Lipoprotein Associated Phospholipase A2 (Lp-PLA2)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 50"
    },
    {
      "testName": "Lipoprotein (a) LpA",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "10 days",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "Lipid Profile",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 10"
    },
    {
      "testName": "Lipid Peroxides Test*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 58"
    },
    {
      "testName": "Lipase",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 7"
    },
    {
      "testName": "LGI1 abs in CSF",
      "group": "CSF diagnostics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 32"
    },
    {
      "testName": "LGI1 abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 32"
    },
    {
      "testName": "Levetiracetam (Keppra)",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Leptospira IgM Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 26"
    },
    {
      "testName": "Leptospira IgG Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 26"
    },
    {
      "testName": "Leptospira DNA Urine",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Leptospira DNA Blood",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Leptin",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 134"
    },
    {
      "testName": "Leishmania DNA (PCR)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 43"
    },
    {
      "testName": "Leishmania Abs Screening (IFT)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 39"
    },
    {
      "testName": "Leishmania Abs Screening (ELISA)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 39"
    },
    {
      "testName": "Leishmania Abs Screening",
      "group": "Serology",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 74"
    },
    {
      "testName": "Legionella pneumophila-IgM abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Legionella pneumophila-IgG abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Legionella Pneumonia",
      "group": "Microbiology: Bacteriology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 32"
    },
    {
      "testName": "Legionella DNA in urine (PCR)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 43"
    },
    {
      "testName": "Legionella DNA (PCR)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 43"
    },
    {
      "testName": "Legionella Culture Food",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 28"
    },
    {
      "testName": "Legionella Culture",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 28"
    },
    {
      "testName": "Lead Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Lead Urine / Creatinine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 101"
    },
    {
      "testName": "Lead Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 62"
    },
    {
      "testName": "Lead Blood",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 31"
    },
    {
      "testName": "Lead 24hr Urine",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 62"
    },
    {
      "testName": "LD Isoenzymes",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "LBC & HPV",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "3 days/14 days",
      "Instructions": "",
      "Price": "\"₦ 58"
    },
    {
      "testName": "Lamotrigine(Lamictin)",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Lambda Total Light Chain Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Lambda Lymphocytes (+ve B Cells)",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 59"
    },
    {
      "testName": "Lambda Free Light Chains Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Lambda Free Light Chain Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Lactose Intolerance (Genetics)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 115"
    },
    {
      "testName": "Lactate Plasma*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 31"
    },
    {
      "testName": "Lactate Dehydrogenase Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 10"
    },
    {
      "testName": "Lactate Dehydrogenase Fluid",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 16"
    },
    {
      "testName": "Lactate Dehydrogenase Dialysate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 16"
    },
    {
      "testName": "Lactate Dehydrogenase CSF",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 16"
    },
    {
      "testName": "Lactate CSF",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 31"
    },
    {
      "testName": "Lactate Aspirate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 31"
    },
    {
      "testName": "Labstix",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "MyPGx RHUMA",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "65 days",
      "Instructions": "",
      "Price": "\"₦ 576"
    },
    {
      "testName": "MyPGx PSY",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "64 days",
      "Instructions": "",
      "Price": "\"₦ 576"
    },
    {
      "testName": "MyPGx ONCO",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "63 days",
      "Instructions": "",
      "Price": "\"₦ 576"
    },
    {
      "testName": "MyPGx NEURO",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "62 days",
      "Instructions": "",
      "Price": "\"₦ 576"
    },
    {
      "testName": "MyPGx FULL",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "61 days",
      "Instructions": "",
      "Price": "\"₦ 822"
    },
    {
      "testName": "Myositis Ab Screening",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 116"
    },
    {
      "testName": "Myoglobin in Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 34"
    },
    {
      "testName": "Myoglobin (Cardiac Reader)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "\"Myoclonus Dystonia",
      "group": "SGCE Gene (SEQ)\"",
      "type": "Genetics",
      "ResultsTime": "Single Test",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "\"Myoclonus Dystonia",
      "group": "SGCE Gene (MLPA)\"",
      "type": "Genetics",
      "ResultsTime": "Single Test",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "MYELOMA / LYMPHOMA - TP53 (17p13) - FISH",
      "group": "Cytogenetics",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 364"
    },
    {
      "testName": "Myelodysplastic Syndrome (MDS) - FISH Test 5q",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 93"
    },
    {
      "testName": "MYELODYSPLASIC SYNDROME PANEL",
      "group": "Cytogenetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 749"
    },
    {
      "testName": "Myelin Oligodendrocyte Glycoprotein IgG Abs (MOG)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 71"
    },
    {
      "testName": "Myelin basic protein Ab Serum",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Myelin basic protein Ab CSF",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Myelin Associated Glycoprotein IgM Abs CSF",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Myelin Associated Glycoprotein IgM Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Myelin Associated Glycoprotein IgG Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Myelin Associated Glycoprotein IgA Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Myelin Associated Glycoprotein Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Myelin 3 gangliosid-IgM abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Myelin 3 gangliosid-IgG abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Myelin 2 gangliosid-IgM abs in CSF",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Myelin 2 gangliosid-IgM abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Myelin 2 gangliosid-IgG abs in CSF",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Myelin 2 gangliosid-IgG abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Myelin 1 gangliosid-IgM abs in CSF",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Myelin 1 gangliosid-IgM abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Myelin 1 gangliosid-IgG abs in CSF",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Myelin 1 gangliosid-IgG abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Mycoplasma pneumoniae IgM Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 34"
    },
    {
      "testName": "Mycoplasma pneumoniae IgG Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 38"
    },
    {
      "testName": "Mycoplasma pneumoniae DNA",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 146"
    },
    {
      "testName": "Mycoplasma hominis DNA",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 86"
    },
    {
      "testName": "Mycoplasma genitalium DNA",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 62"
    },
    {
      "testName": "Mycophenolate (Mycophenolic Acid)",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Mycobacterium tuberculosis-complex DNA (MTB)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 113"
    },
    {
      "testName": "Mycobacterium leprae PCR",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 343"
    },
    {
      "testName": "Mycobacterium leprae EIT",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 70"
    },
    {
      "testName": "Mycobacterium DNA (Other)",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 56"
    },
    {
      "testName": "Mycobacterium Culture",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 43"
    },
    {
      "testName": "Mycobacteium ulcerans (Buruli Ulcer) PCR",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 360"
    },
    {
      "testName": "\"mx2 - Mould Mix (penicillium",
      "group": "cladosporium",
      "type": "aspergillus",
      "ResultsTime": "candida",
      "Instructions": "alternaria",
      "Price": "helminthosporium)\""
    },
    {
      "testName": "\"mx1 - Mould Mix (penicillium notatum",
      "group": "cladosporium her.",
      "type": "aspergillus fumigatus",
      "ResultsTime": "alternaria)\"",
      "Instructions": "Allergy",
      "Price": "Single Test"
    },
    {
      "testName": "Muscular Dystrophy Type Duchenne/Becker (DMD/BMD) 2nd tier",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "46 days",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Muscular Dystrophy Type Duchenne/Becker (DMD/BMD) 1st tier",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "30 days",
      "Instructions": "",
      "Price": "\"₦ 467"
    },
    {
      "testName": "Muscle Specific Kinase (MUSK) Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 209"
    },
    {
      "testName": "Mumps Virus IgM Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "Mumps Virus IgG Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "Multiple Endocrine Neoplasia Type II (MEN2)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Multiple Endocrine Neoplasia Type I (MEN1)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Multi-resistant Gram-negative Bacteria",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "Multi-Drug Resistant TB 2nd Line Probe Assay",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 146"
    },
    {
      "testName": "Multi-Drug Resistant TB 1st Line Probe Assay",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 146"
    },
    {
      "testName": "Mucopolysaccharide Screening Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Mucopolysaccharide Diagnostics Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 103"
    },
    {
      "testName": "Muconic Acid in Urine (Benzene)",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 101"
    },
    {
      "testName": "MTHFR A1298C Mutation",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "30 days",
      "Instructions": "",
      "Price": "\"₦ 146"
    },
    {
      "testName": "MRSA DNA",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 65"
    },
    {
      "testName": "MPL Mutation Diagnostic (W515 Mutation)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 454"
    },
    {
      "testName": "Motility Semen",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Molloscum Contagiosum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 288"
    },
    {
      "testName": "MODY Type 5 2nd Tier (HNF1B Gene)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 463"
    },
    {
      "testName": "MODY Type 5 1st Tier (HNF1B Gene)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "MODY Type 4 1st Tier (PDX1 Gene)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 514"
    },
    {
      "testName": "MODY Type 3 2nd Tier (TCF1 Gene)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 948"
    },
    {
      "testName": "MODY Type 3 1st Tier (TCF1 Gene)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 948"
    },
    {
      "testName": "MODY Type 2 2nd Tier (GCK Gene)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 948"
    },
    {
      "testName": "MODY Type 2 1st Tier (GCK Gene)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 948"
    },
    {
      "testName": "MODY Type 1 2nd Tier (HNF4A Gene)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 948"
    },
    {
      "testName": "MODY Type 1 1st Tier (HNF4A Gene)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 948"
    },
    {
      "testName": "MODY Diagnostic",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Mitochondrial Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 56"
    },
    {
      "testName": "Microscopy Urine",
      "group": "Microbiology: Macroscopy & Microscopy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Microscopy Stool",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Microscopy Sputum",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Microscopy Gram",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Microscopy CSF Cell Count",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Microscopy & Biochemistry Urine",
      "group": "Microbiology",
      "type": "Panel",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 4"
    },
    {
      "testName": "Microscopy (KOH) Fungi",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "MICROSATELLITE INSTABILITY (MSI) - FRAGMENT A.",
      "group": "Cytogenetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 351"
    },
    {
      "testName": "Micronutrient Plus",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 323"
    },
    {
      "testName": "Micronutrient Essential",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "\"24 hrs",
      "Instructions": "14 days\"",
      "Price": ""
    },
    {
      "testName": "Microfilaria Thin Film",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 8"
    },
    {
      "testName": "Microfilaria Parasite",
      "group": "Microbiology",
      "type": "Panel",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 12"
    },
    {
      "testName": "Microfilaria Conc. Method",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 4"
    },
    {
      "testName": "Microalbumin (24 Hours Urine)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "10 days",
      "Instructions": "",
      "Price": "\"₦ 16"
    },
    {
      "testName": "MI2 Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 71"
    },
    {
      "testName": "Metoprolol",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 58"
    },
    {
      "testName": "Methylmalonic Acid Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 130"
    },
    {
      "testName": "Methylmalonic Acid Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 130"
    },
    {
      "testName": "Methylguanine-DNA Methyltransferase (MGMT) Promoter Status",
      "group": "Cytogenetics",
      "type": "Single Test",
      "ResultsTime": "25 days",
      "Instructions": "",
      "Price": "\"₦ 607"
    },
    {
      "testName": "Methotrexate",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 71"
    },
    {
      "testName": "Methicillin-resistant Staphylococcus aureus",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "Methemoglobin*",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Methadone Urine",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 27"
    },
    {
      "testName": "Methadone Serum",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 100"
    },
    {
      "testName": "Metformin in serum",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 159"
    },
    {
      "testName": "Metanephrines in Urine",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 75"
    },
    {
      "testName": "Metanephrines in Plasma*",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 123"
    },
    {
      "testName": "Metanephrine Plasma*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 63"
    },
    {
      "testName": "Metagenomic Stool Diagnostics* (GA-map) Gut Microbiome Testing",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "20 days",
      "Instructions": "",
      "Price": "\"₦ 202"
    },
    {
      "testName": "MERS Coronavirus-RNA",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 50"
    },
    {
      "testName": "Mercury Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 27"
    },
    {
      "testName": "Mercury Urine",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 52"
    },
    {
      "testName": "Mercury Blood",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 31"
    },
    {
      "testName": "Menopausal Screen",
      "group": "Endocrinology",
      "type": "Panel",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 39"
    },
    {
      "testName": "Meningococcus IgG Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 311"
    },
    {
      "testName": "Melatonin Urine",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 51"
    },
    {
      "testName": "Melatonin Serum*",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 50"
    },
    {
      "testName": "Melatonin Saliva",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 49"
    },
    {
      "testName": "Medication and Drug Screening in Urine",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 137"
    },
    {
      "testName": "Medication and Drug Screening in Serum",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 137"
    },
    {
      "testName": "MECP2 Gene Sequencing",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "23 days",
      "Instructions": "",
      "Price": "\"₦ 845"
    },
    {
      "testName": "MECP2 Gene MLPA",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "23 days",
      "Instructions": "",
      "Price": "\"₦ 327"
    },
    {
      "testName": "Measles Virus IgM Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 28"
    },
    {
      "testName": "Measles Virus IgG Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 28"
    },
    {
      "testName": "Mantoux",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 5"
    },
    {
      "testName": "Mannose Binding Lectin (MBL)",
      "group": "Clinical Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 78"
    },
    {
      "testName": "Manganese Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "Manganese in Serum (Mn)",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 39"
    },
    {
      "testName": "Mandrax (Methaqualone) Urine",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 27"
    },
    {
      "testName": "Mandelic Acid Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 58"
    },
    {
      "testName": "Malondialdehyde",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 43"
    },
    {
      "testName": "Male Hormone",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "24 hours/14 days",
      "Instructions": "",
      "Price": "\"₦ 67"
    },
    {
      "testName": "Malaria Thin Film",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Malaria Thick Film",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Malaria QBC",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 7"
    },
    {
      "testName": "Malaria Profile",
      "group": "Haematology",
      "type": "Panel",
      "ResultsTime": "6 hours",
      "Instructions": "",
      "Price": "\"₦ 14"
    },
    {
      "testName": "Malaria Parasite Ag",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Malaria DNA (PCR)",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 42"
    },
    {
      "testName": "Magnesium Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 27"
    },
    {
      "testName": "Magnesium Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 11"
    },
    {
      "testName": "Magnesium Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 5"
    },
    {
      "testName": "Magnesium Intra / Extracellular",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 10"
    },
    {
      "testName": "Magnesium 24hr Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "m81 - Staphylococcus aureus Toxin B IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "m80 - Staphylococcus aureus Toxin A IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "m3-Aspergillus fumigatus",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "m223 - Staphylococcus aureus enterotoxin C IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "M2 - Pyruvate Kinase (M2PK)",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 50"
    },
    {
      "testName": "m2 - Cladosporium herbarum IgE",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "NutriHealth",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "25 days",
      "Instructions": "",
      "Price": "\"₦ 329"
    },
    {
      "testName": "Nucleosome (Chromatin Abs)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "Nuclear Matrix Protein 22 (NMP22)",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 50"
    },
    {
      "testName": "NOTCH3 GENE TEST",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "20 days",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Norovirus antigen in Faeces",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 41"
    },
    {
      "testName": "Normetanephrine Plasma*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 62"
    },
    {
      "testName": "Norepinephrine Plasma (Noradrenaline)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 64"
    },
    {
      "testName": "Norepinephrine 24hr Urine (Noradrenaline)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 72"
    },
    {
      "testName": "NON-MICROCITRIC EXTENDED LUNG (NSCLC) CANCER PANEL - NGS + FISH",
      "group": "Cytogenetics",
      "type": "Single Test",
      "ResultsTime": "42 days",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Non-Invasive Rhesus Test (NIRT)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "20 days",
      "Instructions": "",
      "Price": "\"₦ 380"
    },
    {
      "testName": "Non-Invasive Prenatal Diagnosis (NIPT Trisomy + Sickle Cell Anemia + Sickle Cell & CF)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Non-Invasive Prenatal Diagnosis (NIPT + FETAL SEX)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Non-Invasive Prenatal Diagnosis",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Non-Gynaecological Cytology",
      "group": "Cytopathology",
      "type": "Single Test",
      "ResultsTime": "7 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "NMDA Receptor IgM Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "NMDA Receptor IgG Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "NMDA Receptor IgA Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "NMDA Receptor CSF",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "NK3 cells (CD3-/16/56+)",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 41"
    },
    {
      "testName": "Nitrite Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 27"
    },
    {
      "testName": "Nitrazepam",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 104"
    },
    {
      "testName": "Nitrates Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 27"
    },
    {
      "testName": "Nickel Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "Nickel in Urine*",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 149"
    },
    {
      "testName": "Nickel in Serum (Ni)*",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 49"
    },
    {
      "testName": "Newborn Screening Standard",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 31"
    },
    {
      "testName": "Newborn Screening Plus",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 65"
    },
    {
      "testName": "Newborn Screening Extra Plus",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 93"
    },
    {
      "testName": "Newborn Screening Extended",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 93"
    },
    {
      "testName": "Neutrophil to Lymphocyte Ratio (Immune Status)",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 138"
    },
    {
      "testName": "\"Neutrophil Gelatinase Associated Lipocalin",
      "group": "Urine (NGAL)\"",
      "type": "Immunology",
      "ResultsTime": "Single Test",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "Neuron Specific Enolase Serum",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Neuron Specific Enolase CSF",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Neuron Specific Enolase Aspirate",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Neurofibromatosis Type 2 (NF2) Sequencing",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Neurofibromatosis Type 2 (NF2) MLPA",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 349"
    },
    {
      "testName": "Neurofibromatosis Type 2 (NF2) Full Gene",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "42 days",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Neurofibromatosis Type 1 & 2 (NF1&2) Sequencing",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Neurofibromatosis Type 1 & 2 (NF1&2) MLPA",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 868"
    },
    {
      "testName": "Neurofibromatosis Type 1 & 2 (NF1&2) Full Gene",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "42 days",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Neurofibromatosis Type 1 (NF1) Sequencing",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Neurofibromatosis Type 1 (NF1) MLPA",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 580"
    },
    {
      "testName": "Neurofibromatosis Type 1 (NF1) Full Gene",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "42 days",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Neurofibromatosis (NF) NGS",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "30 days",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "neoBona Genomewide",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 545"
    },
    {
      "testName": "neoBona",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 329"
    },
    {
      "testName": "Neisseria meningitidis DNA CSF (PCR)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 42"
    },
    {
      "testName": "Neisseria gonorrhoeae DNA",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 50"
    },
    {
      "testName": "\"NARP (Neuropathy",
      "group": "Ataxia and Retinitis Pigmentosa) Syndrome\"",
      "type": "Genetics",
      "ResultsTime": "Single Test",
      "Instructions": "21 days",
      "Price": ""
    },
    {
      "testName": "Nagalase",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 192"
    },
    {
      "testName": "Oxycodon Urine",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 42"
    },
    {
      "testName": "Oxycodon Serum",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 100"
    },
    {
      "testName": "Oxidation Status*",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 125"
    },
    {
      "testName": "Oxidated LDL*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 51"
    },
    {
      "testName": "Oxcarbazepine",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Oxalate Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Oxalate Creatinine Urine",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Oxalate 24hr Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Ovulatory Profile (21 Days)",
      "group": "Endocrinology",
      "type": "Panel",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 80"
    },
    {
      "testName": "Ovarian Reserve Panel",
      "group": "",
      "type": "Panel",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 53"
    },
    {
      "testName": "Ovarian Reserve Panel",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "24 hrs",
      "Instructions": "",
      "Price": "\"₦ 53"
    },
    {
      "testName": "Osteoporosis",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "\"3 hrs",
      "Instructions": "3 days\"",
      "Price": ""
    },
    {
      "testName": "\"Osteogenesis Imperfecta (COL1A1",
      "group": "COL1A2)\"",
      "type": "Genetics",
      "ResultsTime": "Single Test",
      "Instructions": "31 days",
      "Price": ""
    },
    {
      "testName": "Osteocalcin*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 58"
    },
    {
      "testName": "Osmolality Urine 24hr",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 12"
    },
    {
      "testName": "Osmolality Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 14"
    },
    {
      "testName": "Osmolality Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 19"
    },
    {
      "testName": "Ortho-Phosphate Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Ornithine Transcarbamylase Deficiency (OTC)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 436"
    },
    {
      "testName": "Organism Id",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "3 days",
      "Instructions": "",
      "Price": "\"₦ 23"
    },
    {
      "testName": "Organic Acids Urine*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 123"
    },
    {
      "testName": "Oral Glucose Tolerance Test (OGTT 75g)",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 9"
    },
    {
      "testName": "Opiates Urine GCMS",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 100"
    },
    {
      "testName": "Opiates Urine",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 27"
    },
    {
      "testName": "Opiates Serum",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 229"
    },
    {
      "testName": "Opiates Hair",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 150"
    },
    {
      "testName": "ONCOinBLOOD - PIK3CA",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "30 days",
      "Instructions": "",
      "Price": "\"₦ 375"
    },
    {
      "testName": "ONCOinBLOOD - MET SPLICING",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "30 days",
      "Instructions": "",
      "Price": "\"₦ 293"
    },
    {
      "testName": "ONCOinBLOOD - KRAS",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "30 days",
      "Instructions": "",
      "Price": "\"₦ 293"
    },
    {
      "testName": "ONCOinBLOOD - EML4-ALK",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "30 days",
      "Instructions": "",
      "Price": "\"₦ 293"
    },
    {
      "testName": "ONCOinBLOOD - EGFR C797S",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "30 days",
      "Instructions": "",
      "Price": "\"₦ 293"
    },
    {
      "testName": "ONCOinBLOOD - EGFR",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "30 days",
      "Instructions": "",
      "Price": "\"₦ 293"
    },
    {
      "testName": "ONCOinBLOOD - BRAF",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "30 days",
      "Instructions": "",
      "Price": "\"₦ 293"
    },
    {
      "testName": "ONCOinBLOOD - ALK",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "30 days",
      "Instructions": "",
      "Price": "\"₦ 293"
    },
    {
      "testName": "Omega 6 Fatty Acids",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 162"
    },
    {
      "testName": "Omega 3 Fatty Acids",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 162"
    },
    {
      "testName": "Oligoclonal Bands CSF/SERUM IgG Index",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 56"
    },
    {
      "testName": "Oestriol (Downs) *",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 49"
    },
    {
      "testName": "Oestradiol (Estrogen E2)",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 13"
    },
    {
      "testName": "OCULOCUTANEOUS/OCULAR ALBINISM - PANEL - NGS",
      "group": "Molecular Genetics",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Purkinje Cells (Yo) Abs Serum (IFT)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Purkinje Cells (Yo) Abs Serum (Blot)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "Purkinje Cells (Yo) Abs CSF (IFT)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "Purkinje Cells (Yo) Abs CSF (Blot)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "Pseudomonas aeruginosa Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "Pseudomonas aeruginosa exotoxin A Ab.IgG",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "PSA",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 20"
    },
    {
      "testName": "Prothrombin IgM Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "Prothrombin IgG Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "Proteinase 3 Antibodies (PR3)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 71"
    },
    {
      "testName": "Protein Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Protein S Antigen*",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 62"
    },
    {
      "testName": "Protein S Activity*",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 58"
    },
    {
      "testName": "Protein Electrophoresis",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 12"
    },
    {
      "testName": "Protein Dialysate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 5"
    },
    {
      "testName": "Protein CSF",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Protein Creatinine Urine",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 5"
    },
    {
      "testName": "Protein C Antigen*",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 62"
    },
    {
      "testName": "Protein C Activity*",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 58"
    },
    {
      "testName": "Protein Aspirate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Protein 24hr Urine",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Protein 14-3-3 in CSF",
      "group": "CSF diagnostics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 54"
    },
    {
      "testName": "Prostate-Specific Antigen (PSA)",
      "group": "Endocrinology",
      "type": "Panel",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 38"
    },
    {
      "testName": "Prostate Health Index (PHI)*",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "20 days",
      "Instructions": "",
      "Price": "\"₦ 143"
    },
    {
      "testName": "Prostaglandin E2[*]",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 893"
    },
    {
      "testName": "Propoxyphene Urine",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Promethazine",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 103"
    },
    {
      "testName": "Prolactin",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 14"
    },
    {
      "testName": "Proinsulin",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 68"
    },
    {
      "testName": "Progesterone",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 13"
    },
    {
      "testName": "Procalcitonin*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 82"
    },
    {
      "testName": "Pro Gastrin Releasing Peptide",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 52"
    },
    {
      "testName": "Premarital Panel 2",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 34"
    },
    {
      "testName": "Premarital Panel 1",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 37"
    },
    {
      "testName": "Pregnenolone Sulfate",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 100"
    },
    {
      "testName": "Pregnenolone",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 100"
    },
    {
      "testName": "Prednisolone",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Prealbumin",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "Pre-Operation Panel",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Potassium Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Potassium Stool",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "Potassium Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Potassium Dialysate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 5"
    },
    {
      "testName": "Potassium Aspirate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Potassium 24hr Urine",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Porphobilinogen 24hr Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 47"
    },
    {
      "testName": "Polyoma JCV DNA Urine (PCR)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 218"
    },
    {
      "testName": "Polyoma JCV DNA (PCR)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 218"
    },
    {
      "testName": "Polyoma BKV DNA Urine (PCR)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 218"
    },
    {
      "testName": "Polyoma BKV DNA (PCR)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 218"
    },
    {
      "testName": "Polymyositis Scleroderma (PM/Scl) p75",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Polymyositis Scleroderma (PM/Scl) p100",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Polymorph Count",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Polycyclic Aromatic Hydrocarbons (PAH) Urine",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 43"
    },
    {
      "testName": "Polycyclic Aromatic Hydrocarbons (PAH) Blood",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 197"
    },
    {
      "testName": "Polio Virus Type 3 Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "Polio Virus Type 1 Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "Polio Virus Antibodies",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 57"
    },
    {
      "testName": "Polarized Micro For Crystals",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 8"
    },
    {
      "testName": "PNH Phenotyping",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 307"
    },
    {
      "testName": "Pneumocystis jiroveci DNA (PCR)",
      "group": "Infection Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 53"
    },
    {
      "testName": "Pneumococcus-IgG abs (PCP)",
      "group": "Infection Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "PM/Scl Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Platinum Wellness Test Male",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "24 hours/3 days/14 days",
      "Instructions": "",
      "Price": "\"₦ 228"
    },
    {
      "testName": "Platinum Wellness Test Female",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "24 hours/3 days/14 days",
      "Instructions": "",
      "Price": "\"₦ 228"
    },
    {
      "testName": "Platelet Count",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Platelet Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 206"
    },
    {
      "testName": "Plasminogen Activator Inhibitor (PAI)*",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 43"
    },
    {
      "testName": "Plasminogen",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 171"
    },
    {
      "testName": "Placental Alkaline Phosphatase (ALP)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 44"
    },
    {
      "testName": "PKHD1",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "42 days",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Pituitary Screen",
      "group": "Endocrinology",
      "type": "Panel",
      "ResultsTime": "24 hours/14 days",
      "Instructions": "",
      "Price": "\"₦ 93"
    },
    {
      "testName": "PIK3CA in Tissue",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 446"
    },
    {
      "testName": "Phthalates Urine",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 101"
    },
    {
      "testName": "Phospholipase A2-Receptor IgG Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 31"
    },
    {
      "testName": "Phosphatidylserine IgM Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Phosphatidylserine IgG Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Phosphatidylserine IgA Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Phosphate Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 32"
    },
    {
      "testName": "Phosphate Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Phosphate Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Phosphate Dialysate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 6"
    },
    {
      "testName": "Phosphate Cord",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Phosphate Aspirate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Phosphate 24hr Urine",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Phenytoin (Epanutin)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Phenylalanine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 20"
    },
    {
      "testName": "Phenol Urine",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 57"
    },
    {
      "testName": "Phenobarbitone",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Phencyclidine Urine",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Phadiatop (Inhalent Screen)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "pH Water",
      "group": "Water Analysis",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 18"
    },
    {
      "testName": "pH Urine",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "pH Stool",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "pH Aspirate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "PH (Water GmbH)",
      "group": "Water Analysis",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 19"
    },
    {
      "testName": "Pethidine Urine",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 130"
    },
    {
      "testName": "Pethidine Blood",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 130"
    },
    {
      "testName": "Perinuclear ANCA (pANCA) (Myeloperoxidase MPO)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 44"
    },
    {
      "testName": "Pentazocine Urine (Sosegon)",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 41"
    },
    {
      "testName": "PEARL Ladies Panel",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 52"
    },
    {
      "testName": "Peachy Plum Plus",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "3 hours/24 hours/3 days/14 days",
      "Instructions": "",
      "Price": "\"₦ 82"
    },
    {
      "testName": "Peachy Plum Max",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "3 hours/24 hours/3 days/14 days",
      "Instructions": "",
      "Price": "\"₦ 301"
    },
    {
      "testName": "Peachy Plum Extended",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "3 hours/24 hours/3 days/14 days",
      "Instructions": "",
      "Price": "\"₦ 113"
    },
    {
      "testName": "Peachy Plum",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "3 hours/24 hours/3 days/14 days",
      "Instructions": "",
      "Price": "\"₦ 57"
    },
    {
      "testName": "Peachy On The Go",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 10"
    },
    {
      "testName": "Peachy",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "3 hours/24 hours/3 days/14 days",
      "Instructions": "",
      "Price": "\"₦ 18"
    },
    {
      "testName": "PDL1 Immunochemistry",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 326"
    },
    {
      "testName": "PD-L1 Gene Expression",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 371"
    },
    {
      "testName": "PCA3-Score",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 405"
    },
    {
      "testName": "Pathologist Bone Marrow Aspirate Film Review",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "5 days",
      "Instructions": "",
      "Price": "\"₦ 8"
    },
    {
      "testName": "Pathologist Blood Film Review",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 5"
    },
    {
      "testName": "Parvovirus B19 IgM Abs (Blot)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 40"
    },
    {
      "testName": "Parvovirus B19 IgM Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Parvovirus B19 IgG Abs (Blot)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 40"
    },
    {
      "testName": "Parvovirus B19 IgG Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Parvovirus B19 DNA CSF (PCR)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 52"
    },
    {
      "testName": "Parvovirus B19 DNA (PCR)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 52"
    },
    {
      "testName": "Particles/Foreign Substances in Drink (Poison)",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 637"
    },
    {
      "testName": "Parietal Cell Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 27"
    },
    {
      "testName": "Parathormone*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 59"
    },
    {
      "testName": "Parathormone Related Protein*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 48"
    },
    {
      "testName": "Parasites",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 7"
    },
    {
      "testName": "Paraneoplastic Autoantibody Panel",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 110"
    },
    {
      "testName": "Paracetamol (Acetaminophen) Urine",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Paracetamol (Acetaminophen)",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Pap Smear",
      "group": "Cytopathology",
      "type": "Single Test",
      "ResultsTime": "3 days",
      "Instructions": "",
      "Price": "\"₦ 18"
    },
    {
      "testName": "Pancreatic Polypeptide (PP)*",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 77"
    },
    {
      "testName": "Pancreatic Amylase",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 53"
    },
    {
      "testName": "Palmitic Acid",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 122"
    },
    {
      "testName": "Packed Cell Volume (PCV)",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "p53 Autoantibody",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 98"
    },
    {
      "testName": "p450c21-Hydroxylase Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 31"
    },
    {
      "testName": "p450c17-Hydroxylase Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 31"
    },
    {
      "testName": "P16-/KI67- protein biomarkers",
      "group": "Cytopathology",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 84"
    },
    {
      "testName": "QUANTITATIVE REARRANGEMENT PML/RAR RT-PCR",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "20 days",
      "Instructions": "",
      "Price": "\"₦ 163"
    },
    {
      "testName": "Quantiferon TB",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "5 days",
      "Instructions": "",
      "Price": "\"₦ 60"
    },
    {
      "testName": "RUBY Ladies Panel",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "24 hours/3 days",
      "Instructions": "",
      "Price": "\"₦ 106"
    },
    {
      "testName": "Rubella-RNA (PCR)",
      "group": "Infection Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 53"
    },
    {
      "testName": "Rubella IgM Ab Avidity",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 53"
    },
    {
      "testName": "Rubella IgM Ab (Vidas)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "Rubella IgG Ab Avidity",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 53"
    },
    {
      "testName": "Rubella IgG Ab (Vidas)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "Rubella Elisa IgM Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "Rubella Elisa IgG Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "RPR Agglutination",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 7"
    },
    {
      "testName": "Rotavirus",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "ROMA Index",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 70"
    },
    {
      "testName": "Roberts Syndrome (ESCO2 gene sequencing)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "32 days",
      "Instructions": "",
      "Price": "\"₦ 948"
    },
    {
      "testName": "RNP/Sm abs (68 rec.)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "RNA Polymerase III Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Rifampicin (RMP)*",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 52"
    },
    {
      "testName": "Rickettsia typhi/prowazekii IgM abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Rickettsia typhi/prowazekii IgG abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Rickettsia tsutsugamushi abs (Scrub typhus)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Rickettsia DNA (PCR)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 61"
    },
    {
      "testName": "Rickettsia conori/rickettsii IgM abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Rickettsia conori/rickettsii IgG abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Ribosomal-P Protein Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 45"
    },
    {
      "testName": "RF Latex (Screen)",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 6"
    },
    {
      "testName": "RF IgM",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 47"
    },
    {
      "testName": "RF IgG",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 47"
    },
    {
      "testName": "RF IgA",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 47"
    },
    {
      "testName": "RF (Quant)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 32"
    },
    {
      "testName": "Reverse T3 (LCMS)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 103"
    },
    {
      "testName": "Retinol Binding Globulin",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "Reticulocyte Count",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 5"
    },
    {
      "testName": "Respiratory syncytial virus Ab.IgG",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 26"
    },
    {
      "testName": "Respiratory syncytial virus Ab.IgA",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 26"
    },
    {
      "testName": "Respiratory Pathogen Panel",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 156"
    },
    {
      "testName": "Residual Food Ingredients in stool",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "10 days",
      "Instructions": "",
      "Price": "\"₦ 49"
    },
    {
      "testName": "Renin*",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 100"
    },
    {
      "testName": "Renal Calculus X Ray Diff (Stone Analysis)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 64"
    },
    {
      "testName": "Reducing Substances Urine",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Reducing Substances Stool",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "RAD51C MLPA",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "46 days",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Rabies RNA (PCR)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 61"
    },
    {
      "testName": "Rabies DNA in CSF",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 61"
    },
    {
      "testName": "Rabies Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 34"
    },
    {
      "testName": "Swab Analysis (Throat)",
      "group": "Microbiology",
      "type": "Panel",
      "ResultsTime": "3 days",
      "Instructions": "",
      "Price": "\"₦ 11"
    },
    {
      "testName": "Swab Analysis (STD)",
      "group": "Microbiology",
      "type": "Panel",
      "ResultsTime": "24 hours/3 days",
      "Instructions": "",
      "Price": "\"₦ 16"
    },
    {
      "testName": "Swab Analysis (PUS)",
      "group": "Microbiology",
      "type": "Panel",
      "ResultsTime": "24 hours/3 days",
      "Instructions": "",
      "Price": "\"₦ 16"
    },
    {
      "testName": "Surface Swab TVC",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 8"
    },
    {
      "testName": "Surface Swab STAPH",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 10"
    },
    {
      "testName": "Surface Swab EC",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 9"
    },
    {
      "testName": "Surface Swab EB",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 8"
    },
    {
      "testName": "Superoxide Dismutase",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 52"
    },
    {
      "testName": "Sulphates Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "Sulfonylurea Screening (Oral Antidiabetics Urine)",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 100"
    },
    {
      "testName": "STUDENTS SCREENING",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "3 hours/3 days",
      "Instructions": "",
      "Price": "\"₦ 39"
    },
    {
      "testName": "Strychnin",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 52"
    },
    {
      "testName": "Strongyloides IgG Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 48"
    },
    {
      "testName": "Strongyloides Abs (Immunoblot)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 58"
    },
    {
      "testName": "Streptomycin",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 70"
    },
    {
      "testName": "Streptococcus pneumoniae Ab.IgG",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Streptococcus Group B Culture",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "3 days",
      "Instructions": "",
      "Price": "\"₦ 14"
    },
    {
      "testName": "Stool Concentration Parasites",
      "group": "Microbiology",
      "type": "Panel",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 11"
    },
    {
      "testName": "Stool Analysis Paediatric",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "5 days",
      "Instructions": "",
      "Price": "\"₦ 23"
    },
    {
      "testName": "Stool Analysis",
      "group": "Microbiology",
      "type": "Panel",
      "ResultsTime": "24 hours/3 days",
      "Instructions": "",
      "Price": "\"₦ 19"
    },
    {
      "testName": "Steatocrit (Steatorrhoea)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 62"
    },
    {
      "testName": "Std Wet Prep",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "STD Profile 2",
      "group": "Serology",
      "type": "Panel",
      "ResultsTime": "24 hours/14 days",
      "Instructions": "",
      "Price": "\"₦ 110"
    },
    {
      "testName": "STD Profile 1",
      "group": "Serology",
      "type": "Panel",
      "ResultsTime": "24 hours/14 days",
      "Instructions": "",
      "Price": "\"₦ 63"
    },
    {
      "testName": "Staphylococcus aureus Food",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 32"
    },
    {
      "testName": "SSB (La) Ab (Immunoblot)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "SSB (La) Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 49"
    },
    {
      "testName": "SSA (Ro) Ab (Immunoblot)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 30"
    },
    {
      "testName": "SSA (Ro) Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 49"
    },
    {
      "testName": "Squamous Cell Carcinoma Ag",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 55"
    },
    {
      "testName": "SportGen",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "25 days",
      "Instructions": "",
      "Price": "\"₦ 329"
    },
    {
      "testName": "Spinocerebellar Ataxia",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 379"
    },
    {
      "testName": "SPINAL MUSCULAR ATROPHY - SMN1 - SANGER + Q-PCR",
      "group": "Molecular Genetics",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 733"
    },
    {
      "testName": "SPINAL MUSCLE ATROPHY - SMN1 - MLPA",
      "group": "Molecular Genetics",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 439"
    },
    {
      "testName": "Sphingomyelinase",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 59"
    },
    {
      "testName": "Spermiogram",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 26"
    },
    {
      "testName": "Sperm IgG Antibodies in Serum",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 54"
    },
    {
      "testName": "Sperm IgG Antibodies in Semen",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 54"
    },
    {
      "testName": "Sperm IgA Antibodies in Serum",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 54"
    },
    {
      "testName": "Sperm IgA Antibodies in Semen",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 54"
    },
    {
      "testName": "Sperm DNA Fragmentation Index*",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 37"
    },
    {
      "testName": "Somatostatin*",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 724"
    },
    {
      "testName": "Somatomedin C (IGF-1)*",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 72"
    },
    {
      "testName": "Somatomedin A (IGF-2)*",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 68"
    },
    {
      "testName": "Soluble Transferrin Receptor (sTfR)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "\"Soluble Liver Antigen Antibodies (SLA",
      "group": "LP",
      "type": "liver-pancreas abs)\"",
      "ResultsTime": "Immunology",
      "Instructions": "Single Test",
      "Price": "14 days"
    },
    {
      "testName": "Sodium Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 27"
    },
    {
      "testName": "Sodium Valproate(Epilum)",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 39"
    },
    {
      "testName": "Sodium Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Sodium Stool",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "Sodium Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Sodium Aspirate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Sodium 24hr Urine",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Smooth Muscle Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 63"
    },
    {
      "testName": "Smith Abs (Immunoblot)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "Smith Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 49"
    },
    {
      "testName": "SM/RNP Ab (Immunoblot)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "Skin Allergy Panel",
      "group": "Allergy",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 248"
    },
    {
      "testName": "Sirolimus",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 119"
    },
    {
      "testName": "Silver-Russell Syndrome (SRS)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "28 days",
      "Instructions": "",
      "Price": "\"₦ 526"
    },
    {
      "testName": "Silver Wellness Test",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "3 hours/24 hours",
      "Instructions": "",
      "Price": "\"₦ 46"
    },
    {
      "testName": "Silica Water (SiO2)",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Sickle Cell Anemia (SCD) HBSC",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 181"
    },
    {
      "testName": "Sialic Acid (Neuraminic Acid)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 69"
    },
    {
      "testName": "Sex Hormone Binding Globulin (SHBG)",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "\"SEVERE AZOOSPERMIA/OLIGOSPERMIA - CHROMOSOME Y(MICRODELETIONS AZFa",
      "group": "AZFb",
      "type": "AZFc) - FRAGMENT ANALYSIS\"",
      "ResultsTime": "Molecular Genetics",
      "Instructions": "Single Test",
      "Price": "14 days"
    },
    {
      "testName": "Serotonin 24hr Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 23"
    },
    {
      "testName": "Serotonin",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 52"
    },
    {
      "testName": "\"Sensors Water (Colour",
      "group": "Turbidity",
      "type": "Taste",
      "ResultsTime": "Smell)\"",
      "Instructions": "Water Analysis",
      "Price": "Single Test"
    },
    {
      "testName": "Selenium Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "Selenium Serum",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 51"
    },
    {
      "testName": "Selenium in Erythrocytes",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 31"
    },
    {
      "testName": "Sedimentation Rate (ESR)",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Second Opinion LBC",
      "group": "Cytopathology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 10"
    },
    {
      "testName": "Second Opinion Band 2 Histology",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 28"
    },
    {
      "testName": "SDS Gel Electrophoresis Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 37"
    },
    {
      "testName": "Scleroderma 70 Ab (Immunoblot)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "Scleroderma 70 Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 71"
    },
    {
      "testName": "Schistosoma mansonii (Cercaria) IgG Ab (Intes. Bilharzia)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Schistosoma mansonii (Adult) IgG Ab (Intes. Bilharzia)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Schistosoma haematobium (Cercaria) IgG Ab (Blad. Bilharzia)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Schistosoma haematobium (Adult) IgG Ab (Blad. Bilharzia)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "SAPPHIRE Ladies Panel",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Salmonella Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "Salmonella typhi / paratyphi Ag (Widal)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "6 hours",
      "Instructions": "",
      "Price": "\"₦ 5"
    },
    {
      "testName": "Salmonella IgG/A/M Abs Screening",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "10 days",
      "Instructions": "",
      "Price": "\"₦ 28"
    },
    {
      "testName": "Salmonella IgA Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 19"
    },
    {
      "testName": "Salmonella Food",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 114"
    },
    {
      "testName": "Salmonella Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Salivary Alpha Amylase",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "10 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "Salicylate",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Saccharomyces Cerevisiae IgG Ab (IFT)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Saccharomyces Cerevisiae IgG Ab (EIA)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "S-Phenylmercapturic acid (PMA)",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 100"
    },
    {
      "testName": "S 100-Protein in Serum",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 73"
    },
    {
      "testName": "S 100-Protein in CSF",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 73"
    },
    {
      "testName": "Sodium Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Sodium Aspirate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Sodium 24hr Urine",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Smooth Muscle Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 63"
    },
    {
      "testName": "Smith Abs (Immunoblot)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "Smith Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 49"
    },
    {
      "testName": "SM/RNP Ab (Immunoblot)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "Skin Allergy Panel",
      "group": "Allergy",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 248"
    },
    {
      "testName": "Sirolimus",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 119"
    },
    {
      "testName": "Silver-Russell Syndrome (SRS)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "28 days",
      "Instructions": "",
      "Price": "\"₦ 526"
    },
    {
      "testName": "Silver Wellness Test",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "3 hours/24 hours",
      "Instructions": "",
      "Price": "\"₦ 46"
    },
    {
      "testName": "Silica Water (SiO2)",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "Sickle Cell Anemia (SCD) HBSC",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 181"
    },
    {
      "testName": "Sialic Acid (Neuraminic Acid)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 69"
    },
    {
      "testName": "Sex Hormone Binding Globulin (SHBG)",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "\"SEVERE AZOOSPERMIA/OLIGOSPERMIA - CHROMOSOME Y(MICRODELETIONS AZFa",
      "group": "AZFb",
      "type": "AZFc) - FRAGMENT ANALYSIS\"",
      "ResultsTime": "Molecular Genetics",
      "Instructions": "Single Test",
      "Price": "14 days"
    },
    {
      "testName": "Serotonin 24hr Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 23"
    },
    {
      "testName": "Serotonin",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 52"
    },
    {
      "testName": "\"Sensors Water (Colour",
      "group": "Turbidity",
      "type": "Taste",
      "ResultsTime": "Smell)\"",
      "Instructions": "Water Analysis",
      "Price": "Single Test"
    },
    {
      "testName": "Selenium Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "Selenium Serum",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 51"
    },
    {
      "testName": "Selenium in Erythrocytes",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 31"
    },
    {
      "testName": "Sedimentation Rate (ESR)",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Second Opinion LBC",
      "group": "Cytopathology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 10"
    },
    {
      "testName": "Second Opinion Band 2 Histology",
      "group": "Histopathology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 28"
    },
    {
      "testName": "SDS Gel Electrophoresis Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 37"
    },
    {
      "testName": "Scleroderma 70 Ab (Immunoblot)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "Scleroderma 70 Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 71"
    },
    {
      "testName": "Schistosoma mansonii (Cercaria) IgG Ab (Intes. Bilharzia)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Schistosoma mansonii (Adult) IgG Ab (Intes. Bilharzia)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Schistosoma haematobium (Cercaria) IgG Ab (Blad. Bilharzia)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Schistosoma haematobium (Adult) IgG Ab (Blad. Bilharzia)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "SAPPHIRE Ladies Panel",
      "group": "MULTI-DISCIPLINE",
      "type": "Panel",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Salmonella Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "Salmonella typhi / paratyphi Ag (Widal)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "6 hours",
      "Instructions": "",
      "Price": "\"₦ 5"
    },
    {
      "testName": "Salmonella IgG/A/M Abs Screening",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "10 days",
      "Instructions": "",
      "Price": "\"₦ 28"
    },
    {
      "testName": "Salmonella IgA Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 19"
    },
    {
      "testName": "Salmonella Food",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 114"
    },
    {
      "testName": "Salmonella Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Salivary Alpha Amylase",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "10 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "Salicylate",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Saccharomyces Cerevisiae IgG Ab (IFT)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "Saccharomyces Cerevisiae IgG Ab (EIA)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 29"
    },
    {
      "testName": "S-Phenylmercapturic acid (PMA)",
      "group": "Environmental and Occupational Health",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 100"
    },
    {
      "testName": "S 100-Protein in Serum",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 73"
    },
    {
      "testName": "S 100-Protein in CSF",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 73"
    },
    {
      "testName": "Turbidity Water",
      "group": "Water Analysis",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 11"
    },
    {
      "testName": "Turbidity FTU (Water GmbH)",
      "group": "Water Analysis",
      "type": "Single Test",
      "ResultsTime": "16 days",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "Tumour Necrosis Factor Alpha",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 80"
    },
    {
      "testName": "TTN Gene (Titin Gene Mutation)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "52 days",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "TSH Receptor Antibodies *",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "TSH Basal (Supersensitive)",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 10"
    },
    {
      "testName": "TSH",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 9"
    },
    {
      "testName": "Tryptase",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Trypsin Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 50"
    },
    {
      "testName": "Trypanosoma cruzi IgM Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Trypanosoma cruzi IgG Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Trypanosoma cruzi DNA (PCR)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 307"
    },
    {
      "testName": "Trypanosoma cruzi Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Trypanosoma brucei IgG Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 95"
    },
    {
      "testName": "Trypanosoma brucei gambiense / rhodesiense DNA (PCR)",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 307"
    },
    {
      "testName": "Troponin-I",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 59"
    },
    {
      "testName": "Troponin T (Cardiac Reader)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 22"
    },
    {
      "testName": "Trimethylamine Urine*",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 515"
    },
    {
      "testName": "Triglyceride Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Triglyceride Aspirate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 5"
    },
    {
      "testName": "Tricyclic antidepressants",
      "group": "Toxicology/Drugs",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Trichomonas vaginalis DNA",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Treponema pallidum IgM Ab (Blot)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 39"
    },
    {
      "testName": "Treponema pallidum IgG Ab (Blot)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 39"
    },
    {
      "testName": "Treponema pallidum DNA CSF",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 113"
    },
    {
      "testName": "Treponema pallidum DNA",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 113"
    },
    {
      "testName": "Treponema pallidum Abs IgG/IgM",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "Transferrin",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 17"
    },
    {
      "testName": "Tramadol Urine",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Tramadol Plasma",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 100"
    },
    {
      "testName": "TPHA Serum",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 13"
    },
    {
      "testName": "TPHA CSF",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 13"
    },
    {
      "testName": "Toxoplasma IgM Ab (Vidas)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "Toxoplasma IgG Ab (Vidas)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "Toxoplasma gondii IgA ISAGA",
      "group": "Infection Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 28"
    },
    {
      "testName": "Toxoplasma gondii DNA in CSF (PCR)",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 61"
    },
    {
      "testName": "Toxoplasma Elisa IgM Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "Toxoplasma Elisa IgG Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "Toxoplasma Elisa IgA Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "Toxoplasma Elisa Ab Avidity",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 53"
    },
    {
      "testName": "Toxocara canis IgG abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 28"
    },
    {
      "testName": "\"Total",
      "group": "Free Testosterone & SHBG (Males)\"",
      "type": "Endocrinology",
      "ResultsTime": "Panel",
      "Instructions": "24 hours/14 days",
      "Price": ""
    },
    {
      "testName": "Total T4",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 14"
    },
    {
      "testName": "Total T3",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 14"
    },
    {
      "testName": "Total Solids (Water GmbH)",
      "group": "Water Analysis",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 26"
    },
    {
      "testName": "Total Protein & Albumin",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Total Protein",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Total Porphyrines 24hr Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Total Oxidative Capacity*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 62"
    },
    {
      "testName": "Total Light Chains Serum",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 49"
    },
    {
      "testName": "Total Ketones*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "Total Hemolytic Complement (CH50)*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "\"Total Hardness Water (Incl. Calcium",
      "group": "Magnesium)\"",
      "type": "Water Analysis",
      "ResultsTime": "Single Test",
      "Instructions": "",
      "Price": ""
    },
    {
      "testName": "Total Dissolved Solids @ 180 Water",
      "group": "Water Analysis",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 27"
    },
    {
      "testName": "Total Cholesterol",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Total Bacteria Count",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "3 days",
      "Instructions": "",
      "Price": "\"₦ 2"
    },
    {
      "testName": "Total Antioxidative Capacity*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 62"
    },
    {
      "testName": "Torch Screen",
      "group": "Serology",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 165"
    },
    {
      "testName": "Topiramate*",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 100"
    },
    {
      "testName": "Tobramycin Pre",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Tobramycin Post",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Tissue Transglutaminase IgG Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 43"
    },
    {
      "testName": "Tissue Transglutaminase IgA Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 43"
    },
    {
      "testName": "Tissue Polypeptide Antigen (TPA)",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 49"
    },
    {
      "testName": "Tissue Culture",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Tissue Antibodies Panel",
      "group": "Immunology",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 74"
    },
    {
      "testName": "Thyroxine Binding Globulin",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 14"
    },
    {
      "testName": "Thyroid Stimulating Immunglobulin (TSI)",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 23"
    },
    {
      "testName": "Thyroid Stimulating Hormone (TSH)",
      "group": "",
      "type": "Panel",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 9"
    },
    {
      "testName": "Thyroid Peroxidase Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 41"
    },
    {
      "testName": "Thyroid Function Plus",
      "group": "Endocrinology",
      "type": "Panel",
      "ResultsTime": "24 hours/14 days",
      "Instructions": "",
      "Price": "\"₦ 61"
    },
    {
      "testName": "Thyroid Function",
      "group": "Endocrinology",
      "type": "Panel",
      "ResultsTime": "24 hours",
      "Instructions": "",
      "Price": "\"₦ 25"
    },
    {
      "testName": "\"Thyroid Antibodies",
      "group": "Extended\"",
      "type": "Endocrinology",
      "ResultsTime": "Panel",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "\"Thyroid Antibodies",
      "group": "Basic\"",
      "type": "Endocrinology",
      "ResultsTime": "Panel",
      "Instructions": "14 days",
      "Price": ""
    },
    {
      "testName": "Thyroglobulin Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 41"
    },
    {
      "testName": "Thyroglobulin",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 37"
    },
    {
      "testName": "Thymidine Kinase",
      "group": "Tumour Markers",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 75"
    },
    {
      "testName": "THROMBOPHILIA SCREENING (THRO)",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 516"
    },
    {
      "testName": "Thrombin Time*",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 10"
    },
    {
      "testName": "Thrombin Antithrombin Complex (TAT)*",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 43"
    },
    {
      "testName": "Thioridazine (Melleril)",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 100"
    },
    {
      "testName": "Thiopurine-S-Methyltransferase Deficiency",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 311"
    },
    {
      "testName": "Thiopurine-S-Methyltransferase Activity",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Theophylline",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Thanatophoric Dysplasia (FGFR3 gene)",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "28 days",
      "Instructions": "",
      "Price": "\"₦ 929"
    },
    {
      "testName": "TH1 - TH2 Immunity (Intracellular Cytokines IL & INF)",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 156"
    },
    {
      "testName": "Tetracycline",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Tetanus Antitoxin Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 31"
    },
    {
      "testName": "\"Testosterone",
      "group": "SHBG & FAI (Females)\"",
      "type": "Endocrinology",
      "ResultsTime": "Panel",
      "Instructions": "24 hours/14 days",
      "Price": ""
    },
    {
      "testName": "Testosterone",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 14"
    },
    {
      "testName": "TB Culture",
      "group": "Microbiology",
      "type": "Single Test",
      "ResultsTime": "5 days",
      "Instructions": "",
      "Price": "\"₦ 43"
    },
    {
      "testName": "Tay-Sachs Diagnostic",
      "group": "Haematology",
      "type": "Panel",
      "ResultsTime": "35 days",
      "Instructions": "",
      "Price": "\"₦ 250"
    },
    {
      "testName": "Tartrate-Resistant Acid Phosphatase ( Bone TRAP )",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 47"
    },
    {
      "testName": "Tacrolimus (FK506 Levels)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 119"
    },
    {
      "testName": "t9-Olive Tree (Olea europaea)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 33"
    },
    {
      "testName": "t7-Oak Tree (Quercus alba)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "t3 - Common Silver Birch (Betula verrucosa)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "T-Non-Hodgkin Lymphoma Panel (T-NHL)",
      "group": "Molecular Genetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 162"
    },
    {
      "testName": "T Cell Crossmatch (CDC)",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 72"
    },
    {
      "testName": "Urine Analysis",
      "group": "Microbiology",
      "type": "Panel",
      "ResultsTime": "24 hours/3 days",
      "Instructions": "",
      "Price": "\"₦ 16"
    },
    {
      "testName": "Urinary Steroid Hormone Profile",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "12 weeks",
      "Instructions": "",
      "Price": "\"₦ 203"
    },
    {
      "testName": "Urinary Albumin Quantitation 24hr Urine",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 13"
    },
    {
      "testName": "Urinary Albumin + Creatinine Urine (Microalbumin)",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 9"
    },
    {
      "testName": "Ureaplasma urealyticum DNA",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 105"
    },
    {
      "testName": "Urea Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Urea Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "Urea Dialysate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 5"
    },
    {
      "testName": "Urea Aspirate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Urea 24hr Urine",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Urate Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Urate Serum",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Urate Aspirate",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Urate 24hr Urine",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "Ultra-Sensitive PSA",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "10 days",
      "Instructions": "",
      "Price": "\"₦ 17"
    },
    {
      "testName": "Ultra-Sensitive CRP",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 21"
    },
    {
      "testName": "UDP-Galactose-Epimerase",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 287"
    },
    {
      "testName": "U1-RNP/SM Ab",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 69"
    },
    {
      "testName": "U/E",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "3 hours",
      "Instructions": "",
      "Price": "\"₦ 5"
    },
    {
      "testName": "Von Willebrands Antigen*",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 64"
    },
    {
      "testName": "Von Willebrands Activity (Ristocetin)*",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 41"
    },
    {
      "testName": "Von Willebrand Factor Multimere Analysis",
      "group": "Coagulation",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 228"
    },
    {
      "testName": "Von Willebrand Disease*",
      "group": "Coagulation",
      "type": "Panel",
      "ResultsTime": "24 hours/14 days",
      "Instructions": "",
      "Price": "\"₦ 141"
    },
    {
      "testName": "VMA/HVA 24hr Urine",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 82"
    },
    {
      "testName": "VITREOUS HUMOUR- Comp Drug of Abuse",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "21 days",
      "Instructions": "",
      "Price": "\"₦ 492"
    },
    {
      "testName": "Vitamin K (Menaquinon)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 122"
    },
    {
      "testName": "Vitamin H (Biotin)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 54"
    },
    {
      "testName": "Vitamin Essential",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "\"3 days",
      "Instructions": "14 days\"",
      "Price": ""
    },
    {
      "testName": "Vitamin E (Alpha-tocopherol)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 104"
    },
    {
      "testName": "Vitamin D3 1.25-OH",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 103"
    },
    {
      "testName": "Vitamin D2 25-OH (Ergocalciferol)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 44"
    },
    {
      "testName": "Vitamin D 25-OH",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 52"
    },
    {
      "testName": "Vitamin C (Ascorbic acid)*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 70"
    },
    {
      "testName": "Vitamin B6 (Pyridoxine)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 73"
    },
    {
      "testName": "Vitamin B5 (Pantothenic acid)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 77"
    },
    {
      "testName": "Vitamin B3 (Niacin)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 122"
    },
    {
      "testName": "Vitamin B2 (Riboflavin)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 43"
    },
    {
      "testName": "Vitamin B12",
      "group": "Endocrinology",
      "type": "Single Test",
      "ResultsTime": "3 days",
      "Instructions": "",
      "Price": "\"₦ 17"
    },
    {
      "testName": "Vitamin B1 (Thiamine)",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 75"
    },
    {
      "testName": "Vitamin A Plasma",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 104"
    },
    {
      "testName": "Vigabatrin",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 126"
    },
    {
      "testName": "VDRL (CMT) in CSF",
      "group": "Infection Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 18"
    },
    {
      "testName": "VDRL (CMT)",
      "group": "Infection Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 18"
    },
    {
      "testName": "Vasoactive Intestinal Polypeptide (VIP)*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 80"
    },
    {
      "testName": "Vascular Endothelial Growth Factor (VEGF)*",
      "group": "Molecular Oncology",
      "type": "Single Test",
      "ResultsTime": "20 days",
      "Instructions": "",
      "Price": "\"₦ 73"
    },
    {
      "testName": "Varicella Zoster Virus IgM Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 32"
    },
    {
      "testName": "Varicella Zoster Virus IgG Ab",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 28"
    },
    {
      "testName": "Varicella Zoster Virus DNA in CSF",
      "group": "Infection Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 66"
    },
    {
      "testName": "Varicella Zoster Virus DNA",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 54"
    },
    {
      "testName": "Vanillyl Mandelic Acid Spot Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 23"
    },
    {
      "testName": "Vanillyl Mandelic Acid in Urine (VMA)",
      "group": "Chemistry",
      "type": "Panel",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 64"
    },
    {
      "testName": "Vancomycin Pre",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Vancomycin Pos",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Vanadium Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 26"
    },
    {
      "testName": "WILLIAMS SYNDROME - 7q11.23 - FISH",
      "group": "Cytogenetics",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 260"
    },
    {
      "testName": "White Cell Cysteine (Cystinosis) Sequencing + MPLA",
      "group": "Genetics",
      "type": "Single Test",
      "ResultsTime": "42 days",
      "Instructions": "",
      "Price": "\"₦ 1"
    },
    {
      "testName": "White Cell Count & Differentials",
      "group": "Haematology",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 3"
    },
    {
      "testName": "West Nile Virus RNA",
      "group": "PCR",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 72"
    },
    {
      "testName": "West Nile Virus IgM Abs CSF",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 49"
    },
    {
      "testName": "West Nile Virus IgM Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 49"
    },
    {
      "testName": "West Nile Virus IgG Abs CSF",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 49"
    },
    {
      "testName": "West Nile Virus IgG Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 49"
    },
    {
      "testName": "Water Analysis Basic",
      "group": "Water Analysis",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 69"
    },
    {
      "testName": "Water Analysis",
      "group": "Water Analysis",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 362"
    },
    {
      "testName": "Warfarin",
      "group": "Drug Monitoring",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 37"
    },
    {
      "testName": "w6 - Mugwort (Artemisia vulgaris)",
      "group": "Allergy",
      "type": "Single Test",
      "ResultsTime": "",
      "Instructions": "",
      "Price": "\"₦ 24"
    },
    {
      "testName": "Xanthine 24hr Urine",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 36"
    },
    {
      "testName": "Yellow Fever IgM Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Yellow Fever IgG Abs",
      "group": "Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 35"
    },
    {
      "testName": "Zinc-Protoporphyrin in Erythrocytes (ZPP)",
      "group": "Clinical Chemistry",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 68"
    },
    {
      "testName": "Zinc Water",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 27"
    },
    {
      "testName": "Zinc Urine",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 46"
    },
    {
      "testName": "Zinc Transporter 8 Abs",
      "group": "Immunology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 41"
    },
    {
      "testName": "Zinc Serum",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 46"
    },
    {
      "testName": "Zinc in Erythrocytes",
      "group": "Environmental",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 46"
    },
    {
      "testName": "Zinc in Ejaculate*",
      "group": "Chemistry",
      "type": "Single Test",
      "ResultsTime": "10 days",
      "Instructions": "",
      "Price": "\"₦ 39"
    },
    {
      "testName": "Zika Virus-RNS in urine (PCR)",
      "group": "Infection Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 82"
    },
    {
      "testName": "Zika Virus-RNS (PCR)",
      "group": "Infection Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 82"
    },
    {
      "testName": "Zika Virus-IgM abs (EIA)",
      "group": "Infection Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 59"
    },
    {
      "testName": "Zika Virus-IgG abs (EIA)",
      "group": "Infection Serology",
      "type": "Single Test",
      "ResultsTime": "14 days",
      "Instructions": "",
      "Price": "\"₦ 49"
    }
  ]

