import RecordSearch from '../pages/Hospital/Dashboard/RecordSearch';
import CreateAcct from '../pages/Hospital/Register/CreateAcct';
import Subplan from '../pages/Hospital/Subscription/Subplan';
import AppProcessing from '../pages/Hospital/ApplicationProcessing/AppProcessing';
import SuperAdminRegistration from '../pages/Hospital/SuperAdmin/PersonalInformation';
import LockcodeSetup from '../pages/Hospital/SetupLockcode/LockcodeSetup';
import ScreenTimeout from '../pages/Hospital/ScreenTimeout/ScreenTimeout';
import LoginForm from '../pages/Hospital/Login/LoginForm';
import ForgotPassword from '../pages/Hospital/ForgotPassword/ForgotPassword';
import ResetPassword from '../pages/Hospital/ForgotPassword/ResetPassword';
import Dashboard from '../pages/Hospital/Dashboard/Dashboard';
import Appointment from '../pages/Hospital/Appointment/Appointment';
import PatientAppointment from '../pages/Hospital/Appointment/PatientAppointment';
import Patient from '../pages/Hospital/Patient/Patient';
import PatientFile from '../pages/Hospital/Patient/PatientFile';
import NewPatient from '../pages/Hospital/Patient/NewPatient';
import PatientAppointments from '../pages/Hospital/Patient/PatientAppointments';
import Billing from '../pages/Hospital/Billing/Billing';
import SendMoney from '../pages/Hospital/Billing/SendMoney';
import WithdrawMoney from '../pages/Hospital/Billing/WithdrawMoney';
import BookApointment from '../pages/Hospital/Appointment/BookApointment';
import Admin from '../pages/Hospital/UserMgmt/Admin';
import AddAdmin from '../pages/Hospital/UserMgmt/AddAdmin';
import BillHistory from '../pages/Hospital/Billing/BillHistory';
import CreateBill from '../pages/Hospital/Billing/CreateBill';
import AddMoney from '../pages/Hospital/Billing/AddMoney';
import AdminProfiles from '../pages/Hospital/UserMgmt/AdminProfiles';
import BillInvoices from '../pages/Hospital/Billing/BillInvoices';
import Doctor from '../pages/Hospital/UserMgmt/userMgmtDoctor/Doctor';
import AddDoctor from '../pages/Hospital/UserMgmt/userMgmtDoctor/AddDoctor';
import DoctorProfiles from '../pages/Hospital/UserMgmt/userMgmtDoctor/DoctorProfiles';
import HospitalPage from '../pages/Hospital/Settings/HospitalPage';
import MyProfile from '../pages/Hospital/Settings/MyProfile';
import Nurse from '../pages/Hospital/UserMgmt/userMgmtNurses/Nurse';
import AddNurse from '../pages/Hospital/UserMgmt/userMgmtNurses/AddNurse';
import NurseProfiles from '../pages/Hospital/UserMgmt/userMgmtNurses/NurseProfiles';
import EditProfile from '../pages/Hospital/Settings/EditProfile';
import EditPersonalProfile from '../pages/Hospital/Settings/EditPersonalProfile';
import OTP from '../pages/Hospital/SuperAdmin/OTP';
import VideoCall from '../components/Appointment/Telemedicine/VideoCall';
import TransactionHistories from '../pages/Hospital/Billing/TransactionHistories';
import NewPatientSubscription from '../pages/Hospital/Patient/NewPatientSubscription';
import Messaging from '../pages/Hospital/Messaging/Messaging';
import VoucherPayment from '../pages/Hospital/Patient/VoucherPayment';
import NonAdminSettings from '../pages/Hospital/Settings/NonAdminSettings';
import HospitalWallet from '../pages/Hospital/HospitalWallet/HospitalWallet';
import MyWallet from '../pages/Hospital/MyWallet/MyWallet';
import VoucherPage from '../pages/Hospital/Voucher/VoucherPage';
import VoucherHistory from '../pages/Hospital/Voucher/VoucherHistory';
import MyTransactionHistories from '../pages/Hospital/MyWallet/MyTransactionHistories';
import HospitalVoucher from '../pages/Hospital/Voucher/HospitalVoucher/HospitalVoucher';
import FleetMgmt from '../pages/Hospital/Ambulance/Fleet/FleetMgmt';
import RequestMgmt from '../pages/Hospital/Ambulance/Request/RequestMgmt';
import AmblanceHistories from '../pages/Hospital/Ambulance/AmblanceHistories';
import Profile from '../pages/Hospital/Ambulance/Profile';
import EditStaf from '../pages/Hospital/Ambulance/Fleet/EditStaff';
import AddAmbulance from '../pages/Hospital/Ambulance/Fleet/AddAmbulance';
import RequestHistory from '../pages/Hospital/Ambulance/Request/RequestHistory';
import ReqProfile from '../pages/Hospital/Ambulance/Request/ReqProfile';
import ADS from '../pages/Hospital/TmrADS/ADS';
import CreateAds from '../pages/Hospital/TmrADS/CreateAds';
import AdsProfile from '../pages/Hospital/TmrADS/AdsProfile';

const HospitalRoute = [
    {
        path: 'auth/register',
        element: <CreateAcct />,
    },
    {
        path: 'auth/super-admin',
        element: <SuperAdminRegistration />,
    },
    {
        path: 'auth/setup-lockcode',
        element: <LockcodeSetup />,
    },
    {
        path: 'auth/subscription-plan',
        element: <Subplan />,
    },
    {
        path: 'auth/application-processing',
        element: <AppProcessing />,
    },
    {
        path: 'screen-timeout',
        element: <ScreenTimeout />,
    },
    {
        path: 'auth/login',
        element: <LoginForm />,
    },
    {
        path: 'auth/forgot-password',
        element: <ForgotPassword />,
    },
    {
        path: 'auth/reset-password',
        element: <ResetPassword />,
    },
    {
        path: 'dashboard',
        element: <Dashboard />,
    },
    {
        path: 'user/doctor',
        element: <Doctor />,
    },
    {
        path: 'user/add-doctor',
        element: <AddDoctor />,
    },
    {
        path: 'user/doctor/:id',
        element: <DoctorProfiles />,
    },
    {
        path: 'user/admin',
        element: <Admin />,
    },

    {
        path: 'user/add-admin',
        element: <AddAdmin />,
    },
    {
        path: 'user/:id',
        element: <AdminProfiles />,
    },
    {
        path: 'user/nurse',
        element: <Nurse />,
    },
    {
        path: 'user/add-nurse',
        element: <AddNurse />,
    },
    {
        path: 'user/nurse/:id',
        element: <NurseProfiles />,
    },
    {
        path: 'dashboard/record-search',
        element: <RecordSearch />,
    },
    {
        path: 'appointment',
        element: <Appointment />,
    },
    {
        path: 'appointment/:id',
        element: <PatientAppointment />,
    },
    {
        path: 'appointment/book-appointment',
        element: <BookApointment />,
    },
    {
        path: 'patient',
        element: <Patient />,
    },
    {
        path: 'patient/new-patient',
        element: <NewPatient />,
    },
    {
        path: 'patient/new-patient/subscription',
        element: <NewPatientSubscription />,
    },
    {
        path: 'patient/new-patient/subscription/voucher-payment',
        element: <VoucherPayment />,
    },
    {
        path: 'patient/:id',
        element: <PatientFile />,
    },
    {
        path: 'patient/:id/patient-appointments',
        element: <PatientAppointments />,
    },
    {
        path: 'billing',
        element: <Billing />,
    },
    {
        path: 'billing/bill-history',
        element: <BillHistory />,
    },

    {
        path: 'billing/create-bill',
        element: <CreateBill />,
    },
    {
        path: 'billing/bill-invoice/:id',
        element: <BillInvoices />,
    },
    {
        path: 'billing/add-money',
        element: <AddMoney />,
    },
    {
        path: 'settings/hospital',
        element: <HospitalPage />,
    },
    {
        path: 'settings/profile',
        element: <MyProfile />,
    },
    {
        path: 'settings/hospital/edit',
        element: <EditProfile />,
    },
    {
        path: 'settings/profile/edit',
        element: <EditPersonalProfile />,
    },
    {
        path: 'settings/my-settings',
        element: <NonAdminSettings />,
    },
    {
        path: 'auth/otp',
        element: <OTP />,
    },
    {
        path: '/hospital/appointment/call/:id?',
        element: <VideoCall />,
    },
    {
        path: 'messaging',
        element: <Messaging />,
    },
    {
        path: 'hospital-wallet',
        element: <HospitalWallet />,
    },
    {
        path: 'hospital-wallet/wallet-transactions',
        element: <TransactionHistories />,
    },
    {
        path: 'hospital-wallet/send-money',
        element: <SendMoney />,
    },
    {
        path: 'hospital-wallet/withdraw-money',
        element: <WithdrawMoney />,
    },
    {
        path: 'my-wallet',
        element: <MyWallet />,
    },
    {
        path: 'my-wallet/my-wallet-transactions',
        element: <MyTransactionHistories />,
    },
    {
        path: 'voucher',
        element: <VoucherPage />,
    },
    {
        path: 'voucher/hospital-voucher',
        element: <HospitalVoucher />,
    },
    {
        path: 'voucher/voucher-history/:walletType',
        element: <VoucherHistory />,
    },
    {
        path: 'ambulance/fleet-Mgmt',
        element: <FleetMgmt/>,
    },
    {
        path: 'ambulance/request-Mgmt',
        element: <RequestMgmt/>,
    },
    {
        path: 'ambulance/fleet/ambulance-history',
        element: <AmblanceHistories/>,
    },
    {
        path: 'ambulance/fleet/ambulance-profile/:id',
        element: <Profile />,
      },
      {
        path: 'ambulance/fleet/ambulance-profile/edit',
        element: <EditStaf/>,
      },
      {
        path: 'ambulance/fleet/add-ambulance',
        element: <AddAmbulance/>,
      },
      {
        path: 'ambulance/request/history',
        element: <RequestHistory/>,
      },
      {
        path: 'ambulance/request/ambulance-profile/:id',
        element: <ReqProfile/>
      },
      {
        path: 'tmr-ads',
        element: <ADS/>
      },
      {
        path: 'tmr-ads/create',
        element: <CreateAds />
      },
      
      {
        path: 'ads/:id',
        element: <AdsProfile/>
      },
    
];

export default HospitalRoute;
