import {
    useJoin,
    useLocalCameraTrack,
    useLocalMicrophoneTrack,
    usePublish,
    useRTCClient,
    useRemoteUsers,
    RemoteUser,
    LocalVideoTrack,
    useClientEvent,
    useRemoteAudioTracks
} from "agora-rtc-react";
import { useEffect, useState, memo } from "react";
import './telemedicine.css';
import { CiMicrophoneOff, CiMicrophoneOn } from "react-icons/ci";
import { ProfileCircle, Video, VideoSlash } from "iconsax-react";
import { MdOutlineCallEnd } from "react-icons/md";
import { TmrTelemedicine } from "../../../Images/Svgs";
import Modal from "../../../reusable/Modal";
import { io } from "socket.io-client";

type VideoContainerProps = {
    children: React.ReactNode;
    variant?: string;
    bg?: string;
};

export const AgoraManager = (props: { 
    channelName: string; 
    AppID: string; 
    token: string; 
    uid: number;
    username: string;
}) => {
    const { AppID, channelName, token, uid, username } = props;
    const socket = io(`${process.env.REACT_APP_SOCKET_URL}agoraName`, {
        autoConnect: false,
        auth: {
            token: channelName, // this is the appointment ID 
        },
        query: {
            "name": username,
            "uid": uid,
        },
        path: '/provider-socket'
    });

    const agoraEngine = useRTCClient();
    const { isLoading: isLoadingCam, localCameraTrack } = useLocalCameraTrack();
    const { isLoading: isLoadingMic, localMicrophoneTrack } = useLocalMicrophoneTrack();
    const remoteUsers = useRemoteUsers();
    const [isMicrophoneMuted, setIsMicrophoneMuted] = useState<boolean>(false);
    const [isCameraMuted, setIsCameraMuted] = useState<boolean>(false);
    const [noOfPeopleOnCall, setNoOfPeopleOnCall] = useState<number>(0);
    const [currentTime, setCurrentTime] = useState<string>('');
    const [endSession, setEndSession] = useState<boolean>(false);
    const [showEndSessionModal, setShowEndSessionModal] = useState<boolean>(false);
    const [agoraUsernames, setAgoraUsernames] = useState<any[]>([]);

    useEffect(() => {
        setNoOfPeopleOnCall(remoteUsers.length);
    }, [remoteUsers]);

    usePublish([localMicrophoneTrack, localCameraTrack]);

    useJoin({
        appid: AppID,
        channel: channelName,
        token: token || null,
        uid: uid || null,
    });

    useClientEvent(agoraEngine, "user-joined", (user) => {
        // console.log("The user" , user.uid , " has joined the channel");
    });

    useClientEvent(agoraEngine, "user-left", (user) => {
        // console.log("The user" , user.uid , " has left the channel");
    });

    useClientEvent(agoraEngine, "user-published", (user, mediaType) => {
        // console.log("The user" , user.uid , " has published media in the channel");
    });

    useEffect(() => {
        const disconnectFromSocket = () => {
            socket.disconnect();
        };

        const existingUsersEvent = (data) => {
            setAgoraUsernames(data);
        }

        const newUserEvent = (data) => {
            setAgoraUsernames((prevUsernames) => [...prevUsernames, data]);
        }

        socket.on('disconnect', disconnectFromSocket);
        socket.on('existingUsers', existingUsersEvent);
        socket.on('newUser', newUserEvent);

        socket.connect();

        return () => {
            socket.off('disconnect', disconnectFromSocket);
            socket.off('existingUsers', existingUsersEvent);
            socket.off('newUser', newUserEvent);
            socket.disconnect();
        }
    }, []);

    // useEffect(() => {
    //     return () => {
    //         localCameraTrack?.close();
    //         localMicrophoneTrack?.close();
    //         setIsMicrophoneMuted(false);
    //         setIsCameraMuted(false);
    //     };
    // }, []);

    useEffect(() => {
        const getAndSetTime = setInterval(() => {
            const currentTime = new Intl.DateTimeFormat('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            }).format(new Date());
            setCurrentTime(currentTime);
        }, 1000);
        return () => clearInterval(getAndSetTime);
    }, []);

    const { audioTracks } = useRemoteAudioTracks(remoteUsers);
    // play the remote user audio tracks
    audioTracks.forEach((track) => track.play());

    if (isLoadingMic || isLoadingCam) return <div className="flex justify-center">Loading devices...</div>;
    if (!localCameraTrack || !localMicrophoneTrack) return <div className="flex justify-start flex-col ">Preparing session please wait...</div>;
    if(endSession) return <div className="flex justify-start flex-col ">Session will end in the next 3 seconds...</div>;

    const toggleEndSession = () => {
        setShowEndSessionModal(!showEndSessionModal);
    }

    const toggleWebcam = () => { 
        localCameraTrack.setMuted(!isCameraMuted);
        // localCameraTrack.stop()
        // localCameraTrack.setEnabled(!isCameraMuted);
        setIsCameraMuted(!isCameraMuted);
    }

    const toggleMicrophone = () => {
        localMicrophoneTrack.setMuted(!isMicrophoneMuted);
        setIsMicrophoneMuted(!isMicrophoneMuted);
    }

    const handleEndCall = () => {
        setEndSession(true);
        setTimeout(() => {
            window.close();
        }, 3000);
    }

    const VideoContainer = ({ children, variant, bg }: VideoContainerProps) => (
        <div className={` ${variant ? 'relative w-[400px] h-[250px]' : 'relative w-full max-w-[800px] h-4/5'} ${bg ? bg : ''} `} id="video-container">
            {children}
        </div>
    );

    const MicContainer = ({ children }) => (
        <span style={{ 
            position: 'absolute',
            top: '10px',
            right: '10px',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            borderRadius: '50%',
            padding: '8px',
            cursor: 'pointer' 
        }}>
            {children}
        </span>
    );

    const UsernameContainer = ({ children }) => {
        const username = agoraUsernames.find((data) => data.uid === String(children).trim());
        return (
            <span style={{
                position: 'absolute',
                bottom: '10px',
                left: '10px',
                fontSize: '16px',
                color: '#F5F5F5',
                backgroundColor: '#362F2E',
                textAlign: 'center',
                fontWeight: 400,
                borderRadius: '20px',
            }}>
                <p className="py-2 px-3">{username?.name || children}</p>
            </span>
        )
    };

    const Controls = () => {
        return (
            <>
                <div className="flex flex-row self-center bg-[#F5F5F5] p-4 rounded-[20px] sm:w-[393.9px] gap-2 w-full items-center justify-around">
                    <button className={`${isMicrophoneMuted ? 'bg-[#E4D7D5]' : 'bg-[#fff]'} rounded-[100px] w-[40px] h-[40px] flex items-center justify-center`} onClick={toggleMicrophone}>
                        {isMicrophoneMuted ? <CiMicrophoneOff size={24} color="#C00010"/> : <CiMicrophoneOn size={24} />}
                    </button>
                    <button onClick={toggleEndSession} className="p-2 flex flex-col sm:flex-row items-center gap-2 rounded-lg bg-Tmred text-white w-[119px]">
                        <MdOutlineCallEnd color="#fff" size={24} />
                        <span>End Call</span>
                    </button>
                    <button className={`${isCameraMuted ? 'bg-[#E4D7D5]' : 'bg-[#fff]'} rounded-[100px] w-[40px] h-[40px] flex items-center justify-center`} onClick={toggleWebcam}>
                        {isCameraMuted ? <VideoSlash size={24} color="#C00010" /> : <Video size={24} color="#534341" />}
                    </button>
                </div>
                <Modal
                    isVisible={showEndSessionModal}
                    onClose={toggleEndSession}
                    onSave={handleEndCall}
                    buttonEnabled
                    setButton
                    saveText="Yes, End Call"
                    closeText="No"
                    showCloseButtonBorder={false}
                >
                    <div className='bg-white'>
                        <div className='bg-white'>
                            <h1 className='text-[24px] font-semibold leading-[32px] mb-[16px]'>End Session</h1>
                            <p className='text-[14px] font-medium text-[#534341]'>Are you sure you want to end the telemedicine session?</p>
                            <p className='text-[14px] font-medium text-[#534341]'>Don't worry, you can still start the call again</p>
                            
                        </div>
                    </div>   
                </Modal>
            </>
        );
    };

    const ControlContainer = () => (
        <div className="flex sm:flex-row flex-col justify-evenly gap-2 sm:items-center w-full bg-[#ffffff] p-4 mt-8 rounded-[20px]">
            <p className="text-[14px] font-medium text-center sm:text-left">{currentTime}</p>
            <Controls />
            <div>
                <TmrTelemedicine /> 
            </div>
        </div>
    )

    if(noOfPeopleOnCall === 0) {
        return (
            <div className="flex flex-wrap justify-center items-center box-border h-screen">
                <div className="flex items-center justify-center">
                    <h3 className="bg-[#5D3F3C] rounded-[200px] my-3 py-4 px-3 text-[18px] text-[#fff] text-center sm:w-fit">Waiting for patient to join call...</h3> 
                </div>
                <VideoContainer>
                    { isCameraMuted ? 
                        <div className="w-full h-full relative overflow-hidden">
                            <div className="w-full h-full bg-lightTmrRed flex justify-center items-center">
                                <ProfileCircle size={100} color="#222" />
                            </div>
                        </div>
                        :
                        <LocalVideoTrack track={localCameraTrack} play={true} style={{ width: '100%', height: '100%' }} />
                    }
                    {localMicrophoneTrack && (
                        <MicContainer>
                            {isMicrophoneMuted ? <CiMicrophoneOff size={24} color="#C00010"/> : <CiMicrophoneOn size={24}  />}
                        </MicContainer>
                    )}
                    <UsernameContainer>
                        You
                    </UsernameContainer>
                </VideoContainer>
                <ControlContainer />
            </div>
        )
    } 

    return (
        <div className="flex flex-col">
            <div className="grid grid-cols-2 gap-4 justify-center items-center box-border h-screen">
                <VideoContainer bg="bg-lightTmrRed">
                    { isCameraMuted ?  
                        <div className="w-full h-full flex justify-center items-center">
                            <ProfileCircle size={100} color="#222" />
                        </div>
                        :
                        <LocalVideoTrack track={localCameraTrack} play={true} style={{ width: '100%', height: '100%' }} />
                    }
                    {localMicrophoneTrack && (
                        <MicContainer>
                            {isMicrophoneMuted ? <CiMicrophoneOff size={24} color="#C00010"/> : <CiMicrophoneOn size={24}  />}
                        </MicContainer>
                    )}
                    <UsernameContainer>
                        You
                    </UsernameContainer>
                </VideoContainer>
                {remoteUsers.map((remoteUser) => (
                    <> 
                        { remoteUser.hasVideo ? 
                        <VideoContainer key={remoteUser.uid}>
                            <RemoteUser user={remoteUser} playVideo={true} playAudio={true} style={{ width: '100%', height: '100%' }} />
                            <MicContainer>
                                {remoteUser.hasAudio ? <CiMicrophoneOn size={24}  /> : <CiMicrophoneOff size={24} color="#C00010"/>}
                            </MicContainer>
                            <UsernameContainer>
                                {remoteUser.uid}
                            </UsernameContainer>
                        </VideoContainer>
                        : 
                        <VideoContainer key={remoteUser.uid} bg="bg-lightTmrRed">
                            <div className="w-full h-full flex justify-center items-center">
                                <ProfileCircle size={100} color="#222" />
                            </div>
                            <MicContainer>
                                {remoteUser.hasAudio ? <CiMicrophoneOn size={24}  /> : <CiMicrophoneOff size={24} color="#C00010"/>}
                            </MicContainer>
                            <UsernameContainer>
                                {remoteUser.uid}
                            </UsernameContainer>
                        </VideoContainer>
                        }
                    </>
                ))}
            </div>
            <ControlContainer />
        </div>
    );
};

export default memo(AgoraManager);
