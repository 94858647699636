import { MdChevronLeft } from "react-icons/md";
import avatar from '../../../Images/profile.png'
import { Link, useNavigate, useParams } from "react-router-dom";
import RequestHistory from "./RequestHistory";
import { Fragment, useEffect, useState } from "react";
import Modal from "../../../reusable/LoadingModal";
import { Controller, useForm } from "react-hook-form";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { hospitalRoutes } from "../../../utils/routeNames";
import hospitalService from "../../../services/hospitalService";
import { useMutation, useQuery } from "react-query";
import { formatDateDmy } from "../../../utils/helpers";
import { FaCircleCheck } from "react-icons/fa6";
import { toast } from "react-toastify";
import Lottie from 'lottie-react';
import loadingState from '../../../Images/loading.json';


const RequestProfile = () => {
  const { id } = useParams();
    const navigate = useNavigate()
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [ buttonEnabled, setButton] = useState(false)
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [AmbulanceDetails, setAmbulanceDetails] = useState<any>({});
  
  const [isEditing, setIsEditing] = useState(false);
  const {
  
    getValues,
    control,
    
} = useForm<any>();

const { data, isLoading, refetch, isError } = useQuery(
  ['singleAmbulance', id],
  () =>
      id
          ? hospitalService.getSingleAmbulanceProfile(id)
          : Promise.reject('NO ID'),
  {
      enabled: !!id,
  }
);

console.log(data)
useEffect(() => {
  if (data?.ambulanceProfile) {
      setAmbulanceDetails(data?.ambulanceProfile);
      
   
      console.log(AmbulanceDetails)
  }
}, [AmbulanceDetails, data]);
const deleteAmbulanceMutation = useMutation(
  (id: string) => hospitalService.deleteSingleAmbulance(id),
  {
      onSuccess: (data) => {
          
          console.log('Delete success:', data);
          // Refetch the list of services
          refetch()
          let payload: any = data;
         
          const message = payload.data.message;
          
          toast.success(message);
          setTimeout(() => {
              toast.dismiss();
              setIsDeleting(false);
              navigate(hospitalRoutes.fleetMgmt)
          }, 2000);
      },
      onError: (error: any) => {
          console.error('Delete ambulance error:', error);
          console.log(error);
          setTimeout(() => {
             
              toast.error(error.response.data.error.message);
              setIsDeleting(false);
              
          }, 1000);
      },
  }
);

const handleDeleteConfirm = async () => {
      setIsDeleting(true)
      // setDeleteModalVisible(false);
      deleteAmbulanceMutation.mutate(AmbulanceDetails?._id)
    };
  
    const handleDeleteCancel = () => {
      setDeleteModalVisible(false);
    };
    const editAmbulanceStatus = useMutation(
      (requestData: any) => hospitalService.editAmbulanceStatus(requestData.id, requestData.updatedData),
      {
          onSuccess: (responseData:any) => {
              
              console.log('update success', responseData.data);
              let payload: any = responseData.data;
              const data = payload
              
              toast.success(data.message);
              refetch();
              setTimeout(() => {
                  toast.dismiss(); 
                  setIsEditing(false);
                  setEditModalVisible(false);
              }, 2000);
          },
          onError: (error: any) => {
              console.error('Update ambulance status error:', error);
            
              setTimeout(() => {
                  toast.error(error.response.data.error.message);
                  setIsEditing(false);
              }, 1000);
          },
      }
  );
    const handleEditConfirm = async () => {
      setIsEditing(true)
      setButton(true)
      if (AmbulanceDetails._id) {
        const updatedService = {
            
              status: getValues("ambulanceStatus"),
        };

    editAmbulanceStatus.mutate({
            id: AmbulanceDetails?._id,
            updatedData: updatedService,
        });
    } else {
       
        console.error("No ambulance selected for update");
    }
      
    };
  
    const handleEditCancel = () => {
      setEditModalVisible(false);
    };
    if(isLoading){
      return(
    
      
      <div className="flex justify-center items-center py-32">
      {' '}
      <Lottie
          animationData={loadingState}
          loop={true}
          className="lottie"
      />
    </div>
      )
    }
    if(isError){
      return (
        <div className="flex justify-center items-center">
            Error loading details, please refresh your page
        </div>
    );
    }
  return (
    <Fragment>
    <div className="md:px-8 px-1 mt-6 bg-[#F6F6F7]">
        <div className="w-[95%] mx-auto ">
            <div className="flex items-center">
                <span className="cursor-pointer ">
                    <MdChevronLeft 
                    size={30}
                    onClick={() => navigate(-1)} />
                </span>
                <h1 className="font-semibold text-2xl pl-4">Request Profile</h1>
            </div>
            <div className="flex flex-col lg:flex-row lg:space-x-6 my-8 ">
          <div className="max-w-[720px] w-full">
            <div className=" bg-white  p-2 sm:p-4 rounded-2xl mb-10 lg:mb-0">
              
                
              <div className="flex items-center justify-between bg-[#FFF8F7] p-1 sm:p-4 
                    sm:space-x-3 rounded-xl mb-6">
                <div className="flex items-center">
                    <img
                    src={
                     AmbulanceDetails?.hospitalUser?.image || avatar}
                    alt="adminImage"
                    className="rounded-full w-20 h-20"
                    />
                    <div className="pl-4">
                        <h2 className="sm:text-2xl text-sm py-1 font-medium text-[#201A19]">
                       
                        {`${ AmbulanceDetails?.hospitalUser?.title &&
                                        AmbulanceDetails?.hospitalUser?.title !== 'undefined'
                                            ? AmbulanceDetails?.hospitalUser?.title 
                                            : ''} ${AmbulanceDetails?.hospitalUser?.firstName} ${
                                        AmbulanceDetails?.hospitalUser?.lastName
                                    }`}
                        </h2>
                        <p className="sm:text-sm text-xs font-normal py-1 text-[#534341] ">
                        {AmbulanceDetails?.hospitalUser?.email || ''}
                        </p>
                        <p className="sm:text-sm text-xs capitalize py-1 font-normal text-[#201A19]">
                           staff
                        </p>
                    </div>
                </div>
                <div>
                    <button onClick={()=>navigate(`/hospital/user/${AmbulanceDetails?.hospitalUser?._id}`)} className='underline text-Tmred font-medium'>
                        View Profile
                    </button>
                </div>
              </div>
              <div className="md:w-full my-3">
        <table className="w-full py-2 px-4 border border-[#D8C2BF] rounded-2xl">
            <thead className="rounded-2xl p-2">
                <tr>
                    <th className="py-2 px-4 text-center border-r-[2px]">
                        <p className="text-[12px] text-left text-[#534341]">Total Request</p>
                    </th>
                    <th className="text-center border-r-[2px]">
                        <p className="text-[12px] text-[#534341]">Completed Request</p>
                    </th>
                    <th className="text-center border-r-[2px]">
                        <p className="text-[12px] text-[#534341]">Cancelled Request</p>
                    </th>
                    <th className="text-center ">
                        <p className="text-[12px] text-[#534341]">Amount Generated</p>
                    </th>
                    
                </tr>
            </thead>
            <tbody>
               
                        <tr>
                            <td className="py-2 px-4 border-r-[2px] text-center">
                                <p className="text-[16px]">{data?.totalRequest || 0}</p>
                            </td>
                            <td className="py-2 px-4 text-center border-r-[2px]">
                                <p className="text-[16px]">{data?.completedRequest || 0}</p>
                            </td>
                            <td className="py-2 px-4 text-center border-r-[2px]">
                                <p className="text-[16px]">{data?.cancelRequest || 0}  </p>
                            </td>
                            <td className="py-2 px-4 text-center">
                                <p className="text-[16px]">{data?.amountGenerated || 0}                                </p>
                            </td>
                            
                        </tr>
                   
            </tbody>
        </table>

        
    </div>
              <h2 className="sm:text-2xl text-sm py-3 sm:mb-2 font-medium text-[#201A19] px-[20px]">Ambulance information</h2>
              <div className="flex justify-between items-start md:flex-col lg:flex-row flex-col sm:flex-row px-[20px]">
                <div className="w-full">
                  <div>
                    <p className="text-xs sm:text-sm font-normal text-[#857371] pb-2">
                    Ambulance ID (License plate)
                    </p>
                    <p className="text-xs sm:text-[16px]  font-medium text-[#201A19] ">
                    {AmbulanceDetails?.licenseNumber || ''}
                    </p>
                  </div>
                  <div className="my-5">
                    <p className="text-xs sm:text-sm font-normal text-[#857371] pb-2 ">
                     Amount
                    </p>
                    <p className="text-base sm:text-[16px] font-medium text-[#201A19] ">
                    {AmbulanceDetails?.amount || ''}
                    </p>
                  </div>
                  <div className="mb-5">
                    <p className="text-xs sm:text-sm font-normal text-[#857371] pb-2">
                        Phone Number
                    </p>
                    <p className="text-xs sm:text-[16px]  font-medium text-[#201A19]">
                    {AmbulanceDetails?.phoneNumber || ''}
                    </p>
                  </div>
                  
                  <div className="flex gap-2 flex-col">
                        <p className=" text-[14px] font-medium text-[#857371]">
                            Status:
                        </p>
                        <button
                            type="button"
                            className=" text-gray-900 gap-1 bg-[#F5F5F5] w-fit font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mb-2"
                        >
                          {AmbulanceDetails.status === 'Available' ? (
                                <FaCircleCheck style={{ color: '#009000' }} />
                            ) :

                             
                                <svg
                                    className="w-4 h-4 me-2 -ms-1 text-[#626890]"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
                                        fill="#534341"
                                    />
                                    <path
                                        d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z"
                                        fill="#534341"
                                    />
                                </svg>}
                            
                               { AmbulanceDetails?.status || '' }
                        </button>
                    </div>
                </div>

                <div className="w-full sm:ml-[70px]">
                  <div className="">
                    <p className="text-xs sm:text-sm font-normal text-[#857371] pb-2">
                        Type of ambulance
                    </p>
                    <p className="text-xs sm:text-[16px]  font-medium text-[#201A19]">
                    {AmbulanceDetails?.ambulanceType || ''}
                    </p>
                  </div>
                  <div className="my-5">
                    <p className="text-xs sm:text-sm font-medium text-[#857371] pb-2">
                      Date registered
                    </p>
                    <p className="text-[16px]  font-medium text-[#201A19]">
                    {formatDateDmy(AmbulanceDetails?.createdAt || '')}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex justify-end space-x-6 py-3 border-t mt-10">
                <div>
                  <button
                    type="button"
                    onClick={()=>setDeleteModalVisible(true)}
                    className="py-2 px-3 text-[#C00010] text-[16px] font-normal">
                   Delete Ambulance 
                  </button>
                </div>
                <button
                  type="button"
                 onClick={()=>navigate(hospitalRoutes.ambulanceStaffEdit,{ state: { AmbulanceDetails }})}
                  className=" py-2 px-3 text-[16px] font-normal ">
                  Edit Ambulance 
                </button>
                <button
                  type="button"
                  onClick={()=>setEditModalVisible(true)}
                  className="border py-2 px-3 text-[16px] text-[#C00010] border-[#857371] font-normal rounded-lg">
                 Change status
                </button>
              </div>
            </div>
          </div>
            </div>
            <RequestHistory/>
        </div>
    </div>
    <Modal
        isVisible={isDeleteModalVisible}
        onClose={handleDeleteCancel}
        buttonEnabled={!isDeleting}
        isLoading={isDeleting}
        loadingText="Deleting..."
        setButton={() => {}}
        onSave={handleDeleteConfirm}
        saveText="Delete"
        closeText={'Cancel'}
        showCloseButtonBorder={true}
      >
        <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2 ">
          <h2 className="font-semibold text-2xl mb-5">Delete Ambulance</h2>
          <p className="text-[14px] font-normal text-[#534341] max-w-[382px]">
          Are you sure you want to delete this ambulance from the fleet? This action cannot be undone, and the ambulance will be permanently removed from your list. Ensure that this ambulance is not assigned to any ongoing requests before proceeding.
          </p>
        </div>
      </Modal>
      <Modal
        isVisible={isEditModalVisible}
        onClose={handleEditCancel}
        buttonEnabled={buttonEnabled}
        isLoading={isEditing}
        loadingText="Updating..."
        setButton={setButton}
        onSave={handleEditConfirm}
        saveText="Proceed"
        closeText={'Cancel'}
        showCloseButtonBorder={true}
      >
        <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2 ">
          <h2 className="font-semibold text-2xl mb-3">Ambulance Status</h2>
          <p className="text-[#534341] font-medium text-[14px]">Kindly select the current status of the ambulance.</p>
          <div className='flex flex-col items-left justify-between'>
    <div className='flex justify-between items-center'>
                                <Controller
                                    name="ambulanceStatus"
                                    control={control}
                                    defaultValue=""
                                rules={{ required: 'Ambulance Status is required' }}
                                    render={({ field }) => (
                                        <FormControl component="fieldset" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '16px' }}>
                                            <RadioGroup {...field} onChange={(e) => {
                    field.onChange(e);
                    setButton(true);    
                }}>
                                                <FormControlLabel value="Available" control={<Radio />} label="Available" />
                                                <FormControlLabel value="Not Available" control={<Radio />} label="Not Available" />
                                                <FormControlLabel value="On Duty" control={<Radio />} label="On Duty" />
                                            </RadioGroup>
                                        </FormControl>
                                    )}
                                />
                                </div> 
            
</div>
          
        </div>
      </Modal>
    </Fragment>
  )
}

export default RequestProfile