import { useNavigate, Link, createSearchParams } from 'react-router-dom';
import hospitalService from '../../../services/hospitalService';
import Lottie from 'lottie-react';
import loadingState from '../../../Images/loading.json';
import { useQuery } from 'react-query';
import { hospitalRoutes } from '../../../utils/routeNames';
import { usePatStore } from '../../../store/hospital/auth';

const PatientProfileAppointmentCards = ({
    id,
    patTmrNumber,
    lastName,
    firstName,
}: any) => {
    const navigate = useNavigate();
    const setTmrNumber = usePatStore((state) => state.setTmrNumber);
    const setBookClicked = usePatStore((state) => state.setBookClicked);
    const params = {
        patientTmrNumber: patTmrNumber,
        lastName: lastName,
        firstName: firstName,
    };
    // console.log(params);
    const handleBookButtonClick = () => {
        setTmrNumber(patTmrNumber);
        setBookClicked(true);
        navigate(hospitalRoutes.bookAppointment, { state: params });
    };

    const {
        data: patientAppointmentCounts,
        isError: patientAppointmentCountsError,
        isLoading: patientAppointmentCountsLoading,
    } = useQuery(
        ['patientAppointmentCount', id],
        () =>
            id
                ? hospitalService.getPatientAppointmentCount(id)
                : Promise.reject('NO patientAppointmentCount'),
        {
            enabled: !!id,
        }
    );

    if (patientAppointmentCountsLoading) {
        return (
            <div className="flex justify-center items-center py-32">
                <Lottie
                    animationData={loadingState}
                    loop={true}
                    className="lottie"
                />
            </div>
        );
    }

    if (patientAppointmentCountsError) {
        return (
            <div>
                Error loading patientAppointment, please refresh your page
            </div>
        );
    }

    // const handleSendMessage = () => {
    //     if (message.trim() !== '') {
    //         setMessages([...messages, `You: ${message}`]);
    //         setMessage('');
    //     }
    // };

    return (
        <div className="w-full lg:max-w-[359px] basis-2/6 sm:space-x-5 lg:space-x-0 flex lg:flex-col sm:flex-row flex-col  justify-between mt-8 lg:mt-0">
            <div className="bg-white rounded-2xl pb-4 w-full">
                <h2 className="font-semibold text-2xl border-b p-4">
                    Appointments
                </h2>
                <div className="text-center bg-lightGray400 rounded-2xl text-sm font-normal text-lightBrown mt-6 py-1 mx-4">
                    <h2 className="montFont">Total</h2>
                    <p className="text-[20px] font-medium mt-2">
                        {patientAppointmentCounts?.appointmentCounts?.total}
                    </p>
                </div>
                <div className="flex justify-evenly px-5 text-center mt-4">
                    <div>
                        <h2 className="text-xs font-medium montFont text-lightBrown">
                            Pending
                        </h2>
                        <p className="text-base font-bold text-lightBrown mt-2">
                            {
                                patientAppointmentCounts?.appointmentCounts
                                    ?.pending
                            }
                        </p>
                    </div>
                    <div className="border-l mx-3"></div>
                    <div>
                        <h2 className="text-xs font-medium montFont text-lightBrown">
                            Cancelled
                        </h2>
                        <p className="text-base font-bold text-lightBrown mt-2">
                            {
                                patientAppointmentCounts?.appointmentCounts
                                    ?.cancelled
                            }
                        </p>
                    </div>
                    <div className="border-r mx-3"></div>
                    <div>
                        <h2 className="text-xs font-medium montFont text-lightBrown">
                            Completed
                        </h2>
                        <p className="text-base font-bold text-lightBrown mt-2">
                            {
                                patientAppointmentCounts?.appointmentCounts
                                    ?.completed
                            }
                        </p>
                    </div>
                </div>
                <div className="flex justify-end space-x-[19px] mt-11 mx-4">
                    <Link
                        to={`${hospitalRoutes.appointment}?patientTmrNumber=${patTmrNumber}`}
                        type="button"
                        className="text-Tmred font-medium text-center text-base w-[115px] px-4 py-[10px] border border-Tmred rounded-xl"
                    >
                        View all
                    </Link>
                    <button
                        type="button"
                        onClick={handleBookButtonClick}
                        className="bg-Tmred text-white text-center w-[92px] px-4 py-[10px] rounded-xl font-medium text-base"
                    >
                        Book
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PatientProfileAppointmentCards;
