import React, { useEffect, useState } from 'react';
import card1 from '../Images/card1.png';
import card2 from '../Images/card2.png';
import card3 from '../Images/card3.png';
import count2 from './../Images/count2.svg';
import { useNavigate, useParams } from 'react-router-dom';
import AuthHeader from '../reusable/AuthHeader';
import goBack from '../utils/goBack';
import arrowLeft from './../Images/arrow-left.svg';
import { hospitalRoutes } from '../utils/routeNames';
import { pharmacyRoutes } from '../utils/pharmacyRouteNames';
import { useHospitalAccountStore, useTmrValidationStore, useHospitalAuthStore } from '../store/hospital/auth';
import { toast } from 'react-toastify';
import { usePharmacyAccountStore, usePharmacyAuthStore } from '../store/pharmacy/auth';

const Landing = () => {
    const navigate = useNavigate();
    const { slug } = useParams();
    const [messages, setMessages] = useState<string>('');
    const tmrValidation = useTmrValidationStore((state) => state.tmrValidation);
    const hospitalAccountStore = useHospitalAccountStore((state) => state);
    const hospitalAuthStore = useHospitalAuthStore((state) => state);

    const pharmacyAccountStore = usePharmacyAccountStore((state) => state);
    const pharmacyAuthStore = usePharmacyAuthStore((state) => state);

    useEffect(() => {
        if(!tmrValidation && slug === 'login') {
            navigate('/');
        }
        if(!slug || !['login', 'register'].includes(slug)) {
            navigate('/');
        }
        setMessages( tmrValidation?.messages?.toString() )
    }, []);

    const handleHospitalLogin = () => {
        if (slug === 'register') {
            navigate(hospitalRoutes.register);
        } else if (slug === 'login') {
            if (tmrValidation) {
                const { isHospital } = tmrValidation.types;
                if (isHospital) {
                    const hospitalData = tmrValidation?.entities?.find(entity => entity.type === 'hospital');
                    const hospitalEntity = hospitalData?.entity;
                    
                    // check if hospital has superadmin
                    if(!hospitalEntity.hasSuperAdmin) {
                        toast.error(messages);
                        hospitalAccountStore.updateHospital({ 
                            name: hospitalEntity.name, 
                            logo: hospitalEntity.logo, 
                            tagline: hospitalEntity.tagline 
                        });
                        hospitalAccountStore.updateToken(hospitalEntity.token);
                        navigate(hospitalRoutes.superAdmin);
                        return;
                    }
                    
                    // check if hospital is approved
                    if(!hospitalEntity?.approved) {
                        toast.error(messages)
                        return;
                    } 

                    hospitalAuthStore.updateHospital({ ...hospitalEntity });

                    // navigate to login
                    navigate(hospitalRoutes.login);
                } else {
                    // Redirect to hospital registration
                    navigate(hospitalRoutes.register);
                }
            }
        }
    };
    const handlePharmacyLogin = () => {
        if (slug === 'register') {
            navigate(pharmacyRoutes.register);
        } else if (slug === 'login') {
            if (tmrValidation) {
                const { isPharmacy } = tmrValidation.types;
                if (isPharmacy) {
                    const pharmacyData = tmrValidation?.entities?.find(entity => entity.type === 'pharmacy');
                    const pharmacyEntity = pharmacyData?.entity;
                    
                    // check if pharmacy has superadmin
                    if(!pharmacyEntity.hasSuperAdmin) {
                        toast.error(pharmacyData.message);
                        pharmacyAccountStore.updatePharmacy({ 
                            name: pharmacyEntity.name, 
                            logo: pharmacyEntity.logo, 
                            tagline: pharmacyEntity.tagline 
                        });
                        pharmacyAccountStore.updateToken(pharmacyEntity.token);
                        navigate(pharmacyRoutes.superAdmin)
                        return;
                    }

                    // check if pharmacy is approved
                    if(!pharmacyEntity?.approved) {
                        toast.error(messages)
                        return;
                    }


                    pharmacyAuthStore.updatePharmacy({ ...pharmacyEntity });

                    // navigate to login page
                    navigate(pharmacyRoutes.login);
                } else {
                    // Redirect to pharmacy registration
                    navigate(pharmacyRoutes.register);
                }
            }
        }
    };

    return (
        <div className="flex items-center justify-center bg-[#fff] w-full py-14">
            <div className="mx-auto w-full flex flex-col items-center justify-center gap-4">
                <AuthHeader />
                <div className="absolute bg-no-repeat top-[40%] md:mt-0 left-0 w-96 bg-contain md:h-[100vh] bg-reg-trinkle-left hide"></div>

                <div className="flex w-[80%] lg:w-fit items-center flex-col justify-center gap-3 border rounded-xl bg-lightTmrRed py-[32px] px-[20px] lg:px-[80px] mt-10 lg:mt-10 z-10">
                    <div className="w-full flex flex-row items-center justify-between gap-1 sm:gap-5">
                        <div className="flex">
                            <button
                                className="flex"
                                onClick={() => goBack(navigate)}
                            >
                                <img src={arrowLeft} alt="arrow-left" />
                            </button>
                        </div>
                        <h3 className="font-semibold text-[#201A19] text-center text-[32px] ">
                            Get Started
                        </h3>
                        <div className="flex">
                            <img src={count2} alt="count2" />
                        </div>
                    </div>

                    <p className="font-normal text-[#534341] text-[14px]">
                        Select one user mode type{' '}
                    </p>
                    <div className="flex w-full items-center gap-5 sm:flex-row flex-col">
                        <RegistrationCards
                            img={card1}
                            text="Healthcare Provider"
                            // goTo={
                            //     slug === 'login'
                            //         ? hospitalRoutes.login
                            //         : hospitalRoutes.register
                            // }
                            goTo={handleHospitalLogin}
                        />
                        <RegistrationCards
                            img={card2}
                            text="Health Insurance"
                            showOverlay={true}
                        />
                        <RegistrationCards
                            img={card3}
                            text="Pharmacy"
                            // goTo={
                            //     slug === 'login'
                            //         ? pharmacyRoutes.login
                            //         : pharmacyRoutes.register
                            // }

                            goTo={handlePharmacyLogin}
                        />
                    </div>
                </div>

                <div className="absolute bg-no-repeat top-[40%] md:mt-0 right-0 w-96 bg-contain md:h-[100vh]  bg-reg-trinkle-right hide"></div>

            </div>
        </div>
    );
};

export default Landing;

interface Props {
    img: any;
    text: string;
    goTo?: any;
    showOverlay?: boolean;
}

export const RegistrationCards: React.FC<Props> = ({
    img,
    text,
    goTo,
    showOverlay = false,
}) => {
    return (
        <button
            onClick={goTo}
            className={`relative flex flex-col items-center justify-center h-[16rem] bg-[#fff] border border-[#D8C2BF] rounded-2xl p-4 ${
                showOverlay
                    ? 'cursor-not-allowed'
                    : 'hover:bg-[#C00010] cursor-pointer hover:text-white'
            }  w-full text-[#201A19] `}
        >
            {showOverlay && (
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-2xl">
                    <p className="text-white text-[18px] font-bold">
                        Coming soon..
                    </p>
                </div>
            )}

            <img
                className="max-w-[100%] sm:w-[10.8rem] sm:h-[11.563rem] rounded-xl object-cover"
                src={img}
                alt="TMR register"
            />
            <div className="mt-4 text-center">
                <p className="text-[16px] font-medium ">{text}</p>
            </div>
        </button>
    );
};
