import Tabs from './Tabs';
import PatientAppointmentTable from './PatientAppointmentTable';
import { SlArrowLeft } from 'react-icons/sl';
import { useNavigate } from 'react-router-dom';
import { usePatientAppointmentStore } from '../../store/hospital/auth';
import { useEffect, useState } from 'react';

const ActiveState = () => {
    const navigate = useNavigate();
    const { patientAppointmentDetails } = usePatientAppointmentStore();
    const [pendingAppointment, setPendingAppointment] = useState<any[]>([]);
    const [cancelledAppointment, setCancelledAppointment] = useState<any[]>([]);
    const [completedAppointment, setCompletedAppointment] = useState<any[]>([]);
    // console.log(patientAppointmentDetails?.appointments);
    useEffect(() => {
        if (patientAppointmentDetails.appointments) {
            const pending = patientAppointmentDetails?.appointments?.filter(
                (row: any) => row.status === 'pending'
            );
            setPendingAppointment(pending);
            const cancelled = patientAppointmentDetails?.appointments?.filter(
                (row: any) => row.status === 'cancelled'
            );
            setCancelledAppointment(cancelled);
            const completed = patientAppointmentDetails?.appointments?.filter(
                (row: any) => row.status === 'completed'
            );
            setCompletedAppointment(completed);
        }
    }, [patientAppointmentDetails]);

    const goBack = () => {
        navigate(-1);
    };
    const tabs = [
        {
            title: 'All',
            content: (
                <>
                    <PatientAppointmentTable
                        appointment={patientAppointmentDetails?.appointments}
                    />
                </>
            ),
            count: patientAppointmentDetails?.appointments?.length,
        },
        {
            title: 'Pending',
            content: (
                <>
                    <PatientAppointmentTable appointment={pendingAppointment} />
                </>
            ),
            count: pendingAppointment?.length,
        },
        {
            title: 'Cancelled',
            content: (
                <>
                    <PatientAppointmentTable
                        appointment={cancelledAppointment}
                    />
                </>
            ),
            count: cancelledAppointment?.length,
        },
        {
            title: 'Completed',
            content: (
                <>
                    <PatientAppointmentTable
                        appointment={completedAppointment}
                    />
                </>
            ),
            count: completedAppointment?.length,
        },
    ];
    return (
        <div className="mt-7 m-4  sm:mt-0  ">
            <div className="flex items-center space-x-2 mb-6 cursor-pointer">
                <SlArrowLeft className="m-2" onClick={goBack} />
                <h6 className="font-semibold text-[28px] text-lightBrown">
                    Appointment
                </h6>
            </div>
            <div className="px-4 py-4 font-700 text-xl text-black border-transparent bg-[#FFFFFF]  p-2  rounded-2xl overflow-x-auto sm:overflow-hidden">
                <Tabs tabs={tabs} />
            </div>
        </div>
    );
};

export default ActiveState;
