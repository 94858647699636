import { ChangeEvent } from 'react';
import { TextField } from '@mui/material';

interface InputProps {
    value: string | number;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    label: string;
    name: string;
    className?: string;
    disabled?: boolean;
    type?: 'text' | 'password' | 'email' | 'number' | 'tel' | 'date';
    ref?: any;
    onBlur?:any;
    onClick?:any;
    InputLabelProps?: any;
    inputProps?: any;
}

const Input = ({
    value,
    onClick,
    onChange,
    label,
    name,
    disabled,
    type = 'text',
    ref,
    onBlur,
    ...rest
}: InputProps) => {
    return (
        <div className="w-full">
            <TextField
                {...rest}
                variant="outlined"
                color="primary"
                type={type}
                name={name}
                label={label}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
                onClick={onClick} 
                disabled={disabled}
                // defaultValue={value !== undefined ? value : ''}

                className="w-full border border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider"
            />
        </div>
    );
};

export default Input;
