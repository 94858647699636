
import { hospitalRoutes } from '../utils/routeNames';

import {
    Hospital,
    CalendarTick,
    Note,
    Wallet,
    Wallet3,
    Ticket,
    Messages,
    Profile2User,
    Setting2,
    ReceiptItem,
    Microscope,
    Truck,
} from 'iconsax-react';

const sidebarData = () => {
    let sideBarData = [
        {
            text: 'Dashboard',
            url: hospitalRoutes.dashboard,
            icon: <Hospital size="24" color="#534341" />,
            activeIcon: <Hospital size="24" color="#ffffff" />,
        },
        {
            text: 'Appointment',
            url: hospitalRoutes.appointment,
            icon: <CalendarTick size="24" color="#534341" />,
            activeIcon: <CalendarTick size="24" color="#ffffff" />,
            permissionName: 'appointment',
        },
        {
            text: 'Patient',
            url: hospitalRoutes.patient,
            icon: <Note size="24" color="#534341" />,
            activeIcon: <Note size="24" color="#ffffff" />,
            permissionName: 'patient',
        },
        {
            text: 'Ambulance',
            url: hospitalRoutes.ambulance,
            icon: <Truck variant='Outline' size="24" color="#534341" />,
            activeIcon: <Truck variant='Outline' size="24" color="#ffffff" />,
            permissionName: 'ambulance',
            sublinks: [
                {
                    text: 'Fleet Mgmt',
                    url: hospitalRoutes.fleetMgmt,
                },
                {
                    text: 'Request Mgmt',
                    url: hospitalRoutes.requestMgmt,
                   
                },
            ],
        },
        {
            text: 'Billing',
            url: hospitalRoutes.billing,
            icon: <ReceiptItem size="24" color="#534341" />,
            activeIcon: <ReceiptItem size="24" color="#ffffff" />,
            permissionName: 'billing',
        },
        {
            text: 'Hospital Wallet',
            url: hospitalRoutes.hospitalWallet,
            icon: <Wallet3 size="24" color="#534341" />,
            activeIcon: <Wallet3 size="24" color="#ffffff" />,
            permissionName: 'hospital wallet',
        },
        {
            text: 'My Wallet',
            url: hospitalRoutes.myWallet,
            icon: <Wallet size="24" color="#534341" />,
            activeIcon: <Wallet size="24" color="#ffffff" />,
        },
        {
            text: 'Voucher',
            icon: <Ticket size="24" color="#534341" />,
            url: '/hospital/voucher',
            sublinks: [
                {
                    text: 'My Voucher',
                    url: hospitalRoutes.voucher,
                },
                {
                    text: 'Hospital Voucher',
                    url: hospitalRoutes.HospitalVoucher,
                    // permissionName: 'voucher',
                },
            ],
            activeIcon: <Ticket size="24" color="#ffffff" />,
        },
        {
            text: 'Messaging',
            url: hospitalRoutes.messaging,
            icon: <Messages size="24" color="#534341" />,
            activeIcon: <Messages size="24" color="#ffffff" />,
            permissionName: 'message',
        },
        {
            text: 'Ads',
            url: hospitalRoutes.tmrAds,
            icon: <Microscope size="24" color="#534341" />,
            activeIcon: <Microscope size="24" color="#ffffff" />,
            permissionName: 'ads',
        },
        {
            text: 'User Mgmt',
            icon: <Profile2User size="24" color="#534341" />,
            url: '/hospital/user',
            sublinks: [
                {
                    text: 'Admin',
                    url: hospitalRoutes.admin,
                },
                {
                    text: 'Doctor',
                    url: hospitalRoutes.doctor,
                },
                {
                    text: 'Nurse',
                    url: hospitalRoutes.nurse,
                },
            ],
            activeIcon: <Profile2User size="24" color="#FFFFFF" />,
            permissionName: 'user',
        },
        {
            text: 'Settings',
            icon: <Setting2 size="24" color="#534341" />,
            url: '/hospital/settings',
            sublinks: [
                {
                    text: 'My Profile',
                    url: hospitalRoutes.profile,
                },
                {
                    text: 'Hospital',
                    url: hospitalRoutes.hospital,
                    permissionName: 'settings',
                },
            ],
            activeIcon: <Setting2 size="24" color="#FFFFFF" />,
        },
    ];

    return sideBarData;
};

export default sidebarData;
