import DashboardLayout from '../Dashboard/DashboardLayout';
import BillInvoice from '../../../components/PharmacyComponent/Billing/BillInvoice';

const BillInvoices = () => {
    return (
        <DashboardLayout>
            <div>
                <BillInvoice />
            </div>
        </DashboardLayout>
    );
};

export default BillInvoices;
