import React from 'react'
import { Helmet } from 'react-helmet'
import DashboardLayout from '../../Dashboard/DashboardLayout'
import AddAmb from '../../../../components/Ambulance/FleetMgmt/AddAmb'

function AddAmbulance() {
  return (
    <DashboardLayout>
    <Helmet>
        <title>
           TMR | Add Ambulance
        </title>
    </Helmet>
    <div className="">
       
       <AddAmb/>
    </div>
</DashboardLayout>
  )
}

export default AddAmbulance