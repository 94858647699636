import React from 'react';
import DashboardLayout from '../Dashboard/DashboardLayout';
import AppointmentDetails from '../../../components/Appointment/PatientAppointment/AppointmentDetails';
import {Helmet} from 'react-helmet'


function PatientAppointment() {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Patient Appointment
                </title>
            </Helmet>
            <AppointmentDetails />
        </DashboardLayout>
    );
}

export default PatientAppointment;
