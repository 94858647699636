import React from 'react';

type CardProps = {
    title: string;
    firstData: string;
    firstDataValue: any;
    secondData: string;
    secondDataValue: any;
    thirdData: string;
    thirdDataValue: any;
    iconSrc: string;
};

const Card: React.FC<CardProps> = ({
    title,
    firstData,
    firstDataValue,
    secondData,
    secondDataValue,
    thirdData,
    thirdDataValue,
    iconSrc,
}) => {
    return (
        <div className="bg-white  rounded-md p-4 w-full md:w-[30%] xl:w-[31.7%] 2xl:w-[230px]">
            <div className="flex items-center justify-between pb-4 mb-4 border-b-[1px] ">
                <h6 className="lg:text-xl font-semibold text-lg">{title}</h6>
                <img
                    src={iconSrc}
                    alt={title}
                    className="bg-[#ffd2f03a] rounded-full"
                />
            </div>
            <div className="flex justify-between items-center">
                <p className="font-medium text-sm text-dark100"> {firstData}</p>
                <p className="font-semibold text-[16px]">{firstDataValue}</p>
            </div>
            <div className="flex justify-between items-center my-5">
                <p className="font-medium text-sm text-dark100">
                    {secondData}
                </p>
                <p className="font-semibold text-[16px]"> {secondDataValue}</p>
            </div>
            <div className="flex justify-between items-center">
                <p className="font-medium text-sm text-dark100"> {thirdData}</p>
                <p className="font-semibold text-[16px]">{thirdDataValue}</p>
            </div>
        </div>
    );
};

export default Card;
