import { useEffect, useState } from 'react';
import Layout from '../Layout';
import DoctorAvatar from '../../../reusable/DoctorAvatar';
import Passcode from '../../../reusable/Passcode';
import LockcodeConfirm from './LockcodeConfirm';

import BlockButton from '../../../reusable/BlockButton';
import { useHospitalAuthStore } from '../../../store/hospital/auth';
import HospitalAuthHeader from '../../../reusable/HospitalAuthHeader';
import { Helmet } from 'react-helmet'
import ButtomLogo from '../../../reusable/ButtomLogo';


const LockcodeSetup = () => {
    const [code, setCode] = useState('');
    const hospitalAuthStore = useHospitalAuthStore((state) => state);
    const [showLockcodeConfirm, setShowLockcodeConfirm] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);

    useEffect(() => {
        if (code.length === 4) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [code]);

    const submitLockcode = (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        setShowLockcodeConfirm(true);
    };
    return (
        <Layout>
            <Helmet>
                <title>
                    TMR | Setup Lock Code
                </title>
            </Helmet>
            <div className=" flex items-center justify-center flex-col w-full  py-8 overflow-x-hidden ">
                <div>
                    {!showLockcodeConfirm && ( 
                        <>
                        
                            <HospitalAuthHeader
                                logo={hospitalAuthStore.hospital.logo}
                                text={hospitalAuthStore.hospital.tagline}
                                title={hospitalAuthStore.hospital.name}
                            />
                            
                            <div className="flex flex-col justify-center items-center  md:mb-[10px]">
                                <div className="flex flex-col justify-center border border-[#00000033] bg-white rounded-[25px] px-[40px] sm:px-[80px] py-[40px] max-w-[592px] mb-10">
                                    <div className="flex flex-col items-center">
                                        <h1 className=" font-semibold text-[32px] text-center ">
                                            Setup Lock Passcode
                                        </h1>

                                        <p className="font-normal text-[#534341] text-[14px] text-center tracking-widest mb-4">
                                            Enter a unique passcode that you can
                                            remember
                                        </p>

                                        <DoctorAvatar
                                            name={`${hospitalAuthStore.user.firstName} ${hospitalAuthStore.user.lastName}`}
                                            img={hospitalAuthStore.user.image}
                                        />

                                        <p className=" text-[16px] tracking-wide text-center">
                                            Screen lock activates after{' '}
                                            <span className=" text-[22px] font-semibold">
                                                5mins
                                            </span>{' '}
                                            of inactivity
                                        </p>
                                        <form onSubmit={submitLockcode}>
                                            <Passcode
                                                passcode={code}
                                                setPasscode={setCode}
                                            />
                                            <BlockButton
                                                text="Proceed"
                                                disabled={buttonDisabled}
                                                type="submit"
                                                isLoading={isLoading}
                                            />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {showLockcodeConfirm && <LockcodeConfirm code={code} />}
                </div>
                <div className="ml-auto mt-10 w-full flex justify-end">
                <ButtomLogo/>
                </div>
            </div>
        </Layout>
    );
};

export default LockcodeSetup;
