import React, { Fragment, useEffect, useState } from 'react'
import { IoIosAttach } from 'react-icons/io';
import { IoCloudDownloadOutline } from 'react-icons/io5';
import { SlArrowLeft } from 'react-icons/sl';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useQuery } from 'react-query';
import Lottie from 'lottie-react';
import loadingState from '../../../Images/loading.json';
import { pharmacyService } from '../../../services/pharmacyService';
import TimeComponent, { formatDateDmy, standardAmountFormat } from '../../../utils/helpers';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { usePharmacyAuthStore } from '../../../store/pharmacy/auth';
import { PatientFormat } from '../../Appointment/PatientAppointment/AppointmentCard';
import Modal from '../../../reusable/Modal';
const OrderDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [OrderDetails, setOrderDetails] = useState<any>({});
    const currency = usePharmacyAuthStore((state) => state.pharmacy.currency);
    const [showConfirmOrderModal, setShowConfirmOrderModal] =
        useState<boolean>(false);
        const [cancelOrderLoading, setCancelOrderLoading] =
        useState<boolean>(false);
    const [showCancelOrderModal, setShowCancelOrderModal] =
        useState<boolean>(false);
    const [cancelReason, setCancelReason] = useState<string>('');
    const [confirmOrderLoading, setConfirmOrderLoading] =
        useState<boolean>(false);
    const [confirmationStatus, setConfirmationStatus] =
        useState<boolean>(false);
    const {
    control,
        setValue,
        getValues, 
    } = useForm<any>();
    const goBack = () => {
        navigate(-1);
    };
    // get Order details from the backend
    const { data, isLoading,refetch, isError } = useQuery(
        ['singleOrder', id],
        () =>
            id
                ? pharmacyService.getSingleOrder(id)
                : Promise.reject('NO ID'),
        {
            enabled: !!id,
        }
    );
    console.log('single data', data)
    const toggleCancelOrderModal = () =>
        setShowCancelOrderModal((prev) => !prev);

    const handleCancelOrder = () => {
        const selectedReason = getValues("reason");
        
        if (!selectedReason && !cancelReason) {
            toast.error('Please provide a reason for cancelling the Order');
            return;
        }
    
        const payload = { reason: selectedReason || cancelReason }; // Prioritize the selected radio button value
        toggleCancelOrderModal();
        setCancelOrderLoading(true);
        pharmacyService
            .cancelOrder(OrderDetails._id, payload)
            .then((response) => {
                refetch();
                toast.success(response.data?.message || 'Order canceled successfully');
            })
            .catch((error) => {
                toast.error(error?.response?.data?.error?.message);
            })
            .finally(() => {
                setCancelOrderLoading(false);
            });
    };
    
    const handleCancelReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCancelReason(e.target.value);
        if (e.target.value) {
            setValue("reason", ""); // Clear radio button selection
        }
    };
    

    const toggleConfirmOrderModal = () =>
        setShowConfirmOrderModal((prev) => !prev);

    const handleConfirmOrder = () => {
        toggleConfirmOrderModal();
        setConfirmOrderLoading(true);
        pharmacyService
            .completeOrder(OrderDetails._id)
            .then((response) => {
                refetch();
                toast.success(
                    response.data?.message ||
                        'Order Confirmed Successfully'
                );
            })
            .catch((error) => {
                toast.error(error.response.data.error.message);
            })
            .finally(() => {
                setConfirmOrderLoading(false);
            });
    };

    useEffect(() => {
        if (data?.order) {
            setOrderDetails(data.order);
            setValue("orderStatus", data.order.status); // Set the status value in the form
        }
        if (
            data?.order?.status === 'pending' &&
            data?.order?.confirmed
        ) {
            setConfirmationStatus(true);
        }
    }, [data, setValue]);
    if (isLoading) {
        return <div><div className="flex justify-center items-center py-32">
        <Lottie
            animationData={loadingState}
            loop={true}
            className="lottie"
        />
    </div>.</div>;
    }
    if (isError) {
        return <div>Error fetching order, please refresh</div>;
    }
    const TotalAmount =OrderDetails?.items?.reduce((acc, drug) => acc + drug.price * drug.quantity, 0).toFixed(2)
    
  return (
    <Fragment>
    <div className="w-full bg-[#F5F5F5] h-full px-6 py-6">
      <div className="flex items-center space-x-2 mb-6 cursor-pointer">
                <SlArrowLeft className="m-2" onClick={goBack} />
                <h6 className="font-semibold text-[28px] text-lightBrown">
                   Order Details
                </h6>
            </div>
            <div className="flex flex-col md:flex-row gap-3">
           
                            <div className="flex flex-col lg:w-2/3">
                           
                                <div className="flex-col py-4 font-700 text-xl text-black border-transparent bg-[#FFFFFF]  rounded-2xl max-w-[742px] flex p-4">
                                <div className="flex items-center w-full pb-3 gap-6 sm:flex-row flex-col">
                                        
                                         {OrderDetails?.status ===
                                            'cancelled' && (
                                            <div
                                                className={`bg-[#FFB4AB] text-[#201A19] w-full flex flex-col gap-3 justify-center px-3 py-3 rounded-lg`}
                                            >
                                                <p className="text-center">
                                                  Order  Cancelled
                                                </p>
                                                
                                            </div>
                                        )}
                                    </div>
                                    <div
                                                className={`bg-[#FFF8F7] w-full flex  gap-3 justify-between px-4 py-4 mt-3 rounded-2xl`}
                                            >
                                                <div className='flex flex-col items-left justify-between'>
                                                <p className=' text-[16px] my-2 text-[#534341]'>Invoice ID <span className='text-[#200928] font-semibold'>{OrderDetails?.invoiceNumber}</span></p>

                                                
                                                </div>
                                                <div className='flex flex-col items-left justify-between'>
                                                <Content
                                                title='Date'
                                                content={formatDateDmy(OrderDetails?.createdAt)}
                                                /> 
                                                
            <div className={`flex gap-2 flex-wrap`}>
            <p className='font-medium text-[12px] text-[#534341]'>Time:</p>
            <p className='text-[#2C1512] font-medium text-[16px] max-w-[280px] mt-[6px]'><TimeComponent time={OrderDetails?.createdAt} /></p>
            </div>
                                                </div>
                                                
                                    </div>
                                    <div className='flex w-full flex-col  justify-between'>
                                    <div className='flex  my-3 p-4 flex-col sm:flex-row'>
                                        <div className='flex flex-col'>

                                        
                                            <h1 className='text-[20px] my-2 text-[#201A19] font-medium'>Customer Information</h1>
                                            <div className='flex flex-col items-left justify-between'>
                                                <Content
                                                        title='Name:'
                                                        content={OrderDetails?.customer?.fullName}
                                                        />
                                                        <Content
                                                        title='Phone Number:'
                                                        content={OrderDetails?.customer?.phoneNumber}
                                                        />
                                                        <Content
                                                        title='Email:'
                                                        content={OrderDetails?.customer?.email}
                                                        />
                                                        
                                                        
                                                        
                                        </div>
                                        </div>
                                        <div className='flex flex-col'>

                                       
                                    <h1 className='text-[20px] my-2 text-[#201A19] font-medium'>Shipping Information</h1>
                                    
                                        
                                    <div className='flex flex-col items-left justify-between'>
                                    <Content
                                            title='Method:'
                                            content={OrderDetails?.deliveryOption}
                                            />
                                            <Content
                                            title='Address:'
                                            content={OrderDetails?.customer?.address}
                                            />
                                            
                                            
                                            
                                            
                                </div>
                                </div>
                                    </div>
                                    <div className='flex  flex-col sm:flex-row p-4'>
                                   <div className='flex flex-col'>

                                   
                                    <h1 className='text-[20px]  text-[#201A19] font-medium'>Payment Information</h1>
                                    
                                        
                                        <div className='flex flex-col items-left justify-between'>
                                        <Content
                                                title='Method:'
                                                content={OrderDetails?.paymentMethod}
                                                />
                                                    
                                                
                                    </div>
                                    </div>
                                    <div className='flex flex-col'>

                                    
                                    <h1 className='text-[20px]  text-[#201A19] font-medium'>Doctor’s Prescription</h1>
                                    
                                        
                                      <div className='mt-3 flex justify-between rounded-lg items-center w-[279px] border border-[#EEE7E7] py-2 px-4'>
                                     <span className='flex gap-2 items-center'>
                                      <IoIosAttach />

<p className='text-[#534341] font-medium text-[14px] w-32 truncate'>

{OrderDetails?.doctorsReport?.value ? OrderDetails?.doctorsReport?.value.split('/').pop() : 'No file uploaded'}
        </p>
</span>
<button 
  type='button' 
  onClick={() => OrderDetails?.doctorsReport?.value && window.open(OrderDetails?.doctorsReport?.value, '_blank')} 
  disabled={!OrderDetails?.doctorsReport?.value}
>
  <IoCloudDownloadOutline />
</button>

                                      </div>
                                    </div>
                                    </div>
                                    </div>
                                    <div className='p-3 mb-3'>
                                        <h1 className='text-[#201A19] font-medium text-[20px] py-2'>Item Summary</h1>
                                        <div className="md:w-full">
                        <table className="w-full py-2 px-4">
                            <thead className="bg-[#EEE7E7] rounded-2xl">
                                <tr>
                                    <th className="py-2  px-4 text-center">
                                        <p className="text-[10px] text-left text-[#534341]">
                                           Item Name
                                        </p>
                                    </th>
                                    <th className="text-center">
                                        <p className="text-[10px] text-[#534341]">
                                            Qty
                                        </p>
                                    </th>
                                    <th className="text-center">
                                        <p className="text-[10px] text-[#534341]">
                                            Price
                                        </p>
                                    </th>
                                    <th className="text-center">
                                        <p className="text-[10px] text-[#534341]">
                                            Total
                                        </p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                               
                            {OrderDetails?.items && OrderDetails?.items.length > 0 ? (
                    OrderDetails?.items.map(drug => (
                        <tr key={drug.id}>
                            <td className="py-2 px-4">
                                <p className="text-[12px]">{drug.productName}</p>
                            </td>
                            <td className="py-2 px-4 text-center">
                                <p className="text-[12px]">{drug.quantity}</p>
                            </td>
                            <td className="py-2 px-4 text-center">
                                <p className="text-[12px]">{standardAmountFormat(drug.price,currency)}</p>
                            </td>
                            <td className="py-2 px-4 text-center">
                                
                                <p className="text-[12px]">{standardAmountFormat(drug.total,currency)}</p>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td className="py-2 px-4 text-center">
                            <p className="text-[12px]">No drugs selected</p>
                        </td>
                    </tr>
                )}
                               
                            </tbody>
                        </table>

                       
                       
                        
                    </div>
                                    </div>
                                    <div className='flex flex-col p-3 mb-3'>
    
    <div className='flex flex-col items-left justify-between'>
        {OrderDetails.status === 'cancelled' ? (<div className='bg-[#FFF8F7] p-4 w-full rounded-xl'>
            <PatientFormat
            text1="Reason for cancelling:"
            text2={ OrderDetails?.reason || "Order was cancelled"}
        />
        </div>
        ) : (<>
        <p className='text-[#857371] font-medium text-[14px] py-2'>Order Status</p>
            <Controller
                name="orderStatus"
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <FormControl component="fieldset" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor:'#F5F5F5', borderRadius:'20px', paddingLeft:'5px', width:'150px' }}>
                        <RadioGroup {...field} row>
                            {OrderDetails.status === 'pending' && (
                                <FormControlLabel 
                                    value="pending" 
                                    control={<Radio />} 
                                    label="Pending" 
                                    checked={OrderDetails.status === 'pending'}
                                />
                            )}
                            {OrderDetails.status === 'completed' && (
                                <FormControlLabel 
                                    value="completed" 
                                    control={<Radio />} 
                                    label="Completed" 
                                    checked={OrderDetails.status === 'completed'}
                                />
                            )}
                        </RadioGroup>
                    </FormControl>
                )}
            />
            </>
        )}
    </div>
</div>

                              
                                <div className={`py-3 border-t border-t-[#D8C2BF] mt-6 flex items-end w-full gap-2  flex-col lg:flex-row justify-end ${
                                            OrderDetails.status ===
                                                'cancelled' ||
                                            OrderDetails.status ===
                                                'completed'
                                                ? 'bg-[#1D1B201F] opacity-[28%] pointer-events-none'
                                                : ''
                                        }`}>
                                <button
                      onClick={toggleCancelOrderModal}
                      className={`w-[215px] h-[40px] p-2 sm:px-4 text-[16px]  flex justify-center rounded-lg items-center font-normal gap-2  transition-all duration-500 text-Tmred bg-white hover:cursor-pointer border border-[#857371]`}
                    
                     
                  >
                    {cancelOrderLoading
                                                    ? 'Cancelling...'
                                                    : 'Cancel order'}
                  
                  </button>
                                <button
                    onClick={
                        toggleConfirmOrderModal
                    }
                    disabled={
                        OrderDetails?.status ===
                            'cancelled' ||
                        confirmationStatus ||
                        OrderDetails?.status ===
                            'completed'
                    }
                      className={`w-[215px] h-[40px] p-2 sm:px-4 text-[16px]  flex justify-center rounded-lg items-center font-normal gap-2  transition-all duration-500 bg-Tmred text-white hover:cursor-pointer `}
                    
                     
                  >
                   {confirmOrderLoading
                                                ? 'Completing...'
                                                : confirmationStatus
                                                  ? 'Completed'
                                                  : 'Mark as completed'}
               
                  </button>
                                </div>
                                </div>
                            </div>
                            <div className="flex flex-col lg:w-1/3 gap-6 ">
                            <div className="flex-col py-4 font-700 text-xl text-black border-transparent bg-[#FFFFFF]  rounded-2xl max-w-[359px] flex p-4">
                            <h1 className='text-[#201A19] font-medium text-[20px] py-2'>Order Summary</h1>
                           
                               <div className='border-t border-[#D8C2BF] w-full py-2 my-2 flex flex-row items-center justify-between'>
                                    <p className='font-medium text-[12px] text-[#534341]'>Grand Total</p>
                                   
                                    {OrderDetails?.items && OrderDetails?.items.length > 0 && (
            <div className="py-4">
                <p className="text-right  font-semibold text-[#C00010] text-[14px]">
                    {standardAmountFormat(TotalAmount,currency)}
                </p>
            </div>
        )}
                                    </div>
                                </div>
                           
                            </div>
            </div>
    </div>
    <Modal
                isVisible={showConfirmOrderModal}
                onClose={toggleConfirmOrderModal}
                onSave={handleConfirmOrder}
                buttonEnabled={true}
                setButton=""
                saveText="Mark as completed"
                closeText="Cancel"
                showCloseButtonBorder={false}
            >
                <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2 sm:w-[400px]">
                    <h2 className="font-semibold text-2xl mb-5">
                    Order Completed
                    </h2>
                    <p className=" text-[14px] font-normal text-[#534341]">
                    Are you sure you want to mark this order as completed?
                    </p>
                </div>
            </Modal>
            <Modal
                isVisible={showCancelOrderModal}
                onClose={toggleCancelOrderModal}
                onSave={handleCancelOrder}
                buttonEnabled={true}
                setButton=""
                saveText="Submit"
                closeText="Cancel"
                showCloseButtonBorder={true}
            >
                <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2 sm:w-[500px]">
                    <h2 className="font-semibold text-2xl mb-5">
                    Cancel Order
                    </h2>
                    <p className=" text-[14px] font-normal text-[#534341]">
                    Kindly select a reason to cancel this order
                    </p>
                    <Controller
                                    name="reason"
                                    control={control}
                                    defaultValue=""
                               
                                    render={({ field }) => (
                                        <FormControl component="fieldset" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '16px' }}>
                                            <RadioGroup {...field}  onChange={(e) => {
                    field.onChange(e);
                    if (e.target.value) {
                        setCancelReason(""); // Clear text field value
                    }
                }}>
                                                <FormControlLabel value="The product is out of stock" control={<Radio />} label="out of stock" />
                                                <FormControlLabel value="Can't deliver to location" control={<Radio />} label="Can't deliver to location" />
                                            </RadioGroup>
                                        </FormControl>
                                    )}
                                />
                    <textarea
                        name="reason"
                        placeholder="Other reason"
                        value={cancelReason}
                        onChange={handleCancelReasonChange}
                        className="w-full border h-[56px] border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider my-4"
                    ></textarea>
                </div>
            </Modal>
</Fragment>
  )
}

export default OrderDetails

interface contentProps{
    title: string,
    content?: string,
   
}

export const Content =({title,content}:contentProps)=>{
    return(
        <div className={`flex gap-2 flex-wrap w-[281px]`}>
            <p className='font-medium text-[12px] text-[#534341]'>{title}</p>
            <p className='text-[#2C1512] font-medium text-[14px] max-w-[280px]'>{content}</p>
        </div>
    )
}

export const DetailsContent=[
    {
        title:"Discount",
        value:"N 13,000"

    },
    {
        title:"Delivery Fee",
        value:"N 10,000"

    },
    {
        title:"Order",
        value:"N 1,000"

    },
    {
        title:"VAT",
        value:"-0. 00"

    }
]