import { Tooltip } from '@mui/material';
import { ReactNode, useState } from 'react';

interface Tab {
  title: string;
  content: ReactNode;
  disabled?: boolean;
}

interface TabsProps {
  tabs: Tab[];
}

const DetailsTab: React.FC<TabsProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="w-full">
      <div className=" flex flex-col">
        <div className="flex flex-col-reverse sm:flex-row justify-between w-full border-[#EDE0DE] border-b ">
          <div className="flex">
          {tabs.map((tab, index) => (
  <div
    key={index}
    onClick={() => {
      if (!tab.disabled) {
        setActiveTab(index);
      }
    }}
    className={`flex items-center ${
      activeTab === index ? 'border-b-2 border-Tmred' : ''
    } ${tab.disabled ? 'opacity-50 ' : ''}`}>
    <div className={`mx-2 flex items-center`}>
      <Tooltip
        title={
          tab.disabled
            ? 'You will be able to access this when ad expires'
            : ''
        }
        arrow
      >
        <span>
          <button
            key={index}
            className={`px-4 py-3 font-medium text-[14px] ${
              index > 0 ? 'ml-2' : ''
            } ${
              activeTab === index ? 'text-Tmred' : 'text-[#534341]'
            } focus:outline-none`}
            disabled={tab.disabled}
          >
            {tab.title}
          </button>
        </span>
      </Tooltip>
    </div>
  </div>
))}
          </div>
        </div>

        <div className="">{tabs[activeTab].content}</div>
      </div>
    </div>
  );
};
export default DetailsTab;
