
const Profile = ({ads}) => {
  

  return (
    <div className="bg-[#FFF8F7] rounded-xl mt-9 mb-6 flex items-center p-4 flex-col sm:flex-row">
      <div className="flex items-center space-x-3 ">
        
        <div>
          <h2 className="sm:text-2xl text-sm font-semibold  text-[#201A19]">
          {ads?.adsName}
          </h2>
          <p className="sm:text-sm text-xs font-medium text-[#201A19] ">
          {ads?.adsObjective?.adType}

          </p>
          
        </div>
      </div>
      <div className="text-center sm:ml-[200px]">
        <p className="text-sm pb-1 font-medium text-[#201A19]">Status</p>
        <div
          className={`flex items-start justify-center text-center p-2 rounded-lg ${
            ads?.status === 'pending'
              ? 'bg-[#1D1B2014] text-[#201A19]'
              : ads?.status === 'approved'
              ? 'bg-[#31AF8C] text-white'
              : ads?.status === 'completed'
              ? 'bg-[#E2EEE7] text-[#201A19]'
              : 'bg-[#FFDAD5] text-[#201A19]'
          }`}>
          <div
            className={`w-[6px] h-[6px] rounded-full mr-2 mt-[6px] 
                            ${
                              ads?.status === 'pending'
                                ? 'bg-[#534341] '
                                : ads?.status === 'approved'
                                ? 'bg-[#FFFFFF]'
                                : ads?.status === 'completed'
                                ? 'bg-[#3A975F]'
                                : 'bg-Tmred'
                            }
                                                   }`}></div>
          <p className="text-xs capitalize font-medium">{ads?.status}</p>
        </div>
      </div>
    </div>
  );
};
export default Profile;
