import { useState, useEffect } from 'react';
import { SlArrowLeft } from 'react-icons/sl';
import { useNavigate, useParams } from 'react-router-dom';
import ManageAdminModal from './Modals/ManageAdminModal';
// import AdmProfile from './AdmProfile';
import LogActivity from './LogActivity';
import { useQuery } from 'react-query';
import { formatDateDmy } from '../../../utils/helpers';
import Lottie from 'lottie-react';
import loadingState from '../../../Images/loading.json';
import { pharmacyRoutes } from '../../../utils/pharmacyRouteNames';
import { pharmacyService } from '../../../services/pharmacyService';
const AdminProfile = () => {
    const { id } = useParams();

    const [manageAdminModal, setManageAdminModal] = useState(false);
    const [adminProfile, setAdminProfile] = useState<any>({});
    const [activityLog, setActivityLog] = useState<any[]>([]);
const navigate =useNavigate()
    const { data, isLoading, isError, refetch } = useQuery(
        ['admin', id],
        () =>
            id ? pharmacyService.getSingleUser(id) : Promise.reject('No ID'),
        {
            enabled: !!id, // Only fetch when id is available
        }   
    );

    useEffect(() => {
        if (data) {
            setAdminProfile(data?.user);
            setActivityLog(data?.activityLogs);
        }
    }, [data]);
// console.log({adminProfile})
    const handleModal = () => {
        setManageAdminModal(true);
    };
    const handleCloseModal = () => {
        setManageAdminModal(false);
    };
   
    
    if (isLoading) {
        return (
            <div className="flex justify-center items-center py-64">
                {' '}
                <Lottie
                    animationData={loadingState}
                    loop={true}
                    className="lottie"
                />
            </div>
        );
    }

    if (isError) {
        return <div>Error fetching admin user</div>;
    }
    
    return (
        <div className="mx-6 mt-6">
            <button
                onClick={()=>navigate(pharmacyRoutes.admin)}
                className="flex items-center  cursor-pointer"
            >
                <SlArrowLeft className="m-2" />
                <h2 className="font-semibold text-base md:text-[28px] text-lightBrown">
                    Admin Profile
                </h2>
            </button>
            <div className="flex flex-col lg:flex-row lg:space-x-6 mt-8">
                <div className ="basis-3/5">
                    <div className="flex-1 bg-white p-2 sm:p-4 rounded-2xl mb-10 lg-mb-0">
                    <div>
                        {adminProfile.status === 'suspended' && (
                            <div className='bg-[#FFB4AB] rounded-lg text-[#201A19] text-[22px] p-2 w-full mx-auto my-4 text-center'>
                                Admin Suspended 
                            </div>
                        )}
                    </div>
                        <div className="flex items-center bg-lightPink p-1 sm:p-4 space-x-1 sm:space-x-3 rounded-xl mb-6">
                            <img
                                src={adminProfile.image}
                                alt="adminImage"
                                className="rounded-full w-20 h-20"
                            />
                            <div>
                                <h2 className="sm:text-2xl text-sm font-medium text-lightBrown">
                                    {/* {adminProfile.salutation} {adminProfile.firstName} {adminProfile.lastName} */}
                                    {`${adminProfile.salutation && adminProfile.salutation !== "undefined" ? adminProfile.salutation + ' ' : ''}${adminProfile.firstName} ${adminProfile.lastName}`}

                                </h2>
                                <p className="sm:text-sm text-xs font-normal text-dark100 ">
                                    {adminProfile.email}
                                </p>
                                <p className="sm:text-sm text-xs capitalize font-normal text-lightBrown">
                                    {adminProfile.userType}
                                </p>
                            </div>
                        </div>
                        <div className="flex lg:flex-col xl:flex-row flex-col sm:flex-row gap-3">
                            <div>
                                <h2 className="text-base sm:text-[22px] font-medium text-lightBrown">
                                    Personal information
                                </h2>
                                <div className="my-6">
                                    <h2 className="text-sm font-normal text-lightBrown montFont">
                                        Gender
                                    </h2>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {adminProfile.gender || '-'}
                                    </p>
                                </div>
                                <div>
                                    <h2 className="text-sm font-normal text-lightBrown montFont">
                                        Phone Number
                                    </h2>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {adminProfile.phoneNumber || '-'}
                                    </p>
                                </div>
                                <div className="my-6">
                                    <h2 className="text-sm font-normal text-lightBrown montFont">
                                        City (optional)
                                    </h2>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {adminProfile.city || '-'}
                                    </p>
                                </div>
                                <div>
                                    <h2 className="text-sm font-normal text-lightBrown montFont">
                                        State (optional)
                                    </h2>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {adminProfile.state || '-'}
                                    </p>
                                </div>
                                <div className="mt-6">
                                    <h2 className="text-sm font-normal text-lightBrown montFont">
                                        Address (optional)
                                    </h2>
                                    <p className="text-[12px] font-medium text-lightBrown100">
                                        {adminProfile.address || '-'}
                                    </p>
                                </div>
                            </div>
                            <div className="mt-6 sm:mt-0 lg:mt-6 xl:mt-0">
                                <h2 className=" text-base sm:text-[22px] font-medium text-lightBrown">
                                    Professional information
                                </h2>
                                
                                <div>
                                    <h2 className="text-sm font-normal text-lightBrown montFont mt-4">
                                        MDCN License Number
                                    </h2>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {adminProfile.licenseNumber || '-'}
                                    </p>
                                </div>
                               
                                <div>
                                    <h2 className="text-sm font-normal text-lightBrown montFont">
                                        User Group
                                    </h2>
                                    <div className="flex gap-1 flex-wrap mt-5">
                                        {adminProfile?.permissions?.map(
                                            (permission, index) => (
                                                <button
                                                    type="button"
                                                    key={index}
                                                    className="text-xs font-medium text-brown200 border-[1px] border-brown200 py-[6px] px-3 rounded-lg"
                                                >
                                                    {permission.trim()}
                                                </button>
                                            )
                                        )}
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <h2 className="text-sm font-normal text-lightBrown montFont">
                                        Date Registered
                                    </h2>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {formatDateDmy(adminProfile.createdAt)}
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="flex justify-end space-x-6 py-3 border-t mt-10">
                            <button
                                onClick={handleModal}
                                type="button"
                                className="py-2 px-3"
                            >
                                Manage Admin
                            </button>
                            
                            <button
    type="button"
    className="text-Tmred border-[1px] border-lightBrown100 px-6 py-2 text-base font-normal rounded-lg"
    onClick={() => {
        const email = `mailto:${adminProfile?.email}`;
        const newTab = window.open(email, '_blank');
        if (newTab) {
            newTab.opener = null; // this line is a security measure to prevent the newly opened tab from gaining control over the original page.
        }
    }}
>
    Send Message
</button>

                        </div>
                    </div>
                </div>
                {/* <AdmProfile data={entireData} /> */}
            </div>
            <LogActivity activitylog={activityLog}/>
            {manageAdminModal && (
                <ManageAdminModal
                    closeModal={handleCloseModal}
                    adminId={adminProfile._id}
                    adminName={`${adminProfile.firstName} ${adminProfile.lastName}`}
                    adminUser={adminProfile}
                    refresh={refetch}
                />
            )}
        </div>
    );
};
export default AdminProfile;
