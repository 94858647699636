import { useHospitalAuthStore } from '../../store/hospital/auth';
import AdminDashboard from './AdminDashboard';
import DoctorDashboard from './DoctorDashboard';
import NurseDashboard from './NurseDashboard';



const DashboardUi = () => {
    const userStore = useHospitalAuthStore((state) => state.user);
        
    if (userStore.userType === 'superadmin' || userStore.userType === 'admin') {
        return <AdminDashboard />
    } else if (userStore.userType === 'doctor') {
        return <DoctorDashboard /> 
    } else if (userStore.userType === 'nurse') {
        return <NurseDashboard />
    } else {
        return null
    }
        
};

export default DashboardUi;
