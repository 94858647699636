import React from 'react';

type ErrorStateProps = { message: string };

const ErrorState = ({ message }: ErrorStateProps) => {
    return (
        <div className="flex gap-2 bg-[#FFDAD5] p-4 rounded-md my-6">
            <div className="w-max">
                <div className="w-10 flex text-red-700">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            d="M12 7.75V13M21.08 8.58v6.84c0 1.12-.6 2.16-1.57 2.73l-5.94 3.43c-.97.56-2.17.56-3.15 0l-5.94-3.43a3.15 3.15 0 0 1-1.57-2.73V8.58c0-1.12.6-2.16 1.57-2.73l5.94-3.43c.97-.56 2.17-.56 3.15 0l5.94 3.43c.97.57 1.57 1.6 1.57 2.73Z"
                            stroke="#FF8A65"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M12 16.2v.1"
                            stroke="#FF8A65"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        ></path>
                    </svg>
                </div>
            </div>
            <div className="text-sm">
                <p className="text-red-900 leading-tight">{message}</p>
            </div>
        </div>
    );
};

export default ErrorState;
