import React from 'react'
import DashboardLayout from '../Dashboard/DashboardLayout'
import { Helmet } from 'react-helmet'
import Message from '../../../components/Message/Message'

const Messaging = () => {
  return (
    <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Messaging
                </title>
            </Helmet>
            <div>
                <Message/>
            </div>
        </DashboardLayout>
  )
}

export default Messaging
