export  const permission = [
    {
        id: 1,
        perm: 'Billing',
    },
    {
        id: 2,
        perm: 'User',
    },
    {
        id: 3,
        perm: 'Patient',
    },
    {
        id: 4,
        perm: 'Appointment',
    },
    {
        id: 5,
        perm: 'Settings',
    },
    {
        id: 6,
        perm: 'EHR',
    },
    {
        id: 6,
        perm: 'Message',
    },
    {
        id: 7,
        perm: 'Hospital Wallet',
    },
    {
        id: 8,
        perm: 'Subscription',
    },
    {
        id: 9,
        perm: 'ads',
    },
    {
        id: 10,
        perm: 'ambulance',
    },
];