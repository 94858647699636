import Icon from '../../../Images/wallet-icon.svg';
import openIcon from '../../../Images/eye-icon.svg';
import closeIcon from '../../../Images/eye-slash.png';
import CardIcon from '../../../Images/card-icon.svg';
import RecentTransactions from '../../Billing/RecentTransaction';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import hospitalService from '../../../services/hospitalService';
import Modal from '../../../reusable/FundWallet';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import Input from '../../../reusable/Forms/Input';
import { useEffect, useState } from 'react';
import { standardAmountFormat } from '../../../utils/helpers';
import { adminService } from '../../../services/adminService';
import { useHospitalAuthStore } from '../../../store/hospital/auth';

type InputProps = {
    amount: string;
};

const HWallet = () => {
    const hospitalStore = useHospitalAuthStore();
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [walletBalance, setWalletBalance] = useState<number>(0);

    const walletType = 'hospital'; // set wallet type to hospital by default

    const { data, isLoading } = useQuery(['balance', walletType], () =>
        hospitalService.getWalletBalance(walletType)
    );

    useEffect(() => {
        if (data) {
            setWalletBalance(data?.balance);
        }
    }, [data]);

    const { data: escrowBalanceData, isLoading: escrowLoading,isError: escrowError } = useQuery(
        'escrow',
        adminService.getEscrowBalance
    );

    const [viewBalance, setViewBalance] = useState(true);
    const history = useNavigate();
    const gotoAdd = (e: any) => {
        e.preventDefault();
        setShowModal(true);
    };
    const gotoSave = (e: any) => {
        e.preventDefault();
        history('/hospital/hospital-wallet/send-money');
    };
    const gotoWithdraw = (e: any) => {
        e.preventDefault();
        history('/hospital/hospital-wallet/withdraw-money');
    };
    
    const btnOptions = [
        {
            id: 1,
            text: 'Top up',
            goTo: gotoAdd,
        },
        {
            id: 2,
            text: 'Send',
            goTo: gotoSave,
        },
        {
            id: 3,
            text: 'Withdraw',
            goTo: gotoWithdraw,
        },
    ];

    const toggleBalance = (e: any) => {
        e.preventDefault();
        setViewBalance((prevState) => !prevState);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const {
        formState: { isDirty, isValid },
        handleSubmit,
        control,
        reset,
    } = useForm<InputProps>();

    const mutation = useMutation((data: InputProps) =>
        hospitalService.fundWallet(data, walletType)
    );

    useEffect(() => {
        if (mutation.isSuccess) {
            setLoading(false);
            const response = mutation.data;
            const message = response.data.message;
            toast.success(message);
            const link = response.data.payload.data.paymentLink;
            const openWindowWithDelay = () => {
                window.open(link, '_blank');
            };

            // Set a 3-second delay
            setTimeout(openWindowWithDelay, 2000);
            closeModal();
            reset({});
        }
    }, [mutation.isSuccess, mutation.data, reset]);

    useEffect(() => {
        if (mutation.isError) {
            setLoading(false);
            let err: any = mutation.error;
            toast.error(err.response?.data?.error?.message);
            reset({});
        }
    }, [mutation.isError, mutation.error]);

    const onSubmitForm: SubmitHandler<InputProps> = (data) => {
        setLoading(true);
        mutation.mutate(data);
    };

    return (
        <div className="w-full flex flex-col md:px-8 py-4 px-2">
            <div className="flex justify-between items-center py-4">
                <p className="font-bold sm:text-[28px] text-[20px]">
                    Hospital Wallet
                </p>
            </div>
            <div className="flex flex-col lg:flex-row items-center gap-10 w-fit flex-wrap">
                <div className="w-full sm:w-4/5 lg:w-[360px] h-[225px]">
                    <div className="w-full h-full">
                        <div
                            style={{
                                background:
                                    'linear-gradient(289deg, #410002 5.11%, #C00010 97.64%)',
                            }}
                            className=" w-full px-4 pt-4 pb-7 rounded-[20px] relative"
                        >
                            <div className="flex justify-between items-center">
                                <h2 className="text-xl text-white font-bold">
                                    Wallet
                                </h2>
                                <img
                                    src={Icon}
                                    alt=""
                                    className="rounded-full bg-[#ffffff0e]"
                                />
                            </div>
                            <div className="flex justify-between items-center mt-4">
                                <div className="space-y-2 text-white">
                                    <p className="text-xs font-medium opacity-70">
                                        Available Balance
                                    </p>
                                    <p className="font-semibold text-[28px]">
                                        {viewBalance ? (
                                            <span>
                                                {/* <span>&#8358;</span>  */}
                                                {isLoading
                                                    ? '0'
                                                    : standardAmountFormat(
                                                          walletBalance,
                                                          hospitalStore.hospital
                                                              .currency
                                                      )}
                                            </span>
                                        ) : (
                                            <span>
                                                <span>&#8358;</span>*****
                                            </span>
                                        )}
                                    </p>
                                </div>

                                <div
                                    onClick={toggleBalance}
                                    className="cursor-pointer"
                                >
                                    {viewBalance ? (
                                        <img
                                            className="h-10 w-10"
                                            src={openIcon}
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            className=""
                                            src={closeIcon}
                                            alt=""
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-wrap justify-between items-center mt-6 ">
                                {btnOptions.map((button) => (
                                    <div key={button.id}>
                                        <button
                                            onClick={button.goTo}
                                            className="px-2 cursor-pointer  py-1 flex justify-center items-center gap-2 font-medium text-xs text-white border-[1px] rounded-[18px] mt-2 sm:mt-0 sm:ml-2"
                                        >
                                            <img src={CardIcon} alt="" />

                                            {button.text}
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white rounded-3xl p-4 w-full h-[225px] md:w-[265px] flex-col">
                    <div className="flex items-center justify-between w-full">
                        <h2 className="lg:text-[20px] font-semibold text-md ">
                            Outstanding
                        </h2>
                        <svg
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clip-path="url(#clip0_8229_236996)">
                                <rect
                                    x="4"
                                    y="4"
                                    width="40"
                                    height="40"
                                    rx="20"
                                    fill="#FFF8F7"
                                />
                                <path
                                    d="M25 21H19"
                                    stroke="#2C1512"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M34.0002 22.9692V25.0292C34.0002 25.5792 33.5602 26.0292 33.0002 26.0492H31.0402C29.9602 26.0492 28.9702 25.2592 28.8802 24.1792C28.8202 23.5492 29.0602 22.9592 29.4802 22.5492C29.8502 22.1692 30.3602 21.9492 30.9202 21.9492H33.0002C33.5602 21.9692 34.0002 22.4192 34.0002 22.9692Z"
                                    stroke="#2C1512"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M29.48 22.55C29.06 22.96 28.82 23.55 28.88 24.18C28.97 25.26 29.96 26.05 31.04 26.05H33V27.5C33 30.5 31 32.5 28 32.5H19C16 32.5 14 30.5 14 27.5V20.5C14 17.78 15.64 15.88 18.19 15.56C18.45 15.52 18.72 15.5 19 15.5H28C28.26 15.5 28.51 15.51 28.75 15.55C31.33 15.85 33 17.76 33 20.5V21.95H30.92C30.36 21.95 29.85 22.17 29.48 22.55Z"
                                    stroke="#534341"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_8229_236996">
                                    <rect
                                        x="4"
                                        y="4"
                                        width="40"
                                        height="40"
                                        rx="20"
                                        fill="white"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className=" mt-5">
                        <p className="text-[12px] font-medium ">
                            Escrow balance
                        </p>
                        <h2 className="lg:text-[28px] font-semibold text-md ">
                            {escrowLoading
                                ? '0':escrowError
                                ? '0'
                                : standardAmountFormat(
                                        escrowBalanceData?.escrowAmount,
                                        hospitalStore.hospital.currency
                                    )}
                        </h2>
                    </div>
                </div>
            </div>
            <RecentTransactions type="hospital" />
            <Modal onClose={closeModal} isVisible={showModal}>
                <div className="bg-white">
                    <div className="bg-white">
                        <h1 className="text-[24px] font-semibold leading-[32px] mb-[16px]">
                            Add Money
                        </h1>
                        <p className="text-[14px] mb-[40px] font-medium text-[#534341]">
                            Enter the amount you want to add
                        </p>
                        <form onSubmit={handleSubmit(onSubmitForm)}>
                            <div>
                                <Controller
                                    name="amount"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: 'amount is required' }}
                                    render={({ field }) => (
                                        <Input
                                            type="number"
                                            label="Enter Amount"
                                            {...field}
                                        />
                                    )}
                                />
                            </div>
                            <div className="mb-2 mt-[24px] flex flex-col sm:flex-row gap-6 ml-auto justify-end">
                                <button onClick={closeModal}> Cancel</button>
                                <button
                                    type="submit"
                                    disabled={!isValid || !isDirty}
                                    className={`w-auto h-[40px] p-2 sm:px-4 text-[14px] flex justify-center rounded-lg items-center text-[#9d9b9b] font-semibold  hover:cursor-not-allowed transition-all duration-500  ${
                                        !isValid || !isDirty || loading
                                            ? ' bg-[#1D1B201F] hover:cursor-not-allowed '
                                            : 'bg-Tmred text-white hover:cursor-pointer '
                                    } `}
                                >
                                    {loading ? 'Proceeding' : 'Proceed'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default HWallet;
