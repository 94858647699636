import icon from '../../Images/infoCircle.svg';
import Input from '../../reusable/Forms/Input';
import { useState, useEffect } from 'react';
import { SelectChangeEvent } from '@mui/material';
import SelectElement from '../../reusable/Forms/Select';

interface FormData {
    email: string;
    adminPermission: string;
}
interface ShareAdminFormModalProps {
    closeModal: () => void;
}

const ShareAdminFormModal: React.FC<ShareAdminFormModalProps> = ({
    closeModal,
}) => {
    const [button, setButton] = useState(false);
    const [formData, setFormData] = useState<FormData>({
        email: '',
        adminPermission: '',
    });
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleSelectChange = (e: SelectChangeEvent) => {
        setFormData((prevData: any) => ({
            ...prevData,
            [e.target.name || '']: e.target.value,
        }));
    };
    const handlesubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const dataToSend = {
            email: formData.email,
            adminPermission: formData.adminPermission,
        };

        console.log(dataToSend);
        setFormData({
            email: '',
            adminPermission: '',
        });
    };
    useEffect(() => {
        if (formData.email !== '' && formData.adminPermission !== '') {
            setButton(true);
        } else {
            setButton(false);
        }
    }, [formData.email, formData.adminPermission]);
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-[#2826265E] bg-opacity-75 z-50">
            <form
                onSubmit={handlesubmit}
                className="bg-white p-2 mx-1 sm:mx-0 sm:p-6 rounded-[28px]"
            >
                <h2 className="text-2xl font-semibold text-lightBrown">
                    Share Admin Form
                </h2>
                <p className="font-normal text-sm text-dark100 my-3">
                    Please enter the admin's email to share the form securely.{' '}
                </p>
                <Input
                    name="email"
                    label="Email Address"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full border-[1px] my-6 border-dark100 font-normal p-4 rounded-[8px] placeholder:text-base placeholder:text-dark100 text-base text-dark100 tracking-wider bg-white focus:border-[1px] focus:outline-none focus:border-dark100"
                />
                <div className="mt-3">
                    <SelectElement
                        onChange={handleSelectChange}
                        value={formData.adminPermission}
                        name="adminPermission"
                        options={['Admin permission', 'Single permission']}
                    />
                </div>

                <div className="flex space-x-4 bg-lightPink py-2 px-4 items-center my-6">
                    <img src={icon} alt="" />
                    <p className="text-xs font-medium">
                        Form link expires after 24 hours
                    </p>
                </div>
                <div className="flex justify-end space-x-6  mt-6">
                    <button
                        onClick={closeModal}
                        className="py-[10px] px-6 border-[1px] rounded-lg text-Tmred text-sm font-medium border-lightBrown100"
                    >
                        Cancel
                    </button>
                    <button
                        disabled={!button}
                        className={` py-[10px] px-6 rounded-lg text-sm font-medium  ${
                            button
                                ? 'bg-Tmred   text-white  '
                                : 'bg-[#1D1B201F] text-[#999797]'
                        } `}
                    >
                        Send
                    </button>
                </div>
            </form>
        </div>
    );
};
export default ShareAdminFormModal;
