import React, { useEffect, useState } from 'react'
import Card from '../../dashboard/DashboardCard'

import Amblance from '../../../Images/reqambulance.svg'
import RecentAmbulance from './RecentAmbulance';
import { useQuery } from 'react-query';
import hospitalService from '../../../services/hospitalService';
import Lottie from 'lottie-react';
 import loadingState from '../../../Images/loading.json';
function Ambulance() {
    const { data, isLoading,isError } = useQuery(['fleetCount'], () =>
        hospitalService.getRequestCount()
    );

    const [isAmbulanceRequest, setIsAmbulanceRequest] = useState(false);
    const [ambulanceRequests, setAmbulanceRequests] = useState<any[]>([]);

    useEffect(() => {
        if (data?.ambulanceRequests) {
            setAmbulanceRequests(data?.ambulanceRequests?.slice(0, 10));
            setIsAmbulanceRequest(true);
        } else {
            setIsAmbulanceRequest(false);
        }
    }, [data]);

    const ambulanceData = {
        title: 'Requests ',
        firstData: 'Pending',
        firstDataValue:data?.request?.pendingRequest || 0,
        secondData: 'Accepted',
        secondDataValue: data?.request?.acceptedRequest || 0,
        thirdData: 'Completed',
        thirdDataValue: data?.request?.completedRequest || 0,
        fourthData: 'Cancelled',
        fourthDataValue: data?.request?.cancelRequest || 0,
        fifthData: 'Total',
        fifthDataValue: data?.request?.totalRequest || 0,
        iconSrc: Amblance,
    };
    
    return (
        <div className="md:px-8 px-1 mt-6 bg-[#F6F6F7] ">
            <h2 className="font-semibold text-base md:text-[28px] text-lightBrown">
                    Ambulance 
            </h2> 
            {
            isLoading ?
            <>
                <div className="flex justify-center items-center py-32">
                    <Lottie
                        animationData={loadingState}
                        loop={true}
                        className="lottie"
                    />
                </div>
            </>
            :
            isError ?
            <>
                <div className="flex justify-center items-center">
                    Error loading count, please refresh your page
                </div>
            </>
            :
            <>
                <div className="flex  flex-col  gap-6  w-full my-4">
                    <Card {...ambulanceData} />     
                </div> 
                <RecentAmbulance ambulanceRequests={ambulanceRequests} />
            </>
            }                    
        </div>
    )
}

export default Ambulance