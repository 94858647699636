import React, { useState, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Modal from '../../reusable/Modal';
import DatePickerElement from '../../reusable/Forms/DatePickerElement';

interface FilterModalProps {
    isVisible: boolean;
    onClose: () => void;
    onSave: (queryParams: string) => void;
    buttonEnabled: boolean;
    setButton: (enabled: boolean) => void;
    resetFilters:() => void;
    currentFilters:any
}

const FilterModal: React.FC<FilterModalProps> = ({
    isVisible,
    onClose,
    onSave,
    buttonEnabled,
    setButton,
    resetFilters,
    currentFilters,
}) => {
  

    const [selectedModeButton, setSelectedButton] = useState<string | null>(null);
    const [selectedFromDate, setSelectedFromDate] = useState<string>('');
    const [selectedToDate, setSelectedToDate] = useState<string>('');
    const [selectedOrder, setSelectedOrder] = useState<string | null>(null);
    const [queryParam, setQueryParam] = useState<string>('');
    const [localFromDate, setLocalFromDate] = useState<Dayjs | null>(
        currentFilters.from ? dayjs(currentFilters.from) : null
    );
    const [localToDate, setLocalToDate] = useState<Dayjs | null>(
        currentFilters.to ? dayjs(currentFilters.to) : null
    );
 const handleResetFilter = () => {
    setQueryParam('')
        resetFilters();
      };
    useEffect(() => {
        const queryParamsArr: string[] = [];

        if (selectedFromDate && selectedToDate) {
            queryParamsArr.push(`from=${selectedFromDate}`);
            queryParamsArr.push(`to=${selectedToDate}`);
        }
        if (selectedModeButton) {
            queryParamsArr.push(`mode=${selectedModeButton}`);
        }
        if (selectedOrder) {
            queryParamsArr.push(`order=${selectedOrder}`);
        }

        const queryParams = '&' + queryParamsArr.join('&');
        setQueryParam(queryParams);
    }, [selectedOrder, selectedModeButton, selectedFromDate, selectedToDate]);

    const handleModeButtonClick = (value: string) => {
        setSelectedButton(value);
    };

    const handleOptionChange = (option: string) => {
        setSelectedOrder(option);
    };

    const handleSave = (e: React.FormEvent) => {
        e.preventDefault();
        setButton(false);
        onSave(queryParam);
    };

    return (
        <Modal
            isVisible={isVisible}
            onClose={onClose}
            onSave={handleSave}
            setButton={setButton}
            buttonEnabled={!buttonEnabled}
            saveText="Save"
            closeText="Close"
            showCloseButtonBorder={false}
            clearAll={true}
            onClearAll={handleResetFilter}
        >
            <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2 sm:w-[400px]">
                <h2 className="font-semibold text-2xl mb-5">
                    Filter By
                </h2>
                
                <div className="my-9">
                    <h2 className="font-medium text-base text-dark100 mb-3">
                    Type of ambulance
                    </h2>
                    <div className="md:flex items-start sm:space-x-3 gap-2 space-y-1 md:space-y-0 ml-4 flex-col ">
                        {['Basic Life Support (BLS)', 'Advanced Life Support (ALS)'].map((mode) => (
                            <button
                                key={mode}
                                type="button"
                                className={`px-4 border-[1px] text-lightBrown border-lightBrown100 font-medium text-sm py-[6px] rounded-lg ${
                                    selectedModeButton === mode ? 'bg-[#1D1B2014]' : ''
                                }`}
                                onClick={() => handleModeButtonClick(mode)}
                            >
                                {mode.charAt(0).toUpperCase() + mode.slice(1).replace('-', ' ')}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="mb-5">
                    <h2 className="font-medium text-base text-dark100 mb-3">
                    Date Created
                    </h2>
                    <div className="md:flex md:space-x-6 space-y-1 md:space-y-0 ml-4">
                        <DatePickerElement
                            label="From"
                            value={localFromDate}
                            onChange={(date: Dayjs | null) => setLocalFromDate(date)}
                        />
                        <DatePickerElement
                            label="To"
                            value={localToDate}
                            onChange={(date: Dayjs | null) => setLocalToDate(date)}
                        />
                    </div>
                </div>
                <div className="">
                    <h2 className="font-medium text-base text-dark100 mb-3">
                        Order
                    </h2>
                    <div className="ml-4">
                        {['asc', 'desc'].map((order) => (
                            <label key={order} className="flex items-center space-x-2 cursor-pointer mb-2">
                                <input
                                    type="checkbox"
                                    className="p-[2px] rounded-sm cursor-pointer"
                                    checked={selectedOrder === order}
                                    onChange={() => handleOptionChange(order)}
                                />
                                <span className="text-base font-normal">
                                    {order.charAt(0).toUpperCase() + order.slice(1)}
                                </span>
                            </label>
                        ))}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default FilterModal;
