import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

/**
 * Pharmacy Account Store
 */

type PharmacyAccountStore = {
    pharmacy: {
        name: string;
        logo: string;
        tagline: string;
    };
    token: string;
};
type AdditionalService = {
    name: any;

    amount: number;
};

type PharmacyAccountStoreAction = {
    updatePharmacy: (pharmacy: {
        name: string;
        logo: string;
        tagline: string;
    }) => void;
    updateToken: (token: string) => void;
    resetPharmacy: () => void;
    resetToken: () => void;
};

export const usePharmacyAccountStore = create<
    PharmacyAccountStore & PharmacyAccountStoreAction
>()(
    persist(
        (set) => ({
            pharmacy: {
                name: '',
                logo: '',
                tagline: '',
            },
            token: '',
            updatePharmacy: (pharmacy) => set(() => ({ pharmacy })),
            resetPharmacy: () =>
                set(() => ({
                    pharmacy: {
                        name: '',
                        logo: '',
                        tagline: '',
                    },
                })),
            updateToken: (token) => set(() => ({ token })),
            resetToken: () => set(() => ({ token: '' })),
        }),
        {
            name: 'pharmacy-account-store',
            storage: createJSONStorage(() => sessionStorage),
        }
    )
);

/**
 * Hospital Authentication Store
 */

type PharmacyAuthStore = {
    pharmacy: {
        name: string;
        logo: string;
        tagline: string;
        tmrId: string;
        id: string;
        _id: string;
        email: string;
        address: string;
        city: string;
        state: string;
        country: string;
        currency: string;
        phoneNumber: string;
        createdAt: string;
        licenseNumber: string;
        privateMode: boolean;
        bankDetails: {
            bankName: string;
            accountName: string;
            accountNumber: string;
            bankCode: string;
        };
        metadata: {
            specialties: any;
            about: string;
            gallery: string[];
            rating: number | null;
        };
        location: {
            type: string;
            coordinates: string[];
        };
        scheduling: {
            availableDays: string[];
            durationPerSession: number | null;
            telemedicine: {
                capacity: number | string;
                fee: number | string;
            };
            homeVisit: {
                fee: number | string;
                capacity: number | string;
            };
            walkIn: {
                fee: number | string;
                capacity: number | string;
            };
            openHours: {
                end: string;
                start: string;
            };
            defaultHospitalFee: any;
        };
    };
    subscription: any;
    user: {
        _id: string;
        hospitalId: string;
        firstName: string;
        lastName: string;
        title: string;
        email: string;
        permissions?: string[];
        phoneNumber: string;
        image: string;
        userType: string;
        hasSetupPasscode: boolean | null;
        country: string;
        specialties: any;
        status: string;
        availability: string;
        state: string;
        city: string;
        address: string;
        licenseNumber: string;
        createdAt: string;
        gender: string;
        bankDetails: {
            bankName: string;
            accountName: string;
            accountNumber: string;
            bankCode: string;
        };
    };
    additionalServices: AdditionalService[];
    token: string;
};

type PharmacyAuthStoreAction = {
    updatePharmacy: (pharmacy: PharmacyAuthStore['pharmacy']) => void;
    updateUser: (user: PharmacyAuthStore['user']) => void;
    updateSubscription: (
        subscription: PharmacyAuthStore['subscription']
    ) => void;
    updateToken: (token: PharmacyAuthStore['token']) => void;
    updateAdditionalServices: (services: AdditionalService[]) => void;
    clearToken: () => void;
    resetStore: () => void;
};

const initialAuthStore = {
    pharmacy: {
        name: '',
        logo: '',
        tagline: '',
        tmrId: '',
        id: '',
        _id: '',
        email: '',
        address: '',
        city: '',
        state: '',
        country: '',
        currency: '',
        phoneNumber: '',
        createdAt: '',
        licenseNumber: '',
        permissions: [],
        privateMode: false,
        bankDetails: {
            bankName: '',
            accountName: '',
            accountNumber: '',
            bankCode: '',
        },
        metadata: {
            specialties: [],
            about: '',
            gallery: [],
            rating: null,
        },
        location: {
            type: '',
            coordinates: [],
        },
        scheduling: {
            availableDays: [],
            durationPerSession: null,
            telemedicine: {
                capacity: '',
                fee: '',
            },
            homeVisit: {
                fee: '',
                capacity: '',
            },
            walkIn: {
                fee: '',
                capacity: '',
            },
            openHours: {
                end: '',
                start: '',
            },
            defaultHospitalFee: '',
        },
    },
    subscription: null,
    user: {
        _id: '',
        hospitalId: '',
        firstName: '',
        lastName: '',
        title: '',
        email: '',
        phoneNumber: '',
        image: '',
        userType: '',
        hasSetupPasscode: null,
        country: '',
        specialties: [],
        status: '',
        availability: '',
        state: '',
        city: '',
        address: '',
        licenseNumber: '',
        createdAt: '',
        gender: '',
        bankDetails: {
            bankName: '',
            accountName: '',
            accountNumber: '',
            bankCode: '',
        },
    },
    additionalServices: [],
    token: '',
};
export const usePharmacyAuthStore = create<
    PharmacyAuthStore & PharmacyAuthStoreAction
>()(
    persist(
        (set) => ({
            ...initialAuthStore,
            updateAdditionalServices: (services: AdditionalService[]) =>
                set({ additionalServices: services }),

            updatePharmacy: (pharmacy: PharmacyAuthStore['pharmacy']) =>
                set({ pharmacy }),

            updateUser: (user: PharmacyAuthStore['user']) => set({ user }),
            updateSubscription: (
                subscription: PharmacyAuthStore['subscription']
            ) => set({ subscription }),
            updateToken: (token: string) => set({ token }),
            clearToken: () => set({ token: '' }),
            resetStore: () => set({ ...initialAuthStore }),
        }),

        {
            name: 'pharmacy-auth-store',
            storage: createJSONStorage(() => sessionStorage),
        }
    )
);

/**
 * Reset Password Store
 */

type ResetPasswordStore = {
    token: string;
};

type ResetPasswordStoreAction = {
    setToken: (token: string) => void;
};
export const useResetPasswordStore = create<
    ResetPasswordStore & ResetPasswordStoreAction
>(
    persist(
        (set) => ({
            token: '',
            setToken: (token: string) => set({ token }),
        }),
        {
            name: 'pharmacy-reset-password-store',
            storage: createJSONStorage(() => sessionStorage),
        }
    ) as any
);

/**
 * change Password Store
 */

type PasscodeStore = {
    code: string;
};

type PasscodeStoreAction = {
    setCode: (code: string) => void;
};
export const usePasscodeStore = create<PasscodeStore & PasscodeStoreAction>(
    persist(
        (set) => ({
            code: '',
            setCode: (code: string) => set({ code }),
        }),
        {
            name: 'pharmacy-passcode-store',
            storage: createJSONStorage(() => sessionStorage),
        }
    ) as any
);

type NotificationStore = {
    notificationLength: number;
    unreadLength: number;
    selectedNotifications: string[];
};

type NotificationStoreActions = {
    setNotificationLength: (length: number) => void;
    setUnreadLength: (length: number) => void;
    setSelectedNotifications: (selectedNotifications: string[]) => void;
};

type NotificationStoreState = NotificationStore & NotificationStoreActions;

export const useNotificationStore = create<NotificationStoreState>((set) => ({
    notificationLength: 0,
    unreadLength: 0,
    selectedNotifications: [],
    setNotificationLength: (length: number) =>
        set((state) => ({ ...state, notificationLength: length })),
    setUnreadLength: (length: number) =>
        set((state) => ({ ...state, unreadLength: length })),
    setSelectedNotifications: (selectedNotifications: string[]) =>
        set((state) => ({ ...state, selectedNotifications })),
}));

type AdminStore = {
    adminCount: number;
};
type AdminStoreActions = {
    setAdminCount: (count: number) => void;
};
type AdminStoreState = AdminStore & AdminStoreActions;

export const useAdminStore = create<AdminStoreState>((set) => ({
    adminCount: 0,
    setAdminCount: (count: number) =>
        set((state) => ({ ...state, adminCount: count })),
}));

type UserCountStore = {
    nurseCount: number;
    doctorCount: number;
};
type UserCountStoreActions = {
    setNurseCount: (count: number) => void;
    setDoctorCount: (count: number) => void;
};
type UserCountStoreState = UserCountStore & UserCountStoreActions;
export const useUserCountStore = create<UserCountStoreState>((set) => ({
    nurseCount: 0,
    doctorCount: 0,
    setNurseCount: (count: number) =>
        set((state) => ({ ...state, nurseCount: count })),
    setDoctorCount: (count: number) =>
        set((state) => ({ ...state, doctorCount: count })),
}));

type PatientStore = {
    patientCount: number;
};
type PatientStoreActions = {
    setPatientCount: (count: number) => void;
};
type PatientStoreState = PatientStore & PatientStoreActions;
export const usePatientStore = create<PatientStoreState>((set) => ({
    patientCount: 0,
    setPatientCount: (count: number) =>
        set((state) => ({ ...state, patientCount: count })),
}));

interface PatientSearchStore {
    searchedPatient: any | null;
    setSearchedPatient: (patient: any | null) => void;
}

export const usePatientSearchStore = create<PatientSearchStore>((set) => ({
    searchedPatient: null,
    setSearchedPatient: (patient) => set({ searchedPatient: patient }),
}));

interface PatientDataSearchStore {
    searchedPatient: any | null;
    setSearchedPatient: (patient: any | null) => void;
}

export const usePatientDataSearchStore = create<PatientDataSearchStore>(
    (set) => ({
        searchedPatient: null,
        setSearchedPatient: (patient) => set({ searchedPatient: patient }),
    })
);
interface HospitalPlanId {
    planId: any | null;
    setPlanId: (hospital: any | null) => void;
}

export const useHospitalPlanId = create<HospitalPlanId>((set) => ({
    planId: null,
    setPlanId: (hospital) => set({ planId: hospital }),
}));

type AppointmentStore = {
    mode: string;
    reason: string;
    time: string;
    date: string;
    doctor: {
        firstName: string;
        lastName: string;
    };
};
type AppointmentStoreActions = {
    setMode: (mode: string) => void;
    setReason: (reason: string) => void;
    setTime: (time: string) => void;
    setDate: (date: string) => void;
    setDoctor: (doctor: { firstName: string; lastName: string }) => void;
};
type AppointmentStoreState = AppointmentStore & AppointmentStoreActions;
export const useAppointmentStore = create<AppointmentStoreState>((set) => ({
    mode: '',
    time: '',
    reason: '',
    date: '',
    doctor: {
        firstName: '',
        lastName: '',
    },
    setMode: (word: string) => set((state) => ({ ...state, mode: word })),
    setTime: (time: string) => set((state) => ({ ...state, time: time })),
    setReason: (reason: string) =>
        set((state) => ({ ...state, reason: reason })),
    setDate: (date: string) => set((state) => ({ ...state, date: date })),
    setDoctor: (doctor: { firstName: string; lastName: string }) =>
        set((state) => ({ ...state, doctor: doctor })),
}));
type AppointmentLengthStore = {
    pending: number;
    cancelled: number;
    completed: number;
    all: number;
};
type AppointmentLengthActions = {
    setPending: (count: number) => void;
    setCancelled: (count: number) => void;
    setCompleted: (count: number) => void;
    setAll: (count: number) => void;
};
type AppointmentLengthState = AppointmentLengthStore & AppointmentLengthActions;
export const useAppointmentLengthStore = create<AppointmentLengthState>(
    (set) => ({
        pending: 0,
        completed: 0,
        cancelled: 0,
        all: 0,
        setPending: (count: number) =>
            set((state) => ({ ...state, pending: count })),
        setCompleted: (count: number) =>
            set((state) => ({ ...state, completed: count })),
        setCancelled: (count: number) =>
            set((state) => ({ ...state, cancelled: count })),
        setAll: (count: number) => set((state) => ({ ...state, all: count })),
    })
);
type PatientAppointmentStore = {
    patientAppointmentDetails: any;
};
type PatientAppointmentStoreActions = {
    setPatientAppointmentDetails: (details: any) => void;
};
export const usePatientAppointmentStore = create<
    PatientAppointmentStore & PatientAppointmentStoreActions
>((set) => ({
    patientAppointmentDetails: null,
    setPatientAppointmentDetails: (details) =>
        set((state) => ({ ...state, patientAppointmentDetails: details })),
}));

type PatStore = {
    tmrNumber: string;
    bookClicked: boolean;
    viewAllClicked: boolean;
};
type PatActions = {
    setTmrNumber: (tmrNumber: string) => void;
    setBookClicked: (clicked: boolean) => void;
    setViewAllClicked: (clicked: boolean) => void;
};

export const usePatStore = create<PatStore & PatActions>((set) => ({
    tmrNumber: '',
    bookClicked: false,
    viewAllClicked: false,
    setTmrNumber: (tmrNumber: string) => set({ tmrNumber }),
    setBookClicked: (clicked) => set({ bookClicked: clicked }),
    setViewAllClicked: (clicked) => set({ viewAllClicked: clicked }),
}));

interface FilterOptions {
    gender: string;
    fromDate: Date | null;
    toDate: Date | null;
    order: 'ascending' | 'descending';
}

export const useFilterStore = create<{
    filterOptions: FilterOptions;
    setFilterOptions: (newFilterOptions: Partial<FilterOptions>) => void;
}>((set) => ({
    filterOptions: {
        gender: '',
        fromDate: null,
        toDate: null,
        order: 'ascending',
    },
    setFilterOptions: (newFilterOptions) =>
        set((state) => ({
            filterOptions: { ...state.filterOptions, ...newFilterOptions },
        })),
}));

interface RemarkStore {
    remark: string;
    setRemark: (remark: string) => void;
}

export const useRemarkStore = create<RemarkStore>((set) => ({
    remark: '',
    setRemark: (remark) => set({ remark }),
}));

/**
 * Screen time out store
 */

type ScreenTimeOut = {
    isTimeOut: boolean;
};

type ScreenTimeOutAction = {
    setIsTimeOut: (timeout: boolean) => void;
};
export const useScreenTimeOut = create<ScreenTimeOut & ScreenTimeOutAction>(
    persist(
        (set) => ({
            isTimeOut: false,
            setIsTimeOut: (timeout: boolean) => set({ isTimeOut: timeout }),
        }),
        {
            name: 'pharmacy-screen-timeout-store',
            storage: createJSONStorage(() => sessionStorage),
        }
    ) as any
);


