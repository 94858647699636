// CancelModal.js
import React, { useEffect, useState } from 'react';
import Modal from '../../../../../reusable/Modal';
import search from '../../../../../Images/Leading-icon.png';
import drugType from '../../../../../Images/drugType.png';
import drugType1 from '../../../../../Images/PharmacyImages/drug2.svg';
import { useNavigate } from 'react-router-dom';
import { pharmacyRoutes } from '../../../../../utils/pharmacyRouteNames';
import { useQuery } from 'react-query';
import { pharmacyService } from '../../../../../services/pharmacyService';

const DrugNameModal = ({
    showModal,
    onClose,
    onSave,
    buttonEnabled,
    setButton,
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedDrug, setSelectedDrug] = useState(null);
    const navigate = useNavigate();
    const [drug, setDrug] = useState<any>([]);
    // State for search input

    // Function to handle search input change
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };
    //drugs to display
    const { data, isError, isLoading } = useQuery(['drugs', searchTerm], () =>
        pharmacyService.getDrug(searchTerm)
    );
    useEffect(() => {
        setDrug(data?.drugs);
    }, [data]);

    console.log(drug);

    // Filtered list of drugs based on search input
    const filteredDrugs = drug?.filter(
        (drug) => drug?.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const handleDrugSelect = (drug) => {
        setSelectedDrug(drug.name);
        onSave(drug);
    };

    return (
        <Modal
            isVisible={showModal}
            onClose={onClose}
            onSave={onSave}
            buttonEnabled={buttonEnabled}
            setButton={setButton}
            saveText="Proceed"
            closeText="Cancel"
            showCloseButtonBorder={true}
        >
            <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2 lg:w-[500px]">
                <div className="flex items-center  w-full mb-5 justify-between">
                    <h2 className="font-semibold text-2xl ">Search Drug</h2>
                    <button
                        className=" text-[14px] font-normal text-Tmred"
                        onClick={() =>
                            navigate(pharmacyRoutes.requestInventory)
                        }
                    >
                        Request Drug
                    </button>
                </div>

                <div className="mb-5 flex items-center gap-2 rounded-lg w-full bg-[#F8F8F8]">
                    <img src={search} alt="search" />
                    <input
                        type="text"
                        placeholder="Search drug"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="w-full font-normal text-[16px] bg-transparent text-[#534341] border-transparent outline-none focus:ring-0"
                    />
                </div>
                <div className="lg:h-[240px] overflow-y-scroll">
                    {filteredDrugs?.length === 0 ? (
                        <p className="text-center text-gray-500 py-2">
                            Search does not match
                        </p>
                    ) : (
                        filteredDrugs?.map((drug, index) => (
                            <div
                                key={index}
                                className={`flex items-center gap-4 mb-4
                            ${
                                selectedDrug === drug.name
                                    ? 'bg-[#FFDAD5] rounded-tl-2xl rounded-bl-2xl'
                                    : 'hover:bg-[#EDE0DE]'
                            }`}
                                onClick={() => handleDrugSelect(drug)}
                            >
                                <img
                                    src={drug?.images}
                                    alt={drug?.name}
                                    className="w-12 h-12 rounded"
                                />
                                <span className="text-lg font-medium">
                                    {drug?.name}
                                </span>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default DrugNameModal;
