import  { useState, useRef, useEffect } from 'react';
import profile from '../../Images/profile.png';
import arrow from '../../../Images/arrow-up.jpg';

interface ImageUploadProps {
    onImageChange: (image: any) => void;
    selectedImage: string | ArrayBuffer | null;
    selectedImages:any
}

const ImageUpload: React.FC<ImageUploadProps> = ({
    onImageChange,
    selectedImage,
    selectedImages,
}) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const convertFileToURL = (file: File): string => {
        const objectURL = URL.createObjectURL(file);
        return objectURL;
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                console.log('Image Data:', reader.result);
                onImageChange(reader.result);
            };

            reader.readAsDataURL(file);
            const imageURL = convertFileToURL(file);
            console.log('Image URL:', imageURL);
        } else {
            onImageChange(null);
        }
    };

    const handleUploadButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    return (
        <div className="flex justify-center flex-col items-center bg-white p-4 h-[371px] rounded-[20px]">
           

            <div className="relative mt-[42px] w-40 h-40 overflow-hidden rounded-full border ">
                {selectedImage && typeof selectedImage === 'string' && (
                    <img
                        src={selectedImage}
                        alt="Selected"
                        className="w-full h-full object-cover"
                    />
                )}

                <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    ref={fileInputRef}
                    className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                />
            </div>
                    <p className='w-full py-3 col-span-2 text-center'>Max. 6 pictures</p>

            <button
            disabled={selectedImages.length >= 6}
                onClick={handleUploadButtonClick}
                className={`${selectedImages.length >= 6 ? 'opacity-[60%] cursor-not-allowed ' : 'border-Tmred text-Tmred'} flex justify-center rounded-lg items-center py-2 border lg:w-[358px]  w-full`}
            >
              {selectedImages.length <  6 &&   <img src={arrow} alt="" />}
                <span className=" text-xs font-medium ml-2">
                    Upload
                </span>
            </button>
        </div>
    );
};

export default ImageUpload;
