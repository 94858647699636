import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SlArrowLeft } from 'react-icons/sl';
import EditInventryDetailsForm from './EditInventryDetailsForm';
import { Trash } from 'iconsax-react';
import { pharmacyRoutes } from '../../../../utils/pharmacyRouteNames';
import DeleteModal from '../AddInventory/InventoryModals/DeleteModal';

function EditPage() {
    const navigate = useNavigate();
    const back = () => {
        navigate(-1);
    };
    const handleRemoveClick = (e: any) => {
        setDeleteModalVisible(true);
    };
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const handleDeleteConfirm = async () => {
        navigate(pharmacyRoutes.inventory);

        setDeleteModalVisible(false);
    };

    const handleDeleteCancel = () => {
        setDeleteModalVisible(false);
    };
    return (
        <Fragment>
            <div className="md:mx-8 mx-1 mt-6 ">
                <div className="flex items-center w-full justify-between mb-6">
                    <div className="flex justify-between">
                        <button
                            onClick={back}
                            className="flex items-center  cursor-pointer"
                        >
                            <SlArrowLeft className="m-2" />
                            <h2 className="font-semibold text-base md:text-[28px] text-lightBrown">
                                Edit Product
                            </h2>
                        </button>
                    </div>
                    <button
                        className="text-Tmred bg-white rounded-lg flex flex-row items-center gap-3 text-center max-w-[302px] py-2 px-4  border  border-Tmred"
                        onClick={handleRemoveClick}
                    >
                        <Trash size="24" color="#C00010" />
                        <span className="leading-[1.2rem]">Delete Product</span>
                    </button>
                </div>
                <EditInventryDetailsForm />
            </div>
            <DeleteModal
                handleDeleteConfirm={handleDeleteConfirm}
                handleDeleteCancel={handleDeleteCancel}
                isDeleteModalVisible={isDeleteModalVisible}
            />
        </Fragment>
    );
}

export default EditPage;
