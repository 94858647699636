import { useRouteError } from 'react-router-dom';

export default function ErrorPage() {
    const error = useRouteError() as { statusText?: string; message?: string };
    // console.error(error);

    return (
        <div className="px-8 flex flex-col items-center justify-center w-full h-full">
            <h1 className="text-Tmred text-[22px]">Oops!</h1>
            <p className=" text-[18px]">
                Sorry, an unexpected error has occurred.
            </p>
            <p className="text-Tmred text-[12px] font-semibold">
                <i>Page {error.statusText || error.message}</i>
            </p>
        </div>
    );
}
