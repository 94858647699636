import React from 'react';
import DashboardLayout from '../Dashboard/DashboardLayout';
import {Helmet} from 'react-helmet'
import AddInventry from '../../../components/PharmacyComponent/Inventory/AddInventory/AddInventry';


const AddInventory = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Add Inventory
                </title>
            </Helmet>
          <AddInventry/>
        </DashboardLayout>
    );
};

export default AddInventory;
