
import image from '../../../Images/EmptyAds.svg';
const AdsEmptyState: React.FC = () => {
    return (
        <div className="flex flex-col justify-center items-center mt-28">
            <img src={image} alt="emptyImage" />
            <p className="text-center text-[#534341] font-normal text-[14px] pt-2">
            No ads are currently available or listed.
             Add new ads to start <br /> managing your Ads and 
             ensure your services are ready when needed. 
               
            </p>
        </div>
    );
};

export default AdsEmptyState;