import { Close } from '@mui/icons-material';
import React, { ReactNode } from 'react';
type VoucherModalProps = {
    isVisible: boolean;
    onClose: () => void;
    children: ReactNode;
    header: any;
    headerContent?:any;
    // h: string;
    // w: string;
};
const VoucherModal = ({
    isVisible,
    onClose,
    children,
    header,
    headerContent
    // h,
    // w,
}: VoucherModalProps) => {
    if (!isVisible) return null;
    const handleClose = (e) => {
        if (e.target.id === 'wrapper') {
            onClose();
        }
    };
    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div
                className="fixed z-10 inset-0 flex justify-center items-center bg-[#2826265E] bg-opacity-20 "
                id="wrapper"
                onClick={handleClose}
            >
                <div className={`bg-white rounded-2xl p-4`}>
                    <div className="flex justify-between">
                        <div className='flex gap-2'>
                        <p className="2rem font-[600] leading-10">{header}</p>
                        <span >
                                {headerContent}
                        </span>
                        </div>
                       
                        <div
                            className="cursor-pointer "
                            onClick={() => onClose()}
                        >
                            <Close />
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default VoucherModal;
