interface Props {
    logo: string;
    text: string;
    title: string;
}
const HospitalAuthHeader = ({ logo, title, text }: Props) => {
    return (
        <div className="flex w-full  items-center justify-center gap-4 py-14">
            <div>
                <img src={logo} alt="Hospital logo" className='rounded-full w-[88px] h-[88px]' />
            </div>
            <div className="flex flex-col  ">
                <h3 className="font-semibold max-w-[450px] truncate text-[#201A19] sm:text-[36px]">
                    {title}
                </h3>
                <p className="text-[12px] font-normal text-[#49454F] italic">
                    {text}
                </p>
            </div>
        </div>
    );
};

export default HospitalAuthHeader;
