import React, { useEffect, useState } from 'react';
import VoucherImg from '../../Images/voucher.svg';
import Table from '../../constants/Table';
import TimeComponent, { formatDateDmy } from '../../utils/helpers';
import { useQuery } from 'react-query';
import hospitalService from '../../services/hospitalService';
import { useNavigate } from 'react-router-dom';
import CurrencyFormat from '../../utils/Currency';
import { adminService } from '../../services/adminService';
import { toast } from 'react-toastify';

const RecentVoucher = ({ walletType }: { walletType: string }) => {
    const [isVoucher, setVoucherHistory] = useState(false);
    const [voucherData, setVoucherData] = useState<any>();

    const navigate = useNavigate();
    // const goBack = () => {
    //     navigate(-1);
    // };

    //get voucher
    useEffect(() => {
        adminService
            .getAllVoucher(walletType, '', 1) // Get All voucher
            .then((res) => {
                console.log(res);
                setVoucherData(res);
                console.log(voucherData.vouchers);
            })
            .catch((err) => {
                toast.error(err);
            });
    }, []);

    // console.log(billHistories[0].totalAmount);
    const upcomingTableHeaders = [
        {
            title: 'Voucher Code',
            render: (row: any) => (
                <div className="py-[6px] text-[12px] font-medium text-light_gray text-left">
                    <p className="font-medium !text-[#201A19] text-sm">
                        {row.code}
                    </p>
                </div>
            ),
        },
        {
            title: 'Amount',
            render: (row: any) => (
                <div>
                    <p
                        className={`${
                            row.transactionType === 'credit'
                                ? '!text-[#3A975F]'
                                : '!text-[#201A19]'
                        } text-xs font-medium`}
                    >
                        <CurrencyFormat amount={row.amount} />
                    </p>
                </div>
            ),
        },
        {
            title: 'Name',
            render: (row: any) => (
                <div className="flex items-center">
                    <div>
                        <p className="!text-lightBrown text-sm font-medium truncate w-[180px]">
                            {row.status === 'used'
                                ? row.metadata?.usedBy?.name
                                : row.metadata?.boughtBy?.name}
                        </p>
                    </div>
                </div>
            ),
        },
        {
            title: 'Type',
            render: (row: any) => (
                <div
                    className={`flex items-start justify-center p-3 rounded w-fit ${
                        row.status === 'used'
                            ? 'bg-[#FFDAD5] text-red-500'
                            : 'bg-[#1D1B2014] text-lightBrown'
                    }`}
                >
                    <div
                        className={`w-[6px] h-[6px] rounded-full mr-2 mt-[6px] ${
                            row.status === 'used'
                                ? 'bg-[#C00010]'
                                : 'bg-[#8BBEA0]'
                        }`}
                    />
                    <p className="text-xs">{row.status}</p>
                </div>
            ),
        },

        {
            title: 'Date',
            render: (row: any) => (
                <p className="capitalize flex gap-2 font-medium text-lightBrown text-xs">
                    {formatDateDmy(row.createdAt)},
                    <TimeComponent time={row.createdAt} />
                </p>
            ),
        },
    ];
    return (
        <div className="mt-6 w-full px-4 py-4 rounded-xl md:overflow-hidden bg-white">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-black font-bold text-md md:text-xl">
                    Recent Vouchers
                </h2>
            </div>
            {voucherData?.vouchers?.length === 0 ? (
                <div className="flex flex-col justify-center items-center mt-6">
                    <img src={VoucherImg} alt="empty" />
                    <p className="mt-8 mb-16 w-[40%] text-center text-sm font-medium text-dark100">
                        Purchase vouchers and use it for your subscription,
                        Appointment, Bill, and wallet funding
                    </p>
                </div>
            ) : (
                <div className="mt-[13px] flex flex-col px-4 py-3 overflow-x-auto scrollbar-hide">
                    <>
                        <Table
                            rows={voucherData?.vouchers?.slice(0, 5)}
                            headers={upcomingTableHeaders}
                            allowRowClick
                            showHead={true}
                        />
                        <div className="flex items-center justify-center w-full my-3 pt-6">
                            <button
                                onClick={() =>
                                    navigate(
                                        `/hospital/voucher/voucher-history/${walletType}`
                                    )
                                }
                                className="w-full sm:w-fit h-[40px] text-[14px] flex items-center text-center justify-center text-[#C00010] border border-[#C00010] py-2 px-6 rounded-lg font-medium"
                            >
                                View all vouchers
                            </button>
                        </div>
                        {/* <Pagination /> */}
                    </>
                </div>
            )}
        </div>
    );
};

export default RecentVoucher;
