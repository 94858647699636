import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Tab from './Tab';
import Tabs from './Tabs';
import AppointmentData from './AppointmentData';
import { useQuery } from 'react-query';
import hospitalService from '../../services/hospitalService';
import { hospitalRoutes } from '../../utils/routeNames';
import Lottie from 'lottie-react';
import loadingState from '../../Images/loading.json';
import { useHospitalAuthStore, usePatStore } from '../../store/hospital/auth';
import Modal from '../../reusable/Modal';

const ActiveState = () => {
    const userStore = useHospitalAuthStore((state) => state.user);
    const viewAllClicked = usePatStore((state) => state.viewAllClicked);
    const [appointment, setAppointment] = useState<any[]>([]);
    const [pendingAppointment, setPendingAppointment] = useState<any[]>([]);
    const [cancelledAppointment, setCancelledAppointment] = useState<any[]>([]);
    const [completedAppointment, setCompletedAppointment] = useState<any[]>([]);
    const [appointmentData, setAppointmentData] = useState<any>([]);
    const [filteredAppointmentData, setFilteredAppointmentData] = useState('');
    const [bookAppointmentModal, setBookAppointmentModal] = useState(false);
    const navigate = useNavigate();

    const { data, isError, isLoading } = useQuery(
        'appointment',
        hospitalService.getAppointment
    );

    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (searchTerm: string) => {
        setSearchQuery(searchTerm);
    };

    const handleFilterQueryParam = (queryParams: string) => {
        hospitalService
            .filterAppointment(queryParams)
            .then((res) => {
                setFilteredAppointmentData(res);
            })
            .catch((err) => {
                // toast.error()
                // console.log(err);
            });
    };

    useEffect(() => {
        setAppointmentData(data);
        if (filteredAppointmentData) {
            setAppointmentData(filteredAppointmentData);
        }
    }, [data, filteredAppointmentData]);

    useEffect(() => {
        const patientTmrNumber = new URLSearchParams(
            window.location.search
        ).get('patientTmrNumber');
        if (patientTmrNumber) {
            const patientAppointments = appointmentData?.appointments?.filter(
                (row: any) => row.patientTmrNumber === patientTmrNumber
            );
            setAppointment(patientAppointments);
            const pending = appointmentData?.appointments?.filter(
                (row: any) =>
                    row.status === 'pending' &&
                    row.patientTmrNumber === patientTmrNumber
            );
            setPendingAppointment(pending);
            const cancelled = appointmentData?.appointments?.filter(
                (row: any) =>
                    row.status === 'cancelled' &&
                    row.patientTmrNumber === patientTmrNumber
            );
            setCancelledAppointment(cancelled);
            const completed = appointmentData?.appointments?.filter(
                (row: any) =>
                    row.status === 'completed' &&
                    row.patientTmrNumber === patientTmrNumber
            );
            setCompletedAppointment(completed);
        } else if (appointmentData?.appointments) {
            if (userStore.userType === 'doctor') {
                const doctorAppointments = appointmentData.appointments?.filter(
                    (appointment) => userStore._id === appointment?.doctor?._id
                );

                setAppointment(doctorAppointments);
                const pending = doctorAppointments?.filter(
                    (row: any) => row.status === 'pending'
                );
                setPendingAppointment(pending);
                const cancelled = doctorAppointments?.filter(
                    (row: any) => row.status === 'cancelled'
                );
                setCancelledAppointment(cancelled);
                const completed = doctorAppointments?.filter(
                    (row: any) => row.status === 'completed'
                );
                setCompletedAppointment(completed);
            } else {
                setAppointment(appointmentData.appointments);
                const pending = appointmentData?.appointments?.filter(
                    (row: any) => row.status === 'pending'
                );
                setPendingAppointment(pending);
                const cancelled = appointmentData?.appointments?.filter(
                    (row: any) => row.status === 'cancelled'
                );
                setCancelledAppointment(cancelled);
                const completed = appointmentData?.appointments?.filter(
                    (row: any) => row.status === 'completed'
                );
                setCompletedAppointment(completed);
            }
        }
    }, [appointmentData, viewAllClicked, userStore]);

    const toggleBookAppointmentModal = () => {
        setBookAppointmentModal(!bookAppointmentModal);
    };

    const handleBookAppointment = () => {
        navigate(hospitalRoutes.bookAppointment);
    };

    const tabs = [
        {
            title: 'All',
            content: (
                <Tab>
                    <AppointmentData
                        appointment={appointment}
                        searchQuery={searchQuery}
                    />
                </Tab>
            ),
            count: appointment?.length || 0,
        },
        {
            title: 'Pending',
            content: (
                <Tab>
                    <AppointmentData
                        appointment={pendingAppointment}
                        searchQuery={searchQuery}
                    />
                </Tab>
            ),
            count: pendingAppointment?.length || 0,
        },
        {
            title: 'Cancelled',
            content: (
                <Tab>
                    <AppointmentData
                        appointment={cancelledAppointment}
                        searchQuery={searchQuery}
                    />
                </Tab>
            ),
            count: cancelledAppointment?.length || 0,
        },
        {
            title: 'Completed',
            content: (
                <Tab>
                    <AppointmentData
                        appointment={completedAppointment}
                        searchQuery={searchQuery}
                    />
                </Tab>
            ),
            count: completedAppointment?.length || 0,
        },
    ];

    return (
        <div className="mt-7 sm:mt-0 w-full bg-[#F5F5F5] ">
            <div className="flex justify-between items-center px-8 py-4">
                <p className="font-bold sm:text-[28px] text-[20px]">
                    Appointment
                </p>
                <button
                    onClick={toggleBookAppointmentModal}
                    className="text-white font-light bg-[#C00010] rounded-[8px] px-3 sm:pr-[24px] sm:px-[16px] sm:py-[10px] py-2 text-[16px]"
                >
                    + Book
                </button>
            </div>
            <div className="px-4 py-4 font-700 text-xl text-black border-transparent bg-[#FFFFFF]  p-2 m-4  rounded-2xl overflow-x-auto sm:overflow-hidden">
                {isLoading ? (
                    <div className="flex justify-center items-center py-64">
                        <Lottie
                            animationData={loadingState}
                            loop={true}
                            className="lottie"
                        />
                    </div>
                ) : (
                    <Tabs
                        tabs={tabs}
                        onSearchChange={handleSearchChange}
                        onFilter={handleFilterQueryParam}
                    />
                )}
            </div>
            <Modal
                isVisible={bookAppointmentModal}
                onClose={toggleBookAppointmentModal}
                onSave={handleBookAppointment}
                buttonEnabled
                setButton
                saveText="Yes, book appointment"
                closeText="No"
                showCloseButtonBorder={false}
            >
                <div>
                    <div className="bg-white ">
                        <h1 className="text-3xl font-semibold leading-[32px] mb-6">
                            Book Appointment
                        </h1>
                        <p className="text-[14px] font-medium text-[#534341] w-full max-w-[400px] mb-5">
                            Please be aware that booking an appointment through
                            our system may incur a fee, which will automatically
                            be deducted from your wallet.
                        </p>
                        <span className="text-[14px] font-medium text-[#534341] w-[360px] ">
                            Would you like me to book the appointment?
                        </span>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ActiveState;
