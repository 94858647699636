import React from 'react';
import DashboardLayout from '../Dashboard/DashboardLayout';

import { Helmet } from 'react-helmet'
import UserManagement from '../../../components/PharmacyComponent/UserAdmin/UserManagement';


const Admin = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                   TMR | Admins
                </title>
            </Helmet>
            <div className="">
            <UserManagement />
            </div>
        </DashboardLayout>
    );
};

export default Admin;
