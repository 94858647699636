import PatientServiceAPI from '../utils/hospitalAuth/patientServiceApi';

export const patientService = {
    async getCountries() {
        const { data } = await PatientServiceAPI().get('/countries/activated');
        return data.payload.data;
    },
    editUserProfile: async (payload: any) => {
        return await PatientServiceAPI().put('/profile', payload);
    },
    addHospitalPatient: async (payload: any) => {
        return await PatientServiceAPI().post('/patient/hospital', payload);
    },
    getSinglePatient: async (tmrNumber: string) => {
        const { data } = await PatientServiceAPI().get(`/patient/${tmrNumber}`);
        return data.payload.data;
    },
    createHealthRequest: async (payload: {
        patientId: string;
        hospitalId: string;
        name: string;
        logo: string;
        tmrNumber: string;
        licenseNumber: string;
        address: string;
    }) => {
        return await PatientServiceAPI().post('/health-request', payload);
    },
    createHRwithEmNumber: async (payload: {
        tmrNumber: string;
        condition: [];
        result: string;
    }) => {
        return await PatientServiceAPI().post('/health-request', payload);
    },
    getEmergencyNumCondition: async () => {
        const { data } = await PatientServiceAPI().post(
            'health-request/condition'
        );
        return data.payload.data;
    },

    async getPatientSubscriptionplans(country) {
        const { data } = await PatientServiceAPI().get(
            `/plan?country=${country}`
        );
        return data.payload.data;
    },
    subscribe: async (payload: { planId: string; token: string }) => {
        return await PatientServiceAPI().post('/subscribe/hospital', payload, {
            headers: {
                Authorization: `Bearer ${payload.token}`,
            },
        });
    },

    createPatient: async (payload: any) => {
        return await PatientServiceAPI().post(
            '/patient/hospital',
            payload
        );
    },
    createPatientVoucher: async (payload: any) => {
        return await PatientServiceAPI().post(
            'patient/hospital?paymentMode=voucher',
            payload
        );
    },
    createMedicalReport: async (payload: any) => {
        return await PatientServiceAPI().post('/ehr/medical-report', payload);
    },

    createMedication: async (medicalReportId: any, payload: any) => {
        return await PatientServiceAPI().post(
            `/ehr/medication/${medicalReportId}`,
            payload
        );
    },

    getAllMedications: async (
        patientTmrNumber: string,
        appointmentId: string
    ) => {
        const { data } = await PatientServiceAPI().get(
            `/ehr/medication/hospital/${patientTmrNumber}?appointmentId=${appointmentId}`
        );
        return data.payload.data;
    },

    // ehr/medication/:medicalReportId
    getSingleMedicationDetails: async (
        tmrNumber: string,
        medicalReportId: any
    ) => {
        const { data } = await PatientServiceAPI().get(
            `/ehr/hospital/medication/details/${tmrNumber}/${medicalReportId}`
        );
        return data.payload.data;
    },
    // to get wallet balance
    getWallet: async (walletType: string) => {
        const { data } = await PatientServiceAPI().get(
            `wallet?type=${walletType}`
        );
        return data.payload.data;
    },
};

export default patientService;
