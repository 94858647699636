import React from 'react'
import { useNavigate } from 'react-router-dom'
import { SlArrowLeft } from 'react-icons/sl';
import RequestDrug from './RequestDrug';


function RequestDrugPage() {
    const navigate = useNavigate();
    const back = ()=>{
        navigate(-1)
      }
  return (
    <div className="md:mx-8 mx-1 mt-6">
            <div className="flex justify-between">
                <button
                   onClick={back}
                    className="flex items-center  cursor-pointer"
                >
                    <SlArrowLeft className="m-2" />
                    <h2 className="font-semibold text-base md:text-[28px] text-lightBrown">
                    Request Drug
                    </h2>
                </button>
                </div>
                    <RequestDrug/>
                </div>
  )
}

export default RequestDrugPage