import { useState, useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../dashboard/DashboardCard';
import Patient from '../../Images/patient-icon.svg';
import Doctors from '../../Images/doctor-icon.svg';
import Appointment from '../../Images/appointment-icon.svg';
import UpcomingAppointments from './UpcomingAppointment';
import Input from '../../reusable/Forms/Input';
import BlockButton from '../../reusable/BlockButton';
import hospitalService from '../../services/hospitalService';
import { hospitalRoutes } from '../../utils/routeNames';
import { useQuery } from 'react-query';

type SearchProps = {
    searchParam: string;
};

const AdminDashboard: React.FC = () => {
    const call = [
        {
            _id: '664f725f38950d90aadb61ce',
            appointment: {
                _id: '66610e9ed0f637a97ddd7e36',
                status: 'pending',
                appointmentDate: '2024-03-30T00:00:00.000Z',
                appointmentTime: '12:00 pm',
            },
            hostDoctor: {
                _id: '6596c243ac9236a0f3190199',
                firstName: 'Abiodun',
                lastName: 'Alao',
            },
        },
        {
            _id: '664f725f38950d90aadb61ce',
            appointment: {
                _id: '6661c7f4f8aef9e84bfae02a',
                status: 'pending',
                appointmentDate: '2024-03-30T00:00:00.000Z',
                appointmentTime: '12:00 pm',
            },
            hostDoctor: {
                _id: '6596c243ac9236a0f3190199',
                firstName: 'Abiodun',
                lastName: 'Alao',
            },
        },
    ];
    const navigate = useNavigate();
    const [dashboardData, setDashboardData] = useState<any>();
    const {
        formState: { isDirty, isValid },
        handleSubmit,
        control,
    } = useForm<SearchProps>();
    console.log(call);
    const patientData = {
        title: 'Patients',
        firstData: 'Male',
        firstDataValue: dashboardData?.patients.male,
        secondData: 'Female',
        secondDataValue: dashboardData?.patients.female,
        thirdData: 'Total',
        thirdDataValue: dashboardData?.patients.total,
        iconSrc: Patient,
    };

    const doctorData = {
        title: 'Doctors',
        firstData: 'Available',
        firstDataValue: dashboardData?.doctors.available,
        secondData: 'Unavailable',
        secondDataValue: dashboardData?.doctors.unavailable,
        thirdData: 'Total',
        thirdDataValue: dashboardData?.doctors.total,
        iconSrc: Doctors,
    };

    const appointmentData = {
        title: 'Appointment',
        firstData: 'Pending',
        firstDataValue: dashboardData?.appointments.pending,
        secondData: 'Completed',
        secondDataValue: dashboardData?.appointments.completed,
        thirdData: 'Total',
        thirdDataValue: dashboardData?.appointments.total,
        iconSrc: Appointment,
    };

    useEffect(() => {
        hospitalService
            .getDashboardData() // TODO: change to useQuery
            .then((res) => {
                setDashboardData(res);
            })
            .catch((err) => {
                toast.error(err);
            });
    }, []);

    const submitSearch: SubmitHandler<SearchProps> = (data) => {
        const { searchParam } = data;
        navigate(`/hospital/dashboard/record-search?query=${searchParam}`);
    };

    return (
        <div className="md:px-8 py-6 px-2 w-full">
            <h2 className="mb-6 font-semibold text-[28px]">Dashboard</h2>
            <div className="flex flex-row gap-4 w-full flex-wrap ">
                <div className="flex-1">
                    <div className="flex md:flex-row flex-col items-center justify-between gap-6">
                        <Card {...patientData} />
                        <Card {...doctorData} />
                        <Card {...appointmentData} />
                    </div>
                </div>

                <div className="w-full xl:w-[380px] 2xl:w-flex-1">
                    <div className="bg-white  p-4 rounded-2xl ">
                        <h6 className="text-dark text-xl font-bold mb-6">
                            Record Search
                        </h6>
                        <form onSubmit={handleSubmit(submitSearch)}>
                            <Controller
                                name="searchParam"
                                rules={{
                                    required: 'Search Parameter is required',
                                }}
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        label="Patient Name or TMR Number"
                                        {...field}
                                    />
                                )}
                            />

                            <BlockButton
                                text="View Record"
                                disabled={!isDirty || !isValid}
                                type="submit"
                                isLoading={false}
                                className="mt-5 p-3"
                            />
                        </form>
                    </div>
                </div>
            </div>
            <div className="flex flex-col xl:flex-row gap-4 w-full mt-8">
                <UpcomingAppointments />
                {/* <RecentTransaction /> */}
            </div>
        </div>
    );
};

export default AdminDashboard;
