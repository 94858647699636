import React, { useEffect } from 'react';
import { useState } from 'react';
import uploadedPrdt from '../../../../Images/PharmacyImages/upload.svg';
// import { useNavigate } from 'react-router-dom';
import Input from '../../../../reusable/Forms/Input';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
// import { useMutation, useQuery } from 'react-query';
// import hospitalService from '../../services/hospitalService';
import { toast, ToastContainer } from 'react-toastify';

import DrugNameModal from './InventoryModals/DrugNameModal';
import { useNavigate } from 'react-router-dom';
import { pharmacyRoutes } from '../../../../utils/pharmacyRouteNames';
import { useMutation, useQuery } from 'react-query';
import { pharmacyService } from '../../../../services/pharmacyService';
import { usePharmacyAuthStore } from '../../../../store/pharmacy/auth';
type InventoryDataProps = {
    drugId: string;
    quantity: number;
    price: number;
    pharmacyId: string;
};

function InventryDetailsForm() {
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [drugId, setDrugId] = useState<string | null>(null);
    const [isLoadin, setIsLoadin] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const userStore = usePharmacyAuthStore((state) => state.pharmacy);
    const navigate = useNavigate();
    const [quantity, setQuatity] = useState<string>('');

    const mutation = useMutation((data: any) =>
        pharmacyService.addInventory(data)
    );
    const [showModal, setShowModal] = useState(false);
    // const [details, setDetails] = useState<InventoryDataProps>({
    //     drugId: '',
    //     quantity: 0,
    //     price: 0,
    //     pharmacyId: '',
    // });

    const {
        formState: { isDirty, isValid },
        handleSubmit,
        control,
        reset,
        setValue,
    } = useForm<any>();

    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoadin(false);
            console.log('success', mutation.data);
            const response = mutation.data;
            const message = response.data.message;
            console.log('Success:', message);
            toast.success(message);
            setTimeout(() => {
                toast.dismiss(); // Dismiss the toast after 3000 milliseconds
                navigate(pharmacyRoutes.inventory);
            }, 2000);
        }
    }, [mutation.isSuccess, mutation.data, reset]);

    useEffect(() => {
        if (mutation.isError) {
            setIsLoadin(false);
            let err: any = mutation.error;
            console.log('is error', err);
            setErrorMessage(
                err.response?.data?.error?.message || 'Something went wrong'
            );
            toast.error(err.response?.data?.error?.message);
            reset({});
        } else {
            setErrorMessage('');
        }
    }, [mutation.isError, mutation.error]);

    const onSubmitForm: SubmitHandler<any> = (data) => {
        console.log('submit form', data);
        setIsLoadin(true);
        // const inventoryData = new FormData();
        // inventoryData.append('drugId', drugId);
        // inventoryData.append('quantity', data.quantity);
        // inventoryData.append('price', data.price);
        // inventoryData.append('pharmacyId', userStore?._id);

        const inventoryData: any = {
            drugId: drugId,
            quantity: data.quantity,
            price: data.price,
            pharmacyId: userStore?._id,
        };
        console.log(inventoryData);
        console.log(drugId);
        mutation.mutate(inventoryData);
    };

    //drugs to append
    const { data, isLoading, isError, refetch } = useQuery(
        ['drug-details', drugId],
        () => pharmacyService.getSingleDrug(drugId)
    );
    const drugDetails = data?.drug;
    console.log(drugDetails?.category?.name);
    console.log(drugDetails?.dosageForm);
    console.log(drugDetails);

    useEffect(() => {
        console.log(drugDetails);
        setValue('drugName', drugDetails?.name);
        setValue('dosage', drugDetails?.dosageForm);
        setValue('category', drugDetails?.category?.name);
        setValue(
            'doctorsReport',
            drugDetails?.doctorReportRequired === true ? 'Yes' : 'No'
        );
    }, [data, refetch]);

    const handleDrugSelection = (drug) => {
        refetch();
        if (drug?.images) {
            setSelectedImage(drug?.images);
        }
        setDrugId(drug?._id);
        setShowModal(false);
        // getDrugDetails(drugId);
    };
    const handleClearAll = () => {
        reset({
            drugName: '',
            quantity: '',
            price: '',
        });
        setSelectedImage(null);
    };
    const handlePreview = () => {
        // const previewData: any = {
        //     images: drugDetails?.images,
        //     drugName: previews?.drugName,
        //     category: drugDetails?.category?.name,
        //     quantity: previews?.quantity,
        //     price: previews?.price,
        //     description: drugDetails?.description,
        //     sideEffect: drugDetails?.sideEffects,
        //     dosage: drugDetails?.dosageForm,
        //     doctorsReport:
        //         drugDetails?.doctorReportRequired === true ? 'Yes' : 'No',
        // };
        // sessionStorage.setItem('preview', JSON.stringify(previewData));
        // console.log(previewData);
        // setTimeout(() => {
        //     sessionStorage.removeItem('preview');
        // }, 5000);
        navigate(`${pharmacyRoutes.previewInventory}/add`);
    };

    const styles = {
        // backgroundColor: '#362F2E',
        fontSize: '12px',
        color: '#FBEEEC',
        padding: '2px',
    };
    return (
        <div className="flex flex-col md:flex-row justify-between md:space-x-6 mt-10 md:mt-4">
            <ToastContainer />
            <div className="flex justify-center flex-col items-center mt-10 md:mt-0 bg-white p-4 h-[317px] rounded-[20px] lg:w-[358px]">
                <h2 className="text-[22px] font-semibold text-lightBrown bg-lightPink p-4 rounded-t-[20px]">
                    Product Picture(s)
                </h2>

                <div className="flex items-center justify-center w-full flex-col mt-5">
                    {selectedImage ? (
                        <img
                            src={selectedImage}
                            alt="Selected"
                            className="w-full h-[150px] object-contain rounded-full "
                        />
                    ) : (
                        <>
                            <img
                                src={uploadedPrdt}
                                alt="Default"
                                className="w-full h-[150px] object-contain"
                            />
                            <p className="w-full pt-2 col-span-2">
                                Images will be added after selecting a drug
                            </p>
                        </>
                    )}
                </div>
            </div>
            <div className="flex-1 bg-white p-4 rounded-2xl mt-10 md:mt-0">
                <h2 className="text-[22px] font-semibold text-lightBrown text-center p-4 bg-lightPink">
                    Product Details{' '}
                </h2>
                <form onSubmit={handleSubmit(onSubmitForm)} noValidate>
                    <div className="flex flex-row mt-10">
                        <Controller
                            name="drugName"
                            defaultValue=""
                            rules={{
                                required: 'Drug Name is required',
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    label="Drug Name"
                                    {...field}
                                    onClick={() => setShowModal(true)}
                                />
                            )}
                        />
                    </div>
                    <div className="w-full py-2 mt-5">
                        <Controller
                            name="doctorsReport"
                            defaultValue={
                                drugDetails?.doctorReportRequired === true
                                    ? 'Yes'
                                    : 'No'
                            }
                            disabled
                            control={control}
                            rules={{
                                required: "Doctor's Report is required",
                            }}
                            render={({ field }) => (
                                <FormControl
                                    fullWidth
                                    sx={{ minWidth: 120 }}
                                    size="small"
                                >
                                    <InputLabel
                                        id="demo-select-small-label"
                                        htmlFor={'doctorsReport'}
                                    >
                                        {'Doctors Report'}
                                    </InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        defaultValue={
                                            drugDetails?.doctorReportRequired ===
                                            true
                                                ? 'Yes'
                                                : 'No'
                                        }
                                        label="Doctors Report (optional)"
                                        {...field}
                                        className="w-full p-2"
                                    >
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        />
                    </div>
                    <div className="flex items-center my-6 space-x-6">
                        <Controller
                            name="dosage"
                            defaultValue=""
                            disabled
                            rules={{
                                required: 'Dosage is required',
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input label="Dosage" {...field} />
                            )}
                        />
                        <Controller
                            name="category"
                            defaultValue=""
                            disabled
                            rules={{
                                required: 'Category is required',
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input label="Category" {...field} />
                            )}
                        />
                        {/* <Controller
                            name="dosage"
                            control={control}
                            defaultValue={drugDetails?.dosageForm || 'Okay'}
                            disabled
                            render={({ field }) => (
                                <FormControl
                                    fullWidth
                                    sx={{ minWidth: 120 }}
                                    size="small"
                                >
                                    <InputLabel
                                        id="demo-select-small-label"
                                        htmlFor={'dosage'}
                                    >
                                        {'Dosage'}
                                    </InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        label="Dosage"
                                        defaultValue={
                                            drugDetails?.dosageForm || 'Okay'
                                        }
                                        {...field}
                                        className="w-full p-2"
                                    >
                                        <MenuItem
                                            value={drugDetails?.dosageForm}
                                        >
                                            {drugDetails?.dosageForm}
                                        </MenuItem>
                                        {/* <MenuItem value="Liquid">
                                            Liquid
                                        </MenuItem>
                                        <MenuItem value="Capsule">
                                            Capsule
                                        </MenuItem>
                                        <MenuItem value={'Tablet'}>
                                            Tablet
                                        </MenuItem> */}
                        {/* </Select>
                                </FormControl>
                            )} */}
                        {/* /> */}
                        {/* <Controller
                            name="category"
                            control={control}
                            defaultValue={drugDetails.category.name}
                            disabled
                            render={({ field }) => (
                                <FormControl
                                    fullWidth
                                    sx={{ minWidth: 120 }}
                                    size="small"
                                >
                                    <InputLabel
                                        id="demo-select-small-label"
                                        htmlFor={'category'}
                                    >
                                        {'Category'}
                                    </InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        defaultValue={drugDetails.category.name}
                                        label="Category"
                                        {...field}
                                        className="w-full p-2"
                                    >
                                        <MenuItem
                                            value={drugDetails.category.name}
                                        >
                                            {drugDetails.category.name}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        /> */}
                    </div>
                    <div className="flex items-center flex-col sm:flex-row my-6 gap-6">
                        <Controller
                            name="price"
                            defaultValue=""
                            rules={{
                                required: 'Price is required',
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input label="Price (₦)" {...field} />
                            )}
                        />
                        <Controller
                            name="quantity"
                            defaultValue=""
                            rules={{
                                required: 'Quantity is required',
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    // onChange={(value) => {
                                    //     setQuatity(value);
                                    // }}
                                    label="Quantity "
                                    {...field}
                                />
                            )}
                        />
                    </div>
                    {/* <div className="flex items-center my-6 space-x-6">
            <Controller
                    name="productionDate"
                    defaultValue=""
                    rules={{
                        required: 'Production Date is required',
                    }}
                    control={control}
                    render={({ field }) => (
                        <Input label="Production Date" {...field} />
                    )}
                />
                <Controller
                    name="expiryDate"
                    defaultValue=""
                    rules={{
                        required: 'Expiry Date is required',
                    }}
                    control={control}
                    render={({ field }) => (
                        <Input label="Expiry Date" {...field} />
                    )}
                />
            </div>
           
            <div className="flex items-center flex-col lg:flex-row space-x-6 my-10">
             

                
            <Controller
                name="minStock"
                defaultValue=""
                rules={{ required: 'Minimum Stock Level is required' }}
                control={control}
                render={({ field }) => (
                    <TextField
                        className="w-full"
                        type="number"
                        {...field}
                        label="Minimum Stock Level"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Tooltip
                                        title={
                                            <div style={styles}>
                                                Notify me when stock is 
                                                <br />
                                                below this number
                                                
                                            </div>
                                        }
                                    >
                                        <InfoCircle size="20" color="#534341" />
                                    </Tooltip>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            />
           
<div className='flex items-center justify-center '>
    <p className='border-b-2 p-2 text-[#534341]'></p>
</div>
<div className='lg:w-[473px]'>
                <Controller
                    name="maxStock"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                        <Input label="Maximum Stock Level" {...field} />
                    )}
                />
                </div>
            </div>
            <Controller
                        name="manufacturerNumber"
                        defaultValue=""
                        rules={{
                            required: 'Manufacturer information  is required',
                        }}
                        control={control}
                        render={({ field }) => (
                            <Input
                                label="Manufacturer information "
                                {...field}
                            />
                        )}
                    /> */}
                    <Controller
                        name="sideEffect"
                        control={control}
                        disabled
                        defaultValue={drugDetails?.sideEffects}
                        rules={{
                            required: 'side effect information  is required',
                        }}
                        render={({ field }) => (
                            <textarea
                                placeholder="Side Effects"
                                rows={6}
                                disabled
                                defaultValue={drugDetails?.sideEffects}
                                required={true}
                                className="w-full h-[100px] my-6 border-[1px] border-dark bor p-4 rounded-[8px] text-[16px] tracking-wider focus:border-[1px] focus:outline-none focus:border-dark100"
                                {...field}
                            ></textarea>
                        )}
                    />
                    <Controller
                        name="description"
                        control={control}
                        disabled
                        defaultValue={drugDetails?.description}
                        rules={{
                            required: 'Description information  is required',
                        }}
                        render={({ field }) => (
                            <textarea
                                placeholder="Description"
                                rows={6}
                                required={true}
                                disabled
                                defaultValue={drugDetails?.description}
                                className="w-full h-[100px] mb-6 border-[1px] border-dark100 p-4 rounded-[8px] text-[16px] tracking-wider focus:border-[1px] focus:outline-none focus:border-dark100"
                                {...field}
                            ></textarea>
                        )}
                    />

                    <div className="flex justify-end space-x-6 py-3 border-t mt-10">
                        <button
                            type="reset"
                            className="py-2 px-3 text-Tmred font-normal text-base"
                            onClick={handleClearAll}
                        >
                            Clear All
                        </button>
                        <button
                            disabled={!isDirty || !isValid}
                            className={`py-2 px-3 text-Tmred  text-base border rounded-lg   border-[#857371] ${
                                !isDirty || !isValid
                                    ? 'cursor-not-allowed font-normal'
                                    : ' hover:font-semibold hover:cursor-pointer'
                            }`}
                            onClick={handlePreview}
                        >
                            Preview
                        </button>
                        <button
                            disabled={!isDirty || !isValid}
                            // onClick={handleSubmit(onSubmitForm)}
                            type="submit"
                            className={` px-6 py-2 text-lightBrown text-base font-normal rounded-lg 
                     ${
                         !isDirty || !isValid || mutation.isLoading
                             ? 'bg-lightGray cursor-not-allowed'
                             : ' bg-Tmred text-white hover:cursor-pointer'
                     }
                    `}
                        >
                            {mutation.isLoading ? 'Saving...' : 'Save Product'}
                        </button>
                    </div>
                </form>
            </div>
            <DrugNameModal
                showModal={showModal}
                onClose={() => setShowModal(false)}
                onSave={handleDrugSelection}
                buttonEnabled={true}
                setButton={() => {}}
                // formData={{}}
                // handleChange={() => {}}
            />
        </div>
    );
}

export default InventryDetailsForm;
