import ProfileDetailsForm from '../../../components/PharmacyComponent/Settings/MyProfile/ProfileDetailsForm';
import DashboardLayout from '../Dashboard/DashboardLayout';
import {Helmet} from 'react-helmet'


const EditPersonalProfile = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Edit Profile
                </title>
            </Helmet>
            <ProfileDetailsForm />
        </DashboardLayout>
    );
};
export default EditPersonalProfile;
