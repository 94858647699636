import PatientFiles from '../../../components/Appointment/PatientAppointment/PatientFiles';
import DashboardLayout from '../Dashboard/DashboardLayout';

const PatientFile = () => {
    return (
        <DashboardLayout>
            <PatientFiles />
        </DashboardLayout>
    );
};
export default PatientFile;
