import React from 'react';
import Copy from '../../Images/copy.svg';
import { toast } from 'react-toastify';
import { useHospitalAuthStore } from '../../store/hospital/auth';
import { standardAmountFormat } from '../../utils/helpers';

type VoucherCardProps = {
    code: string;
    amount: number;
    show?: boolean;
    bg?: boolean;
};
const VoucherCard: React.FC<VoucherCardProps> = ({
    code,
    amount,
    show,
    bg = false,
}) => {
    const currency = useHospitalAuthStore((state) => state.hospital.currency);

    // Split the formatted amount to get currency and amount separately
    const formattedAmount = standardAmountFormat(amount || 0, currency);
    const [formattedCurrency, formattedValue] = formattedAmount.split(' ');

    return (
        <div
            className={`border-[1px] h-fit border-solid p-2 rounded-lg bg-white ${
                bg ? 'border-Tmred' : 'border-[#F5F5F5]'
            }`}
        >
            <div
                className={`p-2 rounded ${
                    bg ? 'bg-[#FFDAD5]' : 'bg-[#F5F5F5]'
                }`}
            >
                <div className="flex justify-between">
                    <p className="text-Tmred text-[0.75rem] ">{code}</p>
                    {show ? (
                        <div
                            className="rounded-[50%] cursor-pointer hover:bg-[#F5F5F550]"
                            onClick={() => {
                                navigator.clipboard.writeText(code);
                                toast.success(
                                    'Voucher code copied successfully'
                                );
                            }}
                        >
                            <img src={Copy} alt="copy-icon" />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                <p className="text-black font-[600] text-[1.375rem] leading-7">
                    {/* {standardAmountFormat(amount || 0, currency)} */}
                    {formattedCurrency}
                    <br />
                    {formattedValue}
                </p>
            </div>
        </div>
    );
};

export default VoucherCard;
