import React, { Fragment, useEffect, useState } from 'react';
import { BsChevronLeft } from 'react-icons/bs';
import DashboardLayout from '../Dashboard/DashboardLayout';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import VoucherModal from '../../../components/Voucher/Modals/VoucherModal';
import VoucherCard from '../../../components/Voucher/VoucherCard';
import VoucherEyeOpen from '../../../Images/VoucherEyeOpen';
import VoucherEye from '../../../Images/VoucherEye';
import { adminService } from '../../../services/adminService';
import { toast } from 'react-toastify';
import Input from '../../../reusable/Forms/Input';
import { formatNumber } from '../../../utils/helpers';
import { PatientPlan1, PatientPlan2 } from '../../../Images/Svgs';
import CurrencyFormat from '../../../utils/Currency';
import { useMutation } from 'react-query';
import patientService from '../../../services/patientService';
import { hospitalRoutes } from '../../../utils/routeNames';

const VoucherPayment = () => {
    const navigate = useNavigate();
    const [voucherData, setVoucherData] = useState<any>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showCode, setShowCode] = useState<boolean>(false);
    const [showCode2, setShowCode2] = useState<boolean>(false);
    const [onOpen, setOnOpen] = useState<boolean>(false);
    const [formValid, setFormValid] = useState<boolean>(false);
    const [isSelectVoucher, setSelectedVoucher] = useState<boolean>(false);
    const [active, setActive] = useState();
    const toggleShowCode = () => {
        setShowCode(!showCode);
    };
    const toggleShowCode2 = () => {
        setShowCode2(!showCode2);
    };
    const setModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };
    const [formData, setformData] = useState<any>({
        voucherCode: '',
    });
    const plan = sessionStorage.getItem('plan');
    let plans;
    if (plan !== null) {
        plans = JSON.parse(plan);
    }

    const form = sessionStorage.getItem('finalData');
    let formsData;
    if (form !== null) {
        formsData = JSON.parse(form);
    }
    console.log(formsData);

    const mutation = useMutation((data: any) =>
        patientService.createPatientVoucher(data)
    );
    useEffect(() => {
        if (formData.voucherCode !== '') {
            setFormValid(true);
        } else {
            setFormValid(false);
        }
    }, [formData.voucherCode]);
    useEffect(() => {
        adminService
            .getAllVoucher('hospital') // Get All voucher
            .then((res) => {
                console.log(res);
                setVoucherData(res);
            })
            .catch((err) => {
                toast.error(err);
            });
    }, []);
    let filteredVoucher = voucherData?.vouchers?.filter((voucher) => {
        return voucher.status === 'unused';
    });
    console.log(filteredVoucher);
    const handleChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
    ) => {
        const { name, value } = e.target;
        setformData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const selectVoucher = (vouch: any) => {
        setformData(() => ({
            voucherCode: vouch.code,
            amount: vouch.amount,
        }));
    };
    console.log(plans?.features);
    const PlanImage =
        plans.name === 'Basic' ? <PatientPlan1 /> : <PatientPlan2 />;

    const handleSubmit = (e: any) => {
        e.preventDefault();
        console.log('kkkk');
        if (plans?._id !== null) {
            const finalData = {
                ...formsData,
                voucherCode: formData.voucherCode,
                phoneNumber: formsData?.phone_number,
            };
            delete formsData.phone_number;
            console.log('submitted data', finalData);
            mutation.mutate(finalData, {
                onSuccess: (data) => {
                    console.log(
                        'Patient successfully created using voucher payment',
                        data
                    );
                    toast.success(
                        'Patient successfully created using voucher payment'
                    );
                    setTimeout(() => {
                        toast.dismiss();
                        navigate(hospitalRoutes.patient);
                    }, 3000);
                },
                onError: (error: any) => {
                    console.error(
                        'Error creating patient:',
                        error.response.data.error.message
                    );
                    toast.error(error.response.data.error.message);
                    setTimeout(() => {
                        toast.dismiss();
                    }, 3000);
                },
            });
        }
    };

    return (
        <Fragment>
            <Helmet>
                <title>TMR | Payment with Voucher</title>
            </Helmet>

            <DashboardLayout>
                <div className="my-4 mx-1 sm:mx-6">
                    <div
                        className="flex gap-4 items-center cursor-pointer"
                        onClick={() => navigate(-1)}
                    >
                        <BsChevronLeft className="text-dark100 w-4 h-4 " />
                        <h1 className="font-semibold md:text-[28px] text-lg  text-lightBrown">
                            Payment using Voucher
                        </h1>
                    </div>

                    <div className="flex justify-between gap-6 sm:gap-5 mt-12 ">
                        <div className="flex flex-col w-[59%] bg-white rounded-2xl p-4">
                            <div className="px-4 relative py-4 w-[100%] rounded-xl bg-white md:overflow-hidden gap-4">
                                <div className="h-fit flex justify-between items-center">
                                    <div className="flex gap-2 items-center">
                                        <h2 className="text-black font-bold flex gap-2 text-md md:text-xl">
                                            Unused Vouchers
                                            <span className="md:text-[16px] text-sm bg-red py-[3px] w-[30px] h-[30px] text-white px-1 md:px-[8px] flex justify-center items-center rounded-[290.909px]">
                                                {filteredVoucher?.length}
                                            </span>
                                        </h2>
                                        <button onClick={toggleShowCode}>
                                            {showCode ? (
                                                <VoucherEyeOpen />
                                            ) : (
                                                <VoucherEye />
                                            )}
                                        </button>
                                    </div>
                                    <button
                                        className="hover:underline"
                                        onClick={setModal}
                                    >
                                        See all
                                    </button>
                                </div>
                                {filteredVoucher?.length !== 0 ? (
                                    <div className="h-[fit p-[0.1rem] overflow-y-hidden rounded-xl grid grid-cols-4 gap-2 mt-2">
                                        {filteredVoucher?.map((vouch, i) => (
                                            <button
                                                key={i}
                                                type="button"
                                                onClick={() => {
                                                    setActive(i);
                                                    selectVoucher(vouch);
                                                    setSelectedVoucher(true);
                                                }}
                                            >
                                                <VoucherCard
                                                    code={
                                                        showCode
                                                            ? `${vouch.code}`
                                                            : '********'
                                                    }
                                                    show={showCode}
                                                    amount={vouch.amount}
                                                    bg={
                                                        isSelectVoucher &&
                                                        i === active
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </button>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="h-[82%] rounded-xl flex justify-center items-center bg-[#F8F8F8] mt-2">
                                        <p className="text-sm font-medium text-dark100">
                                            Your available vouchers will appear
                                            here.
                                        </p>
                                    </div>
                                )}

                                <VoucherModal
                                    isVisible={showModal}
                                    onClose={() => closeModal()}
                                    header={'Available Voucher'}
                                    headerContent={
                                        <button onClick={toggleShowCode2}>
                                            {showCode2 ? (
                                                <VoucherEyeOpen />
                                            ) : (
                                                <VoucherEye />
                                            )}
                                        </button>
                                    }
                                    close={true}
                                >
                                    <div className="w-[32rem] h-[23rem] overflow-y-auto rounded-xl grid grid-cols-3 justify-start gap-4 mt-2">
                                        {filteredVoucher?.map((vouch, i) => (
                                            <button
                                                key={i}
                                                type="button"
                                                onClick={() => {
                                                    setActive(i);
                                                    selectVoucher(vouch);
                                                    setSelectedVoucher(true);
                                                }}
                                                className="h-fit"
                                            >
                                                <VoucherCard
                                                    code={
                                                        showCode2
                                                            ? `${vouch.code}`
                                                            : '********'
                                                    }
                                                    show={showCode2}
                                                    amount={vouch.amount}
                                                    bg={
                                                        isSelectVoucher &&
                                                        i === active
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </button>
                                        ))}
                                    </div>
                                </VoucherModal>
                            </div>
                            <form
                                action=""
                                className="flex flex-col gap-6 mt-4"
                            >
                                <Input
                                    type="text"
                                    name="voucherCode"
                                    onChange={handleChange}
                                    value={formData.voucherCode}
                                    label="Voucher Code"
                                    className="py-4 rounded-lg border border-[#534341] pl-4 outline-none mt-4 placeholder:text-sm font-normal w-full"
                                />
                                {/* <Input
                                    type="text"
                                    name="amount"
                                    onChange={handleChange}
                                    value={formData.amount}
                                    label="Amount"
                                    disabled
                                    className="py-4 rounded-lg border border-[#534341] pl-4 outline-none mt-4 placeholder:text-sm font-normal w-full"
                                /> */}
                                <button
                                    onClick={handleSubmit}
                                    disabled={!formValid}
                                    className={`mt-4 py-[10px] px-6 rounded-lg w-full  ${
                                        !formValid
                                            ? 'bg-[#1D1B201F] text-[#999797] cursor-not-allowed'
                                            : 'bg-Tmred text-white hover:cursor-pointer '
                                    } `}
                                >
                                    {'Use voucher'}
                                </button>
                            </form>
                        </div>
                        <div className="flex flex-col w-[39%] bg-white rounded-2xl p-4">
                            <div
                                key={plans?._id}
                                className={`flex flex-col border bg-[#FFFFFF] border-[#FFDAD5] items-center justify-center rounded-[16px]  py-[40px] px-[20px] mt-7 w-full flex-wrap`}
                            >
                                <div className="flex items-center justify-center py-1">
                                    {PlanImage}
                                </div>
                                <span
                                    className={`font-semibold mt-2 text-center text-[14px] p-2  rounded-[8px] w-fit
                                                bg-[#FFDAD5] text-[#534341]`}
                                >
                                    {plans?.name} Plan
                                </span>
                                <p className="text-center text-dark100 ">
                                    {plans?.type}
                                </p>

                                <div className="flex justify-center mt-6 text-[36px] font-semibold flex-wrap">
                                    <CurrencyFormat amount={plans?.amount} />
                                </div>
                                <span className="flex justify-center text-black100 text-center">
                                    Annually
                                </span>

                                <div className="text-dark100 bg-[#F8F8F8] my-8  p-2  ">
                                    {Object.keys(plans.features).map(
                                        (key, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={`border-b border-[#D8C2BF] p-3 ${
                                                        index ===
                                                        Object.keys(
                                                            plans.features
                                                        ).length -
                                                            1
                                                            ? 'border-b-0'
                                                            : ''
                                                    } flex items-center flex-wrap justify-between text-left gap-5 mb-5 `}
                                                >
                                                    {/* <div>
                                                        <img
                                                            src={tickCircle}
                                                            alt=""
                                                            className=""
                                                        />
                                                    </div> */}
                                                    <div className="flex-1">
                                                        <p className="text-[14px] font-medium text-[#534341] break-words break-all">
                                                            {
                                                                plans.features[
                                                                    key
                                                                ].label
                                                            }
                                                        </p>
                                                    </div>

                                                    <div className="flex-shrink-0">
                                                        <p className="text-[14px] font-medium text-[#534341]">
                                                            {
                                                                key ===
                                                                'canAccessPrivateHospital'
                                                                    ? plans
                                                                          .features[
                                                                          key
                                                                      ].value
                                                                        ? 'yes'
                                                                        : 'no'
                                                                    : typeof plans
                                                                            .features[
                                                                            key
                                                                        ]
                                                                            .value ===
                                                                        'number'
                                                                      ? formatNumber(
                                                                            plans
                                                                                .features[
                                                                                key
                                                                            ]
                                                                                .value
                                                                        ) // Format number with commas
                                                                      : plans
                                                                            .features[
                                                                            key
                                                                        ].value // Render other values as is
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DashboardLayout>
        </Fragment>
    );
};

export default VoucherPayment;
