import React from 'react';
import { ToastContainer } from 'react-toastify';
import drug1 from './../../../../Images/PharmacyImages/drug2.svg';
import CurrencyFormat from '../../../../utils/Currency';
import { useParams } from 'react-router-dom';

function PreviewDetails() {
    const { slug } = useParams();
    const previews = sessionStorage.getItem('preview');
    const previewData = previews !== null ? JSON.parse(previews) : '';
    console.log(previewData);

    // typeof previewData === string ? console.log(JSON.parse(previewData)) : '';
    // const selectedImages = previewData?.images;

    return (
        <div className="flex flex-col md:flex-row justify-between md:space-x-6 bg-white mt-10 md:mt-4 ">
            <ToastContainer />

            <div className="flex h-fit justify-center flex-col items-center mt-10 md:mt-10 bg-[#F6F6F7] md:p-4 rounded-[20px] lg:w-[358px]">
                <div className="flex items-center justify-center w-full flex-col mt-2 mb-5 lg:w-[328px]">
                    <div className="bg-white rounded-2xl w-full m-2">
                        <img
                            src={previewData?.images[0]}
                            alt="Default"
                            className="w-full h-[350px] object-contain "
                        />
                    </div>
                    <div className="overflow-x-auto flex space-x-1 w-full  mt-5 gap-2 h-[150px]">
                        {previewData?.images.length > 0 &&
                            previewData?.images?.map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    alt={`Selected ${index}`}
                                    className="w-[7rem] h-full object-cover rounded-lg bg-white"
                                />
                            ))}
                    </div>
                </div>
            </div>
            <div className="flex-1 bg-white p-4 rounded-2xl mt-10 md:mt-0 lg:w-[490px]">
                <h2 className="text-[22px] lg:text-[28px] font-semibold text-lightBrown text-left ">
                    {previewData?.drugName}
                </h2>
                <h3 className="text-Tmred font-semibold text-[22px]">
                    <CurrencyFormat amount={previewData?.price} />
                </h3>
                <div className="border-[#D8C2BF] border-b mx-auto my-5"></div>
                <div className="lg:w-[490px]">
                    <h3 className="font-medium text-[16px] text-[#201A19] my-5">
                        Quantity
                    </h3>
                    <ul className="list-none flex flex-col lg:flex-row gap-10">
                        <li className="text-[14px] font-normal">
                            <span className="font-bold">Total Stock: </span>
                            {previewData?.quantity}
                        </li>
                        {slug === 'edit' ? (
                            <li className="text-[14px] font-normal">
                                <span className="font-bold">Sold Stock: </span>
                                {previewData?.soldCount}
                            </li>
                        ) : (
                            ''
                        )}
                    </ul>
                </div>
                <div className="border-[#D8C2BF] border-b mx-auto my-5"></div>
                <div className="my-5 lg:w-[490px]">
                    <h2 className="font-medium text-[16px] text-[#201A19] my-5">
                        Specifications
                    </h2>
                    <ul className="list-disc flex flex-col ml-4 list-inside gap-3">
                        <li className="text-[14px] font-normal">
                            <span className="font-bold">Doctors Report </span>
                            {previewData?.doctorsReport}
                        </li>{' '}
                        <li className="text-[14px] font-normal">
                            <span className="font-bold">Category </span>
                            {previewData?.drugName}
                        </li>{' '}
                        <li className="text-[14px] font-normal">
                            <span className="font-bold">Dosage Form </span>
                            {previewData?.dosage}
                        </li>
                    </ul>
                </div>
                <div className="border-[#D8C2BF] border-b mx-auto my-5"></div>
                <div className="my-5 lg:w-[490px]">
                    <h2 className="font-medium text-[16px] text-[#201A19] my-5">
                        Side Effect
                    </h2>
                    <p>{previewData?.sideEffect}</p>
                </div>
                <div className="border-[#D8C2BF] border-b mx-auto my-5"></div>
                <div className="my-5 lg:w-[490px]">
                    <h2 className="font-medium text-[16px] text-[#201A19] my-5">
                        Description
                    </h2>

                    <p>{previewData?.description}</p>
                </div>
            </div>
        </div>
    );
}

export default PreviewDetails;
