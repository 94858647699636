import HospitalServiceAPI from '../utils/hospitalAuth/hospitalServiceAPI';

import PharmacyServiceAPI from '../utils/pharmacyAuth/pharmacyServiceAPI';

export const pharmacyService = {
    createPharmacy: async (payload: any) => {
        return await PharmacyServiceAPI().post('/auth/pharmacy', payload);
    },
    createPharmacyUser: async ({
        pharmacyFormData,
        token,
    }: {
        pharmacyFormData: any;
        token: string;
    }) => {
        return await PharmacyServiceAPI().post(
            '/auth/pharmacy/user',
            pharmacyFormData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    },
    verifyOTP: async (payload: any) => {
        return await PharmacyServiceAPI().post(
            '/auth/pharmacy/verify',
            payload
        );
    },
    resendOTP: async (payload: { email: string }) => {
        return await PharmacyServiceAPI().post('/auth/pharmacy/otp', payload);
    },
    validateTmrId: async (payload: { tmrId: string }) => {
        return await PharmacyServiceAPI().post('/auth/hospital/tmrId', payload);
    },
    login: async (payload: {
        email: string;
        password: string;
        pharmacyId: string;
    }) => {
        return await PharmacyServiceAPI().post('/auth/pharmacy/login', payload);
    },
    forgotPassword: async (payload: { email: string; pharmacyId: string }) => {
        return await PharmacyServiceAPI().post(
            '/auth/pharmacy/password/forgot',
            payload
        );
    },
    verifyForgotPasswordOTP: async (payload: {
        email: string;
        otp: string;
    }) => {
        return await PharmacyServiceAPI().post(
            '/auth/pharmacy/password/verify',
            payload
        );
    },
    resetPassword: async ({
        newPassword,
        token,
    }: {
        newPassword: string;
        token: string;
    }) => {
        return await PharmacyServiceAPI().post(
            '/auth/pharmacy/password/reset',
            {
                newPassword,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    },
    createPasscode: async (payload: { code: string }, token: string) => {
        return await PharmacyServiceAPI().post('/pharmacy/passcode', payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    },
    verifyPasscode: async (payload: { code: string; token: string }) => {
        return await PharmacyServiceAPI().post(
            '/pharmacy/passcode/verify',
            payload,
            {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                },
            }
        );
    },

    changePasscode: async (payload: { code: string; token: string }) => {
        return await PharmacyServiceAPI().put(
            '/pharmacy/passcode/update',
            payload,
            {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                },
            }
        );
    },
    changePassword: async (payload: {
        oldPassword: string;
        token: string;
        newPassword: string;
        confirmPassword: string;
    }) => {
        return await PharmacyServiceAPI().put(
            '/pharmacy/user/password',
            payload
        );
    },
    editUserProfile: async (payload: any) => {
        return await PharmacyServiceAPI().put('/pharmacy/user/me', payload);
    },
    editPharmacyProfile: async (payload: any) => {
        return await PharmacyServiceAPI().put('/pharmacy', payload);
    },
    editPharmacyMedia: async (payload: any) => {
        return await PharmacyServiceAPI().put('/pharmacy/media', payload);
    },
    deletePharmacyMedia: async (payload: any) => {
        return await PharmacyServiceAPI().delete('/pharmacy/media', {
            data: {
                image_urls: payload.image_urls,
            },
        });
    },
    getBanks: async () => {
        const { data } = await PharmacyServiceAPI().get('/wallet/banks');
        return data.payload.data;
    },
    addBankDetails: async (
        payload: {
            bankName: string;
            accountNumber: string;
            bankCode: string;
        },
        token: string
    ) => {
        return await PharmacyServiceAPI().post(
            '/wallet/pharmacy/bank?type=pharmacy',
            payload,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    },
    resolveBankAccount: async (payload: {
        accountNumber: string;
        bankCode: string;
    }) => {
        const { data } = await PharmacyServiceAPI().post(
            'wallet/bank/resolve',
            payload
        );
        return data.payload.data;
    },

    getUserSubscriptions: async () => {
        const { data } = await PharmacyServiceAPI().get('/pharmacy/subscribe');
        return data.payload.data;
    },

    async plans(country) {
        const { data } = await PharmacyServiceAPI().get(
            `/pharmacy/plan?country=${country}`
        );
        return data.payload.data;
    },
    subscribe: async (payload: { planId: string; token: string }) => {
        return await PharmacyServiceAPI().post('/pharmacy/subscribe', payload, {
            headers: {
                Authorization: `Bearer ${payload.token}`,
            },
        });
    },
    getActiveSubscription: async (planId: string, token: string) => {
        const { data } = await PharmacyServiceAPI().get(
            `/pharmacy/subscribe/${planId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return data.payload.data;
    },

    getOrder: async (
        page: number,
        search: string = '',

        status: string = '',
        order: string = '',
        from: string = '',
        to: string = ''
    ) => {
        const { data } = await PharmacyServiceAPI().get(
            `order?page=${page}&limit=10&search=${search}&status=${status}&order=${order}&from=${from}&to=${to}`
        );
        return data.payload.data;
    },
    filterAppointment: async (query?: string) => {
        const { data } = await PharmacyServiceAPI().get(
            `appointment?service=hospital${query}`
        );
        return data.payload.data;
    },
    addInventory: async (payload: {
        quantity: number;
        price: number;
        pharmacyId: string;
    }) => {
        return await PharmacyServiceAPI().post('/inventory', payload);
    },
    updateInventory: async (
        inventoryId,
        payload: {
            quantity: number;
            price: number;
            pharmacyId: string;
        }
    ) => {
        return await PharmacyServiceAPI().put(
            `/inventory/${inventoryId}`,
            payload
        );
    },
    getInventory: async (
        page: number,
        dosageForm: string = '',
        search: string = ''
    ) => {
        const { data } = await PharmacyServiceAPI().get(
            `/inventory?search=${search}&dosageForm=${dosageForm}&page=${page}&limit=10`
        );
        return data.payload.data;
    },
    getSingleInventory: async (drugId) => {
        const { data } = await PharmacyServiceAPI().get(`/inventory/${drugId}`);
        return data.payload.data;
    },
    // deleteInventory: async (inventoryId) => {
    //     const { data } = await PharmacyServiceAPI().delete(
    //         `/admin/drug/${inventoryId}`
    //     );
    // },
    getDrug: async (search = '') => {
        const { data } = await HospitalServiceAPI().get(
            `/drug?page=1&limit=10&search=${search}`
        );
        return data.payload.data;
    },
    getSingleDrug: async (drugId) => {
        const { data } = await HospitalServiceAPI().get(`/drug/${drugId}`);
        return data.payload.data;
    },
    createOrder: async (payload) => {
        return await PharmacyServiceAPI().post('/order', payload, {
            // headers: {
            //     'Content-Type': 'multipart/form-data',
            // },
        });
    },
    getSingleOrder: async (id: string) => {
        const { data } = await PharmacyServiceAPI().get(`order/${id}`);
        return data.payload.data;
    },
    getAllDrugs: async () => {
        const { data } = await PharmacyServiceAPI().get('/inventory');
        return data.payload.data;
    },
    completeOrder: async (id: string) => {
        return await PharmacyServiceAPI().put(`order/${id}/?action=completed`);
    },
    cancelOrder: async (id: string, payload: any) => {
        return await PharmacyServiceAPI().put(
            `order/${id}/?action=cancel`,
            payload
        );
    },

    fundWallet: async (payload: { amount: string }, walletType) => {
        return await PharmacyServiceAPI().post(
            `wallet/pharmacy/fund?type=${walletType}`,
            payload
        );
    },
    getWallet: async (walletType: string) => {
        const { data } = await PharmacyServiceAPI().get(
            `wallet/pharmacy?type=${walletType}`
        );
        return data.payload.data;
    },
    getWalletBalance: async (walletType: string) => {
        const { data } = await PharmacyServiceAPI().get(
            `wallet/pharmacy/balance?type=${walletType}`
        );
        return data.payload.data;
    },
    getWalletTransaction: async (walletType: string) => {
        const { data } = await PharmacyServiceAPI().get(
            `wallet/pharmacy/transaction?type=${walletType}`
        );
        return data.payload.data;
    },
    getAllUsers: async () => {
        const { data } = await PharmacyServiceAPI().get(`/pharmacy/user`);
        return data.payload.data;
    },
    // getAllUsers: async (searchTerm: string = '') => {
    //     const { data } = await PharmacyServiceAPI().get(
    //         `/pharmacy/user?search=${searchTerm}`
    //     );
    //     return data.payload.data;
    // },
    initiateSendStaffMoney: async (payload: any) => {
        return await PharmacyServiceAPI().post(
            `/wallet/pharmacy/initiate-send`,
            payload
        );
    },
    completeSendStaffMoney: async (payload: any) => {
        return await PharmacyServiceAPI().post(
            `/wallet/pharmacy/complete-send`,
            payload
        );
    },
    withdrawMoney: async (payload: any, walletType) => {
        return await PharmacyServiceAPI().post(
            `/wallet/transfer/initiate?type=${walletType}`,
            payload
        );
    },

    completeWithdrawMoney: async (payload: any, walletType) => {
        return await PharmacyServiceAPI().post(
            `/wallet/transfer/complete?type=${walletType}`,
            payload
        );
    },

    // addBankDetails: async (
    //     payload: {
    //         bankName: string;
    //         accountNumber: string;
    //         bankCode: string;
    //     },
    //     walletType
    // ) => {
    //     return await PharmacyServiceAPI().post(
    //         `/wallet/pharmacy/bank?type=${walletType}`,
    //         payload
    //     );
    // },

    updateBankDetails: async (payload: {
        bankName: string;
        accountName: string;
        accountNumber: string;
        bankCode: string;
        pharmacyUserId: string;
    }) => {
        const { data } = await PharmacyServiceAPI().put(
            `wallet/pharmacy/bank`,
            payload
        );
        return data.payload.data;
    },
    // admin
    createAdmin: async (payload: {
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        image: any;
        gender: string;
        address: string;
        state: string;
        city: string;
        permissions: string[];
    }) => {
        return await PharmacyServiceAPI().post('/pharmacy/user', payload);
    },

    // getAllPharmacyUsers: async (
    //     permission: string = '',
    //     gender: string = '',
    //     from: string = '',
    //     to: string = ''
    // ) => {
    //     const params = new URLSearchParams();
    //     if (permission?.trim()) {
    //         params.append('permission', permission);
    //     }

    //     if (gender?.trim()) {
    //         params.append('gender', gender);
    //     }

    //     if (from?.trim()) {
    //         params.append('from', from);
    //     }

    //     if (to?.trim()) {
    //         params.append('to', to);
    //     }

    //     const { data } = await PharmacyServiceAPI().get(
    //         `pharmacy/user?${params.toString()}`
    //     );
    //     return data.payload.data;
    // },

    getAllPharmacyUsers: async (queryParam = '') => {
        // Construct the endpoint with the optional queryParam
        const endpoint = queryParam
            ? `/pharmacy/user?${queryParam}`
            : '/pharmacy/user';

        // Make the API request to the constructed endpoint
        const { data } = await PharmacyServiceAPI().get(endpoint);

        // Return the filtered data
        return data.payload.data;
    },

    // endpoint to get a single staff by ID
    getSingleUser: async (id: string) => {
        const { data } = await PharmacyServiceAPI().get(`/pharmacy/user/${id}`);
        return data.payload.data;
    },

    // endpoint to delete a pharmacy staff by ID
    removeUser: async (id: string) => {
        return await PharmacyServiceAPI().delete(`/pharmacy/user/${id}/remove`);
    },

    // endpoint to suspend pharmacystaff
    suspendUser: async (id: string) => {
        return await PharmacyServiceAPI().put(
            `/pharmacy/user/${id}/suspendOrUnsuspend`
        );
    },

    // edit pharmacy user Group

    editUserGroup: async (id: string, payload: any) => {
        return await PharmacyServiceAPI().put(
            `/pharmacy/user/${id}/update`,
            payload
        );
    },

    // get pharmacy dashboard data
    getDashboardData: async () => {
        const { data } = await PharmacyServiceAPI().get('pharmacy/dashboard');
        return data.payload.data;
    },
    getAdsPrice: async (country: string) => {
        const { data } = await PharmacyServiceAPI().get(`pharmacy/ad-pricing?country=${country}`);
        return data.payload.data;
    },
    createAds: async (payload: any) => {
        return await PharmacyServiceAPI().post('pharmacy/ads', payload);
    },
    getAllAds: async () => {
        const { data } = await PharmacyServiceAPI().get(`pharmacy/ads`);
        return data.payload.data;
    },
    filterAds: async (query?: string) => {
        const { data } = await PharmacyServiceAPI().get(
            `pharmacy/ads?${query}`
        );
        return data.payload.data;
    },
    getSingleAd: async (id: string) => {
        const { data } = await PharmacyServiceAPI().get(`pharmacy/ads/${id}`);
        return data.payload.data;
    },
};
