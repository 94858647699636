import React, { useEffect, useState } from 'react';
import Filter from '../../Images/filtericon.svg';
import { useNavigate } from 'react-router-dom';
import usePagination from '../../hooks/usePagination';
import Table from '../../constants/Table';
import Image from '../../Images/noEvent2.png';
import { useQuery } from 'react-query';
import hospitalService from '../../services/hospitalService';
import { formatDateDmy } from '../../utils/helpers';
import Lottie from 'lottie-react';
import loadingState from '../../Images/loading.json';
import { hospitalRoutes } from '../../utils/routeNames';
import { useHospitalAuthStore, usePatStore } from '../../store/hospital/auth';
import FilterModal from '../../reusable/FilterModal';
import AppointmentEmptyState from '../Appointment/AppointmentEmptyState';

interface rowType {
    _id?: string;
}

const UpcomingAppointments = () => {
    const userStore = useHospitalAuthStore((state) => state.user);
    const viewAllClicked = usePatStore((state) => state.viewAllClicked);
    const [upcomingAppointModal, setUpcomingAppointModal] = useState(false);
    const [emptyState, setEmptyState] = useState(true);
    const [appointments, setAppointments] = useState<[]>([]);
    const [filteredAppointmentData, setFilteredAppointmentData] = useState('');
    const [appointmentData, setAppointmentData] = useState<any>([]);
    const [buttonEnabled, setButton] = useState(false);
    const { data, isError, isLoading } = useQuery(
        'upcomingAppointment',
        hospitalService.getUpcomingAppointment
    );

    useEffect(() => {
        setAppointmentData(data);
        if (filteredAppointmentData) {
            setAppointmentData(filteredAppointmentData);
        }
    }, [data, filteredAppointmentData]);

    const handleFilterQueryParam = (queryParams: string) => {
        hospitalService
            .filterAppointment(queryParams)
            .then((res) => {
                setFilteredAppointmentData(res);
            })
            .catch((err) => {
                // Handle error
                console.log(err);
            });
    };

    const navigate = useNavigate();

    useEffect(() => {
        if (data?.appointments) {
            if (userStore?.userType === 'doctor') {
                const doctorAppointments = data?.appointments?.filter(
                    (appointment) => userStore?._id === appointment?.doctor?._id
                );
                setAppointments(doctorAppointments);
            } else {
                setAppointments(data.appointments);
            }
        }
    }, [data]);

    useEffect(() => {
        if (appointments?.length > 0) {
            setEmptyState(false);
        }
    }, [appointments]);
    useEffect(() => {
        const patientTmrNumber = new URLSearchParams(
            window.location.search
        ).get('patientTmrNumber');
        if (patientTmrNumber) {
            const patientAppointments = appointmentData?.appointments?.filter(
                (row: any) => row.patientTmrNumber === patientTmrNumber
            );
            setAppointments(patientAppointments);
            
        } else if (appointmentData?.appointments) {
            if (userStore.userType === 'doctor') {
                const doctorAppointments = appointmentData.appointments?.filter(
                    (appointment) => userStore._id === appointment?.doctor?._id
                );

                setAppointments(doctorAppointments);
                
                
            } else {
                setAppointments(appointmentData.appointments);
                
                
            }
        }
    }, [appointmentData, viewAllClicked, userStore]);
    // Pagination
    const { page, limit, Pagination } = usePagination({
        page: 1,
        limit: 10,
        total: appointments?.length,
    });

    const paginatedRows = appointments?.slice((page - 1) * limit, page * limit);

    const handleOpenModal = () => {
        setUpcomingAppointModal(true);
    };

    const handleCloseModal = () => {
        setUpcomingAppointModal(false);
    };

    const handleSubmit = (queryParams: string) => {
        console.log('Form submitted!');
        setUpcomingAppointModal(false);
        setButton(false);
        handleFilterQueryParam(queryParams);
    };

    const upcomingTableHeaders = [
        {
            title: 'S/N',
            render: (row: any) => (
                <p>
                    {appointments?.findIndex((user: any) => user._id === row._id) + 1}
                </p>
            ),
        },
        {
            title: 'Patient Name',
            render: (row: any) => (
                <div className="flex items-center gap-x-[10px]">
                    <div>
                        <p className="truncate w-32 font-medium text-lightBrown text-sm">
                            {`${row.patient?.firstName} ${row.patient?.lastName}`}
                        </p>
                        <p className="truncate w-32 font-medium text-lightBrown text-sm">
                            {`${row.patientTmrNumber}`}
                        </p>
                    </div>
                </div>
            ),
        },
        {
            title: 'Assigned Doctor',
            render: (row: any) => (
                <div className="flex items-center gap-x-[10px]">
                    <div>
                        <p className="truncate w-32 font-medium text-lightBrown text-sm">
                            {row?.doctor?.firstName} {row?.doctor?.lastName}
                        </p>
                        <p className="truncate w-32 text-lightBrown100 text-xs">
                            {row?.doctor?.specialties ? row?.doctor?.specialties[0]?.name : ''}
                        </p>
                    </div>
                </div>
            ),
        },
        { title: 'Mode', render: (row: any) => <p>{row.mode}</p> },
        {
            title: 'Time',
            render: (row: any) => (
                <p className="text-lightBrown100 text-xs font-medium">
                    {row.appointmentTime}
                </p>
            ),
        },
        {
            title: 'Appointment Date',
            render: (row: any) => (
                <p className="text-lightBrown100 text-xs font-medium">
                    {formatDateDmy(row.appointmentDate)}
                </p>
            ),
        },
    ];

    return (
        <div className="px-4 py-4 rounded-2xl flex-1 bg-white md:overflow-hidden basis-9/12">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-black font-bold text-md md:text-xl">
                    Upcoming Appointments
                </h2>
                <button onClick={handleOpenModal}>
                    <img
                        src={Filter}
                        alt="filterIcon"
                        className="cursor-pointer"
                    />
                </button>
            </div>
            {isLoading ? (
                <div className="flex justify-center items-center py-64">
                    <Lottie
                        animationData={loadingState}
                        loop={true}
                        className="lottie"
                    />
                </div>
            ) : emptyState ? (
                <div className="flex flex-col justify-center items-center mt-36">
                    <img src={Image} alt="empty" />
                    <p className="mt-4 text-sm font-medium text-dark100">
                        No upcoming appointment schedule
                    </p>
                </div>
            ) : !appointments?.length ? (
                <AppointmentEmptyState />
            ) :(
                <div className="mt-[13px] border-t-[1px] flex flex-col px-4 py-3 overflow-x-auto scrollbar-hide">
                    <Table
                        rows={paginatedRows}
                        headers={upcomingTableHeaders}
                        allowRowClick
                        showHead={true}
                        onRowClick={(row: rowType) =>
                            navigate(`${hospitalRoutes.patientAppointment}${row._id}`)
                        }
                    />
                    <Pagination />
                </div>
            )}

            <FilterModal
                isVisible={upcomingAppointModal}
                onClose={handleCloseModal}
                onSave={handleSubmit}
                buttonEnabled={buttonEnabled}
                setButton={setButton}
            />
        </div>
    );
};

export default UpcomingAppointments;
