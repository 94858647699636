import React, { useEffect } from 'react';
import DashboardLayout from './DashboardLayout';
import DashboardUi from '../../../components/dashboard/DashboardUi';

import { useHospitalAuthStore, useScreenTimeOut } from '../../../store/hospital/auth';
import { useNavigate } from 'react-router-dom';
import { isTokenValid } from '../../../utils/helpers';
import { Helmet } from 'react-helmet';
import { hospitalRoutes } from '../../../utils/routeNames';

const Dashboard = () => {
    const navigate = useNavigate();
    const timeOutStore = useScreenTimeOut();

    const hospitalAuthStore = useHospitalAuthStore((state) => state);
    useEffect(() => {
        if (
            !hospitalAuthStore.token ||
            !isTokenValid(hospitalAuthStore.token)
        ) {

            timeOutStore.setIsTimeOut(false);

            navigate(hospitalRoutes.healthID);
        }
    }, []);

    return (
        <DashboardLayout>
            <Helmet>
                <title>TMR | Dashboard</title>
            </Helmet>
            <div>
                <DashboardUi />
            </div>
        </DashboardLayout>
    );
};

export default Dashboard;
