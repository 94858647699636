import { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../dashboard/DashboardCard';
import drugSvg from '../../../Images/PharmacyImages/drugs.svg';
import orderSvg from '../../../Images/PharmacyImages/orders.svg';
import salesSvg from '../../../Images/PharmacyImages/sales.svg';
import RecentOrder from './RecentOrder';
import { useQuery } from 'react-query';
import { pharmacyService } from '../../../services/pharmacyService';
import Lottie from 'lottie-react';
import loadingState from '../../../Images/loading.json';
import { standardAmountFormat } from '../../../utils/helpers';
import { usePharmacyAuthStore } from '../../../store/pharmacy/auth';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

type DashboardDataType = {
    drugCount: {
        availableDrugs: number;
        outOfStockDrugs: number;
        total: number;
    };
    orderCount: {
        pendingOrders: number;
        completedOrders: number;
        total: number;
    };
    orderAmount: {
        soldOrderAmount: number;
        notSoldOrderCount: number;
        total: number;
    };
    mostOrderDrugs: {
        [key: string]: any;
    };
};

ChartJS.register(ArcElement, Tooltip, Legend);

const AdminDashboard: React.FC = () => {
    const [dashboardData, setDashboardData] = useState<DashboardDataType>({
        drugCount: {
            availableDrugs: 0,
            outOfStockDrugs: 0,
            total: 0,
        },
        orderCount: {
            pendingOrders: 0,
            completedOrders: 0,
            total: 0,
        },
        orderAmount: {
            soldOrderAmount: 0,
            notSoldOrderCount: 0,
            total: 0,
        },
        mostOrderDrugs: {},
    });
    const [chartData, setChartData] = useState<any>({});

    const currency = usePharmacyAuthStore((state) => state.pharmacy.currency);

    const { data, isLoading, isError } = useQuery(
        'pharmacyDashboard',
        pharmacyService.getDashboardData
    );

    useEffect(() => {
        if (data) {
            setDashboardData(data);
        }
    }, [data]);

    const drugData = {
        title: 'Drugs',
        firstData: 'Available',
        firstDataValue: dashboardData.drugCount.availableDrugs,
        secondData: 'Out of Stock',
        secondDataValue: dashboardData.drugCount.outOfStockDrugs,
        thirdData: 'Total',
        thirdDataValue: dashboardData.drugCount.total,
        iconSrc: drugSvg,
    };

    const orderData = {
        title: 'Orders',
        firstData: 'Pending',
        firstDataValue: dashboardData.orderCount.pendingOrders,
        secondData: 'Completed',
        secondDataValue: dashboardData.orderCount.completedOrders,
        thirdData: 'Total',
        thirdDataValue: dashboardData.orderCount.total,
        iconSrc: orderSvg,
    };

    const orderAmountData = {
        title: `Amount (${currency})`,
        firstData: 'Sold',
        firstDataValue: standardAmountFormat(
            dashboardData.orderAmount.soldOrderAmount,
            ''
        ),
        secondData: 'Not Sold',
        secondDataValue: standardAmountFormat(
            dashboardData.orderAmount.notSoldOrderCount,
            ''
        ),
        thirdData: 'Total',
        thirdDataValue: standardAmountFormat(
            dashboardData.orderAmount.total,
            ''
        ),
        iconSrc: salesSvg,
    };

    const randomColor = () => {
        const randomNumber = () => Math.floor(Math.random() * 255);
        return (
            'rgb(' +
            randomNumber() +
            ',' +
            randomNumber() +
            ',' +
            randomNumber() +
            ')'
        );
    };

    useEffect(() => {
        const drugObject = Object.keys(dashboardData.mostOrderDrugs);
        if (drugObject) {
            const chartData: any = {
                labels: [],
                datasets: [
                    {
                        label: 'Most ordered drugs',
                        data: [],
                        backgroundColor: [],
                        hoverOffset: 4,
                    },
                ],
            };
            for (const drugIndex of drugObject) {
                chartData['labels'].push(
                    dashboardData.mostOrderDrugs[drugIndex].productName
                );
                chartData['datasets'][0]['data'].push(
                    dashboardData.mostOrderDrugs[drugIndex].quantity
                );
                chartData['datasets'][0]['backgroundColor'].push(randomColor());
            }
            setChartData(chartData);
        }
    }, [dashboardData.mostOrderDrugs]);

    const MostOrderDrugList = () => (
        <>
            {Object.keys(dashboardData.mostOrderDrugs).map((key, index) => {
                return (
                    <div className="flex justify-between items-center my-2">
                        <p className="font-medium text-sm text-dark100">
                            {dashboardData.mostOrderDrugs[key].productName}
                        </p>
                        <p className="font-semibold text-sm">
                            {dashboardData.mostOrderDrugs[key].quantity}
                        </p>
                    </div>
                );
            })}
        </>
    );

    if (isError) {
        return (
            <div>
                Error loading pharmacy dashboard data, please refresh your page
            </div>
        );
    }

    return (
        <div className="md:px-8 py-6 px-2 w-full">
            <h2 className="mb-6 font-semibold text-[28px]">Dashboard</h2>

            {isLoading ? (
                <div className="flex justify-center items-center py-32">
                    <Lottie
                        animationData={loadingState}
                        loop={true}
                        className="lottie"
                    />
                </div>
            ) : (
                <>
                    <div className="flex flex-row gap-4 w-full flex-wrap ">
                        <div className="flex-1">
                            <div className="flex md:flex-row flex-col items-center justify-between gap-6">
                                <Card {...drugData} />
                                <Card {...orderData} />
                                <Card {...orderAmountData} />
                            </div>
                            <div className="w-full mt-8">
                                <div className="flex flex-col xl:flex-row gap-4 w-full">
                                    <RecentOrder />
                                </div>
                            </div>
                        </div>

                        <div className="w-full xl:w-[380px] 2xl:w-flex-1">
                            {/* <img src={mostordered} alt="" /> */}
                            <div className="bg-white rounded-md p-4 w-full">
                                <h6 className="lg:text-xl font-semibold text-lg">
                                    Most ordered drugs
                                </h6>
                                { chartData?.labels?.length ?
                                <>
                                    <div className="my-4">
                                        
                                            <Doughnut data={chartData} />
                                        
                                    </div>
                                    <div className="my-2 bg-lightGray200 p-3">
                                        <div className="flex justify-between items-center my-2">
                                            <p className="font-medium text-sm text-dark100">
                                                Drugs
                                            </p>
                                            <p className="font-medium text-sm">
                                                Qty
                                            </p>
                                        </div>
                                        <hr />
                                        <MostOrderDrugList />
                                    </div>
                                </>
                                : 
                                <div className="my-6 text-center">
                                    No most order drugs
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default AdminDashboard;
