import React, { useState, ReactNode, Fragment, useEffect } from 'react';
import search from '../../../Images/Leading-icon.png';
import Filter from '../../../Images/filtericon.svg';
import OrderFilterModal from './Modals/OrderFilterModal';
import OrderData from './OrderData';
import { useQuery } from 'react-query';
import { pharmacyService } from '../../../services/pharmacyService';
import { usePagination } from '../../../constants/Pagination/UsePagination';
import UseDebounce from '../../../utils/UseDebounce';
import Tab from './Tab';
import Lottie from 'lottie-react';
import loadingState from '../../../Images/loading.json';

interface ITab {
  title: string;
  content: ReactNode;
  count: number;
}

interface TabsProps {
  tabs: ITab[];
  onSearchChange: (searchTerm: string) => void;
  searchQuery: string;
  onFilter: (queryParams: { status: string; order: string; from: string; to: string }) => void;
  heading?: boolean;
}

const Tabs = () => {

  const [Order, setOrder] = useState<any[]>([]);
    const [pendingOrder, setPendingOrder] = useState<any[]>([]);
    const [ordersPagination, setOrdersPagination]= useState({})
    const [cancelledOrder, setCancelledOrder] = useState<any[]>([]);
    const [completedOrder, setCompletedOrder] = useState<any[]>([]);
    const [OrderDatas, setOrderDatas] = useState<any[]>([]);
    const [filteredOrderData, setFilteredOrderData] = useState('');
    const { page, setPage} = usePagination();
    const [searchQuery, setSearchQuery] = useState('');
    const [filterParams, setFilterParams] = useState({ status: '', order: '', from: '', to: '' });


  const [activeTab, setActiveTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [buttonEnabled, setButton] = useState(false);
  const [filters, setFilters] = useState<any>({});

    const debouncedSearchTerm = UseDebounce(searchQuery, 1800); // Set debounce delay to 500ms
    
    // const { data, isError, isLoading } = useQuery(['order',page], ()=>pharmacyService.getOrder(page));
    const { data, isError, isLoading } = useQuery(
        ['order', page, debouncedSearchTerm, filterParams],
        () =>
          pharmacyService.getOrder(
            page,
            debouncedSearchTerm,
            filterParams.status,
            filterParams.order,
            filterParams.from,
            filterParams.to
          )
      );

    const { data: pendingData } = useQuery(['pending'], () => pharmacyService.getOrder(page, '', 'pending', '', '', ''));
    const { data: completedData } = useQuery(['completed'], () => pharmacyService.getOrder(page, '', 'completed', '', '', ''));
    const { data: cancelledData } = useQuery(['cancelled'], () => pharmacyService.getOrder(page, '', 'cancelled', '', '', ''));
    
    useEffect(() => {
        if (data) {
            const orders = data.orders || [];
            const orderspagination= data.pagination;
            setOrdersPagination(orderspagination)
            setOrderDatas(orders);
            setOrder(orders);
            setPendingOrder(orders.filter((order: any) => order.status === 'pending'));
            setCancelledOrder(orders.filter((order: any) => order.status === 'cancelled'));
            setCompletedOrder(orders.filter((order: any) => order.status === 'completed'));
        }
        console.log('order data', Order)
    }, [data]);

    const tabs = [
        {
            title: 'All',
            content: (
                <Tab>
                    <OrderData
                        Order={Order}
                        searchQuery={searchQuery}
                        pagination={true}
                        page={page}
                        setPage={setPage}
                        ordersPagination= {ordersPagination}

                    />
                </Tab>
            ),
            // count: Order?.length || 0,
            count:data?.pagination?.totalItems || 0
        },
        {
            title: 'Pending',
            content: (
                <Tab>
                    <OrderData
                        Order={pendingOrder}
                        searchQuery={searchQuery}
                        pagination={true}
                        page={page}
                        setPage={setPage}
                        ordersPagination= {ordersPagination}

                    />
                </Tab>
            ),
            count: pendingData?.pagination?.totalItems || 0,
        },
        {
            title: 'Completed',
            content: (
                <Tab>
                    <OrderData
                        Order={completedOrder}
                        searchQuery={searchQuery}
                        pagination={true}
                        page={page}
                        setPage={setPage}
                        ordersPagination= {ordersPagination}

                    />
                </Tab>
            ),
            count: completedData?.pagination?.totalItems || 0,
        },
        {
            title: 'Cancelled',
            content: (
                <Tab>
                    <OrderData
                        Order={cancelledOrder}
                        searchQuery={searchQuery}
                        pagination={true}
                        page={page}
                        setPage={setPage}
                        ordersPagination= {ordersPagination}

                    />
                </Tab>
            ),
            count: cancelledData?.pagination?.totalItems || 0,
        },
    ];

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setSearchQuery(searchTerm);
  };

  const setModal = () => {
    setShowModal(true);
  };

  const handleFilterChange = (newFilterParams: { status: string; order: string; from: string; to: string }) => {
    setFilterParams(newFilterParams);
  };

  const handleSubmit = () => {
    console.log('Form submitted!');
    setShowModal(false);
    setButton(false);
    handleFilterChange(filterParams);
  };
  const handleDisplayModal = () => {
    setShowModal((prev) => !prev);
  };

  const handleFilterApply = (appliedFilters: any) => {
    setFilters(appliedFilters);
  };

  const handleResetFilters = () => {
    setFilters({});
  };
  return (
    <Fragment>
      <div className="w-full">
        <div className="flex flex-col">
          <div className="flex justify-between items-center py-4">
              <p className="font-bold sm:text-[24px] text-[20px]">
                Recent Orders
              </p>
          </div>
          <div className="flex flex-col-reverse sm:flex-row justify-between w-full border-[#EDE0DE] border-b">
            <div className="flex">
              {tabs.map((tab, index) => (
                <div
                  key={index}
                  onClick={() => setActiveTab(index)}
                  className={`flex items-center tab-small-screen ${
                    activeTab === index ? 'border-b-2 border-Tmred' : ''
                  }`}
                >
                  <div className={`mx-2 flex items-center`}>
                    <button
                      key={index}
                      className={`px-2 py-2 font-medium text-[14px] ${index > 0 ? 'ml-2' : ''} ${
                        activeTab === index ? 'text-Tmred' : 'text-[#534341]'
                      } focus:outline-none`}
                    >
                      {tab.title}
                    </button>
                    <span
                      className={`w-[24px] h-[26px] justify-center p-1 flex items-center bg-Tmred text-[12px] rounded-[100px] text-[#fff] focus:outline-none`}
                    >
                      {tab.count}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-between pb-[10px]">
              <div className="flex items-center gap-2 border bg-[#F5F6F7] border-[#F5F6F7] rounded-[28px] sm:max-w-[328px]">
                <img src={search} alt="search" />
                <input
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="w-full font-medium text-[14px] bg-transparent text-[#534341] border-transparent outline-none focus:ring-0"
                />
              </div>
              <div className="flex mr-4 ml-4 items-center" onClick={setModal}>
                <img
                  src={Filter}
                  alt="filterIcon"
                  className="cursor-pointer h-[24px] w-[24px]"
                />
              </div>
            </div>
          </div>
          {isLoading ? (
                    <div className="flex justify-center items-center py-64">
                        <Lottie
                            animationData={loadingState}
                            loop={true}
                            className="lottie"
                        />
                    </div>
                ) : isError?(
                    <p>Error loading orders</p>
                ): (
                  <div className="">{tabs[activeTab].content}</div>
            )}
        </div>
      </div>

      
      
      <OrderFilterModal
        isVisible={showModal}
        onClose={closeModal}
        onSave={handleSubmit}
        buttonEnabled={buttonEnabled}
        setButton={setButton}
        handleDisplayModal={handleDisplayModal}
        onFilterApply={handleFilterApply}
        resetFilters={handleResetFilters}
        currentFilters={filters}
      />
    </Fragment>
  );
};

export default Tabs;
