import usePagination from '../../hooks/usePagination';
import Table from '../../constants/Table';
import { useNavigate } from 'react-router-dom';
import { usePatientAppointmentStore } from '../../store/hospital/auth';
import { formatDateDmy } from '../../utils/helpers';
import AppointmentEmptyState from '../Appointment/AppointmentEmptyState';

const PatientAppointmentTable = ({ appointment }) => {
    const navigate = useNavigate();
    const { patientAppointmentDetails } = usePatientAppointmentStore();
    const { page, limit, Pagination } = usePagination({
        page: 1,
        limit: 10,
        total: appointment?.length,
    });

    const paginatedRows = appointment?.slice((page - 1) * limit, page * limit);

    const patientAppointHeaders = [
        {
            title: 'S/N',
            render: (row: any) => (
                <p>
                    {appointment?.findIndex(
                        (user: any) => user._id === row._id
                    ) + 1}
                </p>
            ),
        },

        {
            title: 'Assigned Doctor',
            render: (row: any) => (
                <div className="flex items-center gap-x-[10px]">
                    <img
                        className="object-contain h-[40px] w-[40px] rounded-full"
                        style={{
                            boxShadow:
                                'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
                        }}
                        src={row.doctor.image}
                        alt=""
                    />{' '}
                    <div>
                        <p className="truncate w-24 font-medium text-lightBrown text-sm ">
                            {row.doctor.name}
                        </p>
                        <p className="text-light_gray text-[12px] font-medium">
                            {row.specialty}
                        </p>
                    </div>
                </div>
            ),
        },
        { title: 'Mode', render: (row: any) => <p>{row.mode}</p> },
        {
            title: 'Time',
            render: (row: any) => (
                <p className="font-medium text-[14px] text-[#534341]">
                    {row.appointmentTime}
                </p>
            ),
        },
        { title: 'Reason', render: (row: any) => <p> {row.reason}</p> },
        {
            title: 'Date Created',
            render: (row: any) => <p> {formatDateDmy(row.appointmentDate)}</p>,
        },
    ];

    return (
        <div className="w-full pt-5">
            {!appointment?.length ? (
                <AppointmentEmptyState />
            ) : (
                <div className="gap-[2rem] block md:grid">
                    <div className="w-full grid gap-x-[30px] self-start md:mb-[0] mb-[2rem]">
                        <Table
                            rows={paginatedRows}
                            headers={patientAppointHeaders}
                            showHead={true}
                            onRowClick={() =>
                                navigate(
                                    `/hospital/appointment/patient-appointment`
                                )
                            }
                            allowRowClick
                        />
                        <Pagination />
                    </div>
                </div>
            )}
        </div>
    );
};

export default PatientAppointmentTable;
