import hospitalServiceAPI from '../utils/hospitalAuth/hospitalServiceAPI';
import PatientServiceAPI from '../utils/hospitalAuth/patientServiceApi';

export const hospitalService = {
    createHospital: async (payload: any) => {
        return await hospitalServiceAPI().post('/auth/hospital', payload);
    },
    createHospitalUser: async ({
        hospitalFormData,
        token,
    }: {
        hospitalFormData: any;
        token: string;
    }) => {
        return await hospitalServiceAPI().post(
            '/auth/hospital/user',
            hospitalFormData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    },
    async getSpecialties() {
        const { data } = await hospitalServiceAPI().get('/specialty');
        return data.payload.data;
    },
    validateTmrId: async (payload: { tmrId: string }) => {
        return await hospitalServiceAPI().post('/auth/hospital/tmrId', payload);
    },
    login: async (payload: {
        email: string;
        password: string;
        hospitalId: string;
    }) => {
        return await hospitalServiceAPI().post('/auth/hospital/login', payload);
    },
    forgotPassword: async (payload: { email: string; hospitalId: string }) => {
        return await hospitalServiceAPI().post(
            '/auth/hospital/password/forgot',
            payload
        );
    },
    verifyForgotPasswordOTP: async (payload: {
        email: string;
        otp: string;
    }) => {
        return await hospitalServiceAPI().post(
            '/auth/hospital/password/verify',
            payload
        );
    },
    resetPassword: async ({
        newPassword,
        token,
    }: {
        newPassword: string;
        token: string;
    }) => {
        return await hospitalServiceAPI().post(
            '/auth/hospital/password/reset',
            {
                newPassword,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    },
    createPasscode: async (payload: { code: string }, token: string) => {
        return await hospitalServiceAPI().post('/passcode', payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    },

    verifyPasscode: async (payload: { code: string; token: string }) => {
        return await hospitalServiceAPI().post('/passcode/verify', payload, {
            headers: {
                Authorization: `Bearer ${payload.token}`,
            },
        });
    },
    changePasscode: async (payload: { code: string; token: string }) => {
        return await hospitalServiceAPI().put('/passcode/update', payload, {
            headers: {
                Authorization: `Bearer ${payload.token}`,
            },
        });
    },

    changePassword: async (payload: {
        oldPassword: string;
        token: string;
        newPassword: string;
        confirmPassword: string;
    }) => {
        return await hospitalServiceAPI().post(
            '/hospital/user/password',
            payload
        );
    },
    editUserProfile: async (payload: any) => {
        return await hospitalServiceAPI().put('/hospital/user/me', payload);
    },

    // update availability
    updateAvailability: async () => {
        return await hospitalServiceAPI().get('/hospital/user/away');
    },
    async notification() {
        const { data } = await hospitalServiceAPI().get('/notification');
        return data.payload.data;
    },
    async plans(country) {
        const { data } = await hospitalServiceAPI().get(
            `/plan?country=${country}`
        );
        return data.payload.data;
    },
    subscribe: async (payload: { planId: string; token: string }) => {
        return await hospitalServiceAPI().post('/subscribe', payload, {
            headers: {
                Authorization: `Bearer ${payload.token}`,
            },
        });
    },
    getActiveSubscription: async (planId: string, token: string) => {
        const { data } = await hospitalServiceAPI().get(
            `/subscribe/${planId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return data.payload.data;
    },
    getUserSubscriptions: async () => {
        const { data } = await hospitalServiceAPI().get('/subscribe');
        return data.payload.data;
    },
    editHospitalProfile: async (payload: any) => {
        return await hospitalServiceAPI().put('/hospital', payload);
    },
    addAdditionalService: async (payload: any) => {
        return await hospitalServiceAPI().post('/additional-service', payload);
    },
    getAdditionalServices: async () => {
        return await hospitalServiceAPI().get('/additional-service');
    },
    deleteAdditionalService: async (serviceId: string) => {
        return await hospitalServiceAPI().delete(
            `/additional-service/${serviceId}`
        );
    },
    updateAdditionalService: async (serviceId: string, updatedData: any) => {
        return await hospitalServiceAPI().put(
            `/additional-service/${serviceId}`,
            updatedData
        );
    },
    editHospitalSchedule: async (payload: any) => {
        return await hospitalServiceAPI().put('/hospital/scheduling', payload);
    },
    deleteNotifications: async (
        notificationIds: string[]
    ): Promise<string[]> => {
        return await hospitalServiceAPI().delete('/notification', {
            data: {
                notificationIds: notificationIds,
            },
        });
    },

    markAsRead: async (notificationIds: string[]): Promise<string[]> => {
        return await hospitalServiceAPI().put('/notification', {
            notificationIds: notificationIds,
        });
    },

    editHospitalMedia: async (payload: any) => {
        return await hospitalServiceAPI().put('/hospital/media', payload);
    },
    deleteHospitalMedia: async (payload: any) => {
        return await hospitalServiceAPI().delete('/hospital/media', {
            data: {
                image_urls: payload.image_urls,
            },
        });
    },
    verifyOTP: async (payload: any) => {
        return await hospitalServiceAPI().post(
            '/auth/hospital/verify',
            payload
        );
    },
    resendOTP: async (payload: { email: string }) => {
        return await hospitalServiceAPI().post('/auth/hospital/otp', payload);
    },
    // endpoint to create a new staff
    createUser: async (payload: {
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        licenseNumber: string;
        title: string;
        userType: string;
        specialties: string[];
        image: any;
        password: string;
        gender: string;
        address: string;
        city: string;
        permissions: string[];
    }) => {
        return await hospitalServiceAPI().post('/hospital/user', payload);
    },
    // endpoint to get a list of all staff based on the user type (doctor, nurse or admin)
    // getUsers: async (userType: string) => {
    //     const { data } = await hospitalServiceAPI().get(
    //         '/hospital/user?userType=' + userType
    //     );
    //     return data.payload.data;
    // },
    getUsers: async (userType: string, availability?: string) => {
        let query = `/hospital/user?userType=${userType}`;
    
        if (availability === 'active') {
            query += `&available=${availability}`;
        }
    
        const { data } = await hospitalServiceAPI().get(query);
        return data.payload.data;
    },
    
    // endpoint to get a single staff by ID
    getSingleUser: async (id: string) => {
        const { data } = await hospitalServiceAPI().get(`/hospital/user/${id}`);
        return data.payload.data;
    },

    // endpoint to delete a staff by ID
    removeUser: async (id: string) => {
        return await hospitalServiceAPI().delete(`/hospital/user/${id}/remove`);
    },

    // endpoint to suspend staff
    suspendUser: async (id: string) => {
        return await hospitalServiceAPI().put(`/hospital/user/${id}/suspend`);
    },

    // edit user Group

    editUserGroup: async (id: string, payload: any) => {
        return await hospitalServiceAPI().put(
            `/hospital/user/${id}/update`,
            payload
        );
    },

    getPatients: async () => {
        const { data } = await hospitalServiceAPI().get('/patient');
        return data.payload.data;
    },
    inviteUserByEmail: async (payload: { email: string }) => {
        return await hospitalServiceAPI().post(
            '/hospital/user/invite',
            payload
        );
    },

    createBill: async (payload: {
        patientTmrNumber: string;
        patientName: string;
        services: {
            name: string;
            quantity: number;
            amount: number;
        }[];
    }) => {
        return await hospitalServiceAPI().post('/billing', payload);
    },
    checkPatientTmrNum: async (payload: { tmrNumber: string }) => {
        return await hospitalServiceAPI().get(
            `/patient/tmrNumber?search=${payload.tmrNumber}`
        );
    },
    getBills: async () => {
        const { data } = await hospitalServiceAPI().get('/billing');
        return data.payload.data;
    },
    getSingleBill: async (id: string) => {
        const { data } = await hospitalServiceAPI().get(`/billing/${id}`);
        return data.payload.data;
    },

    createAppointment: async (payload: {
        patientTmrNumber: string;
        reason: string;
        mode: string;
        appointmentDate: string;
        appointmentTime: string;
        doctorId?: string;
    }) => {
        return await hospitalServiceAPI().post(
            'appointment?service=hospital',
            payload
        );
    },
    getAppointment: async () => {
        const { data } = await hospitalServiceAPI().get(
            'appointment?service=hospital'
        );
        return data.payload.data;
    },
    filterAppointment: async (query?: string) => {
        const { data } = await hospitalServiceAPI().get(
            `appointment?service=hospital${query}`
        );
        return data.payload.data;
    },
    getUpcomingAppointment: async () => {
        const { data } = await hospitalServiceAPI().get(
            'appointment/upcoming?service=hospital'
        );
        return data.payload.data;
    },
    getTelemedicineInvite: async () => {
        const { data } = await hospitalServiceAPI().get(
            'appointment/invite?service=hospital'
        );
        return data.payload.data;
    },
    fundWallet: async (payload: { amount: string }, walletType) => {
        return await hospitalServiceAPI().post(
            `wallet/fund?type=${walletType}`,
            payload
        );
    },
    // getWallet: async () => {
    //     const { data } = await hospitalServiceAPI().get('wallet');
    //     return data.payload.data;
    // },
    getWallet: async (walletType: string) => {
        const { data } = await hospitalServiceAPI().get(
            `wallet?type=${walletType}`
        );
        return data.payload.data;
    },
    getWalletBalance: async (walletType: string) => {
        const { data } = await hospitalServiceAPI().get(
            `wallet/balance?type=${walletType}`
        );
        return data.payload.data;
    },
    getWalletTransaction: async (walletType: string) => {
        const { data } = await hospitalServiceAPI().get(
            `wallet/transaction?type=${walletType}`
        );
        return data.payload.data;
    },
    getSingleAppointment: async (id: string) => {
        const { data } = await hospitalServiceAPI().get(`appointment/${id}`);
        return data.payload.data;
    },

    updateHealthRequest: async (id: string) => {
        return await hospitalServiceAPI().put(
            `/health-request/${id}?action=approve`
        );
    },

    confirmAppointment: async (id: string) => {
        return await hospitalServiceAPI().put(`appointment/${id}/confirm`);
    },

    cancelAppointment: async (id: string, payload: any) => {
        return await hospitalServiceAPI().put(
            `appointment/${id}/cancel?service=hospital`,
            payload
        );
    },
    assignNewDoctor: async (id: string, doctorId: string) => {
        return await hospitalServiceAPI().put(
            `appointment/${id}/assign-doctor?service=hospital`,
            { doctorId }
        );
    },
    //   appointment/:id/reschedule?service=hospital
    rescheduleAppointment: async (id: string, payload: any) => {
        return await hospitalServiceAPI().put(
            `appointment/${id}/reschedule?service=hospital`,
            payload
        );
    },
    hospitalPrivateMode: async (payload: { privateMode: boolean }) => {
        return await hospitalServiceAPI().put('/hospital/privacy', payload);
    },
    completeAppointment: async (id: string) => {
        return await hospitalServiceAPI().put(`/appointment/${id}`);
    },
    markAppointmentCompleted: async (id: string) => {
        return await hospitalServiceAPI().put(`/appointment/${id}/completed`);
    },
    getHospitalSub: async (id: string) => {
        const { data } = await hospitalServiceAPI().get(`subscribe/${id}`);
        return data.payload.data;
    },
    getOutstandingBalance: async () => {
        const { data } = await hospitalServiceAPI().get('/billing/outstanding');
        return data.payload.data;
    },
    getDashboardData: async () => {
        const { data } = await hospitalServiceAPI().get('hospital/dashboard');
        return data.payload.data;
    },
    getPatientAppointmentCount: async (tmrNumber: string) => {
        const { data } = await hospitalServiceAPI().get(
            `/appointment/count?tmrNumber=${tmrNumber}`
        );
        return data.payload.data;
    },

    // getTelemedicineInvite: async () => {
    //     const { data } = await hospitalServiceAPI().get('/appointment/invite');
    //     return data.payload.data;
    // },

    inviteStaffToTelemedicine: async (
        id: string,
        payload: { userId: string }
    ) => {
        return await hospitalServiceAPI().post(
            `appointment/${id}/invite?service=hospital`,
            payload
        );
    },

    payBill: async (payload: any) => {
        return await hospitalServiceAPI().post('/billing/pay', payload);
    },

    getAvailableTimeSlot: async (
        appointmentDate: any,
        hospitalId: any,
        mode?: string
    ) => {
        const { data } = await hospitalServiceAPI().get(
            `appointment/available-time?appointmentDate=${appointmentDate}&hospitalId=${hospitalId}&mode=${mode}`
        );

        return data.payload.data;
    },
    getAllUsers: async () => {
        const { data } = await hospitalServiceAPI().get('/hospital/user');
        return data.payload.data;
    },
    initiateSendStaffMoney: async (payload: any, recipientType: string) => {
        return await hospitalServiceAPI().post(
            `/wallet/initiate-send?recipientType=${recipientType}`,
            payload
        );
    },

    completeSendStaffMoney: async (payload: any, recipientType: string) => {
        return await hospitalServiceAPI().post(
            `/wallet/complete-send?recipientType=${recipientType}`,
            payload
        );
    },

    validateTMRID: async (payload: any) => {
        return await hospitalServiceAPI().post('/wallet/validate', payload);
    },

    withdrawMoney: async (payload: any, walletType) => {
        return await hospitalServiceAPI().post(
            `/wallet/transfer/initiate?type=${walletType}`,
            payload
        );
    },

    completeWithdrawMoney: async (payload: any, walletType) => {
        return await hospitalServiceAPI().post(
            `/wallet/transfer/complete?type=${walletType}`,
            payload
        );
    },
    getBanks: async () => {
        const { data } = await hospitalServiceAPI().get('/wallet/banks');
        return data.payload.data;
    },
    addBankDetails: async (payload: {
        bankName: string;
        accountNumber: string;
        bankCode: string;
    }) => {
        return await hospitalServiceAPI().post('/wallet/bank', payload);
    },
    resolveBankAccount: async (payload: {
        accountNumber: string;
        bankCode: string;
    }) => {
        const { data } = await hospitalServiceAPI().post(
            'wallet/bank/resolve',
            payload
        );
        return data.payload.data;
    },
    getMedicalReportDetails: async (
        tmrNumber: string,
        medicalReportId: string | number | null
    ) => {
        const { data } = await PatientServiceAPI().get(
            `ehr/medication/hospital/${tmrNumber}?medicalReportId=${medicalReportId}`
        );
        return data.payload.data;
    },

    getMedicalReportHistory: async (patientTmrNumber: string) => {
        const { data } = await PatientServiceAPI().get(
            `ehr/medical-report/${patientTmrNumber}`
        );
        return data.payload.data;
    },
    getMedicationHistory: async (patientTmrNumber: string) => {
        const { data } = await PatientServiceAPI().get(
            `ehr/medication?tmrNumber=${patientTmrNumber}`
        );
        return data.payload.data;
    },
    getMedicationDetails: async (
        tmrNumber: string,
        medicalReportId: string | number | null
    ) => {
        const { data } = await PatientServiceAPI().get(
            `ehr/hospital/medication/${medicalReportId}?tmrNumber=${tmrNumber}`
        );
        return data.payload.data;
    },

    getCallInvite: async (id: string) => {
        const { data } = await PatientServiceAPI().get(
            `appointment/patient/invite/${id}?service=patient`
        );
        return data.payload.data;
    },
    getSinglePatientAppointments: async (tmrNumber: string) => {
        const { data } = await hospitalServiceAPI().get(
            `appointment?service=hospital&tmrNumber=${tmrNumber}`
        );
        return data.payload.data;
    },
    createLabTest: async (payload: {
        patientTmrNumber: string;
        testName: string;
        group: string;
        type: string;
        amount: string | number;
    }) => {
        return await PatientServiceAPI().post('/ehr/lab-test', payload);
    },

    getAllLabTest: async (tmrNumber: string) => {
        const { data } = await PatientServiceAPI().get(
            `ehr/lab-test?tmrNumber=${tmrNumber}`
        );
        return data.payload.data;
    },
    getAllLabTestDetails: async (
        tmrNumber: string,
        lastId: string | number | null
    ) => {
        const { data } = await PatientServiceAPI().get(
            `ehr/lab-test/${lastId}?tmrNumber=${tmrNumber}`
        );
        return data.payload.data;
    },
    createLabTestResult: async (payload: any, activeItemId) => {
        const formData = new FormData();
        formData.append('testResult', JSON.stringify(payload.testResult));
        formData.append('remark', payload.remark);
        return await PatientServiceAPI().post(
            `ehr/image-lab/result/upload/${activeItemId}`,
            formData
        );
    },
    // addImageLabTestResult: async (payload: {
    //     imageLabTestResult: File[];
    //     patientTmrNumber: string;
    //     labTestId: number;
    //     remark: string;
    // }) => {
    //     const formData = new FormData();
    //     formData.append('patientTmrNumber', payload.patientTmrNumber);
    //     payload.imageLabTestResult.forEach((imageFile) => {
    //         formData.append('imageResult', imageFile);
    //     });
    //     formData.append('remark', payload.remark);

    //     return await PatientServiceAPI().post(
    //         `ehr/image-lab/result/upload/${payload.labTestId}`,
    //         formData
    //     );
    // },
    addImageLabTestResult: async (payload: any) => {
        const formData = new FormData();
        // formData.append('patientTmrNumber', payload.patientTmrNumber);
        payload.imageResult.forEach((imageFile) => {
            formData.append('imageResult', imageFile);
        });
        // formData.append('labTestId', payload.labTestId);
        formData.append('remark', payload.remark);

        return await PatientServiceAPI().post(
            `ehr/image-lab/result/upload/${payload.labTestId}`,
            formData
        );
    },
    uploadLabTestResult: async (payload: { labTestId: number }) => {
        return await PatientServiceAPI().post(
            `ehr/image-lab/result/upload/${payload.labTestId}`,
            payload
            // ehr/image-lab/result/upload/:
        );
    },
    // uploadResult: async (payload: {
    //     imageResult: File[];
    //     patientTmrNumber: string;
    //     labTestId: number;
    //     remark: string;
    // }) => {
    //     return await PatientServiceAPI().post(
    //         `ehr/image-lab/result/upload/${payload.labTestId}`,
    //         payload
    //     );
    // },

    sendMessage: async (
        payload: {
            message: string;
            messageType: string;
        },
        appointmentId
    ) => {
        return await hospitalServiceAPI().post(
            `/chat/hospital/${appointmentId}?service=hospital`,
            payload
        );
    },
    getMessages: async (appointmentId: string) => {
        const { data } = await hospitalServiceAPI().get(
            `/chat/hospital/${appointmentId}?service=hospital`
        );
        return data.payload.data;
    },
    activateAmbulance: async (payload: any) => {
        return await hospitalServiceAPI().post('/hospital/ambulance', payload);
    },
    getFleetCount: async () => {
        const { data } = await hospitalServiceAPI().get(`/ambulance/fleet/count`);
        return data.payload.data;
    },
    getAmbulanceTypes: async () => {
        const { data } = await hospitalServiceAPI().get(`/hospital/ambulance/types`);
        return data.payload.data;
    },
    getAllAmbulance: async () => {
        const { data } = await hospitalServiceAPI().get(`/ambulance/fleet`);
        return data.payload.data;
    },
    filterAllAmbulance: async (query?: string) => {
        const { data } = await hospitalServiceAPI().get(
            `/ambulance/fleet?${query}`
        );
        return data.payload.data;
    },
    addAmbulance: async (payload: any) => {
        return await hospitalServiceAPI().post('/ambulance/fleet', payload);
    },
    getSingleAmbulanceProfile: async (id: string) => {
        const { data } = await hospitalServiceAPI().get(`ambulance/fleet/${id}/profile`);
        return data.payload.data;
    },
    deleteSingleAmbulance: async (id: string) => {
        return await hospitalServiceAPI().put(
            `/ambulance/fleet/${id}?action=delete-ambulance`
        );
    },
    editAmbulanceStatus: async (id: string,payload) => {
        return await hospitalServiceAPI().put(`/ambulance/fleet/${id}?action=update-status`,payload);
    },
    editAmbulanceProfile: async (id: string,payload) => {
        return await hospitalServiceAPI().put(`/ambulance/fleet/${id}?action=edit-ambulance`,payload);
    },
    getAllRequestHistory: async () => {
        const { data } = await hospitalServiceAPI().get(`/ambulance/request`);
        return data.payload.data;
    },
    updateAmbulanceType: async (payload: any) => {
        return await hospitalServiceAPI().put('/hospital/ambulance', payload);
    },
    getRequestCount: async () => {
        const { data } = await hospitalServiceAPI().get(`/ambulance/request`);
        return data.payload.data;
    },
    filterRequest: async (query?: string) => {
        const { data } = await hospitalServiceAPI().get(
            `ambulance/request?${query}`
        );
        return data.payload.data;
    },
    getSingleRequestProfile: async (id: string) => {
        const { data } = await hospitalServiceAPI().get(`ambulance/request/${id}`);
        return data.payload.data;
    },
    cancelambulanceProfile: async (id: string,payload) => {
        return await hospitalServiceAPI().put(`/ambulance/request/${id}?action=cancel`,payload);
    },
   addDoctorNote: async (id: string,payload) => {
        return await hospitalServiceAPI().put(`/ambulance/request/${id}?action=add-doctors-note`,payload);
    },
    acceptAmbulanceRequest: async (id: string,payload) => {
        return await hospitalServiceAPI().put(`/ambulance/request/${id}?action=accept`,payload);
    },
    markRequestAsCompleted: async (id: string) => {
        return await hospitalServiceAPI().put(`/ambulance/request/${id}?action=complete`);
    },

    // ads endpoints
    getAdsPrice: async (country: string) => {
        const { data } = await hospitalServiceAPI().get(`/ad-pricing?country=${country}`);
        return data.payload.data;
    },
    createAds: async (payload: any) => {
        return await hospitalServiceAPI().post('/ads', payload);
    },
    getAllAds: async () => {
        const { data } = await hospitalServiceAPI().get(`/ads`);
        return data.payload.data;
    },
    filterAds: async (query?: string) => {
        const { data } = await hospitalServiceAPI().get(
            `/ads?${query}`
        );
        return data.payload.data;
    },
    getSingleAd: async (id: string) => {
        const { data } = await hospitalServiceAPI().get(`ads/${id}`);
        return data.payload.data;
    },
}
export default hospitalService;
