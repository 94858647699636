import React, { Fragment, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import EmptySearch from '../../../Images/noAmbulanceReq.svg';
import Table from '../../../constants/Table';
import 'rc-slider/assets/index.css';
import Lottie from 'lottie-react';
import loadingState from '../../../Images/loading.json';
import hospitalService from '../../../services/hospitalService';
import profile from '../../../Images/profile.png';

import { useQuery } from 'react-query';
import  TimeComponent, {
    formatDateDmy,
    standardAmountFormat,
} from '../../../utils/helpers';
import { hospitalRoutes } from '../../../utils/routeNames';
import { useHospitalAuthStore } from '../../../store/hospital/auth';
interface rowType {
    _id: string;
    billId: any;
}
const RecentAmbulance = ({ ambulanceRequests }: any) => {
    const navigate = useNavigate();
    const [isAmbulanceRequest, setIsAmbulanceRequest] = useState(false);
    const currency = useHospitalAuthStore((state) => state.hospital.currency);
    
    const requestHeaders = [
        {
            title: 'S/N',
            render: (row: any) => (
                <p>
                    {ambulanceRequests?.findIndex((user: any) => user._id === row._id) + 1}
                </p>
            ),
        },
        {
            title: 'Patient Name',
            render: (row: any) => (
                <div className="flex items-center gap-x-[10px]" >
                    <img src={row.patient?.img || profile} alt="ambulance img" className='w-[40px] h-[40px] rounded-[200px]'/>
                        
                        <div style={{ width: '168px', whiteSpace: 'normal', wordWrap: 'break-word' }} className="truncate  font-medium text-lightBrown text-sm">
                        <p className="truncate w-32 font-medium text-lightBrown text-sm">
                            {`${row.patient?.firstName} ${row.patient?.lastName}`}
                        </p>
                        <p className="truncate w-32 font-medium text-lightBrown text-sm">
                            {`${row.patient?.tmrNumber
}`}
                        </p>
                    </div>
                </div>
            ),
        },
        {
            title: 'Address',
            render: (row: any) => (
                <div className="flex items-center gap-x-[10px]">
                    <div>
                        <p style={{ width: '200px', whiteSpace: 'normal', wordWrap: 'break-word' }} className="truncate  font-medium text-lightBrown text-sm">
                            {row?.location || ''}
                        </p>
                        
                    </div>
                </div>
            ),
        },
        
        {
            title: 'Types of Ambulance',
            render: (row: any) => (
                <p style={{ width: '168px', whiteSpace: 'normal', wordWrap: 'break-word' }} className="text-lightBrown100 text-xs font-medium">
                    {row.ambulanceType}
                </p>
            ),
        },
        {
            title: 'Amount',
            render: (row: any) => (
                <p className="text-lightBrown100 text-xs font-medium">
                {standardAmountFormat(row.amount, currency)}
                </p>
            ),
        },
        {
            title: 'Status',
            render: (row: any) => (
                <div
                className={`flex items-start justify-center p-2 rounded 
                    ${
                        row.status === 'pending'
                            ? 'bg-[#366CC926] text-lightBrown'
                            : ''
                    } 
                    ${
                        row.status === 'completed'
                            ? 'bg-[#366CC926] text-lightBrown'
                            : ''
                    }
                    ${
                        row.status === 'cancelled'
                            ? 'bg-[#FFDAD5] text-lightBrown'
                            : ''
                    }
                    ${
                        row.status === 'accepted'
                            ? 'bg-[#366CC926] text-lightBrown'
                            : ''
                    }
                `}
            >
                <div
                    className={`w-[6px] h-[6px] rounded-full mr-2 mt-[6px] 
                        ${row.status === 'pending' || 'accepted' ? 'bg-[#366CC9]' : ''}
                        ${row.status === 'completed' ? 'bg-[#3A975F]' : ''}
                        ${row.status === 'cancelled' ? 'bg-[#C00010]' : ''}
                    }`}
                />
                <p className="text-xs">{row.status}</p>
            </div>
            ),
        },
        {
            title: 'Request Date',
            render: (row: any) => (
                <p style={{ width: '121px', whiteSpace: 'normal', wordWrap: 'break-word' }} className="text-lightBrown100 text-xs font-medium">
                    {formatDateDmy(row.createdAt)} <br/>
                    <TimeComponent time={row.createdAt} />
                </p>
            ),
        },
    ];
    return (
        <Fragment>
            <div className="w-full flex flex-col  py-4  ">
                <div className="bg-white p-4 rounded-2xl">
                    <div className="flex flex-col-reverse sm:flex-row items-center justify-between gap-4 w-full px-4">
                        <div className="flex items-center space-x-3">
                            <h2 className="md:text-2xl text-sm font-semibold text-dark">
                                Recent Ambulance Requests
                            </h2>
                        </div>
                    </div>
                    <div className="mt-[9px] flex flex-col px-4 py-3 overflow-x-auto scrollbar-hide">
                        {ambulanceRequests.length !== 0 ? (
                            <>
                                <Table
                                    // key={ambulanceRequests.findIndex()}
                                    rows={ambulanceRequests?.slice(0, 5)}
                                    headers={requestHeaders}
                                    allowRowClick
                                    showHead={true}
                                    onRowClick={(row: rowType) =>
                                        navigate(
                                            `/hospital/ambulance/request/ambulance-profile/${row._id}`
                                        )
                                    }
                                />
                                <div className="flex items-center justify-center w-full my-3 pt-6">
                                    <Link
                                        to={hospitalRoutes.ambulanceRequestHistory}
                                        className="w-full sm:w-fit h-[40px] text-[14px] flex items-center text-center justify-center text-[#C00010] border border-[#C00010] py-2 px-6 rounded-lg font-medium"
                                    >
                                        View all
                                    </Link>
                                </div>
                            </>
                        ) : (
                            <div className="flex flex-col items-center justify-center text-center my-[70px] ">
                                <img
                                    src={EmptySearch}
                                    className="w-[12rem]"
                                    alt="empty"
                                />
                                <p className="mt-6 text-base font-medium font-[Montserrat]">
                                All caught up! There are no ambulance requests at the moment.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default RecentAmbulance;
