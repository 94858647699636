import { useEffect, useState, memo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import hospitalService from '../../../services/hospitalService';
import Lottie from 'lottie-react';
import loadingState from '../../../Images/loading.json';
import { addTimeToDate, compareDate } from '../../../utils/helpers';
import {
    AgoraRTCProvider,
    useRTCClient
} from "agora-rtc-react";
import AgoraRTC from "agora-rtc-sdk-ng";
import AgoraManager from './AgoraManager';
import MedicalReport from '../PatientAppointment/MedicalReport';
import { useHospitalAuthStore } from '../../../store/hospital/auth';
import InviteStaff from './InviteStaff';
// import useCallStatus from './UseCall';

const VideoCall = () => {

    useEffect(() => {
        const channel = new BroadcastChannel('telemedicine-tab');
        channel.postMessage('another-telemedicine-tab');
        // note that listener is added after posting the message
        channel.addEventListener('message', (msg) => {
          if (msg.data === 'another-telemedicine-tab') {
            // message received from 2nd tab
            alert('You can only have one telemedicine tab opened');
            window.close()
          }
        });
        return () => {
            channel.close();
        }
    }, []);

    const agoraEngine = useRTCClient(AgoraRTC.createClient({ codec: "vp8", mode: "rtc" }));
    const AppID = String(process.env.REACT_APP_AGORA_APP_ID);
    const user = useHospitalAuthStore(store => store.user);
    const uid = Math.floor(Math.random() * 1000000000);

    const [canCall, setCanCall] = useState<boolean>(false);
    const [appointment, setAppointment] = useState<any>({});
    const { id } = useParams();
// prevent camera from loading on multiple tabs within same browser
    // useCallStatus();

    // Function to check camera and microphone permissions
    const checkPermissions = () => {
        // Check if camera permission is granted
        navigator.mediaDevices.getUserMedia({ video: true })
        .then(function (stream) {
            // console.log('Camera permission granted');
            // Access to camera is allowed
        })
        .catch(function (error) {
            // console.log('Camera permission denied');
            // Access to camera is not granted
            // Request camera permission
            requestCameraPermission();
        });
    
        // Check if microphone permission is granted
        navigator.mediaDevices.getUserMedia({ audio: true })
        .then(function (stream) {
            // console.log('Microphone permission granted');
            // Access to microphone is allowed
        })
        .catch(function (error) {
            // console.log('Microphone permission denied');
            // Access to microphone is not granted
            // Request microphone permission
            requestMicrophonePermission();
        });
    }
    
    // Function to request camera permission
    const requestCameraPermission = () => {
        navigator.mediaDevices.getUserMedia({ video: true })
        .then(function (stream) {
            // console.log('Camera permission granted');
            // Camera permission is now granted
        })
        .catch(function (error) {
            // console.log('Camera permission denied');
            // Camera permission is still denied
        });
    }
    
    // Function to request microphone permission
    const requestMicrophonePermission = () => {
        navigator.mediaDevices.getUserMedia({ audio: true })
        .then(function (stream) {
            // console.log('Microphone permission granted');
            // Microphone permission is now granted
        })
        .catch(function (error) {
            // console.log('Microphone permission denied');
            // Microphone permission is still denied
        });
    }
    
    useEffect(() => {
        // Call the checkPermissions function to start checking permissions
        checkPermissions();
    }, []);

    const { data, isLoading, refetch, isError } = useQuery(['singleAppointment', id], () =>
        id ? hospitalService.getSingleAppointment(id) : Promise.reject('NO ID')
    )

    useEffect(() => {
        if(data) {
            const { appointment } = data;
            // console.log(appointment);
            setAppointment(appointment);
            if(appointment.mode === 'telemedicine' && appointment.status === 'pending' && appointment.confirmed && compareDate( addTimeToDate(appointment.appointmentDate, appointment.appointmentTime) )) {
                setCanCall(true);
            } else {
                setCanCall(false);
            }
        } else {
            refetch();
        }
    }, [data]);

    return (
    <div className="flex flex-col flex-1 justify-center bg-[#F5F5F5] w-full p-4 ">
        {  isLoading ? 
            (
            <div className="flex justify-center items-center py-64">
                <Lottie
                    animationData={loadingState}
                    loop={true}
                    className="lottie"
                />
            </div>
            ) : canCall ? 
            <div className="bg-[#F5F5F5] w-full">
                <div className="flow-root px-2 mx-4 mt-2">
                    <h1 className="float-left text-[#201A19] text-[28px] font-semibold">Patient Video Call</h1>
                    <div className="float-right">
                        <InviteStaff appointment={appointment} refetch={refetch} />
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row gap-4 justify-between mx-4 mt-4">
                    <AgoraRTCProvider client={agoraEngine}>
                        <div className="md:w-full w-2/3 p-4">
                            <AgoraManager 
                                channelName={appointment._id} 
                                AppID={AppID} 
                                token="" 
                                uid={uid}
                                username={`${user.firstName} ${user.lastName}`}
                            />
                        </div>
                    </AgoraRTCProvider>
                    <div className="flex flex-col w-1/3 gap-6"> 
                        <MedicalReport {...appointment} />
                    </div>
                </div>

            </div>
            :
            <div className="bg-[#F5F5F5] w-full">
                The video call session is not available at the moment
            </div>
        }
    </div>
    )
}

export default memo(VideoCall);