import usePagination from '../../../hooks/usePagination';
import Table from '../../../constants/Table';
import { useNavigate } from 'react-router-dom';
import image from '../../../Images/userMgmt.png';
import { useQuery } from 'react-query';
import { formatDateDmy } from '../../../utils/helpers';
import { useAdminStore } from '../../../store/hospital/auth';
import { useEffect , useState} from 'react';
import Lottie from 'lottie-react';
import loadingState from '../../../Images/loading.json';
import { pharmacyService } from '../../../services/pharmacyService';
interface rowType {
    _id: string;
}

const TotalAdmin = ({ isData, searchTerm, queryParam }: any) => {
    const navigate = useNavigate();
    const setAdminCount = useAdminStore((state) => state.setAdminCount);
    const [adminData, setAdminData] = useState<any[]>([]);

    const { data, isError, isLoading } = useQuery(
        'user',
        () => pharmacyService.getAllPharmacyUsers(), 
    );

    useEffect(() => {
        // When data (from 'admins' query) changes, update adminData
        if (data) {
            setAdminData(data?.users);
        }
    }, [data]);

    const { data: filterData } = useQuery(
        ['filteredAdmins', queryParam],
        () => pharmacyService.getAllPharmacyUsers(`${queryParam}`),
        {
            enabled: queryParam !== undefined, // Fetch even when queryParam is an empty string, to refetch data when clear all is clicked
        }
    );

    useEffect(() => {
   
        if (filterData) {
            setAdminData(filterData.users);
        }
    }, [filterData, queryParam]);

    useEffect(() => {
        setAdminCount(adminData?.length || 0);
    }, [adminData]);

    const filteredAdminUsers = adminData?.filter((user: any) =>
        user.firstName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const { page, limit, Pagination } = usePagination({
        page: 1,
        limit: 10,
        total: filteredAdminUsers?.length,
    });

    const paginatedRows = filteredAdminUsers?.slice(
        (page - 1) * limit,
        page * limit
    );
    if (isLoading) {
        return (
            <div className="flex justify-center items-center py-32">
                {' '}
                <Lottie
                    animationData={loadingState}
                    loop={true}
                    className="lottie"
                />
            </div>
        );
    }

    if (isError) {
        return <div>Error loading admin, please refresh your page</div>;
    }
    const recordSearchTableHeaders = [
        {
            title: 'S/N',
            render: (row: any, index: number) => (
                <p className="text-xs font-medium">
                    {adminData?.findIndex(
                        (user: any) => user._id === row._id
                    ) + 1}
                </p>
            ),
        },
        {
            title: 'Admins',
            render: (row: any) => (
                <div className="flex items-center space-x-2">
                    <img
                        src={row.image}
                        alt="img"                       
                        className="rounded-full w-10 h-10"
                    />
                    <div>
                        <p className="text-sm font-medium text-lightBrown truncate w-24">
                        {`${row.salutation && row.salutation !== "undefined" ? row.salutation + ' ' : ''}${row.firstName} ${row.lastName}`}

                        </p>
                        <p className="text-xs font-medium text-lightBrown100 truncate w-28">
                            {row.email}
                        </p>
                    </div>
                </div>
            ),
        },
        {
            title: 'Gender',
            render: (row: any) => (
                <p className="text-xs font-medium text-lightBrown100 text-center">
                    {row.gender}
                </p>
            ),
        },
        {
            title: 'Phone Number',
            render: (row: any) => (
                <p className="font-medium text-xs text-lightBrown100">
                    {row.phoneNumber}
                </p>
            ),
        },
        {
            title: 'User Group',
            render: (row: any) => (
                <div className="flex truncate gap-1 break-words w-[200px]" >
                    {row.permissions.map((permission, index) => (
                        <p
                            key={index}
                            className="text-xs font-medium text-lightBrown100  "
                        >
                            {permission.trim()} 
                            {index < row.permissions.length - 1 && ', '}
                        </p>
                    ))}
                </div>
            ),
        },
        {
            title: 'Date Joined',
            render: (row: any) => (
                <div>
                    <p> {formatDateDmy(row.createdAt)}</p>
                </div>
            ),
        },
    ];
    return (
        <div className="mt-[13px] overflow-x-auto scrollbar-hide">
            <>
                {isData === true ? (
                    <>
                        {filteredAdminUsers && filteredAdminUsers.length > 0 ? (
                            <>
                                <Table
                                    rows={paginatedRows}
                                    headers={recordSearchTableHeaders}
                                    showHead={true}
                                    allowRowClick
                                    onRowClick={(row: rowType) =>
                                        navigate(`/pharmacy/admin-profile/${row._id}`)
                                    }
                                />
                                <Pagination />
                            </>
                        ) : (
                            <>
                                <Table
                                    headers={recordSearchTableHeaders}
                                    showHead={true}
                                />
                                <div className="flex flex-col items-center justify-center text-center my-[70px] ">
                                    <img src={image} alt="" />
                                    <p className=" mt-6 text-base font-medium font-[Montserrat]">
                                        The admin section is currently empty.
                                    </p>
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <Table headers={recordSearchTableHeaders} showHead={true} />
                )}
            </>
        </div>
    );
};

export default TotalAdmin;
