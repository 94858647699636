import React from 'react';
import BookAppointment from '../../../components/Appointment/BookAppointment';
import DashboardLayout from '../Dashboard/DashboardLayout';
import {Helmet} from 'react-helmet'


const BookApointment = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Book Appointment
                </title>
            </Helmet>
            <BookAppointment />
        </DashboardLayout>
    );
};

export default BookApointment;
