import { Link } from "react-router-dom";
import { hospitalRoutes } from "../../../utils/routeNames";

type PatientProps ={
    image: string;
    firstName: string;
    lastName: string;
    phoneNumber: any;
    email: string;
    tmrNumber: string;
    viewProfileVisible?: boolean;
}

const PatientCard = (patientDetails: PatientProps) => {

    return (
        <div className="flex flex-col md:flex-row justify-between items-center bg-lightTmrRed w-full px-4 py-2 rounded-xl mt-2">
            <div className="flex items-center gap-2 py-2">
                <div className="pr-2">
                    <img
                        src={patientDetails.image}
                        alt=""
                        className="h-[80px] w-[109px] rounded-full"
                    />
                </div>

                <div className="flex flex-col w-full gap-2">
                    <h6 className="font-semibold text-[#201A19] text-xs sm:text-[24px]">
                        {patientDetails.firstName} {patientDetails.lastName}
                    </h6>
                    <p className="font-medium text-[#534341] text-sm ">
                        {patientDetails.email}
                    </p>
                    <p className="text-[#534341] text-[14px] font-normal">
                        {patientDetails.tmrNumber}
                    </p>
                </div>
            </div>
            {patientDetails?.viewProfileVisible && 
            <div className="flex items-center">
                <p className="font-medium text-[#201A19] text-[16px]">
                    <Link 
                        to={`${hospitalRoutes.patient}/${patientDetails.tmrNumber}`} 
                        className="text-Tmred underline"
                    >
                        View Profile
                    </Link>
                </p>
            </div>
            }
        </div>
    );
};

export default PatientCard;
