import React from 'react';
import DashboardLayout from '../Dashboard/DashboardLayout';
import {Helmet} from 'react-helmet'
import RequestDrugPage from '../../../components/PharmacyComponent/Inventory/RequestInventory/RequstDrugPage';


const RequestInventory = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Request Drug
                </title>
            </Helmet>
          <RequestDrugPage/>
        </DashboardLayout>
    );
};

export default RequestInventory;
