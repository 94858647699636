import { useState } from 'react';
import openeye from './../../Images/openeye.svg';

const PasswordInput = ({ ...rest }) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    function togglePasswordVisibility() {
        setIsPasswordVisible((prevState) => !prevState);
    }

    return (
        <>
            <input
                {...rest}
                placeholder={rest.placeholder || 'Password'}
                type={isPasswordVisible ? 'text' : 'password'}
                className="w-full border border-[#4d454454] hover:border-dark focus:border-Tmred focus:border-2 outline-none p-4 rounded-[4px] text-base tracking-wider my-4 mt-5"
            />
            <div
                className="absolute top-[23px] right-0"
                onClick={togglePasswordVisibility}
            >
                {isPasswordVisible ? (
                    <img src={openeye} alt="" />
                ) : (
                    <div className="absolute top-[17px] right-[15px]">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                            />
                        </svg>
                    </div>
                )}
            </div>
        </>
    );
};

export default PasswordInput;
