

const CircularProgress = ({ percentage, maleColor, femaleColor }: any) => {
  const circumference = 2 * Math.PI * 45; // 45 is the radius
  const maleProgress = (percentage / 100) * circumference;
  const femaleProgress = circumference - maleProgress;

  return (
    <svg width="120" height="120" viewBox="0 0 120 120">
      <circle
        cx="60"
        cy="60"
        r="45"
        stroke={femaleColor}
        strokeWidth="10"
        fill="none"
        strokeDasharray={`${femaleProgress} ${circumference}`}
        strokeDashoffset={-maleProgress}
        transform="rotate(-90 60 60)"
      />
      <circle
        cx="60"
        cy="60"
        r="45"
        stroke={maleColor}
        strokeWidth="10"
        fill="none"
        strokeDasharray={`${maleProgress} ${circumference}`}
        strokeDashoffset="0"
        transform="rotate(-90 60 60)"
      />
    </svg>
  );
};

export default CircularProgress;
