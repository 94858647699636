import React, { Fragment, useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import HospitalAuthHeader from '../../../reusable/HospitalAuthHeader';
import Input from '../../../reusable/Forms/Input';
import count1 from './../../../Images/Frame1.svg';

import ProfessionalInformation from './ProfessionalInformation';
import Layout from '../Layout';
import { useLocation, Navigate, useNavigate } from 'react-router-dom';
import { useHospitalAccountStore } from '../../../store/hospital/auth';
import { isTokenValid } from '../../../utils/helpers';
import BlockButton from '../../../reusable/BlockButton';
import PasswordInput from '../../../reusable/Forms/PasswordInput';
import {Helmet} from 'react-helmet'
// import Modal from '../../../reusable/ConsentModal';
import Modal from '../../../reusable/Modal';
import SelectElement from '../../../reusable/Forms/Select';
import { SelectChangeEvent } from '@mui/material';
import ButtomLogo from '../../../reusable/ButtomLogo';
import { hospitalRoutes } from '../../../utils/routeNames';

type InputProps = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    password: string;
    Gender:string;
    Salutation: string;
};

const PersonalInformation = () => {
    const [showProfessionalPage, setShowProfessionalPage] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const[disabled,setDisabled] = useState(true)
    const navigate=useNavigate()
    const [formData, setFormData] = useState<InputProps>({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        password: '',
        Gender:'',
        Salutation: ''
    });
    const [isAcceptModalVisible, setAcceptModalVisible] = useState(false);

    const { formState, handleSubmit, control ,watch} = useForm<InputProps>();

    const handleSelectChange = (e: SelectChangeEvent) => {
        const { name, value } = e.target;
          setFormData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleAcceptConfirm = async () => {
       
        navigate(hospitalRoutes.healthID);
        setAcceptModalVisible(false)
       
      };
    
      const handleAcceptCancel = () => {
        setAcceptModalVisible(false);
      };
    const hospitalAccount = useHospitalAccountStore((state) => state);
    const location = useLocation();
    // const navigate = useNavigate();
    if (
        !isTokenValid(hospitalAccount.token) ||
        !hospitalAccount.hospital.name
    ) {
        return (
            <Navigate
                to="/hospital/auth/register"
                replace
                state={{ from: location }}
            />
        );
        // navigate('/hospital/auth/register');
    }

    const { isValid, isSubmitting, isDirty } = formState;

    const hideProfessionalPage = () => {
        setShowProfessionalPage(false);
    };

    const onSubmitForm: SubmitHandler<InputProps> = (data) => {
        setFormData(data);
        setShowProfessionalPage(true);
    };
    const onInvalid = (errors) => console.error(errors);
    
    const handleCheckboxChange = () => {
        setIsCheckboxChecked(!isCheckboxChecked);
        setDisabled(!disabled)
      };
    
      const handleModalSubmit = () => {
        
        setIsModalOpen(false);
      };

    const onClose=()=>{
        // setIsModalOpen(false);
        setAcceptModalVisible(true)
       
    }

    return (
        <Layout>
            <Helmet>
                <title>
                    TMR | Create SuperAdmin
                </title>
            </Helmet>
            <Fragment>
          <>
                {!showProfessionalPage && (
                    <>
                        <div className="flex items-center justify-center flex-col w-full  py-8 overflow-x-hidden ">
                            <div className="mx-auto flex flex-col items-center justify-center gap-4">
                                <HospitalAuthHeader
                                    logo={hospitalAccount.hospital.logo}
                                    text={hospitalAccount.hospital.tagline}
                                    title={hospitalAccount.hospital.name}
                                />
                                <div className="flex items-center flex-col justify-center  border rounded-xl bg-[#FFf] py-[40px] px-[80px] mt-7 max-w-[592px]">
                                    <div className="flex items-start">
                                        <div>
                                            <h3 className="font-semibold text-[#201A19] text-center text-[32px] ">
                                            Super Admin Information
                                            </h3>
                                            <p className="font-normal text-[#534341] text-[14px] max-w-[432px] pt-4 text-center">
                                                Please provide your basic
                                                personal details for account
                                                creation.
                                            </p>
                                        </div>
                                        <div className="text-end">
                                            <img src={count1} alt="count1" />
                                        </div>
                                    </div>
                                    <div className="py-5">
                                        <form
                                            onSubmit={handleSubmit(
                                                onSubmitForm,
                                                onInvalid
                                            )}
                                            className="w-full flex flex-col gap-4 sm:gap-4"
                                            noValidate
                                        >
                                            <div className="flex flex-row">
                                                <div className='w-full py-2'>
                                                    <Controller
                                                        name="Salutation"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <SelectElement
                                                                name="Salutation"
                                                                onChange={(e: SelectChangeEvent) => {
                                                                    field.onChange(e);
                                                                    handleSelectChange(e);
                                                                }}
                                                                value={field.value}
                                                                options={['Mr.', 'Mrs.', 'Miss', 'Dr.', 'Nurse']}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex items-center flex-col gap-3 w-full sm:flex-row">
                                                <div className="flex flex-col items-start sm:flex-1 ">
                                                    {/* <Input
                                                        label="First Name"
                                                        value={watch('firstName')}
                                                        {...register("firstName", { required: 'First name is required' })}
                                                    /> */}
                                                    <Controller
                                                        name="firstName"
                                                        rules={{
                                                            required:
                                                                'First name is required',
                                                        }}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Input
                                                                label="First Name"
                                                                {...field}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="flex flex-col items-start sm:flex-1 ">
                                                    <Controller
                                                        name="lastName"
                                                        rules={{
                                                            required:
                                                                'Last name is required',
                                                        }}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Input
                                                                label="Last Name"
                                                                {...field}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <Controller
                                                name="email"
                                                rules={{
                                                    required:
                                                        'Email is required',
                                                }}
                                                control={control}
                                                render={({ field }) => (
                                                    <Input
                                                        label="Email"
                                                        {...field}
                                                    />
                                                )}
                                            />
                                            <div className='w-full py-2'>
                                                <Controller
                                                    name="Gender"
                                                    rules={{ required: 'Gender is required' }}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <SelectElement
                                                            name="Gender"
                                                            onChange={(e: SelectChangeEvent) => {
                                                                field.onChange(e);
                                                                handleSelectChange(e);
                                                            }}
                                                            value={field.value}
                                                            options={['female', 'male']}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <Controller
                                                name="phoneNumber"
                                                rules={{
                                                    required:
                                                        'Phone number is required',
                                                }}
                                                control={control}
                                                render={({ field }) => (
                                                    <Input
                                                        label="Phone Number"
                                                        {...field}
                                                    />
                                                )}
                                            />
                                            <div className="coloredInput">
                                                <div className="flex flex-col items-start relative">
                                                    <Controller
                                                        name="password"
                                                        rules={{
                                                            required:
                                                                'Password is required',
                                                        }}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <PasswordInput
                                                                {...field}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <BlockButton
                                                text="Proceed"
                                                disabled={!isDirty || !isValid}
                                                type="submit"
                                                isLoading={isSubmitting}
                                                onclick={() => onSubmitForm(watch())}
                                            />
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="ml-auto mt-10 w-full flex justify-end">
                            <ButtomLogo/>
                            </div>
                        </div>
                    </>
                )}
                {showProfessionalPage && (
                    <ProfessionalInformation
                        formData={formData}
                        showFirstComponent={hideProfessionalPage}
                        email={watch('email')}
                    />
                )}
                </>
                <Modal
                    isVisible={isModalOpen}
                    onClose={onClose}
                    onSave={handleModalSubmit}
                    buttonEnabled={isCheckboxChecked}
                    setButton={disabled}
                    saveText="Proceed"
                    closeText="Cancel"
                    showCloseButtonBorder={false}
                    allowCloseOnOuterClick ={false}
                >
                    <div className="bg-white md:p-3 p-2 rounded-[28px] mx-2 max-w-[438px]">
                            <h1 className="font-semibold text-[#201A19] text-2xl sm:text-[32px] mb-5">
                            Please read carefully
                            </h1>
                            <p className="text-[#534341] py-4 text-[12px] sm:text-[14px] font-medium max-w-[486px] w-full">
                            You are about to create a Super Admin account. Please be informed that the Super Admin account is very sensitive. Creating a Super Admin account will grant you access to almost every feature of the hospital portal including sending and receiving funds via the hospital wallet. Please be advised that you trust the individual who has permission as a Super Admin or perhaps the hospital owner may best be registered as the Super Admin. Again, please be reminded that TMR will not be responsible for the actions of a Super Admin.                            
                            </p>
                        
                            <div className='flex items-center mt-4'>
                            
                            
                            <input
                            type="checkbox"
                            checked={isCheckboxChecked}
                            onChange={handleCheckboxChange}
                        
                        />
                            <span className="ml-2 pl-2"> I acknowledge that I have read and understood the disclaimer provided above.</span>
                        
                            </div>
                    </div>
                </Modal>
                <Modal
        isVisible={isAcceptModalVisible}
        onClose={handleAcceptCancel}
        buttonEnabled={true}
        // isLoading={isAccepting}
        // loadingText="Updating..."
        setButton={()=>{}}
        onSave={handleAcceptConfirm}
        saveText="Cancel Registration"
        closeText={'Cancel'}
        showCloseButtonBorder={false}
      >
        <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2  lg:w-[482px]">
          <h2 className="font-semibold text-2xl mb-3">Cancel Registration</h2>
          <p className=" text-[14px] font-normal text-[#534341]">
          Are you sure you want to cancel this registration process ? 
                    </p>
            

          
        </div>
      </Modal>
            </Fragment>
        </Layout>
    );
};

export default PersonalInformation;
