import Tabs from './Tabs';

const ActiveState = () => {
  
    
    return (
        <div className="mt-7 sm:mt-0 w-full bg-[#F5F5F5] ">
            <div className="px-4 py-4 font-700 text-xl text-black border-transparent bg-[#FFFFFF]  p-2 m-4  rounded-2xl overflow-x-auto sm:overflow-hidden">
                <Tabs />
            </div>
        </div>
    );
};

export default ActiveState;
