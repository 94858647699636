import React, { useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';



type propType = {
    closeModal:any;
    handleSubmit:any;
    isLoading:any;
    setButton:any;
    buttonEnabled:any
    
};
const OTPModal: React.FC<propType> = ({
    closeModal,handleSubmit,isLoading,setButton,buttonEnabled
}) => {

    
const [OTP,setOTP]=useState('')
    useEffect(() => {
        if (OTP.length === 4) {
            setButton(true);
         
            console.log('otp button enabled')
        } else {
            setButton(false);
        }
    }, [OTP,setButton]);

const handleClose = (e:any)=>{
    e.preventDefault()
  closeModal()
}
const handleOTPSubmit = ()=>{
    handleSubmit(OTP)
}
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-[#2826265E] bg-opacity-75 z-50">

            <div className="bg-white p-6 rounded-[28px] w-fit m-2  sm:m-0 relative overflow-x-auto">
            <div className="flex items-center justify-between mb-8">
                    <div className="flex items-center justify-center flex-col gap-2">
                       
                           
                      
                        <h6 className="text-[22px] font-medium text-lightBrown">
                        Enter OTP
                        </h6>
                        <p className='text-[14px] font-normal text-[#534341]'>Kindly enter code sent to your superadmin email</p>
                    </div>
                   
                </div>
                <OtpInput
                    value={OTP}
                    onChange={setOTP}
                    numInputs={4}
                    inputType="number"
                    containerStyle={'my-5'}
                    inputStyle={
                        'border bg-[#EDE0DE] !w-[50px] sm:!w-[61px] h-[50px] sm:h-[54px] mx-2 outline-none rounded-[8px] codeInput'
                    }
                    renderSeparator={<span></span>}
                    renderInput={(props) => <input {...props} />}
                />
                  <div className='flex items-center gap-4 justify-center'>
                    <button onClick={handleClose} className='text-Tmred flex-1  h-[40px] font-semibold text-[16px] justify-center mt-4'>
                        Cancel
                    </button>
                    <button
                        onClick={handleOTPSubmit}
                        className={`w-full h-[40px] flex-1 justify-center rounded-lg items-center text-[#9d9b9b] font-semibold mt-4  hover:cursor-not-allowed transition-all duration-500  ${
                            buttonEnabled
                                ? 'bg-Tmred text-white hover:cursor-pointer '
                                : 'bg-[#1D1B201F] '
                        } `}
                    >
                     {isLoading? 'confirming...': 'Confirm'}  
                    </button>

                </div>
            </div>
            
         
              
         
        </div>
    );
};
export default OTPModal;
