import React from 'react';
import DashboardLayout from '../../Dashboard/DashboardLayout';
import { Helmet } from 'react-helmet';
import Voucher from '../../../../components/Voucher/Voucher';

const HospitalVoucher = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>TMR | Voucher</title>
            </Helmet>
            <div className="">
                <Voucher walletType="hospital" />
            </div>
        </DashboardLayout>
    );
};

export default HospitalVoucher;
