import React, { useState, ReactNode, Fragment } from 'react';
import search from '../../../Images/Leading-icon.png';
import Filter from '../../../Images/filtericon.svg';
import FilterAdsModal from './FilterAdsModal';


interface Tab {
    title: string;
    content: ReactNode;
    count: number;
}

interface TabsProps {
    tabs: Tab[];
    onSearchChange: (searchTerm: string) => void;
    onFilter: (queryParams: string) => void;
}

const Tabs: React.FC<TabsProps> = ({ tabs, onSearchChange, onFilter }) => {
    const [activeTab, setActiveTab] = useState(0);
   
    const [buttonEnabled, setButton] = useState(false);
    // const [selectedFromTime, setSelectedFromTime] = useState('');
    // const [selectedToTime, setSelectedToTime] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [filters, setFilters] = useState<any>({});
    
   

    const [show, setShow] = useState<boolean>(false);
  const handleModal = () => {
    setShow((prev) => !prev);
  };
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = e.target.value;
        setSearchQuery(searchTerm);
        onSearchChange(searchTerm);
    };
   
    const handleSubmit = (queryParams: string) => {
        console.log('Form submitted!');
        setShow(false);
        setButton(false);
        onFilter(queryParams);
    };
    const handleResetFilters = () => {
        
        setFilters({});
      };
    return (
        <Fragment>
            <div className="w-full">
                <div className=" flex flex-col">
                    <div className="flex flex-col-reverse sm:flex-row justify-between w-full border-[#EDE0DE] border-b ">
                        <div className="flex">
                            {tabs.map((tab, index) => (
                                <div
                                    key={index}
                                    onClick={() => setActiveTab(index)}
                                    className={`flex items-center tab-small-screen  ${
                                        activeTab === index
                                            ? 'border-b-2  border-Tmred'
                                            : ''
                                    }`}
                                >
                                    <div className={`mx-2 flex  items-center `}>
                                        <button
                                            key={index}
                                            className={`px-2 py-2 font-medium text-[14px]  ${
                                                index > 0 ? 'ml-2' : ''
                                            } ${
                                                activeTab === index
                                                    ? 'text-Tmred'
                                                    : 'text-[#534341]'
                                            } focus:outline-none`}
                                        >
                                            {tab.title}
                                        </button>
                                        <span
                                            className={` w-[24px] h-[26px]  justify-center p-1 flex items-center  bg-Tmred text-[12px] rounded-[100px] text-[#fff]  focus:outline-none`}
                                        >
                                            {/* {tab.content && React.Children.count(tab.content)} */}
                                            {tab.count}
                                        </span>
                                    </div>
                                </div>
                            ))}
                            <div></div>
                        </div>
                        <div className="flex  justify-between pb-[10px]">
                            <div className=" flex items-center gap-2 border bg-[#F5F6F7] border-[#F5F6F7] rounded-[28px] sm:max-w-[328px]">
                                <img src={search} alt="search" />
                                <input
                                    type="text"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    className="w-full font-medium text-[14px] bg-transparent text-[#534341] border-transparent outline-none focus:ring-0"
                                />
                            </div>
                            <div
                                className="flex mr-4 ml-4 items-center"
                                onClick={handleModal}
                            >
                                <img
                                    src={Filter}
                                    alt="filterIcon"
                                    className="cursor-pointer h-[24px] w-[24px]"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="">{tabs[activeTab].content}</div>
                </div>
            </div>
            
            {/* <FilterModal
                isVisible={showModal}
                onClose={closeModal}
                onSave={handleSubmit}
                buttonEnabled={buttonEnabled}
                setButton={setButton}
                resetFilters={handleResetFilters}
                currentFilters={filters}
            /> */}
            {show &&(
            <FilterAdsModal
            resetFilters={handleResetFilters}
            currentFilters={filters}
            handleModal={handleModal}
            
            onFilterApply={handleSubmit}
  />)}
        </Fragment>
    );
};

export default Tabs;
