import { useEffect, useState } from 'react';
import VoucherIcon from '../../Images/voucher-icon.svg';
import Ticket from '../../Images/ticket.svg';
import Card from '../dashboard/DashboardCard';
import { toast } from 'react-toastify';
import VoucherCard from './VoucherCard';
import RecentVoucher from './RecentVoucher';
import VoucherModal from './Modals/VoucherModal';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Input from '../../reusable/Forms/Input';
import BlockButton from '../../reusable/BlockButton';
import VoucherEye from '../../Images/VoucherEye';
import VoucherEyeOpen from '../../Images/VoucherEyeOpen';
import { useMutation } from 'react-query';
import { adminService } from '../../services/adminService';

type InputProps = {
    amount: string;
};

const Voucher = ({ walletType }: { walletType: string }) => {
    const [voucherCount, setVoucherCount] = useState<any>();
    const [voucherData, setVoucherData] = useState<any>();
    const [voucherStatus, setVoucherStatus] = useState<any>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [onOpen, setOnOpen] = useState<boolean>(false);
    const [showCode, setShowCode] = useState<boolean>(false);
    const [showCode2, setShowCode2] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const closeModal = () => {
        setShowModal(false);
    };
    const setModal = () => {
        setShowModal(true);
    };
    const vouchersData = {
        title: 'Vouchers',
        firstData: 'Used',
        firstDataValue: voucherCount?.count?.used,
        secondData: 'Unused',
        secondDataValue: voucherCount?.count?.unused,
        thirdData: 'Bought',
        thirdDataValue: voucherCount?.count?.bought,
        iconSrc: VoucherIcon,
    };

    const toggleShowCode = () => {
        setShowCode(!showCode);
    };
    const toggleShowCode2 = () => {
        setShowCode2(!showCode2);
    };
    const {
        formState: { isDirty, isValid },
        handleSubmit,
        control,
        reset,
    } = useForm<InputProps>();

    const mutation = useMutation((data: InputProps) =>
        adminService.purchaseVoucher(data, walletType)
    );

    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            const response = mutation.data;
            const message = response.data.message;
            toast.success(message);
            const link = response.data.payload.data.paymentLink;
            // setOnOpen(false);
            const openWindowWithDelay = () => {
                window.open(link, '_blank');
            };

            // Set a 3-second delay
            setTimeout(openWindowWithDelay, 2000);
            reset({});
        }
    }, [mutation.isSuccess, mutation.data, reset]);

    useEffect(() => {
        if (mutation.isError) {
            setIsLoading(false);
            let err: any = mutation.error;
            console.log('is error', err);
            setErrorMessage(
                err.response?.data?.error?.message || 'Something went wrong'
            );
            toast.error(err.response?.data?.error?.message);
            reset({});
        } else {
            setErrorMessage('');
        }
    }, [mutation.isError, mutation.error]);

    const onSubmitForm: SubmitHandler<InputProps> = (data) => {
        // console.log('submit data:', data);
        setIsLoading(true);
        mutation.mutate(data);
    };

    //get voucher
    useEffect(() => {
        adminService
            .getAllVoucher(walletType) // Get All voucher
            .then((res) => {
                console.log(res);
                setVoucherData(res);
                console.log(voucherData);
            })
            .catch((err) => {
                toast.error(err);
            });
    }, []);
    //get voucher
    useEffect(() => {
        adminService
            .getAllVoucher(walletType, 'unused') // Get All voucher
            .then((res) => {
                console.log(res);
                setVoucherStatus(res);
                console.log(voucherData);
            })
            .catch((err) => {
                toast.error(err);
            });
    }, []);
    console.log('status', voucherStatus);
    useEffect(() => {
        adminService
            .getVoucherCount(walletType) // Get voucher count
            .then((res) => {
                console.log(res);
                setVoucherCount(res);
                console.log(voucherCount.count);
            })
            .catch((err) => {
                toast.error(err);
            });
    }, []);
    useEffect(() => {
        if (mutation.isSuccess) {
            // Refresh all necessary GET requests when a vouvher is successfuly purchased
            adminService.getAllVoucher(walletType).then((res) => {
                setVoucherData(res);
            });
    
            adminService.getAllVoucher(walletType, 'unused').then((res) => {
                setVoucherStatus(res);
            });
    
            adminService.getVoucherCount(walletType).then((res) => {
                setVoucherCount(res);
            });
        }
    }, [mutation.isSuccess, walletType]);
    
    return (
        <div className="md:px-8 py-6 px-2 gap-4 w-full ">
            <div className="flex justify-between items-center mb-6">
                <h2 className="font-semibold text-[28px]">Voucher</h2>
                <button
                    onClick={() => {
                        setOnOpen(true);
                    }}
                    className="text-white flex gap-3 font-light bg-[#C00010] rounded-[8px] px-3  sm:px-[16px] sm:py-[10px] py-2 text-[16px]"
                >
                    <img src={Ticket} alt="voucher-icon" />
                    Purchase voucher
                </button>
                <div className="absolute">
                    <VoucherModal
                        isVisible={onOpen}
                        onClose={() => setOnOpen(false)}
                        header={'Purchase Voucher'}
                    >
                        <div>
                            <p className="my-6">
                                Kindly enter the amount you want to buy
                            </p>
                            <form onSubmit={handleSubmit(onSubmitForm)}>
                                <Controller
                                    name="amount"
                                    rules={{
                                        required:
                                            'Search Parameter is required',
                                    }}
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            label="Enter Amount"
                                            type="number"
                                            {...field}
                                        />
                                    )}
                                />

                                <BlockButton
                                    text="Proceed"
                                    disabled={!isDirty || !isValid}
                                    type="submit"
                                    isLoading={false}
                                    className="mt-5 p-3"
                                />
                            </form>
                        </div>
                    </VoucherModal>
                </div>
            </div>
            <div className="flex h-[14rem] justify-between gap-4 w-full flex-col sm:flex-row">
                <Card {...vouchersData} />
                <div className="px-4 relative h-full py-4 w-[100%] rounded-xl bg-white md:overflow-hidden gap-4">
                    <div className="h-fit flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                            <h2 className="text-black font-bold flex gap-2 text-md md:text-xl">
                                Unused Vouchers
                                <span className="md:text-[16px] text-sm bg-red py-[3px] w-[30px] h-[30px] text-white px-1 md:px-[8px] flex justify-center items-center rounded-[290.909px]">
                                    {voucherCount?.count?.unused}
                                </span>
                            </h2>
                            <button onClick={toggleShowCode}>
                                {showCode ? <VoucherEyeOpen /> : <VoucherEye />}
                            </button>
                        </div>
                        <button className="hover:underline" onClick={setModal}>
                            See all
                        </button>
                    </div>
                    {voucherStatus?.vouchers.length !== 0 ? (
                        <div className="h-[82%] p-[0.1rem] overflow-y-auto rounded-xl grid grid-cols-4 gap-2 mt-2">
                            {voucherStatus?.vouchers.map((vouch, i) => (
                                <VoucherCard
                                    key={i}
                                    code={
                                        showCode ? `${vouch.code}` : '********'
                                    }
                                    show={showCode}
                                    amount={vouch.amount}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="h-[82%] rounded-xl flex justify-center items-center bg-[#F8F8F8] mt-2">
                            <p className="text-sm font-medium text-dark100">
                                Your available vouchers will appear here.
                            </p>
                        </div>
                    )}

                    <VoucherModal
                        isVisible={showModal}
                        onClose={() => closeModal()}
                        header={'Unused Voucher'}
                        headerContent={
                            <button onClick={toggleShowCode2}>
                                {showCode2 ? (
                                    <VoucherEyeOpen />
                                ) : (
                                    <VoucherEye />
                                )}
                            </button>
                        }
                    >
                        <div className="h-[25rem]">
                            <div className="w-[32rem] overflow-y-auto rounded-xl p-1 grid grid-cols-3 gap-y-4 gap-x-3 mt-2">
                                {voucherStatus?.vouchers.map((vouch, i) => (
                                    <VoucherCard
                                        key={i}
                                        code={
                                            showCode2
                                                ? `${vouch.code}`
                                                : '********'
                                        }
                                        show={showCode2}
                                        amount={vouch.amount}
                                    />
                                ))}
                            </div>
                        </div>
                    </VoucherModal>
                </div>
            </div>
            <RecentVoucher walletType={walletType} />
        </div>
    );
};

export default Voucher;
