import React, { Fragment, useState } from 'react';
import { SlArrowLeft } from 'react-icons/sl';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import Input from '../../../reusable/Forms/Input';
import { IoCloudUploadOutline } from 'react-icons/io5';
import { IoIosAttach } from 'react-icons/io';
import search from '../../../Images/Leading-icon.png';
import { useQuery } from 'react-query';
import Lottie from 'lottie-react';
import loadingState from '../../../Images/loading.json';
import { pharmacyService } from '../../../services/pharmacyService';
import { Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
// import { DetailsContent } from './OrderDetails';
import { pharmacyRoutes } from '../../../utils/pharmacyRouteNames';
import DrugSearchModal from './Modals/DrugSearchModal';
import { standardAmountFormat } from '../../../utils/helpers';
import { usePharmacyAuthStore } from '../../../store/pharmacy/auth';

type InputProps = {
    fullName: string;
    TmrNum: string;
    phoneNumber: string;
    email: string;
    address: string;
    shippingMethod: any;
    shippingAddress: string;
    orderStatus: any;
    deliveryStatus: any;
    name:any;
}

function CreateOrder() {
    const navigate = useNavigate();
    const {
        formState: { isDirty, isValid },
        handleSubmit,
        control,
        watch,
        setValue
    } = useForm<InputProps>();
    const homeAddress = watch('address');
    
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedDrugs, setSelectedDrugs] = useState<{ _id: number, name: string, price: number, quantity: number,drug:any }[]>([]);
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const currency = usePharmacyAuthStore((state) => state.pharmacy.currency);
    const {
        data: drugsData,
        isError: isdrugsError,
        isLoading: isdrugsLoading,
    } = useQuery('drugs', () => pharmacyService.getAllDrugs(), {
        enabled: isModalOpen // Fetch data only when modal is open
    });
   
    
    
console.log({drugsData})
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setValue('shippingAddress', homeAddress);
        } else {
            setValue('shippingAddress', '');
        }
    };
    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] || null;
        setUploadedFile(file);
        
    };
    
//    to check if doctors report is not uploaded when doctors report is true
const isDoctorReportRequiredAndNotUploaded = () => {
    return selectedDrugs.some(drug => drug.drug?.doctorReportRequired) && !uploadedFile;
};

    const handleSelectDrug = (drug: { _id: number, name: string, price: number,drug:any }) => {
        if (!selectedDrugs.some(selectedDrug => selectedDrug._id === drug._id)) {
            setSelectedDrugs([...selectedDrugs, { ...drug, quantity: 1 }]); 
        }
        setModalOpen(false);
        console.log(drug.drug)
    };
    const handleQuantityChange = (drugId: number, increment: boolean) => {
        setSelectedDrugs(prevDrugs =>
            prevDrugs.map(drug =>
                drug._id === drugId
                    ? { ...drug, quantity: drug.quantity + (increment ? 1 : -1) }
                    : drug
            ).filter(drug => drug.quantity > 0)
        );
    };

    const handleDrugDelete = (drugId: number) => {
        setSelectedDrugs(prevDrugs =>
            prevDrugs.filter(drug => drug._id !== drugId)
        );
    };

    const goBack = () => {
        navigate(-1);
    };

    const onSubmitForm: SubmitHandler<InputProps> = (data) => {
        navigate(pharmacyRoutes.previewOrder, {
            state: { ...data, uploadedFile, selectedDrugs }
        });
    };

    const total = selectedDrugs.reduce((acc, drug) => acc + drug.price * drug.quantity, 0);

    return (
        <Fragment>
             <div className="w-full bg-[#F5F5F5] h-full px-6 py-6">
            <div className="flex items-center space-x-2 mb-6 cursor-pointer">
                <SlArrowLeft className="m-2" onClick={goBack} />
                <h6 className="font-semibold text-[28px] text-lightBrown">Create Order</h6>
            </div>
            <form onSubmit={handleSubmit(onSubmitForm)} noValidate className="flex flex-col md:flex-row gap-3">
                <div className="flex flex-col lg:w-2/3">
                    <div className="flex-col py-4 font-700 text-xl text-black border-transparent bg-[#FFFFFF] rounded-2xl max-w-[742px] flex p-4">
                        <div className='flex items-center justify-between'>
                            <h3 className=' text-[24px] font-medium text-[#201A19]'>Order Details </h3>
                            {/* <p className='text-[#534341] font-semibold text-[16px]'>Invoice ID #200928</p> */}
                        </div>
                        <div>
                            <div className="flex items-center justify-between mt-10  w-full ">
                                <h2 className="font-semibold text-base text-lightBrown w-1/3">Personal information</h2>
                                <span className="border-t h-[1px] flex-1 border-brown100 w-2/3"></span>
                            </div>
                            <div>
                                <div className="flex items-center my-6 space-x-6">
                                    <Controller
                                        name="fullName"
                                        defaultValue=""
                                        rules={{ required: 'Full Name is required' }}
                                        control={control}
                                        render={({ field }) => <Input label="Full Name" {...field} />}
                                    />
                                    <Controller
                                        name="TmrNum"
                                        defaultValue=""
                                        control={control}
                                        render={({ field }) => <Input label="TMR Number (Optional)" {...field} />}
                                    />
                                </div>
                                <div className="flex items-center my-6 space-x-6">
                                    <Controller
                                        name="phoneNumber"
                                        defaultValue=""
                                        rules={{ required: 'Phone Number is required' }}
                                        control={control}
                                        render={({ field }) => <Input label="Phone Number" {...field} />}
                                    />
                                    <Controller
                                        name="email"
                                        defaultValue=""
                                        control={control}
                                        rules={{ required: 'Email is required' }}
                                        render={({ field }) => <Input label="Email Address" {...field} type='email' />}
                                    />
                                </div>
                                <Controller
                                    name="address"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: 'Address is required' }}
                                    render={({ field }) => (
                                        <textarea
                                            placeholder="Home Address"
                                            required={true}
                                            className="w-full h-[100px] my-6 border-[1px] border-dark100 p-4 rounded-[8px] text-[16px] tracking-wider focus:border-[1px] focus:outline-none focus:border-dark100"
                                            {...field}
                                        ></textarea>
                                    )}
                                />
                            </div>

                           
                            <div className="flex items-center justify-between mt-10 w-full">
    <h2 className="font-semibold text-base text-lightBrown w-1/3">Doctor’s Report</h2>
    <span className="border-t h-[1px] flex-1 border-brown100 w-2/3"></span>
</div>
<div className='mt-3 flex justify-between rounded-lg items-center w-full border border-[#EEE7E7] py-2 px-4'>
    <span className='flex gap-2 items-center'>
        <IoIosAttach />
        <p className='text-[#534341] font-medium text-[14px]'>
            {uploadedFile ? uploadedFile.name : 'No file selected'}
        </p>
    </span>
    <input 
        type="file" 
        accept="image/*" 
        onChange={handleFileUpload} 
        className="hidden" 
        id="file-upload" 
    />
    <label htmlFor="file-upload" className="cursor-pointer">
        <IoCloudUploadOutline />
    </label>
</div>

                            <div className="flex items-center justify-between mt-10  w-full ">
                                <h2 className="font-semibold text-base text-lightBrown w-1/3">Shipping Information</h2>
                                <span className="border-t h-[1px] flex-1 border-brown100 w-2/3"></span>
                            </div>
                            <div className="flex-col py-4 font-700 text-xl text-black border-transparent bg-[#FFFFFF] rounded-2xl max-w-[359px] flex p-4">
                                <Controller
                                    name="shippingMethod"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: 'Shipping Method is required' }}
                                    render={({ field }) => (
                                        <FormControl component="fieldset" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <FormLabel component="legend" style={{ marginRight: '16px' }}>Shipping Method</FormLabel>
                                            <RadioGroup {...field} row>
                                                <FormControlLabel value="pickup" control={<Radio />} label="Pick Up" />
                                                <FormControlLabel value="delivery" control={<Radio />} label="Home Delivery" />
                                            </RadioGroup>
                                        </FormControl>
                                    )}
                                />
                            </div>
                            <Controller
                                name="shippingAddress"
                                control={control}
                                defaultValue=""
                                rules={{ required: 'Shipping Address is required' }}
                                render={({ field }) => (
                                    <textarea
                                        placeholder="Shipping Address"
                                        required={true}
                                        className="w-full h-[100px] mt-6 border-[1px] border-dark100 p-4 rounded-[8px] text-[16px] tracking-wider focus:border-[1px] focus:outline-none focus:border-dark100"
                                        {...field}
                                    ></textarea>
                                )}
                            />
                            <div className="flex items-center">
                                <Checkbox onChange={handleCheckboxChange} />
                                <span className="ml-2 text-[#534341] font-medium text-[14px]">Same as Home Address</span>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="flex flex-col lg:w-1/3 gap-6 ">
                    <div className="flex-col py-4 font-700 text-xl text-black border-transparent bg-[#FFFFFF] rounded-2xl max-w-[359px] flex p-4">
                        <div>
                            <h3>Items Purchased</h3>
                            <div className="my-5 flex items-center gap-2 rounded-[24px] w-full bg-[#F5F6F7]">
                                <img src={search} alt="search" />
                                <input
                                    type="text"
                                    placeholder="Search "
                                    onFocus={() => setModalOpen(true)}
                                    className="w-full font-normal text-[16px] bg-transparent text-[#534341] border-transparent outline-none focus:ring-0"
                                />
                                  
                            </div>
                            <div className='flex items-center gap-1'>
                            <h2 className='text-[#201A19] text-[16px] font-medium '>Items</h2>
                            <span className='h-[15px] w-[16px] bg-[#C00010] text-white rounded-[67px] text-center flex items-center justify-center text-[11px] font-medium'>{selectedDrugs.length}</span>
                            </div>
                            {isdrugsLoading ? (
    <div className="flex justify-center items-center py-32">
        <Lottie
            animationData={loadingState}
            loop={true}
            className="lottie"
        />
    </div>
) : isdrugsError ? (
    <p className="text-red-500">Error loading drugs : {isdrugsError}</p>
) : (
                            <div className='h-[336px] overflow-y-auto'>
                            {selectedDrugs.map((drug, index) => (
                                <div key={drug._id} className="flex mt-3 mb-5 items-center justify-between w-full bg-transparent my-2 p-2 pb-3 rounded border-b border-b-[">
                                    
                                    <div className='flex gap-2 '>
                                    <span className='text-[#201A19] text-[16px] font-medium'>#{index + 1}</span>
                                    <div className='flex flex-col items-center gap-2'>
                                        <p className='text-[#201A19] text-[16px] font-medium w-24 truncate'>{drug.drug.name}</p>
                                        <div className='flex items-center justify-between gap-2'>
                                        <button type="button"  onClick={() => handleQuantityChange(drug._id, false)} className='h-[26px] w-[26px] bg-[#C00010] text-white rounded-[67px] text-center flex items-center justify-center'>-</button>
                                        <p> {drug.quantity}</p>
                                            <button type="button"  onClick={() => handleQuantityChange(drug._id, true)} className='h-[26px] w-[26px] bg-[#C00010] text-white rounded-[67px] text-center flex items-center justify-center' >+</button>
                                        </div>
                                       
                                       <p className='text-[#201A19] font-normal text-[12px]'>Doctor's Report & Prescription</p>
                                    </div>
                                    </div>
                                    <div className='flex flex-col gap-2'>
                                    <p className='text-[#201A19] text-[16px] font-medium'>{standardAmountFormat(drug.price,currency)}</p>
                                    <button onClick={() => handleDrugDelete(drug._id)} className='border border-[#D8C2BF] text-[#201A19] font-normal text-[12px] rounded-[100px] px-[6px] '>Delete Item</button>
    <p className='text-[#201A19] font-medium text-[12px]'>{drug.drug?.doctorReportRequired ? 'Yes' :'No'}</p>
                                    </div>
                                </div>
                            ))}
                            </div>
                           )}
                            <div className='border-t border-[#D8C2BF] w-full py-2 my-2 flex flex-row items-center justify-between'>
                                <p className='font-medium text-[12px] text-[#534341]'>Grand Total</p>
                                <p className='text-[#C00010] font-medium text-[14px] '>{standardAmountFormat(total,currency)}</p>
                            </div>
                            <button
                                disabled={!isDirty || !isValid || selectedDrugs.length === 0 || isDoctorReportRequiredAndNotUploaded() }
                                type="submit"
                                className={`w-full my-3 px-6 py-2 text-lightBrown text-base font-normal rounded-lg ${
                                    !isDirty || !isValid || selectedDrugs.length === 0 || isDoctorReportRequiredAndNotUploaded() 
                                        ? 'bg-lightGray cursor-not-allowed'
                                        : ' bg-Tmred text-white hover:cursor-pointer'
                                }`}
                            >
                                Preview Order
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            
        </div>
        {
            isModalOpen && (

           
        <DrugSearchModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onSelectDrug={handleSelectDrug}
        selectedDrugs={selectedDrugs}
        drugsData={drugsData}
        isDrugsLoading={isdrugsLoading}
        isDrugsError={isdrugsError}
    />
)
}
    </Fragment>

    );
}

export default CreateOrder;
