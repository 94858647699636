import React, { useState, ChangeEvent, useEffect } from 'react';
import Layout from './../Layout';
import AuthHeader from '../../../reusable/AuthHeader';
import arrowLeft from './../../../Images/arrow-left.svg';
import count2 from './../../../Images/count2.svg';
import Input from '../../../reusable/Forms/Input';

import {
    Select,
    MenuItem,
    SelectChangeEvent,
    InputLabel,
    FormControl,
} from '@mui/material';
import BlockButton from '../../../reusable/BlockButton';
import { Link } from 'react-router-dom';

interface CreateAcctTwoProps {
    showThirdComponent: () => void;
    showFirstComponent: () => void;
    setFormData: any;
    formData: any;
}

const CreateAcctTwo: React.FC<CreateAcctTwoProps> = ({
    showThirdComponent,
    showFirstComponent,
    formData,
    setFormData,
}) => {
    const [isDisabled, setIsDisabled] = useState(true);
    const [showEmailError, setShowEmailError] = useState(false);
    // const [filteredStates, setFilteredStates] = useState(formData.countryStates);

    const handleBack = () => {
        showFirstComponent();
    };

    function handleChange(
        e: ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
    ) {
        setFormData((prevData: any) => {
            return {
                ...prevData,
                [e.target.name]: e.target.value,
            };
        });
    }

    // useEffect to set form valid to true

    useEffect(() => {
        if (
            formData.phoneNumber !== '' &&
            formData.name !== '' &&
            formData.state !== '' &&
            formData.email !== '' &&
            formData.address !== '' &&
            formData.coordinates !== ''
        ) {
            // console.log('form valid', formData);
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [formData]);

    const handleSelectChange = (e: SelectChangeEvent) => {
        setFormData((prevData: any) => ({
            ...prevData,
            [e.target.name || '']: e.target.value,
        }));
    };

    const handlesubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/i.test(formData.email)) {
            setShowEmailError(true);
            setTimeout(() => {
                setShowEmailError(false);
            }, 3000);
            return;
        }
        showThirdComponent();
    };

    useEffect(() => {
        if (navigator.geolocation) {
            // get the current users location
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    // save the geolocation coordinates in two variables
                    const { latitude, longitude } = position.coords;
                    // update the value of coordinate variable
                    setFormData((prevData: any) => {
                        return {
                            ...prevData,
                            coordinates: `${latitude}, ${longitude}`,
                        };
                    });
                },
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    }, []);

    return (
        <Layout>
            <div className="py-[60px]">
                <AuthHeader />
            </div>
            <div className="flex flex-col justify-center p-[1.5rem] lg:p-0 items-center mb-11 ">
                <div className="flex flex-col justify-center border border-[#00000033] bg-white rounded-[25px] p-[2rem] lg:px-[40px] lg:py-[40px] max-w-[592px] sm:w-full mb-10 ">
                    <div className="flex flex-row justify-between gap-1 sm:gap-5">
                        <div className="flex">
                            <button onClick={handleBack} className="flex">
                                <img src={arrowLeft} alt="arrow-left" />
                            </button>
                        </div>
                        <div>
                            <h1 className=" font-semibold text-center  text-[1.2rem] lg:text-[32px]">
                                {' '}
                                Register your Pharmacy{' '}
                            </h1>
                        </div>
                        <div className="flex">
                            <img src={count2} alt="count1" />
                        </div>
                    </div>
                    <p className="text-black text-center pt-4">
                        Kindly fill in your details to register
                    </p>

                    <div className="flex flex-col items-center mt-[40px]">
                        <form
                            className="w-full flex flex-col gap-4"
                            onSubmit={handlesubmit}
                            noValidate
                        >
                            <Input
                                name="name"
                                label="Pharmacy Name"
                                value={formData.name}
                                onChange={handleChange}
                                className={
                                    'w-full border border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider'
                                }
                            />
                            <Input
                                name="email"
                                label="Email Address"
                                value={formData.email}
                                onChange={handleChange}
                                className={
                                    'w-full border border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider'
                                }
                            />
                            {showEmailError && (
                                <div className="flex items-center r">
                                    <p
                                        className={` max-w-[567px] text-[#E25F5F] font-normal  text-[14px] sm:text-[18px] sm:text-center flex items-center justify-center`}
                                    >
                                        Please enter a valid email address
                                    </p>
                                </div>
                            )}
                            <Input
                                name="phoneNumber"
                                label="Phone Number"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                className={
                                    'w-full border border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider'
                                }
                            />

                            <div className="flex md:flex-row flex-col gap-3 md:gap-5 justify-between">
                                <div className="md:flex-1">
                                    <FormControl
                                        fullWidth
                                        sx={{ minWidth: 120 }}
                                        size="small"
                                    >
                                        <InputLabel
                                            id="demo-select-small-label"
                                            htmlFor={'state'}
                                        >
                                            {'State'}
                                        </InputLabel>

                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            label="State"
                                            onChange={handleSelectChange}
                                            name="state"
                                            value={formData.state}
                                            className="w-full  p-2 "
                                        >
                                            {formData.countryStates.map(
                                                (state: any) => (
                                                    <MenuItem
                                                        key={state.code}
                                                        value={state.name}
                                                    >
                                                        {state.name}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="md:flex-1">
                                    <Input
                                        name="city"
                                        label="City"
                                        value={formData.city}
                                        onChange={handleChange}
                                        className="w-full border border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider"
                                    />
                                </div>
                            </div>

                            <textarea
                                name="address"
                                placeholder="Pharmacy Address"
                                required={true}
                                value={formData.address}
                                onChange={handleChange}
                                className="w-full border h-[150px] border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider"
                            ></textarea>

                            <Input
                                name="coordinates"
                                label="Coordinates (Latitude & Longitude)"
                                value={formData.coordinates}
                                onChange={handleChange}
                                className={
                                    'w-full border border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider'
                                }
                            />
                            <span className=" text-gray-500 text-sm">
                                <Link
                                    to="https://maps.google.com"
                                    className=" text-Tmred"
                                    target="_"
                                    rel="nofollow noindex"
                                >
                                    Click here to search
                                </Link>{' '}
                                your pharmacy on google maps, right-click copy
                                coordinates and paste here
                            </span>

                            <BlockButton
                                text="Proceed"
                                disabled={isDisabled}
                                type="submit"
                            />
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default CreateAcctTwo;
