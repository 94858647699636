import React from 'react';
import DashboardLayout from '../Dashboard/DashboardLayout';
import {Helmet} from 'react-helmet'
import EditPage from '../../../components/PharmacyComponent/Inventory/EditInventory/EditPage';


const EditInventory = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Edit Inventory
                </title>
            </Helmet>
         <EditPage/>
        </DashboardLayout>
    );
};

export default EditInventory;
