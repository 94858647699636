import React from 'react'
import DashboardLayout from '../Dashboard/DashboardLayout'
import { Helmet } from 'react-helmet'
import CreateOrder from '../../../components/PharmacyComponent/Order/CreateOrder'

function Createorder() {
  return (
    <DashboardLayout>
    <Helmet>
        <title>
           TMR | Create Order
        </title>
    </Helmet>
    <div className="">
        <CreateOrder/>
    </div>
</DashboardLayout>
  )
}

export default Createorder