import React from 'react';
import DashboardLayout from '../Dashboard/DashboardLayout';
import { Helmet } from 'react-helmet';
import MyTransactionHistory from '../../../components/Billing/MyTransactioHistory';

const MyTransactionHistories = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>TMR | My Wallet Transactions</title>
            </Helmet>
            <div className="">
                <MyTransactionHistory />
            </div>
        </DashboardLayout>
    );
};

export default MyTransactionHistories;
