import React, { useState, useEffect } from 'react';
import Layout from './../Layout';
import AuthHeader from '../../../reusable/AuthHeader';
import arrowLeft from './../../../Images/arrow-left.svg';
import count3 from './../../../Images/count3.svg';
import { useNavigate } from 'react-router-dom';
import upload from '../../../Images/upload.png';
import Input from '../../../reusable/Forms/Input';
import { useMutation } from 'react-query';

import ErrorState from '../../../reusable/ErrorState';
import BlockButton from '../../../reusable/BlockButton';
import { usePharmacyAccountStore } from '../../../store/pharmacy/auth';
import { pharmacyService } from '../../../services/pharmacyService';
import { useTmrValidationStore } from '../../../store/hospital/auth';
import { pharmacyRoutes } from '../../../utils/pharmacyRouteNames';

interface CreateAcctThreeProps {
    showCreateAcctTwo: () => void;
    setFormData: any;
    formData: any;
}

const CreateAcctThree: React.FC<CreateAcctThreeProps> = ({
    showCreateAcctTwo,
    formData,
    setFormData,
}) => {
    const [isDisabled, setIsDisabled] = useState(true);
    const [displayImg, setDisplayImg] = useState('');
    const [license, setLicense] = useState('');
    const [logo, setImageFile] = useState('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    

    const pharmacyAccountStore = usePharmacyAccountStore((state) => state);
    const tmrValidation = useTmrValidationStore((state) => state.tmrValidation);
    const navigate = useNavigate();
   
    useEffect(() => {
        if (license !== '') {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [license]);

    const mutation = useMutation((data: any) =>
        pharmacyService.createPharmacy(data)
    );

    useEffect(() => {
        if (mutation.isSuccess) {
            console.log('success', mutation.data);
            let payload: any = mutation.data;
            pharmacyAccountStore.updatePharmacy(payload.data.payload.data.pharmacy);
            pharmacyAccountStore.updateToken(payload.data.payload.token);
            navigate(pharmacyRoutes.superAdmin);
        }
    }, [mutation.isSuccess, mutation.data]);

    
    const handleBack = () => {
        showCreateAcctTwo(); // Call the function to show the second component
    };

    // Event handler for input change
    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setFormData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const onImageChange = (e: any) => {
        e.preventDefault();
        const image_as_base64 = URL.createObjectURL(e.target.files[0]);
        const image_as_files = e.target.files[0];
        setDisplayImg(image_as_base64);
        setImageFile(image_as_files);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setErrorMessage('');
        const pharmcyFormData = new FormData();
        pharmcyFormData.append('name', formData.name);
        pharmcyFormData.append('email', formData.email);
        pharmcyFormData.append('phoneNumber', formData.phoneNumber);
        pharmcyFormData.append('address', formData.address);
        pharmcyFormData.append('city', formData.city);
        pharmcyFormData.append('state', formData.state);
        pharmcyFormData.append('country', formData.country);
        pharmcyFormData.append('referralCode', formData.referralCode);
        pharmcyFormData.append(
            'licenseNumber',
            license ? formData.licenseNumber : ''
        );
        if (formData.tagline) {
            pharmcyFormData.append(
                'tagline',
                formData.tagline)
        }
        
        pharmcyFormData.append('coordinates', formData.coordinates);

        if (logo) {
            pharmcyFormData.append('logo', logo);
        }
        
        // Include the hospitalID from the TMR validation store
        if (tmrValidation){
            const hospitalEntity = tmrValidation?.entities?.find((entity: any) => entity?.type === 'hospital');
            pharmcyFormData.append('hospitalId', hospitalEntity?.entity?.id)
        }
        mutation.mutate(pharmcyFormData);
    };

    useEffect(() => {
        if (mutation.isError) {
            let err: any = mutation?.error;
            // console.log(err);
            setErrorMessage(err.message);
        } else {
            setErrorMessage('');
        }
    }, [mutation.isError]);

    return (
        <Layout>
            <div className="py-[60px]">
                <AuthHeader />
            </div>
            <div className="flex flex-col justify-center p-[1.5rem] lg:p-0 items-center mb-11 w-full">
                <div className="flex flex-col justify-center border border-[#00000033] bg-white rounded-[25px] p-[2rem] lg:px-[40px] lg:py-[40px] max-w-[592px] sm:w-full  mb-10">
                    <div className="flex flex-row justify-between items-center gap-1 sm:gap-5">
                        <div className="flex">
                            <button onClick={handleBack} className="flex">
                                <img src={arrowLeft} alt="arrow-left" />
                            </button>
                        </div>
                        <div>
                            <h1 className=" font-semibold text-center text-[1.2rem] lg:text-[32px]  ">
                            Register your Pharmacy 
                            </h1>
                        </div>
                        <div className="flex">
                            <img src={count3} alt="count1" />
                        </div>
                    </div>
                    <p className="text-black pt-4 text-center">
                        Kindly fill in your details to register
                    </p>

                    {errorMessage && <ErrorState message={errorMessage} />}

                    <div className="flex flex-col items-center mt-[40px]">
                        <form
                            className="w-full flex flex-col gap-4 sm:gap-8"
                            onSubmit={handleSubmit}
                        >
                            <Input
                                name="tagline"
                                label="Tag Line (optional)"
                                value={formData.tagline}
                                onChange={handleChange}
                                className={
                                    'w-full border border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider'
                                }
                            />
                            {/* <button
                                onClick={(e) => toggleModal(e)}
                                className="w-full flex items-center justify-between border border-[#4d4544] px-4 py-2 rounded-[8px] text-[16px] tracking-wider  text-left"
                            >
                                <span>{'Category'}</span>
                                <img src={drop} alt="" className="w-[24]" />
                            </button>

                            {showModal && (
                                <SpecialtyModal
                                    searchQuery={searchQuery}
                                    setSearchQuery={setSearchQuery}
                                    selectedSpecialtyIds={selectedSpecialtyIds}
                                    onSpecialtyCheckboxChange={
                                        onSpecialtyCheckboxChange
                                    }
                                    onClose={(_) => {
                                        setShowModal(false); // Close the modal
                                    }}
                                />
                            )}
                            {selectedOSpecialtyNames.length > 0 && (
                                <div className="mt-2">
                                    <ul className="flex flex-wrap gap-2">
                                        {selectedOSpecialtyNames.map(
                                            (option, index) => {
                                                const isLongOption =
                                                    option.length > 20;
                                                const maxContentWidth =
                                                    isLongOption
                                                        ? '300px'
                                                        : '600px';

                                                return (
                                                    <li
                                                        key={index}
                                                        className={`flex items-center w-fit lg:max-w-[${maxContentWidth}] border rounded-lg py-[6px] pr-[8px] pl-[12px] border-[#4D4544] ${
                                                            isLongOption
                                                                ? 'flex-row'
                                                                : 'flex-row'
                                                        }`}
                                                        // style={{
                                                        //     maxWidth:
                                                        //         maxContentWidth,
                                                        // }}
                                                    >
                                                        <span className="font-normal text-[16px] text-[#201A19]">
                                                            {option}
                                                        </span>
                                                        <button
                                                            onClick={() =>
                                                                handleSpecialityRemove(
                                                                    index
                                                                )
                                                            }
                                                            className="ml-2 text-red-500"
                                                        >
                                                            <IoIosClose
                                                                size={'18'}
                                                            />
                                                        </button>
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </div>
                            )} */}

                            <Input
                                name="licenseNumber"
                                label="License number "
                                value={formData.licenseNumber}
                                onChange={(e) => {
                                    handleChange(e);
                                    setLicense(e.target.value);
                                }}
                                className={
                                    'w-full border border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider'
                                }
                            />
                            <div className="flex items-center sm:flex-row flex-col gap-2">
                                <div className="flex flex-col items-center lg:items-start max-w-[152px]">
                                    <p className="font-normal text-[#534341] text-[16px] text-center lg:text-left">
                                        Profile Image (optional)
                                    </p>
                                    <span className="font-normal py-6 text-[#534341] text-[14px] text-left">
                                        PNG or JPG <br />
                                        (max. 4MB)
                                    </span>
                                </div>
                                <div className="relative mb-2 w-max mx-auto">
                                    <div className="w-[150px] h-[150px] rounded-full bg-[#FFDAD5] overflow-hidden">
                                        {displayImg ? (
                                            <img
                                                src={displayImg}
                                                alt=""
                                                className="object-cover w-full h-full rounded-full max-w-[100%]"
                                            />
                                        ) : (
                                            <div className="w-full h-full mx-auto flex flex-col max-w-[134px] justify-center items-center px-3">
                                                <img src={upload} alt="" />
                                                <p className="text-center mt-2 text-[14px] text-[#534341]">
                                                    Click to upload or drag and
                                                    drop
                                                </p>
                                            </div>
                                        )}
                                    </div>

                                    <input
                                        type="file"
                                        accept=".png, .jpeg, .jpg"
                                        name="uploads"
                                        style={{
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                        className="bg-[#FFDAD5] w-[100%] h-[100%] top-[50%] left-[50%] z-[4] opacity-0 cursor-pointer absolute"
                                        onChange={onImageChange}
                                    />
                                </div>
                            </div>
                            <BlockButton
                                text="Register"
                                disabled={isDisabled}
                                type="submit"
                                isLoading={mutation.isLoading}
                            />
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default CreateAcctThree;
