import { pharmacyRoutes } from '../utils/pharmacyRouteNames';

import {
    Hospital,
    Box,
    TruckFast,
    Setting2,
    Profile2User,   
    // Ticket,
    Wallet3,
    Microscope,
} from 'iconsax-react';

const sidebarData = () => {
    let sideBarData = [
        {
            text: 'Dashboard',
            url: pharmacyRoutes.dashboard,
            icon: <Hospital size="24" color="#534341" />,
            activeIcon: <Hospital size="24" color="#ffffff" />,
        },
        {
            text: 'Inventory',
            url: pharmacyRoutes.inventory,
            icon: <Box size="24" color="#534341" />,
            activeIcon: <Box size="24" color="#ffffff" />,
            permissionName: 'inventory',
        },
        {
            text: 'Orders',
            url: pharmacyRoutes.orders,
            icon: <TruckFast size="24" color="#534341" />,
            activeIcon: <TruckFast size="24" color="#ffffff" />,
            permissionName: 'order',
        },
        {
            text: 'Wallet',
            url: pharmacyRoutes.wallet,
            icon: <Wallet3 size="24" color="#534341" />,
            activeIcon: <Wallet3 size="24" color="#ffffff" />,
            sublinks: [
                {
                    text: 'Pharmacy Wallet',
                    url: pharmacyRoutes.pharmacyWallet,
                    permissionName: 'pharmacy wallet',
                },
                {
                    text: 'My Wallet',
                    url: pharmacyRoutes.myWallet,
                },
               
            ],
            
           
        },
        {
            text: 'Ads',
            url: pharmacyRoutes.tmrAds,
            icon: <Microscope size="24" color="#534341" />,
            activeIcon: <Microscope size="24" color="#ffffff" />,
            permissionName: 'ads',
        },
        {
            text: 'Admin',
            url: pharmacyRoutes.admin,
            icon: <Profile2User size="24" color="#534341" />,
            activeIcon: <Profile2User size="24" color="#ffffff" />,
            permissionName: 'admin',
        },
        {
            text: 'Settings',
            url: pharmacyRoutes.setting,
            icon: <Setting2 size="24" color="#534341" />,
            sublinks: [
                {
                    text: 'My Profile',
                    url: pharmacyRoutes.myProfile,
                },
                {
                    text: 'Pharmacy',
                    url: pharmacyRoutes.pharmacyProfile,
                    permissionName: 'settings',
                },
            ],
            activeIcon: <Setting2 size="24" color="#ffffff" />,
        },
       
    ];

    return sideBarData;
};

export default sidebarData;
