import React, { useState, useEffect } from 'react';
import arrowLeft from './../../../Images/arrow-left.svg';
import Passcode from '../../../reusable/Passcode';
import { useNavigate } from 'react-router-dom';
import {
    useResetPasswordStore,
} from '../../../store/hospital/auth';
import { hospitalRoutes } from '../../../utils/routeNames';
import ErrorState from '../../../reusable/ErrorState';
import BlockButton from '../../../reusable/BlockButton';
import { useMutation } from 'react-query';
import hospitalService from '../../../services/hospitalService';
import logger from '../../../utils/logger';

interface propType {
    backToForgotPassword: () => void;
    email: string;
}

type ForgotPasswordOTPProps = {
    otp: string;
    email: string;
}

const OTP: React.FC<propType> = ({ backToForgotPassword, email }) => {
    const navigate = useNavigate();
    const resetPasswordStore = useResetPasswordStore();
    const [ otp, setOTP ] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const mutation = useMutation((data: ForgotPasswordOTPProps) => hospitalService.verifyForgotPasswordOTP(data));

    useEffect(() => {
        if (otp.length === 4) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [otp]);

    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            console.log('success', mutation.data);
            let response: any = mutation.data;
            const data = response.data.payload;
            logger(data);
            resetPasswordStore.setToken(data.token);
            navigate(hospitalRoutes.resetPassword);
        }
    }, [mutation.isSuccess, mutation.data]);

    useEffect(() => {
        if (mutation.isError) {
            setIsLoading(false);
            let err: any = mutation.error;
            setErrorMessage(err.response.data.error.message);
        } else {
            setErrorMessage('');
        }
    }, [mutation.isError, mutation.error]);
    
    const submitForgotPasswordOTP = (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        mutation.mutate({
            otp,
            email
        });
    };
    return (
        
            <div className="flex flex-col justify-center items-center">
                <div className="flex flex-col justify-center border border-[#00000033] bg-white rounded-[25px] px-[40px] py-[40px] max-w-[592px] w-[fill]">
                    <div className="flex">
                        <div className="flex absolute">
                            <button className="flex relative" onClick={backToForgotPassword}>
                                <img src={arrowLeft} alt="arrow-left" />
                            </button>
                        </div>
                        <div className="flex items-center justify-center w-[fill]">
                            <h1 className=" font-semibold text-center text-[32px]  ">
                                OTP
                            </h1>
                        </div>
                    </div>
                    <div className="flex flex-col items-center">
                        <p className="text-black text-center">
                            Enter the code sent to {email}
                        </p>
                        {errorMessage && (
                            <ErrorState message={errorMessage} />
                        )}
                        <form onSubmit={submitForgotPasswordOTP}>
                            <Passcode
                                passcode={otp}
                                setPasscode={setOTP}
                            />
                            <BlockButton
                                text="Proceed"
                                disabled={buttonDisabled}
                                type="submit"
                                isLoading={isLoading}
                            />
                        </form>
                    </div>
                </div>
            </div>
    );
};

export default OTP;
