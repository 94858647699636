import usePagination from '../../hooks/usePagination';
import Table from '../../constants/Table';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import hospitalService from '../../services/hospitalService';
import { formatDateDmy } from '../../utils/helpers';
import { useUserCountStore } from '../../store/hospital/auth';
import { useEffect, useState } from 'react';
import image from '../../Images/nurse.png';
import Lottie from 'lottie-react';
import loadingState from '../../Images/loading.json';
interface rowType {
    _id: string;
}

const TotalNurses = ({ isData, searchTerm, queryParam }: any) => {
    const navigate = useNavigate();
    const setNurseCount = useUserCountStore((state) => state.setNurseCount);
    const [nurseData, setNursesData] = useState<any[]>([]);

    const { data, isError, isLoading } = useQuery('nurses', () =>
        hospitalService.getUsers('nurse')
    );
    console.log(data);
    useEffect(() => {
        // When data (from 'nurses' query) changes, update nurseData
        if (data) {
            setNursesData(data.users);
        }
    }, [data, queryParam]);

    const { data: filterData } = useQuery(
        ['filteredNurses', queryParam],
        () => hospitalService.getUsers(`nurse${queryParam}`),
        {
            enabled: !!queryParam,
        }
    );

    useEffect(() => {
        // When data (from 'nurses' query) changes, update nurseData
        if (filterData) {
            setNursesData(filterData.users);
        }
    }, [filterData, queryParam]);

    useEffect(() => {
        // When filterData (from 'filteredNurses' query) changes, update doctorData
        if (filterData) {
            setNursesData(filterData.users);
        }
    }, [filterData]);

    useEffect(() => {
        setNurseCount(nurseData?.length || 0);
    }, [nurseData]);

    const filteredNurseUsers = nurseData?.filter(
        (user: any) =>
            user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.lastName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const { page, limit, Pagination } = usePagination({
        page: 1,
        limit: 10,
        total: filteredNurseUsers?.length,
    });

    const paginatedRows = filteredNurseUsers?.slice(
        (page - 1) * limit,
        page * limit
    );
    if (isLoading) {
        return (
            <div className="flex justify-center items-center py-32">
                {' '}
                <Lottie
                    animationData={loadingState}
                    loop={true}
                    className="lottie"
                />
            </div>
        );
    }

    if (isError) {
        return <div>Error loading nurses, please refresh your page</div>;
    }
    const recordSearchTableHeaders = [
        {
            title: 'S/N',
            render: (row: any) => (
                <p className="text-xs font-medium">
                    {nurseData?.findIndex((user: any) => user._id === row._id) +
                        1}
                </p>
            ),
        },
        {
            title: 'Nurses',
            render: (row: any) => (
                <div className="flex items-center space-x-2">
                    <img
                        src={row.image}
                        alt="img"
                        className="rounded-full w-10 object-cover h-10"
                    />
                    <div>
                        <p className="text-sm font-medium text-lightBrown truncate w-24">
                            {/* {row.firstName} {row.lastName} */}
                            {`${
                                row.salutation && row.salutation !== 'undefined'
                                    ? row.salutation + ' '
                                    : ''
                            }${row.firstName} ${row.lastName}`}
                        </p>
                        <p className="text-xs font-medium mr-8 lg:mr-0 text-lightBrown100 truncate w-28">
                            {row.email}
                        </p>
                    </div>
                </div>
            ),
        },
        {
            title: 'Gender',
            render: (row: any) => (
                <p className="text-xs font-medium text-lightBrown100 ">
                    {row.gender}
                </p>
            ),
        },
        {
            title: 'Specialty',
            render: (row: any) => (
                <div className="flex justify-between">
                    <div className="flex space-x-2 items-center w-[126px] truncate">
                        {row.specialties.length > 0 && (
                            <p className="text-xs font-medium text-lightBrown100 overflow-ellipsis">
                                {row.specialties[0].name}
                            </p>
                        )}
                    </div>
                    <>
                        {row.specialties.length > 1 && (
                            <div className="flex items-center">
                                <p className="text-xs font-medium text-lightBrown100 bg-lightGray rounded-xl px-3 py-1">
                                    +{row.specialties.length - 1}
                                </p>
                            </div>
                        )}
                    </>
                </div>
            ),
        },
        {
            title: 'Phone Number',
            render: (row: any) => (
                <p className="font-medium text-xs text-lightBrown100">
                    {row.phoneNumber}
                </p>
            ),
        },
        {
            title: 'Address',
            render: (row: any) => (
                <p className="text-xs font-medium text-lightBrown100 w-[117px] whitespace-nowrap overflow-hidden truncate ">
                    {' '}
                    {row.address}
                </p>
            ),
        },
        {
            title: 'Date Joined',
            render: (row: any) => (
                <div>
                    <p> {formatDateDmy(row.createdAt)}</p>
                </div>
            ),
        },
    ];

    const emptyMessage = (
        <div className="flex flex-col items-center justify-center text-center my-[70px]">
            <img src={image} alt="empty" />
            <p className="mt-6 text-base font-medium font-[Montserrat]">
                {searchTerm ? (
                    'No matching results found for the search term.'
                ) : (
                    <span>
                        Your list of nurses is currently empty.
                        <br /> Add new nurse profiles to begin <br />
                        managing your healthcare team.
                    </span>
                )}
            </p>
        </div>
    );

    return (
        <div className="mt-[13px] overflow-x-auto scrollbar-hide">
            <>
                {isData === true ? (
                    <>
                        {filteredNurseUsers && filteredNurseUsers.length > 0 ? (
                            <>
                                <Table
                                    rows={paginatedRows}
                                    headers={recordSearchTableHeaders}
                                    showHead={true}
                                    allowRowClick
                                    onRowClick={(row: rowType) =>
                                        navigate(
                                            `/hospital/user/nurse/${row._id}`
                                        )
                                    }
                                />
                                <Pagination />
                            </>
                        ) : (
                            <>
                                <Table
                                    headers={recordSearchTableHeaders}
                                    showHead={true}
                                />
                                {emptyMessage}
                            </>
                        )}
                    </>
                ) : (
                    <Table headers={recordSearchTableHeaders} showHead={true} />
                )}
            </>
        </div>
    );
};

export default TotalNurses;
