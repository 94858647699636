import Modal from '../../../../reusable/Modal';

const MarkAppointmentModal = ({
    showModal,
    onClose,
    onSave,
    buttonEnabled,
    patientFirstName,
    patientLastName,
    isSubmitting
}) => {
    return (
        <Modal
            isVisible={showModal}
            onClose={onClose}
            onSave={onSave}
            buttonEnabled={buttonEnabled}
            setButton=''
            saveText={`${isSubmitting  ? 'Submitting...' : 'Confirm & End Chat' }`}
            closeText="Cancel"
            showCloseButtonBorder={false}
        >
            <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2 ">
                <h2 className="font-semibold text-2xl mb-5">
                    Appointment Completed ?
                </h2>
                <p className=" text-[16px] font-normal -mb-5">
                    Have you completed your appointment with patient <span className="font-bold"> {patientFirstName} {patientLastName}</span>? Please be aware that confirming this appointment will also end the chat session. Once confirmed, no further changes can be made to this appointment or the medical report. Are you sure you want to proceed?
                </p>
            </div>
        </Modal>
    );
};

export default MarkAppointmentModal;
