import shareIcon from '../../Images/share.svg';
import { SlArrowLeft } from 'react-icons/sl';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import DoctorDetailsForm from './DoctorDetailsForm';
import ShareDoctorFormModal from './ShareDoctorFormModal';

const AddDoc = () => {
    const [show, setShow] = useState(false);
    const handleShowModal = () => {
        setShow(true);
    };
    const handleCloseModal = () => {
        setShow(false);
    };
    return (
        <div className="md:mx-8 mx-1 mt-6">
            <div className="flex justify-between">
                <Link
                    to="/hospital/user/doctor"
                    className="flex items-center  cursor-pointer"
                >
                    <SlArrowLeft className="m-2" />
                    <h2 className="font-semibold text-base md:text-[28px] text-lightBrown">
                        Add Doctor
                    </h2>
                </Link>
                {/* <button
                    onClick={handleShowModal}
                    className="bg-Tmred text-white rounded-lg p-1 md:p-3 flex items-center space-x-2 cursor-pointer"
                >
                    <img src={shareIcon} alt="" />
                    <span className="md:text-base text-xs"> Share Form</span>
                </button> */}
            </div>
            <DoctorDetailsForm />
            {show && <ShareDoctorFormModal closeModal={handleCloseModal} />}
        </div>
    );
};
export default AddDoc;
