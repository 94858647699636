import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import sidebarData from '../../../constants/pharmacySideBar';
import DashboardSidebarHeader from './DashboardSidebarHeader';
import tmr from '../../../Images/logo.png';
import { usePharmacyAuthStore } from '../../../store/pharmacy/auth';

const DashboardSidebarContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    transition: all 0.3s ease-in;
    width: 100%;
    max-width: 17rem;
    height: 100%;
    border-right: 1px solid #0000004d;
    padding-right: 5px;
    background: #ffffff;
    z-index: 2;

    @media (max-width: 850px) {
        display: none;
        max-width: 100%;
        box-shadow: none;

        &.isOpen {
            display: initial;

            .DashboardSidebar__nav {
                margin-top: 0;
            }
        }
    }
    @media (max-width: 1284px) {
        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .DashboardSidebar__nav {
        position: relative;
        transition: all 0.5s ease-out;
        overflow-y: auto;
        height: calc(100vh - 5.5rem);
        margin-top: 2rem;

        /* custom scrollbar-color */
        &::-webkit-scrollbar {
            width: 4px;
        }
        &::-webkit-scrollbar-track {
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #c00010;
        }

        ul {
            margin: 0;
            padding: 0;

            li {
                list-style: none;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }

                & summary,
                & > .DashboardSidebar__nav__link {
                    display: flex;
                    gap: 1rem;
                    align-items: center;
                    padding: 8px 10px;
                    transition: background-color 0.5s ease-out;
                    color: #00000 @media (max-width: 850px) {
                        padding-left: 24px;
                    }

                    &:active {
                        background: #c00010;
                        color: white;
                    }
                    &:hover {
                        background-color: #f5f6f7;
                    }

                    i {
                        width: 31px;
                        height: 34px;
                        background-position: center;
                        background-repeat: no-repeat;
                    }

                    span {
                        font-weight: 500;
                        font-size: 17px;
                        line-height: 30px;
                        letter-spacing: 0.44px;
                    }
                }

                &.isActiveNav {
                    & summary,
                    & > .DashboardSidebar__nav__link {
                        background-color: #c00010;
                        color: #ffffff;
                        border-radius: 8px;
                    }
                }

                & > details {
                    margin: 0;
                    padding: 0;

                    summary {
                        list-style: none;
                        cursor: pointer;
                        .arrow-icon {
                            width: 5px;
                            height: 5px;
                            background-color: purple;
                        }
                    }

                    li {
                        .DashboardSidebar__subnav__link {
                            display: flex;
                            gap: 16px;
                            width: 100%;
                            height: 2rem;
                            align-items: center;
                            margin-left: 20px;
                            padding: 8px 8px 8px 70px;
                            font-weight: 500;
                            font-size: 15px;
                            line-height: 30px;
                            letter-spacing: 0.44px;
                            transition: all 0.5s ease-out;
                            color: #000000;
                            .sublink_nav {
                                width: 15px;
                                height: 15px;
                                border-radius: 50%;
                                border: 1px solid #828282;
                            }
                        }

                        &:hover {
                            background-color: #f5f6f7;
                        }

                        &.isActiveSubNav {
                            .DashboardSidebar__subnav__link {
                                color: #000000;
                            }
                        }
                    }

                    &[open] {
                        ul {
                            border-bottom: 1px solid #eaeaea;
                        }
                    }
                }
            }
        }

        @media (max-width: 850px) {
            height: calc(100vh - 5rem);
        }
    }

    .logout__btn {
        display: none;
        @media (max-width: 850px) {
            display: block;
            padding: 16px 16px 0 37px;
        }
    }
`;

interface Props {
    isOpen: boolean;
    toggleSidebar: Function;
}

export const DashboardSidebar: React.FC<Props> = ({
    isOpen,
    toggleSidebar,
}) => {
    const location = useLocation();
    const pharmacyStore = usePharmacyAuthStore((state) => state.pharmacy);
    const userStore = usePharmacyAuthStore((state) => state.user);
    // const [open, setOpen] = useState(false);

    const mapSidebarNav = (nav: any, index: any) => {
        const { sublinks = [] } = nav || {};
        const isActiveNav =
            index === 0
                ? location.pathname === nav.url
                : location.pathname.includes(nav.url);
        const isMessageNav = nav.text === 'Messaging';

        const nonAdminSideBar = userStore?.permissions?.map(
            (permission: string) => permission.toLowerCase()
        );
        if (
            userStore.userType !== 'superadmin' &&
            !nonAdminSideBar?.includes(nav?.permissionName) &&
            nav.permissionName
        )
            return null;
        // remove My Wallet for superadmin
        if (userStore.userType === 'superadmin' && nav.text === 'My Wallet')
            return null;

        // //Remove hospital voucher from doctor and nurse
        // if (
        //     (userStore.userType === 'doctor' ||
        //         userStore.userType === 'nurse') &&
        //     nav.text === 'Hospital Voucher'
        // )
        //     return null;

        if (sublinks.length) {
            return (
                <Disclosure key={nav.text}>
                    {({ open }) => (
                        <>
                            <Disclosure.Button
                                className={`flex w-full gap-4 items-center px-[10px] rounded-lg py-2 font-medium text-black h-[3.5rem]  focus:outline-none ${
                                    isActiveNav ? 'bg-[#C00010] text-white' : ''
                                } ${open ? 'bg-[#C00010] text-white' : ''}`}
                            >
                                {isActiveNav ? nav.activeIcon : nav.icon}
                                <span className="text-[17px] font-medium">
                                    {nav.text}
                                </span>
                                <MdKeyboardArrowRight
                                    className={`${
                                        open ? 'rotate-90 transform' : ''
                                    } h-5 w-5 text-[#b3b3b3]`}
                                />
                            </Disclosure.Button>
                            <Disclosure.Panel className="pr-4 pl-[37px] py-4 text-gray-500">
                                <ul className="flex flex-col gap-2">
                                    {sublinks.map((subnav: any) => {
                                        const isActiveSubNav =
                                            location.pathname === subnav.url;

                                        if (
                                            userStore.userType !==
                                                'superadmin' &&
                                            !nonAdminSideBar?.includes(
                                                subnav?.permissionName
                                            ) &&
                                            subnav.permissionName
                                        )
                                            return null;

                                        return (
                                            <li
                                                key={subnav.text}
                                                className={clsx({
                                                    isActiveSubNav,
                                                })}
                                            >
                                                <Link
                                                    to={subnav.url}
                                                    className="flex gap-4 items-center border-l-white border-l-[7px]"
                                                >
                                                    <div
                                                        style={{
                                                            background: `${
                                                                isActiveSubNav
                                                                    ? '#C00010'
                                                                    : 'transparent'
                                                            }`,
                                                        }}
                                                        className="w-[24px] h-[24px] border border-[#B3B3B3] rounded-full"
                                                    ></div>
                                                    <span
                                                        className={`text-[17px] font-medium ${
                                                            isActiveSubNav
                                                                ? 'text-[#C00010]'
                                                                : ''
                                                        }`}
                                                    >
                                                        {subnav.text}
                                                    </span>
                                                </Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            );
        }

        return (
            <li
                key={nav.text}
                className={clsx({ isActiveNav })}
                onClick={nav.onClick}
            >
                <Link to={nav.url} className="DashboardSidebar__nav__link">
                    {isActiveNav ? nav.activeIcon : nav.icon}
                    <span>{nav.text}</span>
                    {isMessageNav && (
                        <>
                            <span className=" w-[88px]  rounded-[100px] px-[2px] font-semibold text-center bg-[#FDDFA6]">
                                <p className="text-[#534341] text-[8px]">
                                    {' '}
                                    Coming soon..
                                </p>
                            </span>
                        </>
                    )}
                </Link>
            </li>
        );
    };

    return (
        <DashboardSidebarContainer className={clsx({ isOpen })}>
            <DashboardSidebarHeader
                isOpen={isOpen}
                toggleSidebar={toggleSidebar}
                // showOnDesktop
            />

            <div
                className={`${
                    isOpen ? 'hidden' : ''
                } flex items-center mx-4 py-4 gap-4 border-b border-b-[#00004d] `}
            >
                <img
                    src={pharmacyStore.logo}
                    alt=""
                    className="w-[50px] h-[50px] rounded-full"
                />
                <h1
                    className="text-2xl font-semibold text-dark truncate"
                    title={pharmacyStore.name}
                >
                    {pharmacyStore.name}
                </h1>
            </div>

            <nav className="DashboardSidebar__nav pb-20 px-2">
                <ul>{sidebarData().map(mapSidebarNav)}</ul>
            </nav>
            <div></div>
            <div className="flex flex-col justify-center relative items-center gap-2 pb-10 bottom-0">
                <h1 className="text-[14px] font-semibold text-Tmred">
                    Powered by
                </h1>
                <img
                    src={tmr}
                    alt=""
                    className="max-w-[100%] w-[70px] h-[66px] "
                />
            </div>
        </DashboardSidebarContainer>
    );
};

export default DashboardSidebar;
