import React from 'react';
import DashboardLayout from '../Dashboard/DashboardLayout';

import { Helmet } from 'react-helmet'
import ActiveState from '../../../components/PharmacyComponent/Inventory/ActiveState';

const Inventory = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                   TMR | Inventory
                </title>
            </Helmet>
            <div className="">
                <ActiveState />
            </div>
        </DashboardLayout>
    );
};

export default Inventory;
