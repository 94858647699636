
import { BsPencil, BsTrash } from 'react-icons/bs';
import IOSSwitch from '../../../reusable/Forms/Switch';

import { TextField } from '@mui/material';
import { Fragment, useState } from 'react';
import Modal from '../../../reusable/LoadingModal';
import { useHospitalAuthStore } from '../../../store/hospital/auth';


interface ServiceProps {
  name: any;
  value: any;
  onchange: any;
  title: any;
  onRemove: any;
  onEdit: any; 
  isDeleting: any;
  switchState: boolean;
  switchCallback: (state: boolean) => void;
}

export const Service: React.FC<ServiceProps> = ({
  name,
  isDeleting,
  value,
  onchange,
  title,
  onRemove,
  onEdit, 
  switchState,
  switchCallback,
}) => {
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

  const handleSwitchChange = () => {
    switchCallback(!switchState);
  };

  const handleRemoveClick = (e: any) => {
    setDeleteModalVisible(true);
  };

  const handleEditClick = () => {
    onEdit(); 
  };

  const handleDeleteConfirm = async () => {
    onRemove();
    setDeleteModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
  };
  const currency = useHospitalAuthStore((state) => state.hospital.currency);

  return (
    <Fragment>
      <div className="flex flex-col gap-5">
        <div className="flex items-center justify-between ">
          <div className="flex items-center gap-2">
            <span className="h-[8px] w-2 bg-[#857371]  text-[#857371] rounded-full"></span>
            <p className="font-semibold text-[16px]">{title}</p>
          </div>
          <div className="flex items-center gap-5">
            <button onClick={handleRemoveClick}>
              <BsTrash />
            </button>
            <button onClick={handleEditClick}>
            
              <BsPencil />
            </button>
            <IOSSwitch checked={switchState} onChange={handleSwitchChange} />
          </div>
        </div>

        <TextField
          type="number"
          disabled={!switchState}
          label={`Enter Fee (${currency})`}
          value={value}
          name={name}
          onChange={(e) => onchange(e, name)}
        />
      </div>

      {/* Delete Modal */}
      <Modal
        isVisible={isDeleteModalVisible}
        onClose={handleDeleteCancel}
        buttonEnabled={!isDeleting}
        isLoading={isDeleting}
        loadingText="Deleting..."
        setButton={() => {}}
        onSave={handleDeleteConfirm}
        saveText="Delete"
        closeText={'No, don’t delete'}
        showCloseButtonBorder={false}
      >
        <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2 sm:w-[312px] sm:h-[112px]">
          <h2 className="font-semibold text-2xl mb-5">Delete Service</h2>
          <p className="text-[14px] font-normal text-[#534341]">
            Are you sure you want to delete this service?
          </p>
        </div>
      </Modal>
    </Fragment>
  );
};

