import React from 'react';
import DashboardLayout from '../Dashboard/DashboardLayout';
import AppointmentDetails from '../../../components/Appointment/PatientAppointment/AppointmentDetails';
import {Helmet} from 'react-helmet'
import PrdtDetailsPage from '../../../components/PharmacyComponent/Inventory/ProductDetails/PrdtDetailsPage';


function ProductDetails() {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Product Details
                </title>
            </Helmet>
           <PrdtDetailsPage/>
        </DashboardLayout>
    );
}

export default ProductDetails;
