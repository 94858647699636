import { useQuery } from 'react-query';
import hospitalService from '../../../services/hospitalService';
import Lottie from 'lottie-react';
import loadingState from '../../../Images/loading.json';
import { BsChevronLeft } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import icon from '../../../Images/icon-button.svg';
import RecordSearches from './RecordSearches';
import RecordSearchModal from '../../../components/dashboard/modals/recordSearchModal';
// import { useModal } from '../../../contexts/ModalContext';
import emptySearch from '../../../Images/emptySearch.png';
import { useState } from 'react';

const Record = () => {
    // const { isModalOpen, openModal } = useModal();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const searchParam = new URLSearchParams(window.location.search).get(
        'query'
    );

    const { data, isError, isLoading, refetch } = useQuery(
        'patients',
        hospitalService.getPatients,
        {
            enabled: true,
        }
    );

    const allPatients = data?.patients || [];

    const filteredpatients = allPatients?.filter((user: any) => {
        const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
        const searchTerm = searchParam?.toLowerCase() || '';
    
        return (
            user.firstName?.toLowerCase().includes(searchTerm) ||
            user.lastName?.toLowerCase().includes(searchTerm) ||
            user.tmrNumber?.toLowerCase().includes(searchTerm) ||
            fullName.includes(searchTerm)
        );
    });
    
    if (isLoading) {
        return (
            <div className="flex justify-center items-center py-32">
                {' '}
                <Lottie
                    animationData={loadingState}
                    loop={true}
                    className="lottie"
                />
            </div>
        );
    }

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };
    return (
        <div className="md:mx-8 mb-8 mt-4 mx-1">
            <div className="flex items-center space-x-[2px] mb-6">
                <Link to="/hospital/dashboard" className="p-[8px]">
                    <BsChevronLeft className="text-dark100 w-6 h-6 " />
                </Link>
                <h2 className="font-semibold md:text-[28px] text-lg  text-lightBrown">
                    Record Search
                </h2>
            </div>
            <div className="bg-white p-4 rounded-2xl">
                <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-3">
                        <h2 className="md:text-2xl text-sm font-semibold text-dark">
                            {searchParam}
                        </h2>
                        <button
                            type="button"
                            className="md:text-[22px] text-sm bg-red py-1 text-white px-1 md:px-[11.64px] rounded-[290.909px]"
                        >
                            {filteredpatients.length}{' '}
                            {filteredpatients.length > 1 ? 'Results' : 'Result'}
                        </button>
                    </div>
                    {/* <div onClick={handleOpenModal}>
                        <img src={icon} alt="" className="cursor-pointer" />
                    </div> */}
                </div>
            </div>
            {filteredpatients.length > 0 ? (
                <div className="bg-white p-4 rounded-2xl">
                    <RecordSearches filteredpatients={filteredpatients} />
                </div>
            ) : (
                <div className="flex flex-col justify-center items-center py-20">
                    <div>
                        <img src={emptySearch} alt="no record" />
                    </div>
                    <p> No result found </p>
                </div>
            )}
            {isModalOpen && (
                <RecordSearchModal setIsModalOpen={setIsModalOpen} />
            )}
        </div>
    );
};
export default Record;
