import { useState, useEffect } from 'react';
import { SlArrowLeft } from 'react-icons/sl';
import { Link, useParams } from 'react-router-dom';
import ActiveState from './ActiveState';
import hospitalService from '../../services/hospitalService';
import { useQuery } from 'react-query';
import Lottie from 'lottie-react';
import loadingState from '../../Images/loading.json';
import { formatDateDmy } from '../../utils/helpers';
import LogActivity from './LogActivity';
import ManageDocModal from './Modals/ManageDocModal';

const DoctorProfile = () => {
    const { id } = useParams();
    const [isActive, setIsActive] = useState(true);
    const [manageDoctorModal, setManageDoctorModal] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    const [doctorProfile, setDoctorProfile] = useState<any>({});
    const [activityLog, setActivityLog] = useState<any[]>([]);
    const [doctorAppointments, setDoctorAppointments] = useState<any[]>([]);
    const { data, isLoading, isError,refetch } = useQuery(
        ['doctor', id],
        () =>
            id ? hospitalService.getSingleUser(id) : Promise.reject('No ID'),
        {
            enabled: !!id,
        }
    );

    const { data: appointmentData, isError: appointmentIsError, isLoading: appointmentIsLoading } = useQuery(
        'upcomingAppointment',
        hospitalService.getUpcomingAppointment
    );

    useEffect(() => {
        if (data) {
            setDoctorProfile(data?.user);
            setActivityLog(data?.activityLogs);
        }
    }, [data]);

    useEffect(() => {
        if (appointmentData) {
            setDoctorAppointments(doctorAppointments);
        }
    }, [appointmentData]);
    
   const handleRefresh = (status) => {
        setIsRefresh(status)
   } 
    
    // const entireData= data
    const handleOpenModal = () => {
        setManageDoctorModal(true);
    };
    const handleCloseModal = () => {
        setManageDoctorModal(false);
    };
    const handleToggle = () => {
        setIsActive(!isActive);
    };
    
    useEffect(() => {
        if(isRefresh){
            window.location.reload()
        }
    }, [isRefresh])

    if (isLoading) {
        return (
            <div className="flex justify-center items-center py-64">
                {' '}
                <Lottie
                    animationData={loadingState}
                    loop={true}
                    className="lottie"
                />
            </div>
        );
    }
    
    if (appointmentIsLoading) {
        return (
            <div className="flex justify-center items-center py-64">
                {' '}
                <Lottie
                    animationData={loadingState}
                    loop={true}
                    className="lottie"
                />
            </div>
        );
    }

    
    if (isError) {
        return <div>Error fetching doctor user</div>;
    }
    if (appointmentIsError) {
        return <div>Error fetching appointments</div>;
    }
    
    return (
        <div className="mx-6 mt-6">
            <Link
                to="/hospital/user/doctor"
                className="flex items-center  cursor-pointer"
            >
                <SlArrowLeft className="m-2" />
                <h2 className="font-semibold text-base md:text-[28px] text-lightBrown">
                    Doctor Profile
                </h2>
            </Link>
            <div className="flex flex-col lg:flex-row lg:space-x-6 mt-8">
                <div>
                    <div className="flex-1 bg-white p-2 sm:p-4 rounded-2xl mb-10 lg-mb-0">
                        <div>
                            {doctorProfile.status === 'suspended' && (
                                <div className="bg-[#FFB4AB] rounded-lg text-[#201A19] text-[22px] p-2 w-full mx-auto my-4 text-center">
                                    Doctor Suspended
                                </div>
                            )}
                        </div>
                        <div className="flex items-center bg-lightPink p-1 sm:p-4 space-x-1 sm:space-x-3 rounded-xl mb-6">
                            <img
                                src={doctorProfile.image}
                                alt="doctorImage"
                                className="rounded-full h-20 object-cover w-20"
                            />
                            <div>
                                <h2 className="sm:text-2xl text-sm font-medium text-lightBrown">
                                    {/* {`${doctorProfile.salutation}`} {`${doctorProfile.firstName} ${doctorProfile.lastName}`} */}
                                    {`${
                                        doctorProfile.salutation &&
                                        doctorProfile.salutation !== 'undefined'
                                            ? doctorProfile.salutation + ' '
                                            : ''
                                    }${doctorProfile.firstName} ${
                                        doctorProfile.lastName
                                    }`}
                                </h2>
                                <p className="sm:text-sm text-xs font-normal text-dark100 ">
                                    {doctorProfile.email}
                                </p>
                                <p className="sm:text-sm text-xs font-normal capitalize text-lightBrown">
                                    {doctorProfile.userType}
                                </p>
                            </div>
                        </div>
                        <div className="flex lg:flex-col xl:flex-row flex-col sm:flex-row">
                            <div>
                                <h2 className="text-base sm:text-[22px] font-medium text-lightBrown">
                                    Personal information
                                </h2>
                                <div className="my-6">
                                    <h2 className="text-sm font-normal text-lightBrown montFont">
                                        Gender
                                    </h2>
                                    <p className="text-sm font-medium capitalize text-lightBrown100">
                                        {doctorProfile?.gender || '-'}
                                    </p>
                                </div>
                                <div>
                                    <h2 className="text-sm font-normal text-lightBrown montFont">
                                        Phone Number
                                    </h2>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {doctorProfile?.phoneNumber || '-'}
                                    </p>
                                </div>
                                <div className="my-6">
                                    <h2 className="text-sm font-normal text-lightBrown montFont">
                                        City (optional)
                                    </h2>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {doctorProfile.city || '-'}
                                    </p>
                                </div>
                                <div>
                                    <h2 className="text-sm font-normal text-lightBrown montFont">
                                        State (optional)
                                    </h2>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {doctorProfile.state || '-'}
                                    </p>
                                </div>
                                <div className="mt-6">
                                    <h2 className="text-sm font-normal text-lightBrown montFont">
                                        Address (optional)
                                    </h2>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {doctorProfile.address || '-'}
                                    </p>
                                </div>
                            </div>
                            <div className="mt-6 sm:mt-0 lg:mt-6 xl:mt-0 ml-14">
                                <h2 className=" text-base sm:text-[22px] font-medium text-lightBrown">
                                    Professional information
                                </h2>
                                <div className="my-6">
                                    <h2 className="text-sm font-normal text-lightBrown montFont">
                                        Title (optional)
                                    </h2>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {doctorProfile.title || '-'}
                                    </p>
                                </div>
                                <div>
                                    <h2 className="text-sm font-normal text-lightBrown montFont">
                                        MDCN License Number
                                    </h2>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {doctorProfile.licenseNumber || '-'}
                                    </p>
                                </div>
                                <div className="my-6">
                                    <h2 className="text-sm font-normal text-lightBrown montFont">
                                        Specialty
                                    </h2>
                                    {doctorProfile?.specialties?.map(
                                        (specialty: any) => (
                                            <button
                                                key={specialty._id}
                                                type="button"
                                                className="text-xs font-medium text-brown200 mr-1 mb-1 border-[1px] border-brown200 py-[6px] px-3 rounded-lg"
                                            >
                                                {specialty.name
                                                    ? specialty.name
                                                    : ''}
                                            </button>
                                        )
                                    )}
                                </div>
                                <div className="my-6">
                                    <h2 className="text-sm font-normal text-lightBrown montFont mb-3">
                                        User Group
                                    </h2>
                                    <div className=" flex flex-wrap max-w-[380px] gap-2">
                                        {doctorProfile?.permissions?.map(
                                            (permission: any, index) => (
                                                <button
                                                    key={index}
                                                    type="button"
                                                    className="text-xs font-medium text-brown200 mr-1 mb-1 border-[1px] border-brown200 py-[6px] px-3 rounded-lg"
                                                >
                                                    {permission
                                                        ? permission
                                                        : ''}
                                                </button>
                                            )
                                        )}
                                    </div>
                                </div>

                                <div className="mt-6">
                                    <h2 className="text-sm font-normal text-lightBrown montFont">
                                        Date Registered
                                    </h2>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {formatDateDmy(doctorProfile.createdAt)}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {doctorProfile?.status !== 'suspended' && (
                            <div className="mt-8">
                                <h2 className="text-sm text-lightBrown font-normal montFont">
                                    Availability
                                </h2>
                                <div className="flex max-w-[322px] justify-between items-center mt-2">
                                    <span
                                        className={`text-base text-lightBrown font-normal ${
                                            isActive
                                                ? 'text-base '
                                                : 'text-base'
                                        }`}
                                    >
                                        {doctorProfile?.availability}
                                    </span>
                                </div>
                            </div>
                        )}
                        <div className="flex justify-end space-x-6 py-3 border-t mt-10">
                            <button
                                type="button"
                                onClick={handleOpenModal}
                                className="py-2 px-3"
                            >
                                Manage Doctor
                            </button>
                            <button
                                type="submit"
                                className="text-Tmred border-[1px] border-lightBrown100 px-6 py-2 text-base font-normal rounded-lg"
                            >
                                Send Message
                            </button>
                        </div>
                    </div>
                </div>
                {/* <DocProfile data={entireData}/> */}
            </div>
            <ActiveState
                data={doctorAppointments}
                isloading={isLoading}
                doctorId={doctorProfile._id}
            />
            <LogActivity activitylog={activityLog} />
            {manageDoctorModal && (
                <ManageDocModal
                    closeModal={handleCloseModal}
                    doctorId={doctorProfile._id}
                    doctorName={`${doctorProfile.firstName} ${doctorProfile.lastName}`}
                    doctorUser={doctorProfile}
                    refresh={refetch}
                />
            )}
            {/* {manageAdminModal && (
                <ManageAdminModal
                    closeModal={handleCloseModal}
                    adminId={adminUser._id}
                    adminName={`${adminUser.firstName} ${adminUser.lastName}`}
                    adminUser={adminUser}
                />
            )} */}
        </div>
    );
};
export default DoctorProfile;
