import AdminServiceAPI from '../utils/hospitalAuth/adminServiceAPI';

export const adminService = {
    getEscrowBalance: async () => {
        const { data } = await AdminServiceAPI().get('/escrow-account/amount');
        return data.payload.data;
    },
    purchaseVoucher: async (payload: any, walletType) => {
        return await AdminServiceAPI().post(
            `hospital/voucher?service=healthcare&type=${walletType}`,
            payload
        );
    },
    getAllVoucher: async (walletType: any, status?: any, page?: any) => {
        const { data } = await AdminServiceAPI().get(
            `hospital/vouchers?service=healthcare&limit=10&type=${walletType}&status=${status}&page=${page}&limit=10`
            // `hospital/vouchers?service=healthcare&page=${page}&limit=10&type=${walletType}&status=${status}`
        );
        return data.payload.data;
    },
    getVoucherCount: async (walletType) => {
        const { data } = await AdminServiceAPI().get(
            `hospital/voucher/count?service=healthcare&type=${walletType}`
        );
        return data.payload.data;
    },
};
