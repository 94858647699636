import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import PatientCard from './PatientCard';
import PatientProfileCards from './PatientProfileAppointmentCards';
import { SlArrowLeft } from 'react-icons/sl';
import MedicalRecords from './MedicalRecords';
import Lottie from 'lottie-react';
import loadingState from '../../../Images/loading.json';
import { useQuery } from 'react-query';

import patientService from '../../../services/patientService';

const PatientFiles = () => {
    const [details, setDetails] = useState<any>({}); // Set the initial state as 'any'
    let { id } = useParams();
    const navigate = useNavigate();

    const { data, isLoading, isError } = useQuery(
        ['patient', id],
        () =>
            id ? patientService.getSinglePatient(id) : Promise.reject('No id'),
        {
            enabled: !!id,
        }
    );
    useEffect(() => {
        if (data) {
            setDetails(data);
        }
    }, [data]);

   
    // console.log({ details });
    return (
        <div className="p-1 sm:px-8">
            <div
                className="flex items-center mb-6 cursor-pointer"
                onClick={() => navigate('/hospital/patient')}
            >
                <SlArrowLeft className="m-2" />
                <h2 className="font-semibold text-[28px] text-lightBrown">
                    Patient Profile
                </h2>
            </div>
            <div>
                {isLoading ? <>
                    <div className="flex justify-center items-center py-64">
                {' '}
                <Lottie
                    animationData={loadingState}
                    loop={true}
                    className="lottie"
                />
            </div>
                </>: isError ? <>
                <div className="flex justify-center items-center py-64">
                <div>
                    You do not have permission to view patient health record
                </div>
            </div>
                </>:(
<>
               
            <div className="flex lg:flex-row flex-col lg:space-x-2 xl:space-x-6 mb-10">
                <div className="bg-white p-4 rounded-2xl basis-4/6">
                    <div className="">
                        <PatientCard
                            firstName={details?.patient?.firstName}
                            lastName={details?.patient?.lastName}
                            phoneNumber={details?.patient?.phoneNumber}
                            tmrNumber={details?.patient?.tmrNumber}
                            image={details?.patient?.image}
                            email={details?.patient?.email}
                        />
                    </div>
                    <div className="mt-6">
                        <h6 className="text-[22px] font-medium text-lightBrown">
                            Contact Information
                        </h6>
                        <div className="flex sm:flex-row flex-col sm:space-x-6 mt-6">
                            <div className=" basis-1/2 ">
                                <div>
                                    <p className="text-sm font-medium text-lightBrown">
                                        Phone Number
                                    </p>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {details?.patient?.phoneNumber}
                                    </p>
                                </div>
                                <div className="my-6">
                                    <p className="text-sm font-medium text-lightBrown">
                                        Country
                                    </p>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {details?.patient?.country}
                                    </p>
                                </div>
                                <div>
                                    <p className="text-sm font-medium text-lightBrown">
                                        City(optional)
                                    </p>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {details?.patient?.city}
                                    </p>
                                </div>
                                <div className="my-6">
                                    <p className="text-sm font-medium text-lightBrown">
                                        State(optional)
                                    </p>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {details?.patient?.state}
                                    </p>
                                </div>
                                <div className="mb-6">
                                    <p className="text-sm font-medium text-lightBrown ">
                                        Address (optional)
                                    </p>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {details?.patient?.address}
                                    </p>
                                </div>
                                <p className="text-sm font-medium text-lightBrown ">
                                    Plan
                                </p>
                                <div className="flex justify-between items-center pt-3">
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {details?.plan || '-'}
                                    </p>
                                    <p className="text-sm font-medium text-dark100">
                                        {details?.patient?.currency}{' '}
                                        {details?.subscription?.amount || '-'}
                                    </p>
                                </div>
                            </div>

                            <div className="mt-6 sm:mt-0 basis-1/2">
                                <div>
                                    <p className="text-sm tracking-wide font-medium text-lightBrown mb-3">
                                        Health Insurance
                                    </p>
                                    <div className="flex items-center gap-4">
                                        <p className="text-sm font-medium text-lightBrown100">
                                            Enrollee Number
                                        </p>
                                        <p className="text-[16px] font-medium text-[#534341]">
                                            {details?.patient?.healthInsurance
                                                ?.enrolleeNumber || '-'}
                                        </p>
                                    </div>
                                    <div className="flex items-center gap-4 ">
                                        <p className="text-sm font-medium text-lightBrown100">
                                            Provider
                                        </p>
                                        <p className="text-[16px] w-[250px]  overflow-auto font-medium text-[#534341] flex-wrap flex">
                                            {details?.patient?.healthInsurance
                                                ?.insuranceProvider || '-'}{' '}
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <p className="text-sm tracking-wide font-medium text-lightBrown mb-3">
                                        Family members
                                    </p>
                                    {details.familyMembers &&
                                    details.familyMembers.length > 0 ? (
                                        details.familyMembers.map(
                                            (member, index) => (
                                                <div
                                                    key={index}
                                                    className="flex items-center space-x-1 gap-2"
                                                >
                                                    <img
                                                        src={
                                                            member.image || '-'
                                                        }
                                                        className=" h-7 w-7 rounded-full"
                                                        alt=""
                                                    />
                                                    <div>
                                                        <p className="text-sm font-medium text-lightBrown">
                                                            {member.firstName ||
                                                                '-'}{' '}
                                                            {member.lastName ||
                                                                '-'}
                                                        </p>
                                                        <p className="text-sm font-medium text-lightBrown100">
                                                            {member.phoneNumber ||
                                                                '-'}
                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        )
                                    ) : (
                                        // <>
                                        //     {' '}

                                        //     <div className="flex items-center space-x-1 my-3">
                                        //         <img
                                        //             src={
                                        //                 details.familyMembers
                                        //                     ?.secondFamilyImage ||
                                        //                 '-'
                                        //             }
                                        //             alt=""
                                        //         />
                                        //         <div>
                                        //             <p className="text-sm font-medium text-lightBrown">
                                        //                 {details?.familyMembers
                                        //                     ?.secondFamilyName ||
                                        //                     '-'}
                                        //             </p>
                                        //             <p className="text-sm font-medium text-lightBrown100">
                                        //                 {details?.familyMembers
                                        //                     ?.secondFamilyNum ||
                                        //                     '-'}
                                        //             </p>
                                        //         </div>
                                        //     </div>
                                        // </>
                                        <p>
                                            Patient has listed no Family members
                                        </p>
                                    )}
                                </div>
                                <div className="max-w-[331px] bg-lightPink200 p-3 rounded-xl mt-5">
                                    <p className="text-Tmred text-base font-normal mb-6 ">
                                        Emergency Contact
                                    </p>
                                    {details?.patient?.hasEmergencyNumber ===
                                    false ? (
                                        'Patient has no emergency contact listed'
                                    ) : (
                                        <div>
                                            <div>
                                                <p className="text-sm font-normal text-lightBrown">
                                                    Person
                                                </p>
                                                <p className="text-sm font-medium text-lightBrown100">
                                                    {details?.emergencyContact
                                                        ?.fullName || '-'}
                                                    {/* maybe the enpoint is suppose
                                                    to be
                                                    details?.emergencyContact
                                                    ?.person */}
                                                </p>
                                            </div>
                                            <div className="my-6">
                                                <p className="text-sm font-normal text-lightBrown">
                                                    Phone Number
                                                </p>
                                                <p className="text-sm font-medium text-lightBrown100">
                                                    {details?.emergencyContact
                                                        ?.phoneNumber || '-'}
                                                </p>
                                            </div>
                                            <div>
                                                <p className="text-sm font-normal text-lightBrown">
                                                    Address
                                                </p>
                                                <p className="text-sm font-medium text-lightBrown100">
                                                    {details?.emergencyContact
                                                        ?.address || '-'}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PatientProfileCards
                    id={id}
                    patTmrNumber={details?.patient?.tmrNumber}
                    lastName={details?.patient?.lastName}
                    firstName={details?.patient?.firstName}
                />
            </div>
            {/* <MedicalRecords /> */}
            <MedicalRecords
                preMedicalForm={details?.patient?.preMedicalForm}
                tmrNumber={details?.patient?.tmrNumber}
                id={details?.patient?.defaultHospital?.id}
            />
        </>)}
            </div>
        </div>
    );
};

export default PatientFiles;
