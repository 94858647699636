import React from 'react';
import DashboardLayout from '../Dashboard/DashboardLayout';
import Withdraw from '../../../components/Billing/Withdraw';
import {Helmet} from 'react-helmet'


const WithdrawMoney = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Withdraw Money
                </title>
            </Helmet>
            <div className="">
                <Withdraw />
            </div>
        </DashboardLayout>
    );
};

export default WithdrawMoney;
