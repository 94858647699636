import React, { useEffect, useState } from 'react';
import Table from '../../constants/Table';
import { Link, useNavigate } from 'react-router-dom';
import Image from '../../Images/noEvent.png';
import { useQuery } from 'react-query';
import hospitalService from '../../services/hospitalService';
import Lottie from 'lottie-react';
import loadingState from '../../Images/loading.json';
import TimeComponent, {
    formatDateDmy,
    standardAmountFormat,
} from '../../utils/helpers';
import { ArrowUp, ArrowDown } from 'iconsax-react';
import { useHospitalAuthStore } from '../../store/hospital/auth';

const RecentTransactions = ({ type }: { type: string }) => {
    const [emptyState, setEmptyState] = useState(true);
    const [transactions, setTransactions] = useState<any[]>([]);
    const hospitalStore = useHospitalAuthStore((state) => state.hospital);
    //API CALL
    const { data, isLoading, isError } = useQuery(['transactions', type], () =>
        hospitalService.getWalletTransaction(type)
    );

    useEffect(() => {
        if (data && data?.transaction?.length === 0) {
            setEmptyState(true);
        } else {
            setTransactions(data?.transaction?.slice(0, 5));
            setEmptyState(false);
        }
    }, [data]);

    const upcomingTableHeaders = [
        {
            title: 'Type',
            render: (row: any) => (
                <div className="py-[6px] text-[12px] flex items-center gap-2 font-medium text-light_gray text-center">
                    <img
                        src={row.type}
                        alt=""
                        className="bg-gray-200 rounded-full"
                    />

                    <p className="font-medium !text-[#201A19] text-sm  flex items-center gap-3">
                        {row.transactionType === 'credit' ? (
                            <div className="flex h-10 w-10 shrink-0 grow-0 items-center justify-center rounded-full bg-[#DDDDDD]">
                                <ArrowDown size={18} />
                            </div>
                        ) : (
                            <div className="flex h-10 w-10 shrink-0 grow-0 items-center justify-center rounded-full bg-[#DDDDDD] ">
                                <ArrowUp size={18} />
                            </div>
                        )}
                        {row.transactionType}
                    </p>
                </div>
            ),
        },
        {
            title: 'Sender/Receiver',
            render: (row: any) => (
                <p className=" font-medium text-deep_black text-sm text-center" style={{ width: '130px', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                {row.transactionDetails?.senderName || '-'                }
            </p>
            ),
        },
        {
            title: 'Transaction Id',
            render: (row: any) => (
                <div className="flex items-center gap-x-[10px]">
                    <div>
                        <p className="!text-lightBrown200 text-[12px] font-medium">
                            {row.transactionId}
                        </p>
                    </div>
                </div>
            ),
        },
        {
            title: 'Narration',
            render: (row: any) => (
                <p className="!text-lightBrown text-xs font-medium " style={{ width: '130px', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                    {row.narration}
                </p>
            ),
        },
       

        {
            title: 'Amount',
            render: (row: any) => (
                <div>
                    <p style={{ width: '100px', whiteSpace: 'normal', wordWrap: 'break-word' }}
                        className={`${
                            row.transactionType === 'credit'
                                ? '!text-[#3A975F]'
                                : '!text-[#201A19]'
                        } text-xs font-medium`}
                    >
                        {standardAmountFormat(row.amount,hospitalStore.currency)}
                    </p>
                </div>
            ),
        },

        {
            title: 'Date',
            render: (row: any) => (
                <p className="capitalize font-medium text-lightBrown text-xs">
                    {' '}
                    {formatDateDmy(row.updatedAt)}
                </p>
            ),
        },
        {
            title: 'Time',
            render: (row: any) => <TimeComponent time={row.createdAt} />,
        },
    ];
    if (isLoading) {
        return (
            <div className="flex justify-center items-center py-32">
                {' '}
                <Lottie
                    animationData={loadingState}
                    loop={true}
                    className="lottie"
                />
            </div>
        );
    }

    return (
        <div className="mt-6 px-4 py-4 rounded-2xl flex-1  bg-white overflow-x-auto scrollbar-hide">
            <div className="flex justify-between sm:flex-row flex-col items-center mb-4">
                <h2 className="text-black font-bold text-md md:text-xl">
                    Recent Wallet Transactions
                </h2>
            </div>
            {emptyState ? (
                <div className="flex flex-col justify-center items-center my-20">
                    <img src={Image} alt="empty" />
                    <p className="mt-4 text-sm font-medium text-dark100">
                        No Recent Wallet transaction
                    </p>
                </div>
            ) : (
                <div className="mt-[13px]  flex flex-col px-4 py-3 ">
                    {/* {!emptyState ? ( */}
                    <>
                        <Table
                            rows={transactions}
                            headers={upcomingTableHeaders}
                            allowRowClick
                            showHead={true}
                            // onRowClick={() =>
                            //     navigate(`/hospital/billing/bill-history`)
                            // }
                        />
                        <div className="flex items-center justify-center w-full my-3 pt-6">
                            <Link
                                to={
                                    type === 'personal'
                                        ? '/hospital/my-wallet/my-wallet-transactions'
                                        : '/hospital/hospital-wallet/wallet-transactions'
                                }
                                className="w-full sm:w-fit h-[40px] text-[14px] flex items-center text-center justify-center text-[#C00010] border border-[#C00010] py-2 px-6 rounded-lg font-medium"
                            >
                                View all transactions
                            </Link>
                        </div>
                        {/* <Pagination /> */}
                    </>
                    {/* ) : ( */}
                    {/* <>
                            <img src={empty} alt="" />
                        </>
                    )} */}
                </div>
            )}
        </div>
    );
};
export default RecentTransactions;
