import React, { useState, ReactNode } from 'react';
import search from '../../Images/Leading-icon.png';
import Filter from '../../Images/filtericon.svg';
interface Tab {
    title: string;
    content: ReactNode;
    count:number;
}

interface TabsProps {
    tabs: Tab[];

}

const Tabs: React.FC<TabsProps> = ({ tabs}) => {
    const [activeTab, setActiveTab] = useState(0);

    const [selectedOption, setSelectedOption] = useState(null);
    const handleOptionChange = (option: any) => {
        setSelectedOption(option);
    };
   
   
   
    const [selectedTab, setSelectedTab] = useState('tab1'); // State to keep track of selected tab

    // const handleTabChange = (tab: any) => {
    //     setSelectedTab(tab);
    // };
    const handleTabChange = (tabIndex: number) => {
    setActiveTab(tabIndex); // Update activeTab based on the selected tab index
    setSelectedTab(tabs[tabIndex].title);
};

    return (
        <>
            <div className="w-full">
                <div className=" flex flex-col">
                    <div className="flex flex-col-reverse sm:flex-row justify-between w-full border-[#EDE0DE] border-b ">
                        <div className="flex">
                            {tabs.map((tab, index) => (
                                <div
                                    onClick={() => setActiveTab(index)}
                                    className={`flex items-center tab-small-screen  ${
                                        activeTab === index
                                            ? 'border-b-2  border-Tmred'
                                            : ''
                                    }`}
                                >
                                    <div className={`mx-2 flex  items-center `}>
                                        <button
                                            key={index}
                                            className={`px-2 py-2 font-medium text-[14px]  ${
                                                index > 0 ? 'ml-2' : ''
                                            } ${
                                                activeTab === index
                                                    ? 'text-Tmred'
                                                    : 'text-[#534341]'
                                            } focus:outline-none`}
                                        >
                                            {tab.title}
                                        </button>
                                        <span
                                            className={` w-[24px] h-[26px]  text-center p-1 flex items-center justify-center bg-Tmred text-[12px] rounded-[100px] text-[#fff]  focus:outline-none`}
                                        >
                                        {tab.count}
                                        </span>
                                      

                                    </div>
                                </div>
                            ))}
                            <div></div>
                        </div>
                        <div className="flex items-center space-x-[52px] justify-between pb-[2px]">
                            <div className=" flex items-center gap-2 border bg-[#F5F6F7] border-[#F5F6F7] rounded-[28px] sm:max-w-[328px]">
                                <img src={search} alt="search" />
                                <input
                                    type="text"
                                    placeholder="Search"
                                    //   value={searchQuery}
                                    //   onChange={(e) => setSearchQuery(e.target.value)}
                                    className="w-full font-medium text-[14px] bg-transparent text-[#534341] border-transparent outline-none focus:ring-0"
                                />
                            </div>
                            <div className="flex  items-center">
                                <img
                                    src={Filter}
                                    alt="filterIcon"
                                    className="cursor-pointer h-[24px] w-[24px]"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="">{tabs[activeTab].content}</div>
                </div>
            </div>
        </>
    );
};

export default Tabs;
