import React from 'react';
import usePagination from '../../../hooks/usePagination';
import { formatDateDmy } from '../../../utils/helpers';
import Table from '../../../constants/Table';
import { useNavigate } from 'react-router-dom';

interface rowType {
    _id: string;
    tmrNumber?: string
}
const RecordSearches = ({filteredpatients}) => {
    const navigate = useNavigate();
    console.log(filteredpatients);
    
    
    const { page, limit, Pagination } = usePagination({
        page: 1,
        limit: 10,
        total: filteredpatients?.length,
    });
    
    const paginatedRows = filteredpatients?.slice((page - 1) * limit, page * limit);
    const recordSearchTableHeaders = [
        { title: 'S/N', render: (row: any) => <p></p> },
        {
            title: 'Patient Name',
            render: (row: any) => (
                <div className="flex items-center gap-x-[10px]">
                    <div>
                        <p className="text-sm font-medium !text-lightBrown ">
                            {row.firstName + " " + row.lastName}
                        </p>
                        <p className="text-xs font-medium !text-lightBrown100">
                            {row.tmrNumber}
                        </p>
                    </div>
                </div>
            ),
        },
        {
            title: 'Gender',
            render: (row: any) => (
                <div className="flex items-center gap-x-[10px]">
                    <div>
                        <p className="text-xs font-medium text-text-lightBrown100">
                            {row.gender}
                        </p>
                    </div>
                </div>
            ),
        },
        {
            title: 'Phone Number',
            render: (row: any) => (
                <p className="text-xs font-medium text-lightBrown100">
                    {row.phoneNumber}
                </p>
            ),
        },
        {
            title: 'Address',
            render: (row: any) => (
                <p className="font-medium text-xs text-text-lightBrown100whitespace-normal">
                    {row.address}
                </p>
            ),
        },
        {
            title: 'Date Registered',
            render: (row: any) => (
                <p className="text-xs font-medium text-text-lightBrown100">
                    {' '}
                    {formatDateDmy(row.createdAt)}
                </p>
            ),
        },
    ];
    return (
        <div className="w-full">
            <div className="gap-[2rem] block md:grid">
                <div className="w-full grid gap-x-[30px] self-start md:mb-[0] mb-[2rem]">
                    <div className="pt-[10px]  py-[20px] overflow-x-auto">
                        {' '}
                        <Table
                            rows={paginatedRows}
                            headers={recordSearchTableHeaders}
                            showHead={true} 
                            allowRowClick
                            onRowClick={(row: rowType) =>
                                navigate(`/hospital/patient/${row.tmrNumber}`)
                            }
                        />
                        <Pagination />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecordSearches;
