import {useEffect, useState } from 'react'
import { useHospitalAuthStore } from '../../../store/hospital/auth';
import Table from '../../../constants/Table';
import hospitalService from '../../../services/hospitalService';
import { ToastContainer, toast } from 'react-toastify';
import usePagination from '../../../hooks/usePagination';
import { useNavigate } from 'react-router-dom';
import { formatDateDmy, formatNumber, standardAmountFormat } from '../../../utils/helpers';

const SubscriptionPlanTab = () => {
    const subPlan = useHospitalAuthStore((state) => state.subscription.plan);    
    console.log(subPlan);
    const navigate = useNavigate();
    const [userSubHistory, setUserSubHistory] = useState<any>([])
    const [expiryDate, setExpiryDate] = useState<any>('') 

    const subFeatures: string[] = [];
    for (const [key, value] of Object.entries(subPlan.features)) {
        if (value === "true") {
            subFeatures.push(key);
        }
    }
    

    //Get the user's subscription History and set it to set on success
    useEffect(() => {
        hospitalService.getUserSubscriptions()
        .then((res) =>{
            console.log(res.subscription); 
            setUserSubHistory(res.subscription)
        })
        .catch((error) =>{
            toast.error(error)
        })
    }, [])
    console.log('sub hist',userSubHistory);
    console.log('last hist',userSubHistory[userSubHistory.length-1]);
    
    useEffect(() => {
    if (userSubHistory) {
        // Ensure that userSubHistory is not empty to avoid accessing properties of undefined
        if (userSubHistory.length > 0) {
            setExpiryDate(userSubHistory[userSubHistory.length - 1].startDate);
        } else {
            // Handle the case where userSubHistory is an empty array
            setExpiryDate('');
        }
    }
}, [userSubHistory]);
    
    
    //Pagination
    const { page, limit, Pagination } = usePagination({
        page: 1,
        limit: 10,
        total: userSubHistory?.length,
    });

    const paginatedRows = userSubHistory?.slice(
        (page - 1) * limit,
        page * limit
    );
    const currency = useHospitalAuthStore((state) => state.hospital.currency);
    // console.log(currency);     
    
    
    const getTime = (timestamp) =>{
        const date = new Date(timestamp)
        const hours = date.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const hours12 = hours % 12 || 12; 
        console.log(hours12);
        
        return  ` ${hours12}  ${ampm} `
    } 

    const datePlus30days = (timestamp) =>{
        const date = new Date(timestamp);
        date.setDate(date.getDate() + 30);
        return date.toISOString();
    }
        
    const subHistoryHeaders = [
        {
            title: <p className=" font-semibold"> Plan </p>,
            render: (row: any) =>(
                <p className=' font-semibold'>{row.plan.name} ({row.plan.type}) </p>
            ),
        },
        
        {
            title: <p className=" font-semibold"> Amount </p>,
            render: (row: any) =>(
                <p className='text-black font-semibold'>{row.amount}  </p>
            ),
        },

        {
            title: <p className=" font-semibold"> Paid Date</p>, 
            render: (row: any) =>(
                <p className=' text-black font-semibold'>{formatDateDmy(row.startDate)}  </p>
            ),
        },
        {
            title: <p className=" font-semibold"> Expiry Date</p>, 
            render: (row: any) =>(
                <p className=' text-black font-semibold'> {formatDateDmy(datePlus30days(row.startDate))} </p>
            ),
        },

        {
            title:  <p className=" font-semibold"> Time</p>,
            render: (row: any) =>(
                <p className=' font-semibold'>{getTime(row.startDate)}  </p>
            ),
        },

    ]
    


    return (
        <div>
            <ToastContainer /> 
            <div className="flex justify-between">
                <h1 className="text-[24px] font-semibold">Subscription Plan</h1>
                <button 
                    className="border-Tmred border py-3 px-5 font-semibold text-Tmred rounded-lg"
                    onClick={() => navigate('/hospital/auth/subscription-plan')} 
                >
                    Change plan
                </button>
            </div>
            <div className="flex  gap-5 mt-5">
                <div className="border-2 border-Tmred flex flex-col justify-center items-center p-5 min-w-[350px] rounded-2xl">
                    <h3
                        className="first-letter:font-semibold text-center text-2xl text-blueX"
                    >
                        {subPlan?.name }
                        
                    </h3>
                    <p className="text-center text-dark100">
                        {subPlan?.type }
                    </p>

                     <div
                        className={`border-t-8 ${
                            subPlan
                                ? 'border-Tmred'
                                : 'border-blueX'
                        } rounded-lg my-3 w-12`}
                    />
                    <h2 className="flex justify-center mt-5 text-[36px] font-semibold ">
                       
                    {standardAmountFormat(
    subPlan?.amount || 0,
    currency
)}

                    </h2>

                    <span className="flex justify-center text-black100 text-center">

                        Expiry Date : 
                        <span className="font-semibold">  {expiryDate? formatDateDmy(datePlus30days(expiryDate)) : '--'} </span> 
                        
                    </span>
                    
                     <button className=" bg-lightPinkX py-3 px-5 font-semibold w-full rounded-lg text-lightBrown mt-4">
                    Active Plan
                </button>
                    
                </div>
                <div className="w-full">
                    <h2 className="text-[26px] font-normal tracking-wide">Features</h2>
                    <div className="text-dark100 w-full bg-lightGray500 p-5 rounded-lg">
                        {Object.keys(subPlan.features).map((feature, index) => {
                            return (
                                <div
                                    key={index}
                                    className="flex items-center gap-5 mb-5"
                                >
                                    
                                    <p className="text-[14px]">
                                        {subPlan.features[feature].label}
                                    </p>
                                    <div className="flex-shrink-0">
            
 <p  className="text-[14px] font-medium text-[#534341]">
          {feature === 'privateMode' 
            ? (subPlan.features[feature].value ? 'yes' : 'no')
            : typeof subPlan.features[feature].value === 'number' 
              ? formatNumber(subPlan.features[feature].value) // Format number with commas
              : subPlan.features[feature].value // Render other values as is
          }
        </p>
                </div>
                                </div>
                            );
                        })}

                    </div>
                </div>
            </div>
            <div className="my-9">
                <h2 className=' font-semibold text-[24px]'>
                    Subcription History
                </h2>

                <div className="my-4">
                    <Table 
                        rows={paginatedRows}
                        headers={subHistoryHeaders}
                        showHead={true} 
                    />
                    <Pagination />
                </div>
                
            </div>
        </div>
    )
}

export default SubscriptionPlanTab