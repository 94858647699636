import React from 'react';
import DashboardLayout from '../Dashboard/DashboardLayout';
import {Helmet} from 'react-helmet'
import TransactionHistory from '../../../components/Billing/TransactionHistory';


const TransactionHistories = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Wallet Transactions
                </title>
            </Helmet>
            <div className="">
            <TransactionHistory/>
            </div>
        </DashboardLayout>
    );
};

export default TransactionHistories;
