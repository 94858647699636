import { Star1, StarSlash } from 'iconsax-react';

const PatientReview = (appointmentDetails) => {
    
    return (
        <div className="w-full p-4 bg-[#fff]  h-fit rounded-[20px]">
            <div className="bg-lightTmrRed rounded-[20px] p-4 mb-4">
                <p className="font-normal sm:text-[22px] text-[18px] text-[#201A19]">
                    Patient Review
                </p>
            </div>
            <div>
                <div className="border-b-2">
                    <div className="flex gap-4 justify-center mb-8">
                        {[1, 2, 3, 4, 5].map((index) => (
                            appointmentDetails.rating >= index ? (
                                <StarSlash size="32" color="#FF8A65" />
                            ) : (
                                <Star1 size="32" color="#FF8A65" />
                            )
                        ))}
                    </div>
                </div>
                <div className='py-3 mx-2 bg-[#F8F8F8] mt-4 rounded-md'>
                    <p className='p-2 text-[#534341]'>{appointmentDetails?.review || 'Awaiting patients review...'}</p>
                </div>
            </div>
        </div>
    )
}

export default PatientReview