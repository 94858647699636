import Patients from '../../../components/Patient/Patients';
import DashboardLayout from '../Dashboard/DashboardLayout';
import {Helmet} from 'react-helmet'


const Patient = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Patient
                </title>
            </Helmet>
            <div>
                <Patients />
            </div>
        </DashboardLayout>
    );
};
export default Patient;
