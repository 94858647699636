export const hospitalRoutes = {
    healthID: '/',
    landingLogin: '/landing/login',
    login: '/hospital/auth/login',
    register: '/hospital/auth/register',
    superAdmin: '/hospital/auth/super-admin',
    setupLockcode: '/hospital/auth/setup-lockcode',
    subscriptionPlan: '/hospital/auth/subscription-plan',
    applicationProcessing: '/hospital/auth/application-processing',
    screenTimeout: '/hospital/screen-timeout',
    forgotPassword: '/hospital/auth/forgot-password',
    resetPassword: '/hospital/auth/reset-password',
    dashboard: '/hospital/dashboard',
    doctor: '/hospital/user/doctor',
    addDoctor: '/hospital/user/add-doctor',
    doctorProfile: '/hospital/user/doctor-profile',
    admin: '/hospital/user/admin',
    addAdmin: '/hospital/user/add-admin',
    adminProfile: '/hospital/user/admin-profile',
    nurse: '/hospital/user/nurse',
    addNurse: '/hospital/user/add-nurse',
    nurseProfile: '/hospital/user/nurse-profile',
    recordSearch: '/hospital/dashboard/recordsearch',
    appointment: '/hospital/appointment',
    patientAppointment: '/hospital/appointment/',
    bookAppointment: '/hospital/appointment/book-appointment',
    patient: '/hospital/patient',
    newPatient: '/hospital/patient/new-patient',
    newPatientSubscription: '/hospital/patient/new-patient/subscription',
    paymentVoucher:
        '/hospital/patient/new-patient/subscription/voucher-payment',
    patientId: '/hospital/patient/:id',
    patientAppointments: '/hospital/patient/:id/patient-appointments',
    billing: '/hospital/billing',
    sendMoney: '/hospital/billing/send-money',
    withdrawMoney: '/hospital/billing/withdraw-money',
    billHistory: '/hospital/billing/bill-history',
    createBill: '/hospital/billing/create-bill',
    billInvoice: '/hospital/billing/bill-invoice',
    topUp: '/hospital/billing/add-money',
    profile: '/hospital/settings/profile',
    hospital: '/hospital/settings/hospital',
    staffSettings: '/hospital/settings/my-settings',
    editProfile: '/hospital/settings/hospital/edit',
    editPersonalProfile: '/hospital/settings/profile/edit',
    otp: '/hospital/auth/otp',
    startCall: '/hospital/appointment/call/',
    messaging: '/hospital/messaging',
    hospitalWallet: '/hospital/hospital-wallet',
    myWallet: '/hospital/my-wallet',
    voucher: '/hospital/voucher',
    HospitalVoucher:'/hospital/voucher/hospital-voucher',
    ambulance: '/hospital/ambulance',
    fleetMgmt:'/hospital/ambulance/fleet-Mgmt',
    requestMgmt:'/hospital/ambulance/request-Mgmt',
    ambulanceHistory:'/hospital/ambulance/fleet/ambulance-history',
    ambulanceStaffEdit:'/hospital/ambulance/fleet/ambulance-profile/edit',
    addAmbulance:'/hospital/ambulance/fleet/add-ambulance',
    ambulanceRequestHistory:'/hospital/ambulance/request/history',
    tmrAds:'/hospital/tmr-ads',
    createAds:'/hospital/tmr-ads/create',
    adsProfile:'hospital/ads/:id'
};
