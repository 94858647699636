import React, { useEffect, useState } from 'react';
import TimePickerElement from '../../../reusable/Forms/TimePickerElement';
import dayjs from 'dayjs';
import RadioButtonsGroup from '../../../reusable/Forms/RadioButton';
import { useMutation } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logger from '../../../utils/logger';
import Input from '../../../reusable/Forms/Input';
import CustomCheckbox from '../../../reusable/Forms/Checkbox';
import { useHospitalAuthStore } from '../../../store/hospital/auth';
import hospitalService from '../../../services/hospitalService';
import ErrorState from '../../../reusable/ErrorState';
import Tooltip from '@mui/material/Tooltip';
import { InfoCircle } from 'iconsax-react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import CustomCheckboxWrapper from '../../../reusable/Forms/CustomCheckboxWrapper';
// import { TextField } from '@mui/material';
// import { standardAmountFormat } from '../../../utils/helpers';
const AppointmentSchedulling = () => {
    const hospitalAuthStore = useHospitalAuthStore((state) => state.hospital);
    const [formData, setFormData] = useState({
        selectedOpenTime: hospitalAuthStore.scheduling.openHours.start,
        selectedCloseTime: hospitalAuthStore.scheduling.openHours.end,
        duration:
            hospitalAuthStore.scheduling.durationPerSession?.toString() || '',
        TeleFee: hospitalAuthStore.scheduling.telemedicine.fee,
        TeleCapacity: hospitalAuthStore.scheduling.telemedicine.capacity,
        HosFee: hospitalAuthStore.scheduling.homeVisit.fee,
        Session: '',
        HomeCapacity: hospitalAuthStore.scheduling.homeVisit.capacity,
        WalkInCapacity: hospitalAuthStore.scheduling.walkIn.capacity,
        WalkInFee: hospitalAuthStore.scheduling.walkIn.fee,
        defaultHospitalFee:
            hospitalAuthStore.scheduling.defaultHospitalFee || '',
    });
    const mutation = useMutation((data: any) =>
        hospitalService.editHospitalSchedule(data)
    );
    const [buttonEnabled, setButton] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const hospitalAccountStore = useHospitalAuthStore((state) => state);
    const [isCustomSelected, setIsCustomSelected] = useState(false);
    const [isOpen24Hours, setIsOpen24Hours] = useState(false);
    const [isAllDays, setAllDays] = useState(false);
    const [customDuration, setCustomDuration] = useState('');
    const [daysOfWeek, setDaysOfWeek] = useState(() => {
        const initialDays: Record<string, boolean> = {
            sunday: false,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
        };

        hospitalAuthStore.scheduling.availableDays.forEach((day) => {
            initialDays[day.toLowerCase()] = true;
        });

        return initialDays;
    });
    const initialFormData = {
        selectedOpenTime: hospitalAuthStore.scheduling.openHours.start,
        selectedCloseTime: hospitalAuthStore.scheduling.openHours.end,
        duration:
            hospitalAuthStore.scheduling.durationPerSession?.toString() || '',
        TeleFee: hospitalAuthStore.scheduling.telemedicine.fee,
        TeleCapacity: hospitalAuthStore.scheduling.telemedicine.capacity,
        HosFee: hospitalAuthStore.scheduling.homeVisit.fee,
        Session: '',
        HomeCapacity: hospitalAuthStore.scheduling.homeVisit.capacity,
        WalkInCapacity: hospitalAuthStore.scheduling.walkIn.capacity,
        WalkInFee: hospitalAuthStore.scheduling.walkIn.fee,
        defaultHospitalFee:
            hospitalAuthStore.scheduling.defaultHospitalFee || '',
    };

    useEffect(() => {
        // Check if there is a value in custom duration and set isCustomSelected accordingly
        if (customDuration || formData.duration === 'custom') {
            setIsCustomSelected(true);
        }
    }, [customDuration, formData.duration]);

    useEffect(() => {
        // Check if there is a value in custom duration and set isCustomSelected accordingly
        if (customDuration || formData.duration === 'custom') {
            setIsCustomSelected(true);
        } else {
            // Check if durationPerSession matches any predefined option
            const predefinedOptions = ['15', '20', '30'];
            const durationPerSession =
                hospitalAuthStore.scheduling.durationPerSession?.toString();
            if (
                !durationPerSession ||
                !predefinedOptions.includes(durationPerSession)
            ) {
                setIsCustomSelected(true);
                setCustomDuration(
                    hospitalAuthStore.scheduling.durationPerSession?.toString() ||
                        ''
                );
            } else {
                setIsCustomSelected(false);
                setCustomDuration('');
            }
        }
    }, [
        customDuration,
        formData.duration,
        hospitalAuthStore.scheduling.durationPerSession,
    ]);

    const handleOpen24HoursChange = () => {
        setIsOpen24Hours((prevIsOpen24Hours) => !prevIsOpen24Hours);

        // If Open 24 hours is selected, set open and close time to default values
        if (isOpen24Hours) {
            setFormData((prevData) => ({
                ...prevData,
                selectedOpenTime: '00:00',
                selectedCloseTime: '23:59',
            }));
        }
        setButton(true);
    };

    useEffect(() => {
        // Check if open and close times are set to 00:00 and 23:59 respectively
        const isDefault24Hours =
            formData.selectedOpenTime === '00:00' &&
            formData.selectedCloseTime === '23:59';

        setIsOpen24Hours(isDefault24Hours);
    }, [formData.selectedOpenTime, formData.selectedCloseTime]);
    const resetToPrevious = () => {
        setFormData(initialFormData);
        setCustomDuration(initialFormData.duration);
        setButton(false);
        // console.log('Initial Form Data:', initialFormData);
        // console.log('Current Form Data:', formData);

        // Reset daysOfWeek based on initial available days
        setDaysOfWeek(() => {
            const initialDays: Record<string, boolean> = {
                sunday: false,
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
            };

            hospitalAuthStore.scheduling.availableDays.forEach(
                (day: string) => {
                    initialDays[day.toLowerCase()] = true;
                }
            );

            return initialDays;
        });
    };
    const checkIfFormIsValid = (formData, daysOfWeek) => {
        if (
            !formData.selectedOpenTime ||
            !formData.selectedCloseTime ||
            !formData.duration ||
            !formData.TeleFee ||
            !formData.TeleCapacity ||
            !formData.HosFee ||
            !formData.HomeCapacity ||
            !Object.values(daysOfWeek).includes(true)
        ) {
            toast.error('All fields are required');
            return false;
        }
        return true;
    };

    const handleCustomDurationChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        e.preventDefault();
        const value = e.target.value;
        // const { name, value } = e.target as HTMLInputElement;

        // setFormData((prevData) => ({
        //     ...prevData,
        //     [name]: value,
        // }));
        setIsCustomSelected(!!value); // Set isCustomSelected based on whether the value is truthy
        setCustomDuration(value);
        // setButton(true);
    };

    const handleCheckboxChange = (day: string, checked: boolean) => {
        setDaysOfWeek((prevDays) => ({
            ...prevDays,
            [day]: checked,
        }));
        setButton(true);
    };
    const handleCheckboxAll = (checked: boolean) => {
        setAllDays((prev) => !prev);
        setDaysOfWeek((prevDays) => ({
            ...prevDays,
            sunday: checked,
            monday: checked,
            tuesday: checked,
            wednesday: checked,
            thursday: checked,
            friday: checked,
            saturday: checked,
        }));
        setButton(true);
    };
    console.log(daysOfWeek);
    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            console.log('success', mutation.data);
            let payload: any = mutation.data;
            hospitalAccountStore.updateHospital(payload.data.payload.data.hospital);
            const data = payload.data;
            logger(data.message);
            // Show success message using Toastify
            toast.success('Schedule updated successfully');
            setTimeout(() => {
                toast.dismiss(); // Dismiss the toast after 3000 milliseconds
            }, 2000);
        }
    }, [mutation.isSuccess, mutation.data]);

    useEffect(() => {
        if (mutation.isError) {
            setIsLoading(false);
            let err: any = mutation.error;
            setErrorMessage(err.response.data.error.message);
        } else {
            setErrorMessage('');
        }
    }, [mutation.isError, mutation.error]);

    useEffect(() => {
        console.log(formData.duration);
    }, [formData.duration]);

    const handleChange = (value: string) => {
        if (value === 'custom') {
            setIsCustomSelected(true);
            // Set custom duration to formData.duration or an empty string if not available
            //   setCustomDuration(formData.duration || '');
        } else {
            setIsCustomSelected(false);
            // Update formData.duration with the selected value, not the label
            setFormData((prevData) => ({
                ...prevData,
                duration: value,
            }));
        }
        setButton(true);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const { name, value } = e.target as HTMLInputElement;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        setButton(true);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!checkIfFormIsValid(formData, daysOfWeek)) {
            return;
        }
        const selectedDuration =
            isCustomSelected && customDuration
                ? parseInt(customDuration, 10)
                : parseInt(formData.duration as string, 10);
        const updatedData = {
            openHours: {
                start: isOpen24Hours ? '00:00' : formData.selectedOpenTime,
                end: isOpen24Hours ? '23:59' : formData.selectedCloseTime,
            },
            durationPerSession: selectedDuration,
            availableDays: Object.entries(daysOfWeek)
                .filter(([day, isChecked]) => isChecked)
                .map(([day]) => day.charAt(0).toUpperCase() + day.slice(1)),
            telemedicine: {
                fee: parseInt(formData.TeleFee as string, 10),
                capacity: parseInt(formData.TeleCapacity as string, 10),
            },
            homeVisit: {
                fee: parseInt(formData.HosFee as string, 10),
                capacity: parseInt(formData.HomeCapacity as string, 10),
            },
            walkIn: {
                fee: parseInt(formData.WalkInFee as string, 10),
                capacity: parseInt(formData.WalkInCapacity as string, 10),
            },
            defaultHospitalFee: parseInt(
                formData.defaultHospitalFee as string,
                10
            ),
        };

        console.log('Updated Data:', updatedData);
        setIsLoading(true);
        mutation.mutate(updatedData);
        setButton(false);
    };

    const styles = {
        // backgroundColor: '#362F2E',
        fontSize: '12px',
        color: '#FBEEEC',
        padding: '2px',
    };

    return (
        <div>
            <ToastContainer />
            {errorMessage && <ErrorState message={errorMessage} />}
            <div className="bg-white rounded-lg shadow-sm h-[100vh]">
                <h1 className="font-medium sm:text-[22px] text-[18px] pb-[18px]">
                    Appointment Scheduling
                </h1>
                <div className="flex gap-4 flex-col sm:flex-row  lg:w-[873px] bg-white">
                    <div className="sm:flex-1">
                        <p className="font-normal text-[14px] sm:text-[16px]">
                            Opening Hours
                        </p>
                        <div className="bg-white rounded-lg shadow-md w-full sm:w-[459px] p-4 mt-2">
                            <div>
                                <CustomCheckboxWrapper
                                    label="Open 24 hours"
                                    checked={isOpen24Hours}
                                    onChange={handleOpen24HoursChange}
                                />
                            </div>
                            <div className="flex flex-col sm:flex-row items-center gap-4 ">
                                <div className="flex items-center gap-2">
                                    <p className="font-semibold text-[14px] sm:text-[16px]">
                                        Open
                                    </p>
                                    <TimePickerElement
                                        value={
                                            isOpen24Hours
                                                ? dayjs('00:00', 'HH:mm')
                                                : formData.selectedOpenTime
                                                  ? dayjs(
                                                        formData.selectedOpenTime,
                                                        'HH:mm'
                                                    )
                                                  : null
                                        }
                                        onChange={(time) => {
                                            setFormData((prevData) => ({
                                                ...prevData,
                                                selectedOpenTime: time
                                                    ? time.format('HH:mm')
                                                    : '',
                                            }));
                                            setButton(true);
                                        }}
                                    />
                                </div>

                                <div className="hidden sm:block border-r-[2px] h-[52px] w-[2px] border-r-[#D8C2BF] border-solid"></div>
                                <div className="flex items-center gap-2">
                                    <p className="font-semibold text-[14px] sm:text-[16px]">
                                        Close
                                    </p>
                                    <TimePickerElement
                                        value={
                                            isOpen24Hours
                                                ? dayjs('23:59', 'HH:mm')
                                                : formData.selectedCloseTime
                                                  ? dayjs(
                                                        formData.selectedCloseTime,
                                                        'HH:mm'
                                                    )
                                                  : null
                                        }
                                        onChange={(time) => {
                                            setFormData((prevData) => ({
                                                ...prevData,
                                                selectedCloseTime: time
                                                    ? time.format('HH:mm')
                                                    : '',
                                            }));
                                            setButton(true);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-between sm:w-[459px] py-8">
                            <p className="font-semibold text-[16px]">
                                Duration
                            </p>
                        </div>
                        <div className="bg-white rounded-lg shadow-md w-full sm:w-[459px] p-4 mt-2 ">
                            <RadioButtonsGroup
                                label="Duration"
                                options={[
                                    { value: '15', label: '15 mins' },
                                    { value: '20', label: '20 mins' },
                                    { value: '30', label: '30 mins' },
                                    { value: 'custom', label: 'Custom' },
                                ]}
                                value={
                                    isCustomSelected
                                        ? 'custom'
                                        : formData.duration
                                }
                                onChange={handleChange}
                                customOptionChecked={isCustomSelected}
                                customDuration={customDuration}
                                handleCustomDurationChange={
                                    handleCustomDurationChange
                                }
                                isCustomSelected={isCustomSelected}
                            />
                        </div>
                        {/* <div className="pt-8">
                                    <div className=" flex py-4">
                                        <button></button>
                                        <p className="text-[16px] font-medium">
                                        Default Hospital Fee
                                        </p>
                                    </div>
                                    <Input
                                        value={
                                           formData.defaultHospitalFee
                                        }
                                        name="defaultHospitalFee"
                                        label="Enter Fee (₦}"
                                        onChange={handleInputChange}
                                        
                                    />
                               
                            </div> */}
                        <div className="pt-8 pb-2 w-full">
                            <div className="flex py-4">
                                <p className="text-[16px] font-medium">
                                    Default Hospital Fee
                                </p>
                            </div>
                            <TextField
                                className="w-full"
                                type="number"
                                value={formData.defaultHospitalFee}
                                name="defaultHospitalFee"
                                label="Enter Fee (₦}"
                                onChange={handleInputChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip
                                                title={
                                                    <div style={styles}>
                                                        A fee charged to
                                                        patients
                                                        <br />
                                                        for selecting your
                                                        hospital <br />
                                                        as their default
                                                        healthcare <br />{' '}
                                                        provider.
                                                    </div>
                                                }
                                            >
                                                <InfoCircle
                                                    size="20"
                                                    color="#534341"
                                                />
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div className="py-8">
                            <p className="font-normal text-[14px] sm:text-[16px]">
                                Fee
                            </p>
                            <div className="flex flex-col sm:flex-row items-center gap-8 sm:w-[459px]">
                                <div className="w-full">
                                    <div className=" flex py-4">
                                        <button></button>
                                        <p className="text-[16px] font-medium">
                                            Walk in
                                        </p>
                                    </div>
                                    <Input
                                        value={formData.WalkInFee}
                                        name="WalkInFee"
                                        label="Enter Fee (₦}"
                                        onChange={handleInputChange}
                                        type="number"
                                    />
                                </div>
                                <div className="w-full">
                                    <div className=" flex py-4">
                                        <button></button>
                                        <p className="text-[16px] font-medium">
                                            Telemedicine
                                        </p>
                                    </div>
                                    <Input
                                        value={formData.TeleFee}
                                        name="TeleFee"
                                        label="Enter Fee (₦}"
                                        onChange={handleInputChange}
                                        type="number"
                                    />
                                    {/* <TextField
                            variant="outlined"
                            type="number"
                            name="TeleFee"
                            label="Enter Fee (₦}"
                            onChange={handleInputChange}
                            value={standardAmountFormat(formData.TeleFee)}
                            
                        /> */}
                                </div>
                                <div className="w-full">
                                    <div className=" flex  py-4">
                                        <button></button>
                                        <p className="text-[16px] font-medium">
                                            Home Visit
                                        </p>
                                    </div>
                                    <Input
                                        value={formData.HosFee}
                                        name="HosFee"
                                        label="Enter Fee (₦}"
                                        onChange={handleInputChange}
                                        type="number"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="pb-8 pt-2">
                            <p className="font-normal text-[14px] sm:text-[16px]">
                                Capacity
                            </p>
                            <div className="flex flex-col sm:flex-row items-center gap-8 sm:w-[459px]">
                                <div className="w-full">
                                    <div className=" flex py-4">
                                        <button></button>
                                        <p className="text-[16px] font-medium">
                                            Walk in
                                        </p>
                                    </div>
                                    <Input
                                        value={formData.WalkInCapacity}
                                        name="WalkInCapacity"
                                        label="No. patients per session"
                                        onChange={handleInputChange}
                                        type="number"
                                    />
                                </div>
                                <div className="w-full">
                                    <div className=" flex  py-4">
                                        <button></button>
                                        <p className="text-[16px] font-medium">
                                            Home Visit Session{' '}
                                        </p>
                                    </div>
                                    <Input
                                        value={formData.HomeCapacity}
                                        name="HomeCapacity"
                                        label="No. patients per session"
                                        onChange={handleInputChange}
                                        type="number"
                                    />
                                </div>
                            </div>
                            <div className="sm:w-1/2">
                                <div className=" flex py-4">
                                    <button></button>
                                    <p className="text-[16px] font-medium">
                                        Telemedicine Session{' '}
                                    </p>
                                </div>
                                <Input
                                    value={formData.TeleCapacity}
                                    name="TeleCapacity"
                                    label="No. patients per session"
                                    onChange={handleInputChange}
                                    type="number"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="sm:flex-1 sm:pl-6">
                        <div className="flex justify-between items-center">
                            <p className="font-normal text-[14px] sm:text-[16px] pb-4">
                                Available Days
                            </p>
                            <div>
                                <CustomCheckboxWrapper
                                    ml="0px"
                                    label="All"
                                    checked={isAllDays}
                                    onChange={handleCheckboxAll}
                                />
                            </div>
                        </div>
                        <div className="w-full border rounded-2xl ">
                            {Object.entries(daysOfWeek).map(
                                ([day, isChecked], index, array) => (
                                    <CustomCheckbox
                                        key={day}
                                        label={
                                            day.charAt(0).toUpperCase() +
                                            day.slice(1)
                                        }
                                        name="daysCheck"
                                        checked={isChecked}
                                        onChange={(checked) =>
                                            handleCheckboxChange(day, checked)
                                        }
                                        isLast={index === array.length - 1}
                                    />
                                )
                            )}
                        </div>
                    </div>
                </div>
                <div className="mt-[16px] items-center gap-4 flex justify-end py-4"></div>
                <div className="mt-[16px] items-center gap-4 flex justify-end border-t py-4">
                    <button
                        disabled={!buttonEnabled}
                        onClick={resetToPrevious}
                        className={`border-0 text-[16px]  ${
                            buttonEnabled
                                ? 'text-Tmred hover:cursor-pointer '
                                : 'text-lightGray'
                        }`}
                    >
                        Reset to previous
                    </button>
                    <button
                        disabled={!buttonEnabled}
                        onClick={handleSubmit}
                        className={`text-[16px] duration-300 rounded-lg px-6 py-1 ${
                            buttonEnabled
                                ? 'bg-Tmred text-white hover:cursor-pointer '
                                : 'bg-[#1D1B201F] text-lightGray'
                        }`}
                    >
                        {isLoading ? 'Updating...' : 'Update'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AppointmentSchedulling;
