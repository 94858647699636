
import AdminProfile from '../../../components/PharmacyComponent/UserAdmin/AdminProfile';
import DashboardLayout from '../Dashboard/DashboardLayout';
import {Helmet} from 'react-helmet'


const AdminProfiles = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Admin's Profile
                </title>
            </Helmet>
            <AdminProfile />
        </DashboardLayout>
    );
};
export default AdminProfiles;
