import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import back from '../../Images/back.png';
import Input from '../../reusable/Forms/Input';
import { useMutation, useQuery } from 'react-query';
import hospitalService from '../../services/hospitalService';
import { standardAmountFormat } from '../../utils/helpers';
import search from '../../Images/Leading-icon.png';
import Lottie from 'lottie-react';
import loadingState from '../../Images/loading.json';
import { toast } from 'react-toastify';
import { useHospitalAuthStore } from '../../store/hospital/auth';
import OTPModal from './OTPModal';
import HospitalSend from './HospitalSend';
import noStaff from '../../Images/no-staf.svg'
const Send = () => {
    const navigate = useNavigate();
    const [buttonEnabled, setButton] = useState(false);
    const [selectedStaffId, setSelectedStaffId] = useState(null);
    const [isloading, setIsLoading] = useState(false);
    const [walletBalance, setWalletBalance] = useState<number>(0);
    const [users, setUsers] = useState<any[]>([]);
    const [otpModal, setOtpModal] = useState<boolean>(false);
    const [buttonOTPEnabled, setOTPButton] = useState(false);
    const [OTPLoading, setIsOTPLoading] = useState(false);
    const currency = useHospitalAuthStore((state) => state.hospital.currency);
    const [walletType, setWalletType] = useState<string>('hospital'); // set hospital as default wallet type
    const [view, setView] = useState<string>('staff'); 
    const onClose = () => {
        setOtpModal(false);
    };
    const {
        data: walletBalanceData,
        isError,
        isLoading,
        refetch,
    } = useQuery(['balance', walletType], () =>
        hospitalService.getWalletBalance(walletType)
    );

    useEffect(() => {
        setWalletBalance(walletBalanceData?.balance);
    }, [walletBalanceData]);

    const {
        data: usersData,
        isError: isUsersError,
        isLoading: isUsersLoading,
    } = useQuery('staffs', () => hospitalService.getAllUsers());

    useEffect(() => {
        if (usersData) {
            const filteredUsers = usersData.users.filter(
                (user) => user?.userType?.toLowerCase() !== 'superadmin'
            );
            setUsers(filteredUsers);
        }
    }, [usersData]);

    // console.log("All staff:", getUsers)
    const mutation = useMutation(({ data, recipientType }: any) =>
        hospitalService.initiateSendStaffMoney(data, recipientType)
    );
    

    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            let payload: any = mutation.data;
            toast.success(mutation.data?.data.message);
            setTimeout(() => {
                toast.dismiss();
            }, 5000);
            setFormData({
                Recipient_Name: '',
                Amount: '',
                purpose: '',
                staffSearch: '',
                selectedStaff: '',
            });
            setButton(false);
            setOtpModal(true);
        }
    }, [mutation.isSuccess, mutation.data]);

    useEffect(() => {
        if (mutation.isError) {
            setIsLoading(false);
            let err: any = mutation.error;
            toast(err.response.data.error.message);
            setTimeout(() => {
                toast.dismiss();
            }, 2000);
        }
    }, [mutation.isError, mutation.error]);

    const otpmutation = useMutation(({ data, recipientType }: any) =>
        hospitalService.completeSendStaffMoney(data, recipientType)
    );
    useEffect(() => {
        if (otpmutation.isSuccess) {
            setIsOTPLoading(false);
            const response = otpmutation.data;
            const message = response.data.message;
            toast.success(response.data.message);
            setTimeout(() => {
                toast.dismiss();
             refetch()
            }, 2000);
            setOtpModal(false);
            setOTPButton(false);
        }
    }, [otpmutation.isSuccess, otpmutation.data]);

    useEffect(() => {
        if (otpmutation.isError) {
            setIsOTPLoading(false);
            let err: any = otpmutation.error;

            toast.error(err.response?.data?.error?.message || 'error');
        } else {
            // console.log('error');
        }
    }, [otpmutation.isError, otpmutation.error]);
    const [formData, setFormData] = useState({
        Recipient_Name: '',
        Amount: '',
        purpose: '',
        staffSearch: '',
        selectedStaff: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (
            formData.Amount !== '' &&
            formData.Recipient_Name !== '' &&
            formData.purpose !== ''
        ) {
            setButton(true);
        }
    }, [formData.Amount, formData.Recipient_Name, formData.purpose]);

    const filteredStaff = users.filter((staff) => {
        const searchQueryLower = formData.staffSearch.toLowerCase();
        return (
            staff.firstName.toLowerCase().includes(searchQueryLower) ||
            staff.lastName.toLowerCase().includes(searchQueryLower) ||
            staff.userType.toLowerCase().includes(searchQueryLower)
        );
    });

    const handleStaffSearchChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const searchQuery = e.target.value.toLowerCase();

        setFormData((prevData: any) => ({
            ...prevData,
            staffSearch: searchQuery,
        }));
    };

    const handlesubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setButton(false);
        try {
            setIsLoading(true);
            const selectedStaff = users.find(
                (staff) => staff._id === selectedStaffId
            );
            const paymentData = {
                amount: formData.Amount,
                recipientId: selectedStaff._id,
                purpose: formData.purpose,
            };
            const recipientType = 'staff';  
            await mutation.mutateAsync({ data: paymentData, recipientType });
        } catch (error) {
            console.error('Error sending money:', error);
        } finally {
            setIsLoading(false);
        }
    };
    const handleOTPSubmit = async (otp: string) => {
        setButton(false);
        setOTPButton(false);
        try {
            setIsOTPLoading(true);
            const withdrawData = {
                otp: otp,
            };
            const recipientType = 'staff';  
            await otpmutation.mutateAsync( { data: withdrawData, recipientType });
        } catch (error) {
            console.error('Error withdrawing:', error);
        } finally {
            setIsOTPLoading(false);
        }
    };
    const goBack = () => {
        navigate(-1);
    };

    if (isError) {
        return <div>Error loading balance, please refresh your page</div>;
    }

    if (isUsersLoading) {
        return (
            <div className="flex justify-center items-center py-32">
                {' '}
                <Lottie
                    animationData={loadingState}
                    loop={true}
                    className="lottie"
                />
            </div>
        );
    }

    if (isUsersError) {
        return (
            <div className="flex justify-center items-center">
                Error loading staff, please refresh your page
            </div>
        );
    }

    return (
        <div className="w-full flex flex-col md:px-8 py-4 px-2 ">
            <div className="flex gap-2 items-center ">
                <button onClick={goBack}>
                    <img src={back} alt="" />
                </button>
                <h3 className="font-bold sm:text-[28px] text-[20px]">
                    Send Money
                </h3>
            </div>
            <div className="w-full h-full mt-4">
            <div className="flex gap-4 mb-4">
                    <button
                        onClick={() => setView('staff')}
                        className={`px-2 py-2  ${view === 'staff' ? 'border-b-2  border-Tmred text-[#534341]' : 'text-[#857371]'}`}
                    >
                        To Staff
                    </button>
                    <button
                        onClick={() => setView('hospital')}
                        className={`px-2 py-2 ${view === 'hospital' ? 'border-b-2  border-Tmred text-[#534341]' : 'text-[#857371]'}`}
                    >
                        To Hospital
                    </button>
                </div>
                {view === 'staff' ? (
                <div className="flex items-center w-full flex-col gap-8 sm:flex-row max-w-[80%]">
                    <div className="py-4 px-2  flex-1 bg-[#fff] lg:min-h-[661px] rounded-2xl flex items-center flex-col">
                        <div className="mb-4 flex items-center gap-2 rounded w-full">
                            <img src={search} alt="search" />
                            <input
                                type="text"
                                placeholder="Search staff"
                                value={formData.staffSearch}
                                onChange={handleStaffSearchChange}
                                className="w-full font-normal text-[16px] bg-transparent text-[#534341] border-transparent outline-none focus:ring-0"
                            />
                        </div>
                        <div className="max-h-[574px]  overflow-y-auto w-full">
                            {isUsersLoading ? (
                                <div className="flex justify-center items-center py-32">
                                    {' '}
                                    <Lottie
                                        animationData={loadingState}
                                        loop={true}
                                        className="lottie"
                                    />
                                </div>
                            ) : (
                                <>
                                    {filteredStaff.length === 0 ? (<div className='flex flex-col items-center'>
                                    <img src={noStaff} alt="" />
                                        <p className="text-[16px] text-[#534341] flex">
                                            search not found
                                        </p>
                                    </div>
                                    ) : (
                                        filteredStaff.map((row, index) => (
                                            <div
                                                key={index}
                                                className={`flex p-2 items-center gap-8 cursor-pointer ${
                                                    selectedStaffId === row._id
                                                        ? 'bg-[#EDE0DE] rounded-tl-2xl rounded-bl-2xl'
                                                        : 'hover:bg-[#EDE0DE] hover:rounded-tl-2xl hover:rounded-bl-2xl'
                                                } justify-between mb-4 font-normal text-[12px] text-[#201A19] border-b-[2px]`}
                                                onClick={() => {
                                                    setSelectedStaffId(row._id);
                                                    setFormData(
                                                        (prevData: any) => ({
                                                            ...prevData,
                                                            selectedStaff:
                                                                prevData.selectedStaff ===
                                                                row._id
                                                                    ? null
                                                                    : row,
                                                            Recipient_Name: `${row.firstName} ${row.lastName}`,
                                                        })
                                                    );
                                                }}
                                                style={{ lineHeight: '24px' }}
                                            >
                                                <div className="flex flex-col">
                                                    <div className="flex items-center gap-x-[10px]">
                                                        <img
                                                            className="object-contain h-[40px] w-[40px] rounded-full"
                                                            style={{
                                                                boxShadow:
                                                                    'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
                                                            }}
                                                            src={row.image}
                                                            alt=""
                                                        />{' '}
                                                        <div>
                                                            <p className="truncate w-32 font-normal text-deep_black text-sm ">
                                                                {`${row.firstName} ${row.lastName}`}
                                                            </p>
                                                            <p className="text-light_gray text-[12px] font-medium">
                                                                {row.userType}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <form
                        action=""
                        onSubmit={handlesubmit}
                        className="flex flex-col gap-3 lg:min-h-[661px]  sm:gap-8 flex-1  p-8 rounded-2xl bg-[#FFFFFF]"
                    >
                        <div className="flex flex-col md:flex-row items-center justify-between">
                            <div>
                                <p className=" font-normal text-deep_black text-sm ">
                                    Available Balance
                                </p>
                                <p className="text-[#000] text-[20px] font-semibold">
                                    <span>
                                        {isLoading
                                            ? '0'
                                            : standardAmountFormat(
                                                  walletBalance,
                                                  currency
                                              )}
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="w-full">
                            <Input
                                name="Amount"
                                label="Enter Amount"
                                value={formData.Amount}
                                onChange={handleChange}
                                type="number"
                                className="w-full border border-[#857371] p-4 rounded-[8px] text-[16px] tracking-wider my-4 mt-10"
                            />
                        </div>
                        <div className="w-full ">
                            <p className=" font-normal text-deep_black text-sm pb-3">
                                Select staff to send money
                            </p>
                            <Input
                                name="Recipient_Name"
                                label="Staff Name"
                                value={formData.Recipient_Name}
                                onChange={handleChange}
                                disabled={true}
                                className="w-full border border-[#857371] p-4 rounded-[8px] text-[16px] tracking-wider my-4 mt-10"
                            />
                        </div>
                        <div className="w-full ">
                            <Input
                                name="purpose"
                                label="Purpose"
                                value={formData.purpose}
                                onChange={handleChange}
                                className="w-full border border-[#857371] p-4 rounded-[8px] text-[16px] tracking-wider my-4 mt-10"
                            />
                        </div>
                        {/* <div className="my-4 flex items-center gap-3">
                            <input
                                type="checkbox"
                                onChange={handleremember}
                                className="h-[16px] w-[16px] mx-2 cursor-pointer"
                                name="remember"
                                id="remember"
                            />
                            <label className="" htmlFor="remember">
                                Save this card for future use
                            </label>
                        </div> */}
                        <div className="py-8">
                            <button
                                type="submit"
                                className={`w-full h-[40px] flex justify-center rounded-lg items-center text-[#9d9b9b] font-semibold  hover:cursor-not-allowed transition-all duration-500  ${
                                    buttonEnabled
                                        ? 'bg-Tmred text-white hover:cursor-pointer '
                                        : 'bg-[#1D1B201F] '
                                } `}
                            >
                                {isloading ? 'Sending...' : 'Send Money'}
                            </button>
                        </div>
                    </form>
                    {otpModal && (
                        <OTPModal
                            isLoading={OTPLoading}
                            setButton={setOTPButton}
                            buttonEnabled={buttonOTPEnabled}
                            closeModal={onClose}
                            handleSubmit={handleOTPSubmit}
                        />
                    )}
                </div>
               ) : (
                <div>
                    <HospitalSend walletBalance={walletBalance} refetch={refetch} currency={currency}/>
                </div>
            )}
            </div>
        </div>
    );
};

export default Send;
