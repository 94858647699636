// import react from 'react';
import image from '../../../Images/EmptyAmbulance.svg';
const ReqEmptyState: React.FC = () => {
    return (
        <div className="flex flex-col justify-center items-center mt-28">
            <img src={image} alt="emptyImage" />
            <p className="text-center text-[#534341] font-normal text-[14px] pt-2">
            No ambulances are currently available or listed.
             Add new ambulances to start <br /> managing your fleet and 
             ensure your services are ready when needed. 
               
            </p>
        </div>
    );
};

export default ReqEmptyState;