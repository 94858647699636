// PaginationButtons.tsx

interface PaginationButtonsProps {
    page: number;
    setPage: (page: number) => void;
    totalPages: number;
}

const PaginationButton: React.FC<PaginationButtonsProps> = ({
    page,
    setPage,
    totalPages,
}) => {
    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    return (
        <div className="flex justify-between w-full items-center">
            <p className="text-sm font-normal text-customBrown100">
                {' '}
                Page {page} of {totalPages}
            </p>
            <div className="flex justify-end mt-4">
                <button
                    onClick={handlePrevPage}
                    disabled={page === 1}
                    className="px-6 py-[10px] mr-2 border border-customBrown200 text-xs rounded-md disabled:opacity-50 disabled:cursor-none"
                >
                    Previous
                </button>
                <button
                    onClick={handleNextPage}
                    disabled={page >= totalPages}
                    className="px-6 py-[10px] border border-customBrown200 text-xs rounded-md disabled:opacity-50 disabled:cursor-none"
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default PaginationButton;
