import React from 'react'
import DashboardLayout from '../Dashboard/DashboardLayout';
import MWallet from '../../../components/PharmacyComponent/Wallet/my-wallet/MWallet';
import {Helmet} from 'react-helmet'

const MyWallet = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | My Wallet
                </title>
            </Helmet>
            <div className="">
                <MWallet />
            </div>
        </DashboardLayout>
    )
}

export default MyWallet