import { MdChevronLeft } from 'react-icons/md';
import avatar from '../../../Images/avatard5.png';
import { useNavigate, useParams } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import Modal from '../../../reusable/LoadingModal';
import { toast } from 'react-toastify';
import { PatientFormat } from '../../Appointment/PatientAppointment/AppointmentCard';
import { useMutation, useQuery } from 'react-query';
import hospitalService from '../../../services/hospitalService';
import noStaff from '../../../Images/no-staf.svg';
import search from '../../../Images/Leading-icon.png';
import Lottie from 'lottie-react';
import loadingState from '../../../Images/loading.json';
import TimeComponent, {
    formatDateDmy,
    standardAmountFormat,
} from '../../../utils/helpers';
import { useHospitalAuthStore } from '../../../store/hospital/auth';
import { FaCircleCheck } from 'react-icons/fa6';

const RequestProfile = () => {
    const navigate = useNavigate();
    const [isCancelModalVisible, setCancelModalVisible] = useState(false);
    const [isNoteModalVisible, setNoteModalVisible] = useState(false);
    const [isCancelling, setIsCancelling] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [buttonEnabled, setButton] = useState(false);
    const [isAcceptModalVisible, setAcceptModalVisible] = useState(false);
    const [isAssignModalVisible, setAssignModalVisible] = useState(false);
    const [isConfirmModalVisible, setConfirmModalVisible] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false);
    const [isAccepting, setIsAccepting] = useState(false);
    const [users, setUsers] = useState<any[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedStaffId, setSelectedStaffId] = useState(null);
    const [requestDetails, setRequestDetails] = useState<any>({});

    const { id } = useParams();
    const currency = useHospitalAuthStore((state) => state.hospital.currency);
    const [cancelReason, setCancelReason] = useState<string>('');
    const [doctorNote, setDoctorNote] = useState<string>('');

    const handleAddDocNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDoctorNote(e.target.value);
    };
    const handleCancelReasonChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setCancelReason(e.target.value);
    };
    const handleCancel = () => {
        setCancelModalVisible(false);
    };
    const handleAddNote = () => {
        setNoteModalVisible(false);
    };
    const handleAcceptConfirm = async () => {
        setButton(true);
        setAssignModalVisible(true);
    };
    const handleCloseConfirmModal = () => {
        setConfirmModalVisible(false);
    };

    const { data, isError, isLoading, refetch } = useQuery(
        ['singleProfile', id],

        () =>
            id
                ? hospitalService.getSingleRequestProfile(id)
                : Promise.reject('NO ID'),
        {
            enabled: !!id,
        }
    );
    useEffect(() => {
        if (data?.ambulanceRequest) {
            setRequestDetails(data?.ambulanceRequest);
            // if (
            //     data.ambulanceRequest?.status === 'pending' &&
            //     data.ambulanceRequest?.confirmed
            // ) {
            //     setConfirmationStatus(true);
            // }

            console.log(requestDetails);
        }
    }, [data]);

    const handleAcceptCancel = () => {
        setAcceptModalVisible(false);
    };
    const handleAssignCancel = () => {
        setAssignModalVisible(false);
    };

    const [formData, setFormData] = useState({
        staffSearch: '',
    });
    const {
        data: usersData,
        isError: isUsersError,
        isLoading: isUsersLoading,
    } = useQuery('fleets', () => hospitalService.getAllAmbulance());

    useEffect(() => {
        if (usersData) {
            const filteredUsers = usersData.fleets.filter(
                (user) => user?.userType?.toLowerCase() !== 'superadmin'
            );
            setUsers(filteredUsers);
        }
    }, [usersData]);
    const filteredStaff = users.filter((staff) => {
        const searchQueryLower = formData.staffSearch.toLowerCase();
        return (
            staff.hospitalUser?.firstName
                .toLowerCase()
                .includes(searchQueryLower) ||
            staff.hospitalUser?.lastName
                .toLowerCase()
                .includes(searchQueryLower) ||
            staff.ambulanceType.toLowerCase().includes(searchQueryLower)
        );
    });

    const handleStaffSearchChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const searchQuery = e.target.value.toLowerCase();
        setSearchTerm(searchQuery);

        setFormData((prevData: any) => ({
            ...prevData,
            staffSearch: searchQuery,
        }));
    };
    const assignMutation = useMutation(
        ({ id, fleet }: { id: string; fleet: string }) =>
            hospitalService.acceptAmbulanceRequest(id, { fleet }),
        {
            onSuccess: (response) => {
                setIsAccepting(false);
                toast.success(
                    response.data?.message ||
                        'Ambulance request status updated successfully'
                );
                setAssignModalVisible(false);
                setAcceptModalVisible(false);
                setButton(true);
                refetch();
            },
            onError: (error: any) => {
                setIsAccepting(false);
                toast.error(
                    error.response?.data?.error?.message || 'Error assigning '
                );
            },
        }
    );

    const handleCancelConfirm = async () => {
        if (!cancelReason) {
            toast.error('Please provide a reason for cancelling the request');
            return;
        }
        setIsCancelling(true);
        setButton(false);
        const payload = { cancelReason: cancelReason };
        setIsCancelling(true);

        if (id) {
            hospitalService
                .cancelambulanceProfile(id, payload)
                .then((response) => {
                    refetch();
                    toast.success(
                        response.data?.message ||
                            'Ambulance canceled successfully'
                    );
                    setIsCancelling(false);
                    setButton(true);
                    setTimeout(() => {
                        setCancelModalVisible(false);
                    }, 1000);
                })
                .catch((error) => {
                    toast.error(error?.response?.data?.error?.message);
                    setButton(true);
                })
                .finally(() => {
                    setIsCancelling(false);
                });
        }
    };
    const handleAddNoteConfirm = async () => {
        if (!doctorNote) {
            toast.error("Please provide a doctor's Note");
            return;
        }

        const payload = { doctorsNote: doctorNote };

        setIsAdding(true);
        if (id) {
            hospitalService
                .addDoctorNote(id, payload)
                .then((response) => {
                    refetch();
                    toast.success(
                        response.data?.message ||
                            'Doctors Note added successfully'
                    );
                    setIsAdding(false);
                    setButton(true);
                    setTimeout(() => {
                        setNoteModalVisible(false);
                        toast.dismiss();
                    }, 1000);
                })
                .catch((error) => {
                    toast.error(error?.response?.data?.error?.message);
                    setButton(true);
                    setIsAdding(true);
                })
                .finally(() => {
                    setIsAdding(false);
                });
        }
    };

    const handleAssignConfirm = async () => {
        if (!selectedStaffId || !id) return; // Ensure a staff member is selected and id is present

        setIsAccepting(true);
        setButton(false);

        assignMutation.mutate({
            id: id,
            fleet: selectedStaffId, // ID of the selected staff member
        });
    };
    const handleMarkComplete = () => {
        setIsConfirming(true);
        setButton(false);

        if (id) {
            hospitalService
                .markRequestAsCompleted(id)
                .then((response) => {
                    refetch();
                    toast.success(
                        response.data?.message ||
                            'Ambulance request status updated successfully'
                    );
                    setIsConfirming(false);
                    setButton(true);
                    setTimeout(() => {
                        setConfirmModalVisible(false);
                        toast.dismiss();
                    }, 1000);
                })
                .catch((error) => {
                    toast.error(error?.response?.data?.error?.message);
                    setButton(true);
                })
                .finally(() => {
                    setIsConfirming(false);
                });
        }
    };
    return (
        <Fragment>
            <div className="md:px-8 px-1 mt-6 bg-[#F6F6F7]">
                <div className="w-[95%] mx-auto ">
                    <div className="flex items-center">
                        <span className="cursor-pointer ">
                            <MdChevronLeft
                                size={30}
                                onClick={() => navigate(-1)}
                            />
                        </span>
                        <h1 className="font-semibold text-2xl pl-4">
                            Request Profile
                        </h1>
                    </div>
                    {isLoading ? (
                        <>
                            <div className="flex justify-center items-center py-32">
                                {' '}
                                <Lottie
                                    animationData={loadingState}
                                    loop={true}
                                    className="lottie"
                                />
                            </div>
                        </>
                    ) : isError ? (
                        <>
                            <div className="flex justify-center items-center">
                                Error loading details, please refresh your page
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="flex flex-col lg:flex-row lg:space-x-6 my-8 ">
                                <div className="max-w-[720px] w-full">
                                    <div className=" bg-white  p-2 sm:p-4 rounded-2xl mb-10 lg:mb-0">
                                        <div
                                            className="flex items-center justify-between bg-[#FFF8F7] p-1 sm:p-4 
                    sm:space-x-3 rounded-xl mb-6"
                                        >
                                            <div className="flex items-center">
                                                <img
                                                    src={
                                                        data?.ambulanceRequest
                                                            ?.patient?.image ||
                                                        avatar
                                                    }
                                                    alt="adminImage"
                                                    className="rounded-full w-20 h-20"
                                                />
                                                <div className="pl-4">
                                                    <h2 className="sm:text-2xl text-sm py-1 font-medium text-[#201A19]">
                                                        {`${
                                                            data
                                                                ?.ambulanceRequest
                                                                ?.patient
                                                                ?.title &&
                                                            data
                                                                ?.ambulanceRequest
                                                                ?.patient
                                                                ?.title !==
                                                                'undefined'
                                                                ? data
                                                                      ?.ambulanceRequest
                                                                      ?.patient
                                                                      ?.title
                                                                : ''
                                                        } ${data
                                                            ?.ambulanceRequest
                                                            ?.patient
                                                            ?.firstName} ${data
                                                            ?.ambulanceRequest
                                                            ?.patient
                                                            ?.lastName}`}
                                                    </h2>
                                                    <p className="sm:text-sm text-xs font-normal py-1 text-[#534341] ">
                                                        {data?.ambulanceRequest
                                                            ?.patient?.email ||
                                                            ''}
                                                    </p>
                                                    <p className="sm:text-sm text-xs capitalize py-1 font-normal text-[#201A19]">
                                                        {data?.ambulanceRequest
                                                            ?.patient
                                                            ?.tmrNumber || ''}
                                                    </p>
                                                </div>
                                            </div>
                                            <div>
                                                <button
                                                    onClick={() =>
                                                        navigate(
                                                            `/hospital/patient/${data?.ambulanceRequest?.patient?.tmrNumber}`
                                                        )
                                                    }
                                                    className="underline text-Tmred font-medium"
                                                >
                                                    View Profile
                                                </button>
                                            </div>
                                        </div>

                                        {/* <h2 className="sm:text-2xl text-sm py-3 sm:mb-2 font-medium text-[#201A19] px-[20px]">Ambulance information</h2> */}
                                        <div className="flex justify-between items-start md:flex-col lg:flex-row flex-col sm:flex-row px-[20px]">
                                            <div className="w-full">
                                                <div className="">
                                                    <p className="text-xs sm:text-sm font-medium text-[#857371] pb-2">
                                                        Date registered
                                                    </p>
                                                    <p className="text-[16px]  font-medium text-[#201A19]">
                                                        {formatDateDmy(
                                                            data
                                                                ?.ambulanceRequest
                                                                ?.createdAt ||
                                                                ''
                                                        )}
                                                    </p>
                                                </div>

                                                <div className="my-5">
                                                    <p className="text-xs sm:text-sm font-normal text-[#857371] pb-2 ">
                                                        Time
                                                    </p>
                                                    <p className="text-base sm:text-[16px] font-medium text-[#201A19] ">
                                                        <TimeComponent
                                                            time={
                                                                data
                                                                    ?.ambulanceRequest
                                                                    ?.createdAt
                                                            }
                                                        />
                                                    </p>
                                                </div>
                                                <div className="my-5">
                                                    <p className="text-xs sm:text-sm font-normal text-[#857371] pb-2 ">
                                                        Address
                                                    </p>
                                                    <p className="text-base sm:text-[16px] font-medium text-[#201A19] ">
                                                        {
                                                            data
                                                                ?.ambulanceRequest
                                                                ?.location
                                                        }
                                                    </p>
                                                </div>
                                                <div className="my-5">
                                                    <p className="text-xs sm:text-sm font-normal text-[#857371] pb-2 ">
                                                        Additional Information
                                                    </p>
                                                    <p className="text-base sm:text-[16px] font-medium text-[#201A19] ">
                                                        {data?.ambulanceRequest
                                                            ?.additionalInformation ===
                                                        ''
                                                            ? 'None available'
                                                            : data
                                                                  ?.ambulanceRequest
                                                                  ?.additionalInformation}
                                                    </p>
                                                </div>
                                                <div className="flex gap-2 flex-col">
                                                    <p className=" text-[14px] font-medium text-[#857371]">
                                                        Status:
                                                    </p>
                                                    <button
                                                        type="button"
                                                        className="w-[150px] text-gray-900 gap-3 bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-full text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 me-2 mb-2"
                                                    >
                                                        {requestDetails.status ===
                                                        'completed' ? (
                                                            <FaCircleCheck
                                                                style={{
                                                                    color: '#009000',
                                                                }}
                                                            />
                                                        ) : requestDetails.status ===
                                                          'accepted' ? (
                                                            <svg
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
                                                                    fill="#366CC9"
                                                                />
                                                                <path
                                                                    d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z"
                                                                    fill="#366CC9"
                                                                />
                                                            </svg>
                                                        ) : (
                                                            <svg
                                                                className="w-4 h-4 me-2 -ms-1 text-[#626890]"
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
                                                                    fill="#534341"
                                                                />
                                                                <path
                                                                    d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z"
                                                                    fill="#534341"
                                                                />
                                                            </svg>
                                                        )}
                                                        {requestDetails.status}
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="w-full sm:ml-[70px]">
                                                {(requestDetails.status ===
                                                    'accepted' ||
                                                    requestDetails.status ===
                                                        'completed') && (
                                                    <>
                                                        <p className="text-xs sm:text-sm font-normal text-[#857371] pb-2 ">
                                                            Staff Assigned
                                                        </p>
                                                        <div className="flex items-center">
                                                            <img
                                                                src={
                                                                    data
                                                                        ?.ambulanceRequest
                                                                        ?.fleet?.hospitalUser
                                                                        ?.image ||
                                                                    avatar
                                                                }
                                                                alt="assignedstaffImage"
                                                                className="rounded-full w-10 h-10"
                                                            />
                                                            <div className="pl-2">
                                                                <p className="sm:text-[16px] w-[200px] truncate text-sm py-1 font-medium text-[#201A19]">
                                                                    {`${data?.ambulanceRequest?.fleet?.hospitalUser?.firstName} ${data?.ambulanceRequest?.fleet?.hospitalUser?.lastName}`}
                                                                </p>
                                                                <p className="sm:text-sm text-xs font-normal text-[#534341] ">
                                                                    {data
                                                                        ?.ambulanceRequest
                                                                        ?.fleet?.hospitalUser
                                                                        ?.email ||
                                                                        ''}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                <div className="my-5">
                                                    <p className="text-xs sm:text-sm font-normal text-[#857371] pb-2">
                                                        Type of ambulance
                                                    </p>
                                                    <p className="text-xs sm:text-[16px]  font-medium text-[#201A19]">
                                                        {
                                                            data
                                                                ?.ambulanceRequest
                                                                ?.ambulanceType
                                                        }
                                                    </p>
                                                </div>
                                                <div className="my-5">
                                                    <p className="text-xs sm:text-sm font-normal text-[#857371] pb-2 ">
                                                        Amount
                                                    </p>
                                                    <p className="text-base sm:text-[16px] font-medium text-[#201A19] ">
                                                        {standardAmountFormat(
                                                            data
                                                                ?.ambulanceRequest
                                                                ?.amount,
                                                            currency
                                                        ) || 0}
                                                    </p>
                                                </div>
                                                <div className="mb-5">
                                                    <p className="text-xs sm:text-sm font-normal text-[#857371] pb-2">
                                                        Doctor's Note
                                                    </p>
                                                    <p className="text-xs sm:text-[16px]  font-medium text-[#201A19]">
                                                        {data?.ambulanceRequest
                                                            ?.doctorsNote ||
                                                            'None Available'}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex items-center w-full pb-3 gap-6 sm:flex-row flex-col">
                                            {data?.ambulanceRequest?.status ===
                                                'cancelled' && (
                                                <div className="bg-[#FFF8F7] p-4 w-full rounded-xl">
                                                    <PatientFormat
                                                        text1="Reason for cancelling:"
                                                        text2={
                                                            data
                                                                ?.ambulanceRequest
                                                                ?.cancelReason ||
                                                            'Request was cancelled'
                                                        }
                                                    />
                                                </div>
                                            )}
                                            {data?.ambulanceRequest?.status ===
                                                'accepted' && (
                                                <button
                                                    onClick={() =>
                                                        setConfirmModalVisible(
                                                            true
                                                        )
                                                    }
                                                    className="w-full border border-[#C00010] rounded-lg m-2 p-3"
                                                >
                                                    <p className="text-xs sm:text-[16px]  font-medium text-[#201A19] flex gap-2 items-center justify-center">
                                                        <FaCircleCheck
                                                            size={24}
                                                            style={{
                                                                color: '#009000',
                                                            }}
                                                        />
                                                        <span>
                                                            Mark Request as
                                                            Completed
                                                        </span>
                                                    </p>
                                                </button>
                                            )}
                                        </div>
                                        <div
                                            className={`flex justify-end space-x-6 py-3 border-t mt-10 ${
                                                requestDetails.status ===
                                                    'cancelled' ||
                                                requestDetails.status ===
                                                    'completed'
                                                    ? 'bg-[#1D1B201F] opacity-[28%] pointer-events-none'
                                                    : ''
                                            }`}
                                        >
                                            <div
                                                className={`${
                                                    requestDetails.status !==
                                                        'accepted' &&
                                                    ' opacity-[28%] pointer-events-none'
                                                }`}
                                            >
                                                <button
                                                    type="button"
                                                    onClick={() =>
                                                        setNoteModalVisible(
                                                            true
                                                        )
                                                    }
                                                    className="py-2 px-3 text-[#C00010] text-[16px] font-normal"
                                                >
                                                    Add Note
                                                </button>
                                            </div>
                                            <div
                                                className={`${
                                                    requestDetails.status ===
                                                        'accepted' &&
                                                    ' opacity-[28%] pointer-events-none'
                                                }`}
                                            >
                                                <button
                                                    type="button"
                                                    onClick={() =>
                                                        setCancelModalVisible(
                                                            true
                                                        )
                                                    }
                                                    className="py-2 px-3 text-[#C00010] text-[16px] font-normal"
                                                >
                                                    Cancel Request
                                                </button>
                                            </div>
                                            <div
                                                className={`${
                                                    requestDetails.status ===
                                                        'accepted' &&
                                                    ' opacity-[28%] pointer-events-none'
                                                }`}
                                            >
                                                <button
                                                    type="button"
                                                    onClick={() =>
                                                        setAcceptModalVisible(
                                                            true
                                                        )
                                                    }
                                                    className="border py-2 px-3 text-[16px] text-[#C00010] border-[#857371] font-normal rounded-lg"
                                                >
                                                    Accept Request
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <Modal
                isVisible={isCancelModalVisible}
                onClose={handleCancel}
                buttonEnabled={!isCancelling}
                isLoading={isCancelling}
                loadingText="Cancelling..."
                setButton={() => {}}
                onSave={handleCancelConfirm}
                saveText="Submit"
                closeText={'Close'}
                showCloseButtonBorder={true}
            >
                <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2 sm:w-[500px]">
                    <h2 className="font-semibold text-2xl mb-5">
                        Cancel Request
                    </h2>
                    <p className=" text-[14px] font-normal text-[#534341]">
                        Are you sure you want to cancel this request?
                    </p>
                    <textarea
                        name="reason"
                        placeholder="Reason"
                        value={cancelReason}
                        onChange={handleCancelReasonChange}
                        className="w-full border h-[150px] border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider my-4"
                    ></textarea>
                </div>
            </Modal>
            <Modal
                isVisible={isAcceptModalVisible}
                onClose={handleAcceptCancel}
                buttonEnabled={true}
                // isLoading={isAccepting}
                // loadingText="Accepting..."
                setButton={setButton}
                onSave={handleAcceptConfirm}
                saveText="Accept and Assign Request"
                closeText={'Cancel'}
                showCloseButtonBorder={false}
            >
                <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2  lg:w-[482px]">
                    <h2 className="font-semibold text-2xl mb-3">
                        Accept Request?
                    </h2>
                    <p className=" text-[14px] font-normal text-[#534341]">
                        Are you sure you want to accept this ambulance request?
                        By accepting, you agree to assign an available ambulance
                        to this request and notify the patient. Please ensure
                        all necessary resources are ready and available before
                        proceeding.
                    </p>
                </div>
            </Modal>
            <Modal
                isVisible={isAssignModalVisible}
                onClose={handleAssignCancel}
                buttonEnabled={!!selectedStaffId}
                isLoading={isAccepting}
                loadingText="Assigning..."
                setButton={setButton}
                onSave={handleAssignConfirm}
                saveText="Assign Ambulance"
                closeText={'Cancel'}
                showCloseButtonBorder={true}
            >
                <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2  lg:w-[482px]">
                    <h2 className="font-semibold text-2xl mb-3">
                        Assign Available Ambulance
                    </h2>
                    <div className="py-4 px-2  flex-1 bg-[#fff]  rounded-2xl flex items-center flex-col">
                        <div className="mb-4 flex items-center gap-2 rounded w-full">
                            <img src={search} alt="search" />
                            <input
                                type="text"
                                placeholder="Search ambulance/staff"
                                value={formData.staffSearch}
                                onChange={handleStaffSearchChange}
                                className="w-full font-normal text-[16px] bg-transparent text-[#534341] border-transparent outline-none focus:ring-0"
                            />
                        </div>
                        <div className="max-h-[574px]  overflow-y-auto w-full">
                            {isUsersLoading ? (
                                <div className="flex justify-center items-center py-32">
                                    {' '}
                                    <Lottie
                                        animationData={loadingState}
                                        loop={true}
                                        className="lottie"
                                    />
                                </div>
                            ) : isUsersError ? (
                                <>
                                    <p className="text-center">
                                        Error Fetching Users
                                    </p>
                                </>
                            ) : (
                                <>
                                    {filteredStaff?.length === 0 ? (
                                        <div className="flex flex-col items-center">
                                            <img src={noStaff} alt="" />
                                            <p className="text-[16px] text-[#534341] flex">
                                                search not found
                                            </p>
                                        </div>
                                    ) : (
                                        filteredStaff?.map((row, index) => (
                                            <div
                                                key={index}
                                                className={`flex p-2 items-center gap-8 cursor-pointer ${
                                                    selectedStaffId === row._id
                                                        ? 'bg-[#EDE0DE] rounded-tl-2xl rounded-bl-2xl'
                                                        : 'hover:bg-[#EDE0DE] hover:rounded-tl-2xl hover:rounded-bl-2xl'
                                                } justify-between mb-4 font-normal text-[12px] text-[#201A19] border-b-[2px]`}
                                                onClick={() => {
                                                    setSelectedStaffId(row._id);
                                                    setFormData(
                                                        (prevData: any) => ({
                                                            ...prevData,
                                                            selectedStaff:
                                                                prevData.selectedStaff ===
                                                                row._id
                                                                    ? null
                                                                    : row,
                                                            Recipient_Name: `${row.hospitalUser?.firstName} ${row.hospitalUser?.lastName}`,
                                                            phoneNumber:
                                                                row.phoneNumber,
                                                        })
                                                    );
                                                }}
                                                style={{ lineHeight: '24px' }}
                                            >
                                                <div className="flex flex-col">
                                                    <div className="flex items-center gap-x-[10px]">
                                                        <img
                                                            className="object-contain h-[40px] w-[40px] rounded-full"
                                                            style={{
                                                                boxShadow:
                                                                    'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
                                                            }}
                                                            src={
                                                                row.hospitalUser
                                                                    ?.image
                                                            }
                                                            alt="ambulanceImage"
                                                        />{' '}
                                                        <div>
                                                            <p className="truncate w-32 font-normal text-deep_black text-sm ">
                                                                {`${
                                                                    row
                                                                        .hospitalUser
                                                                        ?.firstName ||
                                                                    ''
                                                                } ${
                                                                    row
                                                                        .hospitalUser
                                                                        ?.lastName ||
                                                                    ''
                                                                }`}
                                                            </p>
                                                            <p className="text-light_gray text-[12px] font-medium">
                                                                {
                                                                    row.ambulanceType
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                isVisible={isNoteModalVisible}
                onClose={handleAddNote}
                buttonEnabled={!isAdding}
                isLoading={isAdding}
                loadingText="Adding..."
                setButton={() => {}}
                onSave={handleAddNoteConfirm}
                saveText="Save"
                closeText={'Close'}
                showCloseButtonBorder={true}
            >
                <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2 sm:w-[500px]">
                    <h2 className="font-semibold text-2xl mb-5">
                        Doctor's Note
                    </h2>
                    <p className=" text-[14px] font-normal text-[#534341]">
                        Record key patient details during the ambulance ride to
                        ensure accurate and timely care.
                    </p>
                    <textarea
                        name="note"
                        placeholder="Noted"
                        value={doctorNote}
                        onChange={handleAddDocNote}
                        className="w-full border h-[150px] border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider my-4"
                    ></textarea>
                </div>
            </Modal>
            <Modal
                isVisible={isConfirmModalVisible}
                onClose={handleCloseConfirmModal}
                buttonEnabled={true}
                isLoading={isConfirming}
                loadingText="Confirming..."
                setButton={setButton}
                onSave={handleMarkComplete}
                saveText="Confirm Completion"
                closeText={'Cancel'}
                showCloseButtonBorder={false}
            >
                <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2  lg:w-[482px]">
                    <h2 className="font-semibold text-2xl mb-3">
                        Request Completed?
                    </h2>
                    <p className=" text-[14px] font-normal text-[#534341]">
                        Please confirm that the ambulance service for this
                        request has been successfully completed. Once marked as
                        complete, the request will be closed, and the patient
                        will be notified. Ensure that all provided services were
                        delivered as expected.
                    </p>
                </div>
            </Modal>
        </Fragment>
    );
};

export default RequestProfile;
