import { useEffect, useState } from 'react';

import Layout from '../Layout';
import { useNavigate } from 'react-router-dom';
import HospitalAuthHeader from '../../../reusable/HospitalAuthHeader';
import { useHospitalAuthStore, useScreenTimeOut } from '../../../store/hospital/auth';
import { useHospitalAccountStore } from '../../../store/hospital/auth';
import { useMutation, useQuery } from 'react-query';
import hospitalService from '../../../services/hospitalService';
import loadingState from '../../../Images/loading.json';
import Lottie from 'lottie-react';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { hospitalRoutes } from '../../../utils/routeNames';
import { standardAmountFormat } from '../../../utils/helpers';
import ButtomLogo from '../../../reusable/ButtomLogo';

type SubplanProps = {
    planId: string;
    token: string;
};

const Subplan = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const hospitalAccountStore = useHospitalAccountStore((state) => state)
    const hospitalAuthStore = useHospitalAuthStore((state) => state);
    const [planChosen, setPlanChosen] = useState<boolean>(false);
    const [plan, setPlan] = useState('');
    const [planObj, setPlanObj] = useState<any>();
    const [chosenPlanId, setChosenPlanId] = useState<string | null>(null);
    const country = useHospitalAuthStore((state) => state.hospital.country);
    const updateSubscription = useHospitalAuthStore((state) => state.updateSubscription);
    const mutation = useMutation((data: SubplanProps) =>
        hospitalService.subscribe(data)
    );
    const currency = useHospitalAuthStore((state) => state.hospital.currency);

    const getActivePlanMutation = useMutation(
        ({ planId, token }: { planId: string; token: string }) =>
            hospitalService.getActiveSubscription(planId, token)
            
    );

    // TODO: check if logged in user have subscription permission before they can create a subscription

    useEffect(() => {
        if (mutation.isSuccess) {
            setLoading(false);
            const responseData = mutation.data;
            const paymentLink = responseData?.data?.payload?.data?.paymentLink;
            if (paymentLink) {
                window.open(paymentLink, '_blank');
            }
        }
    }, [mutation.isSuccess, mutation.data]);

    useEffect(() => {
        if (mutation.isError) {
            setLoading(false);
            let err: any = mutation.error;
            toast.error(err?.response?.data?.error?.message || 'We are unable to generate payment link at the moment, please try again');
        }
    }, [mutation.isError, mutation.error]);

    const handleSubscribe = (plan) => {
        if (loading) {
            return; // Prevent multiple clicks while loading
        }
        setLoading(true);
        setPlanObj(plan);
        
        const planId = plan._id 
        const subscribeData = {
            planId,
            token: hospitalAuthStore.token || hospitalAccountStore.token,
        };
        setPlan(planId);
        // setPlanId(planId)
        setChosenPlanId(planId);
        mutation.mutate(subscribeData);
        setPlanChosen(true);
    };

    const handleActiveSubscribe = () => {
        
        if (!plan) {
            console.error('Plan ID is not set');
            return;
        }
        const token = hospitalAuthStore.token || hospitalAccountStore.token;
        getActivePlanMutation
            .mutateAsync({ planId: plan, token })
            .then((responseData) => {
                if (!responseData || Object.keys(responseData.subscription).length === 0) {
                    toast.error('Your payment is yet to be confirmed, please try again in few seconds.');
                } else {
                    hospitalAuthStore.updateSubscription(planObj); 
                    if (!hospitalAuthStore.user.hasSetupPasscode) {
                        navigate(hospitalRoutes.setupLockcode);
                    } else {
                        navigate(hospitalRoutes.landingLogin);
                    }
                    setPlanObj(responseData.subscription)
                    updateSubscription(planObj);
                }
            })
            .catch((error) => {
                console.error('Error checking active status:', error);
            });
    };

    
    const { data, isLoading, isError } = useQuery(
        ['plans',country],
        () =>
            country ? hospitalService.plans(country) : Promise.reject('No country'),
        {
            enabled: !!country,
        }
    );
    const formatNumber = (number) => {
        // Check if the number is valid and convert to string
        if (!isNaN(number)) {
          return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return number; // Return original value if not a number
      };
    //logout function
    const clearStore = useHospitalAuthStore((state) => state.clearToken);
    const timeOutStore = useScreenTimeOut();

    const handleLogout = () => {
        clearStore();
        sessionStorage.removeItem('hospital-auth-store'); // clear auth store
        timeOutStore.setIsTimeOut(false); // reset timeout store
        navigate(hospitalRoutes.healthID, { replace: true });
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Lottie
                    animationData={loadingState}
                    loop={true}
                    className="lottie"
                />
            </div>
        );
    }

    if (isError) {
        return <div>Error loading plan, please refresh your page</div>;
    }
    const plans = data.plans;
    console.log({plans})
    plans?.forEach((plan) => {
        const subFeaturesArr: string[] = [];
        for (const [key, value] of Object.entries(plan.features)) {
            if (value === "true") {
                subFeaturesArr.push(key);
            }
        }
    });

    // Sorting function
    const sortByNameInOrder = (a: any, b: any) => {
    const order = { Standard: 0, "Pro Plus": 1, Premium: 2 };
    return order[a.name] - order[b.name];
    };

    plans.sort(sortByNameInOrder);

    return (
        <Layout>
            <ToastContainer />
            <Helmet>
                <title>TMR | Subscription</title>
            </Helmet>
            <div className="flex items-center justify-center flex-col bg-transparent w-full">
                <div className="flex flex-col items-center justify-center gap-4 w-full mx-auto">
                    <HospitalAuthHeader
                        logo={hospitalAuthStore.hospital.logo || hospitalAccountStore.hospital.logo}
                        text={hospitalAuthStore.hospital.tagline || hospitalAccountStore.hospital.tagline}
                        title={hospitalAuthStore.hospital.name || hospitalAccountStore.hospital.name}
                    />
                  
                    <div className="flex gap-10 w-full mx-auto  flex-wrap lg:flex-nowrap px-5 items-center justify-center overflow-x-auto">
                        {plans?.map((plan, index) => {
                            const isPlanLoading =
                                chosenPlanId === plan._id && loading;
                            return (
                                <div
                                    key={plan._id}
                                    className="flex flex-col  rounded-3xl  border bg-[#FFf] py-[40px] px-[20px]  mt-7 w-[350px] lg:w-[546px]  "
                                >
                                   <h3
    className={`font-semibold text-center text-2xl ${
        index === 0 ? 'text-blueX' : index === 1 ? 'text-Tmred' : index === 2 ? 'text-[#702BB5]' : ''
    }`}
>
    {plan.name}
</h3>

                                    <p className="text-center text-dark100">
                                        {plan.type}
                                    </p>

                                    <div className="flex justify-center items-center ">
                                        <div
                                            className={`border-t-8 ${
                                                index === 0 ? 'border-blueX': index === 1
                                                    ? 'border-Tmred'
                                                    : index === 2 ? 'border-[#702bb5]' : ''
                                            } rounded-lg my-3 w-12`}
                                        />
                                    </div>

                                    <h2 className="flex justify-center mt-6 text-[36px] font-semibold ">
                                       
                             {standardAmountFormat(
    plan?.amount || 0,
    currency
)}
                                        
                                    </h2>
                                    <span className="flex justify-center text-black100 text-center">
                                        {plan.validity} days
                                    </span>

                                    <div className="text-dark100 my-8 bg-lightGray500 p-5 rounded-lg w-full">
    {
        Object.keys(plan.features).map((key, index) => (
            <div
                key={index}
                className={`flex items-center gap-5 mb-5 p-3 flex-wrap justify-between border-b border-[#D8C2BF] ${
                    index === Object.keys(plan.features).length - 1 ? 'border-b-0' : ''
                }`}
            >
                {/* <img src={tickCircle} alt="" className="" /> */}
                <div className="flex-1 ">
                    <p className="text-[14px] font-medium text-[#534341] break-words break-all">
                        {plan.features[key].label}
                    </p>
                </div>
                <div className="flex-shrink-0">
            
 <p  className="text-[14px] font-medium text-[#534341]">
          {key === 'privateMode' 
            ? (plan.features[key].value ? 'yes' : 'no')
            : typeof plan.features[key].value === 'number' 
              ? formatNumber(plan.features[key].value) // Format number with commas
              : plan.features[key].value // Render other values as is
          }
        </p>
                </div>
            </div>
        ))
    }
</div>

                                    <button
                                        onClick={() =>
                                            handleSubscribe(plan)
                                        }
                                        className={`w-full h-[40px] text-sm flex justify-center rounded-lg items-center font-semibold  transition-all duration-500 border border-Tmred text-Tmred hover:bg-Tmred hover:text-white hover:cursor-pointer`}
                                    >
                                        {isPlanLoading
                                            ? 'Loading...'
                                            : ' Choose Plan'}
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                  
                    
                </div>
                <div className=" mt-6 flex justify-center items-center">
                    <button
                        type="button"
                        onClick={handleActiveSubscribe}
                        className={`h-[40px] w-[285px] text-sm font-semibold flex justify-center rounded-lg items-center transition-all duration-500 text-white ${
                            planChosen
                                ? ' bg-[#FEF1EF] cursor-pointer !text-Tmred'
                                : 'bg-[#1D1B201F] !text-[#201A19] cursor-not-allowed'
                        } shadow-md`}
                    >
                        {getActivePlanMutation.isLoading
                            ? 'Checking...'
                            : ' I’ve made payment'}
                    </button>
                </div>
                <div className=" mt-6 flex justify-center items-center ">
                    <button
                        type="button"
                        onClick={handleLogout}
                        className={`text-sm font-semibold flex justify-center items-center 
                        text-Tmred
                        `}
                    >
                        Logout
                    </button>
                </div>
                <div className="ml-auto mt-10 w-full flex justify-end">
                <ButtomLogo/>
                </div>
            </div>
        </Layout>
    );
};

export default Subplan;
