import { useEffect, useState } from 'react';
import Table from '../../../constants/Table';
import { Link, useNavigate } from 'react-router-dom';
import OrderEmptyState from './OrderEmptyState';
import { formatDateDmy, standardAmountFormat } from '../../../utils/helpers';
import image from '../../../Images/userMgmt.png';
import { pharmacyRoutes } from '../../../utils/pharmacyRouteNames';
import PaginationButton from '../../../constants/Pagination/PaginationButton';

interface rowType {
    _id: string;
}
interface OrderDataProps {
    Order: any;
    searchQuery: string;
    pagination?: boolean;
    limitedRow?: boolean;
    page?:any
    setPage?:any
    ordersPagination?:any
}
const OrderData = ({ Order,ordersPagination, searchQuery, pagination, limitedRow,page,setPage }: OrderDataProps) => {
    const navigate = useNavigate();

    const filteredOrders = Order?.filter(
        (row: any) =>
            row.customer.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row.invoiceNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row.status.toLowerCase().includes(searchQuery.toLowerCase())
    );
    
    const displayLimit = limitedRow ? 5 : 10;
    const paginatedRows = filteredOrders?.slice(0, displayLimit);

    const OrderTableHeaders = [
        
        {
            title: 'S/N',
            render: (row: any) => {
              const index = Order?.findIndex((user: any) => user._id === row._id) + 1;
          
              return limitedRow ? (
                <p>{index}</p>
              ) : (
                <p className="text-black">
                  {(page - 1) * 10 + index}
                </p>
              );
            },
          },
          
        {
            title: 'Invoice ID',
            render: (row: any) => (
                <div className="flex items-center gap-x-[10px]">
                    <div>
                        <p className="truncate w-32 text-lightBrown text-sm ">
                            {row.invoiceNumber}
                        </p>
                    </div>
                </div>
            ),
        },
        {
            title: 'Name',
            render: (row: any) => (
                <div className="flex items-center gap-x-[10px]">
                    <p className="truncate w-32 text-lightBrown text-sm ">
                        {row.customer.fullName}
                    </p>
                </div>
            ),
        },
        {
            title: 'Amount',
            render: (row: any) => <p>{`${standardAmountFormat(row.totalPrice, row.currency)}`}</p>,
        },
        {
            title: 'Status',
            render: (row: any) => (
                <div
                    className={`flex items-start justify-center p-2 rounded 
                        ${
                            row.status === 'pending'
                                ? 'bg-[#1D1B2014] text-lightBrown'
                                : ''
                        } 
                        ${
                            row.status === 'completed'
                                ? 'bg-[#E2EEE7] text-green-500'
                                : ''
                        }
                        ${
                            row.status === 'cancelled'
                                ? 'bg-[#FFDAD5] text-red-500'
                                : ''
                        }
                    `}
                >
                    <div
                        className={`w-[6px] h-[6px] rounded-full mr-2 mt-[6px] 
                            ${row.status === 'pending' ? 'bg-[#615f60]' : ''}
                            ${row.status === 'completed' ? 'bg-[#3A975F]' : ''}
                            ${row.status === 'cancelled' ? 'bg-[#C00010]' : ''}
                        }`}
                    />
                    <p className="text-xs">{row.status}</p>
                </div>
            ),
        },
        {
            title: 'Date ordered',
            render: (row: any) => <p>{formatDateDmy(row.createdAt)}</p>,
        },
    ];

    return (
        <div className="w-full pt-5">
            {!Order?.length ? (
                <OrderEmptyState />
            ) : filteredOrders?.length === 0 ? (
                <div className="flex flex-col items-center justify-center text-center my-[70px] ">
                    <img src={image} alt="" />
                    <p className="mt-6 text-base font-medium font-[Montserrat]">
                        The search result is currently empty.
                    </p>
                </div>
            ) : (
                <div className="gap-[2rem] block md:grid">
                    <div className="w-full grid gap-x-[30px] self-start md:mb-[0] mb-[2rem] overflow-x-auto scrollbar-hide">
                        <Table
                            rows={paginatedRows}
                            headers={OrderTableHeaders}
                            showHead={true}
                            allowRowClick
                            onRowClick={(row: rowType) =>
                                navigate(`${pharmacyRoutes.orderDetails}/${row._id}`)
                            }
                        />
                        {pagination ? (
                            // <Pagination />
                            <PaginationButton
                        page={page}
                        setPage={setPage}
                        totalPages={ordersPagination?.totalPages || 1}
                        />
                        ) : (
                            <div className="flex items-center justify-center w-full my-3 pt-6">
                                <Link
                                    to={pharmacyRoutes.orders}
                                    className="w-full sm:w-fit h-[40px] text-[14px] flex items-center text-center justify-center text-[#C00010] border border-[#C00010] py-2 px-6 rounded-lg font-medium"
                                >
                                    View all orders
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default OrderData;
