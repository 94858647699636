import UserManagement from '../../../../components/UserDoctor/UserManagement';
import DashboardLayout from '../../Dashboard/DashboardLayout';
import {Helmet} from 'react-helmet'


const Doctor = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Doctor
                </title>
            </Helmet>
            <UserManagement />
        </DashboardLayout>
    );
};
export default Doctor;
