import React, { useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import { hospitalRoutes } from '../../../utils/routeNames';
import { useNavigate } from 'react-router-dom';

type propType = {
    closeModal: any;
    formData: any;
    setFormData: any;
};
const EmergencyPasscode: React.FC<propType> = ({
    closeModal,
    formData,
    setFormData,
}) => {
    const [buttonEnabled, setButton] = useState(false);
    const navigate = useNavigate();
    const [passcode, setPasscode] = useState('');
    useEffect(() => {
        if (passcode.length === 6) {
            setButton(true);
            setFormData(passcode);
        } else {
            setButton(false);
        }
    }, [passcode, setFormData]);
    const handleSubmit = (e: any) => {
        e.preventDefault();
        setPasscode('');
        const submittedData = { ...formData, emergencyNumber: passcode };
        console.log('Submitted Data:', formData?.country);
        navigate(hospitalRoutes.newPatientSubscription, {
            state: { submittedData },
        });
    };
    const handleClose = (e: any) => {
        e.preventDefault();
        closeModal();
    };
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-[#2826265E] bg-opacity-75 z-50">
            <div className="bg-white p-6 rounded-[28px] w-fit m-2  sm:m-0 relative overflow-x-auto">
                <div className="flex items-center justify-between mb-8">
                    <div className="flex items-center flex-col gap-2">
                        <h6 className="text-[22px] font-medium text-lightBrown">
                            Create patient six-digits emergency Number
                        </h6>
                        <p className="text-[14px] font-normal text-[#534341]">
                            Enter 6-digits Number to help have access to your
                            account{' '}
                        </p>
                    </div>
                </div>
                <OtpInput
                    value={passcode}
                    onChange={setPasscode}
                    numInputs={6}
                    inputType="number"
                    containerStyle={'my-5'}
                    inputStyle={
                        'border bg-[#EDE0DE] !w-[50px] sm:!w-[61px] h-[50px] sm:h-[54px] mx-2 outline-none rounded-[8px] codeInput'
                    }
                    renderSeparator={<span></span>}
                    renderInput={(props) => <input {...props} />}
                />
                <div className="flex items-center gap-4 justify-center">
                    <button
                        onClick={handleClose}
                        className="text-Tmred flex-1  h-[40px] font-semibold text-[16px] justify-center mt-4"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSubmit}
                        className={`w-full h-[40px] flex-1 justify-center rounded-lg items-center text-[#9d9b9b] font-semibold mt-4  hover:cursor-not-allowed transition-all duration-500  ${
                            buttonEnabled
                                ? 'bg-Tmred text-white hover:cursor-pointer '
                                : 'bg-[#1D1B201F] '
                        } `}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
};
export default EmergencyPasscode;
