import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { hospitalRoutes } from '../../../utils/routeNames';
import { useHospitalAuthStore } from '../../../store/hospital/auth';
import ChangePasscode from './ChangePasscode';
import { formatDateDmy } from '../../../utils/helpers';
import PasswordChange from './PasswordChange';

const Profile = () => {
    const [isActive, setIsActive] = useState(true);
    const userAuthStore = useHospitalAuthStore((state) => state.user);
    const [show, setShow] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const handleShowModal = () => {
        setShow(true);
    };
    const handleCloseModal = () => {
        setShow(false);
    };

    const handleShowPasswordModal = () => {
        setShowPassword(true);
    };
    const handleClosePasswordModal = () => {
        setShowPassword(false);
    };

    const editProfile = () => {
        navigate(hospitalRoutes.editPersonalProfile);
    };

    useEffect(() => {
        if (userAuthStore.status === 'active') {
            setIsActive(true);
        } else if (userAuthStore.status === 'pending') {
            setIsActive(false);
        }
    }, [userAuthStore.status]);
    const specialty = userAuthStore.specialties.map((specialtyName: any) => {
        return specialtyName.name;
    });
    return (
        <div className="mt-7 sm:mt-0  w-full bg-[#F5F5F5] ">
            <div className="flex justify-between items-center px-8 pt-4 mb-9 gap-6">
                <h6 className="font-bold sm:text-[28px] text-[20px]">
                    Settings
                </h6>
            </div>
            <div className=" w-fit p-4 mx-8 bg-white rounded-2xl">
                <div className="flex flex-col lg:flex-row lg:space-x-6  ">
                    <div className="">
                        <div className="flex items-center justify-between bg-lightPink p-1  sm:p-4  rounded-xl mb-6">
                            <div className="flex items-center space-x-1 sm:space-x-3 ">
                                <img
                                    src={userAuthStore.image}
                                    alt="user logo"
                                    className="rounded-full h-[98px] object-cover w-[98px]"
                                />
                                <div>
                                    <h6 className="sm:text-2xl text-sm font-medium text-lightBrown">
                                        {`${userAuthStore.firstName}  ${userAuthStore.lastName}`}
                                    </h6>
                                    <p className="sm:text-sm text-xs my-2 font-medium text-lightBrown ">
                                        {userAuthStore.email}
                                    </p>
                                    <p className="sm:text-sm text-xs font-normal text-lightBrown">
                                        {userAuthStore.userType}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex  items-start space-x-20   lg:flex-row flex-col ">
                            <div className='flex-1'>
                                <h6 className="text-base sm:text-[22px] font-medium text-lightBrown">
                                    Contact information
                                </h6>
                                <div className="my-6">
                                    <p className="text-sm font-normal text-lightBrown">
                                        Gender
                                    </p>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {userAuthStore.gender
                                            ? userAuthStore.gender
                                            : '-'}
                                    </p>
                                </div>
                                <div>
                                    <p className="text-sm font-normal text-lightBrown">
                                        Phone Number
                                    </p>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {userAuthStore.phoneNumber
                                            ? userAuthStore.phoneNumber
                                            : '-'}
                                    </p>
                                </div>
                                <div className="my-6">
                                    <p className="text-sm font-normal text-lightBrown">
                                        City (optional)
                                    </p>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {userAuthStore.city
                                            ? userAuthStore.city
                                            : '-'}
                                    </p>
                                </div>

                                <div>
                                    <p className="text-sm font-normal text-lightBrown">
                                        State (optional)
                                    </p>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {userAuthStore.state
                                            ? userAuthStore.state
                                            : '-'}
                                    </p>
                                </div>

                                <div className="mt-6">
                                    <p className="text-sm font-normal text-lightBrown">
                                        Address (optional)
                                    </p>
                                    <p className="text-sm font-medium text-lightBrown100 max-w-[310px]">
                                        {userAuthStore.address
                                            ? userAuthStore.address
                                            : '-'}
                                    </p>
                                </div>
                                <h6 className="text-base mt-8 sm:text-[22px] font-medium text-lightBrown">
                                    Availability
                                </h6>
                                <p className="text-sm font-medium text-lightBrown100 max-w-[310px]">
                                    {userAuthStore.availability
                                        ? userAuthStore.availability
                                        : '-'}
                                </p>
                            </div>
                            <div className="mt-6 sm:mt-0 md:mt-6 lg:mt-0 pr-10 flex-1">
                                <h6
                                    className={`${
                                        userAuthStore?.userType === 'admin'
                                            ? 'sm:pb-5'
                                            : ''
                                    } text-base sm:text-[22px] font-medium text-lightBrown`}
                                >
                                    Professional information
                                </h6>

                                {userAuthStore?.userType !== 'admin' && (
                                    <>
                                        <div className="my-6">
                                            <p className="text-sm font-normal text-lightBrown">
                                                Title (optional)
                                            </p>
                                            <p className="text-sm font-medium text-lightBrown100">
                                                {userAuthStore.title
                                                    ? userAuthStore.title
                                                    : '-'}
                                            </p>
                                        </div>
                                    </>
                                )}
                                {userAuthStore?.userType !== 'admin' && (
                                    <>
                                        <div>
                                            <p className="text-sm font-normal text-lightBrown">
                                                MDCN License Number
                                            </p>
                                            <p className="text-sm font-medium text-lightBrown100">
                                                {userAuthStore.licenseNumber
                                                    ? userAuthStore.licenseNumber
                                                    : '-'}
                                            </p>
                                        </div>
                                    </>
                                )}
                                {userAuthStore?.userType !== 'admin' && (
                                    <>
                                        <div className="my-6">
                                            <p className="text-sm font-normal text-lightBrown">
                                                Specialization (optional)
                                            </p>
                                            <div className="flex flex-wrap gap-4">
                                                <p>
                                                    {userAuthStore.specialties &&
                                                    userAuthStore.specialties
                                                        .length > 0
                                                        ? specialty.join(', ')
                                                        : '-'}
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className="flex flex-col">
                                    <p>User Group</p>
                                    {/* <span className="text-xs font-medium text-brown200 border-[1px] border-brown200 px-2 py-1 rounded-lg w-fit">
                                        All
                                       
                                    </span> */}
                                    <div className="flex gap-2 items-center flex-wrap">
                                        {userAuthStore?.permissions?.map(
                                            (permission, index) => (
                                                <span
                                                    key={index}
                                                    className="w-fit text-xs font-medium text-brown200 border-[1px] border-brown200 py-[6px] px-3 rounded-lg"
                                                >
                                                    {permission.trim()}
                                                </span>
                                            )
                                        )}
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <p className="text-sm font-normal text-lightBrown">
                                        Date registered
                                    </p>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {formatDateDmy(userAuthStore.createdAt)}
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* <div className="mt-8">
                            <h2 className="text-sm text-lightBrown font-normal">
                                Status
                            </h2>
                            <div className="flex max-w-[322px] justify-between items-center mt-2">
                                <span
                                    className={`text-base text-lightBrown font-normal ${
                                        isActive ? 'text-base ' : 'text-base'
                                    }`}
                                >
                                    {isActive ? 'Active' : 'Not Active'}
                                </span>
                                <input
                                    type="checkbox"
                                    className="hidden"
                                    id="toggleSwitch"
                                    checked={isActive}
                                    onChange={handleToggle}
                                />
                                {isActive ? (
                                        <Active/>
                                ):<Inactive/>} 
                                 <label
                                    htmlFor="toggleSwitch"
                                    className={`relative inline-block w-12 h-8  ${
                                        isActive ? 'bg-green100' : 'bg-[#EDE0DE]'
                                    } rounded-full cursor-pointer transition duration-300`}
                                >
                                    <span
                                        className={`absolute top-0 bottom-0 my-1  shadow-md transform ${
                                            isActive
                                                ? 'translate-x-5 '
                                                : 'translate-x-1 bg-[#EDE0DE] rounded-full p-3'
                                        } transition duration-300`}
                                    >
                                        {isActive ? (
                                            <MdOutlineCheck className="bg-white rounded-full w-6 h-6 p-1" /> 
                                        ): <SlClose color='#857371' size={'16'} />
                                    }
                                    </span>
                                </label> 
                            </div>
                        </div> */}
                        <div className="flex justify-end space-x-6 py-3 border-t mt-10">
                            <button
                                type="button"
                                onClick={handleShowModal}
                                className="text-Tmred border-[1px] border-lightBrown100 px-6 py-2 text-base font-normal rounded-lg"
                            >
                                Change Passcode
                            </button>
                            <button
                                type="button"
                                onClick={handleShowPasswordModal}
                                className="text-Tmred border-[1px] border-lightBrown100 px-6 py-2 text-base font-normal rounded-lg"
                            >
                                Change Password
                            </button>
                            <button
                                onClick={editProfile}
                                type="submit"
                                className="text-Tmred border-[1px] border-lightBrown100 px-6 py-2 text-base font-normal rounded-lg"
                            >
                                {' '}
                                Edit Profile
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {show && <ChangePasscode closeModal={handleCloseModal} />}
            {showPassword && (
                <PasswordChange closeModal={handleClosePasswordModal} />
            )}
        </div>
    );
};

export default Profile;
