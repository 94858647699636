import React, { useEffect, useState } from 'react';
import Input from '../../../../reusable/Forms/Input';
import { FaPlus } from 'react-icons/fa';
import CloseRemark from '../PatientModals/CloseRemark';
import hospitalService from '../../../../services/hospitalService';
import { useMutation } from 'react-query';
import { useRemarkStore } from '../../../../store/hospital/auth';
import { ToastContainer, toast } from 'react-toastify';

interface LabTestResult {
    name: string;
    result: string;
}
interface FormData {
    testName: string;
    result: any;
    name: any;
    remark: string;
}

const UploadTest = ({ closeModal, activeItemId, tmrNum, testNamed }) => {
    const { remark: childRemark } = useRemarkStore();
    const [button, setButton] = useState(false);
    const [loading, setLoading] = useState(false); // Add loading state

    const handleOpenModal = (e: React.FormEvent) => {
        setOpenModal(true);
        e.preventDefault();
    };

    const [formData, setFormData] = useState<FormData>({
        testName: testNamed,
        result: '',
        name: '',
        remark: '',
    });
    const [openModal, setOpenModal] = useState(false);

    const handleChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
    ) => {
        const { name, value } = e.target;
        setFormData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
    };
    useEffect(() => {
        if (
            formData.testName !== '' &&
            formData.name !== '' &&
            formData.result !== ''
        ) {
            setButton(true);
        } else {
            setButton(false);
        }
    }, [formData.result, formData.name, formData.testName]);
    const [newTests, setNewTests] = useState<any>([]);
    const handleNewTestChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        const { name, value } = e.target;
        setNewTests((prevTests) => {
            const updatedTests = [...prevTests];
            updatedTests[index] = {
                ...updatedTests[index],
                [name]: value,
            };
            return updatedTests;
        });
    };
    const addNewTest = (e: any) => {
        e.preventDefault();
        // setTests((prevTests: any) => [...prevTests, formData.Test]);
        setNewTests((prevData: any) => [...prevData, { name: '', result: '' }]);
    };
    const closeRemark = (remark: string) => {
        setOpenModal(false);
        setFormData((prevData: any) => ({
            ...prevData,
            remark: remark, // Update the remark value in the parent component
        }));
    };

    const handleCloseRemark = (remark: string) => {
        setOpenModal(false);
        setLoading(true);
        const allTestsData = [
            {
                result: formData.result,
                name: formData.name,
            },
            ...newTests.map((test) => ({
                result: test.result,
                name: test.name,
            })),
        ];

        const payload = {
            // labTestId: activeItemId,
            // patientTmrNumber: tmrNum,
            testResult: allTestsData,
            remark: remark || childRemark || '',
        };
        // Submit lab test results along with the remark to the backend
        hospitalService
            .createLabTestResult(payload, activeItemId)
            .then((response) => {
                toast.success(response.data.message);
                closeModal();
            })
            .catch((error) => {
                console.error('Error:', error.response.data.error.message);
                closeModal();
                toast.error(error.response.data.error.message);
            })
            .finally(() => {
                setLoading(false); // Set loading to false after submission
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setOpenModal(true); // Open the modal for entering the remark
    };

    return (
        <div>
            <ToastContainer />
            <form onSubmit={handleSubmit}>
                <Input
                    name="testName"
                    label="Test Name"
                    value={formData.testName}
                    onChange={handleChange}
                    className={
                        'w-full border border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider'
                    }
                    disabled
                />
                <div className="w-full flex service flex-col sm:flex-row sm:gap-6 my-4 sm:py-6">
                    <div className="w-1/2">
                        <Input
                            name="name"
                            label="Test Name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="w-1/2 flex flex-col sm:flex-row sm:gap-3">
                        <textarea
                            name="result"
                            placeholder="Result"
                            required={true}
                            value={formData.result}
                            onChange={handleChange}
                            className="w-full border h-[109px] border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider"
                        ></textarea>
                    </div>
                </div>
                {newTests.map((newTest, index) => (
                    <div
                        key={index}
                        className="w-full flex service flex-col sm:flex-row sm:gap-6 my-4 sm:py-6"
                    >
                        <div className="w-1/2">
                            <Input
                                name="test"
                                label="Test Name"
                                value={newTest.test}
                                onChange={(e) => handleNewTestChange(e, index)}
                            />
                        </div>

                        <div className="w-1/2 flex flex-col sm:flex-row sm:gap-3">
                            <textarea
                                name="result"
                                placeholder="Result"
                                required={true}
                                value={newTest.result}
                                onChange={(e: any) =>
                                    handleNewTestChange(e, index)
                                }
                                className="w-full border h-[109px] border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider"
                            ></textarea>
                        </div>
                    </div>
                ))}
                <div className="flex justify-center">
                    <button
                        onClick={addNewTest}
                        className="flex gap-2 items-center text-Tmred font-medium   p-1 outline-none mt-4"
                    >
                        <FaPlus />{' '}
                        <span className="text-[14px] py-1 px-2">
                            Add new test
                        </span>
                    </button>
                </div>
                <div className="flex justify-end space-x-6  mt-6">
                    <button
                        onClick={closeModal}
                        className="py-[10px] px-6 border-[1px] rounded-lg text-Tmred text-sm font-medium border-lightBrown100"
                    >
                        Close
                    </button>
                    <button
                        disabled={!button || loading}
                        className={` py-[10px] px-6 rounded-lg text-sm font-medium  ${
                            button && !loading
                                ? 'bg-Tmred   text-white  '
                                : 'bg-[#1D1B201F] text-[#999797]'
                        } `}
                    >
                        {loading ? 'Uploading Test Result...' : 'Confirm Test'}
                    </button>
                </div>
            </form>

            {openModal && (
                <CloseRemark
                    closeModal={handleCloseRemark}
                    handleClose={closeRemark}
                    testNamed={testNamed}
                />
            )}
        </div>
    );
};

export default UploadTest;
