import React from 'react'

function VoucherEye() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_22721_310293)">
    <rect x="3" y="3" width="30" height="30" rx="15" fill="#D8C2BF"/>
    <path d="M19.8974 16.102L16.1024 19.897C15.6149 19.4095 15.3149 18.742 15.3149 17.9995C15.3149 16.5145 16.5149 15.3145 17.9999 15.3145C18.7424 15.3145 19.4099 15.6145 19.8974 16.102Z" stroke="#534341" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22.3649 13.3269C21.0524 12.3369 19.5524 11.7969 17.9999 11.7969C15.3524 11.7969 12.8849 13.3569 11.1674 16.0569C10.4924 17.1144 10.4924 18.8919 11.1674 19.9494C11.7599 20.8794 12.4499 21.6819 13.1999 22.3269" stroke="#534341" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.3149 23.6473C16.1699 24.0073 17.0774 24.2023 17.9999 24.2023C20.6474 24.2023 23.1149 22.6423 24.8324 19.9423C25.5074 18.8848 25.5074 17.1073 24.8324 16.0498C24.5849 15.6598 24.3149 15.2923 24.0374 14.9473" stroke="#534341" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M20.6326 18.5234C20.4376 19.5809 19.5751 20.4434 18.5176 20.6384" stroke="#534341" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.1025 19.8965L10.5 25.499" stroke="#534341" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M25.5 10.5L19.8975 16.1025" stroke="#534341" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_22721_310293">
    <rect x="3" y="3" width="30" height="30" rx="15" fill="white"/>
    </clipPath>
    </defs>
    </svg>

  )
}

export default VoucherEye