import { useState } from 'react';
import UploadTest from '../tabs/UploadTest';
import UploadImage from '../tabs/UploadImage';

interface UploadTestModalProps {
    closeModal: () => void;
    activeItemId: number | null;
    tmrNum: string;
    testName: string;
}

const UploadTestModal: React.FC<UploadTestModalProps> = ({
    closeModal,
    activeItemId,
    tmrNum,
    testName
}) => {
    const [activeTab, setActiveTab] = useState('TestResult');

    const handleTabChange = (tab: string) => {
        setActiveTab(tab);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-[#2826265E] bg-opacity-75 z-50">
            <div className="bg-white p-2 mx-1 sm:mx-0 sm:p-6 rounded-[28px] w-full max-w-[753px] max-h-[551px] overflow-y-auto">
                <div className="pb-3">
                    <h6 className="text-2xl font-semibold text-lightBrown pb-2">
                        Upload Test Result
                    </h6>
                    <p className="font-normal text-sm text-dark100 my-3">
                        Kindly provide the result information or upload the
                        image.
                    </p>
                </div>
                <div className="flex justify-between items-center pb-2 mb-4">
                    <div
                        className={`cursor-pointer flex-1 text-center py-3 ${
                            activeTab === 'TestResult'
                                ? 'border-b-2 border-[#E53E3E]'
                                : ''
                        }`}
                        onClick={() => handleTabChange('TestResult')}
                    >
                        <p className="text-[14px] font-medium text-[#201A19]">
                            Test Result
                        </p>
                    </div>
                    <div
                        className={`cursor-pointer flex-1 pl-2 text-center py-3 ${
                            activeTab === 'ImageResult'
                                ? 'border-b-2 border-[#E53E3E]'
                                : ''
                        }`}
                        onClick={() => handleTabChange('ImageResult')}
                    >
                        <p className="text-[14px] font-medium text-[#201A19]">
                            Image Result
                        </p>
                    </div>
                </div>
                <div className="">
                    {activeTab === 'TestResult' ? (
                        <UploadTest
                            closeModal={closeModal}
                            activeItemId={activeItemId}
                            tmrNum={tmrNum}
                            testNamed={testName}
                        />
                    ) : (
                        <UploadImage
                            closeModal={closeModal}
                            testNamed={testName}
                            tmrNum={tmrNum}
                            activeItemId={activeItemId}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default UploadTestModal;
