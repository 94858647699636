import React, { useEffect, useState } from 'react';
import SetUpAmbulance from './SetUpAmbulance';
import Ambulance from './Ambulance';
import { useHospitalAuthStore } from '../../../store/hospital/auth'; 

function Fleet() {
    const [isAmbulanceSetUp, setIsAmbulanceSetUp] = useState(false);
    const hospitalAuthStore = useHospitalAuthStore((state) => state.hospital);
    
    useEffect(() => {
        // Check if ambulance setup has been done
        const ambulanceSetupStatus = hospitalAuthStore?.hasAmbulance ? true : false;; 
        setIsAmbulanceSetUp(ambulanceSetupStatus);
    }, [hospitalAuthStore]);

    return (
        <div>
            {isAmbulanceSetUp ? (
                <Ambulance />
            ) : (
                <SetUpAmbulance setIsAmbulanceSetUp={setIsAmbulanceSetUp} />
            )}
        </div>
    );
}

export default Fleet;
