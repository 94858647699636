import React from 'react';
import DashboardLayout from '../Dashboard/DashboardLayout';
import HospitalProfile from '../../../components/Settings/Hospital/HospitalProfile';
import { Helmet } from 'react-helmet'


const HospitalPage = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Hospital Profile
                </title>
            </Helmet>
            <div className="">
                <HospitalProfile />
            </div>
        </DashboardLayout>
    );
};

export default HospitalPage;
