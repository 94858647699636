import React from 'react';

type docProps = {
    name: string;
    img: any;
};

const DoctorAvatar = (props: docProps) => {
    const { name, img } = props;
    return (
        <div className="flex flex-col gap-2 justify-center items-center my-5">
            <h1 className="font-semibold text-[24px] text-center">{name}</h1>
            <img
                className=" rounded-full h-[120px] w-[120px] "
                src={img}
                alt="doctor_image"
            />
        </div>
    );
};
export default DoctorAvatar;
