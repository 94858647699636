import React from 'react'
import { Helmet } from 'react-helmet'
import DashboardLayout from '../Dashboard/DashboardLayout'
import Ads from '../../../components/TmrAds/Ads'

function ADS() {
  return (
    <DashboardLayout>
    <Helmet>
        <title>
            TMR | TMR Ads
        </title>
    </Helmet>
    <div className="">
        <Ads/>
    </div>
</DashboardLayout>
  )
}

export default ADS