import { useState, useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../dashboard/DashboardCard';
import Doctors from '../../Images/doctor-icon.svg';
import Appointment from '../../Images/appointment-icon.svg';
import UpcomingAppointments from './UpcomingAppointment';
import Input from '../../reusable/Forms/Input';
import BlockButton from '../../reusable/BlockButton';
import hospitalService from '../../services/hospitalService';

type SearchProps = {
    searchParam: string;
};

const NurseDashboard: React.FC = () => {
    const navigate = useNavigate();
    const [dashboardData, setDashboardData] = useState<any>();

    const {
        formState: { isDirty, isValid },
        handleSubmit,
        control,
    } = useForm<SearchProps>();

    const modeData = {
        title: 'Mode',
        firstData: 'Home Visit',
        firstDataValue: dashboardData?.modes?.homeVisit,
        secondData: 'Telemedicine',
        secondDataValue: dashboardData?.modes?.telemedicine,
        thirdData: 'Walk-in',
        thirdDataValue: dashboardData?.modes?.walkIn,
        iconSrc: Doctors,
    };

    const appointmentData = {
        title: 'Appointment',
        firstData: 'Pending',
        firstDataValue: dashboardData?.appointments?.pending,
        secondData: 'Completed',
        secondDataValue: dashboardData?.appointments?.completed,
        thirdData: 'Total',
        thirdDataValue: dashboardData?.appointments?.total,
        iconSrc: Appointment,
    };

    useEffect(() => {
        hospitalService
            .getDashboardData() // TODO: change to useQuery
            .then((res) => {
                setDashboardData(res);
                console.log(res);
            })
            .catch((err) => {
                toast.error(err);
            });
    }, []);

    const submitSearch: SubmitHandler<SearchProps> = (data) => {
        const { searchParam } = data;
        navigate(`/hospital/dashboard/record-search?query=${searchParam}`);
    };
    return (
        <div className="md:px-8 py-6 px-2 w-full">
            <h2 className="mb-6 font-semibold text-[28px]">Dashboard</h2>
            <div className="flex flex-row gap-4 w-full flex-wrap ">
                <div className="flex-1 ">
                    <div className="flex md:flex-row flex-col items-center gap-6">
                        <Card {...appointmentData} />
                        <Card {...modeData} />
                    </div>
                </div>

                <div className="w-full xl:w-[358px] 2xl:w-flex-1">
                    <div className="bg-white  p-4 rounded-2xl ">
                        <h6 className="text-dark text-xl font-bold mb-6">
                            Record Search
                        </h6>
                        <form onSubmit={handleSubmit(submitSearch)}>
                            <Controller
                                name="searchParam"
                                rules={{
                                    required: 'Search Parameter is required',
                                }}
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        label="Patient Name or TMR Number"
                                        {...field}
                                    />
                                )}
                            />

                            <BlockButton
                                text="View Record"
                                disabled={!isDirty || !isValid}
                                type="submit"
                                isLoading={false}
                                className="mt-5 p-3"
                            />
                        </form>
                    </div>
                </div>
            </div>
            <div className="flex flex-col xl:flex-row gap-4 w-full mt-8">
                <UpcomingAppointments />
                {/* <RecentTransaction /> */}
            </div>
        </div>
    );
};

export default NurseDashboard;
