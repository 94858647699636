import DoctorProfile from '../../../../components/UserDoctor/DoctorProfile';
import DashboardLayout from '../../Dashboard/DashboardLayout';
import {Helmet} from 'react-helmet'


const DoctorProfiles = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Doctor's Profile
                </title>
            </Helmet>
            <DoctorProfile />
        </DashboardLayout>
    );
};
export default DoctorProfiles;
