import { useNavigate, useParams } from 'react-router-dom';

import DashboardLayout from '../Dashboard/DashboardLayout';
import ActiveState from '../../../components/Patient/ActiveState';

const PatientAppointments = () => {
    const navigate = useNavigate();
    let { id } = useParams();
    return (
        <DashboardLayout>
            <div>
                <ActiveState />
            </div>
        </DashboardLayout>
    );
};

export default PatientAppointments;
