import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHospitalAuthStore } from '../../../store/hospital/auth';
import { useHospitalAccountStore } from '../../../store/hospital/auth';
import { useMutation, useQuery } from 'react-query';
import hospitalService from '../../../services/hospitalService';
import logger from '../../../utils/logger';
import loadingState from '../../../Images/loading.json';
import Lottie from 'lottie-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import patientService from '../../../services/patientService';
import { PatientPlan1, PatientPlan2 } from '../../../Images/Svgs';
import PaymentModal from './PaymentModal';
import { formatNumber, standardAmountFormat } from '../../../utils/helpers';
import { currencySymbols } from '../../../utils/currencySymbols';

const PatientSubPlan = ({ submittedData }) => {
    console.log('Submitted Data:', submittedData);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const hospitalAccountStore = useHospitalAccountStore((state) => state);
    const hospitalAuthStore = useHospitalAuthStore((state) => state);
    // const {setPlanId} = useHospitalPlanId()
    const [planChosen, setPlanChosen] = useState<boolean>(false);
    const [plan, setPlan] = useState('');
    const [planObj, setPlanObj] = useState<any>();
    const [chosenPlanId, setChosenPlanId] = useState<string | null>(null);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showFinalData, setShowFinalData] = useState('');
    const mutation = useMutation((data: any) =>
        hospitalService.subscribe(data)
    );
    const normalizeCountryName = (country: string) => {
        return country.replace(/\s+/g, ''); //To Remove spaces and convert country to lower case
      };
      const selectedCountry = submittedData?.country
      const normalizedCountry = normalizeCountryName(selectedCountry);
      const currency = currencySymbols[normalizedCountry] || ''; //To Get the currency symbol based on the selected country
    // const getActivePlanMutation = useMutation(
    //     () =>
    //         patientService.getPatientSubscription()

    // );

    useEffect(() => {
        if (mutation.isSuccess) {
            setLoading(false);
            const responseData = mutation.data;
            console.log('success', responseData);
            const paymentLink = responseData?.data?.payload?.data?.paymentLink;
            if (paymentLink) {
                window.open(paymentLink, '_blank');
            }
        }
    }, [mutation.isSuccess, mutation.data]);
    useEffect(() => {
        if (mutation.isError) {
            setLoading(false);
            let err: any = mutation.error;
            logger(err.response.data.error.message);
            toast.error(err.response.data.error.message);
        }
    }, [mutation.isError, mutation.error]);
    const handleSubscribe = (plan) => {
        if (loading) {
            return; // Prevent multiple clicks while loading
        }
        setLoading(true);
        setPlanObj(plan);
        sessionStorage.setItem('plan', JSON.stringify(plan));
        console.log(plan);

        const planId = plan._id;
        const finalData = { ...submittedData, planId: planId };
        setShowFinalData(finalData);
        console.log(finalData);

        sessionStorage.setItem('finalData', JSON.stringify(finalData));
        const subscribeData = {
            planId,
            token: hospitalAuthStore.token || hospitalAccountStore.token,
        };
        setPlan(planId);
        console.log(planId);
        // setPlanId(planId)

        setChosenPlanId(planId);
        // mutation.mutate(subscribeData);
        setPlanChosen(true);
    };
    const handleActiveSubscribe = () => {
        if (!plan) {
            console.error('Plan ID is not set');
            return;
        }
        // getActivePlanMutation
        //     .mutateAsync()
        //     .then((responseData) => {
        //         console.log('success', responseData);
        //         if (!responseData || Object.keys(responseData).length === 0) {
        //             toast.error('Payment has not been made');
        //         } else {

        //             setPlanObj(responseData.subscription)
        //             console.log(
        //                 'Success checking active status:',
        //                 responseData
        //             );
        //         }
        //     })
        //     .catch((error) => {
        //         console.error('Error checking active status:', error);
        //     });
    };

    const handleCloseModal = () => {
        setShowPaymentModal(false);
    };
    // const country = 'Ghana';

    const country= submittedData.country
    // const currency = useHospitalAuthStore((state) => state.hospital.currency)

    const { data, isError, isLoading } = useQuery(
        ['Patient plans', country],
        () => patientService.getPatientSubscriptionplans(country)
    );
    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Lottie
                    animationData={loadingState}
                    loop={true}
                    className="lottie"
                />
            </div>
        );
    }

    if (isError) {
        return <div>Error loading plan, please refresh your page</div>;
    }
    const plans = data?.plans;
    console.log('Here are the available Patient plans:', plans);
    return (
        <Fragment>
            <ToastContainer />

            <div className="flex items-center justify-center flex-col w-full overflow-x-hidden ">
                <div className="mx-auto flex flex-col items-center justify-center gap-4">
                    <div className="flex flex-wrap md:flex-nowrap justify-center gap-5 ">
                        {plans.map((plan, index) => {
                            // const isPlanLoading =
                            //     chosenPlanId === plan._id && loading;
                            const isPlanLoading = chosenPlanId === plan._id;
                            const PlanImage =
                                plan.name === 'Basic' ? (
                                    <PatientPlan1 />
                                ) : (
                                    <PatientPlan2 />
                                );
                            return (
                                <div
                                    key={plan._id}
                                    className={`${
                                        isPlanLoading
                                            ? 'bg-[#F2E5E3] border-[#C00010]'
                                            : 'bg-[#FFFFFF] border-[#FFDAD5]'
                                    } flex flex-col border   items-center justify-center rounded-[16px]  py-[40px] px-[20px] mt-7 w-[250px] lg:w-[424px] max-w-[524px] flex-wrap`}
                                >
                                    <div className="flex items-center justify-center py-1">
                                        {PlanImage}
                                    </div>
                                    <span
                                        className={`font-semibold mt-2 text-center text-[14px] p-2  rounded-[8px] w-fit
                                           
                                                bg-[#FFDAD5] text-[#534341]
                                                
                                        `}
                                    >
                                        {plan.name} Plan
                                    </span>
                                    <p className="text-center text-dark100 ">
                                        {plan.type}
                                    </p>

                                    <h2 className="flex justify-center mt-6 text-[36px] font-semibold flex-wrap">
                                        {standardAmountFormat(
                                            plan?.amount || 0,
                                            currency
                                        )}
                                        /
                                    </h2>
                                    <span className="flex justify-center text-black100 text-center">
                                        {plan.validity} days
                                    </span>

                                    <div className="text-dark100 my-8  p-2  ">
                                        {Object.keys(plan.features).map(
                                            (key, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`border-b border-[#D8C2BF] p-3 ${
                                                            index ===
                                                            Object.keys(
                                                                plan.features
                                                            ).length -
                                                                1
                                                                ? 'border-b-0'
                                                                : ''
                                                        } flex items-center flex-wrap justify-between text-left gap-5 mb-5 `}
                                                    >
                                                        {/* <div>
                                                        <img
                                                            src={tickCircle}
                                                            alt=""
                                                            className=""
                                                        />
                                                    </div> */}
                                                        <div className="flex-1">
                                                            <p className="text-[14px] font-medium text-[#534341] break-words break-all">
                                                                {
                                                                    plan
                                                                        .features[
                                                                        key
                                                                    ].label
                                                                }
                                                            </p>
                                                        </div>

                                                        <div className="flex-shrink-0">
                                                            <p className="text-[14px] font-medium text-[#534341]">
                                                                {
                                                                    key ===
                                                                    'canAccessPrivateHospital'
                                                                        ? plan
                                                                              .features[
                                                                              key
                                                                          ]
                                                                              .value
                                                                            ? 'yes'
                                                                            : 'no'
                                                                        : typeof plan
                                                                                .features[
                                                                                key
                                                                            ]
                                                                                .value ===
                                                                            'number'
                                                                          ? formatNumber(
                                                                                plan
                                                                                    .features[
                                                                                    key
                                                                                ]
                                                                                    .value
                                                                            ) // Format number with commas
                                                                          : plan
                                                                                .features[
                                                                                key
                                                                            ]
                                                                                .value // Render other values as is
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                    <button
                                        onClick={() => handleSubscribe(plan)}
                                        className={`w-full h-[40px] text-sm flex justify-center rounded-lg items-center font-semibold  transition-all duration-500 border border-Tmred text-Tmred hover:bg-Tmred hover:text-white hover:cursor-pointer`}
                                    >
                                        {/* {isPlanLoading
                                            ? 'Loading...'
                                            : ' Choose Plan'} */}
                                        {isPlanLoading
                                            ? 'Plan selected'
                                            : 'Select plan'}
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className=" my-6 flex justify-center items-center ">
                    <button
                        type="button"
                        // onClick={handleActiveSubscribe}
                        onClick={() => setShowPaymentModal(true)}
                        className={`h-[40px] w-[285px] text-sm font-semibold flex justify-center rounded-lg items-center transition-all duration-500 text-white ${
                            planChosen
                                ? ' !text-[#FEF1EF] cursor-pointer !bg-Tmred'
                                : 'bg-[#1D1B201F] !text-[#201A19] cursor-not-allowed'
                        } shadow-md`}
                    >
                        {/* {getActivePlanMutation.isLoading
                            ? 'Checking...'
                            : ' I’ve made payment'} */}
                        Select payment method
                    </button>
                </div>
            </div>
            {showPaymentModal && (
                <PaymentModal
                    closeModal={handleCloseModal}
                    selectedPlanId={chosenPlanId}
                    finalData={showFinalData}
                />
            )}
        </Fragment>
    );
};

export default PatientSubPlan;
