import { useMutation } from 'react-query';
import { useEffect, useState } from 'react';
import hospitalService from '../../services/hospitalService';
import { toast } from 'react-toastify';
import logger from '../../utils/logger';
import { usePatientSearchStore } from '../../store/hospital/auth';

interface ModalProps {
    closeModal: () => void;
    onPatientAdd: () => void;
    onResetTmrNumber: () => void;
}

const SearchedPatientModal: React.FC<ModalProps> = ({
    closeModal,
    onPatientAdd,
    onResetTmrNumber,
}) => {
    const [selectedPatient, setSelectedPatient] = useState(null);
    const patientInStore = usePatientSearchStore((state) => state.searchedPatient);
    const setPatientInStore = usePatientSearchStore(
        (state) => state.setSearchedPatient
    );
    const [patientData, setPatientData] = useState(patientInStore[0])
        
    const handleCancel = () => {
      onResetTmrNumber();// Update tmrNumber to an empty string
        closeModal();
    };

    useEffect(() => {
    //   console.log(patientInStore[0]);
    //   console.log(patientData);
    }, [patientData, patientInStore])
    
    //Sets patientData to the selected patient data
    const handleSelectedPatient = (patient) => {
        setPatientData(patient)
        setSelectedPatient(patient._id)
        
    }

    const handleAddPatient = () => {
        // Set the patient details in the store
        onPatientAdd();
        setPatientInStore(patientData);
        closeModal();
        toast.success('Patient Added Successfully');
        // console.log(patientData);
    };
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-[#2826265E] bg-opacity-75 z-50">
            <div className="bg-white p-6 rounded-[28px] w-full m-2 max-w-[500px] sm:m-0 ">
                {
                    patientInStore.length > 1?
                    <>
                        <div className="my-4">
                            <h1 className=" text-Tmred text-[24px] font-semibold">{patientInStore.length} Patient's results </h1>

                            <p className="my-5">Kindly select the patients</p>
                        </div>

                        <div className="flex flex-col gap-4">
                            { patientInStore.map((patient, index) => (
                                <div className={`flex gap-5 hover:bg-[#EDE0DE] p-5 rounded-lg ${selectedPatient === patient._id ? 'bg-[#EDE0DE]' : ''}`}
                                    onClick={ () => handleSelectedPatient(patient)}
                                >
                                    <div>
                                        <img src={patient.image} className=" h-16 w-16 rounded-full " alt="" />
                                    </div>
                                    <div className="flex flex-col justify-center">
                                        <h1 className=" text-[18px] font-bold ">
                                            {patient.firstName} {patient.lastName} 
                                        </h1>
                                        <p className="text-[14px]">
                                            {patient.email}
                                        </p>
                                    </div>
                                </div>
                            )) }
                        </div>
                        <div className="mt-6">
                            <div className="flex justify-end space-x-6 pt-3 mt-6">
                                <button
                                    onClick={handleCancel}
                                    className="py-[10px] px-4 text-lightBrown text-sm font-medium"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    onClick={handleAddPatient}
                                    className={` text-sm font-medium py-[10px] px-4 rounded-lg  bg-Tmred text-white hover:cursor-pointer`}
                                >
                                    Add Patient
                                </button>
                            </div>
                        </div>  
                    </>

                    :

                    <>
                        <div className="flex items-center gap-3">
                            <img
                                src={patientInStore[0]?.image}
                                alt="patientImage"
                                className="w-24 h-24 rounded-full shadow-md"
                            />
                            <div>
                                <h2 className="font-semibold text-2xl text-lightBrown ">
                                    {`${patientInStore[0]?.firstName} ${patientInStore[0]?.lastName}`}
                                </h2>
                                <p className="text-dark100 text-sm font-medium mt-3">
                                    {patientInStore[0]?.email}
                                </p>
                            </div>
                        </div>
                        <div className="mt-6">
                            <div className="flex justify-end space-x-6 pt-3 mt-6">
                                <button
                                    onClick={handleCancel}
                                    className="py-[10px] px-4 text-lightBrown text-sm font-medium"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    onClick={handleAddPatient}
                                    className={` text-sm font-medium py-[10px] px-4 rounded-lg  bg-Tmred text-white hover:cursor-pointer`}
                                >
                                    Add Patient
                                </button>
                            </div>
                        </div>  
                    </>


                }
            </div>
        </div>
    );
};
export default SearchedPatientModal;
