import React from 'react'
import image from '../../Images/message.png';
const Message = () => {
  return (
    <div className="w-full   flex flex-col md:px-8 py-4 px-2">
   
    <div className="flex justify-between items-center py-4">
        <p className="font-bold sm:text-[28px] text-[20px]">Messaging</p>
    </div>
    <div className="border-transparent bg-[#FFFFFF]  rounded-2xl overflow-x-auto sm:overflow-hidden">
    <div className="flex flex-col items-center justify-center text-center my-[70px] ">
                                    <img src={image} alt="" className='max-w-[412px]'/>
                                    <span className='bg-[#FDDFA6] p-2 mt-3 w-full text-center'>
                                    <h5 className="  text-base sm:text-[24px] font-semibold  text-[#C00010]">
                                    Coming soon...
                                    </h5>
                                    </span>
                                    
                                </div>
    </div>
    </div>
  )
}

export default Message
