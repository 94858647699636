import React from 'react'
import Modal from '../../../../../reusable/LoadingModal'

function DeleteModal({handleDeleteConfirm, handleDeleteCancel,isDeleteModalVisible}) {

    
  return (
    <div>
        
        {/* Delete Modal */}
      <Modal
        isVisible={isDeleteModalVisible}
        onClose={handleDeleteCancel}
        buttonEnabled={true}
        isLoading={false}
        loadingText="Deleting..."
        setButton={() => {}}
        onSave={handleDeleteConfirm}
        saveText="Yes, delete"
        closeText={'cancel'}
        showCloseButtonBorder={true}
      >
        <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2 sm:w-[420px] sm:h-[112px]">
          <h2 className="font-semibold text-2xl mb-5">Delete Drug</h2>
          <p className="text-[14px] font-normal text-[#534341]">
          Are you sure you want to delete Amoxicillin? 
          </p>
        </div>
      </Modal>
    </div>
  )
}

export default DeleteModal