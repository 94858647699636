import React, { useEffect, useState } from 'react';
import usePagination from '../../../hooks/usePagination';
import Table from '../../../constants/Table';
import Image from '../../../Images/EmptyAmbulance.svg';
import { useQuery } from 'react-query';
import hospitalService from '../../../services/hospitalService';
import TimeComponent, { formatDateDmy } from '../../../utils/helpers';
import Lottie from 'lottie-react';
import loadingState from '../../../Images/loading.json';
import search from '../../../Images/Leading-icon.png';
import profile from '../../../Images/profile.png';


import FleetEmptyState from './FleetEmptyState';




const RequestHistory = () => {
   
   
    const [searchQuery, setSearchQuery] = useState('');

    const [emptyState, setEmptyState] = useState(true);
    const [Requests, setRequests] = useState<[]>([]);
    

    const { data, isError, isLoading } = useQuery(
        'requestHistory',
        hospitalService.getAllRequestHistory
    );
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
    
    useEffect(() => {
        
                setRequests(data?.ambulanceRequests);
            
    }, [data]);
    
    // console.log(data?.ambulanceRequests)
   
    useEffect(() => {
        if (data) {
            let filteredData = data?.ambulanceRequests
            
    
    
            if (searchQuery) {
                filteredData = filteredData.filter(
                    (row) =>
                        row?.patient?.firstName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        row?.patient?.lastName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        row?.tmrNumber?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        row?.status?.toLowerCase().includes(searchQuery.toLowerCase()) 
                );
            }
    
            setRequests(filteredData);
        }
    }, [data, searchQuery]);

    useEffect(() => {
        if (Requests?.length > 0) {
            setEmptyState(false);
        }
    }, [Requests]);
    
    // Pagination
    const { page, limit, Pagination } = usePagination({
        page: 1,
        limit: 10,
        total: Requests?.length,
    });

    const paginatedRows = Requests?.slice((page - 1) * limit, page * limit);

    

  
if(isError){
    return(
        <p>Error loading request history</p>
    )
}
   

    const requestHeaders = [
        {
            title: 'S/N',
            render: (row: any) => (
                <p>
                    {Requests?.findIndex((user: any) => user._id === row._id) + 1}
                </p>
            ),
        },
        {
            title: 'Patient Name',
            render: (row: any) => (
                <div className="flex items-center gap-x-[10px]" >
                    <img src={row.patient?.img || profile} alt="ambulance img" className='w-[40px] h-[40px] rounded-[200px]'/>
                        
                        <div style={{ width: '168px', whiteSpace: 'normal', wordWrap: 'break-word' }} className="truncate  font-medium text-lightBrown text-sm">
                        <p className="truncate w-32 font-medium text-lightBrown text-sm">
                            {`${row.patient?.firstName} ${row.patient?.lastName}`}
                        </p>
                        <p className="truncate w-32 font-medium text-lightBrown text-sm">
                            {`${row.patient?.tmrNumber
}`}
                        </p>
                    </div>
                </div>
            ),
        },
        {
            title: 'Address',
            render: (row: any) => (
                <div className="flex items-center gap-x-[10px]">
                    <div>
                        <p style={{ width: '200px', whiteSpace: 'normal', wordWrap: 'break-word' }} className="truncate  font-medium text-lightBrown text-sm">
                            {row?.location || ''}
                        </p>
                        
                    </div>
                </div>
            ),
        },
        
        {
            title: 'Types of Ambulance',
            render: (row: any) => (
                <p style={{ width: '168px', whiteSpace: 'normal', wordWrap: 'break-word' }} className="text-lightBrown100 text-xs font-medium">
                    {row.ambulanceType}
                </p>
            ),
        },
        {
            title: 'Amount',
            render: (row: any) => (
                <p className="text-lightBrown100 text-xs font-medium">
                {row.amount}
                </p>
            ),
        },
        {
            title: 'Status',
            render: (row: any) => (
                <div
                className={`flex items-start justify-center p-2 rounded 
                    ${
                        row.status === 'pending'
                            ? 'bg-[#366CC926] text-lightBrown'
                            : ''
                    } 
                    ${
                        row.status === 'completed'
                            ? 'bg-[#366CC926] text-lightBrown'
                            : ''
                    }
                    ${
                        row.status === 'cancelled'
                            ? 'bg-[#FFDAD5] text-lightBrown'
                            : ''
                    }
                `}
            >
                <div
                    className={`w-[6px] h-[6px] rounded-full mr-2 mt-[6px] 
                        ${row.status === 'pending' ? 'bg-[#366CC9]' : ''}
                        ${row.status === 'completed' ? 'bg-[#3A975F]' : ''}
                        ${row.status === 'cancelled' ? 'bg-[#C00010]' : ''}
                    }`}
                />
                <p className="text-xs">{row.status}</p>
            </div>
            ),
        },
        {
            title: 'Request Date',
            render: (row: any) => (
                <p style={{ width: '121px', whiteSpace: 'normal', wordWrap: 'break-word' }} className="text-lightBrown100 text-xs font-medium">
                    {formatDateDmy(row.createdAt)} <br/>
                    <TimeComponent time={row.createdAt} />
                </p>
            ),
        },
    ];

    return (
        <div className="px-4 py-4 rounded-2xl flex-1 bg-white md:overflow-hidden basis-9/12">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-black font-bold text-md md:text-xl">
                Request History
                </h2>
               
            </div>
            <div className=" flex items-center gap-2 border bg-[#F5F6F7] border-[#F5F6F7] rounded-[28px] sm:max-w-[328px]">
                                <img src={search} alt="search" />
                                <input
    type="text"
    placeholder="Search"
    value={searchQuery}
    onChange={handleSearchChange}
    className="w-full font-medium text-[14px] bg-transparent text-[#534341] border-transparent outline-none focus:ring-0"
/>

                            </div>
            {isLoading ? (
                <div className="flex justify-center items-center py-64">
                    <Lottie
                        animationData={loadingState}
                        loop={true}
                        className="lottie"
                    />
                </div>
            ) : emptyState ? (
                <div className="flex flex-col justify-center items-center mt-36">
                    <img src={Image} alt="empty" />
                    <p className="mt-4 text-sm font-medium text-dark100">
                    No ambulances are currently available or listed.
                    </p>
                </div>
            ) : !Requests?.length ? (
                <FleetEmptyState />
            ) :(
                <div className="mt-[13px] border-t-[1px] flex flex-col px-4 py-3 overflow-x-scroll">
                    <Table
                        rows={paginatedRows}
                        headers={requestHeaders}
                        allowRowClick
                        showHead={true}
                        // onRowClick={(row: rowType) =>
                        //     navigate(`${hospitalRoutes.patientRequest}${row._id}`)
                        // }
                    />
                    <Pagination />
                </div>
            )}

           
        </div>
    );
};

export default RequestHistory;
