import { Add, Edit2 } from 'iconsax-react'
import React, { Fragment, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import Card from '../../dashboard/DashboardCard'
import Amblance from '../../../Images/truck.svg';
import RecentAmbulance from './RecentAmbulance'
import { hospitalRoutes } from '../../../utils/routeNames'
import Modal from '../../../reusable/Modal'
import SmallModal from '../../../reusable/SmallModal';
import { standardAmountFormat } from '../../../utils/helpers';
import { useHospitalAuthStore } from '../../../store/hospital/auth'
import { TextField } from '@mui/material'
import hospitalService from '../../../services/hospitalService'
import { useMutation, useQuery } from 'react-query';
import Lottie from 'lottie-react';
import loadingState from '../../../Images/loading.json';
import { toast } from 'react-toastify'
function Ambulance() {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [showSmallModal, setShowSmallModal] = useState(false);
    const [editingService, setEditingService] = useState(null); // Track which service is being edited
    const [buttonEnabled, setButton] = useState(false);
    const currency = useHospitalAuthStore((state) => state.hospital.currency);
    
    const [modalFormData, setModalFormData] = useState({
        BLS: '',
        ALS: '',
        newAmount: '', 
    });
    const { data, isLoading,isError } = useQuery(['fleetCount'], () =>
        hospitalService.getFleetCount()
    );
    const { data:typesData, isLoading:typesLoading,isError:typesError,refetch } = useQuery(['ambulanceTypes'], () =>
        hospitalService.getAmbulanceTypes()
    );
   
   const ambulancetype= typesData?.ambulanceTypes
   const ambulanceType1 = ambulancetype ? ambulancetype[0] : {};
   const ambulanceType2 = ambulancetype ? ambulancetype[1] : {};
    const handleShowModal = () => {
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
    }

    const handleCloseSmallModal = () => {
        setShowSmallModal(false);
    }

    const handleEditClick = (service) => {
        setEditingService(service);
        setModalFormData((prevData) => ({
            ...prevData,
            newAmount: prevData[service] || '', // Pre-fill with existing amount if available
        }));
        setShowSmallModal(true);
    }

    const handleAmountChange = (e) => {
        const { value } = e.target;
        setModalFormData((prevData) => ({
            ...prevData,
            newAmount: value,
        }));
        setButton(!!value);
    }
    const mutation = useMutation((payload: any) =>
        hospitalService.updateAmbulanceType(payload)
    );
    useEffect(() => {
        if (mutation.isSuccess) {
            
            console.log('success', mutation.data)
            refetch()
            setTimeout(()=>{

                setShowSmallModal(false);
                setShowModal(false);
            },1000)
            toast.success('Ambulance updated Successfully')
            
        }
    }, [mutation.isSuccess, mutation.data, refetch]);
    useEffect(() => {
        if (mutation.isError) {
            
            let err: any = mutation?.error;
            
            console.log(err.response.data.error.message);
            toast.error(err.response.data.error.message)
        }
    }, [mutation?.error, mutation.isError]);
    
   
    // const handleSaveModal = () => {
    //     if (editingService) {
    //         setModalFormData((prevData) => {
    //             const updatedData = {
    //                 ...prevData,
    //                 [editingService]: prevData.newAmount,
    //             };
    
                
    //             const payload = {
    //                 basicLifeSupport: {
    //                     name: "BLS",
    //                     amount: updatedData.BLS || 0,
    //                 },
    //                 advancedLifeSupport: {
    //                     name: "ALS",
    //                     amount: updatedData.ALS || 0,
    //                 }
    //             };
    
    //             // Ensure either BLS or ALS is set before sending the data
    //             if (payload.basicLifeSupport.amount || payload.advancedLifeSupport.amount) {
    //                 mutation.mutate(payload);
    //                 setShowSmallModal(false);
    //             setShowModal(false);
    //             } else {
    //                 toast.error('Please set an amount for either BLS or ALS.');
    //             }
    
    //             return updatedData;
    //         });
    //     }
    // };
    
    const handleSaveModal = () => {
        setButton(false)
        if (editingService) {
            setModalFormData((prevData) => {
                const updatedData = {
                    ...prevData,
                    [editingService]: prevData.newAmount,
                };
    
                const payload = {
                    name: editingService === 'BLS' ? 'BLS' : 'ALS',
                    amount: updatedData[editingService] || 0,
                };
    
                // Ensure an amount is set before sending the data
                if (payload.amount) {
                    mutation.mutate(payload);
                    
                } else {
                    toast.error(`Please set an amount for ${payload.name}.`);
                }
    
                return updatedData;
            });
        }
    };
    
    const handleSaveAmount = () => {
        if (editingService) {
            setModalFormData((prevData) => {
                const updatedData = {
                    ...prevData,
                    [editingService]: prevData.newAmount,
                };

                

                return updatedData;
            });
        }
        setShowSmallModal(false);
    }
   
    const fleetData = {
        title: 'Fleet ',
        firstData: 'Available',
        firstDataValue: data?.available || 0,
        secondData: 'On duty',
        secondDataValue: data?.onDuty || 0,
        thirdData: 'Not Available',
        thirdDataValue: data?.notAvailable || 0,
        fourthData: 'Total',
        fourthDataValue: data?.total || 0,
        iconSrc: Amblance,
    };

    const ambulanceData = {
        title: 'Types of Ambulance ',
        firstData:  <>
        {ambulanceType1?.name} <br/>
        {standardAmountFormat(ambulanceType1?.amount, currency) || 0}
    </>,
        firstDataValue:ambulanceType1?.fleetCount || 0,
        secondData:  <>
        {ambulanceType2?.name} <br/>
        {standardAmountFormat(ambulanceType2?.amount, currency) || 0}
    </>,
        secondDataValue: ambulanceType2?.fleetCount || 0,
        iconSrc: Amblance,
    };
  return (
    <Fragment>
        <div className="md:px-8 px-1 mt-6 bg-[#F6F6F7] ">
            <div className='flex w-full mb-8 justify-between items-center'>
                    <div className="flex w-full">
                       
                           
                            <h2 className="font-semibold text-base md:text-[28px] text-lightBrown">
                            Ambulance 
                            </h2>
                       
                        </div>
                        <div className='flex items-center  w-full gap-2 ml-auto justify-end'>

                        
                        <button
                            className="text-Tmred bg-white rounded-lg flex flex-row items-center gap-3 text-center max-w-[302px] py-2 px-4  border  border-Tmred"
                            onClick={handleShowModal}
                        >
                        <Edit2 size="24" color="#C00010"/>
                            <span className="leading-[1.2rem]">Edit Type</span>
                        </button>
                        <button
                            onClick={()=>navigate(hospitalRoutes.addAmbulance)}
                            className="text-white font-light bg-[#C00010] rounded-[8px] px-3  sm:px-[16px] flex items-center gap-2 text-[16px] w-[209px] h-[40px]"
                        >
                            <Add size="24" color="#ffff"/>
                            <span className="leading-[1.2rem]">Add Ambulance</span>
                        </button>
                        </div>
            </div>
            <div className="flex md:flex-row flex-col items-center gap-6  w-full my-4">
                     {isLoading?<>
                        <div className="flex justify-center items-center py-32">
                {' '}
                <Lottie
                    animationData={loadingState}
                    loop={true}
                    className="lottie"
                />
            </div>
            </>:isError?<><div className="flex justify-center items-center">
                Error loading count, please refresh your page
            </div>
            </>:<>
            <Card {...fleetData} />
            </>}
            {typesLoading?<>
                        <div className="flex justify-center items-center py-32">
                {' '}
                <Lottie
                    animationData={loadingState}
                    loop={true}
                    className="lottie"
                />
            </div>
            </>:typesError?<><div className="flex justify-center items-center">
                Error loading count, please refresh your page
            </div>
            </>:<>
            <Card {...ambulanceData} />
            </>}
                        
                     
                       
                    </div>     
                    <RecentAmbulance/>
        </div>
        <Modal
                isVisible={showModal}
                onClose={handleCloseModal}
                onSave={handleSaveModal}
                buttonEnabled={buttonEnabled}
                setButton={setButton}
                saveText="Save"
                closeText="Cancel"
                showCloseButtonBorder={false}
            >
                <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2 flex flex-col ">
                    <h2 className="font-semibold text-2xl mb-5 w-full">
                    Type of Ambulance
                    </h2>
                    <p className="text-[14px] font-normal text-[#534341] mb-6">
                        Select a type to edit
                    </p>
                    <div className='flex items-center w-full justify-between lg:w-[434px]'>
                        <p className="text-[16px] font-normal text-[#534341]">
                            Basic Life Support (BLS)
                            <br />
                            <span className='text-[14px]'>{modalFormData.BLS ? `${standardAmountFormat(modalFormData.BLS,currency)}` : 'No amount Set'}</span>
                        </p>
                        <button
                            className='text-[#C00010] text-[14px] font-medium underline cursor-pointer'
                            onClick={() => handleEditClick('BLS')}
                        >
                            Edit
                        </button>
                    </div>
                    <div className='flex items-center w-full justify-between mt-6'>
                        <p className="text-[16px] font-normal text-[#534341]">
                            Advanced Life Support (ALS)
                            <br />
                            <span className='text-[14px]'>{modalFormData.ALS ? `${standardAmountFormat(modalFormData.ALS,currency)}` : 'No amount Set'}</span>
                        </p>
                        <button
                            className='text-[#C00010] text-[14px] font-medium underline cursor-pointer'
                            onClick={() => handleEditClick('ALS')}
                        >
                            Edit
                        </button>
                    </div>
                </div>
            </Modal>
            <SmallModal
                isVisible={showSmallModal}
                onClose={handleCloseSmallModal}
                onSave={handleSaveAmount}
                buttonEnabled={buttonEnabled}
                setButton={setButton}
                saveText="Save"
                closeText={true}
                showCloseButtonBorder={true}
                title={`Editing ${editingService === 'BLS' ? 'Basic Life Support' : 'Advanced Life Support'}`}
            >
                <div className="flex flex-col gap-5 sm:w-[406px] py-5">
                    <p className='text-[14px] font-normal text-[#534341]'>Change amount for this type</p>
                    <TextField
                        className="w-full"
                        variant="outlined"
                        label="Amount"
                        name="amount"
                        value={modalFormData.newAmount || ''}
                        onChange={handleAmountChange}
                        type='number'
                    />
                </div>
            </SmallModal>
    </Fragment>
  )
}

export default Ambulance