import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AllRoutes from './routes';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ModalProvider } from './contexts/ModalContext';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';

const theme = createTheme({
    palette: {
        primary: {
            main: '#C00010',
        },
    },
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          refetchOnReconnect: false,
          retry: false,
        },
      },
});

const router = createBrowserRouter([...AllRoutes]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <QueryClientProvider client={queryClient}>
        <ModalProvider>
            <ThemeProvider theme={theme}>
                <RouterProvider router={router} />
                <ToastContainer /> 
            </ThemeProvider>
        </ModalProvider>
    </QueryClientProvider>
);

reportWebVitals();
