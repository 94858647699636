import { useEffect, useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import Tab from '../Tab';
import Tabs from '../Tabs';
import { useQuery } from 'react-query';
import hospitalService from '../../../services/hospitalService';
import Lottie from 'lottie-react';
import loadingState from '../../../Images/loading.json';


import back from '../../../Images/back.png';
import ReqData from './ReqData';

const RequestHistories = () => {
   
    const [Ambulance, setAmbulance] = useState<any[]>([]);
    const [pendingAmbulance, setPendingAmbulance] = useState<any[]>([]);
    const [cancelledAmbulance, setCancelledAmbulance] = useState<any[]>([]);
    const [completedAmbulance, setCompletedAmbulance] = useState<any[]>([]);
    const [acceptedAmbulance, setAcceptedAmbulance] = useState<any[]>([]);
    const [AmbulanceData, setAmbulanceData] = useState<any>([]);
    const [filteredAmbulanceData, setFilteredAmbulanceData] = useState('');
  
    const navigate = useNavigate();

    const { data, isError, isLoading } = useQuery(
        'Ambulance',
        hospitalService.getRequestCount
    );
    if (isError){
        console.log("error", isError)
    }

    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (searchTerm: string) => {
        setSearchQuery(searchTerm);
    };

    const handleFilterQueryParam = (queryParams: string) => {
        hospitalService
            .filterRequest(queryParams)
            .then((res) => {
                setFilteredAmbulanceData(res);
            })
            .catch((err) => {
                // toast.error()
                console.log(err);
            });
    };

    useEffect(() => {
        setAmbulanceData(data?.ambulanceRequests);
        console.log(data?.ambulanceRequests)
        if (filteredAmbulanceData) {
            setAmbulanceData(filteredAmbulanceData);
        }
    }, [data, filteredAmbulanceData]);

    useEffect(() => {
         if (AmbulanceData) {
           
                setAmbulance(AmbulanceData);
                console.log(AmbulanceData)
                const pending = AmbulanceData?.filter(
                    (row: any) => row.status === 'pending'
                );
                setPendingAmbulance(pending);
                const cancelled = AmbulanceData?.filter(
                    (row: any) => row.status === 'cancelled'
                );
                setCancelledAmbulance(cancelled);
                const completed = AmbulanceData?.filter(
                    (row: any) => row.status === 'completed'
                );
                setCompletedAmbulance(completed);
                const accepted= AmbulanceData?.filter(
                    (row: any) => row.status === 'accepted'
                );
                setAcceptedAmbulance(accepted);
            }
        
    }, [AmbulanceData]);


    

    const tabs = [
        {
            title: 'All',
            content: (
                <Tab>
                    <ReqData
                        fleet={Ambulance}
                        searchQuery={searchQuery}
                    />
                </Tab>
            ),
            count: Ambulance?.length || 0,
        },
        {
            title: 'Pending',
            content: (
                <Tab>
                    <ReqData
                         fleet={pendingAmbulance}
                        searchQuery={searchQuery}
                    />
                </Tab>
            ),
            count: pendingAmbulance?.length || 0,
        },
        {
            title: 'Cancelled',
            content: (
                <Tab>
                    <ReqData
                         fleet={cancelledAmbulance}
                        searchQuery={searchQuery}
                    />
                </Tab>
            ),
            count: cancelledAmbulance?.length || 0,
        },
        {
            title: 'Completed',
            content: (
                <Tab>
                    <ReqData
                      fleet={completedAmbulance}
                        searchQuery={searchQuery}
                    />
                </Tab>
            ),
            count: completedAmbulance?.length || 0,
        },
        {
            title: 'Accepted',
            content: (
                <Tab>
                    <ReqData
                      fleet={acceptedAmbulance}
                        searchQuery={searchQuery}
                    />
                </Tab>
            ),
            count: acceptedAmbulance?.length || 0,
        },
    ];
  

    const goBack = () => {
        navigate(-1);
    };
    return (
        <div className="mt-7 sm:mt-0 w-full bg-[#F5F5F5] px-1">
            <div className="flex gap-2 items-center  py-3">
                    <button onClick={goBack}>
                        <img src={back} alt="" />
                    </button>
                    <h3 className="font-bold sm:text-[28px] text-[20px]">
                    Viewing All Requests
                    </h3>
                </div>
            <div className="px-4 py-4 font-700 text-xl text-black border-transparent bg-[#FFFFFF]  p-2 m-4  rounded-2xl overflow-x-auto sm:overflow-hidden">
                {isLoading ? (
                    <div className="flex justify-center items-center py-64">
                        <Lottie
                            animationData={loadingState}
                            loop={true}
                            className="lottie"
                        />
                    </div>
                ) : (
                    <Tabs
                        tabs={tabs}
                        onSearchChange={handleSearchChange}
                        onFilter={handleFilterQueryParam}
                    />
                )}
            </div>
            
        </div>
    );
};

export default RequestHistories;
