import HospitalRoute from './HospitalRoute';
import ErrorPage from '../pages/ErrorPage';
import Error404 from '../pages/Error404';
import HealthcareProviderID from '../pages/HealthcareProviderID';
import Landing from '../pages/Landing';
import PharmacyRoute from './PharmacyRoute';

const AllRoutes = [
    {
        path: '/',
        element: <HealthcareProviderID />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/landing/:slug',
        element: <Landing />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/hospital/',
        errorElement: <ErrorPage />,
        children: [...HospitalRoute],
    },
    {
        path: '/pharmacy/',
        errorElement: <ErrorPage />,
        children: [...PharmacyRoute],
    },
    {
        path: '*',
        element: <Error404 />,
        errorElement: <ErrorPage />,
    },
];
export default AllRoutes;
