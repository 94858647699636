import { useState, useEffect } from 'react';
import { SlArrowLeft } from 'react-icons/sl';
import { Link, useParams } from 'react-router-dom';
import ManageAdminModal from './Modals/ManageAdminModal';
// import AdmProfile from './AdmProfile';
import LogActivity from './LogActivity';
import hospitalService from '../../services/hospitalService';
import { useQuery } from 'react-query';
import { formatDateDmy } from '../../utils/helpers';
import Lottie from 'lottie-react';
import loadingState from '../../Images/loading.json';
const AdminProfile = () => {
    const { id } = useParams();
    const [isActive, setIsActive] = useState(true);
    const [manageAdminModal, setManageAdminModal] = useState(false);
    const [adminProfile, setAdminProfile] = useState<any>({});
    const [activityLog, setActivityLog] = useState<any[]>([]);

    const { data, isLoading, isError, refetch } = useQuery(
        ['admin', id],
        () =>
            id ? hospitalService.getSingleUser(id) : Promise.reject('No ID'),
        {
            enabled: !!id, // Only fetch when id is available
        }
    );

    useEffect(() => {
        if (data) {
            setAdminProfile(data?.user);
            setActivityLog(data?.activityLogs);
        }
    }, [data]);

    const handleModal = () => {
        setManageAdminModal(true);
    };
    const handleCloseModal = () => {
        setManageAdminModal(false);
    };
    const handleToggle = () => {
        setIsActive(!isActive);
    };

  

    return (
        <div className="mx-6 mt-6">
            <Link
                to="/hospital/user/admin"
                className="flex items-center  cursor-pointer"
            >
                <SlArrowLeft className="m-2" />
                <h2 className="font-semibold text-base md:text-[28px] text-lightBrown">
                    Admin Profile
                </h2>
            </Link>
            {
                isLoading ? <><div className="flex justify-center items-center py-64">
                {' '}
                <Lottie
                    animationData={loadingState}
                    loop={true}
                    className="lottie"
                />
            </div>
                </>: isError ? <>
                <div className="flex justify-center items-center py-64">
                <div>
                Error fetching admin user
                </div>
            </div>
                </>:<>
                <div className="flex flex-col lg:flex-row lg:space-x-6 mt-8">
                <div className="basis-3/5">
                    <div className="flex-1 bg-white p-2 sm:p-4 rounded-2xl mb-10 lg-mb-0">
                        <div>
                            {adminProfile.status === 'suspended' && (
                                <div className="bg-[#FFB4AB] rounded-lg text-[#201A19] text-[22px] p-2 w-full mx-auto my-4 text-center">
                                    Admin Suspended
                                </div>
                            )}
                        </div>
                        <div className="flex items-center bg-lightPink p-1 sm:p-4 space-x-1 sm:space-x-3 rounded-xl mb-6">
                            <div className="rounded-full w-20 max-w-[100%] h-20">
                                <img
                                    src={adminProfile.image}
                                    alt="adminImage"
                                    className="max-w-[100%] w-[100%] h-[100%] rounded-full object-cover "
                                />
                            </div>
                            <div>
                                <h2 className="sm:text-2xl text-sm font-medium text-lightBrown">
                                    {/* {adminProfile.salutation} {adminProfile.firstName} {adminProfile.lastName} */}
                                    {`${
                                        adminProfile.salutation &&
                                        adminProfile.salutation !== 'undefined'
                                            ? adminProfile.salutation + ' '
                                            : ''
                                    }${adminProfile.firstName} ${
                                        adminProfile.lastName
                                    }`}
                                </h2>
                                <p className="sm:text-sm text-xs font-normal text-dark100 ">
                                    {adminProfile.email}
                                </p>
                                <p className="sm:text-sm text-xs capitalize font-normal text-lightBrown">
                                    {adminProfile.userType}
                                </p>
                            </div>
                        </div>
                        <div className="flex lg:flex-col xl:flex-row flex-col sm:flex-row gap-3">
                            <div>
                                <h2 className="text-base sm:text-[22px] font-medium text-lightBrown">
                                    Personal information
                                </h2>
                                <div className="my-6">
                                    <h2 className="text-sm font-normal text-lightBrown montFont">
                                        Gender
                                    </h2>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {adminProfile.gender || '-'}
                                    </p>
                                </div>
                                <div>
                                    <h2 className="text-sm font-normal text-lightBrown montFont">
                                        Phone Number
                                    </h2>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {adminProfile.phoneNumber || '-'}
                                    </p>
                                </div>
                                <div className="my-6">
                                    <h2 className="text-sm font-normal text-lightBrown montFont">
                                        City (optional)
                                    </h2>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {adminProfile.city || '-'}
                                    </p>
                                </div>
                                <div>
                                    <h2 className="text-sm font-normal text-lightBrown montFont">
                                        State (optional)
                                    </h2>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {adminProfile.state || '-'}
                                    </p>
                                </div>
                                <div className="mt-6">
                                    <h2 className="text-sm font-normal text-lightBrown montFont">
                                        Address (optional)
                                    </h2>
                                    <p className="text-[12px] font-medium text-lightBrown100">
                                        {adminProfile.address || '-'}
                                    </p>
                                </div>
                            </div>
                            <div className="mt-6 sm:mt-0 lg:mt-6 xl:mt-0">
                                <h2 className=" text-base sm:text-[22px] font-medium text-lightBrown">
                                    Professional information
                                </h2>

                                <div>
                                    <h2 className="text-sm font-normal text-lightBrown montFont mt-4">
                                        MDCN License Number
                                    </h2>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {adminProfile.licenseNumber || '-'}
                                    </p>
                                </div>
                                <div className="mt-6 mb-3">
                                    <h2 className="text-sm font-normal text-lightBrown montFont mb-2">
                                        Specialty
                                    </h2>
                                    {adminProfile?.specialties?.map(
                                        (specialty: any) => (
                                            <button
                                                key={specialty._id}
                                                type="button"
                                                className="text-xs font-medium text-brown200 mr-1 mb-1 border-[1px] border-brown200 py-[6px] px-3 rounded-lg"
                                            >
                                                {specialty.name
                                                    ? specialty.name
                                                    : ''}
                                            </button>
                                        )
                                    )}
                                </div>
                                <div>
                                    <h2 className="text-sm font-normal text-lightBrown montFont">
                                        User Group
                                    </h2>
                                    <div className="flex gap-1 flex-wrap mt-5">
                                        {adminProfile?.permissions?.map(
                                            (permission, index) => (
                                                <button
                                                    type="button"
                                                    key={index}
                                                    className="text-xs font-medium text-brown200 border-[1px] border-brown200 py-[6px] px-3 rounded-lg"
                                                >
                                                    {permission.trim()}
                                                </button>
                                            )
                                        )}
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <h2 className="text-sm font-normal text-lightBrown montFont">
                                        Date Registered
                                    </h2>
                                    <p className="text-sm font-medium text-lightBrown100">
                                        {formatDateDmy(adminProfile.createdAt)}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {adminProfile.status !== 'suspended' && (
                            <div className="mt-8">
                                <h2 className="text-sm text-lightBrown font-normal montFont">
                                    Availability
                                </h2>
                                <div className="flex max-w-[322px] justify-between items-center mt-2">
                                    <span
                                        className={`text-base text-lightBrown font-normal ${
                                            isActive
                                                ? 'text-base '
                                                : 'text-base'
                                        }`}
                                    >
                                        {adminProfile?.availability}
                                    </span>
                                </div>
                            </div>
                        )}
                        <div className="flex justify-end space-x-6 py-3 border-t mt-10">
                            <button
                                onClick={handleModal}
                                type="button"
                                className="py-2 px-3"
                            >
                                Manage Admin
                            </button>
                            <button
                                type="submit"
                                className="text-Tmred border-[1px] border-lightBrown100 px-6 py-2 text-base font-normal rounded-lg"
                            >
                                Send Message
                            </button>
                        </div>
                    </div>
                </div>
                {/* <AdmProfile data={entireData} /> */}
            </div>
            <LogActivity activitylog={activityLog} />
            {manageAdminModal && (
                <ManageAdminModal
                    closeModal={handleCloseModal}
                    adminId={adminProfile._id}
                    adminName={`${adminProfile.firstName} ${adminProfile.lastName}`}
                    adminUser={adminProfile}
                    refresh={refetch}
                />
            )}
                </>
            }
            
        </div>
    );
};
export default AdminProfile;
