import MedicalRecordTabs from './MedicalRecordTabs';

const MedicalRecords = ({ tmrNumber, id, preMedicalForm }) => {
    return (
        <div className="max-w-[710px] mb-20 border border-brown100 rounded-2xl p-4">
            <div className="flex items-center py-4 rounded-2xl justify-between px-4 bg-white mb-4">
                <p className="font-semibold text-2xl text-lightBrown">
                    Medical Records
                </p>
                <p className="text-base font-medium">{tmrNumber}</p>
            </div>
            <MedicalRecordTabs
                id={id}
                preMedicalForm={preMedicalForm}
                tmrNum={tmrNumber}
            />
        </div>
    );
};
export default MedicalRecords;
