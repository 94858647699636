import React from 'react'
import DashboardLayout from '../Dashboard/DashboardLayout';
import {Helmet} from 'react-helmet'
import PWallet from '../../../components/PharmacyComponent/Wallet/Pharmacy/PWallet';


const PharmacyWallet = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Pharmacy Wallet
                </title>
            </Helmet>
            <div className="">
                <PWallet />
            </div>
        </DashboardLayout>
    )
}

export default PharmacyWallet