import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import back from '../../../Images/back.png';
import Input from '../../../reusable/Forms/Input';
import { useMutation, useQuery } from 'react-query';
import hospitalService from '../../../services/hospitalService';
import { standardAmountFormat } from '../../../utils/helpers';
import search from '../../../Images/Leading-icon.png';
import Lottie from 'lottie-react';
import loadingState from '../../../Images/loading.json';
import { toast } from 'react-toastify';
import { useHospitalAuthStore } from '../../../store/hospital/auth';

import noStaff from '../../../Images/no-staf.svg';

import Modal from '../../../reusable/LoadingModal';
import { FormControl, InputLabel, MenuItem, Select ,SelectChangeEvent} from '@mui/material';
import { hospitalRoutes } from '../../../utils/routeNames';
const AddAmb = () => {
    const navigate = useNavigate();
    const [buttonEnabled, setButton] = useState(false);
    const [selectedStaffId, setSelectedStaffId] = useState(null);
    const [isloading, setIsLoading] = useState(false);

    const [users, setUsers] = useState<any[]>([]);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

    const [otpModal, setOtpModal] = useState<boolean>(false);

   
    const currency = useHospitalAuthStore((state) => state.hospital.currency);

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [isSaveModalVisible, setSaveModalVisible] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
   

      const handleCheckboxChange = (e) => {
        setIsCheckboxChecked(e.target.checked);
        if (e.target.checked) {
            setFormData({
                ...formData,
                staff_num: formData.phoneNumber
            });
        } else {
            setFormData({
                ...formData,
                staff_num: ''
            });
        }
    };
    
      const handleSaveCancel = () => {
        setSaveModalVisible(false);
      };
    

    const {
        data: usersData,
        isError: isUsersError,
        isLoading: isUsersLoading,
    } = useQuery('staffs', () => hospitalService.getAllUsers());

    useEffect(() => {
        if (usersData) {
            const filteredUsers = usersData.users.filter(
                (user) => user?.userType?.toLowerCase() !== 'superadmin'
            );
            setUsers(filteredUsers);
        }
    }, [usersData]);

    // console.log("All staff:", getUsers)
    const mutation = useMutation((payload:any) =>
        hospitalService.addAmbulance(payload)
    );

    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            console.log('success', mutation.data);
            let payload: any = mutation.data;
            const data = payload.data;
            toast.success(mutation.data?.data.message);
            setTimeout(() => {
                toast.dismiss();
            }, 5000);
            setFormData({
                Recipient_Name: '',
                Amount: '',
                selectedStaff: '',
                staffSearch: '',
                ambulance_id:'',
                staff_num:'',
                ambulance_type:'',
                phoneNumber:'',
                hospitalUser:''
            });
            setButton(false);
            setOtpModal(true);
        }
    }, [mutation.isSuccess, mutation.data]);

    useEffect(() => {
        if (mutation.isError) {
            setIsLoading(false);
            let err: any = mutation.error;
            toast(err.response.data.error.message);
            setTimeout(() => {
                toast.dismiss();
            }, 2000);
        }
    }, [mutation.isError, mutation.error]);

    const handleSaveConfirm = async () => {
        setIsSaving(true);
        try {
            const ambulanceData = {
                licenseNumber: formData.ambulance_id,
                ambulanceType: formData.ambulance_type,
                user:formData.hospitalUser,
                amount: formData.Amount,
                phoneNumber: formData.staff_num,
            };
            await mutation.mutateAsync(ambulanceData); 
            setTimeout(()=>{
                setSaveModalVisible(false);
                navigate(hospitalRoutes.fleetMgmt)
            },2000)
        } catch (error) {
            console.error('Error adding:', error);
        } finally {
            setIsSaving(false);
        }
        console.log('Form Data:', formData);
    };

    const [formData, setFormData] = useState({
        Recipient_Name: '',
        Amount: '',
        selectedStaff: '',
        staffSearch: '',
        ambulance_id:'',
        staff_num:'',
        ambulance_type:'',
        phoneNumber:'',
        hospitalUser:''
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleSelectChange = (e: SelectChangeEvent) => {
        setFormData((prevData: any) => ({
            ...prevData,
            [e.target.name || '']: e.target.value,
        }));
    };
    useEffect(() => {
        if (
            formData.Amount !== '' &&
            formData.Recipient_Name !== '' &&
            formData.ambulance_id !== ''&&
            formData.staff_num !== ''&&
            formData.ambulance_type !== ''
        ) {
            setButton(true);
        }
    }, [formData.Amount, formData.Recipient_Name,  formData.ambulance_id, formData.staff_num, formData.ambulance_type]);

    const filteredStaff = users.filter((staff) => {
        const searchQueryLower = formData.staffSearch.toLowerCase();
        return (
            staff.firstName.toLowerCase().includes(searchQueryLower) ||
            staff.lastName.toLowerCase().includes(searchQueryLower) ||
            staff.userType.toLowerCase().includes(searchQueryLower)
        );
    });

    const handleStaffSearchChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const searchQuery = e.target.value.toLowerCase();
        setSearchTerm(searchQuery);

        setFormData((prevData: any) => ({
            ...prevData,
            staffSearch: searchQuery,
        }));
    };
    const handlesubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // Show the modal instead of directly saving
        setSaveModalVisible(true);
    };
    
    // const handleSaveConfirm = async () => {
    //     setIsSaving(true);
        
    //     // Log the form data to the console
    //     console.log('Form Data:', formData);
    
        
    //     try {
    //         const selectedStaff = users.find(
    //             (staff) => staff._id === selectedStaffId
    //         );
    //         const paymentData = {
    //             amount: formData.Amount,
    //             hospitalUserId: selectedStaff._id,
    //         };
    //         await mutation.mutateAsync({ data: paymentData });
    //     } catch (error) {
    //         console.error('Error saving:', error);
    //     } finally {
    //         setIsSaving(false);
    //         setSaveModalVisible(false); // Close the modal
    //     }
    // };
    
    // const handlesubmit = async (e: React.FormEvent) => {
    //     e.preventDefault();
    //     setButton(false);
    //     try {
    //         setIsLoading(true);
    //         const selectedStaff = users.find(
    //             (staff) => staff._id === selectedStaffId
    //         );
    //         const paymentData = {
    //             amount: formData.Amount,
    //             hospitalUserId: selectedStaff._id,
    //         };
    //         await mutation.mutateAsync({ data: paymentData });
    //     } catch (error) {
    //         console.error('Error sending money:', error);
    //     } finally {
    //         setIsLoading(false);
    //         setSearchTerm('');
    //     }
    // };
    const clearAll = () => {
        setFormData({
            Recipient_Name: '',
            Amount: '',
            selectedStaff: '',
            staffSearch: '',
            ambulance_id: '',
            staff_num: '',
            ambulance_type: '',
            phoneNumber:'',
            hospitalUser:''
        });
        setSelectedStaffId(null);
        setButton(false);
        setSearchTerm('');
    };
    
    
    const goBack = () => {
        navigate(-1);
    };

    

    if (isUsersLoading) {
        return (
            <div className="flex justify-center items-center py-32">
                {' '}
                <Lottie
                    animationData={loadingState}
                    loop={true}
                    className="lottie"
                />
            </div>
        );
    }

    if (isUsersError) {
        return (
            <div className="flex justify-center items-center">
                Error loading staff, please refresh your page
            </div>
        );
    }

    return (
        <div className="w-full flex flex-col md:px-8 py-4 px-2 ">
            <div className="flex gap-2 items-center ">
                <button onClick={goBack}>
                    <img src={back} alt="" />
                </button>
                <h3 className="font-bold sm:text-[28px] text-[20px]">
                    Add Ambulance
                </h3>
            </div>
            <div className="w-full h-full mt-4">
                <div className="flex items-center w-full flex-col gap-8 sm:flex-row max-w-[80%]">
                    <div className="py-4 px-2  flex-1 bg-[#fff] lg:min-h-[661px] rounded-2xl flex items-center flex-col">
                        <div className="mb-4 flex items-center gap-2 rounded w-full">
                            <img src={search} alt="search" />
                            <input
                                type="text"
                                placeholder="Search staff"
                                value={formData.staffSearch}
                                onChange={handleStaffSearchChange}
                                className="w-full font-normal text-[16px] bg-transparent text-[#534341] border-transparent outline-none focus:ring-0"
                            />
                        </div>
                        <div className="max-h-[574px]  overflow-y-auto w-full">
                            {isUsersLoading ? (
                                <div className="flex justify-center items-center py-32">
                                    {' '}
                                    <Lottie
                                        animationData={loadingState}
                                        loop={true}
                                        className="lottie"
                                    />
                                </div>
                            ) : (
                                <>
                                    {filteredStaff?.length === 0 ? (
                                        <div className="flex flex-col items-center">
                                            <img src={noStaff} alt="" />
                                            <p className="text-[16px] text-[#534341] flex">
                                                search not found
                                            </p>
                                        </div>
                                    ) : (
                                        filteredStaff?.map((row, index) => (
                                            <div
                                                key={index}
                                                className={`flex p-2 items-center gap-8 cursor-pointer ${
                                                    selectedStaffId === row._id
                                                        ? 'bg-[#EDE0DE] rounded-tl-2xl rounded-bl-2xl'
                                                        : 'hover:bg-[#EDE0DE] hover:rounded-tl-2xl hover:rounded-bl-2xl'
                                                } justify-between mb-4 font-normal text-[12px] text-[#201A19] border-b-[2px]`}
                                                onClick={() => {
                                                    setSelectedStaffId(row._id);
                                                    setFormData(
                                                        (prevData: any) => ({
                                                            ...prevData,
                                                            selectedStaff:
                                                                prevData.selectedStaff ===
                                                                row._id
                                                                    ? null
                                                                    : row,
                                                            Recipient_Name: `${row.firstName} ${row.lastName}`,
                                                            phoneNumber:row.phoneNumber,
                                                            hospitalUser:row._id
                                                        })
                                                    );
                                                }}
                                                style={{ lineHeight: '24px' }}
                                            >
                                                <div className="flex flex-col">
                                                    <div className="flex items-center gap-x-[10px]">
                                                        <img
                                                            className="object-contain h-[40px] w-[40px] rounded-full"
                                                            style={{
                                                                boxShadow:
                                                                    'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
                                                            }}
                                                            src={row.image}
                                                            alt=""
                                                        />{' '}
                                                        <div>
                                                            <p className="truncate w-32 font-normal text-deep_black text-sm ">
                                                                {`${row.firstName} ${row.lastName}`}
                                                            </p>
                                                            <p className="text-light_gray text-[12px] font-medium">
                                                                {row.userType}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <form
                        action=""
                        onSubmit={handlesubmit}
                        className="flex flex-col gap-3 lg:min-h-[661px]  sm:gap-8 flex-1  p-8 rounded-2xl bg-[#FFFFFF] "
                    >
                        <div className="flex flex-col md:flex-row items-center justify-between">
                            <div className='bg-[#FFF8F7] p-4 rounded-[16px] w-full'>
                                <p className=" font-semibold text-[#201A19] text-center text-sm sm:text-[22px] ">
                                Ambulance’s Details
                                </p>
                                
                            </div>
                        </div>
                        <div className="w-full ">
                            <Input
                                name="ambulance_id"
                                label="Ambulance ID (License plate)"
                                value={formData.ambulance_id}
                                onChange={handleChange}
                                className="w-full border border-[#857371] p-4 rounded-[8px] text-[16px] tracking-wider my-4 mt-10"
                            />
                        </div>
                        <div className="w-full ">
                        <FormControl
                                        fullWidth
                                        sx={{ minWidth: 120 }}
                                        size="small"
                                    >
                                        <InputLabel
                                            id="demo-select-small-label"
                                            htmlFor={'ambulance_type'}
                                        >
                                            {'Type of Ambulance'}
                                        </InputLabel>

                        <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={formData.ambulance_type}
                                        onChange={handleSelectChange}
                                         name="ambulance_type"
                                        label="Type of Ambulance"
                                        className="w-full p-2 rounded-[8px] h-[50px]"
                                    >
                                        <MenuItem value="Advanced Life Support (ALS)">Advanced Life Support (ALS)</MenuItem>
                                        <MenuItem value="Basic Life Support (BLS)">Basic Life Support (BLS)</MenuItem>
                                    </Select>
                                    </FormControl>
                        </div>
                        <div className="w-full">
                            <Input
                                name="Amount"
                                label="Enter Amount"
                                value={formData.Amount}
                                onChange={handleChange}
                                type="number"
                                className="w-full border border-[#857371] p-4 rounded-[8px] text-[16px] tracking-wider my-4 mt-10"
                            />
                        </div>
                        <div className="w-full ">
                            <p className=" font-normal text-deep_black text-sm pb-3">
                            Select a staff to assign to ambulance
                            </p>
                            <Input
                                name="Recipient_Name"
                                label="Staff Name"
                                value={formData.Recipient_Name}
                                onChange={handleChange}
                                disabled={true}
                                className="w-full border border-[#857371] p-4 rounded-[8px] text-[16px] tracking-wider my-4 mt-10"
                            />
                             
                        </div>
                        <div className="w-full">
                        <Input
                                name="staff_num"
                                label="Staff Phone number"
                                value={isCheckboxChecked ? formData.phoneNumber : formData.staff_num} 
                                onChange={handleChange}
                                // disabled={true}
                                className="w-full border border-[#857371] p-4 rounded-[8px] text-[16px] tracking-wider my-4 mt-10"
                            />
                        </div>
                        
                        <div className="my-4 flex items-center gap-3">
                            <input
                                type="checkbox"
                                checked={isCheckboxChecked} 
                                onChange={handleCheckboxChange} 
                                className="h-[16px] w-[16px] mx-2 cursor-pointer"
                                name="remember"
                                id="remember"
                            />

                            <label className="" htmlFor="remember">
                            Use staff default number as the contact for ambulance pickup
                            </label>
                        </div>
                        <div className="py-8 flex items-center gap-2 w-full border-t border-[#FFDAD5]">
                        <button
                                type="button"
                                onClick={()=>clearAll()}
                                className={`w-full  flex justify-center  items-center font-semibold  hover:cursor-not-allowed transition-all duration-500  ${
                                    buttonEnabled
                                        ? 'text-Tmred hover:cursor-pointer '
                                        : 'text-[#1D1B201F] '
                                } `}
                            >
                                {'Clear All'}
                            </button>
                            <button
                                type="submit"
                                className={`w-full h-[40px] flex justify-center rounded-lg items-center text-[#9d9b9b] font-semibold  hover:cursor-not-allowed transition-all duration-500  ${
                                    buttonEnabled
                                        ? 'bg-Tmred text-white hover:cursor-pointer '
                                        : 'bg-[#1D1B201F] '
                                } `}
                            >
                                {isloading ? 'Saving...' : 'Save Ambulance'}
                            </button>
                        </div>
                    </form>
                    <Modal
        isVisible={isSaveModalVisible}
        onClose={handleSaveCancel}
        buttonEnabled={!isSaving}
        isLoading={isSaving}
        loadingText="Saving..."
        setButton={() => {}}
        onSave={handleSaveConfirm}
        saveText="Save"
        closeText={'Cancel'}
        showCloseButtonBorder={true}
      >
        <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2 ">
          <h2 className="font-semibold text-2xl mb-5">Save Ambulance</h2>
          <p className="text-[14px] font-normal text-[#534341] max-w-[382px]">
          Are you sure you want to save this ambulance? 
          </p>
        </div>
      </Modal>
                </div>
            </div>
        </div>
    );
};

export default AddAmb;
