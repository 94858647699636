import React, { Fragment, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import EmptySearch from '../../Images/emptySearch.svg';
// import { billHistory } from '../../constants/upcomingAppointmentData';
import Table from '../../constants/Table';
import 'rc-slider/assets/index.css';
import Lottie from 'lottie-react';
import loadingState from '../../Images/loading.json';
import hospitalService from '../../services/hospitalService';
import { useQuery } from 'react-query';
import TimeComponent, {
    formatDateDmy,
    standardAmountFormat,
} from '../../utils/helpers';
import { hospitalRoutes } from '../../utils/routeNames';
interface rowType {
    _id: string;
    billId: any;
}
const RecentBills = () => {
    const navigate = useNavigate();
    const [isBillHistory, setIsBillHistory] = useState(false);
    const [billHistories, setBillHistories] = useState<any[]>([]);

    const { data, isError, isLoading } = useQuery(
        'bills',
        hospitalService.getBills
    );
    useEffect(() => {
        if (data?.billing) {
            setBillHistories(data?.billing?.slice(0, 10));
            setIsBillHistory(true);
        } else {
            setIsBillHistory(false);
        }
    }, [data]);

    if (isLoading) {
        return (
            <div className="flex justify-center items-center py-32">
                <Lottie
                    animationData={loadingState}
                    loop={true}
                    className="lottie"
                />
            </div>
        );
    }
    if (isError) {
        return <div>Error loading bill, please refresh your page</div>;
    }
    const billHistoryHeader = [
        {
            title: 'S/N',
            render: (row: any) => (
                <div className="py-[6px] text-[12px] flex items-center gap-2 font-medium text-light_gray text-center">
                    <p className="text-xs font-medium">
                        {billHistories?.findIndex(
                            (user: any) => user._id === row._id
                        ) + 1}
                    </p>
                </div>
            ),
        },
        {
            title: 'Bill Id',
            render: (row: any) => <p>{row.billId}</p>,
        },
        {
            title: 'Patient Name',
            render: (row: any) => (
                <>
                    <div>
                        <p className="truncate w-32 font-medium text-lightBrown text-sm ">
                            {`${row.patient?.firstName} ${row.patient?.lastName}`}
                        </p>
                        <p className="text-lightBrown100 text-xs font-medium">
                            {row.patientTmrNumber}
                        </p>
                    </div>
                </>
            ),
        },
        {
            title: 'Phone Number',
            render: (row: any) => <p>{row.patient?.phoneNumber}</p>,
        },

        {
            title: 'Total amount',
            render: (row: any) => (
                <p className="">
                    {standardAmountFormat(row.totalAmount, row.currency)}
                </p>
            ),
            // ${row.totalAmount}
        },
        {
            title: 'Status',
            render: (row: any) => (
                <div
                    className={`flex items-start justify-center p-3 rounded w-fit ${
                        row.status === 'pending'
                            ? 'bg-[#FFDAD5] text-red-500'
                            : 'bg-[#1D1B2014] text-lightBrown'
                    }`}
                >
                    <div
                        className={`w-[6px] h-[6px] rounded-full mr-2 mt-[6px] ${
                            row.status === 'pending'
                                ? 'bg-[#C00010]'
                                : 'bg-[#8BBEA0]'
                        }`}
                    />
                    <p className="text-xs">{row.status}</p>
                </div>
            ),
        },

        {
            title: 'Date Issued',
            render: (row: any) => <p> {formatDateDmy(row.createdAt)}</p>,
        },
        {
            title: 'Time',
            render: (row: any) => (
                <div className="capitalize font-medium text-lightBrown text-xs">
                    {' '}
                    <TimeComponent time={row.createdAt} />
                </div>
            ),
        },
    ];

    return (
        <Fragment>
            <div className="w-full flex flex-col  py-4  ">
                <div className="bg-white p-4 rounded-2xl">
                    <div className="flex flex-col-reverse sm:flex-row items-center justify-between gap-4 w-full px-4">
                        <div className="flex items-center space-x-3">
                            <h2 className="md:text-2xl text-sm font-semibold text-dark">
                                Recent Bills
                            </h2>
                        </div>
                    </div>
                    <div className="mt-[9px] flex flex-col px-4 py-3 overflow-x-auto scrollbar-hide">
                        {isBillHistory && billHistories.length !== 0 ? (
                            <>
                                <Table
                                    // key={billHistories.findIndex()}
                                    rows={billHistories?.slice(0, 5)}
                                    headers={billHistoryHeader}
                                    allowRowClick
                                    showHead={true}
                                    onRowClick={(row: rowType) =>
                                        navigate(
                                            `/hospital/billing/bill-invoice/${row.billId}`
                                        )
                                    }
                                />
                                <div className="flex items-center justify-center w-full my-3 pt-6">
                                    <Link
                                        to={hospitalRoutes.billHistory}
                                        className="w-full sm:w-fit h-[40px] text-[14px] flex items-center text-center justify-center text-[#C00010] border border-[#C00010] py-2 px-6 rounded-lg font-medium"
                                    >
                                        View all Bills
                                    </Link>
                                </div>
                            </>
                        ) : (
                            <div className="flex flex-col items-center justify-center text-center my-[70px] ">
                                <img
                                    src={EmptySearch}
                                    className="w-[12rem]"
                                    alt="empty"
                                />
                                <p className="mt-6 text-base font-medium font-[Montserrat]">
                                    Your Bill History is empty.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default RecentBills;
