import Tabs from './Tabs';
import Lottie from 'lottie-react';
import loadingState from '../../Images/loading.json';
import DocProfileAppointment from './DocProfileAppointment';
import { useEffect, useState } from 'react';

const ActiveState = ({ data, doctorId, isloading }) => {
    const [appointments, setAppointments] = useState<any[]>([]);
    const [pendingAppointment, setPendingAppointment] = useState<any[]>([]);
    const [cancelledAppointment, setCancelledAppointment] = useState<any[]>([]);
    const [completedAppointment, setCompletedAppointment] = useState<any[]>([]);

    useEffect(() => {
        // console.log(data);
        
        if (data?.appointments) {
                const doctorAppointments = data.appointments.filter( appointment => doctorId === appointment.doctorId)

                setAppointments(doctorAppointments);
                const pending = doctorAppointments.filter(
                    (row: any) => row.status === 'pending'
                );
                setPendingAppointment(pending);
                const cancelled = doctorAppointments.filter(
                    (row: any) => row.status === 'cancelled'
                );
                setCancelledAppointment(cancelled);
                const completed = doctorAppointments.filter(
                    (row: any) => row.status === 'completed'
                );
                setCompletedAppointment(completed);
            
            }
    }, [data]);
    const tabs = [
        {
            title: 'All',
            content: (
                <>
                    <DocProfileAppointment appointment={appointments} />{' '}
                </>
            ),
            count: appointments?.length,
        },
        {
            title: 'Pending',
            content: (
                <>
                    <DocProfileAppointment appointment={appointments} />
                </>
            ),
            count: pendingAppointment?.length,
        },
        {
            title: 'Cancelled',
            content: (
                <>
                    <DocProfileAppointment appointment={appointments} />{' '}
                </>
            ),
            count: cancelledAppointment?.length,
        },
        {
            title: 'Completed',
            content: (
                <>
                    <DocProfileAppointment appointment={appointments} />
                </>
            ),
            count: completedAppointment?.length,
        },
    ];

    return (
        <div className="mt-7 sm:mt-0  ">
            <p className="font-semibold sm:text-2xl text-[20px]">
                Appointments
            </p>{' '}
            <div className="px-4 py-4 font-700 text-xl text-black border-transparent bg-[#FFFFFF]  p-2 m-4  rounded-2xl overflow-x-auto sm:overflow-hidden">
                {isloading ? (
                    <div className="flex justify-center items-center py-64">
                        <Lottie
                            animationData={loadingState}
                            loop={true}
                            className="lottie"
                        />
                    </div>
                ) : (
                    <Tabs tabs={tabs} />
                )}
            </div>
        </div>
    );
};

export default ActiveState;
