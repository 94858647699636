import React, { Fragment } from 'react';
import { useState } from 'react';
// import AppointmentSchedulling from './AppointmentSchedulling';

import Media from './Media';
import { Link } from 'react-router-dom';


import { formatDateDmy } from '../../../../utils/helpers';

import PrivateMode from './PrivateMode';
import BankAccount from './BankAccount';
import SubscriptionPlanTab from './SubscriptionPlanTab';
import { usePharmacyAuthStore } from '../../../../store/pharmacy/auth';
import { pharmacyRoutes } from '../../../../utils/pharmacyRouteNames';

const Profile = () => {
    const pharmacyAuthStore = usePharmacyAuthStore((state) => state.pharmacy);
    const Tabs = [
        'Profile',
        // 'Service Information',
        // 'Services',
        'Media',
        'Bank Account',
        'Subscription Plan',
    ];
    const [activeTab, setActiveTab] = useState('Profile');
    const [isActive, setIsActive] = useState(pharmacyAuthStore.privateMode);
    const [showPrivateMode, setShowPrivateMode] = useState(false);

    const handlePrivateMode = (privateMode) => {
        setIsActive(privateMode);
    };
    // const handleShowPrivateMode = () => {
    //     setShowPrivateMode(true);
    // };
    const handleClosePrivateMode = () => {
        setShowPrivateMode(false);
    };
    console.log(isActive);

    const handleTab = (tab) => {
        setActiveTab(tab);
    };
    const coordinates = pharmacyAuthStore.location?.coordinates;
    ;
    return (
        <Fragment>
            <div className="px-2 md:px-8 py-6 ">
                {/*Title */}
                <div className="flex justify-between mb-[24px] items-center">
                    <h2 className="text-[28px] font-semibold">Settings</h2>
                </div>

                <div className="bg-white px-8 py-[18px] rounded-lg">
                    <div className="border-b-[1px]">
                        <div className=" flex justify-between">
                            {Tabs?.map((tab, index) => (
                                <button
                                    key={index}
                                    className={`text-[14px] px-[16px] py-[8px] hover:text-Tmred ${
                                        activeTab === tab
                                            ? 'border-b-[2px] border-Tmred'
                                            : ''
                                    }`}
                                    onClick={() => handleTab(tab)}
                                >
                                    {tab}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="mt-6">
                        {/* Body */}
                        {activeTab === 'Profile' && (
                            <div className="w-full max-w-[742px]">
                                <div className="flex flex-col lg:flex-row lg:space-x-6 mt-8">
                                    <div className="">
                                        <div className="flex items-center justify-between bg-lightPink p-1  sm:p-4  rounded-xl mb-6">
                                            <div className="flex items-center space-x-1 sm:space-x-3 ">
                                                <img
                                                    src={pharmacyAuthStore.logo}
                                                    alt="hospital logo"
                                                    className="rounded-full h-[98px] w-[98px]"
                                                />
                                                <div>
                                                    <h6 className="sm:text-2xl text-sm font-medium text-lightBrown">
                                                        {pharmacyAuthStore.name}
                                                    </h6>
                                                    <p className="sm:text-sm text-xs my-2 font-medium text-lightBrown ">
                                                        {
                                                            pharmacyAuthStore.email
                                                        }
                                                    </p>
                                                    <p className="sm:text-sm text-xs font-normal text-lightBrown">
                                                        {
                                                            pharmacyAuthStore.tmrId
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="mr-4 text-center">
                                                <p className="text-sm text-Tmred font-medium">
                                                    Tag line
                                                </p>
                                                <p className="mt-2 italic text-xs text-dark200">
                                                    {pharmacyAuthStore.tagline}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex xl:space-x-32 items-start  md:flex-col lg:flex-row flex-col sm:flex-row">
                                            <div className='flex-1'>
                                                <h6 className="text-base sm:text-[22px] font-medium text-lightBrown">
                                                    Contact information
                                                </h6>
                                                <div className="my-6">
                                                    <p className="text-sm font-normal text-lightBrown">
                                                        Phone Number
                                                    </p>
                                                    <p className="text-sm font-medium text-lightBrown100">
                                                        {pharmacyAuthStore.phoneNumber
                                                            ? pharmacyAuthStore.phoneNumber
                                                            : '-'}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-sm font-normal text-lightBrown">
                                                        Country
                                                    </p>
                                                    <p className="text-sm font-medium text-lightBrown100">
                                                        {pharmacyAuthStore.country
                                                            ? pharmacyAuthStore.country
                                                            : '-'}
                                                    </p>
                                                </div>
                                                <div className="my-6">
                                                    <p className="text-sm font-normal text-lightBrown">
                                                        State
                                                    </p>
                                                    <p className="text-sm font-medium text-lightBrown100">
                                                        {pharmacyAuthStore.state
                                                            ? pharmacyAuthStore.state
                                                            : '-'}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-sm font-normal text-lightBrown">
                                                        City (optional)
                                                    </p>
                                                    <p className="text-sm font-medium text-lightBrown100">
                                                        {pharmacyAuthStore.city
                                                            ? pharmacyAuthStore.city
                                                            : '-'}
                                                    </p>
                                                </div>

                                                <div className="mt-6">
                                                    <p className="text-sm font-normal text-lightBrown">
                                                        Address (optional)
                                                    </p>
                                                    <p className="text-sm font-medium text-lightBrown100 max-w-[310px]">
                                                        {pharmacyAuthStore.address
                                                            ? pharmacyAuthStore.address
                                                            : '-'}
                                                    </p>
                                                </div>
                                                <div className="mt-6">
                                                    <p className="text-sm font-normal text-lightBrown">
                                                        Coordinates (Long. &
                                                        Lat.)
                                                    </p>
                                                    <p className="text-sm font-medium text-lightBrown100 max-w-[310px]">
                                                        {coordinates
                                                            ? `${coordinates[0]},  ${coordinates[1]}`
                                                            : '-'}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="mt-6 sm:mt-0 flex-1 md:mt-6 lg:mt-0">
                                                <h6 className=" text-base sm:text-[22px] font-medium text-lightBrown">
                                                    Professional information
                                                </h6>

                                                <div className="my-6">
                                                    <p className="text-sm font-normal text-lightBrown">
                                                        MDCN License Number
                                                    </p>
                                                    <p className="text-sm font-medium text-lightBrown100">
                                                        {pharmacyAuthStore.licenseNumber
                                                            ? pharmacyAuthStore.licenseNumber
                                                            : '-'}
                                                    </p>
                                                </div>

                                                
                                                <div className="mt-6">
                                                    <p className="text-sm font-normal text-lightBrown">
                                                        Date Registered
                                                    </p>
                                                    <p className="text-sm font-medium text-lightBrown100">
                                                        {formatDateDmy(
                                                            pharmacyAuthStore.createdAt
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="mt-8 bg-lightGrayX p-4 rounded-xl ">
                                            <h2 className="text-sm text-lightBrown font-normal">
                                                Visibility
                                            </h2>
                                            <div className="flex max-w-[322px] justify-between items-center mt-2 gap-28  ">
                                                <span className="text-base text-lightBrown font-normal ">
                                                    Private Mode
                                                </span>
                                                <button
                                                    onClick={
                                                        handleShowPrivateMode
                                                    }
                                                >
                                                    {isActive ? (
                                                        <Active />
                                                    ) : (
                                                        <Inactive />
                                                    )}
                                                </button>
                                            </div>
                                        </div> */}

                                        <div className="flex justify-end space-x-6 py-3 border-t mt-10">
                                            {/* <button
                                            type="button"
                                            className="text-Tmred border-[1px] border-lightBrown100 px-6 py-2 text-base font-normal rounded-lg"
                                        >
                                            Change Password
                                        </button> */}
                                            <button
                                                type="submit"
                                                className="text-Tmred border-[1px] border-lightBrown100 px-6 py-2 text-base font-normal rounded-lg"
                                            >
                                                {' '}
                                                <Link
                                                    to={
                                                        pharmacyRoutes.editProfile
                                                    }
                                                >
                                                    Edit Information
                                                </Link>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* {activeTab === 'Service Information' && (
                            <div>
                                <AppointmentSchedulling />
                            </div>
                        )} */}
                        {/* {activeTab === 'Services' && (
                            <div>
                                <Services />
                            </div>
                        )} */}

                        {activeTab === 'Media' && (
                            <div>
                                <Media />
                            </div>
                        )}

                        {activeTab === 'Bank Account' && (
                            <div>
                                <BankAccount />
                            </div>
                        )}

                        {activeTab === 'Subscription Plan' && (
                            <div>
                                <SubscriptionPlanTab />
                            </div>
                        )}

                        {showPrivateMode && (
                            <PrivateMode
                                closeModal={handleClosePrivateMode}
                                privateModeChange={handlePrivateMode}
                                isActive={isActive}
                            />
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
export default Profile;
