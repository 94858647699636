import React from 'react'
import DashboardLayout from '../../Dashboard/DashboardLayout'
import { Helmet } from 'react-helmet'
import Request from '../../../../components/Ambulance/RequestMgmt/Request'

function RequestMgmt() {
  return (
    <DashboardLayout>
            <Helmet>
                <title>
                   TMR | Ambulance
                </title>
            </Helmet>
            <div className="">
                <Request/>
            </div>
        </DashboardLayout>
  )
}

export default RequestMgmt