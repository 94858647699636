import React, { Fragment, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SlArrowLeft } from 'react-icons/sl';
import PrdtDetails from './PrdtDetails';
import { pharmacyRoutes } from '../../../../utils/pharmacyRouteNames';
import { Edit2, Trash } from 'iconsax-react';
import DeleteModal from '../AddInventory/InventoryModals/DeleteModal';
import { pharmacyService } from '../../../../services/pharmacyService';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

function PrdtDetailsPage() {
    const { inventoryId } = useParams();
    const navigate = useNavigate();

    const back = () => {
        navigate(-1);
    };
    const handleRemoveClick = (e: any) => {
        setDeleteModalVisible(true);
    };
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    // const [inventoryId, setInventoryId] = useState('');

    // const { data, isError, isLoading } = useQuery(
    //     'delete-inventory',
    //     pharmacyService.deleteInventory(drugId)
    // );
    const handleDeleteConfirm = async () => {
        // pharmacyService.deleteInventory('66ab8f64cc4b5943bb4d4d0c');
        // toast.success('res.data.message', {
        //     autoClose: 1000,
        //     onClose: () => {
        //         setTimeout(() => {
        //             navigate(pharmacyRoutes.inventory);
        //         }, 500);
        //     },
        // });
        setDeleteModalVisible(false);
    };

    const handleDeleteCancel = () => {
        setDeleteModalVisible(false);
    };
    return (
        <Fragment>
            <div className="md:mx-8 mx-1 mt-6">
                <div className="flex items-center w-full justify-between mb-6">
                    <div className="flex justify-between">
                        <button
                            onClick={back}
                            className="flex items-center  cursor-pointer"
                        >
                            <SlArrowLeft className="m-2" />
                            <h2 className="font-semibold text-base md:text-[28px] text-lightBrown">
                                Product Details
                            </h2>
                        </button>
                    </div>
                    <div className="flex items-center gap-2">
                        <button
                            className="text-Tmred bg-white rounded-lg flex flex-row items-center gap-3 text-center max-w-[302px] py-2 px-4  border  border-Tmred"
                            onClick={handleRemoveClick}
                        >
                            <Trash size="24" color="#C00010" />
                            <span className="leading-[1.2rem]">
                                Delete Product
                            </span>
                        </button>
                        <button
                            className="bg-Tmred text-white rounded-lg flex flex-row items-center gap-3 text-center max-w-[302px] py-2 px-4  border  border-Tmred"
                            onClick={() =>
                                navigate(
                                    `${pharmacyRoutes.editDetails}/${inventoryId}`
                                )
                            }
                        >
                            <Edit2 size="24" color="#fff" />
                            <span className="leading-[1.2rem]">
                                Edit Product
                            </span>
                        </button>
                    </div>
                </div>
                <PrdtDetails />
            </div>
            {/* Delete Modal */}
            <DeleteModal
                handleDeleteConfirm={handleDeleteConfirm}
                handleDeleteCancel={handleDeleteCancel}
                isDeleteModalVisible={isDeleteModalVisible}
            />
        </Fragment>
    );
}

export default PrdtDetailsPage;
