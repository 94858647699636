import React from 'react';
import DashboardLayout from '../Dashboard/DashboardLayout';
import Bill from '../../../components/Billing/Bill';
import {Helmet} from 'react-helmet'


const Billing = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Billing
                </title>
            </Helmet>
            <div className="">
                <Bill />
            </div>
        </DashboardLayout>
    );
};

export default Billing;
