import { useEffect, useState } from 'react';
import { SlArrowLeft } from 'react-icons/sl';
import { Link, useParams } from 'react-router-dom';
// import NursProfile from './NursProfile';
import { useQuery } from 'react-query';
import hospitalService from '../../services/hospitalService';
import { formatDateDmy } from '../../utils/helpers';
import Lottie from 'lottie-react';
import loadingState from '../../Images/loading.json';
import LogActivity from './LogActivity';
import ManageNurModal from './modals/ManageNurModal';

const NurseProfile = () => {
    const { id } = useParams();
    const [removeNurseModal, setRemoveNurseModal] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    const [nurseProfile, setNurseProfile] = useState<any>({});
    const [activityLog, setActivityLog] = useState<any[]>([]);

    const { data, isLoading, isError, refetch } = useQuery(
        ['nurse', id],
        () =>
            id ? hospitalService.getSingleUser(id) : Promise.reject('No ID'),
        {
            enabled: !!id,
        }
    );

    useEffect(() => {
        if (data) {
            setNurseProfile(data?.user);
            setActivityLog(data?.activityLogs);
        }
    }, [data]);

    const handleRefresh = (status) => {
        setIsRefresh(status);
    };

    useEffect(() => {
        if (isRefresh) {
            window.location.reload();
        }
    }, [isRefresh]);

    const handleOpenModal = () => {
        setRemoveNurseModal(true);
    };
    const handleCloseModal = () => {
        setRemoveNurseModal(false);
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center py-64">
                {' '}
                <Lottie
                    animationData={loadingState}
                    loop={true}
                    className="lottie"
                />
            </div>
        );
    }

    if (isError) {
        return <div>Error fetching admin user</div>;
    }
    return (
        <div className="mx-6 mt-6">
            <Link
                to="/hospital/user/nurse"
                className="flex items-center  cursor-pointer"
            >
                <SlArrowLeft className="m-2" />
                <h2 className="font-semibold text-base md:text-[28px] text-lightBrown">
                    Nurse Profile
                </h2>
            </Link>
            <div className="flex flex-col lg:flex-row lg:space-x-6 mt-8 w-full  ">
                <div className="flex-1 max-w-[742px] bg-white p-2 sm:p-4 rounded-2xl mb-10 lg-mb-0 ">
                    <div>
                        {nurseProfile.status === 'suspended' && (
                            <div className="bg-[#FFB4AB] rounded-lg text-[#201A19] text-[22px] p-2 w-full mx-auto my-4 text-center">
                                Nurse Suspended
                            </div>
                        )}
                    </div>
                    <div className="flex items-center justify-between bg-lightPink p-1 sm:p-4 rounded-xl mb-6">
                        <div className="flex items-center space-x-1 sm:space-x-3">
                            <img
                                src={nurseProfile.image}
                                alt="adminImage"
                                className="rounded-full object-cover h-20 w-20"
                            />
                            <div>
                                <h2 className="sm:text-2xl capitalize text-sm font-medium text-lightBrown">
                                    {/* {`${nurseProfile.salutation} ${nurseProfile.firstName} ${nurseProfile.lastName}`} */}
                                    {`${
                                        nurseProfile.salutation &&
                                        nurseProfile.salutation !== 'undefined'
                                            ? nurseProfile.salutation + ' '
                                            : ''
                                    }${nurseProfile.firstName} ${
                                        nurseProfile.lastName
                                    }`}
                                </h2>
                                <p className="sm:text-sm text-xs font-normal text-dark100 ">
                                    {nurseProfile.email}
                                </p>
                                <p className="sm:text-sm capitalize text-xs font-normal text-lightBrown">
                                    {nurseProfile.userType}
                                </p>
                            </div>
                        </div>
                        <div className="pr-24">
                            <h2 className="text-sm font-normal text-lightBrown montFont ">
                                Title
                            </h2>
                            <p className="text-base  text-lightBrown capitalize font-semibold fontMont">
                                {nurseProfile?.title || '-'}
                            </p>
                        </div>
                    </div>
                    <div className="flex lg:flex-col xl:flex-row flex-col sm:flex-row justify-between">
                        <div>
                            <h2 className="text-base sm:text-[22px] font-medium text-lightBrown">
                                Personal information
                            </h2>
                            <div className="my-6">
                                <h2 className="text-sm font-normal text-lightBrown montFont ">
                                    Gender
                                </h2>
                                <p className="text-sm font-medium text-lightBrown100 capitalize">
                                    {nurseProfile.gender || '-'}
                                </p>
                            </div>
                            <div>
                                <h2 className="text-sm font-normal text-lightBrown montFont">
                                    Phone Number
                                </h2>
                                <p className="text-sm font-medium text-lightBrown100">
                                    {nurseProfile.phoneNumber || '-'}
                                </p>
                            </div>
                            <div className="my-6">
                                <h2 className="text-sm font-normal text-lightBrown montFont">
                                    City (optional)
                                </h2>
                                <p className="text-sm font-medium text-lightBrown100 capitalize">
                                    {nurseProfile.city || '-'}
                                </p>
                            </div>
                            <div>
                                <h2 className="text-sm font-normal text-lightBrown montFont">
                                    State (optional)
                                </h2>
                                <p className="text-sm font-medium text-lightBrown100 capitalize">
                                    {nurseProfile.state || '-'}
                                </p>
                            </div>
                            <div className="mt-6">
                                <h2 className="text-sm font-normal text-lightBrown montFont">
                                    Address (optional)
                                </h2>
                                <p className="text-sm font-medium text-lightBrown100 ">
                                    {nurseProfile.address || '-'}
                                </p>
                            </div>
                        </div>
                        <div className="mt-6 sm:mt-0 lg:mt-6 xl:mt-0">
                            <h2 className=" text-base sm:text-[22px] font-medium text-lightBrown">
                                Professional information
                            </h2>

                            <div className="my-6">
                                <h2 className="text-sm font-normal text-lightBrown montFont">
                                    MDCN License Number
                                </h2>
                                <p className="text-sm font-medium text-lightBrown100 ">
                                    {nurseProfile.licenseNumber || '-'}
                                </p>
                            </div>
                            <div>
                                <h2 className="text-sm font-normal text-lightBrown montFont">
                                    Specialty (optional)
                                </h2>
                                <p className="text-sm font-medium text-lightBrown100 capitalize mt-2">
                                    {nurseProfile?.specialties?.map(
                                        (specialty: any) => (
                                            <button
                                                key={specialty._id}
                                                type="button"
                                                className="text-xs font-medium text-brown200 mr-1 mb-1 border-[1px] border-brown200 py-[6px] px-3 rounded-lg"
                                            >
                                                {specialty.name
                                                    ? specialty.name
                                                    : ''}
                                            </button>
                                        )
                                    )}
                                </p>
                            </div>
                            <div className="my-6">
                                <h2 className="text-sm font-normal text-lightBrown montFont mb-3">
                                    User Group
                                </h2>
                                <div className=" flex flex-wrap max-w-[380px] gap-2">
                                    {nurseProfile?.permissions?.map(
                                        (permission: any, index) => (
                                            <button
                                                key={index}
                                                type="button"
                                                className="text-xs font-medium text-brown200 mr-1 mb-1 border-[1px] border-brown200 py-[6px] px-3 rounded-lg"
                                            >
                                                {permission ? permission : ''}
                                            </button>
                                        )
                                    )}
                                </div>
                            </div>
                            <div className="mt-6">
                                <h2 className="text-sm font-normal text-lightBrown montFont">
                                    Date Registered
                                </h2>
                                <p className="text-sm font-medium text-lightBrown100">
                                    {formatDateDmy(nurseProfile.createdAt)}
                                </p>
                            </div>
                        </div>
                    </div>
                    {nurseProfile.status !== 'suspended' && (
                        <div className="mt-8">
                            <h2 className="text-sm text-lightBrown font-normal montFont">
                                Availability
                            </h2>
                            <div className="flex max-w-[322px] justify-between items-center mt-2">
                                <span
                                    className={`text-base text-lightBrown font-normal ${
                                        nurseProfile?.status === 'active'
                                            ? 'text-base '
                                            : 'text-base'
                                    }`}
                                >
                                    {nurseProfile?.availability}
                                </span>
                            </div>
                        </div>
                    )}
                    <div className="flex justify-end space-x-6 py-3 border-t mt-10">
                        <button
                            type="button"
                            onClick={handleOpenModal}
                            className="py-2 px-3"
                        >
                            Manage Nurse
                        </button>
                        <button
                            type="submit"
                            className="text-Tmred border-[1px] border-lightBrown100 px-6 py-2 text-base font-normal rounded-lg"
                        >
                            Send Message
                        </button>
                    </div>
                </div>
            </div>

            <LogActivity activitylog={activityLog} />

            {removeNurseModal && (
                <ManageNurModal
                    closeModal={handleCloseModal}
                    nurseId={nurseProfile._id}
                    nurseName={`${nurseProfile.firstName} ${nurseProfile.lastName}`}
                    nurseUser={nurseProfile}
                    refresh={refetch}
                />
            )}
        </div>
    );
};
export default NurseProfile;
