import React, {  useState } from 'react';



interface DrugSearchModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSelectDrug: (drug: { _id: number,name: string, price: number,drug:any }) => void;
    selectedDrugs: { _id: number, name: string, price: number,drug:any }[];
    drugsData?: { drugInventories: any[] };
    isDrugsLoading: boolean;
    isDrugsError: boolean;
}

const DrugSearchModal: React.FC<DrugSearchModalProps> = ({ isOpen, onClose, onSelectDrug,selectedDrugs,drugsData, isDrugsLoading, isDrugsError  }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedDrug, setSelectedDrug] = useState<{ _id: number, name: string, price: number } | null>(null);

    
    

const filteredDrugs = drugsData?.drugInventories?.filter(drug =>
    drug?.drug?.name?.toLowerCase().includes(searchTerm.toLowerCase())
);
    

   
    if (!isOpen) return null;

    const handleClose = (e: React.MouseEvent<HTMLDivElement>) => {
        if ((e.target as HTMLDivElement).id === 'wrapper') {
            onClose();
        }
    };
    

    const handleSelectDrug = (drug: { _id: number, name: string, price: number,drug:any }) => {
        setSelectedDrug(drug);
        onSelectDrug(drug);
    };

    return (
        <div className="relative z-10 " aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div
                className="fixed z-10 inset-0 flex justify-center items-center bg-[#2826265E] bg-opacity-20 "
                id="wrapper"
                onClick={handleClose}
            >
                <div className="max-w-[560px] lg:w-[440px] mx-auto flex flex-col">
                    <div className="bg-white p-[24px] rounded-[28px] border  w-full ">
                    <div className="my-5 flex items-center gap-2 rounded-[24px] w-full bg-[#F5F6F7]">
                       
                        <input
                            type="text"
                            placeholder="Search for an item"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className='w-full p-3 rounded-[24px] bg-[#F5F6F7] font-normal text-[16px] bg-transparent text-[#534341] border-transparent outline-none focus:ring-0'
                        />
                        </div>
                        <ul className='h-[288px] w-full overflow-y-scroll'>
                            {filteredDrugs?.length === 0 ? (
                                <p className="text-center text-gray-500 py-2">
                                    Search does not match
                                </p>
                            ) : (
                                filteredDrugs?.map(drug => (
                                    <li key={drug._id} 
                                        className={`flex items-center gap-4 mb-4 p-2 cursor-pointer
                                            ${selectedDrug && selectedDrug._id === drug.id ? 'bg-[#FFDAD5] rounded-tl-2xl rounded-bl-2xl' : 'hover:bg-[#EDE0DE]'}
                                            ${selectedDrugs.some(selectedDrug => selectedDrug._id === drug._id) ? 'bg-gray-200 cursor-not-allowed' : ''}`
                                        }
                                        onClick={() => handleSelectDrug(drug)}>
                                            <p className='font-normal text-[16px] text-[#201A19]'>{drug.drug.name}</p>
                                         
                                         {/* <p className='font-normal text-[16px] text-[#201A19]'>${drug.price}</p> */}
                                    </li>
                                ))
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DrugSearchModal;
