import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import DashboardSidebarHeader from './DashboardSidebarHeader';
import { useModal } from '../../../contexts/ModalContext';
import EmergencyModal from '../../../components/PharmacyComponent/dashboard/modals/emergencyTmrModal';
import { Notification } from 'iconsax-react';
import { ArrowDown2 } from 'iconsax-react';
import { Messages2 } from 'iconsax-react';
import { LoginCurve } from 'iconsax-react';

import AllNotification from '../../../components/Notification/AllNotification';
import {
  
    useNotificationStore,
    useScreenTimeOut,
} from '../../../store/pharmacy/auth';

import { useNavigate } from 'react-router-dom';
import Modal from '../../../reusable/Modal';

import { usePharmacyAuthStore } from '../../../store/pharmacy/auth';

import { pharmacyRoutes } from '../../../utils/pharmacyRouteNames';

const DashboardHeaderContainer = styled.header`
    .DashboardSidebar__header {
        width: 100%;
        padding: 0.5rem 2.5rem;
        z-index: 1;
        background-color: white;
        display: flex;
        gap: 30px;
        justify-content: flex-end;
        .DashboardHeader__user {
            display: block;
            max-width: 15rem;
            border-radius: 3px;
            background-color: white;
            img.avatar {
                width: 41px;
                height: 41px;
                border-radius: 50%;
                object-fit: cover;
                // img.avatar {
                // 	width: 41px;
                // }
            }
        }
        .DashboardHeader__user-role-wrapper {
            max-width: 8rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 2px;
            text-align: left;
        }

        .DashboardHeader__user--text {
            width: 100%;
            font-size: 15px;
            line-height: 24px;
            color: black;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 600;
        }
        .DashboardHeader__user--role {
            color: black;
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            text-transform: capitalize;
        }
        @media (max-width: 850px) {
            display: none;
            .DashboardHeader__content {
                margin-left: 0;
                padding: 30px;
                width: 100%;
            }
        }
    }
`;

interface PropsType {
    isOpen: boolean;
    toggleSidebar: Function;
}

const DashboardHeader: React.FC<PropsType> = ({ isOpen, toggleSidebar }) => {
    const navigate = useNavigate();
    const { isModalOpen, openModal } = useModal();
    const [open, setOpen] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
    const userStore = usePharmacyAuthStore((state) => state.user);
    const notification = useNotificationStore((state) => state.unreadLength);
    const clearStore = usePharmacyAuthStore((state) => state.clearToken);
    const [showDropdown, setShowDropdown] = useState(false);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const timeOutStore = useScreenTimeOut();
    const handleLogout = () => {
        clearStore();
        // Clear the token from sessionStorage
        sessionStorage.removeItem('persist:pharmacy-auth-store'); // clear auth store
        timeOutStore.setIsTimeOut(false); // reset timeout store
        navigate(pharmacyRoutes.healthID, { replace: true });
    };
    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };
    const handleClose = () => {
        setOpen(false);
        console.log('i clicked');
    };
    const handleOverlayClick = (event: any) => {
        if (
            modalRef.current &&
            !modalRef.current.contains(event.target as Node)
        ) {
            setShowDropdown(false);
        }
    };
    const handleOpenTmrModal = () => {
        openModal();
    };
    const handleNotification = () => {
        setOpen(true);
    };

    const toggleLogoutModal = () => {
        setShowLogoutModal(!showLogoutModal);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOverlayClick);

        return () => {
            document.removeEventListener('mousedown', handleOverlayClick);
        };
    }, []);
    return (
        <React.Fragment>
            <DashboardHeaderContainer>
                <div
                    className="flex flex-row items-center justify-end gap-8 h-[88px] lg:pr-[50px] bg-white border border-b-[#0000004d] absolute top-0 w-[stretch]"
                    id="wrapper"
                    // onClick={closeModal}
                >
                    <DashboardSidebarHeader
                        isOpen={isOpen}
                        toggleSidebar={toggleSidebar}
                        showOnDesktop={false}
                    />
                    <div
                        className="DashboardSidebar__header"
                        // onClick={onClickOutside}
                    >
                        <div className="flex justify-center items-center">
                            <button
                                className="text-white bg-[#410002] text-14px] rounded-[8px] px-6 py-1 "
                                onClick={handleOpenTmrModal}
                            >
                                Global TMR Search
                            </button>
                        </div>
                        <div
                            onClick={handleNotification}
                            className="flex justify-center items-center cursor-pointer"
                        >
                            <span className="relative inline-block">
                                <Notification
                                    size="24"
                                    className="text-black"
                                />
                                <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs leading-none transform translate-x-1/2 -translate-y-1/2 bg-tmrRed text-white rounded-full">
                                    {notification}
                                </span>
                            </span>
                        </div>
                        <div
                            className="flex items-center space-x-5 bg-lightGray200 py-1 px-2 rounded-[20px]"
                            // id="logging"
                        >
                            <div
                                className="flex flex-row gap-3 cursor-pointer"
                                id="logging"
                                onClick={toggleDropdown}
                            >
                                <div>
                                    <img
                                        src={userStore.image}
                                        alt="Staff profile dp"
                                        className="rounded-full h-[48px] w-[48px]"
                                    />
                                </div>
                                <div className="capitalize">
                                    <h3 className="text-[16px] tracking-wider capitalize font-medium mb-1">
                                        {userStore.firstName +
                                            ' ' +
                                            userStore.lastName}
                                    </h3>
                                    <p className="text-[14px] capitalize">
                                        {userStore.userType.toLocaleUpperCase()}
                                    </p>
                                </div>
                            </div>
                            <button onClick={toggleDropdown}>
                                <ArrowDown2 size="24" color="#000" />
                            </button>
                            {showDropdown && (
                                <div
                                    className="absolute z-50 right-24 mt-[200px] w-56 p-2 rounded-[20px] bg-white shadow-lg ring-1 ring-black ring-opacity-5"
                                    id="#logg"
                                    // ref={divRef}
                                    ref={modalRef}
                                >
                                    <div className="py-1">
                                        {/* <label className="block px-4 text-xs font-medium text-lightBrown100">
                                            Status
                                        </label>
                                        <div className="px-4 space-y-3 mt-3">
                                             <div className="flex items-center">
                                                <input
                                                    checked
                                                    type="radio"
                                                    id="active"
                                                    name="status"
                                                    className="radio-green focus:ring-Tmred cursor-pointer h-4 w-4 text-Tmred border-gray-300  checked:bg-green-500 checked:border-transparent"
                                                />
                                                <label
                                                    htmlFor="active"
                                                    className="ml-3 block text-sm font-medium text-lightBrown"
                                                >
                                                    Active
                                                </label>
                                            </div>
                                            <div className="flex items-center">
                                                <input
                                                    type="radio"
                                                    id="away"
                                                    name="status"
                                                    className="radio-yellow focus:ring-Tmred cursor-pointer h-4 w-4 text-Tmred border-gray-300  checked:bg-[#FFFF99] checked:border-transparent"
                                                />
                                                <label
                                                    htmlFor="away"
                                                    className="ml-3 block text-sm font-medium text-lightBrown"
                                                >
                                                    Away
                                                </label>
                                            </div> 
                                        </div>
                                        <div className="border-t border-gray-200 mt-4 mb-1"></div> */}
                                        <div className="py-1">
                                            <button className=" flex items-center space-x-2 px-4 py-1 text-sm text-gray-700 w-full text-left">
                                                <Messages2
                                                    size="24"
                                                    color="#534341"
                                                />
                                                <a
                                                    href="mailto:support@trackmedrec.com"
                                                    className="text-lightBrown text-sm"
                                                >
                                                    Chat with support
                                                </a>
                                            </button>

                                            <button
                                                onClick={toggleLogoutModal}
                                                className="flex items-center space-x-2 px-4 pt-2 text-sm text-gray-700 w-full text-left"
                                            >
                                                <LoginCurve
                                                    size="24"
                                                    color="#C00010"
                                                />
                                                <p className="text-Tmred text-sm">
                                                    Log Out
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* {notificationModal &&} */}

                {isModalOpen && <EmergencyModal />}

                <Modal
                    isVisible={showLogoutModal}
                    onClose={toggleLogoutModal}
                    onSave={handleLogout}
                    buttonEnabled
                    setButton
                    saveText="Yes log out"
                    closeText="No"
                    showCloseButtonBorder={false}
                >
                    <div className="bg-white">
                        <div className="bg-white">
                            <h1 className="text-[24px] font-semibold leading-[32px] mb-[16px]">
                                Log Out?
                            </h1>
                            <p className="text-[14px] font-medium text-[#534341]">
                                Are you sure you want to log out?
                            </p>
                        </div>
                    </div>
                </Modal>
            </DashboardHeaderContainer>
            <AllNotification onClose={() => handleClose()} isVisible={open} />
        </React.Fragment>
    );
};

export default DashboardHeader;
