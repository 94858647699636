import UserManagement from '../../../../components/UserNurse/UserManagement';
import DashboardLayout from '../../Dashboard/DashboardLayout';
import {Helmet} from 'react-helmet'


const Nurse = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Nurse
                </title>
            </Helmet>
            <UserManagement />
        </DashboardLayout>
    );
};
export default Nurse;
