import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DashboardHeader from './DashboardHeader';
import DashboardSidebar from './DashboardSidebar';
import {
    dashboard,
    toggleSidebar,
    closeSidebar,
} from '../../../constants/dashboard';
import { useLocation } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import { hospitalRoutes } from '../../../utils/routeNames';

import { pharmacyRoutes } from '../../../utils/pharmacyRouteNames';
import { useScreenTimeOut } from '../../../store/pharmacy/auth';

const ContentWrapper = styled.div`
    margin-top: 100px;
    margin-left: 17rem;

    @media (max-width: 850px) {
        margin: 8rem 1rem;
    }
`;

interface Props {
    children: any;
    rhsHeading?: any;
    showPageTitle?: boolean;
    pageTitle?: string;
}

export const DashboardLayout: React.FC<Props> = ({
    pageTitle,
    showPageTitle = true,
    children,
    rhsHeading,
}) => {
    const { sidebarIsOpen } = dashboard.use();
    const location = useLocation();
    useEffect(() => closeSidebar(), [location]);
    const navigate = useNavigate();
    const [state, setState] = useState<string>('Active');
    const [count, setCount] = useState<number>(0);

    const timeOutStore = useScreenTimeOut();

    useEffect(() => {
        if (timeOutStore.isTimeOut) {
            navigate(pharmacyRoutes.screenTimeout);
        }
    }, []);

    const onIdle = () => {
        setState('Idle');
    };

    const onActive = () => {
        setState('Active');
    };

    const onAction = () => {
        setCount(count + 1);
    };

    useIdleTimer({
        onIdle,
        onActive,
        onAction,
        timeout: 300_000,
        throttle: 500,
    });

    useEffect(() => {
        if (state === 'Idle') {
            timeOutStore.setIsTimeOut(true);
            navigate(pharmacyRoutes.screenTimeout);
        }
    }, [state]);

    return (
        <div>
            <DashboardSidebar
                isOpen={sidebarIsOpen}
                toggleSidebar={toggleSidebar}
            />
            <div className="flex flex-col bg-gray-50">
                <DashboardHeader
                    isOpen={sidebarIsOpen}
                    toggleSidebar={toggleSidebar}
                />

                <ContentWrapper>
                    <main>{children}</main>
                </ContentWrapper>
            </div>
        </div>
    );
};

export default DashboardLayout;
