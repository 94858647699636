import React, { useEffect, useState } from 'react'
import SetUpAmbulance from '../FleetMgmt/SetUpAmbulance'
import Ambulance from './Ambulance'
import { useHospitalAuthStore } from '../../../store/hospital/auth';

function Request() {
  const [isAmbulanceSetUp, setIsAmbulanceSetUp] = useState(false);
  const hospitalAuthStore = useHospitalAuthStore((state) => state.hospital);

  useEffect(() => {
      // Check if ambulance setup has been done
      const ambulanceSetupStatus = hospitalAuthStore.hasAmbulance || false; 
      setIsAmbulanceSetUp(ambulanceSetupStatus);
  }, [hospitalAuthStore]);

  return (
      <div>
          {isAmbulanceSetUp ? (
              <Ambulance />
          ) : (
              <SetUpAmbulance setIsAmbulanceSetUp={setIsAmbulanceSetUp} />
          )}
      </div>
  );
}

export default Request