import React from 'react';
import styled from 'styled-components';
import { HamburgerMenu } from '../../../reusable/Hamburger';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { usePharmacyAuthStore } from '../../../store/pharmacy/auth';


const DashboardSidebarHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 5rem;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.44px;
    color: #000000;
    padding: 8px 32px 8px 43px;
    background-color: #ffffff;
    z-index: 50;

    .brand-logo {
        width: 142px;
        // height: 36px;
    }

    @media (max-width: 850px) {
        width: 100%;
        height: 5rem;
        padding: 8px 32px;
    }

    &:not(.showOnDesktop) {
        display: none;

        @media (max-width: 850px) {
            display: flex;
        }
    }

    .showOnMobile {
        display: none;
        @media (max-width: 850px) {
            display: block;
        }
    }
    .tooltip__avatar {
        position: relative;
        display: inline-block;
        cursor: pointer;
        img {
            width: 40px;
            border-radius: 50%;
        }
    }

    .tooltip__avatar .tooltiptext {
        visibility: hidden;
        width: auto;
        min-width: 150px;
        color: #000000;
        background-color: #ffffff;
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        font-size: 14px;
        position: absolute;
        z-index: 1;
        box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
        left: -43px;
    }

    .tooltip__avatar:hover .tooltiptext {
        visibility: visible;
    }

    .tooltiptext__user--name {
        font-size: 15px;
        line-height: 24px;
        color: #000000;
        font-weight: 600;
    }

    .tooltiptext__user--role {
        color: #000000;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
    }
`;

interface Props {
    isOpen: boolean;
    showOnDesktop?: boolean;
    toggleSidebar: Function;
}

export const DashboardSidebarHeader: React.FC<Props> = ({
    isOpen,
    toggleSidebar,
    showOnDesktop,
}) => {
    const userStore = usePharmacyAuthStore((state) => state.user);
    const pharmacyStore = usePharmacyAuthStore((state) => state.pharmacy);

    return (
        <DashboardSidebarHeaderContainer
            className={clsx('DashboardSidebarHeader', { showOnDesktop })}
        >
            <Link to="/pharmacy/dashboard">
                <div className="flex items-center lg:mx-4 h-[100%] max-h-[88px] w-[stretch] gap-2 md:gap-4">
                    <img
                        src={pharmacyStore.logo}
                        alt=""
                        className="rounded-full h-[40px] w-[46px] max-w-full  "
                    />
                    <h1 className=" text-xl md:text-2xl font-semibold ">
                        {pharmacyStore.name}
                    </h1>
                </div>
            </Link>
            <div className="tooltip__avatar">
                <img
                    className="showOnMobile rounded-full h-[40px] w-[46px] max-w-full"
                    src={userStore.image}
                    alt="avatar"
                />
                <div className="tooltiptext">
                    {' '}
                    <p className="tooltiptext__user--name">
                        {userStore.firstName + ' ' + userStore.lastName}
                    </p>
                    <p className="tooltiptext__user--role">
                        {userStore.userType.toLocaleUpperCase()}
                    </p>
                </div>
            </div>
            <HamburgerMenu isOpen={isOpen} onClick={toggleSidebar} />
        </DashboardSidebarHeaderContainer>
    );
};

export default DashboardSidebarHeader;
