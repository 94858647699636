import React, { useEffect, useState } from 'react'
import Input from '../../reusable/Forms/Input';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { FormControl, InputLabel, MenuItem, Radio, Select } from '@mui/material';
import hospitalService from '../../services/hospitalService';
import { useMutation } from 'react-query';
import upload from '../../Images/uploadAds.png';
import adPattern1 from '../../Images/adPattern1.png';
import adPattern2 from '../../Images/adPattern2.png';
import { FirstStageProps } from './CreateADS';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { hospitalRoutes } from '../../utils/routeNames';

type SecondAdsProps = {
    firstFormData: FirstStageProps;
    updateStage: (stage: number) => void;
}

type SecondStageProps = {
    adsContentType: string;
    contentTitle: string;
    contentDescription: string;
    callToAction: string;
    pattern: string;
    color: string;
};

const CreateAdsSecondStage = ({
    firstFormData,
    updateStage
}: SecondAdsProps) => {
    const [adTypeDisplay, setAdTypeDisplay] = useState<string>('banner');
    const [adPattern, setAdPattern] = useState<string>('');
    const [adPatternColor, setAdPatternColor] = useState<string>('red');
    const [adImageFile, setAdImageFile] = useState<any>('');
    const [adImagePreview, setAdImagePreview] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    const {
        formState: { isDirty, isValid },
        handleSubmit,
        control,
        watch
    } = useForm<SecondStageProps>();

    useEffect(() => {
        setAdTypeDisplay(watch('adsContentType'));
    }, [watch('adsContentType')]);

    useEffect(() => {
        setAdPattern(watch('pattern'));
    }, [watch('pattern')]);

    useEffect(() => {
        setAdPatternColor(watch('color'));
    }, [watch('color')]);

    const onImageChange = (e: any) => {
        e.preventDefault();
        const image_as_base64 = URL.createObjectURL(e.target.files[0]);
        const image_as_files = e.target.files[0];

        setAdImagePreview(image_as_base64);
        setAdImageFile(image_as_files);
    };
    
    const adsMutation = useMutation((data: any) => hospitalService.createAds(data));
    useEffect(() => {
        if (adsMutation.isSuccess) {
            setIsLoading(false);
            const { data } = adsMutation.data;
            toast.success(data?.message);
            navigate(hospitalRoutes.tmrAds);
        }
    }, [adsMutation.isSuccess, adsMutation.data]);

    useEffect(() => {
        if (adsMutation.isError) {
            setIsLoading(false);
            let err: any = adsMutation?.error;
            toast.error(err.response.data.error.message);
        }
    }, [adsMutation.isError, adsMutation.error]);

    const onSubmitForm: SubmitHandler<SecondStageProps> = (data) => {
        if(data.adsContentType === 'banner' && (!adImagePreview || !adImageFile)) {
            toast.error('You need to upload your ad banner');
            return;
        }
        setIsLoading(true);
        const adsFormData = new FormData();
        adsFormData.append('adsName', firstFormData.adsName);
        adsFormData.append('adsObjective', firstFormData.adObjective);
        adsFormData.append('schedule', JSON.stringify({
            starts: firstFormData.startDate,
            expires: firstFormData.expiryDate
        }));
        adsFormData.append('audience', JSON.stringify({
            gender: firstFormData.gender,
            state: firstFormData.state,
            ageRange: {
                min: firstFormData.ageRangeMin,
                max: firstFormData.ageRangeMax
            }
        }));
        adsFormData.append('targetUrl', firstFormData.targetUrl);
        adsFormData.append('websiteUrl', firstFormData.websiteUrl);
        adsFormData.append('type', data.adsContentType);
        if(data.adsContentType === 'banner') {
            adsFormData.append('adContent', adImageFile);
        } else {
            adsFormData.append('adContent', JSON.stringify({
                contentTitle: data.contentTitle,
                description: data.contentDescription,
                callToAction: data.callToAction,
                pattern: data.pattern,
                color: data.color
            }));
        }
        adsMutation.mutate(adsFormData);
    };

    const onInvalid = (errors) => console.error(errors);

    const AdPreview = () => {
        return (
            <div className="flex flex-col md:w-1/3 w-full mt-7 md:mt-0">
                <div className='mt-2 p-4 bg-white rounded-2xl'>
                    <h1 className="rounded-tl-2xl rounded-tr-2xl py-2 text-xl font-normal">
                        Preview
                    </h1>
                    {
                        (adTypeDisplay === 'template' && adPattern) ? 
                        <div 
                            className='flex flex-col w-full min:h-40 p-4 rounded-2xl bg-no-repeat bg-center bg-cover' 
                            style={{ 
                                backgroundImage: adPattern === '1' ? `url(${adPattern1})` : `url(${adPattern2})`, 
                                backgroundColor: adPatternColor
                            }}
                        >
                            <p className='text-white text-[20px] font-medium'>{watch('contentTitle')}</p>
                            <p className='text-white text-sm font-normal'>{watch('contentDescription')}</p>
                            <button 
                                className='w-32 rounded-lg mt-4 p-2 bg-lightTmrRed text-Tmred hover:cursor-pointer'
                            >
                                {watch('callToAction')}
                            </button>
                        </div>
                         : 
                        (adTypeDisplay === 'banner' && adImagePreview) ? 
                            <img src={adImagePreview} alt='ad preview' />
                        :
                        <div className='flex bg-lightTmrRed w-full h-24 justify-center items-center'>
                            <p className='text-Tmred font-medium'>Preview</p>
                        </div>
                    }
                    
                </div>
            </div>
        )
    }

    return (
        <div>
        <form
            onSubmit={handleSubmit(onSubmitForm, onInvalid)}
            noValidate
            className="flex flex-col md:flex-row sm:gap-5 mb-[80px] w-full"
        >

            <div className="flex flex-col gap-3 md:w-2/3 w-full mt-2 p-4 bg-white rounded-2xl">
                <div className="flex flex-col gap-2 w-full">
                    <h1 className="rounded-tl-2xl rounded-tr-2xl py-2 text-xl font-normal">
                        Ads Content
                    </h1>

                    <Controller
                        name="adsContentType"
                        rules={{
                            required: 'Ad target is required',
                        }}
                        control={control}
                        render={({ field }) => (
                            <>
                                <div className='flex flex-row items-center'>
                                    <Radio 
                                        {...field} 
                                        value='banner'
                                        checked={field.value === 'banner'}  
                                    />
                                    <div>
                                        <p className="font-semibold text-[14px] sm:text-[16px]">
                                            Banner
                                        </p>
                                    </div>
                                </div>
                                <p className="ml-10 mb-3 font-normal text-sm">
                                    Upload your marketing banner
                                </p>
                            </>
                        )}
                    />

                    <Controller
                        name="adsContentType"
                        rules={{
                            required: 'Ad content type is required',
                        }}
                        control={control}
                        render={({ field }) => (
                            <>
                                <div className='flex flex-row items-center'>
                                    <Radio 
                                        {...field} 
                                        value='template'
                                        checked={field.value === 'template'}  
                                    />
                                    <div>
                                        <p className="font-semibold text-[14px] sm:text-[16px]">
                                            Template
                                        </p>
                                    </div>
                                </div>
                                <p className="ml-10 mb-3 font-normal text-sm">
                                    Select a template to customize your ads
                                </p>
                            </>
                        )}
                    />
                    {adTypeDisplay === 'banner' ?
                    <>    
                        <h1 className="rounded-tl-2xl rounded-tr-2xl py-2 text-xl font-normal">
                            Upload Image
                        </h1>
                        <div className="relative w-full flex flex-col items-center justify-center my-4">
                            
                            <div className="w-full max-w-[551px] h-[150px] rounded-2xl bg-white border overflow-hidden">
                                <div className="w-full h-full mx-auto flex flex-col justify-center items-center px-3">
                                    <img src={upload} alt="" />
                                    <p className="text-center mt-2 text-sm font-semibold text-Tmred">
                                        Click to upload
                                    </p>
                                    <span className="text-center text-xs text-lightBrown200">
                                    PNG, JPG, or JPEG (max. 341 x 162px)
                                    </span>
                                </div>
                            </div>

                            <input
                                type="file"
                                accept=".png, .jpeg, .jpg"
                                name="upload"
                                style={{
                                    transform: 'translate(-50%, -50%)',
                                }}
                                className="bg-[#FFDAD5] w-[100%] h-[100%] top-[50%] left-[50%] z-[4] opacity-0 cursor-pointer absolute"
                                onChange={onImageChange}
                            />
                        </div>
                    </>
                    :
                    <>
                        <h1 className="rounded-tl-2xl rounded-tr-2xl py-2 text-xl font-normal">
                            Template Customization
                        </h1>
                        <Controller
                            name="contentTitle"
                            control={control}
                            rules={{
                                required: watch('adsContentType') === 'template' ? true : false
                            }}
                            render={({ field }) => (
                                <>
                                    <Input label="Content Title" {...field} />
                                    <p className='text-xs mb-4 text-lightBrown200'>Note: Not more than 20 words</p>
                                </>
                            )}
                        />
                        <Controller
                            name="contentDescription"
                            control={control}
                            rules={{
                                required: watch('adsContentType') === 'template' ? true : false
                            }}
                            render={({ field }) => (
                                <>
                                    <textarea 
                                        className="w-full border border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider"
                                        rows={4}
                                        placeholder='Content Description'
                                        {...field}
                                    ></textarea>
                                    <p className='text-xs mb-4 text-lightBrown200'>Note: Not more than 50 words</p>
                                </>
                            )}
                        />
                        <Controller
                            name="callToAction"
                            defaultValue=''
                            control={control}
                            rules={{
                                required: watch('adsContentType') === 'template' ? true : false
                            }}
                            render={({ field }) => (
                                <FormControl fullWidth>
                                    <InputLabel id="state-select-filled-label">Call to action</InputLabel>
                                    <Select
                                        labelId="state-select-filled-label"
                                        id="state-select-filled"
                                        label="Ad Objective"
                                        {...field}
                                    >
                                        <MenuItem value="Get Started">Get Started</MenuItem>
                                        <MenuItem value="Learn More">Learn More</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        />
                        <h1 className="rounded-tl-2xl rounded-tr-2xl py-2 text-xl font-normal">
                            Select pattern
                        </h1>
                        <div className="flex flex-row gap-3 w-full">
                            <Controller
                                name="pattern"
                                control={control}
                                rules={{
                                    required: watch('adsContentType') === 'template' ? true : false
                                }}
                                render={({ field }) => (
                                    <>
                                        <div className='flex flex-row items-center'>
                                            <Radio 
                                                {...field} 
                                                value='1'
                                                checked={field.value === '1'}  
                                            />
                                            <div>
                                                <img src={adPattern1} alt='ad pattern 1' className='w-48 h-20' />
                                            </div>
                                        </div>
                                    </>
                                )}
                            />
                            <Controller
                                name="pattern"
                                control={control}
                                rules={{
                                    required: watch('adsContentType') === 'template' ? true : false
                                }}
                                render={({ field }) => (
                                    <>
                                        <div className='flex flex-row items-center'>
                                            <Radio 
                                                {...field} 
                                                value='2'
                                                checked={field.value === '2'}  
                                            />
                                            <div>
                                                <img src={adPattern2} alt='ad pattern 2' className='w-48 h-20' />
                                            </div>
                                        </div>
                                    </>
                                )}
                            />
                        </div>
                        <h1 className="rounded-tl-2xl rounded-tr-2xl py-2 text-xl font-normal">
                            Select color
                        </h1>
                        <div className="flex flex-col md:flex-row mt-5 gap-3 w-full">
                            <Controller
                                name="color"
                                control={control}
                                rules={{
                                    required: watch('adsContentType') === 'template' ? true : false
                                }}
                                render={({ field }) => (
                                    <>
                                        <div className='flex flex-row items-center'>
                                            <Radio 
                                                {...field} 
                                                value='#C00010'
                                                checked={field.value === '#C00010'}  
                                            />
                                            <div className='rounded-full w-12 h-12' style={{ backgroundColor: '#C00010' }}></div>
                                        </div>
                                    </>
                                )}
                            />
                            <Controller
                                name="color"
                                control={control}
                                rules={{
                                    required: watch('adsContentType') === 'template' ? true : false
                                }}
                                render={({ field }) => (
                                    <>
                                        <div className='flex flex-row items-center'>
                                            <Radio 
                                                {...field} 
                                                value='#473836'
                                                checked={field.value === '#473836'}  
                                            />
                                            <div className='rounded-full w-12 h-12' style={{ backgroundColor: '#473836' }}></div>
                                        </div>
                                    </>
                                )}
                            />
                            <Controller
                                name="color"
                                control={control}
                                rules={{
                                    required: watch('adsContentType') === 'template' ? true : false
                                }}
                                render={({ field }) => (
                                    <>
                                        <div className='flex flex-row items-center'>
                                            <Radio 
                                                {...field} 
                                                value='#534341'
                                                checked={field.value === '#534341'}  
                                            />
                                            <div className='rounded-full w-12 h-12' style={{ backgroundColor: '#534341' }}></div>
                                        </div>
                                    </>
                                )}
                            />
                            <Controller
                                name="color"
                                control={control}
                                rules={{
                                    required: watch('adsContentType') === 'template' ? true : false
                                }}
                                render={({ field }) => (
                                    <>
                                        <div className='flex flex-row items-center'>
                                            <Radio 
                                                {...field} 
                                                value='#930009'
                                                checked={field.value === '#930009'}  
                                            />
                                            <div className='rounded-full w-12 h-12' style={{ backgroundColor: '#930009' }}></div>
                                        </div>
                                    </>
                                )}
                            />
                        </div>
                    </>
                    }
                </div>

                <div className="flex w-full justify-end border-t-[1px] border-[#D8C2BF] my-4 ">
                    <button
                        type='button'
                        onClick={() => updateStage(1)}
                        className='mr-4 rounded-lg mt-4 py-[10px] px-6 border border-Tmred text-Tmred hover:cursor-pointer'
                    >
                        Back
                    </button>
                    <button
                        type='submit'
                        disabled={!isDirty || !isValid || isLoading}
                        className={`rounded-lg mt-4 py-[10px] px-6 text-[#9d9b9b] hover:cursor-not-allowed ${
                            !isDirty || !isValid || isLoading
                                ? 'bg-[#1D1B201F]'
                                : 'bg-Tmred text-white hover:cursor-pointer'
                        } `}
                    >
                        {isLoading ? 'Submitting...' : 'Submit'}
                    </button>
                </div>
            </div>
            
            <AdPreview />

        </form>
        
        </div>
    )
}
export default CreateAdsSecondStage;