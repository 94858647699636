import React from 'react'
import DashboardLayout from '../Dashboard/DashboardLayout';

import { Helmet } from 'react-helmet'
import PreviewOrder from '../../../components/PharmacyComponent/Order/PreviewOrder';

function PreviewOrders() {
  return (
    <DashboardLayout>
    <Helmet>
        <title>
           TMR | Preview Order 
        </title>
    </Helmet>
    <div className="">
       <PreviewOrder/>
    </div>
</DashboardLayout>
  )
}

export default PreviewOrders