import React from 'react';
import Copy from '../../../Images/copy.svg';
import { toast } from 'react-toastify';
import { usePharmacyAuthStore } from '../../../store/pharmacy/auth';
import { standardAmountFormat } from '../../../utils/helpers';

type VoucherCardProps = {
    code: string;
    amount: number;
};

const VoucherCard: React.FC<VoucherCardProps> = ({ code, amount }) => {
    const currency = usePharmacyAuthStore((state) => state.pharmacy.currency);

    // Split the formatted amount to get currency and amount separately
    const formattedAmount = standardAmountFormat(amount || 0, currency);
    const [formattedCurrency, formattedValue] = formattedAmount.split(' ');

    return (
        <div className="border-[1px] h-fit border-solid border-[#F5F5F5] p-2 bg-white rounded-lg w-fit">
            <div className="bg-[#F5F5F5] p-2 rounded-lg w-[132px] ">
                <div className="flex justify-between">
                    <p className="text-Tmred text-[0.75rem] ">{code}</p>
                    <div
                        className="rounded-[50%] cursor-pointer hover:bg-[#F5F5F550]"
                        onClick={() => {
                            navigator.clipboard.writeText(code);
                            toast.success('Voucher code copied successfully');
                        }}
                    >
                        <img src={Copy} alt="copy-icon" />
                    </div>
                </div>
                <div className="text-black font-[600] text-[1.375rem] leading-7 mt-2 flex flex-col gap-2 flex-wrap">
                    <h1 className="text-sm lg:text-[22px]">{formattedCurrency} <br className='mt-2'/>{formattedValue}</h1>
                  
                </div>
            </div>
        </div>
    );
};

export default VoucherCard;
