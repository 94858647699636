import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import hospitalService from '../../../../services/hospitalService';
import { formatDateDmy, formatDatesDmy } from '../../../../utils/helpers';
// import ViewMedicationModal from '../PatientModals/ViewMedicationModal';
import { ToastContainer } from 'react-toastify';

interface PatientProps {
    tmrNum?: any;
}
interface MedicalReport {
    id: number;
    createdAt: string;
}

interface MedicalReportHistory {
    patientTmrNumber: string;
    medicalReport: MedicalReport[]; // Define the medicalReport property here
}
interface Prescription {
    drugName: string;
    dosage: string;
    sideEffects: string;
    doctorsNote: string;
}

interface Medication {
    id: number;
    medicalReportId: number;
    patientTmrNumber: string;
    prescriptions: Prescription;
    createdAt: string;
}
interface MedicalReportDetails {
    tmrNumber: string;
    medicalReportId: string | number | null;
    medicalReport: {
        createdAt: string;
        doctorName: string;
        specialty: string;
        diagnosis: {
            observation: string;
            treatment: string;
            verdicts: string;
            // Add other properties as needed
        };
        // Add other properties of the medical report as needed
    } | null;
    medications: Medication[];
}
const useMedicalReportDetails = (
    tmrNumber: string,
    medicalReportId: string | number | null
) => {
    return useQuery<MedicalReportDetails>(
        ['medicalReport', tmrNumber, medicalReportId], // Unique query key
        async () => {
            // Fetch medical report details using HospitalService
            return await hospitalService.getMedicalReportDetails(
                tmrNumber,
                medicalReportId
            );
        }
    );
};

const useMedicalReportHistory = (patientTmrNumber: string) => {
    return useQuery<MedicalReportHistory>(
        ['medicalReportHistory', patientTmrNumber], // Unique query key
        async () => {
            return await hospitalService.getMedicalReportHistory(
                patientTmrNumber
            );
        }
    );
};
const DiagnosesTab: React.FC<PatientProps> = ({ tmrNum }) => {
    const [access, setAccess] = useState(true);
    const [activeItemId, setActiveItemId] = useState<number | null>(null);
    const [open, setOpen] = useState(false);
    const [medicalReportId, setMedicalReportId] = useState<
        string | number | null
    >(1);
    const {
        data: historyData,
        isError: historyError,
        isLoading: historyLoading,
    } = useMedicalReportHistory(tmrNum);
    const history = historyData?.medicalReport;
    const {
        data: reportDetails,
        isError: detailsError,
        isLoading: detailsLoading,
        refetch: refetchReportDetails,
    } = useMedicalReportDetails(tmrNum, medicalReportId);
    // console.log(reportDetails);
    const handleReportClick = (id: number) => {
        setMedicalReportId(id);
        setActiveItemId(id);
    };
    const openModal = () => {
        setOpen(true);
    };
    const closeModal = () => {
        setOpen(false);
    };
    useEffect(() => {
        // Set the first item as active when component mounts
        if (history && history.length > 0) {
            const firstItemId = history[0].id;
            setActiveItemId(firstItemId);
            setMedicalReportId(firstItemId);
        }
    }, [history]);

    useEffect(() => {
        // Fetch report details when medicalReportId changes
        if (medicalReportId) {
            refetchReportDetails();
        }
    }, [medicalReportId]);
    // console.log(historyData?.medicalReport);
    const createdAtString = reportDetails?.medicalReport?.createdAt; // Assuming medicalReportDetails is your data object
    let formattedDate: string;

    if (createdAtString) {
        const createdAtDate = new Date(createdAtString);
        if (!isNaN(createdAtDate.getTime())) {
            formattedDate = createdAtDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
            });
        } else {
            formattedDate = 'Invalid Date';
        }
    } else {
        formattedDate = 'Unknown';
    }

    // console.log(reportDetails?.medicalReport?.createdAt);

    if (historyLoading || detailsLoading) {
        return <div>Loading...</div>;
    }

    if (historyError || detailsError) {
        return <div>An error occurred</div>;
    }

    return (
        <div className="bg-white p-1 sm:p-4 max-w-[590px]">
            <ToastContainer />
            {/* {access ? ( */}
            <div className="flex justify-between space-x-5">
                <div className="flex-1">
                    <h2 className="text-[22px] font-normal text-lightBrown mb-2">
                        Diagnoses
                    </h2>
                    {/* Diagnoses content goes here */}
                    {!reportDetails?.medicalReport && (
                        <div>
                            <h2>EMPTY!!!!</h2>
                        </div>
                    )}
                    {reportDetails && reportDetails.medicalReport && (
                        <>
                            {' '}
                            <div className="pb-5 border-b-[1px] border-brown100">
                                <div className="flex flex-col sm:flex-row justify-between mb-3">
                                    <div className="flex items-center space-x-1 sm:space-x-2">
                                        <p className="text-[11px] font-medium text-dark100">
                                            Date:
                                        </p>
                                        <p className="text-xs font-medium text-lightBrown">
                                            {reportDetails?.medicalReport
                                                ?.createdAt
                                                ? formatDateDmy(
                                                      reportDetails
                                                          ?.medicalReport
                                                          ?.createdAt
                                                  )
                                                : 'N/A'}
                                        </p>
                                    </div>
                                    <div className="flex items-center space-x-1 sm:space-x-2">
                                        <p className="text-[11px] font-medium text-dark100">
                                            Time:
                                        </p>
                                        <p className="text-xs font-medium text-lightBrown">
                                            {reportDetails.medicalReport &&
                                            reportDetails?.medicalReport
                                                ?.createdAt
                                                ? (() => {
                                                      const createdAtDate =
                                                          new Date(
                                                              reportDetails
                                                                  ?.medicalReport
                                                                  ?.createdAt ??
                                                                  ''
                                                          );
                                                      const hours =
                                                          createdAtDate.getHours();
                                                      const minutes =
                                                          createdAtDate.getMinutes();
                                                      const formattedHours =
                                                          hours % 12 === 0
                                                              ? 12
                                                              : hours % 12;
                                                      const amPm =
                                                          hours >= 12
                                                              ? 'pm'
                                                              : 'am';
                                                      return `${formattedHours}:${
                                                          minutes < 10
                                                              ? '0'
                                                              : ''
                                                      }${minutes}${amPm}`;
                                                  })()
                                                : 'N/A'}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-col sm:flex-row justify-between">
                                    <div className="flex space-x-1 sm:space-x-2">
                                        <p className="text-[11px] font-medium text-dark100">
                                            Diagnosed By:{' '}
                                        </p>
                                        <p className="text-xs font-medium text-lightBrown">
                                            {
                                                reportDetails.medicalReport
                                                    ?.doctorName
                                            }
                                        </p>
                                    </div>
                                    <div className="flex space-x-1 sm:space-x-2">
                                        <p className="text-[11px] font-medium text-dark100">
                                            Specialty:
                                        </p>
                                        <p className="text-xs font-medium text-lightBrown">
                                            {
                                                reportDetails.medicalReport
                                                    ?.specialty
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5">
                                <p className="text-[11px] font-medium text-dark100">
                                    Medical Verdict
                                </p>
                                <p className="text-xs font-medium text-lightBrown mt-2">
                                    {reportDetails?.medicalReport?.diagnosis
                                        ?.verdicts
                                        ? reportDetails?.medicalReport
                                              ?.diagnosis?.verdicts
                                        : ''}
                                </p>
                            </div>
                            <div className="mt-5">
                                <p className="text-[11px] font-medium text-dark100">
                                    Observation:
                                </p>
                                <p className="text-xs font-medium text-lightBrown mt-2">
                                    {reportDetails?.medicalReport?.diagnosis
                                        ?.observation
                                        ? reportDetails?.medicalReport
                                              ?.diagnosis?.observation
                                        : ''}
                                </p>
                            </div>
                            <div className="mt-5">
                                <p className="text-[11px] font-medium text-dark100">
                                    Treatment:
                                </p>
                                <ol className="list-decimal list-inside">
                                    <li className="text-xs font-medium text-lightBrown mt-2">
                                        {reportDetails?.medicalReport?.diagnosis
                                            ?.treatment
                                            ? reportDetails?.medicalReport
                                                  ?.diagnosis?.treatment
                                            : ''}
                                    </li>
                                </ol>
                            </div>
                            {/* <div>
                                <h2 className="text-[22px] font-normal text-lightBrown mt-4">
                                    Medication
                                </h2>
                                <ul>
                                    {reportDetails &&
                                        reportDetails.medicalReport && (
                                            <div>
                                                {reportDetails.medications.map(
                                                    (medication) => (
                                                        <div
                                                            key={medication.id}
                                                            className="mt-2 flex items-center justify-between"
                                                        >
                                                            <div className="flex items-center gap-2">
                                                                <img
                                                                    src={
                                                                        drugType
                                                                    }
                                                                    alt="drugPicture"
                                                                />
                                                                <div>
                                                                    <p className="text-xs font-medium text-lightBrown">
                                                                        {
                                                                            medication
                                                                                .prescriptions
                                                                                .drugName
                                                                        }
                                                                    </p>
                                                                    <p className="text-[11px] font-medium mt-2 text-lightBrown">
                                                                        Dosage:{' '}
                                                                        {
                                                                            medication
                                                                                .prescriptions
                                                                                .dosage
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <button
                                                                onClick={
                                                                    openModal
                                                                }
                                                                type="button"
                                                                className="text-Tmred text-xs cursor-pointer"
                                                            >
                                                                View
                                                            </button>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        )}
                                </ul>
                            </div> */}
                            {/* {open && (
                                <ViewMedicationModal
                                    closeModal={closeModal}
                                    medications={reportDetails?.medications}
                                />
                            )} */}
                        </>
                    )}
                </div>
                <div className="text-center bg-lightGray200 px-2 rounded-lg">
                    <p className="mb-3 text-xs pt-1">History</p>
                    <ul>
                        {history?.map((report) => {
                            return (
                                <li
                                    className={`p-1 my-2 text-[11px] rounded-md font-medium ${
                                        activeItemId === report.id
                                            ? 'bg-lightPink100 '
                                            : ''
                                    } text-dark100`}
                                    style={{ cursor: 'pointer' }}
                                    key={report.id}
                                    onClick={() => handleReportClick(report.id)}
                                >
                                    {formatDateDmy(report.createdAt)}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
            {/* // ) : (
            //     <div className="flex justify-center items-center my-20 ">
            //         <button
            //             type="button"
            //             className="bg-Tmred px-4 py-2 text-white text-base font-normal rounded-lg"
            //         >
            //             Request Data Access
            //         </button>
            //     </div>
            // )} */}
        </div>
    );
};
export default DiagnosesTab;
