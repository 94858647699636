import React from 'react';

import DashboardLayout from '../Dashboard/DashboardLayout';
import {Helmet} from 'react-helmet'
import PreviewPage from '../../../components/PharmacyComponent/Inventory/PreviewInventory/PreviewPage';



const Preview = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Preview Inventory
                </title>
            </Helmet>
          <PreviewPage/>
        </DashboardLayout>
    );
};

export default Preview;
