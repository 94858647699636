import React, { useRef, useState, Fragment,useEffect } from 'react';
import profile from '../../../Images/17.png';
import ImageUpload from './ImageUpload';
// import ImageUpload from '../../../components/UserAdmin/ImageUpload';
import { IoIosArrowRoundUp } from 'react-icons/io';
import gallery from '../../../Images/gallery.png';
import { BsTrash } from 'react-icons/bs';
import { SlRefresh } from 'react-icons/sl';
import Modal from '../../../reusable/Modal';
import { useHospitalAuthStore } from '../../../store/hospital/auth';
import hospitalService from '../../../services/hospitalService';
import { useMutation } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logger from '../../../utils/logger';
import ErrorState from '../../../reusable/ErrorState';
const Media = () => {
      const hospitalAuthStore = useHospitalAuthStore((state) => state.hospital);
    const [buttonEnabled, setButton] = useState(false);
    const initialImages = [...hospitalAuthStore.metadata.gallery,null,null,null,null,null].slice(0,5) ;
    const [uploadedImages, setUploadedImages] = useState(initialImages );
    const initialEmptyImage= Array(hospitalAuthStore.metadata.gallery.length).fill(null) 
    console.log(hospitalAuthStore.metadata.gallery)
    const [emptyGallery, setEmptyGallery] = useState(initialEmptyImage)
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [selectedImage, setSelectedImage] = useState(profile);
    const [isImageUploaded, setIsImageUploaded] = useState(false);
    const [uploadedImageFile, setUploadedImageFile] = useState<File | null>(null);
    const [selectedGalleryImages, setSelectedGalleryImages] = useState<string[]>([]); // for displaying on frontend
    const [uploadedGalleryImageFiles, setUploadedGalleryImageFiles] = useState<File[]>([]); // for sending to backend
    const [showModal, setShowModal] = useState(false);
    
    const mutation = useMutation(
        (data: any) => hospitalService.editHospitalMedia(data),
        {
            onSuccess: (response) => {
                const responseData = response?.data?.payload?.data?.hospital?.metadata || {};
                const { gallery } = responseData;

                console.log('gallery', { gallery });

                if (gallery) {
                    // Update the state with the gallery images
                    setUploadedImages(gallery.slice(0, 5) || Array(5).fill(null));
                }
            },
        }
    );
    const deleteGalleryImage = useMutation(
        (data: any) => hospitalService.deleteHospitalMedia(data),
        {
            onSuccess: (data) => {
                setIsLoading(false);
                console.log('Delete success:', data);
                // Refetch the list of gallery images
          
                let payload: any = data;
                hospitalAccountStore.updateHospital(payload.data.payload.data.hospital);
                const message = payload.data.message;
                logger(message);
                toast.success("Image deleted successfully");
                setTimeout(() => {
                    toast.dismiss();
                }, 2000);
       

            },
            onError: (error: any) => {
                console.error('Delete image error:', error);
                console.log(error);
                
                setIsLoading(false);
                setTimeout(() => {
                    setErrorMessage(error.response.data.error.message);
                    toast.error(error.response.data.error.message);
                }, 1000);
            },
        }
    );
    const hospitalAccountStore = useHospitalAuthStore((state) => state);
   
    const [deleteConfirmation, setDeleteConfirmation] = useState<{
        index: number;
        callback: () => void;
    } | null>(null);
   
    const handleImageChange = (image: File) => {
        const imageUrl = URL.createObjectURL(image);

        setSelectedImage(imageUrl);
        setUploadedImageFile(image); // Set the file to be sent to the backend
        setIsImageUploaded(true);
        setButton(true);
    };
    // useEffect(() => {
    //     console.log('Mutation Data:', mutation);
    
    //     const responseData = (mutation?.data as any)?.payload?.data?.hospital?.metadata || {};
    //     const { gallery } = responseData;
    
    //     console.log('gallery', { gallery });
    
    //     if (gallery) {
    //         // Update the state with the gallery images
    //         setUploadedImages(gallery.slice(0, 5) || Array(5).fill(null));
    //     }
    // }, [mutation]);
    
      
      
    // useEffect(() => {
    //     if (mutation.isSuccess) {
    //         setIsLoading(false);
    //         console.log('success', mutation.data);
    //         let payload: any = mutation.data;
    //         hospitalAccountStore.updateHospital(payload.data.payload.data);
    //         const data = payload.data;
    //         logger(data.message);
    //         // Show success message using Toastify
    //         toast.success('Media updated successfully');
    //         setTimeout(() => {
    //             toast.dismiss(); // Dismiss the toast after 3000 milliseconds
               
    //         }, 2000);
    //     }
    // }, [mutation.isSuccess, mutation.data]);

    // useEffect(() => {
    //     if (mutation.isError) {
    //         setIsLoading(false);
    //         let err: any = mutation.error;
    //         setErrorMessage(err.response.data.error.message);
    //     } else {
    //         setErrorMessage('');
    //     }
    // }, [mutation.isError, mutation.error]);
console.log(hospitalAuthStore.metadata.gallery )
    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            console.log('success', mutation.data);
            let payload: any = mutation.data;
            // Check if the payload has the expected structure
            const data = payload?.data?.payload?.data;
    
            if (data) {
                hospitalAccountStore.updateHospital(data.hospital);
                logger(data.message);
                // Show success message using Toastify
                toast.success('Media updated successfully');
                setTimeout(() => {
                    toast.dismiss(); // Dismiss the toast after 3000 milliseconds
                }, 2000);
            } else {
                console.error('Unexpected payload structure:', payload);
            }
        }
    
        if (mutation.isError) {
            setIsLoading(false);
            let err: any = mutation.error;
            setErrorMessage(err.response?.data?.error?.message || 'An error occurred');
        } else {
            setErrorMessage('');
        }
    }, [mutation.isSuccess, mutation.data, mutation.isError, mutation.error]);
    
    useEffect(() => {
        console.log('Hospital Auth Store Logo:', hospitalAuthStore.logo);
        setSelectedImage(hospitalAuthStore.logo || profile);
    }, [hospitalAuthStore.logo]);
 
    const handleGalleryImageChange = (image: File, index: number) => {
        const imageUrl = URL.createObjectURL(image);
    
        // Update the selectedGalleryImages state with the image URL
        setSelectedGalleryImages((prevImages) => {
            const newImages = [...prevImages];
            newImages[index] = imageUrl;
            return newImages;
        });
    
        // Update the uploadedGalleryImageFiles state with the file data
        setUploadedGalleryImageFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            newFiles[index] = image;
            return newFiles;
        });
    
        setButton(true);
    };
    const handleFileUpload = (
        event: React.ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        const file = event.target.files?.[0];
    
        if (file) {
            const newImages = [...uploadedImages];
            newImages[index] = URL.createObjectURL(file);
            setUploadedImages(newImages);
    
            // Call handleGalleryImageChange with the uploaded file
            handleGalleryImageChange(file, index);
        }
        setButton(true);
    };
   
    const handleReset = () => {
        setUploadedImages(initialImages);
    };
    
    const handleUpdate = () => {
        setIsLoading(true);
        const formData = new FormData();
        
        // Append the logo
        formData.append('logo', uploadedImageFile || '') 
    
        // // Append each gallery image as a separate file
        // uploadedGalleryImageFiles.forEach((file, index) => {
        //     formData.append('gallery', file, `gallery_${index}.jpg`);
        // });
        uploadedGalleryImageFiles.forEach((file, index) => {
            formData.append("gallery", file);
        });
        // Ensure the correct headers
        const config:any = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
    
        mutation.mutate(formData, config);
        setButton(false);
    };
    


    const handleDelete = (index: number) => {
        const imageUrlToDelete = uploadedImages[index];
        if (imageUrlToDelete) {
            setDeleteConfirmation({
                index,
                callback: () => handleDeleteCallback(index),
            });
    
          
           setButton(true);
            
            setShowModal(true);
        }
    };
    
    const handleDeleteCallback = (index: number) => {

        const newImages: any[] = [...uploadedImages];
        const imageUrlToDelete = newImages[index];
    console.log({imageUrlToDelete})
        if (imageUrlToDelete) {
            newImages[index] = null;
            setUploadedImages(newImages);
            setShowModal(false);
            let data = {
                "image_urls": [
                  imageUrlToDelete
                ]
            };
            
            // Optimistic update
            deleteGalleryImage.mutate({
                "image_urls": [
                  imageUrlToDelete
                ]
            });
            console.log(data)
            // hospitalAccountStore.updateHospital(newImages);
        } else {
            // Handle the case where the image URL to delete is not found
            console.error('Image URL to delete not found.');
        }
    };
    
    
    

    const handleReplace = (index: number) => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
            fileInputRef.current.click();

            const handleChange = (event: unknown) => {
                handleFileUpload(
                    event as React.ChangeEvent<HTMLInputElement>,
                    index
                );
                fileInputRef.current?.removeEventListener(
                    'change',
                    handleChange
                );
            };

            fileInputRef.current.addEventListener('change', handleChange, {
                once: true,
            });
        }
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const DeleteModal = (index) => {
        setShowModal(true);
        handleDelete(index);
    };

    return (
        <Fragment>
            <ToastContainer/>
            <div className="bg-white  rounded-lg shadow-sm  ">
            {errorMessage && <ErrorState message={errorMessage} />}
                <div className="flex flex-col sm:flex-row w-full  justify-between gap-4 sm:gap-10">
                    <div className="flex flex-col sm:w-[245px] ">
                        <h1 className="font-medium sm:text-[22px] text-[18px] bg-lightTmrRed p-2 text-center sm:w-[245px]">
                            Hospital Logo
                        </h1>
                        <ImageUpload
                        className="rounded-full border items-center"
                            onImageChange={handleImageChange}
                            selectedImageProp={selectedImage? selectedImage : hospitalAuthStore.logo}
                        />
                    </div>
                    <div className="flex flex-col flex-1  sm:w-[664px]">
                        <div className="flex items-center">
                            <h1 className="font-medium sm:text-[22px] text-[18px] p-2">
                                Hospital Gallery
                            </h1>
                            <span className="ml-auto flex justify-end">
                                {uploadedImages.filter(Boolean).length}/5 Max
                            </span>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 w-full py-4">
                            {uploadedImages.map((image, index) => (
                                <div
                                    key={index}
                                    className="w-[200px] h-[304px] bg-[#F5F5F5] border-[#D8C2BF] rounded-2xl flex items-center flex-col gap-3 justify-center relative"
                                    onMouseEnter={() => setHoveredIndex(index)}
                                    onMouseLeave={() => setHoveredIndex(null)}
                                >
                                    {image && (
                                        <>
                                            <img
                                                src={image}
                                                alt=""
                                                className="w-full h-full object-cover max-w-[100%] rounded-2xl"
                                            />
                                            {hoveredIndex === index && (
                                                <div
                                                    className={`absolute inset-0 flex items-center flex-col gap-3 justify-center bg-opacity-75 bg-[#F5F5F5] ${
                                                        hoveredIndex === index
                                                            ? 'visible'
                                                            : 'hidden'
                                                    }`}
                                                >
                                                    {/* <button
                                                        onClick={() =>
                                                            handleReplace(index)
                                                        }
                                                        className="flex justify-center gap-3 rounded-lg items-center py-2 border text-[#fff] bg-Tmred w-[130px]"
                                                    >
                                                        <SlRefresh />
                                                        Replace
                                                    </button> */}
                                                    <button
                                                        onClick={() =>
                                                            DeleteModal(index)
                                                        }
                                                        className="flex justify-center gap-3 rounded-lg items-center py-2 border text-[#fff] w-[130px] bg-Tmred"
                                                    >
                                                        <BsTrash />
                                                        Delete
                                                    </button>
                                                </div>
                                            )}
                                        </>
                                    )}
                                    {!uploadedImages[index] && (
                                        <img src={gallery} alt="" />
                                    )}
                                    {!image && (
                                        <label
                                            htmlFor={`uploadButton${index}`}
                                            className="cursor-pointer"
                                        >
                                            <input
                                                type="file"
                                                id={`uploadButton${index}`}
                                                style={{ display: 'none' }}
                                                onChange={(event) =>
                                                    handleFileUpload(
                                                        event,
                                                        index
                                                    )
                                                }
                                                ref={(input) =>
                                                    (fileInputRef.current =
                                                        input)
                                                }
                                            />
                                            <div className="flex justify-center rounded-lg items-center py-2 border border-[#857371] w-[141px]">
                                                <IoIosArrowRoundUp
                                                    color="#C00010"
                                                    size={'20px'}
                                                />
                                                <span className="text-Tmred text-xs font-medium ml-1">
                                                    Upload
                                                </span>
                                            </div>
                                        </label>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="mt-[16px] items-center gap-4 flex justify-end border-t py-4">
                    <button
                        onClick={handleReset}
                        className={`border-0 text-[16px] ${
                            buttonEnabled
                                ? 'text-Tmred hover:cursor-pointer '
                                : 'text-lightGray cursor-not-allowed'
                        }`}
                    >
                        Reset to previous
                    </button>
                    <button
                        onClick={handleUpdate}
                        className={`text-[16px] duration-300 rounded-lg px-6 py-1 ${
                            buttonEnabled
                                ? 'bg-Tmred text-white hover:cursor-pointer '
                                : 'bg-[#1D1B201F] text-lightGray cursor-not-allowed'
                        }`}
                    >
                       {isLoading ? 'Updating...' : 'Update'}
                    </button>
                </div>
            </div>
            <Modal
                isVisible={showModal}
                onClose={closeModal}
                onSave={() =>
                    deleteConfirmation && deleteConfirmation.callback()
                }
                buttonEnabled={buttonEnabled}
                setButton={setButton}
                saveText="Delete"
                closeText={'No, don’t delete'}
                showCloseButtonBorder={false}
            >
                <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2 sm:w-[312px] sm:h-[112px]">
                    <h2 className="font-semibold text-2xl mb-5">
                        Delete Image
                    </h2>
                    <p className=" text-[14px] font-normal text-[#534341]">
                        Are you sure you want to delete this image?
                    </p>
                </div>
            </Modal>
        </Fragment>
    );
};

export default Media;
