import React from 'react';
import DashboardLayout from '../Dashboard/DashboardLayout';
import ActiveState from '../../../components/Appointment/ActiveState';
import { Helmet } from 'react-helmet'

const Appointment = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                   TMR | Appointment
                </title>
            </Helmet>
            <div className="">
                <ActiveState />
            </div>
        </DashboardLayout>
    );
};

export default Appointment;
