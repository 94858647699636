import React, { useEffect, useState } from 'react';
import Input from '../../../../reusable/Forms/Input';
import { useRemarkStore } from '../../../../store/hospital/auth';

interface FormData {
    remark: string;
    testName: string;
}

const CloseRemark = ({ closeModal, handleClose, testNamed }) => {
    const { remark, setRemark } = useRemarkStore();
    const [button, setButton] = useState(false);
    const [formData, setFormData] = useState<FormData>({
        testName: testNamed,
        remark: '',
    });

    const handlesubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const dataToSend = {
            testResult: formData.testName,
            remark: formData.remark, // Using the remark from the formData state
        };
        setFormData({
            remark: '', // Resetting the remark field after submission
            testName: '',
        });
        closeModal();
    };

    const handleChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
    ) => {
        const { name, value } = e.target;
        setFormData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
        setRemark(value);
    };

    useEffect(() => {
        if (formData.remark !== '' && formData.testName !== '') {
            setButton(true);
        } else {
            setButton(false);
        }
    }, [formData.testName, formData.remark]);

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-[#2826265E] bg-opacity-75 z-50 ">
            <form
                onSubmit={handlesubmit}
                className="bg-white p-2 mx-1 sm:mx-0 sm:p-6 rounded-[28px] w-full max-w-[801px]"
            >
                <h6 className="text-2xl font-semibold text-lightBrown">
                    Final Remark
                </h6>
                <p className="font-normal text-sm text-dark100 my-3">
                    Kindly provide a concise and informative final remark
                    summarizing the overall assessment of the test results.
                </p>
                <Input
                    name="testName"
                    label="Test Name"
                    value={formData.testName}
                    onChange={handleChange}
                    className="w-full border border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider"
                    disabled
                />
                <div className="my-8">
                    <textarea
                        name="remark" // Ensure that the name is set to "remark"
                        placeholder="Remark"
                        required={true}
                        value={formData.remark}
                        onChange={handleChange}
                        className="w-full border h-[109px] border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider"
                    ></textarea>
                </div>
                <div className="flex justify-end space-x-6 mt-6">
                    <button
                        onClick={handleClose}
                        className="py-[10px] px-6 border-[1px] rounded-lg text-Tmred text-sm font-medium border-lightBrown100"
                    >
                        Close
                    </button>
                    <button
                        disabled={!button}
                        className={`py-[10px] px-6 rounded-lg text-sm font-medium ${
                            button
                                ? 'bg-Tmred text-white'
                                : 'bg-[#1D1B201F] text-[#999797]'
                        }`}
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CloseRemark;
