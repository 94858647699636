import React from 'react'

function VoucherEyeOpen() {
  return (
    <svg width="36" height="42" viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_22719_308697)">
<rect x="3" y="6" width="30" height="30" rx="15" fill="#D8C2BF"/>
<path d="M20.6849 20.9995C20.6849 22.4845 19.4849 23.6845 17.9999 23.6845C16.5149 23.6845 15.3149 22.4845 15.3149 20.9995C15.3149 19.5145 16.5149 18.3145 17.9999 18.3145C19.4849 18.3145 20.6849 19.5145 20.6849 20.9995Z" stroke="#534341" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.9999 27.2035C20.6474 27.2035 23.1149 25.6435 24.8324 22.9435C25.5074 21.886 25.5074 20.1085 24.8324 19.051C23.1149 16.351 20.6474 14.791 17.9999 14.791C15.3524 14.791 12.8849 16.351 11.1674 19.051C10.4924 20.1085 10.4924 21.886 11.1674 22.9435C12.8849 25.6435 15.3524 27.2035 17.9999 27.2035Z" stroke="#534341" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_22719_308697">
<rect x="3" y="6" width="30" height="30" rx="15" fill="white"/>
</clipPath>
</defs>
</svg>

  )
}

export default VoucherEyeOpen