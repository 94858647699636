import { Fragment, useEffect, useState } from 'react';
import { ArrowLeft, CloseCircle } from 'iconsax-react';
import Passcode from '../../../reusable/Passcode';
import BlockButton from '../../../reusable/BlockButton';
import { useMutation } from 'react-query';
import hospitalService from '../../../services/hospitalService';
import logger from '../../../utils/logger';
// import ErrorState from '../../../reusable/ErrorState';
import { useHospitalAuthStore } from '../../../store/hospital/auth';
import { Eclipse1, Eclipse2 } from '../../../Images/Svgs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface propType {
    closeModal: () => void;
}

type ChangePasscodeProps = {
    code: string;
    token: string;
};

const ChangePasscode: React.FC<propType> = ({ closeModal }) => {
    const hospitalAuthStore = useHospitalAuthStore((state) => state);
    const [code, setCode] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isFirstScreen, setIsFirstScreen] = useState(true);
    const [originalPasscode, setOriginalPasscode] = useState('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const mutation = useMutation((data: ChangePasscodeProps) =>
        hospitalService.changePasscode(data)
    );

    useEffect(() => {
        if (code.length === 4) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [code]);
    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            console.log('success', mutation.data);
            let response: any = mutation.data;
            const data = response.data;
            logger(data.message);
            toast.success('Passcode updated successfully');
            setTimeout(() => {
                toast.dismiss(); // Dismiss the toast after 3000 milliseconds
                closeModal();   // Close the modal after the toast is dismissed
            }, 2000);
        }
    }, [mutation.isSuccess, mutation.data,closeModal]);

    useEffect(() => {
        if (mutation.isError) {
            setIsLoading(false);
            let err: any = mutation.error;
            setErrorMessage(err.response.data.error.message);
        } else {
            setErrorMessage('');
        }
    }, [mutation.isError, mutation.error]);

    const submitPassCode = (e: any) => {
        e.preventDefault();
        if (isFirstScreen) {
            setOriginalPasscode(code);
            setCode('');
            setIsFirstScreen(false);
        } else {
            if (code === originalPasscode) {
                setIsLoading(true);
                mutation.mutate({
                    code,
                    token: hospitalAuthStore.token,
                });
               
            } else {
                setErrorMessage('Passcodes do not match');
                setCode('');
                setIsFirstScreen(true);
            }
            return;
        }
    };
    const goBack = () => {
        if (!isFirstScreen) {
            setCode('');
            setIsFirstScreen(true);
        }
    };

    return (
        <Fragment>
            <ToastContainer/>
        <div className="fixed inset-0 flex items-center justify-center bg-[#2826265E] bg-opacity-75 z-50">
            <div className="bg-white p-6 rounded-[28px] w-full m-2 max-w-[480px] sm:m-0 relative">
                <div className="flex items-center justify-between mb-8">
                    <div className="flex items-center space-x-4">
                        {isFirstScreen ? (
                            ''
                        ) : (
                            <div
                                onClick={goBack}
                                className="bg-[#49454F1F] p-2 rounded-full cursor-pointer"
                            >
                                <ArrowLeft size="24" color="#534341" />
                            </div>
                        )}
                        <h6 className="text-[22px] font-medium text-lightBrown">
                            Change Passcode
                        </h6>
                    </div>
                    <div onClick={closeModal} className="cursor-pointer">
                        <CloseCircle size="32" color="#534341" />
                    </div>
                </div>
                <div>
                    <p className="font-medium text-base text-dark100">
                        {isFirstScreen
                            ? 'Enter new Passcode'
                            : 'Repeat Passcode'}
                    </p>
                    {/* {errorMessage && <ErrorState message={errorMessage} />} */}
                    <form onSubmit={submitPassCode}>
                        <Passcode passcode={code} setPasscode={setCode} />
                        <p className='font-semibold text-[14px] text-center text-[#C00010]'>{errorMessage && 'Passcodes do not match'}</p>
                        {isFirstScreen?
                        (<div className='flex py-4 items-center gap-2 w-full justify-center'>
                        <Eclipse1/>
                        <Eclipse2/>
                        </div>) : (<div className='flex items-center gap-2 py-4 w-full justify-center'>
                        <Eclipse1/>
                        <Eclipse1/>
                        </div>)}
                        <BlockButton
                            text={isFirstScreen ? 'Next' : 'Save Passcode'}
                            disabled={buttonDisabled}
                            type="submit"
                            isLoading={isLoading}
                        />
                    </form>
                </div>
            </div>
        </div>
        </Fragment>
    );
};

export default ChangePasscode;
