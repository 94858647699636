import React, { useState } from 'react';
import DiagnosesTab from './tabs/DiagnosesTab';
import AllergiesTab from './tabs/AllergiesTab';
import LabTestsTab from './tabs/LabTestsTab';
import { useHospitalAuthStore } from '../../../store/hospital/auth';
import { useQuery } from 'react-query';
import hospitalService from '../../../services/hospitalService';
import MedicationsTab from './tabs/MedicationsTab';

type Tab = 'diagnoses' | 'allergies' | 'labTests' | 'medications';

interface PatientProps {
    id: string;
    preMedicalForm?: any;
    tmrNum: string;
}

const MedicalRecordTabs: React.FC<PatientProps> = ({
    id,
    preMedicalForm,
    tmrNum,
}) => {
    const [activeTab, setActiveTab] = useState<Tab>('diagnoses');
    const hospitalAuthStore = useHospitalAuthStore((state) => state.user);

    const handleTabClick = (tab: Tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="flex flex-col sm:flex-row ">
            <div className="flex flex-col justify-start w-[30%]">
                <div className="flex sm:flex-col flex-row space-x-2 sm:space-x-0 sm:space-y-2">
                    <button
                        className={`text-left p-2  text-xs sm:text-sm font-medium text-lightBrown ${
                            activeTab === 'diagnoses' &&
                            'bg-white sm:rounded-l-2xl font-normal'
                        }`}
                        onClick={() => handleTabClick('diagnoses')}
                    >
                        Medical Report
                    </button>
                    <button
                        className={`text-left p-2  text-xs sm:text-sm font-medium text-lightBrown ${
                            activeTab === 'allergies' &&
                            'bg-white sm:rounded-l-2xl font-normal'
                        }`}
                        onClick={() => handleTabClick('allergies')}
                    >
                        Allergies
                    </button>
                    <button
                        className={`text-left p-2  text-xs sm:text-sm font-medium text-lightBrown ${
                            activeTab === 'labTests' &&
                            'bg-white sm:rounded-l-2xl font-normal'
                        }`}
                        onClick={() => handleTabClick('labTests')}
                    >
                        Lab Tests
                    </button>
                    <button
                        className={`text-left p-2  text-xs sm:text-sm font-medium text-lightBrown ${
                            activeTab === 'medications' &&
                            'bg-white sm:rounded-l-2xl font-normal'
                        }`}
                        onClick={() => handleTabClick('medications')}
                    >
                        Medications
                    </button>
                </div>
            </div>

            <div className="w-full">
                {/* check if current hospital id matches defaultHospital id */}
                {/* {hospitalAuthStore.hospitalId === id ? ( */}
                <>
                    {activeTab === 'diagnoses' && (
                        <DiagnosesTab tmrNum={tmrNum} />
                    )}
                    {activeTab === 'allergies' && (
                        <AllergiesTab preMedicalForm={preMedicalForm} />
                    )}
                    {activeTab === 'labTests' && (
                        <LabTestsTab tmrNum={tmrNum} />
                    )}
                    {activeTab === 'medications' && (
                        <MedicationsTab tmrNum={tmrNum} />
                    )}
                </>
                {/* ) : ( */}
                {/* <div className="flex items-center justify-center text-center w-full">
                        <button className="flex items-center justify-center text-center p-2 bg-tmrRed text-[#fff] w-fit rounded-lg">
                            Request Data Access
                        </button>
                    </div>
                )} */}
            </div>
        </div>
    );
};
export default MedicalRecordTabs;
