import React, { useState, Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactFlagsSelect from 'react-flags-select';
import Layout from '../Layout';
import AuthHeader from '../../../reusable/AuthHeader';
import arrowLeft from './../../../Images/arrow-left.svg';
import count1 from './../../../Images/count1.svg';
import CreateAcctTwo from './CreateAcctTwo';
import CreateAcctThree from './CreateAcctThree';
import { resolveCountryCode, resolveCountryNameToCode } from '../../../utils/countryState';
import Input from '../../../reusable/Forms/Input';
import { Link } from 'react-router-dom';
import BlockButton from '../../../reusable/BlockButton';
import { Helmet } from 'react-helmet';
import goBack from '../../../utils/goBack';
import patientService from '../../../services/patientService';
import { useQuery } from 'react-query';
import Lottie from 'lottie-react';
import loadingState from '../../../Images/loading.json';

type RegFormData = {
    countryCode: string;
    countryStates: object[];
    country: string;
    referralCode: string;
    name: string;
    email: string;
    phoneNumber: string;
    state: string;
    city: string;
    address: string;
    coordinates: string;
};

const convertCountryNamesToCodes = (countries) => {
    return countries.map(({ country }) => resolveCountryNameToCode(country) || '');
};


const CreateAcct = () => {
    const [countryCode, setCountryCode] = useState<string>('NG');
    const [showCreateAcctTwo, setShowCreateAcctTwo] = useState(false);
    const [showThirdComponent, setShowThirdComponent] = useState(false);
    const [countryCodes, setCountryCodes] = useState<string[]>([]);
    const defaultCountry = resolveCountryCode('NG');

    const navigate = useNavigate();

    const handleCountryChange = (code: string) => {
        const newCountry = resolveCountryCode(code);
        setCountryCode(code);
        setFormData((prevData: any) => ({
            ...prevData,
            country: newCountry?.name || '',
            countryStates: newCountry?.states || [],
        }));
    };

    const [formData, setFormData] = useState<RegFormData>({
        countryCode,
        countryStates: defaultCountry?.states || [],
        country: defaultCountry?.name || '',
        referralCode: '',
        name: '',
        email: '',
        phoneNumber: '',
        state: '',
        city: '',
        address: '',
        coordinates: '',
    });

    const changeReferralCode = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prevData) => ({
            ...prevData,
            referralCode: e.target.value,
        }));
    };

    const handleBackComponent = () => {
        setShowCreateAcctTwo(false);
        setShowThirdComponent(false);
    };

    const handleSubmitForm: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        if (countryCode !== '') {
            setShowCreateAcctTwo(true);
            setShowThirdComponent(true);
        }
    };

    const handleThirdComponent = () => {
        setShowCreateAcctTwo(false);
        setShowThirdComponent(true);
    };
    const handleSecondComponent = () => {
        setShowCreateAcctTwo(true);
        setShowThirdComponent(false);
    };

    const { data, isError, isLoading } = useQuery(
        'countries',
        patientService.getCountries
    );
    
    useEffect(() => {
        if (data && Array.isArray(data?.countries)) {
            const countryCodesArray = convertCountryNamesToCodes(data?.countries);
            setCountryCodes(countryCodesArray);
        }
    }, [data]);

    if (isError) {
        return <div>Error loading country, please refresh your page</div>;
    }

    return (
        <Fragment>
            <Helmet>
                <title>TMR | Create Hospital Account</title>
            </Helmet>
            {!showCreateAcctTwo && !showThirdComponent && (
                <Layout>
                    <div className="py-[60px]">
                        <AuthHeader />
                    </div>
                    <div className="flex flex-col py-[1.5rem] lg:p-0 justify-center items-center">
                        <div className="flex flex-col justify-center border border-[#00000033] bg-white rounded-[25px] p-[2rem] lg:px-[40px] lg:py-[40px] w-[85%] lg:max-w-[592px]">
                        {isLoading ? (
                            <div className="flex justify-center items-center py-10">
                                <Lottie
                                    animationData={loadingState}
                                    loop={true}
                                    className="lottie"
                                />
                            </div>
                        ) : (
                        <>
                            
                            <div className="flex flex-row items-center justify-between gap-5 sm:gap-14 md:gap-20">
                                <div className="flex">
                                    <button onClick={() => goBack(navigate)}>
                                        <img src={arrowLeft} alt="arrow-left" />
                                    </button>
                                </div>
                                <div>
                                    <h1 className=" font-semibold text-[1.2rem] lg:text-[32px] text-center ">
                                        Select Country
                                    </h1>
                                </div>
                                <div className="flex">
                                    <img src={count1} alt="count1" />
                                </div>
                            </div>

                            <form
                                className="flex flex-col gap-4 mt-[20px] items-center"
                                onSubmit={handleSubmitForm}
                                noValidate
                            >
                                <div className="w-full">
                                    <ReactFlagsSelect
                                    countries={countryCodes}
                                        selected={countryCode}
                                        searchable
                                        fullWidth={false}
                                        placeholder="Country"
                                        onSelect={handleCountryChange}
                                    />
                                </div>
                                <Input
                                    name="referralCode"
                                    label="Referral code (optional)"
                                    value={formData.referralCode}
                                    onChange={changeReferralCode}
                                    className={
                                        'w-full border border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider'
                                    }
                                />

                                <BlockButton
                                    text="Next"
                                    disabled={false}
                                    type="submit"
                                />

                                <p className="text-black mt-6">
                                    {' '}
                                    You have an account?{' '}
                                    <Link
                                        to="/"
                                        className="text-[#C00010] cursor-pointer hover:underline"
                                    >
                                        Login
                                    </Link>
                                </p>
                            </form>
                        </>
                        )}
                        </div>
                    </div>
                </Layout>
            )}
            {showCreateAcctTwo && (
                <CreateAcctTwo
                    showThirdComponent={handleThirdComponent}
                    showFirstComponent={handleBackComponent}
                    formData={formData}
                    setFormData={setFormData}
                />
            )}
            {!showCreateAcctTwo && showThirdComponent && (
                <CreateAcctThree
                    showCreateAcctTwo={handleSecondComponent}
                    formData={formData}
                    setFormData={setFormData}
                />
            )}
        </Fragment>
    );
};

export default CreateAcct;
