import { useState, useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import Layout from '../Layout';
import HospitalAuthHeader from '../../../reusable/HospitalAuthHeader';
import arrowLeft from './../../../Images/arrow-left.svg';
import count3 from './../../../Images/count3.svg';
import { Link, useNavigate } from 'react-router-dom';

import Input from '../../../reusable/Forms/Input';
import goBack from '../../../utils/goBack';
import { useMutation } from 'react-query';

import ErrorState from '../../../reusable/ErrorState';
import BlockButton from '../../../reusable/BlockButton';

import PasswordInput from '../../../reusable/Forms/PasswordInput';

import { Helmet } from 'react-helmet';
import ButtomLogo from '../../../reusable/ButtomLogo';
import { usePharmacyAuthStore } from '../../../store/pharmacy/auth';
import { pharmacyService } from '../../../services/pharmacyService';
import { pharmacyRoutes } from '../../../utils/pharmacyRouteNames';

type InputProps = {
    email: string;
    password: string;
    pharmacyId: string;
};

const LoginForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const navigate = useNavigate();
    const pharmacyAuthStore = usePharmacyAuthStore((state) => state);

    // useEffect(() => {
    //     if (!pharmacyAuthStore.pharmacy.name) {
    //         navigate('/');
    //     }
    // }, []);

    const {
        formState: { isDirty, isValid },
        handleSubmit,
        control,
    } = useForm<InputProps>();

    const mutation = useMutation((data: InputProps) =>
        pharmacyService.login(data)
    );

    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            console.log('success', mutation.data);
            let response: any = mutation.data;
            const data = response.data.payload;
            console.log(data);
            pharmacyAuthStore.updateUser(data.data.user);
            pharmacyAuthStore.updatePharmacy(data.data.pharmacy);
            pharmacyAuthStore.updateSubscription(data.data.subscription);
            pharmacyAuthStore.updateToken(data.token);
            console.log(data.data.subscription);
            if (Object.keys(data.data.subscription).length === 0) {
                navigate(pharmacyRoutes.subscriptionPlan);
                return;
            } else {
                navigate(pharmacyRoutes.dashboard);
            }
            if (!data.data.user.hasSetupPasscode) {
                navigate(pharmacyRoutes.setupLockcode);
            }
        }
    }, [mutation.isSuccess, mutation.data]);

    useEffect(() => {
        if (mutation.isError) {
            setIsLoading(false);
            let err: any = mutation.error;
            console.log('is error', err);
            setErrorMessage(err.response.data.error.message);
        } else {
            setErrorMessage('');
        }
    }, [mutation.isError, mutation.error]);

    const onSubmitForm: SubmitHandler<InputProps> = (data) => {
        console.log('submit form', data);
        setIsLoading(true);
        const loginData = {
            ...data,
            pharmacyId:
                pharmacyAuthStore.pharmacy.id || pharmacyAuthStore.pharmacy._id,
        };
        console.log(pharmacyAuthStore.pharmacy.id);
        mutation.mutate(loginData);
    };

    const onInvalid = (errors) => console.error(errors);

    return (
        <Layout>
            <Helmet>
                <title>TMR | Login</title>
            </Helmet>
            <div className=" flex items-center justify-center flex-col w-full overflow-x-hidden ">
                <HospitalAuthHeader
                    logo={pharmacyAuthStore.pharmacy.logo}
                    text={pharmacyAuthStore.pharmacy.tagline}
                    title={pharmacyAuthStore.pharmacy.name}
                />
                <div className="flex flex-col justify-center items-center sm:w-[592px]">
                    <div className="flex flex-col justify-center border border-[#00000033] bg-white rounded-[25px] px-[40px] py-[40px] w-full">
                        <div className="flex flex-row justify-between gap-1 sm:gap-5">
                            <div className="flex">
                                <button
                                    onClick={() => goBack(navigate)}
                                    className="flex"
                                >
                                    <img src={arrowLeft} alt="arrow-left" />
                                </button>
                            </div>
                            <div>
                                <h1 className=" font-semibold text-center text-[32px]  ">
                                    Login to Pharmacy
                                </h1>
                            </div>
                            <div className="flex">
                                <img src={count3} alt="count3" />
                            </div>
                        </div>
                        <p className="text-black text-center">
                            Please log in by entering your details.
                        </p>

                        {errorMessage && <ErrorState message={errorMessage} />}

                        <form
                            onSubmit={handleSubmit(onSubmitForm, onInvalid)}
                            noValidate
                            className="my-4 pt-5"
                        >
                            <Controller
                                name="email"
                                rules={{
                                    required: 'Email address is required',
                                }}
                                control={control}
                                render={({ field }) => (
                                    <Input label="Email Address" {...field} />
                                )}
                            />
                            <div className="flex flex-col items-start relative">
                                <Controller
                                    name="password"
                                    rules={{ required: 'Password is required' }}
                                    control={control}
                                    render={({ field }) => (
                                        <PasswordInput {...field} />
                                    )}
                                />
                            </div>
                            <div className="flex justify-end mb-4">
                                <Link
                                    to={pharmacyRoutes.forgotPassword}
                                    className="cursor-pointer hover:underline block text-right"
                                >
                                    Forgot Password?
                                </Link>
                            </div>

                            <BlockButton
                                text="Submit"
                                disabled={!isDirty || !isValid}
                                type="submit"
                                isLoading={isLoading}
                            />
                        </form>
                    </div>
                </div>
                <div className="ml-auto mt-10 w-full flex justify-end">
                <ButtomLogo/>
                </div>
            </div>
        </Layout>
    );
};

export default LoginForm;
