import React from 'react'
import DashboardLayout from '../../Dashboard/DashboardLayout'
import { Helmet } from 'react-helmet'
import RequestHistories from '../../../../components/Ambulance/RequestMgmt/RequestHistories'

function RequestHistory() {
  return (
    <DashboardLayout>
    <Helmet>
        <title>
           TMR | Ambulance Request History
        </title>
    </Helmet>
    <div className="">
        <RequestHistories />
    </div>
</DashboardLayout>
  )
}

export default RequestHistory