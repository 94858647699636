import UserManagement from '../../../components/UserAdmin/UserManagement';
import DashboardLayout from '../Dashboard/DashboardLayout';
import {Helmet} from 'react-helmet'


const Admin = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Admin
                </title>
            </Helmet>
            <UserManagement />
        </DashboardLayout>
    );
};
export default Admin;
