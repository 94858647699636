import React from 'react'
import powered from '../../src/Images/powered.png';
const ButtomLogo = () => {
  return (
    <div className='bg-[#410002] flex flex-col gap-1 w-[220px] py-2 px-3 h-[60px] mb-1 rounded-tl-lg rounded-bl-lg text-white'>
<p className='text-[11px] font-medium'>
Powered by
</p>
<div className='flex items-center gap-1'>
    <img src={powered} alt="tmr" className='w-[25px] h-[25px] max-w-100% object-contain'/>
    <p className='text-[13px] font-semibold'>TrackMedRec</p>
</div>
    </div>
  )
}

export default ButtomLogo;
