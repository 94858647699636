import React, { Fragment, useState, useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import Layout from './Hospital/Layout';
import count1 from '../../src/Images/count1.svg';
import { Link, useNavigate } from 'react-router-dom';
import AuthHeader from '../reusable/AuthHeader';
import Input from '../reusable/Forms/Input';
import { useMutation } from 'react-query';
import hospitalService from '../services/hospitalService';
import ErrorState from '../reusable/ErrorState';
import BlockButton from '../reusable/BlockButton';
import {
    useHospitalAuthStore,
    useHospitalAccountStore,
    useTmrValidationStore,
    useScreenTimeOut,
} from '../store/hospital/auth';
import { Helmet } from 'react-helmet';
import 'react-toastify/dist/ReactToastify.css';
import { 
    usePharmacyAccountStore, 
    usePharmacyAuthStore, 
    useScreenTimeOut as pharmacyScreenTimeOut
} from '../store/pharmacy/auth';
import { hospitalRoutes } from '../utils/routeNames';

type InputProps = {
    tmrId: string;
};

const HealthcareProviderID = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const navigate = useNavigate();

    const hospitalAuthStore = useHospitalAuthStore((state) => state);
    const hospitalAccountStore = useHospitalAccountStore((state) => state);
    const hospitalTimeOutStore = useScreenTimeOut();
    const tmrValidationStore = useTmrValidationStore((state) => state);
    const pharmacyAuthStore = usePharmacyAuthStore((state) => state);
    const pharmacyAccountStore = usePharmacyAccountStore((state) => state);
    const pharmacyTimeOutStore = pharmacyScreenTimeOut();
    const { formState, handleSubmit, control } = useForm<InputProps>();

    useEffect(() => {
        // reset hospital
        hospitalAuthStore.resetStore();
        hospitalAccountStore.resetHospital();
        hospitalAccountStore.resetToken();
        hospitalTimeOutStore.setIsTimeOut(false);
        // reset pharmacy
        pharmacyAuthStore.resetStore();
        pharmacyAccountStore.resetPharmacy();
        pharmacyAccountStore.resetToken();
        pharmacyTimeOutStore.setIsTimeOut(false);
        // reset validation
        tmrValidationStore.reset()
    }, []);

    const { isValid, isDirty } = formState;

    const mutation = useMutation((data: any) =>
        hospitalService.validateTmrId(data)
    );

    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            let response: any = mutation.data;
            const data = response?.data?.payload?.data;
            tmrValidationStore.setTmrValidation(data);
            navigate(hospitalRoutes.landingLogin);
        }
    }, [mutation.isSuccess, mutation.data]); 

    useEffect(() => {
        if (mutation.isError) {
            setIsLoading(false);
            let err: any = mutation.error;
            setErrorMessage(err?.response?.data?.error?.message || err.message);
        } else {
            setErrorMessage('');
        }
    }, [mutation.isError, mutation.error]);

    const onSubmitForm: SubmitHandler<InputProps> = (data) => {
        setIsLoading(true);
        mutation.mutate(data);
    };

    const onInvalid = (errors) => console.error(errors);

    return (
        <Fragment>
            <Helmet>
                <title>TMR | Healthcare Provider ID</title>
            </Helmet>
            <Layout>
                <div className="py-[60px]">
                    <AuthHeader />
                </div>

                <div className="flex flex-col justify-center items-center p-[1.5rem] lg:p-0 mx-auto">
                    <div className="flex flex-col justify-center border border-[#00000033] bg-white rounded-[25px] p-[2rem] lg:px-[40px] lg:py-[40px] max-w-[592px] sm:w-full ">
                        <div className="flex flex-row items-center justify-between gap-1 sm:gap-5 ml-[16%]">
                            <div>
                                <h1 className=" font-semibold text-center text-[1.2rem] lg:text-[32px]">
                                    Healthcare Provider ID
                                </h1>
                            </div>
                            <div className="flex">
                                <img src={count1} alt="count12" />
                            </div>
                        </div>
                        <p className="text-black text-center pt-4 pb-6">
                            Kindly enter your Healthcare Provider ID of your hospital
                        </p>

                        {errorMessage && <ErrorState message={errorMessage} />}

                        <form
                            onSubmit={handleSubmit(onSubmitForm, onInvalid)}
                            noValidate
                            className="flex flex-col gap-6"
                        >
                            <Controller
                                name="tmrId"
                                rules={{
                                    required:
                                        'Healthcare Provider ID is required',
                                }}
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        label="Healthcare Provider ID"
                                        {...field}
                                    />
                                )}
                            />
                            <BlockButton
                                text="Next"
                                disabled={!isDirty || !isValid}
                                type="submit"
                                isLoading={isLoading}
                            />
                            <p className="text-black text-center mt-4">
                                Don't have an account? 
                                <Link
                                    to="landing/register"
                                    className="text-[#C00010] cursor-pointer hover:underline"
                                >
                                     Register
                                </Link>
                            </p>
                        </form>
                    </div>
                </div>
            </Layout>
        </Fragment>
    );
};

export default HealthcareProviderID;
