import React from 'react'
import DashboardLayout from '../Dashboard/DashboardLayout'
import { Helmet } from 'react-helmet'
import AmbulanceHistories from '../../../components/Ambulance/FleetMgmt/FleetHistories'



function AmblanceHistories() {
  return (
    <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Ambulance History
                </title>
            </Helmet>
            <div className="">
              <AmbulanceHistories/>
            </div>
        </DashboardLayout>
  )
}

export default AmblanceHistories