import NurseProfile from '../../../../components/UserNurse/NurseProfile';
import DashboardLayout from '../../Dashboard/DashboardLayout';
import {Helmet} from 'react-helmet'


const NurseProfiles = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Nurse's Profile
                </title>
            </Helmet>
            <NurseProfile />
        </DashboardLayout>
    );
};
export default NurseProfiles;
