import { useState } from 'react';
import Table from '../../../constants/Table';
import { useNavigate } from 'react-router-dom';
import AppointmentEmptyState from './AppointmentEmptyState';
import image from '../../../Images/userMgmt.png';
import { pharmacyRoutes } from '../../../utils/pharmacyRouteNames';
import PaginationButton from '../../../constants/Pagination/PaginationButton';

interface rowType {
    _id: string;
}
interface InventoryDataProps {
    inventory: any;
    searchQuery: string;
    page?: any;
    setPage?: any;
    inventoryPagination?: any;
}
const InventoryData = ({
    inventory,
    searchQuery,
    page,
    setPage,
    inventoryPagination,
}: InventoryDataProps) => {
    const [showPanel, setShowPanel] = useState<boolean>(false);
    const navigate = useNavigate();
    const filteredInventoryData = inventory?.filter(
        (row: any) =>
            row?.drug?.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row?.drug?.dosageForm
                .toLowerCase()
                .includes(searchQuery.toLowerCase())
    );

    const handlePanel = () => {
        setShowPanel(!showPanel);
    };

    const paginatedRows = filteredInventoryData;

    const appointmentTableHeaders = [
        {
            title: 'S/N',
            render: (row: any) => {
                const index =
                    inventory?.findIndex((user: any) => user._id === row._id) +
                    1;

                return <p className="text-black">{(page - 1) * 10 + index}</p>;
            },
        },
        {
            title: 'Drugs',
            render: (row: any) => (
                <div className="flex items-center gap-x-[10px]">
                    <div className="flex items-center gap-x-[10px]">
                        <img
                            className="object-contain h-[40px] w-[40px] rounded-full"
                            style={{
                                boxShadow:
                                    'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
                            }}
                            src={row?.drug?.images[0]}
                            alt=""
                        />
                        <p className="truncate w-32 text-lightBrown text-sm ">
                            {row?.drug?.name}
                        </p>
                    </div>
                </div>
            ),
        },
        {
            title: 'Category',
            render: (row: any) => <p> {row?.drug?.category?.name}</p>,
        },

        {
            title: 'Dosage',
            render: (row: any) => <p>{row?.drug?.dosageForm}</p>,
        },
        {
            title: 'Qty Left',
            render: (row: any) => <p> {row?.quantity}</p>,
        },
        {
            title: 'Price (₦)',
            render: (row: any) => <p>{row?.price}</p>,
        },
    ];
    return (
        <div className="w-full pt-5">
            {!inventory?.length ? (
                <AppointmentEmptyState />
            ) : filteredInventoryData?.length === 0 ? ( // Check if filteredAppointments is empty
                <>
                    {/* <Table headers={appointmentTableHeaders} showHead={true} /> */}
                    <div className="flex flex-col items-center justify-center text-center my-[70px] ">
                        <img src={image} alt="" />
                        <p className="mt-6 text-base font-medium font-[Montserrat]">
                            The search result is currently empty.
                        </p>
                    </div>
                </>
            ) : (
                <div className="gap-[2rem] block md:grid">
                    <div className="w-full grid gap-x-[30px] self-start md:mb-[0] mb-[2rem] overflow-x-auto scrollbar-hide">
                        <Table
                            rows={paginatedRows}
                            headers={appointmentTableHeaders}
                            showHead={true}
                            allowRowClick
                            // onRowClick={(row: rowType) =>
                            //     navigate(
                            //         `${pharmacyRoutes.productDetails}${row._id}`
                            //     )
                            // }
                            onRowClick={(row: rowType) =>
                                navigate(
                                    `${pharmacyRoutes.productDetails}/${row?._id}`
                                )
                            }
                        />
                        <PaginationButton
                            page={page}
                            setPage={setPage}
                            totalPages={inventoryPagination || 1}
                        />{' '}
                    </div>
                </div>
            )}
        </div>
    );
};

export default InventoryData;
