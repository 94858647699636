import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import back from '../../../Images/back.png';
import PatientCard from './PatientCard';
import trash from '../../../Images/trash.png';
import assign from '../../../Images/assign.png';
import clock from '../../../Images/clock.png';
import noChat from '../../../Images/emptyChat.png';
import { Send2 } from 'iconsax-react';
import { Paperclip2 } from 'iconsax-react';
import { Gallery } from 'iconsax-react';
import AppointmentCard, { PatientFormat } from './AppointmentCard';
import AssignModal from './PatientModals/AssignModal';
import Modal from '../../../reusable/Modal';
import dayjs from 'dayjs';
import Lottie from 'lottie-react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import loadingState from '../../../Images/loading.json';
import hospitalService from '../../../services/hospitalService';
import { useHospitalAuthStore } from '../../../store/hospital/auth';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import goBack from '../../../utils/goBack';
import MedicalReport from './MedicalReport';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import PatientReview from './PatientReview';

type InputProps = {
    appointmentDate: string;
    appointmentTime: string;
};
const PatientAppoint = () => {
    const { id } = useParams();

    const hospitalAuthStore = useHospitalAuthStore((store) => ({
        hospital: store.hospital,
        user: store.user,
    }));

    const [showAssignDoctorModal, setShowAssignDoctorModal] = useState<boolean>(false);
    const [showRescheduleModal, setShowRescheduleModal] = useState(false);
    const [showConfirmAppointmentModal, setShowConfirmAppointmentModal] =
        useState<boolean>(false);
    const [showCancelAppointmentModal, setShowCancelAppointmentModal] =
        useState<boolean>(false);
    const [cancelReason, setCancelReason] = useState<string>('');
    const [cancelAppointmentLoading, setCancelAppointmentLoading] =
        useState<boolean>(false);
    const [confirmAppointmentLoading, setConfirmAppointmentLoading] =
        useState<boolean>(false);
    const [confirmationStatus, setConfirmationStatus] =
        useState<boolean>(false);
    const [buttonEnabled4, setButton4] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');
    const [amTimeSlots, setAmTimeSlots] = useState<string[]>([]);
    const [pmTimeSlots, setPmTimeSlots] = useState<string[]>([]);
    const [convertedNumbers, setConvertedNumbers] = useState<number[]>([]);
    const [loading, setIsLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState('AM');
    const [appointmentDetails, setAppointmentDetails] = useState<any>({});
    const [isChat, setIsChat] = useState(false);
    const [chats, setChats] = useState<any>([]);
    const chatContainerRef = useRef<null | HTMLDivElement>(null);
   
    const navigate = useNavigate();
    const hospitalId = useHospitalAuthStore((state) => state.hospital._id);
    
    // get appointment details from the backend
    const { data, isLoading, refetch, isError } = useQuery(
        ['singleAppointment', id],
        () =>
            id
                ? hospitalService.getSingleAppointment(id)
                : Promise.reject('NO ID'),
        {
            enabled: !!id,
        }
    );

    useEffect(() => {
        if (data?.appointment) {
            setAppointmentDetails(data?.appointment);
            if (
                data.appointment?.status === 'pending' &&
                data.appointment?.confirmed
            ) {
                setConfirmationStatus(true);
            }
            // chatRefetch();
        }
    }, [data]);

    // Reschedule logic
    const { data: availableTimeData, isLoading: timeSlotLoading } = useQuery(
        ['availableTime', selectedDate, hospitalId],
        () => fetchAvailableTimeSlots(selectedDate, hospitalId),
        {
            enabled: !!selectedDate && !!hospitalId, // Fetch only when all parameters are present
        }
    );
    // Handle available time data for Reschedule

    useEffect(() => {
        if (availableTimeData) {
            const timeSlots: string[] = availableTimeData.availableTime;
            const amSlots: string[] = [];
            const pmSlots: string[] = [];
            timeSlots.forEach((slot: string) => {
                const time = slot.split(' ')[0]; // Extract time without AM/PM
                if (slot.includes('AM')) {
                    amSlots.push(time);
                } else {
                    pmSlots.push(time);
                }
            });
            setAmTimeSlots(amSlots);
            setPmTimeSlots(pmSlots);
        } else {
            setAmTimeSlots([]);
            setPmTimeSlots([]);
        }
    }, [availableTimeData]);

    const handleTabChange = (tab) => {
        setSelectedTab(tab);
    };

    const convertDaysToNumbers = (daysArray: string[]) => {
        const daysMapping = {
            Sunday: 0,
            Monday: 1,
            Tuesday: 2,
            Wednesday: 3,
            Thursday: 4,
            Friday: 5,
            Saturday: 6,
        };
        const convertedArray = daysArray.map((day) => daysMapping[day]);
        return convertedArray;
    };

    const fetchAvailableTimeSlots = async (selectedDate, hospitalId) => {
        const response = await hospitalService.getAvailableTimeSlot(
            selectedDate,
            hospitalId
        );
        return response;
    };

    const availableDays = useHospitalAuthStore(
        (state) => state.hospital.scheduling.availableDays
    );
    useEffect(() => {
        setConvertedNumbers(convertDaysToNumbers(availableDays));
    }, [availableDays]);

    const mutation = useMutation(
        (data: any) =>
            hospitalService.rescheduleAppointment(
                appointmentDetails?._id,
                data
            ),
        {
            onSuccess: (response) => {
                setIsLoading(false);
                if (response.data && response.data.success) {
                    toast.success(
                        response?.data?.message ||
                            'Appointment rescheduled successfully'
                    );
                    closeModal4();
                    refetch();
                } else {
                    toast.error(
                        response?.data?.message ||
                            'Failed to reschedule appointment'
                    );
                }
            },
            onError: (error) => {
                setIsLoading(false);
                toast.error('Failed to reschedule appointment');
            },
        }
    );

    const onSubmitForm: SubmitHandler<InputProps> = (data) => {
        setIsLoading(true);
        setButton4(false);
        const selectedTabTime = selectedTab === 'AM' ? 'AM' : 'PM';

        // Initialize formattedData object with mandatory fields
        const formattedData: any = {
            appointmentDate: data.appointmentDate,
            appointmentTime: `${details.appointmentTime.toLowerCase()} ${selectedTabTime}`,
        };

        // Submit the formatted data
        mutation.mutate(formattedData);
    };

    const closeModal4 = () => {
        setShowRescheduleModal(false);
        setButton4(false);
        setFormData({
            selectedDate: '',
        });
    };

    
    const setRescheduleModal = () => {
        setShowRescheduleModal(true);
    };
    const { handleSubmit, control } = useForm<InputProps>();
    const today = dayjs();
    const present = dayjs();
    const oneMonth = today.add(1, 'month');

    const [formData, setFormData] = useState({
        selectedDate: '',
    });
    const [details, setDetails] = useState<InputProps>({
        appointmentDate: '',
        appointmentTime: '',
    });
    // Function to handle appointment date change
    const handleDateChange = (selectedDate) => {
        setSelectedDate(selectedDate);
    };
    const handleTimeSlotSelect = (slot, tab, e) => {
        e.preventDefault();
        setSelectedTab(tab);
        setDetails((prevDetails) => ({
            ...prevDetails,
            appointmentTime: slot, // Update the appointmentTime
        }));
        setButton4(true);
    };
    // end of Reschedule logic

    const handleCancelReasonChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setCancelReason(e.target.value);
    };

    //  Begining of AssignDoctor Logic
    const toggleAssignDoctorModal = () => {
        setShowAssignDoctorModal(!showAssignDoctorModal);
    };
    
    // END OF ASSIGNDOC LOGIC

    // cancel appointment logic
    const toggleCancelAppointmentModal = () =>
        setShowCancelAppointmentModal((prev) => !prev);

    const handleCancelAppointment = () => {
        if (!cancelReason) {
            toast.error(
                'Please provide a reason for cancelling the appointment'
            );
            return;
        }
        const payload = { reason: cancelReason };
        toggleCancelAppointmentModal();
        setCancelAppointmentLoading(true);
        hospitalService
            .cancelAppointment(appointmentDetails._id,payload )
            .then((response) => {
                refetch();
                toast.success(
                    response.data?.message ||
                        'Appointment canceled successfully'
                );
            })
            .catch((error) => {
                toast.error(error?.response?.data?.error?.message);
            })
            .finally(() => {
                setCancelAppointmentLoading(false);
            });
    };

    const toggleConfirmAppointmentModal = () =>
        setShowConfirmAppointmentModal((prev) => !prev);

    const handleConfirmAppointment = () => {
        toggleConfirmAppointmentModal();
        setConfirmAppointmentLoading(true);
        hospitalService
            .confirmAppointment(appointmentDetails._id)
            .then((response) => {
                refetch();
                toast.success(
                    response.data?.message ||
                        'Appointment Confirmed Successfully'
                );
            })
            .catch((error) => {
                toast.error(error.response.data.error.message);
            })
            .finally(() => {
                setConfirmAppointmentLoading(false);
            });
    };

    // const handleCompleteAppointment = () =>{
    //     hospitalService.completeAppointment(appointmentDetails._id).then((response)=>{
    //        setLoading(true)
    //         toast.success('Appointment Completed Successfully')
    //     }).catch((error)=>{
    //         toast.error(error.response.data.error.message)
    //     })
    //     .finally(()=>{
    //         setLoading(false)
    //     })
    // }

    //Chat Logic
    const socket = io(`${process.env.REACT_APP_SOCKET_URL}chat`, {
        auth: {
            token: id,
        },
        query: {
            sender:
                hospitalAuthStore.user.userType === 'doctor'
                    ? 'doctor'
                    : 'hospital',
            recipient: 'patient',
        },
        path: '/provider-socket',
        autoConnect: false,
    });

    const [isSocketConnected, setIsSocketConnected] = useState<boolean>(false);

    useEffect(() => {
        const onChatMessage = (data) => {
            const senderData =
                data.sender === 'patient'
                    ? {
                          firstName: appointmentDetails?.patient?.firstName,
                          lastName: appointmentDetails?.patient?.lastName,
                          image: appointmentDetails?.patient?.image,
                      }
                    : data.sender === 'hospital'
                      ? {
                            name: hospitalAuthStore.hospital.name,
                            logo: hospitalAuthStore.hospital.logo,
                        }
                      : {
                            firstName: hospitalAuthStore.user.firstName,
                            lastName: hospitalAuthStore.user.lastName,
                            image: hospitalAuthStore.user.image,
                        };

            setChats((prev) => [
                ...prev,
                {
                    ...data,
                    senderData,
                },
            ]);
            document
                ?.querySelector('#chatEnd')
                ?.scrollIntoView({ behavior: 'smooth' });
            if (!isChat) setIsChat(true);
        };

        // const onSendMessage = (data) => {
        //     socket.emit('chatMessage', data);
        // };

        const onSocketDisconnect = (data) => {
            setIsSocketConnected(!isSocketConnected);
        };

        socket.on('connect', () => {
            setIsSocketConnected(true);
        });

        socket.on('chatMessage', onChatMessage);
        socket.on('disconnect', onSocketDisconnect);

        socket.connect();

        return () => {
            socket.off('chatMessage', onChatMessage);
            socket.off('disconnect', onSocketDisconnect);
            socket.disconnect();
        };
    }, [appointmentDetails]);

    const [message, setMessage] = useState<string>('');
    const handleTextChange = (e) => {
        setMessage(e.target.value);
    };

    const handleSendMessage = () => {
        const messageData = {
            message: message,
            messageType: 'text',
        };
        if (isSocketConnected) {
            if (!socket.connected) socket.connect();
            socket.emit('chatMessage', messageData);
            setMessage('');
        } else {
            toast.error('Message could not be sent, please try again');
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    };

    const {
        data: retrievedData,
        isError: chatError,
        refetch: chatRefetch,
    } = useQuery(
        ['retrievedChats', id],
        () => (id ? hospitalService.getMessages(id) : Promise.reject('No ID')),
        {
            enabled: !!id,
        }
    );
    useEffect(() => {
        if (retrievedData && retrievedData.chats.length > 0) {
            setIsChat(true);
            setChats(retrievedData.chats);
        }
    }, [retrievedData]);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop =
                chatContainerRef.current.scrollHeight;
        } else {
            // console.log('not working');
        }
    }, [chats]);

    const formatDate = (dateString: string): string => {
        const date = new Date(dateString);
        const time = date.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
        });
        const month = date.toLocaleString('default', { month: 'short' });
        const day = date.getDate();
        return `${time.toLowerCase()}, ${month} ${day}`;
    };

    // useEffect(() => {
    //     console.log(appointmentDetails);
    // }, [appointmentDetails]);
    
    if (isError) {
        return <div>Error fetching appointment</div>;
    }
    return (
        <Fragment>
            <div className="w-full bg-[#F5F5F5] h-full px-6 py-6">
                {isLoading ? (
                    <div className="flex justify-center items-center py-64">
                        <Lottie
                            animationData={loadingState}
                            loop={true}
                            className="lottie"
                        />
                    </div>
                ) : (
                    <>
                        <div className="flex gap-2 items-center mb-5">
                            <button onClick={() => goBack(navigate)}>
                                <img src={back} alt="" />
                            </button>
                            <p className="font-bold sm:text-[28px] text-[20px]">
                                Patient Appointment
                            </p> 
                        </div>
                        <div className="flex flex-col md:flex-row gap-3">
                            <div className="flex flex-col lg:w-2/3">
                                <div className="flex-col py-4 font-700 text-xl text-black border-transparent bg-[#FFFFFF]  rounded-2xl max-w-[742px] flex p-4">
                                    <div className="flex items-center w-full pb-3 gap-6 sm:flex-row flex-col">
                                        {appointmentDetails?.status ===
                                            'cancelled' && (
                                            <div
                                                className={`bg-[#FFDAD5] w-full flex flex-col gap-3 justify-center px-3 py-3 rounded-lg`}
                                            >
                                                <p className="text-center">
                                                    Appointment Cancelled
                                                </p>
                                                
                                            </div>
                                        )}
                                    </div>
                                    {appointmentDetails.patientTmrNumber && (
                                        <PatientCard
                                            firstName={
                                                appointmentDetails?.patient
                                                    ?.firstName || '-'
                                            }
                                            lastName={
                                                appointmentDetails?.patient
                                                    ?.lastName || '-'
                                            }
                                            phoneNumber={
                                                appointmentDetails?.patient
                                                    ?.phoneNumber
                                            }
                                            tmrNumber={
                                                appointmentDetails?.patientTmrNumber || '-'
                                            }
                                            image={
                                                appointmentDetails?.patient?.image
                                            }
                                            email={
                                                appointmentDetails?.patient?.email
                                            }
                                            viewProfileVisible={true}
                                        />
                                    )}
                                    {
                                        appointmentDetails.patientTmrNumber && <AppointmentCard
                                            {...appointmentDetails}
                                            refetch={refetch}
                                        />
                                    }
                                    
                                    <div className="flex items-center w-full pb-3 gap-6 sm:flex-row flex-col">
                                        {appointmentDetails?.status ===
                                            'cancelled' && (<div className='bg-[#FFF8F7] p-4 w-full rounded-xl'>
                                            <PatientFormat
                                            text1="Reason for cancelling:"
                                            text2={ appointmentDetails?.cancelledReason || "Appointment was cancelled"}
                                        />
                                            </div>
                                        )}
                                    </div>
                                    <div className="w-full py-2 mt-4">
                                        <button
                                            onClick={
                                                toggleConfirmAppointmentModal
                                            }
                                            disabled={
                                                appointmentDetails.status ===
                                                    'cancelled' ||
                                                confirmationStatus ||
                                                appointmentDetails.status ===
                                                    'completed'
                                            }
                                            className={`font-light ${
                                                confirmAppointmentLoading ||
                                                confirmationStatus ||
                                                appointmentDetails.status ===
                                                    'cancelled' ||
                                                appointmentDetails.status ===
                                                    'completed'
                                                    ? 'bg-[#1D1B201F] text-[#201A19] hover:cursor-not-allowed'
                                                    : 'bg-[#C00010] text-white'
                                            } rounded-[8px] px-3 sm:pr-[24px] sm:px-[16px] sm:py-[10px] py-2 text-[16px] w-full`}
                                        >
                                            {confirmAppointmentLoading
                                                ? 'Accepting Appointment'
                                                : confirmationStatus
                                                  ? 'Appointment Accepted '
                                                  : 'Accept Appointment'}
                                        </button>
                                    </div>
                                    <div
                                        className={`py-1 border-t border-t-[#D8C2BF] mt-6 flex items-start gap-2 justify-center flex-col lg:flex-row ${
                                            appointmentDetails.status ===
                                                'cancelled' ||
                                            appointmentDetails.status ===
                                                'completed'
                                                ? 'bg-[#1D1B201F] opacity-[28%] pointer-events-none'
                                                : ''
                                        }`}
                                    >
                                        <ButtonText
                                            handleClick={toggleAssignDoctorModal}
                                            icon={assign}
                                            text={'Assign New Doctor'}
                                            color={'#201A19'}
                                            // disabled={true}
                                        />
                                        <ButtonText
                                            handleClick={setRescheduleModal}
                                            icon={clock}
                                            text={
                                                loading
                                                    ? 'Rescheduling...'
                                                    : 'Reschedule'
                                            }
                                            color={'#201A19'}
                                        />
                                        <ButtonText
                                            handleClick={
                                                toggleCancelAppointmentModal
                                            }
                                            icon={trash}
                                            text={
                                                cancelAppointmentLoading
                                                    ? 'Cancelling...'
                                                    : 'Cancel Appointment'
                                            }
                                            color={'#C00010'}
                                            disabled={cancelAppointmentLoading}
                                        />
                                    </div>
                                </div>

                                <div className="w-full bg-[#F5F5F5] h-full  py-6">
                                    <div className="flex-col py-4 font-700 text-xl text-black border-transparent bg-lightTmrRed rounded-2xl max-w-[742px] flex">
                                        <div className="flex justify-between px-5 py-2">
                                            <h1> Chat </h1>
                                        </div>
                                        {isChat ? (
                                            <>
                                                <div
                                                    className="bg-white flex flex-col p-4 h-[440px] overflow-y-auto"
                                                    ref={chatContainerRef}
                                                >
                                                    {chats.map(
                                                        (
                                                            chat: any,
                                                            index: number
                                                        ) => (
                                                            <div
                                                                className={`my-4 ${
                                                                    chat.sender !==
                                                                    'patient'
                                                                        ? 'flex flex-col ml-auto'
                                                                        : ''
                                                                }`}
                                                                key={index}
                                                            >
                                                                <div
                                                                    className={`flex gap-3 items-center ${
                                                                        chat.sender !==
                                                                        'patient'
                                                                            ? 'flex-row-reverse ml-auto'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    <div>
                                                                        <img
                                                                            src={
                                                                                chat
                                                                                    .senderData
                                                                                    .logo ||
                                                                                chat
                                                                                    .senderData
                                                                                    .image
                                                                            }
                                                                            className="rounded-full h-9 w-9 "
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className={`${
                                                                            chat.sender !==
                                                                            'patient'
                                                                                ? 'flex flex-col items-end'
                                                                                : ''
                                                                        }`}
                                                                    >
                                                                        <h3 className="text-sm font-semibold">
                                                                            {chat
                                                                                .senderData
                                                                                .name ||
                                                                                `${chat.senderData.firstName} ${chat.senderData.lastName}`}
                                                                        </h3>
                                                                        <span className="text-xs">
                                                                            {formatDate(
                                                                                chat.createdAt
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className=" px-3 py-3 mt-2 w-auto max-w-[400px] bg-lightTmrRed rounded-lg">
                                                                    <span className="  text-sm ">
                                                                        {
                                                                            chat.message
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </>
                                        ) : (
                                            <div className="bg-white flex flex-col p-4 justify-center items-center">
                                                <img src={noChat} alt="" />
                                                <span className="text-center text-[14px] leading-4 mt-4">
                                                    No messages to display{' '}
                                                    <br />
                                                    Begin chatting with your
                                                    Patients or wait for <br />
                                                    messages to appear
                                                </span>
                                            </div>
                                        )}
                                        {appointmentDetails.status ===
                                        'pending' ? (
                                            <div className="py-5 px-5 flex items-center justify-center gap-6">
                                                <div className="bg-white rounded-full w-full py-2 px-4 flex ">
                                                    <input
                                                        className="w-full text-[14px] outline-none"
                                                        type="text"
                                                        placeholder="Type Message..."
                                                        value={message}
                                                        onChange={
                                                            handleTextChange
                                                        }
                                                        onKeyDown={
                                                            handleKeyPress
                                                        }
                                                    />
                                                    <Send2
                                                        size="24"
                                                        color="#000000"
                                                        onClick={
                                                            handleSendMessage
                                                        }
                                                    />
                                                </div>
                                                <Paperclip2 color="#000000" />
                                                <Gallery color="#000000" />
                                            </div>
                                        ) : (
                                            <div className="py-5 px-5 flex items-center justify-center gap-6">
                                                <p className="text-sm text-normal text-Tmred">
                                                    ** Chat session has ended **
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col lg:w-1/3 gap-6 ">
                                <MedicalReport {...appointmentDetails} />
                                {appointmentDetails.patient && (
                                    <PatientReview {...appointmentDetails} />
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>

            <Modal
                isVisible={showConfirmAppointmentModal}
                onClose={toggleConfirmAppointmentModal}
                onSave={handleConfirmAppointment}
                buttonEnabled={true}
                setButton=""
                saveText="Accept"
                closeText="Cancel"
                showCloseButtonBorder={false}
            >
                <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2 sm:w-[400px]">
                    <h2 className="font-semibold text-2xl mb-5">
                        Accept Appointment
                    </h2>
                    <p className=" text-[14px] font-normal text-[#534341]">
                        By accepting this appointment, you are now confirming
                        your scheduled date, time and doctor for your
                        appointment. Note that changes like reassign a doctor,
                        rescheduling the appointment can still be made after
                        accepting the appointment.
                        <br />
                        <br />
                        Are you sure you want to proceed with accepting this
                        appointment?
                    </p>
                </div>
            </Modal>
            <Modal
                isVisible={showCancelAppointmentModal}
                onClose={toggleCancelAppointmentModal}
                onSave={handleCancelAppointment}
                buttonEnabled={true}
                setButton=""
                saveText="Submit"
                closeText="Keep Appointment"
                showCloseButtonBorder={true}
            >
                <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2 sm:w-[500px]">
                    <h2 className="font-semibold text-2xl mb-5">
                        Cancel Appointment
                    </h2>
                    <p className=" text-[14px] font-normal text-[#534341]">
                        Are you sure you want to cancel this appointment?
                    </p>
                    <textarea
                        name="reason"
                        placeholder="Reason"
                        value={cancelReason}
                        onChange={handleCancelReasonChange}
                        className="w-full border h-[150px] border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider my-4"
                    ></textarea>
                </div>
            </Modal>
            
            <AssignModal
                showModal={showAssignDoctorModal}
                onClose={toggleAssignDoctorModal}
                modalText='Assign'
                headingText='Assign New Doctor'
                appointmentDetails={appointmentDetails}
                refetch={refetch}
                assignType='appointment'
            />

            <Modal
                isVisible={showRescheduleModal}
                onClose={() => closeModal4()}
                onSave={handleSubmit(onSubmitForm)}
                buttonEnabled={buttonEnabled4}
                setButton={setButton4}
                saveText="Reschedule"
                closeText="Cancel"
                showCloseButtonBorder={true}
            >
                <div className="bg-white w-[36rem] sm:w-[644px] md:p-6 p-2 rounded-[28px] mx-2  ">
                    <h2 className="font-semibold text-2xl mb-4">
                        Reschedule Appointment
                    </h2>
                    <div className="rounded-2xl flex flex-col lg:flex-row gap-6 ">
                        <div className="py-4 sm:py-0 px-2 lg:h-[400px] lg:w-[342px] w-full bg-[#fff] rounded-2xl flex  flex-col">
                            <div className="w-full">
                                <Controller
                                    control={control}
                                    name="appointmentDate"
                                    defaultValue=""
                                    rules={{
                                        required: 'Pick a date',
                                    }}
                                    render={({ field }) => (
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <StaticDatePicker
                                                displayStaticWrapperAs="desktop"
                                                defaultValue={today}
                                                value={
                                                    formData.selectedDate
                                                        ? dayjs(
                                                              formData.selectedDate
                                                          )
                                                        : null
                                                }
                                                onChange={(date) => {
                                                    handleDateChange(date);
                                                    field.onChange(
                                                        date?.format(
                                                            'YYYY-MM-DD'
                                                        )
                                                    );
                                                }}
                                                minDate={present}
                                                maxDate={oneMonth}
                                                slotProps={{
                                                    toolbar: {
                                                        toolbarFormat:
                                                            'ddd DD MMMM',
                                                        hidden: false,
                                                    },
                                                }}
                                                shouldDisableDate={(date) => {
                                                    const today =
                                                        dayjs().startOf('day');

                                                    // Disable weekends (Saturday and Sunday)
                                                    const isDisabled =
                                                        !convertedNumbers.includes(
                                                            date.day()
                                                        );

                                                    // Disable previous days (before the current date)
                                                    const isPastDate =
                                                        date.isBefore(today);

                                                    return (
                                                        isDisabled || isPastDate
                                                    );
                                                }}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="py-4 px-3 lg:h-[400px] lg:w-full bg-[#F5F5F5] w-full rounded-lg flex flex-col">
                            <div className="mb-5  px-4 lg:w-[240px] rounded-[8px] flex flex-col py-[16px]">
                                <h2 className="font-normal  text-base  text-[#000] mb-3 ">
                                    Available time
                                </h2>
                                <div className="border-[1px] rounded-md border-[#857371] flex w-full">
                                    <input
                                        type="button"
                                        className={`flex-1 text-center cursor-pointer ${
                                            selectedTab === 'AM'
                                                ? 'bg-[#857371] text-white'
                                                : 'text-gray-400'
                                        }`}
                                        onClick={() => handleTabChange('AM')}
                                        value="AM"
                                    />
                                    <input
                                        type="button"
                                        className={`flex-1 cursor-pointer text-center ${
                                            selectedTab === 'PM'
                                                ? 'bg-[#857371] text-white'
                                                : 'text-gray-400'
                                        }`}
                                        onClick={() => handleTabChange('PM')}
                                        value="PM"
                                    />
                                </div>
                                {/* Time slots */}
                                {timeSlotLoading ? (
                                    <div className="flex justify-center items-center ">
                                        <Lottie
                                            animationData={loadingState}
                                            loop={true}
                                            className="lottie"
                                        />
                                    </div>
                                ) : (
                                    <>
                                        {amTimeSlots.length > 0 &&
                                            selectedTab === 'AM' && (
                                                <div className="flex overflow-x-auto w-full">
                                                    {amTimeSlots.map(
                                                        (slot, index) => (
                                                            <button
                                                                onClick={(e) =>
                                                                    handleTimeSlotSelect(
                                                                        slot,
                                                                        'AM',
                                                                        e
                                                                    )
                                                                }
                                                                key={index}
                                                                className={`p-2 mx-2 my-3 text-[#201A19] rounded-lg border-[#857371] border text-[14px] hover:border-[#C00010] ${
                                                                    details.appointmentTime ===
                                                                        slot &&
                                                                    selectedTab ===
                                                                        'AM'
                                                                        ? 'bg-Tmred text-[#fff]'
                                                                        : ''
                                                                }`}
                                                            >{`${slot}AM`}</button>
                                                        )
                                                    )}
                                                </div>
                                            )}

                                        {pmTimeSlots.length > 0 &&
                                            selectedTab === 'PM' && (
                                                <div className="flex overflow-x-auto w-full">
                                                    {pmTimeSlots.map(
                                                        (slot, index) => (
                                                            <button
                                                                onClick={(e) =>
                                                                    handleTimeSlotSelect(
                                                                        slot,
                                                                        'PM',
                                                                        e
                                                                    )
                                                                }
                                                                key={index}
                                                                className={`p-2 mx-2 my-3 text-[#201A19] rounded-lg border-[#857371] border text-[14px] hover:border-[#C00010] ${
                                                                    details.appointmentTime ===
                                                                        slot &&
                                                                    selectedTab ===
                                                                        'PM'
                                                                        ? 'bg-Tmred text-[#fff]'
                                                                        : ''
                                                                }`}
                                                            >{`${slot}PM`}</button>
                                                        )
                                                    )}
                                                </div>
                                            )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div></div>
                </div>
            </Modal>
        </Fragment>
    );
};

export default PatientAppoint;
interface Props {
    icon: any;
    text: any;
    color: any;
    handleClick: any;
    disabled?: any;
}
export const ButtonText: React.FC<Props> = ({
    icon,
    text,
    color,
    handleClick,
    disabled,
}) => {
    return (
        <button
            onClick={handleClick}
            disabled={disabled}
            className={`my-2 flex flex-row items-center gap-3 text-center  text-[${color}] text-[16px]  max-w-[302px] py-2 px-4 rounded-lg hover:border border border-none hover:border-Tmred`}
        >
            <img src={icon} alt="" />
            <span className="leading-[1.2rem]">{text}</span>
        </button>
    );
};
