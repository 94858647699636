import React, { Fragment, useEffect, useState } from 'react'
import BlockButton from '../../../../reusable/BlockButton'
import { useMutation } from 'react-query';
import { IoIosClose } from 'react-icons/io';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import PasswordInput from '../../../../reusable/Forms/PasswordInput';
import ErrorState from '../../../../reusable/ErrorState';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { pharmacyService } from '../../../../services/pharmacyService';
type ChangePasswordProps = {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
    token: string;
};

const PasswordChange = ({ closeModal }) => {
    const mutation = useMutation((data: ChangePasswordProps) =>pharmacyService.changePassword(data));
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const { formState: { isDirty, isValid,errors }, handleSubmit, control, setError,
    clearErrors, } = useForm<ChangePasswordProps >();
    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            console.log('success', mutation.data);
            let response: any = mutation.data;
            const data = response.data.payload;
            console.log(data);
    
            // Show success message using Toastify
            toast.success('Password updated successfully');
            setTimeout(() => {
                toast.dismiss(); // Dismiss the toast after 3000 milliseconds
                closeModal();   // Close the modal after the toast is dismissed
            }, 2000);
        }
    }, [mutation.isSuccess, mutation.data, closeModal]);
    

useEffect(() => {
    if (mutation.isError) {
        setIsLoading(false);
        let err: any = mutation.error;
        setErrorMessage(err.response.data.error.message);
    } else {
        setErrorMessage('');
    }
}, [mutation.isError, mutation.error]);

const submitPassWord: SubmitHandler<ChangePasswordProps> = (data) => {
 
const {newPassword,confirmPassword}=data

        if (newPassword !== confirmPassword) {
            // Set an error if passwords do not match
            setError('confirmPassword', {
                type: 'manual',
                message: 'Passwords do not match',
            });
            return;
        }

        setIsLoading(true);

        // Clear any previous errors for confirmPassword
        clearErrors('confirmPassword');

        mutation.mutate(data);
    
    
};

  return (
    <Fragment>
        <ToastContainer />
    
    <div className="fixed inset-0 flex items-center justify-center bg-[#2826265E] bg-opacity-75 z-50">
            <div className="bg-white p-6 rounded-[28px] w-full m-2 max-w-[480px] sm:m-0 relative">
                <div className="flex items-center justify-between mb-8">
                    <div className="flex items-center space-x-4">
                       
                           
                      
                        <h6 className="text-[22px] font-medium text-lightBrown">
                        Change Password
                        </h6>
                    </div>
                    <div onClick={closeModal} className="cursor-pointer">
                        <IoIosClose size="32" color="#534341" />
                    </div>
                </div>
                <div>
                   
                    
                    <form onSubmit={handleSubmit(submitPassWord)} >
                         {errorMessage && <ErrorState message={errorMessage} />}
                         
                    <div className="flex flex-col items-start relative">
                                <Controller
                                    name="oldPassword"
                                    rules={{ required: 'Password is required' }}
                                    control={control}
                                    render={({ field }) => (
                                        <PasswordInput placeholder='Old Password' {...field} />
                                    )}
                                />
                            </div>
                            <div className="flex flex-col items-start relative">
                                <Controller
                                    name="newPassword"
                                    rules={{ required: 'Password is required' }}
                                    control={control}
                                    render={({ field }) => (
                                        <PasswordInput placeholder='New Password' {...field} />
                                    )}
                                />
                            </div>
                            <div className="flex flex-col items-start relative">
                                <Controller
                                    name="confirmPassword"
                                    rules={{ required: 'Password is required' }}
                                    control={control}
                                    render={({ field }) => (
                                        <PasswordInput placeholder='Confirm Password' {...field} />
                                    )}
                                />
                            </div>
                            {errors.confirmPassword && (
                                <span className="text-Tmred italic text-sm mb-3">{errors.confirmPassword.message}</span>
                            )}
                        <BlockButton
                                text="Change Password"
                                disabled={!isDirty || !isValid}
                                type="submit"
                                isLoading={isLoading}
                            
                            />
                    </form>
                </div>
            </div>
        </div>
        </Fragment>
  )
}

export default PasswordChange
