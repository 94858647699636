import React from 'react';

const Error404 = () => {
    return (
        <div className="px-8 flex flex-col items-center justify-center w-full h-full">
            <h1 className="text-Tmred text-[22px]">Oops!</h1>
            <p className="text-Tmred text-[12px] font-semibold">
                <i>The page you visited does not exist</i>
            </p>
        </div>
    );
};

export default Error404;
