import React from 'react';
import DashboardLayout from '../Dashboard/DashboardLayout';
import Send from '../../../components/PharmacyComponent/Billing/Send';
import {Helmet} from 'react-helmet'


const SendMoney = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Send Money
                </title>
            </Helmet>
            <div className="">
                <Send />
            </div>
        </DashboardLayout>
    );
};

export default SendMoney;
