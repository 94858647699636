import React, { useEffect, useState } from 'react'
import { BsChevronLeft } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import CreateAdProgress from './CreateAdProgress';
import Input from '../../../reusable/Forms/Input';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { FormControl, InputLabel, MenuItem, Radio, Select } from '@mui/material';
import { useHospitalAuthStore } from '../../../store/hospital/auth';
import { resolveCountryCode, resolveCountryNameToCode } from '../../../utils/countryState';
import { formatAdsCreationDate } from '../../../utils/helpers';
import { useQuery } from 'react-query';
import CreateAdsSecondStage from './CreateAdsSecondStage';
import { pharmacyService } from '../../../services/pharmacyService';
import { pharmacyRoutes } from '../../../utils/pharmacyRouteNames';
import { usePharmacyAuthStore } from '../../../store/pharmacy/auth';

export type FirstStageProps = {
    adsName: string;
    adObjective: string;
    startDate: string;
    expiryDate: string;
    ageRangeMin: string;
    ageRangeMax: string;
    state: string;
    gender: string;
    targetUrl: string;
    websiteUrl: string;
};

function CreateADS() {

    const [createAdStage, setCreateAdStage] = useState<number>(1);
    const [adPricingList, setAdPricingList] = useState<any>([]);
    const [currentAdPrice, setCurrentAdPrice] = useState<number>(0);
    const [countryStates, setCountryState] = useState<any>([]);
    const [firstFormData, setFirstFormData] = useState<FirstStageProps>({
        adsName: '',
        adObjective: '',
        startDate: '',
        expiryDate: '',
        ageRangeMax: '',
        ageRangeMin: '',
        state: '',
        gender: '',
        targetUrl: '',
        websiteUrl: ''
    });
    const { country, currency } = usePharmacyAuthStore(state => state.pharmacy);
    const { startDate, expiryDate } = formatAdsCreationDate();

    const { data: adPricingData } = useQuery(
        ['adPrice', country ], 
        () =>pharmacyService.getAdsPrice(country)
    );

    const {
        formState: { isDirty, isValid },
        handleSubmit,
        control,
        watch
    } = useForm<FirstStageProps>();

    useEffect(() => {
        if(country) {
            const countryCode = resolveCountryNameToCode(country);
            if(countryCode) {
                const countryWithStates = resolveCountryCode(countryCode);
                if(countryStates) {
                    setCountryState(countryWithStates?.states);
                }
            }
        }
    }, [country]);

    useEffect(() => {
        if(adPricingList.length && watch('adObjective')) {
            const adPrice = adPricingList.find(price => price.adType === watch('adObjective'));
            setCurrentAdPrice(adPrice.dailyFee);
        }
    }, [watch('adObjective')]);

    useEffect(() => {
        if(adPricingData?.adPricing?.length) {
            setAdPricingList(adPricingData.adPricing);
        }
    }, [adPricingData]);

    const updateStage = (stage: number) => setCreateAdStage(prev => stage);

    const onSubmitForm: SubmitHandler<FirstStageProps> = (data) => {
        console.log('submit form', data);
        setFirstFormData(data);
        updateStage(2);
    };

    const onInvalid = (errors) => console.error(errors);

    return (
        <div className="my-4 mx-1 sm:mx-6">
            <div className="flex justify-between">
                <Link
                    className="flex gap-4 items-center cursor-pointer"
                    to={pharmacyRoutes.tmrAds}
                >
                    <BsChevronLeft className="text-dark100 w-4 h-4 " />
                    <h1 className="font-semibold md:text-[28px] text-lg  text-lightBrown">
                        Create Ads
                    </h1>
                </Link>
                <div className="py-1">
                    <CreateAdProgress level={createAdStage} />
                </div>
            </div>

            { createAdStage === 1 && 
            <form
                onSubmit={handleSubmit(onSubmitForm, onInvalid)}
                noValidate
                className="flex flex-col sm:gap-5 mt-6 bg-white rounded-2xl p-4 mb-[80px] w-full"
            >

                <div className="flex flex-col md:flex-row gap-3 w-full">
                    <div className="flex flex-col gap-5 w-full">
                        <h1 className="rounded-tl-2xl rounded-tr-2xl py-2 text-xl font-normal">
                            Ads Details
                        </h1>
                        <Controller
                            name="adsName"
                            rules={{
                                required: 'Ads Name is required',
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input label="Ads Name" {...field} />
                            )}
                        />
                        <Controller
                            name="adObjective"
                            rules={{
                                required: 'Ad Objective is required',
                            }}
                            defaultValue=''
                            control={control}
                            render={({ field }) => (
                                <FormControl fullWidth>
                                    <InputLabel id="state-select-filled-label">Ad Objective</InputLabel>
                                    <Select
                                        labelId="state-select-filled-label"
                                        id="state-select-filled"
                                        label="Ad Objective"
                                        {...field}
                                    >
                                        <MenuItem value="conversion">Conversion</MenuItem>
                                        <MenuItem value="profile visit">Profile Visit</MenuItem>
                                        <MenuItem value="visibility">Visibility</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        />
                        <h1 className="rounded-tl-2xl rounded-tr-2xl py-2 text-xl font-normal">
                            Schedule
                        </h1>
                        <div className="flex flex-col sm:flex-row items-center gap-4">
                            <div className="flex items-center gap-2">
                                <p className="font-semibold text-[14px] sm:text-[16px]">
                                    Start
                                </p>
                                <Controller
                                    name="startDate"
                                    rules={{
                                        required: 'Start date is required'
                                    }}
                                    control={control}
                                    defaultValue={startDate}
                                    render={({ field }) => (
                                        <Input 
                                            type='date' 
                                            label="Start date" 
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{
                                                min: startDate,
                                                max: expiryDate
                                            }}
                                            {...field} 
                                        />
                                    )}
                                />
                            </div>

                            <div className="hidden sm:block border-r-[2px] h-[52px] w-[1px] border-r-[#D8C2BF] border-solid"></div>
                            <div className="flex items-center gap-2">
                                <p className="font-semibold text-[14px] sm:text-[16px]">
                                    Expires
                                </p>
                                <Controller
                                    name="expiryDate"
                                    rules={{
                                        required: 'Expiry date is required'
                                    }}
                                    control={control}
                                    defaultValue={expiryDate}
                                    render={({ field }) => (
                                        <Input 
                                            type='date' 
                                            label="Expiry date" 
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{
                                                min: startDate
                                            }}
                                            {...field} 
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-full gap-5">
                        <h1 className="rounded-tl-2xl rounded-tr-2xl py-2 text-xl font-normal">
                            Audience
                        </h1>
                        <div className="flex flex-col sm:flex-row items-center gap-4">
                            <div className="flex items-center gap-2">
                                <Controller
                                    name="ageRangeMin"
                                    control={control}
                                    render={({ field }) => (
                                        <Input 
                                            type='number' 
                                            label="Minimum Age (Optional)"
                                            {...field} 
                                        />
                                    )}
                                />
                            </div>

                            <div className="hidden sm:block border-r-[2px] h-[52px] w-[1px] border-r-[#D8C2BF] border-solid"></div>
                            <div className="flex items-center gap-2">
                                <Controller
                                    name="ageRangeMax"
                                    control={control}
                                    render={({ field }) => (
                                        <Input 
                                            type='number' 
                                            label="Maximum Age (Optional)" 
                                            {...field} 
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <Input disabled={true} name='country' label="Country" value={country} />
                        <Controller
                            name="state"
                            defaultValue='all'
                            control={control}
                            render={({ field }) => (
                                <FormControl fullWidth>
                                    <InputLabel id="state-select-filled-label">State</InputLabel>
                                    <Select
                                        labelId="state-select-filled-label"
                                        id="state-select-filled"
                                        label="State"
                                        {...field}
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        {countryStates.map(
                                            (state: any) => (
                                                <MenuItem
                                                    key={state.code}
                                                    value={state.name}
                                                >
                                                    {state.name}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            )}
                        />
                        <Controller
                            name="gender"
                            rules={{
                                required: 'Gender is required',
                            }}
                            defaultValue='all'
                            control={control}
                            render={({ field }) => (
                                <FormControl fullWidth>
                                    <InputLabel id="gender-select-filled-label">Gender</InputLabel>
                                    <Select
                                        labelId="gender-select-filled-label"
                                        id="gender-select-filled"
                                        label="Gender"
                                        {...field}
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        <MenuItem value="male">Male</MenuItem>
                                        <MenuItem value="female">Female</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        />
                    </div>
                </div>

                <div className="flex flex-col md:flex-row gap-3 w-full mt-5">
                    <div className="flex flex-col gap-2 w-full">
                        <h1 className="rounded-tl-2xl rounded-tr-2xl py-2 text-xl font-normal">
                            Where will the Customer go when they click on your Ad?
                        </h1>
                        <div className="flex flex-col">
                            <Controller
                                name="targetUrl"
                                rules={{
                                    required: 'Ad target is required',
                                }}
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <div className='flex flex-row items-center'>
                                            <Radio 
                                                {...field} 
                                                value='website'
                                                checked={field.value === 'website'}  
                                            />
                                            <div>
                                                <p className="font-semibold text-[14px] sm:text-[16px]">
                                                    Website
                                                </p>
                                            </div>
                                        </div>
                                        <p className="ml-10 mb-3 font-normal text-sm">
                                            Get more visit to your website
                                        </p>
                                    </>
                                )}
                            />
                            
                            <div className="ml-10 mb-3 w-1/2">
                                <Controller
                                    name="websiteUrl"
                                    rules={{
                                        required: watch('targetUrl') === 'website' ? true : false
                                    }}
                                    control={control}
                                    defaultValue=''
                                    render={({ field }) => (
                                        <Input label="Website URL" {...field} />
                                    )}
                                />
                            </div>

                            <Controller
                                name="targetUrl"
                                rules={{
                                    required: 'Ad target is required',
                                }}
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <div className='flex flex-row items-center'>
                                            <Radio 
                                                {...field} 
                                                value='pharmacy store'
                                                checked={field.value === 'pharmacy store'}  
                                            />
                                            <div>
                                                <p className="font-semibold text-[14px] sm:text-[16px]">
                                                    Pharmacy store
                                                </p>
                                            </div>
                                        </div>
                                        <p className="ml-10 mb-3 font-normal text-sm">
                                            Increase your pharmacy order rate
                                        </p>
                                    </>
                                )}
                            />

                            <Controller
                                name="targetUrl"
                                rules={{
                                    required: 'Ad target is required',
                                }}
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <div className='flex flex-row items-center'>
                                            <Radio 
                                                {...field} 
                                                value='hospital profile' 
                                                checked={field.value === 'hospital profile'}  
                                            />
                                            <div>
                                                <p className="font-semibold text-[14px] sm:text-[16px]">
                                                   Pharmacy profile
                                                </p>
                                            </div>
                                        </div>
                                        <p className="ml-10 font-normal text-sm">
                                            Enables customer visit yourPharmacy profile
                                        </p>
                                    </>
                                )}
                            />

                            <div className="w-52 mt-6">
                                <Input 
                                    name='adPrice' 
                                    label='Ad Fee Per Day' 
                                    value={`${currency}${currentAdPrice}`} 
                                    disabled={true} 
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex justify-end border-t-[1px] border-[#D8C2BF] my-4 ">
                    <button
                        type='submit'
                        disabled={!isDirty || !isValid}
                        className={`rounded-lg mt-4 py-[10px] px-6 text-[#9d9b9b] hover:cursor-not-allowed ${
                            !isDirty || !isValid
                                ? 'bg-[#1D1B201F]'
                                : 'bg-Tmred text-white hover:cursor-pointer'
                        } `}
                    >
                        Next
                    </button>
                </div>

            </form>
            }
            { createAdStage === 2 && 
                <CreateAdsSecondStage 
                    firstFormData={firstFormData} 
                    updateStage={updateStage} 
                />
            }

        </div>
    )
}

export default CreateADS