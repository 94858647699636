import AddDoc from '../../../../components/UserDoctor/AddDoc';
import DashboardLayout from '../../Dashboard/DashboardLayout';
import {Helmet} from 'react-helmet'


const AddDoctor = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Add Doctor
                </title>
            </Helmet>
            <AddDoc />
        </DashboardLayout>
    );
};
export default AddDoctor;
