import React from 'react';
import { IoIosClose } from 'react-icons/io';

interface SmallModalProps {
    isVisible: any;
    onClose: any;
    children: any;
    setButton?: any;
    buttonEnabled: any;
    title: any;
    onSave: any;
    saveText: any;
    isLoading?: any;
    loadingText?: any;
    closeText?:boolean;
    showCloseButtonBorder?:boolean
}

const SmallModal: React.FC<SmallModalProps> = ({
    isVisible,
    onClose,
    children,
    setButton,
    buttonEnabled,
    title,
    onSave,
    closeText,
    showCloseButtonBorder,
    saveText,
    isLoading,
    loadingText, // Make loadingText prop optional
}) => {
    if (!isVisible) return null;
    const handleClose = (e: React.MouseEvent) => {
        if (e.target && (e.target as HTMLElement).id === 'wrapper') {
            onClose();
        }
    };

    return (
        <div
            className="fixed inset-0 flex items-center justify-center bg-[#2826265E] bg-opacity-75 z-50"
            id="wrapper"
            onClick={handleClose}
        >
            <div className="max-w-[560px] sm:max-w-[744px] mx-auto flex flex-col justify-between">
                <div className="bg-white p-[24px] rounded-[28px] border max-h-[80vh] overflow-y-auto">
                    <div className="flex items-center justify-between w-full">
                        <h3 className="font-medium text-18px sm:text-[22px] text-[#201A19]">
                            {title}
                        </h3>
                        <button onClick={() => onClose()}>
                            <IoIosClose size={'35px'} />
                        </button>
                    </div>
                    {children}

                    <div className={`mb-2 mt-2 flex flex-col sm:flex-row gap-2 items-center ${closeText ? 'ml-auto justify-end':'justify-center'} w-full`}>
                        {closeText && (
                            <>
                             <button
                                className={`w-auto h-[40px] text-[14px] flex p-2 sm:px-3 rounded-lg items-center text-center justify-center text-[#C00010] ${
                                    showCloseButtonBorder
                                        ? 'border hover:border-[#C00010] hover:border-2 rounded-lg border-[#C00010]'
                                        : 'hover:border hover:border-[#C00010]'
                                }`}
                                onClick={() => onClose()}
                               
                            >
                                Close
                            </button></>
                        )}
                        <button
                            className={`${closeText ? 'w-auto' :'w-full'}  h-[40px] p-2 sm:px-4 text-[14px] flex justify-center rounded-lg items-center text-[#9d9b9b] font-semibold transition-all duration-500 ${
                                isLoading
                                    ? 'bg-gray-400 text-white cursor-not-allowed'
                                    : buttonEnabled
                                      ? 'bg-Tmred text-white hover:cursor-pointer'
                                      : 'bg-[#1D1B201F] hover:cursor-not-allowed'
                            } `}
                            onClick={onSave}
                            disabled={isLoading}
                        >
                            {isLoading ? loadingText : saveText}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SmallModal;
