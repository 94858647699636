import { CloseCircle } from 'iconsax-react';

const ViewDrugModal = ({ closeModal, medications }) => {
    return (
        <>
            {' '}
            <div className="fixed inset-0 flex items-center justify-center bg-[#2826265E] bg-opacity-75 z-50 ">
                <div className="p-4 flex justify-between flex-col text-left overflow-y-scroll h-full max-h-[376px] scrollbar-hide bg-[#fff] w-full max-w-[460px] rounded-[28px] ">
                    <div className=" flex justify-between items-center ">
                        <h5 className="font-semibold sm:text-2xl text-[18px] text-lightBrown">
                            Medication
                        </h5>
                        <button type="button" onClick={closeModal}>
                            <CloseCircle size="30" color="#201A19" />
                        </button>
                    </div>
                    <>
                        
                                <div>
                                    <div className="grid gap-1 mb-6">
                                        <p className="text-sm font-medium text-dark100 ">
                                            Drug Name:
                                        </p>
                                        <p className="text-base font-medium text-lightBrown">
                                            {medications.prescriptions.drugName}
                                        </p>
                                    </div>
                                    <div className="grid gap-1 mb-6">
                                        <p className="text-sm font-medium text-dark100">
                                            Dosage:
                                        </p>
                                        <p className="text-base font-medium text-lightBrown">
                                            {medications.prescriptions.dosage}
                                        </p>
                                    </div>
                                    <div className="grid gap-1 mb-6">
                                        <p className="text-sm font-medium text-dark100">
                                            Side Effects:
                                        </p>
                                        <p className="text-base font-medium text-lightBrown">
                                            {
                                                medications.prescriptions
                                                    .sideEffects
                                            }{' '}
                                        </p>
                                    </div>
                                    <div className="grid gap-1 mb-6">
                                        <p className="text-sm font-medium text-dark100">
                                            Doctor's Note:
                                        </p>
                                        <p className="text-base font-medium text-lightBrown">
                                            {
                                                medications.prescriptions
                                                    .doctorsNote
                                            }
                                        </p>
                                    </div>
                                </div>
                            
                    </>
                </div>
            </div>
        </>
    );
};
export default ViewDrugModal;
