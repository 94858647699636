import { useState, useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../dashboard/DashboardCard';
import Doctors from '../../Images/doctor-icon.svg';
import Appointment from '../../Images/appointment-icon.svg';
import UpcomingAppointments from './UpcomingAppointment';
import Input from '../../reusable/Forms/Input';
import BlockButton from '../../reusable/BlockButton';
import hospitalService from '../../services/hospitalService';
import { useQuery } from 'react-query';
import callinvite from '../../Images/Refer a friend.png';
import { formatDateDmy } from '../../utils/helpers';
import { hospitalRoutes } from '../../utils/routeNames';

type SearchProps = {
    searchParam: string;
};
type CallInviteProps = {
    _id: string;
    appointment: {
        _id: string;
        status: string;
        appointmentDate: string;
        appointmentTime: string;
    };
    hostDoctor: {
        _id: string;
        firstName: string;
        lastName: string;
        specialties: {
            _id: string;
            name: string;
        }[];
    };
};
const DoctorDashboard: React.FC = () => {
    const navigate = useNavigate();
    const [dashboardData, setDashboardData] = useState<any>();
    const [telemedicineInvite, setTelemedicineInvite] = useState<
        CallInviteProps[]
    >([]);
    // const [callInvite, setCallInvite] = useState<CallInviteProps[]>([]);

    const {
        formState: { isDirty, isValid },
        handleSubmit,
        control,
    } = useForm<SearchProps>();

    const modeData = {
        title: 'Mode',
        firstData: 'Home Visit',
        firstDataValue: dashboardData?.modes?.homeVisit,
        secondData: 'Telemedicine',
        secondDataValue: dashboardData?.modes?.telemedicine,
        thirdData: 'Walk-in',
        thirdDataValue: dashboardData?.modes?.walkIn,
        iconSrc: Doctors,
    };

    const appointmentData = {
        title: 'Appointment',
        firstData: 'Pending',
        firstDataValue: dashboardData?.appointments?.pending,
        secondData: 'Completed',
        secondDataValue: dashboardData?.appointments?.completed,
        thirdData: 'Total',
        thirdDataValue: dashboardData?.appointments?.total,
        iconSrc: Appointment,
    };

    useEffect(() => {
        hospitalService
            .getDashboardData() // TODO: change to useQuery
            .then((res) => {
                setDashboardData(res);
            })
            .catch((err) => {
                toast.error(err);
            });
    }, []);

    const submitSearch: SubmitHandler<SearchProps> = (data) => {
        const { searchParam } = data;
        navigate(`/hospital/dashboard/record-search?query=${searchParam}`);
    };

    const { data, isError, isLoading } = useQuery(
        'telemedicineInvite',
        hospitalService.getTelemedicineInvite
    );
    useEffect(() => {
        if (data) {
            setTelemedicineInvite(data?.appointmentInvite);
        }
    }, [data]);
    console.log(telemedicineInvite);
    // const { callData, callError, isCallLoading } = useQuery(
    //     'telemedicineInvite',
    //     hospitalService.getTelemedicineInvite
    // );

    // useEffect(() => {
    //     if (callData) {
    //         setCallInvite(callData?.appointmentInvite);
    //         // console.log(data.appointmentInvite);
    //     }
    // }, [callData]);

    //JoinCall
    const startCall = (id) => {
        const newTab = window.open(hospitalRoutes.startCall + id, '_blank');
        if (newTab) {
            newTab.focus();
        }
    };

    return (
        <div className="md:px-8 py-6 px-2 w-full">
            <h2 className="mb-6 font-semibold text-[28px]">Dashboard</h2>
            <div className="flex flex-row gap-4 w-full flex-wrap ">
                <div className="flex-1 ">
                    <div className="flex md:flex-row flex-col items-center gap-6">
                        <Card {...appointmentData} />
                        <Card {...modeData} />
                    </div>
                </div>

                <div className="w-full xl:w-[358px] 2xl:w-flex-1">
                    <div className="bg-white  p-4 rounded-2xl ">
                        <h6 className="text-dark text-xl font-bold mb-6">
                            Record Search
                        </h6>
                        <form onSubmit={handleSubmit(submitSearch)}>
                            <Controller
                                name="searchParam"
                                rules={{
                                    required: 'Search Parameter is required',
                                }}
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        label="Patient Name or TMR Number"
                                        {...field}
                                    />
                                )}
                            />

                            <BlockButton
                                text="View Record"
                                disabled={!isDirty || !isValid}
                                type="submit"
                                isLoading={false}
                                className="mt-5 p-3"
                            />
                        </form>
                    </div>
                </div>
            </div>
            <div className="flex flex-col xl:flex-row gap-4 w-full mt-8">
                <UpcomingAppointments />
                <div className="bg-white rounded-3xl px-4 py-6 w-full h-fit md:w-[400px] flex flex-col gap-6">
                    <h2 className="font-bold text-2xl">Call Invitation</h2>
                    {telemedicineInvite.length !== 0 ? (
                        <div>
                            <div className="flex justify-between mb-3 items-center">
                                <p className="text-lightBrown text-[0.85rem] leading-6">
                                    Doctor/Specialty
                                </p>
                                <p className="text-lightBrown text-[0.85rem] leading-6">
                                    Appointment date/time
                                </p>
                            </div>
                            <div className="flex flex-col gap-3">
                                {telemedicineInvite.map((el, i) => (
                                    <div
                                        key={i}
                                        className="w-[100%] bg-[#F5F6F7] p-3 rounded-xl"
                                    >
                                        <div className="flex justify-between">
                                            <div className="text-left">
                                                <p className="font-bold text-[1rem]">
                                                    {el?.hostDoctor?.firstName}{' '}
                                                    {el?.hostDoctor?.lastName}
                                                </p>
                                                <p className="text-lightBrown text-[1rem]">
                                                    {
                                                        el?.hostDoctor
                                                            ?.specialties[0]
                                                            ?.name
                                                    }
                                                </p>
                                            </div>
                                            <div className="text-right">
                                                <p className="text-lightBrown text-[1rem]">
                                                    {formatDateDmy(
                                                        el?.appointment
                                                            ?.appointmentDate
                                                    )}
                                                </p>
                                                <p className="text-lightBrown text-[1rem]">
                                                    {
                                                        el?.appointment
                                                            ?.appointmentTime
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        <div className="mt-2 flex justify-between items-center">
                                            <Link
                                                to={`${hospitalRoutes.patientAppointment}${el?.appointment?._id}`}
                                                className="text-Tmred hover:underline font-[500]"
                                            >
                                                view appointment
                                            </Link>
                                            <button
                                                className={`flex gap-2 items-center border py-2 text-[1.1rem] px-4 rounded-lg text-Tmred font-[500]`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    startCall(
                                                        el?.appointment?._id
                                                    );
                                                }}
                                                // disabled={!confirmationStatus}
                                            >
                                                Join call
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className="bg-lightPink rounded-3xl p-5 flex flex-col justify-center items-center">
                            <img src={callinvite} alt="" />
                            <span className="mt-4">
                                No calls. Click 'Join' when invited.
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DoctorDashboard;
