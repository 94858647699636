import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
// import { TextField } from '@mui/material';

interface RadioButtonsGroupProps {
    label: string;
    options: { value: string; label: string }[];
    onChange: (value: string) => void;
    value: string;
   
}

const DefaultRadioGroup: React.FC<RadioButtonsGroupProps> = ({
    label,
    options,
    onChange,
    value,
   
}) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange((event.target as HTMLInputElement).value);
    };

    return (
        <FormControl>
            <RadioGroup
                name="controlled-radio-buttons-group"
                row
                value={value}
                onChange={handleChange}
                color="primary"
                style={{
                    display:'flex',
                    flexDirection:'column',
                    width:'100%',
                    alignItems:'left'
                }}
                
            >
                {options.map((option) => (
                    <div
                        key={option.value}
                        style={{
                            // border: '1px solid #ccc',
                            // borderRadius: '8px',
                            // padding: '8px',
                            // paddingLeft: '0px',
                            // marginRight: '12px',
                            // marginBottom: '12px',
                            // textAlign: 'center',
                            color:'#201A19',
                            fontWeight:'500',
                            fontSize:'14px',
                            width:'100%'
                        }}
                    >
                        <FormControlLabel
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                            checked={
                                option.value === value 
                            }
                        />
                    </div>

                ))}
                    
            </RadioGroup>
        </FormControl>
    );
};

export default DefaultRadioGroup;
