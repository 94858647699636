import { Close } from '@mui/icons-material';
import React, { ReactNode } from 'react';
type VoucherModalProps = {
    isVisible: boolean;
    onClose: () => void;
    children: ReactNode;
    header: any;
    headerContent?: any;
    close?: boolean;
    // h: string;
    // w: string;
};
const VoucherModal = ({
    isVisible,
    onClose,
    children,
    header,
    headerContent,
    close,
    // h,
    // w,
}: VoucherModalProps) => {
    if (!isVisible) return null;
    const handleClose = (e) => {
        if (e.target.id === 'wrapper') {
            onClose();
        }
    };
    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div
                className="fixed z-10 inset-0 flex justify-center items-center bg-[#2826265E] bg-opacity-20 "
                id="wrapper"
                onClick={handleClose}
            >
                <div className={`bg-white rounded-2xl p-4`}>
                    <div className="flex justify-between">
                        <div className="flex gap-2">
                            <p className="2rem font-[600] leading-10">
                                {header}
                            </p>
                            <span>{headerContent}</span>
                        </div>

                        <div
                            className="cursor-pointer "
                            onClick={() => onClose()}
                        >
                            <Close />
                        </div>
                    </div>
                    {children}
                    {close && (
                        <div className="ml-auto flex gap-3 ">
                            <button
                                className={`w-auto h-[40px] hover:border hover:border-[#C00010] text-[14px] flex p-2 sm:px-3 rounded-lg items-center text-center justify-center text-[#C00010]`}
                                // ${
                                //                                 showCloseButtonBorder
                                //                                     ? 'border hover:border-[#C00010] hover:border-2 rounded-lg border-[#C00010]'
                                //                                     : 'hover:border hover:border-[#C00010]'
                                //                                 }
                                onClick={() => onClose()}
                            >
                                Close
                            </button>
                            <button
                                className={`w-auto h-[40px] p-2 sm:px-4 text-[14px] flex justify-center rounded-lg items-center text-[#9d9b9b] font-semibold  hover:cursor-not-allowed transition-all duration-500  `}
                                // onClick={onSave}
                            >
                                Use selected voucher
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default VoucherModal;
