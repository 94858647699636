import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import emptyImage from '../Images/EmptystateIMG.png';

const TableOverviewContainer = styled.table`
    width: 100%;
    text-align: left;
    font-size: 15px;
    border-collapse: collapse;
    min-width: 800px;
    border-radius: 16px;

    .align--right {
        text-align: right;
    }
    border-bottom: 1px solid #d0d5dd;
    thead {
        tr {
            th {
                font-weight: 400;
                line-height: 19px;
                font-size: 15px;
                padding: 16px 10px;
                text-align: left;
                white-space: nowrap;

                background: #fff8f7;
                color: #201a19;
                &:first-child {
                    padding-left: 30px;
                }

                &:last-child {
                    padding-right: 30px;
                }
            }
        }
    }

    tbody {
        tr.clickable {
            cursor: pointer;
            height: 80px;
            &:hover {
                background-color: #f2f2f2;
                cursor: pointer;
            }
            &:nth-child(even) {
                background: #fafafb;
            }
            td {
                &:first-child {
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                }

                &:last-child {
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
            }
        }

        td {
            text-align: left;
            padding: 10px 10px;
            white-space: nowrap;
            height: 60px;
            min-height: 60px;
            color: #06042c;
            font-size: 14px;

            p {
                font-size: 14px;
                color: #857371;
                font-weight: 400;
            }

            &:first-child {
                padding-left: 30px;
            }

            &:last-child {
                padding-right: 30px;
            }
        }

        td.status {
            display: flex;
            align-items: center;
        }
    }

    .update_container {
        display: flex;
        justify-content: center;
        width: 100%;

        .data_update {
            font-size: 20px;
            font-weight: 500;
        }
    }

    .modal-heading {
        display: flex;
        justify-content: space-between;

        h2 {
            font-size: 24px;
            line-height: 24px;
            letter-spacing: 0.25px;
        }

        button {
            border: none;
            outline: none;
        }
    }
`;

interface headerType {
    title?: any;
    align?: string;
    render?: Function;
}

interface Props {
    rows?: any;
    headers?: headerType[];
    showHead?: boolean;
    onRowClick?: Function | ((x?: Object) => void);
    allowRowClick?: boolean;
}

export const Table: React.FC<Props> = ({
    headers,
    rows,
    showHead = true,
    onRowClick = () => null,
    allowRowClick = false,
    ...rest
}) => {
    const [isEmpty, setIsEmpty] = useState(true);

    useEffect(() => {
        if (rows) {
            setIsEmpty(false);
        } else {
            setIsEmpty(true);
        }
    }, [rows]);

    return (
        <TableOverviewContainer {...rest}>
            {showHead && (
                <thead>
                    <tr>
                        {headers?.map((header, index) => {
                            return (
                                <th
                                    key={index}
                                    className="text-xs bg-red  font-medium text-lightBrown"
                                >
                                    {header.title}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
            )}
            <tbody>
                {isEmpty
                    ? ''
                    : rows?.map((row: any) => (
                          <>
                              <tr
                                  key={row._id}
                                  onClick={
                                      allowRowClick
                                          ? () => onRowClick(row)
                                          : undefined
                                  }
                                  className={clsx({ clickable: allowRowClick })}
                              >
                                  {headers?.map((header, index) => {
                                      return (
                                          <>
                                              <td
                                                  key={index}
                                                  className={clsx({
                                                      'align--right':
                                                          header.align ===
                                                          'right',
                                                  })}
                                              >
                                                  {header &&
                                                      header?.render?.(row)}
                                              </td>
                                          </>
                                      );
                                  })}
                              </tr>
                          </>
                      ))}
            </tbody>
        </TableOverviewContainer>
    );
};
export default Table;
