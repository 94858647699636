import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Lottie from 'lottie-react';
import loadingState from '../../../Images/loading.json';
import { pharmacyService } from '../../../services/pharmacyService';
import { usePagination } from '../../../constants/Pagination/UsePagination';
import { pharmacyRoutes } from '../../../utils/pharmacyRouteNames';
import { formatDateDmy, standardAmountFormat } from '../../../utils/helpers';
import OrderEmptyState from '../Order/OrderEmptyState';
import Table from '../../../constants/Table';
import { Link, useNavigate } from 'react-router-dom';


interface rowType {
    _id: string;
}

const RecentOrder = () => {
  
    const [orders, setOrders] = useState<any[]>([]);

    const navigate = useNavigate();
    
    const { data, isError, isLoading } = useQuery(
        ['order', 'page', 'searchQuery', 'filterParams'],
        () =>
          pharmacyService.getOrder(
            1,
            '',
            '',
            '',
            '',
            ''
          )
    );
    useEffect(() => {
        if (data?.orders?.length) {
            const orders = data.orders?.slice(0, 10);
            setOrders(orders);
        }
    }, [data]);

    const OrderTableHeaders = [
        {
            title: 'S/N',
            render: (row: any) => {
              const index = orders?.findIndex((user: any) => user._id === row._id) + 1;
          
              return (
                <p className="text-black">
                  {index}
                </p>
              );
            },
          },
          
        {
            title: 'Invoice ID',
            render: (row: any) => (
                <div className="flex items-center gap-x-[10px]">
                    <div>
                        <p className="truncate w-32 text-lightBrown text-sm ">
                            {row.invoiceNumber}
                        </p>
                    </div>
                </div>
            ),
        },
        {
            title: 'Name',
            render: (row: any) => (
                <div className="flex items-center gap-x-[10px]">
                    <p className="truncate w-32 text-lightBrown text-sm ">
                        {row.customer.fullName}
                    </p>
                </div>
            ),
        },
        {
            title: 'Amount',
            render: (row: any) => <p>{`${standardAmountFormat(row.totalPrice, row.currency)}`}</p>,
        },
        {
            title: 'Status',
            render: (row: any) => (
                <div
                    className={`flex items-start justify-center p-2 rounded 
                        ${
                            row.status === 'pending'
                                ? 'bg-[#1D1B2014] text-lightBrown'
                                : ''
                        } 
                        ${
                            row.status === 'completed'
                                ? 'bg-[#E2EEE7] text-green-500'
                                : ''
                        }
                        ${
                            row.status === 'cancelled'
                                ? 'bg-[#FFDAD5] text-red-500'
                                : ''
                        }
                    `}
                >
                    <div
                        className={`w-[6px] h-[6px] rounded-full mr-2 mt-[6px] 
                            ${row.status === 'pending' ? 'bg-[#615f60]' : ''}
                            ${row.status === 'completed' ? 'bg-[#3A975F]' : ''}
                            ${row.status === 'cancelled' ? 'bg-[#C00010]' : ''}
                        }`}
                    />
                    <p className="text-xs">{row.status}</p>
                </div>
            ),
        },
        {
            title: 'Date ordered',
            render: (row: any) => <p>{formatDateDmy(row.createdAt)}</p>,
        },
    ];

    return (
        <div className="w-full bg-white px-3 py-3 rounded-md">
            <div className="flex flex-col">
                <div className="flex justify-between items-center py-4">
                    <p className="font-bold sm:text-[24px] text-[20px]">
                        Recent Orders
                    </p>
                </div>
                {
                isLoading ? (
                    <div className="flex justify-center items-center py-32">
                        <Lottie
                            animationData={loadingState}
                            loop={true}
                            className="lottie"
                        />
                    </div>
                ) :
                !orders?.length ?
                    <OrderEmptyState />
                : (
                    <div className="gap-[2rem] block md:grid">
                        <div className="w-full grid gap-x-[30px] self-start md:mb-[0] mb-[2rem] overflow-x-auto scrollbar-hide">
                            <Table
                                rows={orders}
                                headers={OrderTableHeaders}
                                showHead={true}
                                allowRowClick
                                onRowClick={(row: rowType) =>
                                    navigate(`${pharmacyRoutes.orderDetails}/${row._id}`)
                                }
                            />
                            <div className="flex items-center justify-center w-full my-3 pt-6">
                                <Link
                                    to={pharmacyRoutes.orders}
                                    className="w-full sm:w-fit h-[40px] text-[14px] flex items-center text-center justify-center text-[#C00010] border border-[#C00010] py-2 px-6 rounded-lg font-medium"
                                >
                                    View all orders
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RecentOrder;
