
import usePagination from '../../hooks/usePagination';
import Table from '../../constants/Table';
import icon from '../../Images/state-layer.png';
import Image from '../../Images/noEvent2.png';
const DocProfileAppointment = ({ appointment }: any) => {
   
    const { page, limit, Pagination } = usePagination({
        page: 1,
        limit: 10,
        total: appointment?.length,
    });

    const paginatedRows = appointment?.slice(
        (page - 1) * limit,
        page * limit
    );
    const upcomingTableHeaders = [
        { title: 'S/N', render: (row: any) => <p> {appointment?.findIndex(
            (user: any) => user._id === row._id
        ) + 1}</p> },
        {
            title: 'Patient Name',
            render: (row: any) => (
                <div className="flex items-center gap-x-[10px]">
                    <img
                        className="object-contain h-[40px] w-[40px] rounded-full"
                        style={{
                            boxShadow:
                                'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
                        }}
                        src={row.patientImage}
                        alt=""
                    />{' '}
                    <div>
                        <h2 className="truncate w-24 font-medium text-deep_black text-sm ">
                            {row.patientName}
                        </h2>
                        <p className="text-light_gray text-[12px] font-medium">
                            {row.patientTmrNumber}
                        </p>
                    </div>
                </div>
            ),
        },

        { title: 'Mode', render: (row: any) => <p>{row.mode}</p> },
        {
            title: 'Time',
            render: (row: any) => (
                <p className="font-medium text-[14px] text-[#534341]">
                    {row.appointmentTime}
                </p>
            ),
        },
        { title: 'Reason', render: (row: any) => <p>{row.reason}</p> },
        {
            title: '',
            render: (row: any) => (
                <div>
                    <img src={icon} alt="" />
                </div>
            ),
        },
    ];

    return (
        <>
        {!appointment?.length ? (
                <div className="flex flex-col justify-center items-center mt-36">
                    <img src={Image} alt="empty" />
                    <p className="mt-4 text-sm font-medium text-dark100">
                        No upcoming appointment schedule
                    </p>
                </div>
            ) : (
            <div className=" flex flex-col px-4 py-3 ">
                <Table
                    rows={paginatedRows}
                    headers={upcomingTableHeaders}
                    allowRowClick
                    showHead={true}
                />
                <Pagination />
            </div>)}
        </>
    );
};
export default DocProfileAppointment;
