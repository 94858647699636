import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Tab from './Tab';
import Tabs from './Tabs';
import InventoryData from './InventoryData';
import { useQuery } from 'react-query';
import hospitalService from '../../../services/hospitalService';
import Lottie from 'lottie-react';
import loadingState from '../../../Images/loading.json';
import { pharmacyRoutes } from '../../../utils/pharmacyRouteNames';
import { pharmacyService } from '../../../services/pharmacyService';

const ActiveState = () => {
    const [inventory, setInventory] = useState<any[]>([]);
    const [filteredInventoryData, setFilteredInventoryData] = useState('');
    const [liquidInventory, setLiquidInventory] = useState<any[]>([]);
    const [solidInventory, setSolidInventory] = useState<any[]>([]);
    const [capsuleInventory, setCapsuleInventory] = useState<any[]>([]);
    const [tabletInventory, setTabletInventory] = useState<any[]>([]);
    const [inventoryPagination, setInventoryPagination] = useState<any>({});
    // const { page, setPage } = usePagination();
    const [page, setIsPage] = useState<number>(1);
    const [solidPage, setSolidPage] = useState<number>(1);
    const [liquidPage, setLiquidPage] = useState<number>(1);
    const [capsulePage, setCapsulePage] = useState<number>(1);
    const [tabletPage, setTabletPage] = useState<number>(1);

    const navigate = useNavigate();

    const { data, isError, isLoading } = useQuery(['inventory', page], () =>
        pharmacyService.getInventory(page)
    );
    console.log(data);
    const {
        data: solid,
        isError: isSError,
        isLoading: isSLoading,
    } = useQuery(['Solid', solidPage], () =>
        pharmacyService.getInventory(solidPage, 'Solid')
    );
    const {
        data: liquid,
        isError: isLError,
        isLoading: isLLoading,
    } = useQuery(['Liquid', liquidPage], () =>
        pharmacyService.getInventory(liquidPage, 'Liquid')
    );
    const {
        data: capsule,
        isError: isCError,
        isLoading: isCLoading,
    } = useQuery(['Capsule', capsulePage], () =>
        pharmacyService.getInventory(capsulePage, 'Solid')
    );
    const {
        data: tablet,
        isError: isTError,
        isLoading: isTLoading,
    } = useQuery(['Tablet', tabletPage], () =>
        pharmacyService.getInventory(tabletPage, 'Tablet')
    );
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (searchTerm: string) => {
        setSearchQuery(searchTerm);
    };

    useEffect(() => {
        const inventorypagination = data?.pagination;
        setInventoryPagination(inventorypagination);
        setInventory(data?.drugInventories);
        setSolidInventory(solid?.drugInventories);
        setLiquidInventory(liquid?.drugInventories);
        setCapsuleInventory(capsule?.drugInventories);
        setTabletInventory(tablet?.drugInventories);
    }, [data, solid, liquid, capsule, tablet]);

    const handleAddInventory = () => {
        navigate(pharmacyRoutes.addInventory);
    };

    const tabs = [
        {
            title: 'All',
            content: (
                <Tab>
                    <InventoryData
                        inventory={inventory}
                        searchQuery={searchQuery}
                        page={page}
                        setPage={setIsPage}
                        inventoryPagination={data?.pagination?.totalPages}
                    />
                </Tab>
            ),
            count: inventoryPagination?.totalItems || 0,
        },
        {
            title: 'Solid',
            content: (
                <Tab>
                    <InventoryData
                        inventory={solidInventory}
                        searchQuery={searchQuery}
                        page={solidPage}
                        setPage={setSolidPage}
                        inventoryPagination={solid?.pagination?.totalPages}
                    />
                </Tab>
            ),
            count: solid?.pagination?.totalItems || 0,
        },
        {
            title: 'Liquid',
            content: (
                <Tab>
                    <InventoryData
                        inventory={liquidInventory}
                        searchQuery={searchQuery}
                        page={liquidPage}
                        setPage={setLiquidPage}
                        inventoryPagination={liquid?.pagination?.totalPages}
                    />
                </Tab>
            ),
            count: liquid?.pagination?.totalItems || 0,
        },
        {
            title: 'Capsule',
            content: (
                <Tab>
                    <InventoryData
                        inventory={capsuleInventory}
                        searchQuery={searchQuery}
                        page={capsulePage}
                        setPage={setCapsulePage}
                        inventoryPagination={capsule?.pagination?.totalPages}
                    />
                </Tab>
            ),
            count: capsule?.pagination?.totalItems || 0,
        },
        {
            title: 'Tablet',
            content: (
                <Tab>
                    <InventoryData
                        inventory={tabletInventory}
                        searchQuery={searchQuery}
                        page={tabletPage}
                        setPage={setTabletPage}
                        // setIsPage={setTabletPage}
                        inventoryPagination={tablet?.pagination?.totalPages}
                    />
                </Tab>
            ),
            count: tablet?.pagination?.totalItems || 0,
        },
    ];

    return (
        <div className="mt-7 sm:mt-0 w-full  ">
            <div className="flex justify-between items-center px-8 py-4">
                <p className="font-bold sm:text-[28px] text-[20px]">
                    Inventory
                </p>
                <button
                    onClick={handleAddInventory}
                    className="text-white font-light bg-[#C00010] rounded-[8px] px-3 sm:pr-[24px] sm:px-[16px] sm:py-[10px] py-2 text-[16px]"
                >
                    + Add
                </button>
            </div>
            <div className="px-4 py-4 font-700 text-xl text-black border-transparent bg-[#FFFFFF]  p-2 m-4  rounded-2xl overflow-x-auto sm:overflow-hidden">
                {isLoading ||
                isSLoading ||
                isLoading ||
                isCLoading ||
                isTLoading ? (
                    <div className="flex justify-center items-center py-64">
                        <Lottie
                            animationData={loadingState}
                            loop={true}
                            className="lottie"
                        />
                    </div>
                ) : (
                    <Tabs
                        tabs={tabs}
                        onSearchChange={handleSearchChange}
                    />
                )}
            </div>
        </div>
    );
};

export default ActiveState;
