import axios from 'axios';
import { useHospitalAuthStore } from '../../store/hospital/auth';
import { isTokenValid } from '../../utils/helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const AdminServiceAPI = () => {
    let headers = {};

    const url = window.location.href;
    if(!url.includes('/auth')) {
        const token = useHospitalAuthStore.getState().token;
        if (token) {
            if(!isTokenValid(token)){            
                toast.error('Session expired,kindly login again')
                setTimeout(()=>{
                    toast.dismiss();
                },5000)
                sessionStorage.removeItem('hospital-auth-store')
                window.location.href='/'
            }
            headers = {
                Authorization: `Bearer ${token}`,
            };
        }
    }
    
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_ADMIN_BASE_URL || '',
        timeout: 30000,
        headers,
    });
   

    return axiosInstance;
};

export default AdminServiceAPI;
