import React from 'react';
import logo from '../Images/logo.png';

const TMR = () => {
    return (
        <div className="flex w-full items-center justify-center gap-3">
            <img
                src={logo}
                alt="TMR"
                className="max-w-[100%] w-[94px] h-[88px]"
            />
            <p className="font-semibold text-[#201A19] sm:text-[36px]">
                TrackMedRec
            </p>
        </div>
    );
};

export default TMR;
