import React from 'react';
interface Props {
    logo: any;
    text: string;
    title: any;
}
const HospitalLogo: React.FC<Props> = ({ logo, title, text }) => {
    return (
        <div className="flex w-full items-center justify-center gap-2 py-6">
            <div>
                <img
                    src={logo}
                    alt="TMR"
                    className="max-w-[100%] w-[88px] h-[88px] rounded-full"
                />
            </div>
            <div className="flex flex-col ">
                <h3 className="font-semibold text-[#201A19] sm:text-[36px]">
                    {title}
                </h3>
                <p className="text-[12px] font-normal text-[#49454F] italic">
                    {text}
                </p>
            </div>
        </div>
    );
};

export default HospitalLogo;
