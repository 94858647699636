import usePagination from '../../hooks/usePagination';
import Table from '../../constants/Table';
import Log from '../../Images/log.svg';

const LogActivity = ({ activitylog }) => {
    const { page, limit, Pagination } = usePagination({
        page: 1,
        limit: 10,
        total: activitylog.length,
    });

    const paginatedRows = activitylog.slice((page - 1) * limit, page * limit);
    const activityLogHeaders = [
        { title: 'Date', render: (row: any) => <p>{row.dateCreated}</p> },
        { title: 'Action', render: (row: any) => <p>{row.action}</p> },

        { title: 'Activity', render: (row: any) => <p style={{ width: '200px', whiteSpace: 'normal', wordWrap: 'break-word' }}>{row.reason}</p> },
    ];
    return (
        <>
            <div className=" flex flex-col px-4 py-3 bg-white mt-8 rounded-2xl">
                <h2 className="text-2xl font-semibold text-lightBrown mb-3">
                    Log Activity
                </h2>
                {activitylog.length !== 0 ? (
                    <>
                        <Table
                            rows={paginatedRows}
                            headers={activityLogHeaders}
                            allowRowClick
                            showHead={true}
                        />
                        <Pagination />
                    </>
                ) : (
                    <div className="flex flex-col justify-center items-center mt-6">
                        <img src={Log} alt="empty" />
                        <p className="mt-8 mb-16 w-[40%] text-center text-sm font-medium text-dark100">
                            Log activity is empty
                        </p>
                    </div>
                )}
            </div>
        </>
    );
};
export default LogActivity;
