import React, { useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import {
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    InputAdornment,
    TextField,
    Tooltip,
    Radio,
    RadioGroup,
    FormControlLabel,
} from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { pharmacyRoutes } from '../../../../utils/pharmacyRouteNames';
import { InfoCircle, Trash } from 'iconsax-react';
import Input from '../../../../reusable/Forms/Input';
import ImageUpload from './../ImageUpload'
import upload from '../../../../Images/PharmacyImages/upload.svg'

function RequestDrug() {
    const [selectedImages, setSelectedImages] = useState<string[]>([]);
    const [selectedToDelete, setSelectedToDelete] = useState<number | null>(null);
    const navigate = useNavigate();

    const {
        formState: { isDirty, isValid },
        handleSubmit,
        control,
        reset,
        
    } = useForm<any>();

    const onSubmitForm: SubmitHandler<any> = (data) => {
        console.log('submit form', data);
    };

    const handleClearAll = () => {
        reset({
            drugName: '',
            category: '',
            doctorsReport: '',
            targetAge: '',
            price: '',
            MedStrength: '',
            state: '',
            city: '',
            minStock: '',
            productionDate: '',
            expiryDate: '',
            specialties: [],
            permissions: [],
        });
        setSelectedImages([]);
        setSelectedToDelete(null);
    };

    const handlePreview = () => {
        navigate(pharmacyRoutes.previewInventory);
    };

    const handleImageChange = (image: any) => {
        if (image) {
            setSelectedImages((prevImages) => {
                if (prevImages.length < 6) {
                    return [...prevImages, image];
                }
                return prevImages;
            });
        }
    };

    const handleRemoveClick = () => {
        if (selectedToDelete !== null) {
            setSelectedImages((prevImages) =>
                prevImages.filter((_, index) => index !== selectedToDelete)
            );
            setSelectedToDelete(null);
        }
    };
    const styles = {
        // backgroundColor: '#362F2E',
        fontSize: '12px',
        color: '#FBEEEC',
        padding: '2px',
    };
    return (
        <div className="flex flex-col md:flex-row justify-between md:space-x-6 mt-10 md:mt-4">
            <ToastContainer />
            <div className="flex justify-center flex-col items-center mt-10 md:mt-0 bg-white p-4 sm:h-[653px] rounded-[20px] lg:w-[358px]">
                <p className="text-[22px] font-normal lg:w-[326px] text-center text-lightBrown bg-lightPink p-4 rounded-t-[20px]">
                    Drug Picture(s)
                </p>

                <ImageUpload
                    onImageChange={handleImageChange}
                    selectedImage={

                        upload
                    }
                    selectedImages={selectedImages}
                />

                <div className="flex items-center justify-between w-full mt-5">
                    <p className="font-normal text-[16px]">Uploaded pictures</p>
                    <button
                        className={`${selectedToDelete === null ? 'opacity-[30%]' : ''} text-Tmred flex flex-row gap-0.5 items-center`}
                        onClick={handleRemoveClick}
                        disabled={selectedToDelete === null}
                    >
                        Delete
                        <Trash size="16" color="#C00010" />
                    </button>
                </div>
                <div className="overflow-y-auto grid grid-cols-2 w-full mt-5 gap-2 sm:h-[514px] bg-[#F6F6F7]">
                    {selectedImages.length > 0 &&
                        selectedImages.map((image, index) => (
                            <div key={index} className="relative rounded-lg bg-white h-[150px] w-[135px] p-2 m-2">
                                <img
                                    src={image}
                                    alt={`Selected ${index}`}
                                    className="w-[130px] h-[130px] object-contain"
                                />
                              <FormControl style={{ position: 'absolute', top: '8px', left: '8px' }}>
                            <RadioGroup
                                value={selectedToDelete}
                                onChange={(event) => {
                                    const value = parseInt(event.target.value, 10);
                                    setSelectedToDelete((prev) => (prev === value ? null : value));
                                }}
                            >
                            <FormControlLabel
            value={index.toString()}
            control={<Radio />}
            label=""
        />
    </RadioGroup>
                            </FormControl>

                                
                                 <span className="text-[11px] font-medium absolute bottom-2 right-2 bg-[#F2E5E3] w-4 h-4 text-center rounded-full">{index + 1}</span>
                            </div>
                        ))}
                </div>
            </div>
            <div className="flex-1 bg-white p-4 rounded-2xl mt-10 md:mt-0 lg:w-[490px] ">
                <h2 className="text-[22px] font-semibold text-lightBrown text-center p-4 bg-lightPink">
                    Product Details
                </h2>
                <form onSubmit={handleSubmit(onSubmitForm)} noValidate>
            
            <div className="flex flex-row mt-10">
            <Controller
                    name="drugName"
                    defaultValue=""
                    rules={{
                        required: 'Drug Name is required',
                    }}
                    control={control}
                    render={({ field }) => (
                        <Input
                                    label="Drug Name"
                                    {...field}
                                    
                                />
                    )}
                />

               
            </div>
            <div className="w-full py-2 mt-5">
                    <Controller
                        name="doctorsReport"
                        control={control}
                        rules={{
                            required: "Doctor's Report is required",
                        }}
                        render={({ field }) => (
                            <FormControl
                                fullWidth
                                sx={{ minWidth: 120 }}
                                size="small"
                            >
                                <InputLabel
                                    id="demo-select-small-label"
                                    htmlFor={'doctorsReport'}
                                >
                                    {'Doctors Report'}
                                </InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    label="Doctors Report (optional)"
                                    {...field}
                                    className="w-full p-2"
                                >
                                    <MenuItem value="Yes">Yes</MenuItem>
                                    <MenuItem value="No">
                                       No
                                    </MenuItem>
                                    
                                </Select>
                                
                            </FormControl>
                        )}
                    />
                </div>
            <div className="flex items-center my-6 space-x-6">
            <Controller
                        name="dosage"
                        control={control}
                        render={({ field }) => (
                            <FormControl
                                fullWidth
                                sx={{ minWidth: 120 }}
                                size="small"
                            >
                                <InputLabel
                                    id="demo-select-small-label"
                                    htmlFor={'dosage'}
                                >
                                    {'Dosage'}
                                </InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    label="Dosage"
                                    {...field}
                                    className="w-full p-2"
                                >
                                    <MenuItem value="Mr.">1</MenuItem>
                                    <MenuItem value="Mrs.">
                                        2
                                    </MenuItem>
                                    <MenuItem value="Miss">
                                       3
                                    </MenuItem>
                                    <MenuItem value={'Dr.'}>
                                        4
                                    </MenuItem>
                                </Select>
                                
                            </FormControl>
                        )}
                    />

<Controller
                        name="category"
                        control={control}
                        render={({ field }) => (
                            <FormControl
                                fullWidth
                                sx={{ minWidth: 120 }}
                                size="small"
                            >
                                <InputLabel
                                    id="demo-select-small-label"
                                    htmlFor={'category'}
                                >
                                    {'Category'}
                                </InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    label="Category"
                                    {...field}
                                    className="w-full p-2"
                                >
                                    <MenuItem value="Mr.">1</MenuItem>
                                    <MenuItem value="Mrs.">
                                        2
                                    </MenuItem>
                                    <MenuItem value="Miss">
                                       3
                                    </MenuItem>
                                    <MenuItem value={'Dr.'}>
                                        4
                                    </MenuItem>
                                </Select>
                                
                            </FormControl>
                        )}
                    />
            </div>
            <div className="flex items-center flex-col sm:flex-row my-6 gap-6">
            <Controller
                name="targetAge"
                defaultValue=""
                rules={{
                    required: 'Target Age is required',
                }}
                control={control}
                render={({ field }) => (
                    <Input label="Target Age" {...field} />
                )}
            />
            <Controller
                name="MedStrength"
                defaultValue=""
                rules={{
                    required: 'Strength of Medication is required',
                }}
                control={control}
                render={({ field }) => (
                    <Input label="Strength of Medication " {...field} />
                )}
            />
</div>
            <div className="flex items-center my-6 space-x-6">
            <Controller
                    name="productionDate"
                    defaultValue=""
                    rules={{
                        required: 'Production Date is required',
                    }}
                    control={control}
                    render={({ field }) => (
                        <Input label="Production Date" {...field} />
                    )}
                />
                <Controller
                    name="expiryDate"
                    defaultValue=""
                    rules={{
                        required: 'Expiry Date is required',
                    }}
                    control={control}
                    render={({ field }) => (
                        <Input label="Expiry Date" {...field} />
                    )}
                />
            </div>
            <Controller
                    name="price"
                    defaultValue=""
                    rules={{
                        required: 'Price is required',
                    }}
                    control={control}
                    render={({ field }) => (
                        <Input label="Price (₦)" {...field} />
                    )}
                />
            <div className="flex items-center flex-col lg:flex-row space-x-6 my-10">
             

                
            <Controller
                name="minStock"
                defaultValue=""
                rules={{ required: 'Minimum Stock Level is required' }}
                control={control}
                render={({ field }) => (
                    <TextField
                        className="w-full"
                        type="number"
                        {...field}
                        label="Minimum Stock Level"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Tooltip
                                        title={
                                            <div style={styles}>
                                                Notify me when stock is 
                                                <br />
                                                below this number
                                                
                                            </div>
                                        }
                                    >
                                        <InfoCircle size="20" color="#534341" />
                                    </Tooltip>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            />
           
<div className='flex items-center justify-center '>
    <p className='border-b-2 p-2 text-[#534341]'></p>
</div>
<div className='lg:w-[423px]'>
                <Controller
                    name="maxStock"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                        <Input label="Maximum Stock Level" {...field} />
                    )}
                />
                </div>
            </div>
            <Controller
                        name="manufacturerNumber"
                        defaultValue=""
                        rules={{
                            required: 'Manufacturer information  is required',
                        }}
                        control={control}
                        render={({ field }) => (
                            <Input
                                label="Manufacturer information "
                                {...field}
                            />
                        )}
                    />
            <Controller
                name="sideEffect"
                control={control}
                defaultValue=""
                rules={{
                    required: 'side effect information  is required',
                }}
                render={({ field }) => (
                    <textarea
                        placeholder="Side Effects"
                        required={true}
                        className="w-full h-[100px] my-6 border-[1px] border-dark100 p-4 rounded-[8px] text-[16px] tracking-wider focus:border-[1px] focus:outline-none focus:border-dark100"
                        {...field}
                    ></textarea>
                )}
            />
            
           

            <div className="flex justify-end space-x-6 py-3 border-t mt-10">
                <button
                    type="reset"
                    className="py-2 px-3 text-Tmred font-normal text-base"
                    onClick={handleClearAll}
                >
                    Clear All
                </button>
                <button
                    disabled={!isDirty || !isValid}
                    className={`py-2 px-3 text-Tmred  text-base border rounded-lg   border-[#857371] ${
                        
                        !isDirty || !isValid
                             ? 'cursor-not-allowed font-normal'
                             : ' hover:font-semibold hover:cursor-pointer'
                     }`}
                    onClick={handlePreview}
                >
                    Preview
                </button>
                <button
                    disabled={!isDirty || !isValid}
                    type="submit"
                    className={` px-6 py-2 text-lightBrown text-base font-normal rounded-lg 
                     ${
                        //  !isDirty || !isValid || mutation.isLoading
                        !isDirty || !isValid
                             ? 'bg-lightGray cursor-not-allowed'
                             : ' bg-Tmred text-white hover:cursor-pointer'
                     }
                    `}
                >
                    {/* {mutation.isLoading ? 'Registering...' : 'Register'}
                     */}
                    Save Product
                </button>
            </div>
        </form>
            </div>
        </div>
    );
}

export default RequestDrug;
