import DashboardLayout from './DashboardLayout';
import RecordSearchTable from './RecordSearchTable';
import {Helmet} from 'react-helmet'


const RecordSearch = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Search Result
                </title>
            </Helmet>
            <RecordSearchTable />
        </DashboardLayout>
    );
};
export default RecordSearch;
