import React from 'react'
import DashboardLayout from '../../Dashboard/DashboardLayout'
import { Helmet } from 'react-helmet'
import Fleet from '../../../../components/Ambulance/FleetMgmt/Fleet'

function FleetMgmt() {
  return (
    <DashboardLayout>
    <Helmet>
        <title>
           TMR | Ambulance
        </title>
    </Helmet>
    <div className="">
       
       <Fleet/>
    </div>
</DashboardLayout>
  )
}

export default FleetMgmt