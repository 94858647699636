import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';

interface CheckboxProps {
    label?: string;
    name?: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
    isLast?: boolean;
}

const CustomCheckbox: React.FC<CheckboxProps> = ({
    label,
    name,
    checked,
    onChange,
    isLast,
}) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked);
    };

    return (
        <Box
            borderBottom={isLast ? 0 : 1}
            borderColor="grey.300"
            p={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
        >
            <span>{label}</span>
            <FormControlLabel
                control={
                    <Checkbox
                        name={name}
                        checked={checked}
                        onChange={handleChange}
                    />
                }
                label=""
                labelPlacement="start"
            />
        </Box>
    );
};

export default CustomCheckbox;
