import { useState, useRef, useEffect } from 'react';
import icon from '../../../Images/Icon.png';

const RecordSearchModal = ({ setIsModalOpen }) => {
    const modalRef = useRef<HTMLDivElement | null>(null);
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleOverlayClick = (event: any) => {
        if (
            modalRef.current &&
            !modalRef.current.contains(event.target as Node)
        ) {
            setIsModalOpen(false);
        }
    };

    // Add an event listener to detect clicks outside the modal
    useEffect(() => {
        document.addEventListener('mousedown', handleOverlayClick);

        return () => {
            document.removeEventListener('mousedown', handleOverlayClick);
        };
    }, []);
    const [selectedOption, setSelectedOption] = useState(null);

    const handleOptionChange = (option: any) => {
        setSelectedOption(option);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-[#2826265E] bg-opacity-75 z-50">
            <div
                ref={modalRef}
                className="bg-white md:p-6 p-2 rounded-[28px] mx-2 "
            >
                <h2 className="font-semibold text-2xl mb-5">Filter By</h2>

                <div className=" my-9">
                    <h2 className="font-medium text-base text-dark100 mb-3">
                        Gender
                    </h2>
                    <div className="md:flex items-center sm:space-x-3 space-y-1 md:space-y-0 ml-4">
                        <div className="flex flex-row justify-center gap-2 px-10 border-[1px] text-lightBrown border-text-lightBrown100text-sm py-[6px] rounded-lg">
                            <img src={icon} alt="" />
                            Male
                        </div>
                        <div className="flex flex-row justify-center gap-2 px-10 border-[1px] text-lightBrown border-text-lightBrown100text-sm py-[6px] rounded-lg">
                            <img src={icon} alt="" />
                            Female
                        </div>
                    </div>
                </div>

                <div className="py-8">
                    <h2 className="font-medium text-base text-dark100 mb-3">
                        Order
                    </h2>
                    <div className="ml-4">
                        <label className="flex items-center space-x-2 cursor-pointer mb-2">
                            <input
                                type="checkbox"
                                className="p-[2px] rounded-sm cursor-pointer"
                                checked={selectedOption === 'ascending'}
                                onChange={() => handleOptionChange('ascending')}
                            />
                            <span className="text-base font-normal">
                                Ascending
                            </span>
                        </label>
                        <label className="flex items-center space-x-2 cursor-pointer ">
                            <input
                                type="checkbox"
                                className="p-[2px] rounded-sm  cursor-pointer"
                                checked={selectedOption === 'descending'}
                                onChange={() =>
                                    handleOptionChange('descending')
                                }
                            />
                            <span className="text-base font-normal">
                                Descending
                            </span>
                        </label>
                    </div>
                </div>
                <div className="flex justify-end space-x-6">
                    <button
                        type="button"
                        onClick={handleCloseModal}
                        className="px-[12px] py-[10px]"
                    >
                        Close
                    </button>
                    <button
                        onClick={handleCloseModal}
                        type="button"
                        className="py-[10px] px-6 rounded-lg text-white bg-Tmred"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RecordSearchModal;
