import React, { useEffect, useState } from 'react'
import ambulance from '../../../Images/ambulance.svg'
import BlockButton from '../../../reusable/BlockButton'
import Modal from '../../../reusable/Modal'
import SmallModal from '../../../reusable/SmallModal'
import { TextField } from '@mui/material'
import { useHospitalAuthStore } from '../../../store/hospital/auth'
import { standardAmountFormat } from '../../../utils/helpers'
import { useMutation } from 'react-query'
import hospitalService from '../../../services/hospitalService'
import { toast } from 'react-toastify'

function SetUpAmbulance({ setIsAmbulanceSetUp }) {
    const [showModal, setShowModal] = useState(false);
    const [showSmallModal, setShowSmallModal] = useState(false);
    const [editingService, setEditingService] = useState(null); // Track which service is being edited
    const [buttonEnabled, setButton] = useState(false);
    const [buttonEnabled1, setButton1] = useState(false);
    const currency = useHospitalAuthStore((state) => state.hospital.currency);
    // const [isLoading, setIsLoading] = useState(false);
    const [modalFormData, setModalFormData] = useState({
        BLS: '',
        ALS: '',
        newAmount: '', 
    });
    const hospitalAuthStore = useHospitalAuthStore((state) => state);
    const handleShowModal = () => {
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
    }

    const handleCloseSmallModal = () => {
        setShowSmallModal(false);
    }

    const handleEditClick = (service) => {
        setEditingService(service);
        setModalFormData((prevData) => ({
            ...prevData,
            newAmount: prevData[service] || '', // Pre-fill with existing amount if available
        }));
        setShowSmallModal(true);
    }

    const handleAmountChange = (e) => {
        const { value } = e.target;
        setModalFormData((prevData) => ({
            ...prevData,
            newAmount: value,
        }));
        setButton(!!value);
    }

    const handleSaveModal = () => {
        setButton1(false)
        if (editingService) {
            setModalFormData((prevData) => {
                const updatedData = {
                    ...prevData,
                    [editingService]: prevData.newAmount,
                };
    
                
                const payload = {
                    basicLifeSupport: {
                        name: "BLS",
                        amount: updatedData.BLS || 0,
                    },
                    advancedLifeSupport: {
                        name: "ALS",
                        amount: updatedData.ALS || 0,
                    }
                };
    
                // Ensure either BLS or ALS is set before sending the data
                if (payload.basicLifeSupport.amount || payload.advancedLifeSupport.amount) {
                    mutation.mutate(payload);
                    
                } else {
                    toast.error('Please set an amount for either BLS or ALS.');
                }
    
                return updatedData;
            });
        }
    };
    
    const handleSaveAmount = () => {
        if (editingService) {
            setModalFormData((prevData) => {
                const updatedData = {
                    ...prevData,
                    [editingService]: prevData.newAmount,
                };
setButton1(true)
                

                return updatedData;
            });
        }
        setShowSmallModal(false);
    }
    const mutation = useMutation((payload: any) =>
        hospitalService.activateAmbulance(payload)
    );
    useEffect(() => {
        if (mutation.isSuccess) {
            // setIsLoading(false);
            console.log('success', mutation.data);
            let payload: any = mutation.data;
            
            hospitalAuthStore.updateHospital(payload.data.payload.hospital)
            setShowSmallModal(false);
            toast.success('Ambulance Activated Successfully')
            setIsAmbulanceSetUp(true);
        }
    }, [mutation.isSuccess, mutation.data, hospitalAuthStore, setIsAmbulanceSetUp]);
    useEffect(() => {
        if (mutation.isError) {
            // setIsLoading(false);
            let err: any = mutation?.error;
            // console.log(err);
            console.log(err.response.data.error.message);
            toast.error(err.response.data.error.message)
            setShowModal(false);
            
        }
    }, [mutation?.error, mutation.isError]);
    return (
        <div>
            <div className="flex items-center justify-center w-full m-auto overflow-y-auto h-full">
                <div className="bg-white border border-[#D8C2BF] pt-6 rounded-[16px] flex flex-col sm:w-[50%] lg:h-fit my-5" style={{ 'boxShadow': '0px 8px 12px 6px #00000026' }}>
                    <img src={ambulance} alt="TMRambulance img" />
                    <div className='flex flex-col gap-6 py-6 px-6'>
                        <h1 className='font-semibold text-[28px] text-[#201A19]'>Set up your Ambulance Fleet</h1>
                        <p className='w-full font-normal text-[18px] text-[#534341]'>Ready to enhance your emergency response capabilities? Start managing your ambulance fleet right here. Add new ambulances, update existing ones, and keep your services running smoothly.<br /><br />Click below to get started.</p>
                        <BlockButton
                            onclick={handleShowModal}
                            text="Setup Ambulance"
                            disabled={false}
                            type="button"
                            isLoading={false}
                        />
                    </div>
                </div>
            </div>
            <Modal
                isVisible={showModal}
                onClose={handleCloseModal}
                onSave={handleSaveModal}
                buttonEnabled={buttonEnabled1}
                setButton={setButton1}
                saveText="Save"
                closeText="Cancel"
                showCloseButtonBorder={false}
            >
                <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2 flex flex-col ">
                    <h2 className="font-semibold text-2xl mb-5 w-full">
                        Set amount for type of ambulance
                    </h2>
                    <p className="text-[14px] font-normal text-[#534341] mb-6">
                        Select a type and add amount
                    </p>
                    <div className='flex items-center w-full justify-between'>
                        <p className="text-[16px] font-normal text-[#534341]">
                            Basic Life Support (BLS)
                            <br />
                            <span className='text-[14px]'>{modalFormData.BLS ? `${standardAmountFormat(modalFormData.BLS,currency)}` : 'No amount Set'}</span>
                        </p>
                        <button
                            className='text-[#C00010] text-[14px] font-medium underline cursor-pointer'
                            onClick={() => handleEditClick('BLS')}
                        >
                            Edit
                        </button>
                    </div>
                    <div className='flex items-center w-full justify-between mt-6'>
                        <p className="text-[16px] font-normal text-[#534341]">
                            Advanced Life Support (ALS)
                            <br />
                            <span className='text-[14px]'>{modalFormData.ALS ? `${standardAmountFormat(modalFormData.ALS,currency)}` : 'No amount Set'}</span>
                        </p>
                        <button
                            className='text-[#C00010] text-[14px] font-medium underline cursor-pointer'
                            onClick={() => handleEditClick('ALS')}
                        >
                            Edit
                        </button>
                    </div>
                </div>
            </Modal>
            <SmallModal
                isVisible={showSmallModal}
                onClose={handleCloseSmallModal}
                onSave={handleSaveAmount}
                buttonEnabled={buttonEnabled}
                setButton={setButton}
                saveText="Save"
                closeText={true}
                showCloseButtonBorder={true}
                title={`Editing ${editingService === 'BLS' ? 'Basic Life Support' : 'Advanced Life Support'}`}
            >
                <div className="flex flex-col gap-5 sm:w-[406px] py-5">
                    <TextField
                        className="w-full"
                        variant="outlined"
                        label="Amount"
                        name="amount"
                        value={modalFormData.newAmount || ''}
                        onChange={handleAmountChange}
                        type='number'
                    />
                </div>
            </SmallModal>
        </div>
    )
}

export default SetUpAmbulance;
