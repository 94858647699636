const medicalTitles = [
    "AuD - Doctor of Audiology",
    "DC - Doctor of Chiropractic",
    "DDS - Doctor of Dental Science (or Doctor of Dental Surgery)",
    "DMD - Doctor of Dental Medicine (or Doctor of Medical Dentistry)",
    "DO or OD - Doctor of Osteopathy Medicine (or Doctor of Optometry)",
    "DPM - Doctor of Podiatric Medicine",
    "DPT - Doctor of Physical Therapy",
    "DScPT - Doctor of Science in Physical Therapy",
    "DSN - Doctor of Science in Nursing",
    "DVM - Doctor of Veterinary Medicine",
    "ENT - Ear, nose and throat specialist",
    "GP - General Practitioner",
    "GYN - Gynecologist",
    "LSA - Licensed Surgical Assistant",
    "MD - Doctor of Medicine",
    "MLA - Medical Laboratory Assistant",
    "MP - Medical Psychologist",
    "MS - Master of Surgery",
    "OB/GYN - Obstetrician and Gynecologist",
    "PA - Physician’s Assistant",
    "PharmD - Doctor of Pharmacy",
    "Dr. - Doctor",
    "RN - Registered Nurse",
    "LPN - Licensed Practical Nurse",
    "NP - Nurse Practitioner",
    "PA - Physician Assistant",
    "MD - Medical Doctor (Doctor of Medicine)",
    "DO - Doctor of Osteopathic Medicine",
    "DDS - Doctor of Dental Surgery",
    "DMD - Doctor of Dental Medicine",
    "PharmD - Doctor of Pharmacy",
    "PhD - Doctor of Philosophy (used for researchers and educators)",
    "Pharmacist - Pharmacist",
];
export default medicalTitles;
  