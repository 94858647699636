import React from 'react'
import { Helmet } from 'react-helmet'
import DashboardLayout from '../Dashboard/DashboardLayout'
import CreateADS from '../../../components/PharmacyComponent/TmrAds/CreateADS'

function CreateAds() {
  return (
    <DashboardLayout>
        <Helmet>
            <title>
                TMR | Create Ads
            </title>
        </Helmet>
        <div className="">
            <CreateADS />
        </div>
  </DashboardLayout>
  )
}

export default CreateAds