import React, { Fragment, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import EmptySearch from '../../../Images/EmptyAmbulance.svg';
import Table from '../../../constants/Table';
import 'rc-slider/assets/index.css';
import Lottie from 'lottie-react';
import loadingState from '../../../Images/loading.json';
import hospitalService from '../../../services/hospitalService';
import { useQuery } from 'react-query';
import  {
    formatDateDmy,
    standardAmountFormat,
} from '../../../utils/helpers';
import { hospitalRoutes } from '../../../utils/routeNames';
import { useHospitalAuthStore } from '../../../store/hospital/auth';
interface rowType {
    _id: string;
    ambulanceId: any;
}
const RecentAmbulance = () => {
    const navigate = useNavigate();
    const [isambulanceHistory, setIsambulanceHistory] = useState(false);
    const [ambulanceHistories, setambulanceHistories] = useState<any[]>([]);
    const currency = useHospitalAuthStore((state) => state.hospital.currency);
    const { data, isError, isLoading } = useQuery(
        'ambulance',
        hospitalService.getAllAmbulance
    );
    useEffect(() => {
        if (data?.fleets) {
            setambulanceHistories(data?.fleets?.slice(0, 10));
            setIsambulanceHistory(true);
        } else {
            setIsambulanceHistory(false);
        }
    }, [data]);

    if (isLoading) {
        return (
            <div className="flex justify-center items-center py-32">
                <Lottie
                    animationData={loadingState}
                    loop={true}
                    className="lottie"
                />
            </div>
        );
    }
    if (isError) {
        return <div>Error loading ambulance history, please refresh your page</div>;
    }
    const ambulanceHistoryHeader = [
        {
            title: 'S/N',
            render: (row: any) => (
                <div className="py-[6px] text-[12px] flex items-center gap-2 font-medium text-light_gray text-center">
                    <p className="text-xs font-medium">
                        {ambulanceHistories?.findIndex(
                            (user: any) => user._id === row._id
                        ) + 1}
                    </p>
                </div>
            ),
        },
        {
            title: 'Ambulance ID',
            render: (row: any) => <p>{row.licenseNumber}</p>,
        },
        {
            title: 'Type of Ambulance',
            render: (row: any) => (
                <>
                    <div>
                        <p className="truncate w-32 font-medium text-lightBrown text-sm ">
                            {`${row.ambulanceType}`}
                        </p>
                        
                    </div>
                </>
            ),
        },
       

        {
            title: 'Amount',
            render: (row: any) => (
                <p className="">
                    {standardAmountFormat(row.amount, currency)}
                </p>
            ),
          
        },
        {
            title: 'Status',
            render: (row: any) => (
                <div
                className={`flex items-start justify-center p-2 rounded 
                    ${
                        row.status === 'On Duty'
                            ? 'bg-[#FDDFA6] text-lightBrown'
                            : ''
                    } 
                    ${
                        row.status === 'Available'
                            ? 'bg-[#E2EEE7] text-lightBrown'
                            : ''
                    }
                    ${
                        row.status === 'Not Available'
                            ? 'bg-[#FDDFA6] text-lightBrown'
                            : ''
                    }
                `}
            >
                <div
                    className={`w-[6px] h-[6px] rounded-full mr-2 mt-[6px] 
                        ${row.status === 'On Duty' ? 'bg-lightBrown' : ''}
                        ${row.status === 'Available' ? 'bg-[#3A975F]' : ''}
                        ${row.status === 'Not Available' ? 'bg-lightBrown' : ''}
                    }`}
                />
                <p className="text-xs">{row.status}</p>
            </div>
            ),
        },

        {
            title: 'Date Created',
            render: (row: any) => <p> {formatDateDmy(row.createdAt)}</p>,
        },
        
    ];

    return (
        <Fragment>
            <div className="w-full flex flex-col  py-4  ">
                <div className="bg-white p-4 rounded-2xl">
                    <div className="flex flex-col-reverse sm:flex-row items-center justify-between gap-4 w-full px-4">
                        <div className="flex items-center space-x-3">
                            <h2 className="md:text-2xl text-sm font-semibold text-dark">
                                Recent Ambulance
                            </h2>
                        </div>
                    </div>
                    <div className="mt-[9px] flex flex-col px-4 py-3 overflow-x-auto scrollbar-hide">
                        {isambulanceHistory && ambulanceHistories.length !== 0 ? (
                            <>
                                <Table
                                    // key={ambulanceHistories.findIndex()}
                                    rows={ambulanceHistories?.slice(0, 5)}
                                    headers={ambulanceHistoryHeader}
                                    allowRowClick
                                    showHead={true}
                                    onRowClick={(row: rowType) =>
                                        navigate(
                                            `/hospital/ambulance/fleet/ambulance-profile/${row._id}`
                                        )
                                    }
                                />
                                <div className="flex items-center justify-center w-full my-3 pt-6">
                                    <Link
                                        to={hospitalRoutes.ambulanceHistory}
                                        className="w-full sm:w-fit h-[40px] text-[14px] flex items-center text-center justify-center text-[#C00010] border border-[#C00010] py-2 px-6 rounded-lg font-medium"
                                    >
                                        View all
                                    </Link>
                                </div>
                            </>
                        ) : (
                            <div className="flex flex-col items-center justify-center text-center my-[70px] ">
                                <img
                                    src={EmptySearch}
                                    className="w-[12rem]"
                                    alt="empty"
                                />
                                <p className="mt-6 text-base font-medium font-[Montserrat]">
                                No ambulances are currently available or listed. Add new ambulances to start managing your fleet and ensure your services are ready when needed.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default RecentAmbulance;
