import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

interface SelectElementProps {
    onChange: any;
    value: string | number;
    name: string;
    options: string[];
    defaultValue?:any
}

const SelectElement: React.FC<SelectElementProps> = ({
    onChange,
    value,
    name,
    options,
    defaultValue,
}) => {
    return (
        <div>
            <FormControl fullWidth sx={{ minWidth: 120 }} size="small">
                <InputLabel id={`select-label-${name}`} htmlFor={name}>
                    {name}
                </InputLabel>
                <Select
                    labelId={`select-label-${name}`}
                    id={`select-${name}`}
                    label={name}
                    onChange={onChange}
                    name={name}
                    value={value}
                    defaultValue={defaultValue}
                    className="w-full p-2"
                >
                    {options.map((option, index) => (
                        <MenuItem key={index} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default SelectElement;
