import React, { useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';

type propType = {
    btn?: any;
    message?: any;
    passcode: any;
    setPasscode: any;
    handleSubmit?: any;
};
const Passcode: React.FC<propType> = ({
    btn,
    message,
    passcode,
    setPasscode,
    handleSubmit,
}) => {
    const [buttonEnabled, setButton] = useState(false);

    useEffect(() => {
        if (passcode.length === 4) {
            setButton(true);
        } else {
            setButton(false);
        }
    }, [passcode]);

    return (
        <div>
            <div>
                <OtpInput
                    value={passcode}
                    onChange={setPasscode}
                    numInputs={4}
                    inputType="number"
                    containerStyle={'my-5'}
                    inputStyle={
                        'border bg-[#EDE0DE] !w-[50px] sm:!w-[90px] h-[50px] sm:h-[80px] mx-2 outline-none rounded-[8px] codeInput'
                    }
                    renderSeparator={<span></span>}
                    renderInput={(props) => <input {...props} />}
                />
            </div>
            {message && (
                <div>
                    <p className="text-[16px] text-center tracking-widest">
                        {message}
                    </p>
                </div>
            )}
            {btn && (
                <div>
                    <button
                        onClick={handleSubmit}
                        className={`w-full h-[40px] flex justify-center rounded-lg items-center text-[#9d9b9b] font-semibold mt-4  hover:cursor-not-allowed transition-all duration-500  ${
                            buttonEnabled
                                ? 'bg-Tmred text-white hover:cursor-pointer '
                                : 'bg-[#1D1B201F] '
                        } `}
                    >
                        {btn}
                    </button>
                </div>
            )}
        </div>
    );
};
export default Passcode;
