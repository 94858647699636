import {useState, useEffect} from 'react'
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import Input from '../../../../reusable/Forms/Input';


import {
    
    MenuItem
} from '@mui/material';
import BlockButton from '../../../../reusable/BlockButton';
import BankDetailsDisclaimer from './BankDetailsModal';
import { pharmacyService } from '../../../../services/pharmacyService';
import { usePharmacyAuthStore } from '../../../../store/pharmacy/auth';

type DetailsProps = {
    bankName: string;
    bankCode: string;
    accountNumber: string; 
    accountName: string; 
};

const BankAccount = () => {
    const {
        formState: { isDirty, isValid },
        handleSubmit,
        control,
        reset,
    } = useForm<DetailsProps>();
    const pharmacyAuthStore = usePharmacyAuthStore((state) => state);
    const [isLoading, setIsLoading] = useState(false)
    const [bankDetails, setBankDetails] = useState<DetailsProps>()
    const [bankName, setBankName] = useState('')

    const [showModal, setShowModal] = useState(false)
    const [isBankDetails, setIsBankDetails] = useState(false)
    const [bankObj, setBankObj] = useState([])
    const [filteredBanks, setFilteredBanks] = useState([]);
     const [searchTerm, setSearchTerm] = useState('');
     const [showBanks, setShowBanks] = useState(false);

    
    useEffect(() => {
        //This useEffect is to set the bank details to the data in the session storage when the user hasn't re-logged in after saving bank details

        const sessionStore = sessionStorage.getItem('pharmacybankDetailsSessionStore')
        //This is to check if the session store is empty
        if(sessionStore  === null || sessionStore === '' || sessionStore === "{}" || sessionStore === undefined){
            // It is only after the user logs in again after saving bank detail that the pharmacy store will be populated with the bank details
            if(pharmacyAuthStore.pharmacy.bankDetails && pharmacyAuthStore.user.userType === 'superadmin' ){
                setIsBankDetails(true)
                setBankDetails(pharmacyAuthStore.pharmacy.bankDetails)
            } else{
                setBankDetails(pharmacyAuthStore.user.bankDetails)
            }
        } else{
            //Check if the bankDetails from the pharmacy store exist before setting bankDetails to the data in the session storage
                if(isBankDetails === false){
                    const pharmacybankDetailsSessionStore =JSON.parse(sessionStore)
                    setBankDetails(pharmacybankDetailsSessionStore)
                }
            }
    }, [])

    useEffect(() => {
        
    }, [])
    
    

    useEffect(() => {

        pharmacyService.getBanks()
        .then((res) => {
            const sortedBankObj = res.banks.slice().sort((a:any, b:any) =>
            a.name.localeCompare(b.name)
            );
            setBankObj(sortedBankObj)
            setFilteredBanks(sortedBankObj);
            
        })
        .catch((error) => {
            console.log(error);
            toast.error(error.message)
        })
    }, [])

    useEffect(() => {
        if (!searchTerm) {
        setFilteredBanks(bankObj);
        } else {
        const filtered = bankObj.filter((bank: any) =>
            bank.name.toLowerCase().includes(searchTerm.toLowerCase()),
        );
        setFilteredBanks(filtered);
        }
    }, [searchTerm, bankObj]);

    const handleBankSelect = (bankName) => {
        setSearchTerm(bankName)
        setShowBanks(false)
    }

    // const handleSearch = (event) => {
    //     const searchTerm = event.target.value.toLowerCase();
    //     const filtered = bankObj.filter(
    //     (bank) => bank.name.toLowerCase().includes(searchTerm)
    //     );
    //     setFilteredBanks(filtered);
    // };

    const submitDetails: SubmitHandler<DetailsProps> = (data) =>{
        setIsLoading(true)
        const { bankCode} = data        
        pharmacyService.resolveBankAccount(data)
        .then((res) =>{
            console.log(res);    
            setBankDetails({
                bankName,
                ...res.accountDetails,
                bankCode,
            })
            setShowModal(true)  
            setIsLoading(false)          
        })
        .catch((error) => {
            console.log(error); 
            toast.error(error.response.data.error.message)
            setIsLoading(false)
        })
    }


    const handleSaveDetails = (saveDetails) => {
        setIsBankDetails(saveDetails);
    }
    const handleCloseModal = () => {
        setShowModal(false)
    }

    
    
    return (
        <>
            <div className="bg-white  rounded-lg shadow-sm  ">
                <h1 className=" text-[22px] font-semibold mt-8">
                    Bank Details
                </h1>

                {
                    !isBankDetails ? 
                    (
                        <>
                            <p className=" text-[14px] w-[500px] my-3">
                                Please provide your banking details below, ensuring that the account name matches the name on your dashboard.
                            </p>
        
                            <form className=" my-12 " onSubmit={handleSubmit(submitDetails)}>
            
                                <div className='w-[500px]'>
            
                                    <div className="mb-[20px]">
                                        {/* Search Input */}
                                        

                                        <input 
                                            type="text" 
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            onFocus={() => setShowBanks(true)}
                                            onBlur={() => setTimeout(() => {
                                                setShowBanks(false)
                                            }, 500)}
                                            placeholder='Search Bank'
                                            className="p-4 mb-4 w-[100%] border rounded-md border-Tmred"
                                        />

                                        <Controller
                                            control={control}
                                            name='bankCode'
                                            render={({ field }) => (
                                                <div className={`w-full max-h-400px h-auto shadow-md overflow-y-scroll ${showBanks ? 'block' : 'hidden'}`}>
                                                {filteredBanks.map((bank: any, index) => (
                                                    <MenuItem
                                                    onClick={() => {
                                                        field.onChange(bank.code);
                                                        handleBankSelect(bank.name);
                                                        setBankName(bank.name)
                                                    }}
                                                    key={index}
                                                    value={bank.code}
                                                    >
                                                    {bank.name}
                                                    </MenuItem>
                                                ))}
                                                </div>
                                            )}
                                        />

                                        
                                    </div>
            
                                    <Controller
                                            name="accountNumber"
                                            rules={{ required: 'Account number is required' }}
                                            control={control}
                                            render={({ field }) => (
                                                <Input 
                                                className="!w-[516px]"
                                                label="Account Number" {...field} />
                                            )}
                                        />
                                </div>
            
                                <div className="border border-b mt-12 "/>
                                <div className=" flex justify-end">
                                    <BlockButton 
                                        text="Confirm Details"
                                        disabled={!isDirty || !isValid}
                                        type="submit"
                                        isLoading = {isLoading}
                                        className="!w-auto p-3 mt-3"
                                    />
                                </div>
                            </form>
                        </>
                    ) :

                    (
                        <>
                            <div className="rounded-[28px] bg-lightGray400 px-6 py-3 mt-5 max-w-[700px]">
                                <div className="flex justify-between  py-5 border-b-2 border-brown100 items-center">
                                    <span>Bank Name</span>
                                    <h1 className="text-[22px]">
                                        {bankDetails?.bankName}
                                    </h1>
                                </div>
                                <div className="flex justify-between  py-5 border-b-2 border-brown100 items-center">
                                    <span>Account Number</span>
                                    <h1 className="text-[22px]" >
                                        {bankDetails?.accountNumber}
                                    </h1>
                                </div>
                                <div className="flex justify-between gap-8 py-5 items-center">
                                    <span>Account Name</span>
                                    <h1 className="text-[22px]">{bankDetails?.accountName}</h1>
                                </div>
                            </div>

                            <div className="flex gap-6 mt-8 w-[500px] bg-lightPink100 px-4 py-2 rounded-[8px] items-center">
                                <div>
                                    <AiOutlineExclamationCircle  className="text-[20px]" />

                                </div>
                                <p className="text-[12px]">
                                    For assistance with updating your bank details, our dedicated TMR Support team is here to help. Feel free to reach out to us via chat for any questions or guidance you may need.
                                </p>
                            </div>
                        </>
                    )
                }
            </div>    
            {
                showModal && <BankDetailsDisclaimer closeModal = {handleCloseModal} bankDetails= {bankDetails} onSaveDetails = {handleSaveDetails} /> 
            }
        </>
    )
}

export default BankAccount