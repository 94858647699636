import { Add } from 'iconsax-react'
import React from 'react'
import { Link } from 'react-router-dom'
import { hospitalRoutes } from '../../utils/routeNames'
import AddHistories from './AddHistories'

function Ads() {
  return (
    <div className="w-full flex flex-col md:px-8 py-4 px-2">
            <div className="flex justify-between items-center py-4">
                <h2 className="font-bold sm:text-[28px] text-[20px]">
                    ADS
                </h2>
                <Link
                    to={hospitalRoutes.createAds}
                    className={`w-fit px-4 h-[40px] flex justify-center gap-1 rounded-lg items-center bg-Tmred text-white hover:cursor-pointer`}
                >
                    <Add size="24" color="#ffffff" />
                    <span className=" tracking-wide">Create New Ads</span>
                </Link>
            </div>
            <AddHistories/>
    </div>
  )
}

export default Ads