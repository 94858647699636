import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';


interface DatePickerElementProps {
    label: string;
    value: Dayjs | null;
    onChange: (date: Dayjs | null) => void;
}

const DatePickerElement: React.FC<DatePickerElementProps> = ({
    label,
    value,
    onChange,
}) => {
    const handleDateChange = (date: Date | null) => {
        // Convert Date to Dayjs before passing it to the parent component
        onChange(date ? dayjs(date) : null);
    };

    return (
        <div className="w-full">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label={label}
                    value={value ? value.toDate() : null}
                    onChange={handleDateChange}
                />
            </LocalizationProvider>
        </div>
    );
};

export default DatePickerElement;




