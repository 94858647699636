import TotalPatients from './TotalPatients';
import { useNavigate } from 'react-router-dom';
import search from '../../Images/Leading-icon.png';
import filter from '../../Images/filtericon.svg';
import Filter from './Filter';
import { useState } from 'react';
import { hospitalRoutes } from '../../utils/routeNames';
import { usePatientStore } from '../../store/hospital/auth';

const Patients = () => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const patientCount = usePatientStore((state) => state.patientCount);
    const [buttonEnabled, setButton] = useState(false);
    const [queryParam, setQueryParam] = useState<any>({
        gender: '',
        fromDate: null,
        toDate: null,
        order: '',
    });

    const handleModal = () => {
        setOpenModal(true);
    };
    const closeModal = () => {
        setOpenModal(false)
    }

    const onFilter = (queryParams: any) => {
        setQueryParam(queryParams);
    };

    const onSave = () => {
        setButton(false);
        setQueryParam(queryParam);
        closeModal();
    };

    return (
        <div className="mx-8 mt-6">
            <div className="flex items-center justify-between">
                <h2 className="font-semibold text-[28px]">Patient</h2>
                <button
                    className="bg-Tmred text-white rounded-lg p-3"
                    onClick={() => navigate(hospitalRoutes.newPatient)}
                >
                    + New Patient
                </button>
            </div>
            <div className="bg-white p-4 rounded-2xl mt-8">
                <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-3">
                        <h2 className="md:text-2xl text-sm font-semibold text-dark">
                            Total Patients
                        </h2>
                        <button
                            type="button"
                            className="md:text-[22px] text-sm bg-red py-1 text-white px-1 md:px-[11.64px] rounded-[290.909px]"
                        >
                            {patientCount}
                        </button>
                    </div>
                    <div className="flex space-x-[52px] justify-between">
                        <div className=" flex items-center gap-2 border h-11 max-w-[328px] w-full bg-lightGray300 border-lightGray300 rounded-[28px]">
                            <img
                                src={search}
                                alt="search"
                                className="w-10 h-10"
                            />
                            <input
                                type="text"
                                placeholder="Search"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="bg-lightGray300 focus:outline-none outline-none focus:ring-0"
                            />
                        </div>
                        <div onClick={handleModal}>
                            <img
                                src={filter}
                                alt="filterIcon"
                                className="cursor-pointer h-10 w-10"
                            />
                        </div>
                    </div>
                </div>
                <Filter
                    isVisible={openModal}
                    onClose={closeModal}
                    onSave={onSave}
                    buttonEnabled={buttonEnabled}
                    setButton={setButton}
                    queryParam={queryParam}
                    setQueryParam={onFilter}
                />
                <TotalPatients filterOptions={queryParam} searchTerm={searchTerm} />
            </div>
        </div>
    );
};
export default Patients;
