
import usePagination from '../../../hooks/usePagination';
import Table from '../../../constants/Table';
import { useNavigate } from 'react-router-dom';
import TimeComponent, { formatDateDmy, standardAmountFormat } from '../../../utils/helpers';

import image from '../../../Images/EmptyAmbulance.svg';
import FleetEmptyState from './ReqEmptyState';
import profile from '../../../Images/profile.png';
import { useHospitalAuthStore } from '../../../store/hospital/auth';

interface rowType {
    _id: string;
}
const ReqData = ({ fleet, searchQuery }: any) => {
    const currency = useHospitalAuthStore((state) => state.hospital.currency);
    const navigate = useNavigate();
 
    const filteredfleets = fleet?.filter(
        (row: any) =>
            row?.patient?.firstName
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
            row?.patient?.lastName
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
            row?.ambulanceType?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row?.status?.toLowerCase().includes(searchQuery.toLowerCase()) 
    );

    //Pagination
    const { page, limit, Pagination } = usePagination({
        page: 1,
        limit: 10,
        total: fleet?.length,
    });

    const paginatedRows = filteredfleets?.slice(
        (page - 1) * limit,
        page * limit
    );
   
    const fleetTableHeaders = [
        {
            title: 'S/N',
            render: (row: any) => (
                <p>
                    {fleet?.findIndex((user: any) => user._id === row._id) + 1}
                </p>
            ),
        },
        {
            title: 'Patient Name',
            render: (row: any) => (
                <div className="flex items-center gap-x-[10px]" >
                    <img src={row.patient?.img || profile} alt="ambulance img" className='w-[40px] h-[40px] rounded-[200px]'/>
                        
                        <div style={{ width: '168px', whiteSpace: 'normal', wordWrap: 'break-word' }} className="truncate  font-medium text-lightBrown text-sm">
                        <p className="truncate w-32 font-medium text-lightBrown text-sm">
                            {`${row.patient?.firstName} ${row.patient?.lastName}`}
                        </p>
                        <p className="truncate w-32 font-medium text-lightBrown text-sm">
                            {`${row.patient?.tmrNumber
}`}
                        </p>
                    </div>
                </div>
            ),
        },
        {
            title: 'Address',
            render: (row: any) => (
                <div className="flex items-center gap-x-[10px]">
                    <div>
                        <p style={{ width: '200px', whiteSpace: 'normal', wordWrap: 'break-word' }} className="truncate  font-medium text-lightBrown text-sm">
                            {row?.location || ''}
                        </p>
                        
                    </div>
                </div>
            ),
        },
        
        {
            title: 'Types of Ambulance',
            render: (row: any) => (
                <p style={{ width: '168px', whiteSpace: 'normal', wordWrap: 'break-word' }} className="text-lightBrown100 text-xs font-medium">
                    {row.ambulanceType}
                </p>
            ),
        },
        {
            title: 'Amount',
            render: (row: any) => (
                <p className="text-lightBrown100 text-xs font-medium">
                {standardAmountFormat(row.amount,currency)}
                </p>
            ),
        },
        {
            title: 'Status',
            render: (row: any) => (
                <div
                className={`flex items-start justify-center p-2 rounded 
                    ${
                        row.status === 'pending'
                            ? 'bg-[#366CC926] text-lightBrown'
                            : ''
                    } 
                    ${
                        row.status === 'completed'
                            ? 'bg-[#366CC926] text-lightBrown'
                            : ''
                    }
                    ${
                        row.status === 'cancelled'
                            ? 'bg-[#FFDAD5] text-lightBrown'
                            : ''
                    }
                    ${
                        row.status === 'accepted'
                            ? 'bg-[#366CC926] text-lightBrown'
                            : ''
                    }
                `}
            >
                <div
                    className={`w-[6px] h-[6px] rounded-full mr-2 mt-[6px] 
                        ${row.status === 'pending' || 'accepted' ? 'bg-[#366CC9]' : ''}
                        ${row.status === 'completed' ? 'bg-[#3A975F]' : ''}
                        ${row.status === 'cancelled' ? 'bg-[#C00010]' : ''}
                    }`}
                />
                <p className="text-xs">{row.status}</p>
            </div>
            ),
        },
        {
            title: 'Request Date',
            render: (row: any) => (
                <p style={{ width: '121px', whiteSpace: 'normal', wordWrap: 'break-word' }} className="text-lightBrown100 text-xs font-medium">
                    {formatDateDmy(row.createdAt)} <br/>
                    <TimeComponent time={row.createdAt} />
                </p>
            ),
        },
    ];
    return (
        <div className="w-full pt-5">
            {!fleet?.length ? (
                <FleetEmptyState />
            ) : filteredfleets?.length === 0 ? ( // Check if filteredfleets is empty
                <>
                    {/* <Table headers={fleetTableHeaders} showHead={true} /> */}
                    <div className="flex flex-col items-center justify-center text-center my-[70px] ">
                        <img src={image} alt="" />
                        <p className="mt-6 text-base font-medium font-[Montserrat]">
                            The search result is currently empty.
                        </p>
                    </div>
                </>
            ) : (
                <div className="gap-[2rem] block md:grid">
                    <div className="w-full grid gap-x-[30px] self-start md:mb-[0] mb-[2rem] overflow-x-auto scrollbar-hide">
                        <Table
                            rows={paginatedRows}
                            headers={fleetTableHeaders}
                            showHead={true}
                            allowRowClick
                            onRowClick={(row: rowType) =>
                                navigate(
                                    `/hospital/ambulance/request/ambulance-profile/${row._id}`
                                )
                            }
                        />
                        <Pagination />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ReqData;
