import React, { useState, ReactNode } from 'react';


interface Tab {
    title: string;
    content: ReactNode;
}

interface TabsProps {
    tabs: Tab[];
}

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
    const [activeTab, setActiveTab] = useState(0);
    

    return (
        <>
            <div className="w-full ">
                <div className=" flex flex-col">
                    <div className="flex flex-col-reverse sm:flex-row justify-between w-full border-[#EDE0DE] border-b ">
                        <div className="flex">
                            {tabs.map((tab, index) => (
                                <div
                                    onClick={() => setActiveTab(index)}
                                    className={`flex items-center tab-small-screen  ${
                                        activeTab === index
                                            ? 'border-b-2  border-Tmred'
                                            : ''
                                    }`}
                                >
                                    <div className={` flex  items-center `}>
                                        <button
                                            key={index}
                                            className={`w-[120px] py-2 font-medium text-sm  ${
                                                index > 0 ? 'ml-2' : ''
                                            } ${
                                                activeTab === index
                                                    ? 'text-Tmred'
                                                    : 'text-[#534341]'
                                            } focus:outline-none`}
                                        >
                                            {tab.title}
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="">{tabs[activeTab].content}</div>
                </div>
            </div>
        </>
    );
};

export default Tabs;
