import React from 'react';
import DashboardLayout from '../Dashboard/DashboardLayout';
import Profile from '../../../components/Settings/MyProfile/Profile';
import {Helmet} from 'react-helmet'


const MyProfile = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Profile
                </title>
            </Helmet>
            <div className="">
                <Profile />
            </div>
        </DashboardLayout>
    );
};

export default MyProfile;
