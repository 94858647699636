/* eslint-disable @typescript-eslint/no-explicit-any */
// import Slider from 'rc-slider';
import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import DatePickerElement from '../../reusable/Forms/DatePickerElement';
// import DatePickerElement from '../../pages/Reuseables/DatePickerElement';
interface VoucherFilterProps {
  handleModal: () => void;
  onFilterApply: any;
  currentFilters: any;
  resetFilters: any;
}

const FilterAdsModal: React.FC<VoucherFilterProps> = ({
  handleModal,
  onFilterApply,
  currentFilters,
  resetFilters,
}) => {
  const [order, setOrder] = useState(currentFilters.order || '');
  const [status, setStatus] = useState(currentFilters.status || '');
  const [adsObjectives, setAdsObjectives] = useState(
    currentFilters.adsObjectives || ''
  );
  const [rangeValues, setRangeValues] = useState([
    currentFilters.minAmount || 0,
    currentFilters.maxAmount || 500,
  ]);
  const [localFromDate, setLocalFromDate] = useState<Dayjs | null>(
    currentFilters.from ? dayjs(currentFilters.from) : null
  );
  const [localToDate, setLocalToDate] = useState<Dayjs | null>(
    currentFilters.to ? dayjs(currentFilters.to) : null
  );
  useEffect(() => {
    setOrder(currentFilters.order || '');
    setStatus(currentFilters.status || '');
    setAdsObjectives(currentFilters.adsObjectives || '');
    setRangeValues([
      currentFilters.minAmount || 100,
      currentFilters.maxAmount || 50000,
    ]);
    setLocalFromDate(currentFilters.from ? dayjs(currentFilters.from) : null);
    setLocalToDate(currentFilters.to ? dayjs(currentFilters.to) : null);
  }, [currentFilters]);
  const handleRangeChange = (values: any) => {
    setRangeValues(values);
  };

  const trackStyle = { backgroundColor: '#C00010' };
  const handleStyle = {
    borderColor: '#C00010',
    backgroundColor: '#C00010',
  };
  const railStyle = { backgroundColor: '#EDE0DE' };
  const minAmount = rangeValues[0] || '';
  const maxAmount = rangeValues[1] || '';
  const handleApplyFilter = () => {
    onFilterApply({
      order,
      status,
      adsObjectives,
      minAmount,
      maxAmount,
      from: localFromDate ? localFromDate.format('YYYY-MM-DD') : '',
      to: localToDate ? localToDate.format('YYYY-MM-DD') : '',
    });
    handleModal();
  };
  const handleResetFilter = () => {
    resetFilters();
  };

  return (
    <div onClick={handleModal} className="modal-backgrounds fixed z-10 inset-0 flex justify-center items-center bg-[#2826265E] bg-opacity-20">
      <div  className="bg-white p-5 max-w-[450px] h-[70%]  rounded-[28px] overflow-y-scroll">
        <div>
          <h1 className="text-2xl font-medium">Filter by</h1>
          <div className="my-[20px]">
            <p className="text-base mb-3">Ads Objectives</p>
            <div className="flex items-center space-x-3">
              <button
                onClick={() => setAdsObjectives('visibility')}
                className={`border-[1px] border-gray-400 px-5 rounded-lg py-1 text-sm ${
                  adsObjectives === 'visibility' ? 'bg-gray-200' : ''
                }`}>
                Visibility
              </button>
              <button
                onClick={() => setAdsObjectives('conversion')}
                className={`border-[1px] border-gray-400 px-5 rounded-lg py-1 text-sm ${
                  adsObjectives === 'conversion' ? 'bg-gray-200' : ''
                }`}>
                Conversion
              </button>
              <button
                onClick={() => setAdsObjectives('profile visit')}
                className={`border-[1px] border-gray-400 px-5 rounded-lg py-1 text-sm ${
                  adsObjectives === 'profile visit' ? 'bg-gray-200' : ''
                }`}>
                Profile Visit
              </button>
            </div>
          </div>
         
          <div className="my-[25px]">
            <p className="font-normal text-base text-dark100 mb-3">Amount</p>
            <Slider
              range
              min={100}
              max={50000}
              value={rangeValues}
              onChange={handleRangeChange}
              trackStyle={[trackStyle]}
              handleStyle={[handleStyle, handleStyle]}
              railStyle={railStyle}
            />
            <div className="md:flex md:space-x-6 space-y-1 md:space-y-0 ml-4 mt-4">
              <button
                type="button"
                className="flex items-center justify-start text-base font-normal border 
                            border-dark100 py-[4px] rounded-[8px]  w-[191px] h-[56px]">
                <span className="text-dark100 text-base font-normal px-2">
                  {rangeValues[0].toLocaleString()}
                </span>
              </button>
              <button
                type="button"
                className="flex justify-start items-center text-base font-normal border
                             border-dark100 py-[4px] rounded-[8px]  w-[191px] h-[56px]">
                <span className="text-dark100 text-base font-normal px-2">
                  {rangeValues[1].toLocaleString()}
                </span>
              </button>
            </div>
          </div>
          <div className="mb-4">
            <p className=" text-base text-dark100 mb-3">Date</p>
            <div className="md:flex md:space-x-6 space-y-1 md:space-y-0 ml-4">
              <DatePickerElement
                label="From"
                value={localFromDate}
                onChange={(date: Dayjs | null) => setLocalFromDate(date)}
              />
              <DatePickerElement
                label="To"
                value={localToDate}
                onChange={(date: Dayjs | null) => setLocalToDate(date)}
              />
            </div>
          </div>
          <div>
            <p className="text-base text-dark100 mb-3">Order</p>
            <div className="ml-4">
              <label className="flex items-center space-x-2 cursor-pointer mb-2">
                <input
                  type="checkbox"
                  className="p-[2px] rounded-sm cursor-pointer"
                  checked={order === 'asc'}
                  onChange={() => setOrder('asc')}
                />
                <span className="text-base font-light">Ascending</span>
              </label>
              <label className="flex items-center space-x-2 cursor-pointer pt-2 ">
                <input
                  type="checkbox"
                  className="p-[2px] rounded-sm  cursor-pointer"
                  checked={order === 'desc'}
                  onChange={() => setOrder('desc')}
                />
                <span className="text-base font-light">Descending</span>
              </label>
            </div>
          </div>
          <div className="flex items-center justify-end space-x-6 mt-[30px]">
            <button onClick={handleResetFilter} className="text-sm">
              Reset Filter
            </button>
            <button className="text-sm" onClick={handleModal}>
              Close
            </button>
            <button
              onClick={handleApplyFilter}
              className="bg-Tmred text-white text-sm px-5 py-2 rounded-lg">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterAdsModal;
