interface CreateAdProgressProps {
    level: number;
}

const CreateAdProgress: React.FC<CreateAdProgressProps> = ({
  level,
}) => {
    const radius = 25;
    const circumference = 2 * Math.PI * radius;
    const offset = (level === 1) ? circumference - circumference/2 : 0;

    return (
        <>
            <svg width="60" height="60">
                <circle
                    style={{
                        fill: 'none',
                        stroke: '#e3e3e4'
                    }}
                    cx="30"
                    cy="30"
                    r={radius}
                    strokeWidth="4"
                />
                <circle
                    style={{
                        fill: 'none',
                        transform: 'rotate(90deg)',
                        transformOrigin: '50% 50%'
                    }}
                    cx="30"
                    cy="30"
                    r={radius}
                    strokeWidth="4"
                    stroke="#C00010"
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}
                />
                <text
                    x="30"
                    y="30"
                    dy=".3em"
                    textAnchor="middle"
                >
                    {`${level}/2`}
                </text>
            </svg>
        </>
    );
};

export default CreateAdProgress;
