import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import back from '../../Images/back.png';
import Input from '../../reusable/Forms/Input';
import { useMutation, useQuery } from 'react-query';
import hospitalService from '../../services/hospitalService';
import { standardAmountFormat } from '../../utils/helpers';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { useHospitalAuthStore } from '../../store/hospital/auth';
import { toast } from 'react-toastify';
import OTPModal from './OTPModal';
type DetailsProps = {
    bankName: string;
    bankCode: string;
    accountNumber: string;
    accountName: string;
};

const Withdraw = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [buttonEnabled, setButton] = useState(false);
    const [buttonOTPEnabled, setOTPButton] = useState(false);
    const [OTPLoading, setIsOTPLoading] = useState(false);
    const [bankDetails, setBankDetails] = useState<DetailsProps>();
    const [isBankDetails, setIsBankDetails] = useState(false);
    const hospitalAuthStore = useHospitalAuthStore((state) => state);
    const currency = useHospitalAuthStore((state) => state.hospital.currency);
    const [isloading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        Account_Name: bankDetails?.accountName || '',
        Account_Number: bankDetails?.accountNumber || '',
        Amount: '',
        Bank_name: bankDetails?.bankName || '',
    });
    const [walletType, setWalletType] = useState('');

    useEffect(() => {
        if (hospitalAuthStore.user.userType === 'superadmin') {
            setWalletType('hospital');
        } else {
            setWalletType('personal');
        }
    }, []);

    const { data, isLoading, isError } = useQuery(['balance', walletType], () =>
        hospitalService.getWalletBalance(walletType)
    );

    const walletBalance = data?.balance;

    useEffect(() => {
        if (
            hospitalAuthStore.hospital.bankDetails &&
            hospitalAuthStore.user.userType === 'superadmin'
        ) {
            setIsBankDetails(true);
            setBankDetails(hospitalAuthStore.hospital.bankDetails);
        } else if (
            hospitalAuthStore.user.bankDetails &&
            hospitalAuthStore.user.userType !== 'superadmin'
        ) {
            setIsBankDetails(true);
            setBankDetails(hospitalAuthStore.user.bankDetails);
        }
    }, []);
    const closeModal = () => {
        setShowModal(false);
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const otpmutation = useMutation((data: any) =>
        hospitalService.completeWithdrawMoney(data, walletType)
    );

    useEffect(() => {
        if (otpmutation.isSuccess) {
            setIsOTPLoading(false);
            console.log('success', otpmutation.data);
            const response = otpmutation.data;
            const message = response.data.message;
            console.log('Success:', message);
            toast.success('Transfer completed successfully');
            setTimeout(() => {
                toast.dismiss();
            }, 2000);
            setShowModal(false);
            setOTPButton(false);
        }
    }, [otpmutation.isSuccess, otpmutation.data]);

    useEffect(() => {
        if (otpmutation.isError) {
            setIsOTPLoading(false);
            let err: any = otpmutation.error;
            console.log('is error', err);

            toast.error(err.response?.data?.error?.message || 'error');
        } else {
            console.log('error');
        }
    }, [otpmutation.isError, otpmutation.error]);

    const mutation = useMutation((data: any) =>
        hospitalService.withdrawMoney(data, walletType)
    );

    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            console.log('success', mutation.data);
            toast.success('Transfer initiated successfully. Confirm your Otp');
            setTimeout(() => {
                toast.dismiss();
            }, 2000);
            setShowModal(true);
            setFormData({
                Account_Name: bankDetails?.accountName || '',
                Account_Number: bankDetails?.accountNumber || '',
                Amount: '',
                Bank_name: bankDetails?.bankName || '',
            });
            setButton(false);
        }
    }, [mutation.isSuccess, mutation.data]);

    useEffect(() => {
        if (mutation.isError) {
            setIsLoading(false);
            let err: any = mutation.error;
            toast(
                err.response?.data?.error?.message ||
                    'error initiating withdraw'
            );
            setTimeout(() => {
                toast.dismiss();
            }, 2000);
        } else {
            console.log('error');
        }
    }, [mutation.isError, mutation.error]);
    useEffect(() => {
        if (formData.Amount !== '') {
            setButton(true);
            console.log('withdrawal button enabled');
        } else {
            setButton(false);
        }
    }, [formData.Amount]);

    const handlesubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        console.log(formData);
        setButton(false);
        try {
            setIsLoading(true);
            const withdrawData = {
                amount: formData.Amount,
            };

            await mutation.mutateAsync(withdrawData);
            // console.log({paymentData})
        } catch (error) {
            console.error('Error withdrawing:', error);
        } finally {
            setIsLoading(false);
        }
    };
    const handleOTPSubmit = async (otp: string) => {
        console.log({ otp });
        setButton(false);
        setOTPButton(false);
        try {
            setIsOTPLoading(true);
            const withdrawData = {
                otp: otp,
            };

            await otpmutation.mutateAsync(withdrawData);
        } catch (error) {
            console.error('Error withdrawing:', error);
        } finally {
            setIsOTPLoading(false);
        }
    };

    const goBack = () => {
        navigate(-1);
    };
    if (isError) {
        return <div>Error loading balance, please refresh your page</div>;
    }
    return (
        <Fragment>
            <div className="w-full flex flex-col md:px-8 py-4 px-2 ">
                <div className="flex gap-2 items-center ">
                    <button onClick={goBack}>
                        <img src={back} alt="" />
                    </button>
                    <h3 className="font-bold sm:text-[28px] text-[20px]">
                        Withdraw Money
                    </h3>
                </div>
                <div className="max-w-[60%] w-full p-8 rounded-2xl bg-[#FFFFFF] mb-5">
                    <div className="flex flex-col md:flex-row items-center justify-between">
                        <div>
                            <p className=" font-normal text-[#000000] text-sm ">
                                Available Balance
                            </p>
                            <p className="text-[#000] text-[20px] font-semibold">
                                <span>
                                    {isLoading
                                        ? '0'
                                        : standardAmountFormat(walletBalance,currency)}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div>
                        <form
                            action=""
                            onSubmit={handlesubmit}
                            className="flex flex-col gap-3 sm:gap-8"
                        >
                            <div className=""></div>
                            <Input
                                name="Amount"
                                label="Enter Amount"
                                value={formData.Amount}
                                onChange={handleChange}
                                type="number"
                            />

                            <Input
                                name="Account_Number"
                                label="Account Number"
                                value={
                                    bankDetails
                                        ? bankDetails.accountNumber
                                        : formData.Account_Number
                                }
                                onChange={handleChange}
                                disabled={!!bankDetails}
                                className="w-full border border-[#857371] p-4 rounded-[8px] text-[16px] tracking-wider my-4 mt-10"
                            />
                            <Input
                                name="Account_Name"
                                label="Account Name"
                                value={
                                    bankDetails
                                        ? bankDetails.accountName
                                        : formData.Account_Name
                                }
                                onChange={handleChange}
                                disabled={!!bankDetails}
                                className="w-full border border-[#857371] p-4 rounded-[8px] text-[16px] tracking-wider my-4 mt-10"
                            />
                            <Input
                                name="Bank_name"
                                label="Bank Name"
                                value={
                                    bankDetails
                                        ? bankDetails.bankName
                                        : formData.Bank_name
                                }
                                onChange={handleChange}
                                className="w-full border border-[#857371] p-4 rounded-[8px] text-[16px] tracking-wider my-4 mt-10"
                                disabled={!!bankDetails}
                            />

                            <div className="flex gap-6 mt-3 w-fit bg-[#F5F5F5] px-4 py-2 rounded-[8px] items-center">
                                <div>
                                    <AiOutlineExclamationCircle className="text-[20px]" />
                                </div>
                                <p className="text-[12px] max-w-[406px] font-normal text-[#201A19]">
                                    For assistance with updating your bank
                                    details, chat with our TMR Support.
                                </p>
                            </div>

                            <div className="py-8">
                                <button
                                    type="submit"
                                    className={`w-full h-[40px] flex justify-center rounded-lg items-center text-[#9d9b9b] font-semibold  hover:cursor-not-allowed transition-all duration-500  ${
                                        buttonEnabled
                                            ? 'bg-Tmred text-white hover:cursor-pointer '
                                            : 'bg-[#1D1B201F] '
                                    } `}
                                >
                                    {isloading
                                        ? 'Withdrawing...'
                                        : 'Withdraw Money'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                {showModal && (
                    <OTPModal
                        isLoading={OTPLoading}
                        setButton={setOTPButton}
                        buttonEnabled={buttonOTPEnabled}
                        closeModal={closeModal}
                        handleSubmit={handleOTPSubmit}
                    />
                )}
            </div>
        </Fragment>
    );
};

export default Withdraw;
