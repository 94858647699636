import ActiveState from './ActiveStates';

const AllNotification = ({ onClose, isVisible }) => {
    // useEffect(() => {
    //   document.body.style.overflow = 'hidden';
    //   return () => {
    //     document.body.style.overflow = 'auto';
    //   };
    // }, []);
    if (!isVisible) return null;
    const handleClose = (e) => {
        if (e.currentTarget.id === 'wrapper') {
            onClose();
        }
    };
    const handleInnerClick = (e) => {
        // Prevent the event from propagating to the parent (wrapper)
        e.stopPropagation();
    };

    return (
        <div
            id="wrapper"
            onClick={handleClose}
            className="fixed inset-0 flex justify-center items-center bg-[#2826265E] bg-opacity-75 z-50 "
        >
            <div className="w-full flex justify-center lg:justify-end  lg:pr-16 pt-20 ">
                <div
                    onClick={handleInnerClick}
                    className="bg-white w-full max-w-[90vw] md:max-w-[73vw] h-[80vh] overflow-y-auto  scrollbar-hide pt-6 px-1 md:px-6 rounded-2xl"
                >                   

                    <ActiveState />
                </div>
            </div>
        </div>
    );
};

export default AllNotification;
