import React from 'react';
import DashboardLayout from '../Dashboard/DashboardLayout';

import { Helmet } from 'react-helmet'
import Orders from '../../../components/PharmacyComponent/Order/Orders';

const Order = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                   TMR | Orders
                </title>
            </Helmet>
            <div className="">
                <Orders/>
            </div>
        </DashboardLayout>
    );
};

export default Order;
