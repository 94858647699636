import React from 'react';
import DashboardLayout from '../Dashboard/DashboardLayout';
import { Helmet } from 'react-helmet';
import VoucherHistories from '../../../components/Voucher/VoucherHistories';

const VoucherHistory = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>TMR | Voucher History</title>
            </Helmet>
            <div className="">
                <VoucherHistories />
            </div>
        </DashboardLayout>
    );
};

export default VoucherHistory;
