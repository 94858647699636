import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import Modal from './Modal';
import TimePickerElement from './Forms/TimePickerElement';

interface FilterModalProps {
    isVisible: boolean;
    onClose: () => void;
    onSave: (queryParams: string) => void;
    buttonEnabled: boolean;
    setButton: (enabled: boolean) => void;
}

const FilterModal: React.FC<FilterModalProps> = ({
    isVisible,
    onClose,
    onSave,
    buttonEnabled,
    setButton,
}) => {
    const [selectedModeButton, setSelectedButton] = useState<string | null>(null);
    const [selectedFromTime, setSelectedFromTime] = useState<string>('');
    const [selectedToTime, setSelectedToTime] = useState<string>('');
    const [selectedOrder, setSelectedOrder] = useState<string | null>(null);
    const [queryParam, setQueryParam] = useState<string>('');

    useEffect(() => {
        const queryParamsArr: string[] = [];

        if (selectedFromTime && selectedToTime) {
            queryParamsArr.push(`from=${selectedFromTime}`);
            queryParamsArr.push(`to=${selectedToTime}`);
        }
        if (selectedModeButton) {
            queryParamsArr.push(`mode=${selectedModeButton}`);
        }
        if (selectedOrder) {
            queryParamsArr.push(`order=${selectedOrder}`);
        }

        const queryParams = '&' + queryParamsArr.join('&');
        setQueryParam(queryParams);
    }, [selectedOrder, selectedFromTime, selectedToTime, selectedModeButton]);

    const resetQueryParam = () => {
        setSelectedButton('');
        setSelectedFromTime('');
        setSelectedToTime('');
        setSelectedOrder('');
        setQueryParam('');
    }

    const handleModeButtonClick = (value: string) => {
        setSelectedButton(value);
    };

    const handleOptionChange = (option: string) => {
        setSelectedOrder(option);
    };

    const handleSave = (e: React.FormEvent) => {
        e.preventDefault();
        setButton(false);
        onSave(queryParam);
    };

    return (
        <Modal
            isVisible={isVisible}
            onClose={onClose}
            onSave={handleSave}
            setButton={setButton}
            buttonEnabled={!buttonEnabled}
            saveText="Save"
            closeText="Close"
            showCloseButtonBorder={false}
            clearAll={true}
            onClearAll={resetQueryParam}
        >
            <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2 ">
                <h2 className="font-semibold text-2xl mb-5">
                    Filter By
                </h2>
                
                <div className="my-9">
                    <h2 className="font-medium text-base text-dark100 mb-3">
                        Mode
                    </h2>
                    <div className="md:flex items-center sm:space-x-3 space-y-1 md:space-y-0 ml-4 ">
                        {['home-visit', 'telemedicine', 'walk-in'].map((mode) => (
                            <button
                                key={mode}
                                type="button"
                                className={`px-4 border-[1px] text-lightBrown border-lightBrown100 font-medium text-sm py-[6px] rounded-lg ${
                                    selectedModeButton === mode ? 'bg-[#1D1B2014]' : ''
                                }`}
                                onClick={() => handleModeButtonClick(mode)}
                            >
                                {mode.charAt(0).toUpperCase() + mode.slice(1).replace('-', ' ')}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="mb-5">
                    <h2 className="font-medium text-base text-dark100 mb-3">
                        Time
                    </h2>
                    <div className="md:flex md:space-x-6 space-y-1 md:space-y-0 ml-4 sm:max-w-[362px]">
                        <TimePickerElement
                            label="From"
                            value={selectedFromTime ? dayjs(selectedFromTime, 'hh:mm a') : null}
                            onChange={(time) => setSelectedFromTime(time ? time.format('hh:mm a') : '')}
                        />
                        <TimePickerElement
                            label="To"
                            value={selectedToTime ? dayjs(selectedToTime, 'hh:mm a') : null}
                            onChange={(time) => setSelectedToTime(time ? time.format('hh:mm a') : '')}
                        />
                    </div>
                </div>
                <div className="">
                    <h2 className="font-medium text-base text-dark100 mb-3">
                        Order
                    </h2>
                    <div className="ml-4">
                        {['asc', 'desc'].map((order) => (
                            <label key={order} className="flex items-center space-x-2 cursor-pointer mb-2">
                                <input
                                    type="checkbox"
                                    className="p-[2px] rounded-sm cursor-pointer"
                                    checked={selectedOrder === order}
                                    onChange={() => handleOptionChange(order)}
                                />
                                <span className="text-base font-normal">
                                    {order.charAt(0).toUpperCase() + order.slice(1)}
                                </span>
                            </label>
                        ))}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default FilterModal;
