
import React from 'react';
import search from '../../Images/Leading-icon.png';
import Modal from '../../reusable/Modal';

const DocModal = ({
    showModal,
    onClose,
    onSave,
    buttonEnabled,
    setButton3,
    setFormData3,
    formData3,
    handleDoctorSearchChange,
    filteredDoctors,
}) => {
    return (
        <Modal
            isVisible={showModal}
            onClose={onClose}
            onSave={() => onSave(formData3.selectedDoctor?._id)}
            buttonEnabled={buttonEnabled}
            setButton={setButton3}
            saveText="Add doctor"
            closeText="Cancel"
            showCloseButtonBorder={true}
        >
            <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2 ">
                <h2 className="font-semibold text-2xl mb-5">
                Select Doctor
                </h2>
                <div className="bg-[#1D1B2008] lg:h-full lg:max-w-[482px]  rounded-2xl flex flex-col sm:flex-row gap-6 items-center">
                   
                    <div className="py-4 px-2  lg:min-w-[343px] bg-[#fff] rounded-2xl flex items-center flex-col">
                        <div className="mb-4 flex items-center gap-2 rounded w-full">
                            <img src={search} alt="search" />
                            <input
                                type="text"
                                placeholder="Search doctor"
                                value={formData3.doctorSearch}
                                onChange={handleDoctorSearchChange}
                                className="w-full font-normal text-[16px] bg-transparent text-[#534341] border-transparent outline-none focus:ring-0"
                            />
                        </div>
                        <div className="h-[240px] w-full overflow-y-auto">
                            {filteredDoctors.length === 0 ? (
                                <p className="text-center text-gray-500 py-2">
                                    Search does not match
                                </p>
                            ) :(filteredDoctors.map((row, index) => (
                                <div
                                    key={index}
                                    className={`flex p-2 items-center gap-8 cursor-pointer ${
                                        formData3.selectedDoctor &&
                                        formData3.selectedDoctor._id ===
                                            row._id
                                            ? 'bg-[#EDE0DE] rounded-tl-2xl rounded-bl-2xl'
                                            : 'hover:bg-[#EDE0DE] hover:rounded-tl-2xl hover:rounded-bl-2xl'
                                    } justify-between mb-4 font-normal text-[12px] text-[#201A19] border-b-[2px]`}
                                    onClick={() => {
                                        setFormData3((prevData: any) => ({
                                            ...prevData,
                                            selectedDoctor:
                                                prevData.selectedDoctor &&
                                                prevData.selectedDoctor
                                                    ._id ===
                                                    row._id
                                                    ? null
                                                    : row,
                                        }));
                                        setButton3(true);
                                    }}
                                    style={{ lineHeight: '24px' }}
                                >
                                    <div className="flex flex-col">
                                        <div className="flex items-center gap-x-[10px]">
                                            <img
                                                className="object-contain h-[40px] w-[40px] rounded-full"
                                                style={{
                                                    boxShadow:
                                                        'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
                                                }}
                                                src={row.image}
                                                alt=""
                                            />{' '}
                                            <div>
                                                <p className="truncate w-[226px] font-normal text-deep_black text-sm ">
                                                {row.title.split('-')[0]} {row.firstName} {row.lastName}
                                                </p>
                                                <div className='flex w-full items-center justify-between '>
                                                <p className="text-light_gray text-[12px] font-medium">
                                                {row.specialties.length > 0 ? row.specialties[0].name : ''}
                                                </p>
                                                {row.specialties.length > 1 && (
                                                <div className="flex items-center py-2">
                                                <p className="text-xs font-medium text-lightBrown100 bg-lightGray rounded-xl px-3 py-1">
                                                    +{row.specialties.length - 1}
                                                </p>
                                                </div>
                                                )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            )))}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default DocModal;
