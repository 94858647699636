import React from 'react';
import { useState, useEffect } from 'react';
import profile from '../../../../Images/profile.png';
import uploadedPrdt from '../../../../Images/PharmacyImages/upload.svg';
// import { useNavigate } from 'react-router-dom';
import Input from '../../../../reusable/Forms/Input';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import {
    Select,
    MenuItem,
    SelectChangeEvent,
    InputLabel,
    FormControl,
    Tooltip,
    InputAdornment,
    TextField,
} from '@mui/material';
import { useMutation, useQuery } from 'react-query';
// import hospitalService from '../../services/hospitalService';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { pharmacyRoutes } from '../../../../utils/pharmacyRouteNames';
import { InfoCircle } from 'iconsax-react';
import drug1 from './../../../../Images/PharmacyImages/drug2.svg';
import drug2 from './../../../../Images/PharmacyImages/drug2.svg';
import drug3 from './../../../../Images/PharmacyImages/drug2.svg';
import { pharmacyService } from '../../../../services/pharmacyService';
import { usePharmacyAuthStore } from '../../../../store/pharmacy/auth';

function EditInventryDetailsForm() {
    const { inventoryId } = useParams();
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const navigate = useNavigate();
    const [loadin, setIsLoadin] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [drugId, setDrugId] = useState<string>('');
    const userStore = usePharmacyAuthStore((state) => state.pharmacy);
    //  const [drugDetails, setDrugDetails] = useState<any>();
    const [dosage, setDosage] = useState<string>('');
    const {
        formState: { isDirty, isValid },
        handleSubmit,
        control,
        reset,
        setValue,
    } = useForm<any>();

    const mutation = useMutation((data: any) =>
        pharmacyService.updateInventory(inventoryId, data)
    );

    const onSubmitForm: SubmitHandler<any> = (data) => {
        console.log('submit form', data);
        // setIsLoadin(true);
        // const inventoryData = new FormData();
        // inventoryData.append('drugId', inventoryId);
        // inventoryData.append('price', data.price);
        // inventoryData.append('quantity', data.quantity);
        // inventoryData.append('pharmacyId', userStore?._id);

        const inventoryData: any = {
            drugId: drugId,
            quantity: data.quantity,
            price: data.price,
            pharmacyId: userStore?._id,
        };
        mutation.mutate(inventoryData);
    };
    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoadin(false);
            console.log('success', mutation.data);
            const response = mutation.data;
            const message = response.data.message;
            console.log('Success:', message);
            toast.success(message);
            setTimeout(() => {
                toast.dismiss(); // Dismiss the toast after 3000 milliseconds
                navigate(pharmacyRoutes.inventory);
            }, 2000);
        }
    }, [mutation.isSuccess, mutation.data, reset]);

    useEffect(() => {
        if (mutation.isError) {
            setIsLoadin(false);
            let err: any = mutation.error;
            console.log('is error', err);
            setErrorMessage(
                err.response?.data?.error?.message || 'Something went wrong'
            );
            toast.error(err.response?.data?.error?.message);
            reset({});
        } else {
            setErrorMessage('');
        }
    }, [mutation.isError, mutation.error]);
    const handleClearAll = () => {
        reset({
            drugName: '',
            price: '',
            quantity: '',
        });
        setSelectedImage(null);
    };

    const { data, isLoading, isError } = useQuery(
        ['drug-details', inventoryId],
        () => pharmacyService.getSingleInventory(inventoryId)
    );
    const drugDetails = data?.drugInventory;

    useEffect(() => {
        setDrugId(drugDetails?.drug?._id);
        setValue('drugName', drugDetails?.drugName);
    }, [data]);
    const handlePreview = (previews) => {
        // const previewData: any = {
        //     images: drugDetails?.drug?.images,
        //     drugName: drugDetails?.drugName,
        //     category: drugDetails?.drug?.category?.name,
        //     quantity: previews?.quantity,
        //     price: previews?.price,
        //     description: drugDetails?.drug?.description,
        //     sideEffect: drugDetails?.drug?.sideEffects,
        //     dosage: drugDetails?.dosageForm,
        //     soldCount: drugDetails?.soldCount,
        //     doctorsReport:
        //         drugDetails?.doctorReportRequired === true ? 'Yes' : 'No',
        // };
        // sessionStorage.setItem('preview', JSON.stringify(previewData));
        navigate(`${pharmacyRoutes.previewInventory}/edit`);
    };
    const styles = {
        // backgroundColor: '#362F2E',
        fontSize: '12px',
        color: '#FBEEEC',
        padding: '2px',
    };
    console.log(drugDetails);

    const selectedImages = drugDetails?.drug?.images;
    return (
        <div className="flex flex-col md:flex-row justify-between md:space-x-6 mt-10 md:mt-4">
            <ToastContainer />
            <div className="flex justify-center flex-col items-center mt-10 md:mt-0 bg-white p-4 sm:h-[653px] rounded-[20px] lg:w-[358px]">
                <h2 className="text-[22px] font-semibold text-lightBrown bg-lightPink p-4 rounded-t-[20px]">
                    Product Picture(s)
                </h2>

                <div className="overflow-y-auto grid grid-cols-2 w-full  mt-5 gap-2 sm:h-[514px] bg-[#F6F6F7]">
                    {selectedImages?.length > 0 &&
                        selectedImages?.map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt={`Selected ${index}`}
                                className="w-full h-full object-contain rounded-lg bg-white"
                            />
                        ))}
                </div>
            </div>
            <div className="flex-1 bg-white p-4 rounded-2xl mt-10 md:mt-0 ">
                <h2 className="text-[22px] font-semibold text-lightBrown text-center p-4 bg-lightPink">
                    Product Details{' '}
                </h2>
                <form onSubmit={handleSubmit(onSubmitForm)} noValidate>
                    <div className="flex flex-row mt-10">
                        <Controller
                            name="drugName"
                            defaultValue=""
                            disabled
                            rules={{
                                required: 'Drug Name is required',
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input label="Drug Name" {...field} />
                            )}
                        />
                    </div>
                    <div className="w-full py-2 mt-5">
                        <Controller
                            name="doctorsReport"
                            defaultValue={
                                drugDetails?.doctorReportRequired ? 'Yes' : 'No'
                            }
                            control={control}
                            disabled
                            rules={{
                                required: "Doctor's Report is required",
                            }}
                            render={({ field }) => (
                                <FormControl
                                    fullWidth
                                    sx={{ minWidth: 120 }}
                                    size="small"
                                >
                                    <InputLabel
                                        id="demo-select-small-label"
                                        htmlFor={'doctorsReport'}
                                    >
                                        {'Doctors Report'}
                                    </InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        label="Doctors Report (optional)"
                                        defaultValue={
                                            drugDetails?.doctorReportRequired
                                                ? 'Yes'
                                                : 'No'
                                        }
                                        {...field}
                                        className="w-full p-2"
                                    >
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        />
                    </div>
                    <div className="flex items-center my-6 space-x-6">
                        <Controller
                            name="dosage"
                            defaultValue={drugDetails?.dosageForm}
                            control={control}
                            disabled
                            render={({ field }) => (
                                <FormControl
                                    fullWidth
                                    sx={{ minWidth: 120 }}
                                    size="small"
                                >
                                    <InputLabel
                                        id="demo-select-small-label"
                                        htmlFor={'dosage'}
                                    >
                                        {'Dosage'}
                                    </InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        label="Dosage"
                                        defaultValue={drugDetails?.dosageForm}
                                        {...field}
                                        className="w-full p-2"
                                    >
                                        <MenuItem
                                            value={drugDetails?.dosageForm}
                                        >
                                            {drugDetails?.dosageForm}
                                        </MenuItem>
                                        {/* <MenuItem value="Liquid">
                                            Liquid
                                        </MenuItem>
                                        <MenuItem value="Capsule">
                                            Capsule
                                        </MenuItem>
                                        <MenuItem value={'Tablet'}>
                                            Tablet
                                        </MenuItem> */}
                                    </Select>
                                </FormControl>
                            )}
                        />

                        <Controller
                            name="category"
                            control={control}
                            defaultValue={drugDetails?.drug?.category?.name}
                            disabled
                            render={({ field }) => (
                                <FormControl
                                    fullWidth
                                    sx={{ minWidth: 120 }}
                                    size="small"
                                >
                                    <InputLabel
                                        id="demo-select-small-label"
                                        htmlFor={'category'}
                                    >
                                        {'Category'}
                                    </InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        defaultValue={
                                            drugDetails?.drug?.category?.name
                                        }
                                        label="Category"
                                        {...field}
                                        className="w-full p-2"
                                    >
                                        <MenuItem
                                            value={
                                                drugDetails?.drug?.category
                                                    ?.name
                                            }
                                        >
                                            {drugDetails?.drug?.category?.name}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        />
                    </div>
                    <div className="flex items-center flex-col sm:flex-row my-6 gap-6">
                        <Controller
                            name="price"
                            defaultValue={drugDetails?.price}
                            rules={{
                                required: 'Price is required',
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input label="Price (₦)" {...field} />
                            )}
                        />
                        <Controller
                            name="quantity"
                            defaultValue={drugDetails?.quantity}
                            rules={{
                                required: 'Quantity is required',
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input label="Quantity " {...field} />
                            )}
                        />
                    </div>
                    <Controller
                        name="sideEffect"
                        control={control}
                        disabled
                        defaultValue={drugDetails?.drug?.sideEffects}
                        rules={{
                            required: 'side effect information  is required',
                        }}
                        render={({ field }) => (
                            <textarea
                                placeholder="Side Effects"
                                rows={6}
                                required={true}
                                disabled
                                className="w-full h-[100px] my-6 border-[1px] border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider focus:border-[1px] focus:outline-none focus:border-dark100"
                                {...field}
                            ></textarea>
                        )}
                    />
                    <Controller
                        name="description"
                        control={control}
                        disabled
                        defaultValue={drugDetails?.drug?.description}
                        rules={{
                            required: 'Description information  is required',
                        }}
                        render={({ field }) => (
                            <textarea
                                placeholder="Description"
                                rows={6}
                                required={true}
                                disabled
                                className="w-full h-[100px] mb-6 border-[1px] border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider focus:border-[1px] focus:outline-none focus:border-dark100"
                                {...field}
                            ></textarea>
                        )}
                    />

                    <div className="flex justify-end space-x-6 py-3 border-t mt-10">
                        <button
                            type="reset"
                            className="py-2 px-3 text-Tmred font-normal text-base"
                            onClick={handleClearAll}
                        >
                            Clear All
                        </button>
                        <button
                            disabled={!isDirty || !isValid}
                            className={`py-2 px-3 text-Tmred  text-base border rounded-lg   border-[#857371] ${
                                !isDirty || !isValid
                                    ? 'cursor-not-allowed font-normal'
                                    : ' hover:font-semibold hover:cursor-pointer'
                            }`}
                            // onClick={handlePreview}
                        >
                            Preview
                        </button>
                        <button
                            disabled={!isDirty || !isValid}
                            type="submit"
                            // onClick={handleSubmit(onSubmitForm)}
                            className={` px-6 py-2 text-lightBrown text-base font-normal rounded-lg 
                     ${
                         !isDirty || !isValid || mutation.isLoading
                             ? 'bg-lightGray cursor-not-allowed'
                             : ' bg-Tmred text-white hover:cursor-pointer'
                     }
                    `}
                        >
                            {mutation.isLoading ? 'Saving...' : 'Save Product'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default EditInventryDetailsForm;
