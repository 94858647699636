import { useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { formatDateDmy } from '../../utils/helpers';
import Modal from '../../reusable/Modal';
import email from '../../Images/sms.svg';
import call from '../../Images/call.svg';
import location from '../../Images/location.svg';
import rectangle from '../../Images/Rectangle.png';
import tmr from '../../Images/logo.png';
import { toast } from 'react-toastify';

const BillReceipt = ({ billInvoice }) => {
    const [showReceipt, setShowReceipt] = useState<boolean>(false);
    const componentRef = useRef<HTMLDivElement>(null);
    const [hospitalBase64String, setHospitalBase64String] = useState(billInvoice?.hospital.logo || '');
    const [patientBase64String, setPatientBase64String] = useState(billInvoice?.patient.image || '');

    const calculateServiceTotal = (
        quantity: number,
        amount: number
    ): number => {
        return quantity * amount;
    };
    
    useEffect(() => {
        const fetchAndConvertImage = async () => {
            if (billInvoice?.hospital && billInvoice?.patient) {
                const hospitalImage = billInvoice.hospital.logo;
                const patientImage = billInvoice?.patient.image
        
                try {
                    const [hospitalBlob, patientBlob] = await Promise.all([
                        fetch(hospitalImage).then((res) => res.blob()),
                        fetch(patientImage).then((res) => res.blob()),
                    ]);
            
                    const [hospitalBase64, patientBase64] = await Promise.all([
                        convertImageToBase64(hospitalBlob),
                        convertImageToBase64(patientBlob),
                    ]);

                    setHospitalBase64String(hospitalBase64);
                    setPatientBase64String(patientBase64);
                } catch (error) {
                    console.error('Error converting image:', error);
                }
            }
        };
    
        fetchAndConvertImage();
    }, [billInvoice]);
    
    const convertImageToBase64 = (blob: Blob): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result as string);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsDataURL(blob);
        });
    };

    const downloadPdf = () => {
        if (!componentRef.current) {
            console.error('Ref not available');
            toast.error('Unable to generate receipt, please try again later');
            return;
        }

        const input = componentRef.current;
        // const canvas = html2canvas(input);
        html2canvas(input, { scale: 2 }).then((canvas) => {
            const pdf = new jsPDF({
                unit: 'mm',
                format: 'a4',
                orientation: 'portrait',
            });
            const width = pdf.internal.pageSize.getWidth();
            const height = (canvas.height * width) / canvas.width;
            pdf.addImage(
                canvas.toDataURL('image/jpeg'),
                'JPEG',
                0,
                0,
                width,
                height
            );
            pdf.save(`bill-${billInvoice?.billId}.pdf`);
            setShowReceipt(false);
        });
    };

    return (
        <>
            <button
                onClick={() => setShowReceipt(true)}
                className="bg-Tmred text-sm rounded-lg text-white px-[10px] md:px-[24px] py-[10px]"
            >
                View Receipt
            </button>
            <Modal
                isVisible={showReceipt}
                onClose={() => setShowReceipt(false)}
                onSave={downloadPdf}
                buttonEnabled={true}
                setButton={true}
                saveText="Download"
                closeText="Close"
                showCloseButtonBorder={true}
            >
                <div
                    ref={componentRef}
                    id="canvas"
                    style={{
                        fontSize: 'textnode-font-size',
                        lineHeight: 'textnode-line-height',
                    }}
                    className="w-full lg:w-[646px] h-[900px] flex flex-col p-6 gap-6 rounded-[22px] bg-white font-serif"
                >
                    <div className="flex justify-between">
                        <div className="w-[25%]">
                            <h3 className="text-[16px] mb-6 font-semibold">
                                Bill Invoice
                            </h3>
                            <div className="mb-4 gap-2">
                                <p className="text-[10px] mb-[4px] text-[#857371]">
                                    Bill id:
                                </p>
                                <p className="text-[12px] font-medium">
                                    #{billInvoice?.billId}
                                </p>
                            </div>

                            <div className="gap-2">
                                <p className="text-[10px] mb-[4px] text-[#857371]">
                                    Date Issued:
                                </p>
                                <p className="text-[12px] font-medium">
                                    {formatDateDmy(billInvoice?.createdAt)}
                                </p>
                            </div>
                        </div>
                        <div className="w-[32%] p-2 h-[9rem] bg-[#FAFAFB] rounded-[1rem]">
                            <div className="flex items-center gap-2">
                                <img
                                    className="rounded-[50%] h-[2rem] w-[2rem]"
                                    alt="logo"
                                    src={hospitalBase64String}
                                />
                                <p className="text-[0.85rem] font-[500]">
                                    {billInvoice?.hospital.name}
                                </p>
                            </div>
                            <div className="flex flex-col gap-2 text-[#201A19] p-2">
                                <a
                                    className="text-[0.625rem] flex items-center gap-1"
                                    href={`mailto:${billInvoice?.hospital.email}`}
                                >
                                    <img src={email} alt="email" />
                                    {billInvoice?.hospital.email}
                                </a>
                                <a
                                    className="text-[0.625rem] flex items-center gap-1"
                                    href={`tel:+${billInvoice?.hospital.phoneNumber}`}
                                >
                                    <img src={call} alt="call" />
                                    {billInvoice?.hospital.phoneNumber}
                                </a>
                                <p className="text-[0.625rem] flex items-center gap-1">
                                    <img src={location} alt="location" />
                                    {billInvoice?.hospital.address}
                                </p>
                            </div>
                        </div>
                        <div className="w-[32%] h-[9rem] p-2 bg-[#FAFAFB] rounded-[1rem]">
                            <div className="flex items-center gap-2">
                                <img
                                    className="rounded-[50%] h-[2rem] w-[2rem]"
                                    alt="patient dp"
                                    src={patientBase64String}
                                />
                                <p className="text-[0.85rem] font-[500]">
                                    {billInvoice?.patient.firstName}{' '}
                                    {billInvoice?.patient.lastName}
                                </p>
                            </div>
                            <div className="flex flex-col gap-2 text-[#201A19] p-2">
                                <a
                                    className="text-[0.625rem] flex items-center gap-1"
                                    href={`mailto:${billInvoice?.patient.email}`}
                                >
                                    <img src={email} alt="email" />
                                    {billInvoice?.patient.email}
                                </a>
                                <a
                                    className="text-[0.625rem] flex items-center gap-1"
                                    href={`tel:+${billInvoice?.patient.phoneNumber}`}
                                >
                                    <img src={call} alt="call" />
                                    {billInvoice?.patient.phoneNumber}
                                </a>
                                <p className="text-[0.625rem] flex items-center gap-1">
                                    <img src={location} alt="location" />
                                    {billInvoice?.patient.address}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="w-[100%] flex-grow">
                        <table className="w-full py-2 px-4">
                            <thead className="bg-[#FAFAFB]">
                                <tr>
                                    <th className="py-2  px-4 text-center">
                                        <p className="text-[10px] text-left text-[#534341]">
                                            Services
                                        </p>
                                    </th>
                                    <th className="text-center">
                                        <p className="text-[10px] text-[#534341]">
                                            Qty
                                        </p>
                                    </th>
                                    <th className="text-center">
                                        <p className="text-[10px] text-[#534341]">
                                            Price
                                        </p>
                                    </th>
                                    <th className="text-center">
                                        <p className="text-[10px] text-[#534341]">
                                            Total ({billInvoice?.currency})
                                        </p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {billInvoice?.services?.map((service) => (
                                    <tr key={service._id}>
                                        <td className="py-2 px-4 ">
                                            <p className="text-[12px]">
                                                {service.name}
                                            </p>
                                        </td>
                                        <td className="py-2  px-4 text-center">
                                            <p className="text-[12px]">
                                                {service.quantity}
                                            </p>
                                        </td>
                                        <td className="py-2 px-4 text-center">
                                            <p className="text-[12px] text-center">
                                                {service.amount.toLocaleString(
                                                    'en-NG',
                                                    {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                            </p>
                                        </td>
                                        <td className="py-2 px-4 text-center">
                                            <p className="text-[12px]">
                                                {calculateServiceTotal(
                                                    service.quantity,
                                                    service.amount
                                                ).toLocaleString('en-NG', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </p>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="flex my-[4px] items-center">
                        <div>
                            <img src={rectangle} alt="" />
                        </div>

                        <button className="rounded-[12px] text-[#857371] bg-[#FAFAFB] h-[40px] w-[238.34px] flex items-center justify-between px-[20px]">
                            <p className="text-[8px] md:text-[11px]">
                                Total ({billInvoice?.currency})
                            </p>
                            <p className="text-[12px] md:text-[16px] font-medium">
                                {billInvoice?.totalAmount.toLocaleString('en-NG', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                            </p>
                        </button>
                    </div>
                    <div className="bg-lightPink flex rounded-lg py-2 mt-[4px] justify-center">
                        <div className="flex items-center justify-center gap-[12px]">
                            <p className="text-[11px]">Produced by TMR</p>
                            <img className="w-[24px]" src={tmr} alt="" />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default BillReceipt;
