import React from 'react'
import DashboardLayout from '../../Dashboard/DashboardLayout'
import { Helmet } from 'react-helmet'
import Voucher from '../../../../components/PharmacyComponent/Voucher/Voucher'


const PharmacyVoucher = () => {
  return (
    <DashboardLayout>
            <Helmet>
                <title>TMR | Voucher</title>
            </Helmet>
            <div className="">
              {/* walletType="pharmacy" */}
                <Voucher walletType="pharmacy"/>
            </div>
        </DashboardLayout>
  )
}

export default PharmacyVoucher
