import React, { Fragment, useState,useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import { Service } from './Service';
import SmallModal from '../../../reusable/SmallModal';
import { TextField } from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import hospitalService from '../../../services/hospitalService';
import logger from '../../../utils/logger';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHospitalAuthStore } from '../../../store/hospital/auth';
import ErrorState from '../../../reusable/ErrorState';
import Lottie from 'lottie-react';
import loadingState from '../../../Images/loading.json';
const Services = () => {
    const [buttonEnabled, setButton] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const mutation = useMutation((data:any) =>hospitalService.addAdditionalService(data));
    const [isLoading, setIsLoading] = useState(false);
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const hospitalAccountStore = useHospitalAuthStore((state) => state);
    const [selectedServiceName, setSelectedServiceName] = useState<any>(null);
    const [selectedServiceId, setSelectedServiceId] = useState<any>(null);
    const servicesList: AdditionalServices[] = hospitalAccountStore.additionalServices || [];
    const [isDeleting, setIsDeleting] = useState(false);
    
    const deleteAdditionalServiceMutation = useMutation(
        (serviceId: string) => hospitalService.deleteAdditionalService(serviceId),
        {
            onSuccess: (data) => {
                
                console.log('Delete success:', data);
                // Refetch the list of services
                fetchAllServicesQuery.refetch()
                let payload: any = data;
                hospitalAccountStore.updateAdditionalServices(payload.data.payload.data);
                const message = payload.data.message;
                logger(message);
                toast.success(message);
                setTimeout(() => {
                    toast.dismiss();
                    setIsDeleting(false);
                    setIsLoading(false);
                }, 2000);
            },
            onError: (error: any) => {
                console.error('Delete additional service error:', error);
                console.log(error);
                setTimeout(() => {
                    setErrorMessage(error.response.data.error.message);
                    toast.error(error.response.data.error.message);
                    setIsDeleting(false);
                    setIsLoading(false);
                }, 1000);
            },
        }
    );
    const updateAdditionalServiceMutation = useMutation(
        (requestData: any) => hospitalService.updateAdditionalService(requestData.serviceId, requestData.updatedData),
        {
            onSuccess: (responseData:any) => {
                
                console.log('update success', responseData.data);
                let payload: any = responseData.data;
                const data = payload
                logger(data.message);
                toast.success(data.message);
                fetchAllServicesQuery.refetch();
                setTimeout(() => {
                    toast.dismiss(); 
                    setIsLoading(false);
                }, 2000);
            },
            onError: (error: any) => {
                console.error('Update additional service error:', error);
              
                setTimeout(() => {
                    toast.error(error.response.data.error.message);
                    setIsLoading(false);
                }, 1000);
            },
        }
    );
    const fetchAllServicesQuery = useQuery('fetchAllServices', hospitalService.getAdditionalServices, {
        onSuccess: (data:any) => {
            const services = data.data.payload.data.additionalService;
            hospitalAccountStore.updateAdditionalServices(services);
            console.log(services)
        },
    });

    useEffect(() => {
        // Trigger the fetch when the component mounts
        fetchAllServicesQuery.refetch();
    }, []);
  

    
    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            console.log('success', mutation.data);
            let payload: any = mutation.data;
            hospitalAccountStore.updateAdditionalServices(payload.data.payload.data);
            const data = payload.data;
            logger(data.message);
            // Show success message using Toastify
            toast.success('Services updated successfully');
            setTimeout(() => {
                toast.dismiss(); // Dismiss the toast after 3000 milliseconds
              
            }, 2000);
        }
    }, [mutation.isSuccess, mutation.data]);


    useEffect(() => {
        if (mutation.isError) {
            setIsLoading(false);
            let err: any = mutation.error;
            setErrorMessage(err.response.data.error.message);
        } else {
            setErrorMessage('');
        }
    }, [mutation.isError, mutation.error]);
// console.log(mutation)
    // const initialServices = hospitalAccountStore.additionalServices || [];
 
    const [formData, setFormData] = useState(() => {
        const initialData = servicesList.reduce((acc, service) => {
            acc[service.name] = service.amount;
            return acc;
        }, {});

        return {
            newMonthlyFee: '',
            newServiceName: '',
            ...initialData,
        };
    });
   
    const [activeService, setActiveService] = useState<any>(null);
  
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, serviceId: string, serviceName: string) => {
        const { value } = e.target;
        setButton(!!value);
        setFormData((prevData) => ({
            ...prevData,
            [serviceName]: value,
        }));
        setSelectedServiceName(serviceName)
        setSelectedServiceId(serviceId);
        // console.log(selectedServiceId)
        // setButton(true);
    };
    
    
    
    const handleServiceRemove = (e: any, serviceId: string) => {
        if (e) {
            e.preventDefault();
        }
        console.log('deleted');
        setIsDeleting(true);
        deleteAdditionalServiceMutation.mutate(serviceId);
    };
    const [modalFormData, setModalFormData] = useState({
        newMonthlyFee: '',
        newServiceName: '',
      });
    // console.log(modalFormData)
    const handleChangeModal = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setModalFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setButton(!!value && !!modalFormData.newServiceName && !!modalFormData.newMonthlyFee)
      };
    
    type AdditionalServices = {
        _id?: any;
        name?: any;
        description?: string;
        amount?: number;
        
    };
    const addNewService = () => {
        setShowModal(true);
       
        setModalFormData({
            newMonthlyFee: '',
            newServiceName: '',
          });
        setButton(false)
        console.log('clicked');
    };
    const closeModal = () => {
        setShowModal(false);
    };
    const closeEditModal = () => {
        setShowEditModal(false);
    };
    
   
    const handleSubmit = () => {
        setShowModal(false);
        setShowEditModal(false)
        setIsLoading(true);
    
        if (selectedServiceId) {
            const updatedService = {
                amount: formData[selectedServiceName], 
                 name:selectedServiceName
            };
        //     console.log('serviceId:', selectedServiceId);
        // console.log('updatedData:', updatedService);
            updateAdditionalServiceMutation.mutate({
                serviceId: selectedServiceId,
                updatedData: updatedService,
            });
        } else {
            // Handle the case when no service is selected
            console.error("No service selected for update");
        }
        // console.log({formData})
        // console.log({selectedServiceName})
        // console.log({selectedServiceId})
        // console.log(formData[selectedServiceName])
        setActiveService(null);
        setButton(false);
    };
      
    const handleEditClick = (serviceId: string, serviceName: string) => {
        setSelectedServiceId(serviceId);
        setSelectedServiceName(serviceName);
        const service = servicesList.find((s) => s._id === serviceId);
        if (service) {
          setModalFormData({
            newServiceName: service.name || '',
            newMonthlyFee: service.amount?.toString() || '',
          });
          setButton(true);
          setShowEditModal(true);
        }
      };
      
    
      const handleEditModalSubmit = async () => {
       
        setIsModalLoading(true)
        try {
          const updatedService = {
            name: modalFormData.newServiceName,
            amount: Number(modalFormData.newMonthlyFee),
          };
      
          updateAdditionalServiceMutation.mutate({
            serviceId: selectedServiceId,
            updatedData: updatedService,
          });
     
          
      
          setButton(false);
          setFormData((prevData) => ({
            ...prevData,
            [updatedService.name]: updatedService.amount,
        }));
        setTimeout(()=>{
            setIsModalLoading(false);
          setShowEditModal(false);
          fetchAllServicesQuery.refetch();
        },1000)

        } catch (error: any) {
          console.error('Edit additional service error:', error);
          setTimeout(() => {
              toast.error(error.response.data.error.message);
              setIsModalLoading(false);
          }, 1000);
        } finally {
          setModalFormData({
            newMonthlyFee: '',
            newServiceName: '',
          });
          setButton(false);
        }
      };
      
      

    const handleModalSubmit = async () => {
        setIsModalLoading(true);
        console.log('loading')
        try {
            const newServiceData = {
                name: modalFormData.newServiceName,
                amount: Number(modalFormData.newMonthlyFee),
            };
    
            const response = await hospitalService.addAdditionalService(newServiceData);
    
            // Handle success
       
            logger(response.data.message);
    
            // Wait for fetchAllServicesMutation to complete before updating local state
            // await fetchAllServicesQuery.refetch();
    
            toast.success('Service added successfully');
            fetchAllServicesQuery.refetch();
            setTimeout(() => {
                toast.dismiss();
                setShowModal(false);
                setIsModalLoading(false);
            }, 1000);
            setButton(false);
    
            // Update local state with the new service including the amount
            setFormData((prevData) => ({
                ...prevData,
                [newServiceData.name]: newServiceData.amount,
            }));
        } catch (error: any) {
            // Handle error
            setIsModalLoading(false);
    
            toast.error(error.response?.data?.error?.message || 'Failed to add service');
            setTimeout(() => {
                toast.dismiss();
            }, 3000);
        } finally {
            setModalFormData({
                newMonthlyFee: '',
                newServiceName: '',
            });
            setButton(false);
        }
    };
    
    
    
    const resetService = async () => {
        setButton(false);
    
        try {
            setIsLoading(true);
            // Fetch the latest services from the backend
            await  fetchAllServicesQuery.refetch();
            setIsLoading(false);
    
            // Update the form data with the original values from the fetched services
            const updatedFormData = hospitalAccountStore.additionalServices.reduce(
                (acc, service) => {
                    acc[service.name] = service.amount;
                    return acc;
                },
                {
                    newMonthlyFee: '',
                    newServiceName: '',
                }
            );
    
            setFormData(updatedFormData);
            setActiveService(null);
            toast.success('Services reset to previous state successfully');
        } catch (error:any) {
            setIsLoading(false);
            const errorMessage = error.response?.data?.error?.message || 'Failed to reset services';
            toast.error(errorMessage);
        }
    };
    
    
    
    return (
        <Fragment>
            <ToastContainer/>            
            <div className="bg-white rounded-lg shadow-sm">
            {errorMessage && <ErrorState message={errorMessage} />}
                <div className="flex flex-col gap-2">
                    <h1 className="font-medium sm:text-[22px] text-[18px]">
                        Hospital Services
                    </h1>
                    <p className="font-normal text-[14px] text-[#857371]">
                        Extra services your hospital offers.
                    </p>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-14 w-full py-4 sm:max-w-[764px]">
                {fetchAllServicesQuery.isLoading ? (
                
                <div className="flex flex-col justify-center items-center h-screen">
                    <span>Loading services...</span>
                    <Lottie
                    animationData={loadingState}
                    loop={true}
                    className="lottie"
                />
                </div>
            ):(<>
        {servicesList.map((service) => (
          <Service
            key={service._id}
            title={service.name}
            name={service.name}
            value={formData[service.name || ''] || ''}
            onchange={(e) => handleChange(e, service._id, service.name || '')}
            switchState={activeService === service.name}
            switchCallback={(state: any) => {
              setActiveService((prevState: any) => (state ? service.name : null));
            }}
            isDeleting={isDeleting}
            onRemove={(e: any) => handleServiceRemove(e, service._id as string)}
            onEdit={() => handleEditClick(service._id, service.name || '')} 
          />
        ))}
        </>)}
      </div>
                <div className="flex">
                    <button
                        onClick={() => addNewService()}
                        className="flex gap-2 items-center text-[#C00010] font-medium rounded-lg border border-[#534341] p-2 outline-none mt-4"
                    >
                        <FaPlus color="#C00010" />{' '}
                        <span className="text-[14px] ">Add new Service</span>
                    </button>
                </div>
                <div className="mt-[16px] items-center gap-4 flex justify-end py-4">

</div>
                <div className="mt-[16px] items-center gap-4 flex justify-end border-t py-4">
                    <button
                     disabled={!buttonEnabled}
                    onClick={resetService}
                        className={`border-0 text-[16px] ${
                            buttonEnabled
                                ? 'text-Tmred hover:cursor-pointer '
                                : 'text-lightGray '
                        }`}
                    >
                        Reset to previous
                    </button>
                    <button
                      disabled={!buttonEnabled}
                        onClick={handleSubmit}
                        className={`text-[16px] duration-300 rounded-lg px-6 py-1 ${
                            buttonEnabled
                                ? 'bg-Tmred text-white hover:cursor-pointer '
                                : 'bg-[#1D1B201F] text-lightGray'
                        }`}
                    >
                         {isLoading ? 'Updating...' : 'Update'}
                    </button>
                </div>
            </div>
            <SmallModal
                isVisible={showModal}
                onClose={closeModal}
                onSave={handleModalSubmit}
                buttonEnabled={buttonEnabled}
                setButton={setButton}
                saveText="Add New Service"
                title={'Add New Service'}
                isLoading={isModalLoading} 
                loadingText="Adding New Service..."
            >
                 <div className="flex flex-col gap-5 sm:w-[406px] py-5">
          <TextField
            className="w-full"
            variant="outlined"
            label="Enter Service Name"
            name="newServiceName"
            value={modalFormData.newServiceName}
            onChange={handleChangeModal}
          />
          <TextField
            className="w-full"
            variant="outlined"
            label="Enter Fee"
            name="newMonthlyFee"
            value={modalFormData.newMonthlyFee}
            onChange={handleChangeModal}
            type='number'
                        />
          
        </div>
            </SmallModal>
            <SmallModal
                isVisible={showEditModal}
                onClose={closeEditModal}
                onSave={handleEditModalSubmit}
                buttonEnabled={buttonEnabled}
                setButton={setButton}
                saveText="Save changes"
                title={'Edit Service'}
                isLoading={isModalLoading} 
                loadingText="Saving Changes..." 
            >
                 <div className="flex flex-col gap-5 sm:w-[406px] py-5">
          <TextField
            className="w-full"
            variant="outlined"
            label="Enter Service Name"
            name="newServiceName"
            value={modalFormData.newServiceName}
            onChange={handleChangeModal}
          />
          <TextField
            className="w-full"
            variant="outlined"
            label="Enter Fee"
            name="newMonthlyFee"
            value={modalFormData.newMonthlyFee}
            onChange={handleChangeModal}
            type='number'
                        />
          
        </div>
            </SmallModal>
        </Fragment>
    );
};

export default Services;
