import AddNurs from '../../../../components/UserNurse/AddNurs';
import DashboardLayout from '../../Dashboard/DashboardLayout';
import {Helmet} from 'react-helmet'


const AddNurse = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Add Nurse
                </title>
            </Helmet>
            <AddNurs />
        </DashboardLayout>
    );
};
export default AddNurse;
