import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import Modal from '../../../../reusable/Modal';
import Slider from 'rc-slider';
import  { Dayjs } from 'dayjs';
import 'rc-slider/assets/index.css';
import DatePickerElement from '../../../../reusable/Forms/DatePickerElement';
interface OrderFilterModalProps {
    isVisible: boolean;
    onClose: () => void;
    onSave: (queryParams: string) => void;
    buttonEnabled: boolean;
    setButton: (enabled: boolean) => void;
    handleDisplayModal: any;
  onFilterApply: any;
  resetFilters: any;
  currentFilters: any;
}

const OrderFilterModal: React.FC<OrderFilterModalProps> = ({
    isVisible,
    onClose,
    onSave,
    buttonEnabled,
    setButton,
    handleDisplayModal,
  onFilterApply,
  resetFilters,
  currentFilters,
}) => {
    const [queryParam, setQueryParam] = useState<string>('');
    const [order, setOrder] = useState(currentFilters.order || '');
    const [status, setStatus] = useState(currentFilters.status || '');
    const [rangeValues, setRangeValues] = useState([0, 100]);
    const handleRangeChange = (values: any) => {
      setRangeValues(values);
    };
    const [localFromDate, setLocalFromDate] = useState<Dayjs | null>(
      currentFilters.from ? dayjs(currentFilters.from) : null
    );
    const [localToDate, setLocalToDate] = useState<Dayjs | null>(
      currentFilters.to ? dayjs(currentFilters.to) : null
    );
  
    const trackStyle = { backgroundColor: '#C00010' };
    const handleStyle = {
      borderColor: '#C00010',
      backgroundColor: '#C00010',
    };
    const railStyle = { backgroundColor: '#EDE0DE' };
    useEffect(() => {
      setOrder(currentFilters.order || '');
      setStatus(currentFilters.status || '');
      // setRangeValues([
      //   currentFilters.rangeStart || 0,
      //   currentFilters.rangeEnd || 100,
      // ]);
      setLocalFromDate(currentFilters.from ? dayjs(currentFilters.from) : null);
      setLocalToDate(currentFilters.to ? dayjs(currentFilters.to) : null);
    }, [currentFilters]);
  

    const handleSave = (e: React.FormEvent) => {
        e.preventDefault();
        setButton(false);
        onSave(queryParam);
    };
    const handleApplyFilter = () => {
        onFilterApply({
          order,
          status,
          from: localFromDate ? localFromDate.format('YYYY-MM-DD') : '',
          to: localToDate ? localToDate.format('YYYY-MM-DD') : '',
        });
        handleDisplayModal();
      };
    
      const handleResetFilter = () => {
        resetFilters();
      };
    return (
        <Modal
            isVisible={isVisible}
            onClose={onClose}
            onSave={handleSave}
            setButton={setButton}
            buttonEnabled={!buttonEnabled}
            saveText="Save"
            closeText="Close"
            showCloseButtonBorder={false}
            clearAll={true}
            onClearAll={handleResetFilter}
        >
            <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2 max-w-[400px]">
                <h2 className="font-semibold text-2xl mb-5">
                    Filter By
                </h2>
            
          <div className="my-[30px]">
            <p className="font-normal text-base text-dark100 mb-3">Amount</p>
            <Slider
              range
              min={0}
              max={100}
              value={rangeValues}
              onChange={handleRangeChange}
              trackStyle={[trackStyle]}
              handleStyle={[handleStyle, handleStyle]}
              railStyle={railStyle}
            />
            <div className="md:flex md:space-x-6 space-y-1 md:space-y-0 ml-4 mt-4">
              <button
                type="button"
                className="flex items-center justify-start text-base font-normal border 
                        border-dark100 py-[4px] rounded-[8px]  w-[191px] h-[56px]">
                <span className="text-dark100 text-base font-normal px-2">
                  {rangeValues[0]}
                </span>
              </button>
              <button
                type="button"
                className="flex justify-start items-center text-base font-normal border
                         border-dark100 py-[4px] rounded-[8px]  w-[191px] h-[56px]">
                <span className="text-dark100 text-base font-normal px-2">
                  {rangeValues[1]}k
                </span>
              </button>
            </div>
          </div>
          <div className="mb-5">
            <p className=" text-base text-dark100 mb-3">Date Ordered</p>
            <div className="md:flex md:space-x-6 space-y-1 md:space-y-0 ml-4">
              <DatePickerElement
                label="From"
                value={localFromDate}
                onChange={(date: Dayjs | null) => setLocalFromDate(date)}
              />
              <DatePickerElement
                label="To"
                value={localToDate}
                onChange={(date: Dayjs | null) => setLocalToDate(date)}
              />
            </div>
          </div>
          <div>
            <p className="text-base text-dark100 mb-3">Order</p>
            <div className="ml-4">
              <label className="flex items-center space-x-2 cursor-pointer mb-2">
                <input
                  type="checkbox"
                  className="p-[2px] rounded-sm cursor-pointer"
                  checked={order === 'asc'}
                  onChange={() => setOrder('asc')}
                />
                <span className="text-base font-light">Ascending</span>
              </label>
              <label className="flex items-center space-x-2 cursor-pointer pt-2 ">
                <input
                  type="checkbox"
                  className="p-[2px] rounded-sm  cursor-pointer"
                  checked={order === 'desc'}
                  onChange={() => setOrder('desc')}
                />
                <span className="text-base font-light">Descending</span>
              </label>
            </div>
          </div>
          {/* <div className="flex items-center justify-end space-x-6 mt-[30px]">
            <button className="text-sm" onClick={handleResetFilter}>
              Reset
            </button>
            <button className="text-sm" onClick={handleDisplayModal}>
              Close
            </button>
            <button
              onClick={handleApplyFilter}
              className="bg-Tmred text-sm px-5 py-2 rounded-lg text-white">
              Save
            </button>
          </div> */}
            </div>
        </Modal>
    );
};

export default OrderFilterModal;
