import AddAdm from '../../../components/UserAdmin/AddAdm';
import DashboardLayout from '../Dashboard/DashboardLayout';
import {Helmet} from 'react-helmet'


const AddAdmin = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Add Admin
                </title>
            </Helmet>
            <AddAdm />
        </DashboardLayout>
    );
};
export default AddAdmin;
