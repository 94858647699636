import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import back from '../../../Images/back.png';
import search from '../../../Images/Leading-icon.png';
import Filter from '../../../Images/filtericon.svg';
import VoucherImg from '../../../Images/voucher.svg';
import usePagination from '../../../hooks/usePagination';
import Ticket from '../../../Images/ticket.svg';
import Table from '../../../constants/Table';
import Modal from '../../../reusable/Modal';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import dayjs, { Dayjs } from 'dayjs';
import DatePickerElement from '../../../reusable/Forms/DatePickerElement';
import Lottie from 'lottie-react';
import loadingState from '../../../Images/loading.json';
import { useMutation } from 'react-query';
import TimeComponent, { formatDateDmy } from '../../../utils/helpers';
import BlockButton from '../../../reusable/BlockButton';
import Input from '../../../reusable/Forms/Input';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import VoucherModal from './Modals/VoucherModal';
import { adminService } from '../../../services/adminService';
import { toast } from 'react-toastify';
import CurrencyFormat from '../../../utils/Currency';
interface rowType {
    _id: string;
    billId: any;
}
type InputProps = {
    amount: string;
};

const VoucherHistories = () => {
    const { walletType } = useParams();

    const navigate = useNavigate();
    const [showModal, setShowModal] = React.useState(false);
    const [buttonEnabled, setButton] = useState(false);
    const [rangeValues, setRangeValues] = useState([0, 100]);
    const [onOpen, setOnOpen] = useState<boolean>(false);
    const [selectedFromDate, setSelectedFromDate] = useState<Dayjs | null>(
        dayjs('')
    );
    const [selectedToDate, setSelectedToDate] = useState<Dayjs | null>(
        dayjs('')
    );

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [voucherData, setVoucherData] = useState<any>();
    const [errorMessage, setErrorMessage] = useState<string>('');

    const goBack = () => {
        navigate(-1);
    };
    //get voucher
    useEffect(() => {
        setIsLoading(true);
        adminService
            .getAllVoucher(walletType) // Get All voucher
            .then((res) => {
                setIsLoading(false);
                setVoucherData(res?.vouchers);
                console.log(voucherData);
            })
            .catch((err) => {
                setIsError(true);
                toast.error(err);
            });
    }, []);
    //Purchase Voucher
    const {
        formState: { isDirty, isValid },
        handleSubmit,
        control,
        reset,
    } = useForm<InputProps>();
    {
        /* <InputProps> */
    }
    const mutation = useMutation((data: InputProps) =>
        adminService.purchaseVoucher(data, walletType)
    );

    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            console.log('success', mutation.data);
            const response = mutation.data;
            const message = response.data.message;
            console.log('Success:', message);
            toast.success(message);
            const link = response.data.payload.data.paymentLink;
            const openWindowWithDelay = () => {
                window.open(link, '_blank');
            };

            // Set a 3-second delay
            setTimeout(openWindowWithDelay, 2000);
            reset({});
        }
    }, [mutation.isSuccess, mutation.data, reset]);

    useEffect(() => {
        if (mutation.isError) {
            setIsLoading(false);
            let err: any = mutation.error;
            console.log('is error', err);
            setErrorMessage(
                err.response?.data?.error?.message || 'Something went wrong'
            );
            toast.error(err.response?.data?.error?.message);
            reset({});
        } else {
            setErrorMessage('');
        }
    }, [mutation.isError, mutation.error]);

    const onSubmitForm: SubmitHandler<InputProps> = (data) => {
        console.log('submit data:', data);
        setOnOpen(false);
        setIsLoading(true);
        mutation.mutate(data);
    };

    // Update the rangeValues state when the slider values change
    // const {
    //     formState: { isDirty, isValid },
    //     handleSubmitSearch,
    //     control,
    // } = useForm();
    const handleRangeChange = (values) => {
        setRangeValues(values);
    };
    const trackStyle = { backgroundColor: 'red' };
    const handleStyle = {
        borderColor: 'red',
        backgroundColor: 'red',
    };
    const railStyle = { backgroundColor: '#EDE0DE' };

    const closeModal = () => {
        setShowModal(false);
    };
    const [selectedOption, setSelectedOption] = useState(null);
    const handleOptionChange = (option: any) => {
        setSelectedOption(option);
    };

    const setModal = () => {
        setShowModal(true);
    };
    const handleSubmitSearch = (e: React.FormEvent) => {
        console.log('Form submitted!');
        setShowModal(false);
        setButton(false);
    };
    //Pagination
    const { page, limit, Pagination } = usePagination({
        page: 1,
        limit: 10,
        total: voucherData?.length,
    });

    const paginatedRows = voucherData?.slice((page - 1) * limit, page * limit);

    if (isLoading) {
        return (
            <div className="flex justify-center items-center py-32">
                <Lottie
                    animationData={loadingState}
                    loop={true}
                    className="lottie"
                />
            </div>
        );
    }

    if (isError) {
        return <div>Error loading bill, please refresh your page</div>;
    }
    const upcomingTableHeaders = [
        // {
        //     title: 'S/N',
        //     render: (row: any) => (
        //         <p>
        //             {voucherData?.findIndex(
        //                 (user: any) => user._id === row._id
        //             ) + 1}
        //         </p>
        //     ),
        // },
        {
            title: 'Voucher Code',
            render: (row: any) => (
                <div className="py-[6px] text-[12px] font-medium text-light_gray text-left">
                    <p className="font-medium !text-[#201A19] text-sm">
                        {row.code}
                    </p>
                </div>
            ),
        },
        {
            title: 'Amount',
            render: (row: any) => (
                <div>
                    <p className={`${'!text-[#201A19]'} text-xs font-medium`}>
                        <CurrencyFormat amount={row.amount} />
                    </p>
                </div>
            ),
        },
        {
            title: 'Name',
            render: (row: any) => (
                <div className="flex items-center">
                    <div>
                        <p className="!text-lightBrown text-sm font-medium truncate w-[180px]">
                            {row.status === 'used'
                                ? row.metadata?.usedBy?.name
                                : row.metadata?.boughtBy?.name}
                        </p>
                    </div>
                </div>
            ),
        },
        {
            title: 'Type',
            render: (row: any) => (
                <div
                    className={`flex items-start justify-center p-3 rounded w-fit ${
                        row.status === 'used'
                            ? 'bg-[#FFDAD5] text-red-500'
                            : 'bg-[#1D1B2014] text-lightBrown'
                    }`}
                >
                    <div
                        className={`w-[6px] h-[6px] rounded-full mr-2 mt-[6px] ${
                            row.status === 'used'
                                ? 'bg-[#C00010]'
                                : 'bg-[#8BBEA0]'
                        }`}
                    />
                    <p className="text-xs">{row.status}</p>
                </div>
            ),
        },

        {
            title: 'Date',
            render: (row: any) => (
                <p className="capitalize flex gap-2 font-medium text-lightBrown text-xs">
                    {formatDateDmy(row.createdAt)},
                    <TimeComponent time={row.createdAt} />
                </p>
            ),
        },
    ];

    return (
        <Fragment>
            <div className="w-full flex flex-col md:px-8 py-4 px-2 ">
                <div className="flex gap-2 items-center mb-8">
                    <button onClick={goBack}>
                        <img src={back} alt="" />
                    </button>
                    <div className="flex justify-between flex-grow items-center ">
                        <h2 className="font-semibold text-[28px]">
                            Viewing Voucher
                        </h2>
                        <button
                            onClick={() => {
                                setOnOpen(true);
                            }}
                            className="text-white flex gap-3 font-light bg-[#C00010] rounded-[8px] px-3  sm:px-[16px] sm:py-[10px] py-2 text-[16px]"
                        >
                            <img src={Ticket} alt="voucher-icon" />
                            Purchase voucher
                        </button>
                        <div className="absolute">
                            <VoucherModal
                                isVisible={onOpen}
                                onClose={() => setOnOpen(false)}
                                header={'Purchase Voucher'}
                            >
                                <div>
                                    <p className="my-6">
                                        Kindly enter the amount you want to buy
                                    </p>
                                    <form onSubmit={handleSubmit(onSubmitForm)}>
                                        <Controller
                                            name="amount"
                                            rules={{
                                                required:
                                                    'Search Parameter is required',
                                            }}
                                            control={control}
                                            render={({ field }) => (
                                                <Input
                                                    label="Enter Amount"
                                                    type="number"
                                                    {...field}
                                                />
                                            )}
                                        />

                                        <BlockButton
                                            text="Proceed"
                                            disabled={!isDirty || !isValid}
                                            type="submit"
                                            isLoading={false}
                                            className="mt-5 p-3"
                                        />
                                    </form>
                                </div>
                            </VoucherModal>
                        </div>
                    </div>
                </div>
                <div className="bg-white p-4 rounded-2xl">
                    <div className="flex flex-col-reverse sm:flex-row items-center justify-between gap-4 w-full px-4">
                        <div className="flex items-center space-x-3">
                            <h2 className="md:text-2xl text-sm font-semibold text-dark">
                                Total Vouchers
                            </h2>
                            <button
                                type="button"
                                className="md:text-[22px] text-sm bg-red py-1 text-white px-1 md:px-[11.64px] rounded-[290.909px]"
                            >
                                {voucherData?.length}
                            </button>
                        </div>
                        <div>
                            <div className="flex  justify-between">
                                <div className=" flex items-center gap-2 border bg-[#F5F6F7] border-[#F5F6F7] rounded-[28px] sm:max-w-[328px]">
                                    <img src={search} alt="search" />
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        //   value={searchQuery}
                                        //   onChange={(e) => setSearchQuery(e.target.value)}
                                        className="w-full outline-none font-medium text-[14px] bg-transparent text-[#534341] border-transparent focus:ring-0"
                                    />
                                </div>
                                <div className="flex mr-4 ml-4 items-center">
                                    <img
                                        onClick={setModal}
                                        src={Filter}
                                        alt="filterIcon"
                                        className="cursor-pointer h-[24px] w-[24px]"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-[9px]  flex flex-col px-4 py-3 ">
                        {voucherData && voucherData.length !== 0 ? (
                            <>
                                <Table
                                    rows={voucherData.slice(0, 10)}
                                    headers={upcomingTableHeaders}
                                    allowRowClick
                                    showHead={true}
                                    // onRowClick={(row: rowType) =>
                                    //     navigate(
                                    //         `/pharmacy/billing/bill-invoice/${row.billId}`
                                    //     )
                                    // }
                                />
                                <Pagination />
                            </>
                        ) : (
                            <div className="flex flex-col justify-center items-center mt-6">
                                <img src={VoucherImg} alt="empty" />
                                <p className="mt-8 mb-16 w-[40%] text-center text-sm font-medium text-dark100">
                                    Purchase vouchers and use it for your
                                    subscription, Appointment, Bill, and wallet
                                    funding
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Modal
                isVisible={showModal}
                onClose={() => closeModal()}
                onSave={handleSubmit}
                buttonEnabled={buttonEnabled}
                setButton={setButton}
                saveText="Save"
                closeText="Close"
                showCloseButtonBorder={false}
            >
                <div className=" ">
                    <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2 sm:max-w-[458px]">
                        <h2 className="font-semibold text-2xl mb-10">
                            Filter By
                        </h2>

                        <div className="mb-5">
                            <h2 className="font-medium text-base text-dark100 mb-3">
                                Amount
                            </h2>
                            <Slider
                                range
                                min={0}
                                max={100}
                                value={rangeValues}
                                onChange={handleRangeChange}
                                trackStyle={[trackStyle]}
                                handleStyle={[handleStyle, handleStyle]}
                                railStyle={railStyle}
                            />

                            <div className="md:flex md:space-x-6 space-y-1 md:space-y-0 ml-4 mt-4">
                                <button
                                    type="button"
                                    className="flex items-center justify-start text-base font-normal border border-dark100 py-[4px] rounded-[8px]  w-[191px] h-[56px]"
                                >
                                    <span className="text-dark100 text-base font-normal px-2">
                                        {rangeValues[0]}
                                    </span>
                                </button>
                                <button
                                    type="button"
                                    className="flex justify-start items-center text-base font-normal border border-dark100 py-[4px] rounded-[8px]  w-[191px] h-[56px]"
                                >
                                    <span className="text-dark100 text-base font-normal px-2">
                                        {rangeValues[1]}k
                                    </span>
                                </button>
                            </div>
                        </div>

                        <div className="mb-5">
                            <h2 className="font-medium text-base text-dark100 mb-3">
                                Date
                            </h2>
                            <div className="md:flex md:space-x-6 space-y-1 md:space-y-0 ml-4">
                                <DatePickerElement
                                    label="From"
                                    value={selectedFromDate}
                                    onChange={(date) =>
                                        setSelectedFromDate(date)
                                    }
                                />
                                <DatePickerElement
                                    label="To"
                                    value={selectedToDate}
                                    onChange={(date) => setSelectedToDate(date)}
                                />
                            </div>
                        </div>
                        <div className="">
                            <h2 className="font-medium text-base text-dark100 mb-3">
                                Order
                            </h2>
                            <div className="ml-4">
                                <label className="flex items-center space-x-2 cursor-pointer mb-2">
                                    <input
                                        type="checkbox"
                                        className="p-[2px] rounded-sm cursor-pointer"
                                        checked={selectedOption === 'ascending'}
                                        onChange={() =>
                                            handleOptionChange('ascending')
                                        }
                                    />
                                    <span className="text-base font-normal">
                                        Ascending
                                    </span>
                                </label>
                                <label className="flex items-center space-x-2 cursor-pointer pt-2 ">
                                    <input
                                        type="checkbox"
                                        className="p-[2px] rounded-sm  cursor-pointer"
                                        checked={
                                            selectedOption === 'descending'
                                        }
                                        onChange={() =>
                                            handleOptionChange('descending')
                                        }
                                    />
                                    <span className="text-base font-normal">
                                        Descending
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </Fragment>
    );
};

export default VoucherHistories;
