import { useEffect, useState } from 'react';
import Modal from '../../reusable/Modal';
import Input from '../../reusable/Forms/Input';

interface FilterModalProps {
    isVisible: boolean;
    onClose: () => void;
    onSave: (queryParams: string) => void;
    buttonEnabled: boolean;
    setButton: (enabled: boolean) => void;
    queryParam?: any;
    setQueryParam: (queryParams: any) => void;
}

const PatientFilterModal = ({
    isVisible,
    onClose,
    onSave,
    buttonEnabled,
    setButton,
    queryParam,
    setQueryParam
}: FilterModalProps) => {

    const [localQueryParam, setLocalQueryParam] = useState<any>(queryParam);

    const resetQueryParam = () => {
        setQueryParam({
            gender: '',
            fromDate: null,
            toDate: null,
            order: '',
        });
    }

    useEffect(() => {
        setLocalQueryParam(queryParam);
    }, [queryParam]);

    const handleSave = (e: React.FormEvent) => {
        e.preventDefault();
        setButton(false);
        onSave(queryParam);
    };

    return (
        <Modal
            isVisible={isVisible}
            onClose={onClose}
            onSave={handleSave}
            setButton={setButton}
            buttonEnabled={!buttonEnabled}
            saveText="Save"
            closeText="Close"
            showCloseButtonBorder={false}
            clearAll={true}
            onClearAll={resetQueryParam}
        >
            <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2 ">
                <h2 className="font-semibold text-2xl mb-5">
                    Filter By
                </h2>

                <div className="mb-5">
                    <h2 className="font-medium text-base text-dark100 mb-3">
                        Gender
                    </h2>
                    <div className="md:flex items-center sm:space-x-3 space-y-1 md:space-y-0 ml-4 ">
                        {['male', 'female'].map((mode) => (
                            <button
                                key={mode}
                                type="button"
                                className={`px-4 border-[1px] text-lightBrown border-lightBrown100 font-medium text-sm py-[6px] rounded-lg ${
                                    localQueryParam.gender === mode ? 'bg-[#1D1B2014]' : ''
                                }`}
                                onClick={() => setQueryParam({ ...queryParam, gender: mode })}
                            >
                                {mode.charAt(0).toUpperCase() + mode.slice(1)}
                            </button>
                        ))}
                    </div>
                </div>

                <div className="mb-5">
                    <h2 className="font-medium text-base text-dark100 mb-3">
                        Date Registered
                    </h2>
                    <div className="md:flex md:space-x-6 space-y-1 md:space-y-0 ml-4 sm:max-w-[362px]">
                        <Input 
                            type='date' 
                            name='fromDate'
                            label="From Date" 
                            value={localQueryParam.fromDate || ''}
                            onChange={(e) => setQueryParam({ ...queryParam, fromDate: e.target.value })}
                        />
                        <Input 
                            type='date' 
                            name='toDate'
                            label="To Date" 
                            value={localQueryParam.toDate || ''}
                            onChange={(e) => setQueryParam({ ...queryParam, toDate: e.target.value })}
                        />
                    </div>
                </div>
                <div className="">
                    <h2 className="font-medium text-base text-dark100 mb-3">
                        Order
                    </h2>
                    <div className="ml-4">
                        {['ascending', 'descending'].map((order) => (
                            <label key={order} className="flex items-center space-x-2 cursor-pointer mb-2">
                                <input
                                    type="checkbox"
                                    className="p-[2px] rounded-sm cursor-pointer"
                                    checked={localQueryParam.order === order}
                                    onChange={() => setQueryParam({ ...queryParam, order })}
                                />
                                <span className="text-base font-normal">
                                    {order.charAt(0).toUpperCase() + order.slice(1)}
                                </span>
                            </label>
                        ))}
                    </div>
                </div>
            </div>
        </Modal>
    );
};
export default PatientFilterModal;
