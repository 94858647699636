import { useState, useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import Layout from '../Layout';
import arrowLeft from './../../../Images/arrow-left.svg';
import OTP from './OTP';
import { useNavigate } from 'react-router-dom';
import Input from '../../../reusable/Forms/Input';
import ErrorState from '../../../reusable/ErrorState';
import BlockButton from '../../../reusable/BlockButton';
import HospitalAuthHeader from '../../../reusable/HospitalAuthHeader';
import goBack from '../../../utils/goBack';
import { useMutation } from 'react-query';
import logger from '../../../utils/logger';
import {Helmet} from 'react-helmet'
import { pharmacyService } from '../../../services/pharmacyService';
import { usePharmacyAuthStore } from '../../../store/pharmacy/auth';
import { pharmacyRoutes } from '../../../utils/pharmacyRouteNames';


type ForgotPasswordProps = {
    email: string;
    pharmacyId: string;
};

const ForgotPassword = () => {
    const [showOTP, setShowOTP] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const pharmacyAuthStore = usePharmacyAuthStore((state) => state);

    useEffect(() => {
        if (!pharmacyAuthStore.pharmacy.name) {
            navigate(pharmacyRoutes.healthID);
        }
    }, []);

    const {
        formState: { isDirty, isValid },
        handleSubmit,
        control,
    } = useForm<ForgotPasswordProps>();

    const mutation = useMutation((data: ForgotPasswordProps) =>
        pharmacyService.forgotPassword(data)
    );

    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            console.log('success', mutation.data);
            let response: any = mutation.data;
            const data = response.data.payload;
            logger(data);
            setShowOTP(true);
        }
    }, [mutation.isSuccess, mutation.data]);

    useEffect(() => {
        if (mutation.isError) {
            setIsLoading(false);
            let err: any = mutation.error;
            setErrorMessage(err.response.data.error.message);
        } else {
            setErrorMessage('');
        }
    }, [mutation.isError, mutation.error]);

    const backToForgotPassword = () => {
        //  clearEmail();
        setShowOTP(false);
    };

    const submitForgotPassword: SubmitHandler<ForgotPasswordProps> = async (
        data
    ) => {
        setIsLoading(true);
        mutation.mutate({
            ...data,
            pharmacyId: pharmacyAuthStore.pharmacy.id,
        });
        setEmail(data.email);
    };

    return (
        <Layout>
            <Helmet>
                <title>
                    TMR | Forgot Password
                </title>
            </Helmet>
            <HospitalAuthHeader
                logo={pharmacyAuthStore.pharmacy.logo}
                text={pharmacyAuthStore.pharmacy.tagline}
                title={pharmacyAuthStore.pharmacy.name}
            />
            {!showOTP && (
                <div className="flex flex-col justify-center items-center">
                    <div className="flex flex-col justify-center border border-[#00000033] bg-white rounded-[25px] px-[40px] py-[40px] max-w-[592px] w-[fill]">
                        <div className="flex">
                            <div className="flex absolute">
                                <button
                                    className="flex relative"
                                    onClick={(_) => goBack(navigate)}
                                >
                                    <img src={arrowLeft} alt="arrow-left" />
                                </button>
                            </div>
                            <div className="flex items-center justify-center w-[fill]">
                                <h1 className=" font-semibold text-center text-[32px]  ">
                                    {' '}
                                    Forgot Password{' '}
                                </h1>
                            </div>
                        </div>
                        <p className="text-[#534341] text-center text-[14px]">
                            Enter your email to reset password
                        </p>

                        {errorMessage && <ErrorState message={errorMessage} />}
                        <form
                            onSubmit={handleSubmit(submitForgotPassword)}
                            className="my-4 "
                            noValidate
                        >
                            <Controller
                                name="email"
                                rules={{
                                    required: 'Email address is required',
                                    pattern: {
                                        value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                        message: 'Invalid email address',
                                    },
                                }}
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        label="Email Address"
                                        type="email"
                                        {...field}
                                    />
                                )}
                            />
                            <div className="py-5">
                                <BlockButton
                                    text="Proceed"
                                    disabled={!isDirty || !isValid}
                                    type="submit"
                                    isLoading={isLoading}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {showOTP && (
                <OTP
                    backToForgotPassword={backToForgotPassword}
                    email={email}
                />
            )}
        </Layout>
    );
};

export default ForgotPassword;
