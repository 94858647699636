export const pharmacyRoutes = {
    healthID: '/',
    login: '/pharmacy/auth/login',
    register: '/pharmacy/auth/register',
    superAdmin: '/pharmacy/auth/super-admin',
    otp: '/pharmacy/auth/otp',
    setupLockcode: '/pharmacy/auth/setup-lockcode',
    subscriptionPlan: '/pharmacy/auth/subscription-plan',
    applicationProcessing: '/pharmacy/auth/application-processing',
    screenTimeout: '/pharmacy/screen-timeout',
    forgotPassword: '/pharmacy/auth/forgot-password',
    resetPassword: '/pharmacy/auth/reset-password',
    dashboard: '/pharmacy/dashboard',
    inventory: '/pharmacy/inventory',
    addInventory: '/pharmacy/inventory/add-inventory',
    previewInventory: '/pharmacy/inventory/preview-inventory',
    requestInventory: '/pharmacy/inventory/request-inventory',
    productDetails: '/pharmacy/inventory/product-details',
    editDetails: '/pharmacy/inventory/edit-details',
    orders: '/pharmacy/orders',
    orderDetails: '/pharmacy/orders/details',
    createOrder: '/pharmacy/orders/create-order',
    previewOrder: '/pharmacy/orders/preview-order',
    admin: '/pharmacy/admin',
    addAdmin: '/pharmacy/add-admin',
    adminProfile: '/pharmacy/admin-profile',
    setting: '/pharmacy/settings',
    myProfile: '/pharmacy/settings/my-profile',
    pharmacyProfile: '/pharmacy/settings/pharmacy-profile',
    editProfile: '/pharmacy/settings/pharmacy/edit',
    editPersonalProfile: '/pharmacy/settings/profile/edit',
    nonAdminSetting: '/pharmacy/settings/my-settings',
    pharmacyVoucher: '/pharmacy/voucher/pharmacy-voucher',
    pharmacyVoucherHistory: '/pharmacy/voucher/voucher-history',
    sendMoney: '/pharmacy/billing/send-money',
    withdrawMoney: '/pharmacy/billing/withdraw-money',
    billHistory: '/pharmacy/billing/bill-history',
    billInvoice: '/pharmacy/billing/bill-invoice',
    pharmacyWallet: '/pharmacy/wallet/pharmacy-wallet',
    myWallet: '/pharmacy/wallet/my-wallet',
    wallet: '/pharmacy/wallet',
    voucher: '/pharmacy/voucher',
    PharmacyVoucher: '/pharmacy/voucher/pharmacy-voucher',
    tmrAds:'/pharmacy/tmr-ads',
    createAds:'/pharmacy/tmr-ads/create',
    adsProfile:'pharmacy/ads/:id'
};
