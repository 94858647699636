// import { useState } from 'react';
import usePagination from '../../hooks/usePagination';
import Table from '../../constants/Table';
import { useNavigate } from 'react-router-dom';
import { formatDateDmy, standardAmountFormat } from '../../utils/helpers';
import image from '../../Images/EmptyAds.svg';
import AdsEmptyState from './AdsEmptyState';
import { useHospitalAuthStore } from '../../store/hospital/auth';

interface rowType {
    _id: string;
}
const AdsData = ({ ads, searchQuery }: any) => {
    const currency = useHospitalAuthStore((state) => state.hospital.currency);
    const navigate = useNavigate();
 console.log(ads)
    const filteredadss = ads?.filter(
        (row: any) =>
            row.adsName
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
            row.adsName
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
            row.status.toLowerCase().includes(searchQuery.toLowerCase()) 
    );

    //Pagination
    const { page, limit, Pagination } = usePagination({
        page: 1,
        limit: 10,
        total: ads?.length,
    });

    const paginatedRows = filteredadss?.slice(
        (page - 1) * limit,
        page * limit
    );

   

    const adsTableHeaders = [
        {
            title: 'S/N',
            render: (row: any) => (
                <p>
                    {ads?.findIndex(
                        (user: any) => user._id === row._id
                    ) + 1}
                </p>
            ),
        },
        {
            title: 'Ad Name',
            render: (row: any) => <p>{row.adsName}</p>,
        },
      
       

        {
            title: 'Amount',
            render: (row: any) => (
                <p className="">
                    {standardAmountFormat(row.fee, currency)}
                </p>
            ),
            
        },

       
        {
            title: 'Start Date ',
            render: (row: any) => <p> {formatDateDmy(row.schedule.starts)}</p>,
        },
        {
            title: 'Expiry Date ',
            render: (row: any) => <p> {formatDateDmy(row.schedule.expires)}</p>,
        },
        {
            title: 'Status',
            render: (row: any) => (
                <div
                className={`flex items-start justify-center p-2 rounded 
                    ${
                        row.status === 'pending'
                            ? 'bg-[#FDDFA6] text-lightBrown'
                            : ''
                    } 
                    ${
                        row.status === 'expired'
                            ? 'bg-[#E2EEE7] text-lightBrown'
                            : ''
                    }
                    ${
                        row.status === 'approved'
                            ? 'bg-[#E2EEE7] text-lightBrown'
                            : ''
                    }
                    ${
                        row.status === 'rejected'
                            ? 'bg-[#FFDAD5] text-lightBrown'
                            : ''
                    }
                `}
            >
                <div
                    className={`w-[6px] h-[6px] rounded-full mr-2 mt-[6px] 
                        ${row.status === 'pending' ? 'bg-lightBrown' : ''}
                        ${row.status === 'expired' ? 'bg-[#3A975F]' : ''}
                        ${row.status === 'approved' ? 'bg-[#3A975F]' : ''}
                        ${row.status === 'rejected' ? 'bg-Tmred' : ''}
                    }`}
                />
                <p className="text-xs">{row.status}</p>
            </div>
            ),
        },
    ];
    return (
        <div className="w-full pt-5">
            {!ads?.length ? (
                <AdsEmptyState />
            ) : filteredadss?.length === 0 ? ( // Check if filteredadss is empty
                <>
                    {/* <Table headers={adsTableHeaders} showHead={true} /> */}
                    <div className="flex flex-col items-center justify-center text-center my-[70px] ">
                        <img src={image} alt="" />
                        <p className="mt-6 text-base font-medium font-[Montserrat]">
                            The search result is currently empty.
                        </p>
                    </div>
                </>
            ) : (
                <div className="gap-[2rem] block md:grid">
                    <div className="w-full grid gap-x-[30px] self-start md:mb-[0] mb-[2rem] overflow-x-auto scrollbar-hide">
                        <Table
                            rows={paginatedRows}
                            headers={adsTableHeaders}
                            showHead={true}
                            allowRowClick
                            onRowClick={(row: rowType) =>
                                navigate(
                                    `/hospital/ads/${row._id}`
                                )
                            }
                        />
                        <Pagination />
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdsData;
