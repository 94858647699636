import { useState, memo } from 'react';
import AssignModal from '../PatientAppointment/PatientModals/AssignModal';

const InviteStaff = ({appointment, refetch}) => {

    const [inviteStaffModal, setInviteStaffModal] = useState<boolean>(false);
    const toggleInviteStaffModal = () => {
        setInviteStaffModal(!inviteStaffModal)
    };
    return (
        <>
            <button 
                className="flex justify-center items-center text-xs border-[1px] border-[#857371] rounded-[10px] text-red px-4 py-2"
                onClick={toggleInviteStaffModal}
            >
                Invite Staff
            </button>
            <AssignModal
                showModal={inviteStaffModal}
                onClose={toggleInviteStaffModal}
                modalText='Send Invite'
                headingText='Invite Staff Member'
                appointmentDetails={appointment}
                refetch={refetch}
                assignType='telemedicine'
            />
        </>
    )
}

export default memo(InviteStaff)