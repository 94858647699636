import { jwtDecode } from 'jwt-decode';
import { useQuery } from 'react-query';
import hospitalService from '../services/hospitalService';
import { useEffect, useState } from 'react';

interface MyToken {
    name: string;
    exp: number;
}

/**
 * Validate if the token is valid or has expired
 * @param token the jwt token
 * @returns boolean
 */
export const isTokenValid = (token: string) => {
    try {
        if (!token) return false;
        const decoded = jwtDecode<MyToken>(token);
        if (Date.now() >= decoded.exp * 1000) {
            return false;
        }
        return true;
    } catch (error) {
        return false;
    }
};

export const formatDateDmy = (date: any) => {
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    const d = new Date(date);
    const day = d.getDate();
    const month = months[d.getMonth()];
    const year = d.getFullYear();

    return `${day} ${month} ${year}`;
};

export const formatDatesDmy = (date: any) => {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const d = new Date(date);
    const day = d.getDate();
    const daySuffix = getDaySuffix(day);
    const dayOfWeek = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ][d.getDay()]; // Array containing days of the week
    const month = months[d.getMonth()];
    const year = d.getFullYear();

    return `${dayOfWeek} ${day}${daySuffix} of ${month} ${year}`;
};

export const formatAdsCreationDate = () => {
    const d = new Date();
    const day = d.getDate();
    const month: string | number = d.getMonth();
    const year = d.getFullYear();
    const startMonth: string = month < 10 ? '0'+ (month+1) : String(month);
    const expiryMonth: string = month < 10 ? '0'+ (month+2) : String(month + 1);

    const startDate = `${year}-${startMonth}-${day}`
    const expiryDate = `${year}-${expiryMonth}-${day}`

    return { startDate, expiryDate };
}

const getDaySuffix = (day: number) => {
    if (day >= 11 && day <= 13) {
        return 'th';
    }
    const lastDigit = day % 10;
    switch (lastDigit) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
};

export function standardAmountFormat(
    amount: string | number,
    currency: string = 'NGN'
) {
    return (
        currency + ' ' + new Intl.NumberFormat('en-US').format(Number(amount))
    );
}


export const addTimeToDate = (date: Date, time: string): Date => {
    if (!date || !time) return date;

    // Split the time string into hour, minute, and AM/PM
    const [hourString, minuteString] = time.split(':');
    const [minuteValue, amPmString] = minuteString.split(' ');

    // Parse the hour, minute, and AM/PM values
    let hour = parseInt(hourString, 10);
    const minute = parseInt(minuteValue, 10);
    const isAM = amPmString.toUpperCase() === 'AM';

    // Handle 12 AM/PM cases
    if (hour === 12) {
        hour = isAM ? 0 : 12; // 12 AM is 0, 12 PM is 12
    } else if (!isAM) {
        hour += 12; // PM hours need to be in 24-hour format
    }

    // Create a new Date object with the updated time
    const newDate = new Date(date);
    newDate.setHours(hour);
    newDate.setMinutes(minute);

    return newDate;
};

/**
 * Compare the difference between two dates
 * @param minutes
 * @returns boolean (true if date has passed otherwise false)
 */
export const compareDate = (
    dateToCompare: Date,
    currentDate: Date = new Date()
): boolean => {
    let date1 = currentDate.getTime(); // current date
    let date2 = dateToCompare.getTime(); // date to compare
    if (date1 > date2) {
        return true;
    } else {
        return false;
    }
};

export const TimeComponent = ({ time }: { time: string }) => {
    const formatTime = (timeString: string) => {
        const date = new Date(timeString);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const amPm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        return `${formattedHours}:${formattedMinutes} ${amPm}`;
    };

    return (
        <p className="capitalize font-medium text-lightBrown text-xs">
            {formatTime(time)}
        </p>
    );
};
export const formatNumber = (number) => {
    // Check if the number is valid and convert to string
    if (!isNaN(number)) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return number; // Return original value if not a number
  };
export default TimeComponent;
