import React, { useEffect, useState } from 'react';
import Input from '../../../../reusable/Forms/Input';
import upload from '../../../../Images/upload.png';
import CloseRemark from '../PatientModals/CloseRemark';
import { FaRegTrashCan } from 'react-icons/fa6';
import hospitalService from '../../../../services/hospitalService';
import { useMutation } from 'react-query';
import { useRemarkStore } from '../../../../store/hospital/auth';
import { ToastContainer, toast } from 'react-toastify';

interface FormData {
    testName: string;
}

type uploadImageProps = {
    imageResult: File[];
    patientTmrNumber: string;
    labTestId: number;
    remark: string;
};
const UploadImage = ({ closeModal, testNamed, tmrNum, activeItemId }) => {
    const { remark: childRemark } = useRemarkStore();
    const [button, setButton] = useState(false);
    const [displayImgs, setDisplayImgs] = useState<string[]>([]);
    const [logo, setImageFiles] = useState<File[]>([]);
    const [openModal, setOpenModal] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
    const handleOpenModal = (e: React.FormEvent) => {
        setOpenModal(true);
        e.preventDefault();
    };
    const [formData, setFormData] = useState<FormData>({
        testName: testNamed,
    });
    const imageTestResultMutation = useMutation((payload: uploadImageProps) =>
        hospitalService.addImageLabTestResult(payload)
    );

    useEffect(() => {
        if (imageTestResultMutation.isSuccess) {
            closeModal();
            toast.success(imageTestResultMutation.data.data.message);
        }
    }, [imageTestResultMutation.isSuccess, imageTestResultMutation.data]);

    useEffect(() => {
        if (imageTestResultMutation.isError) {
            const err: any = imageTestResultMutation.error;
            closeModal();
            toast.error(err.response.data.error.message);
        }
    }, [imageTestResultMutation.isError, imageTestResultMutation.error]);

    const handleCloseRemark = (remark: string) => {
        setOpenModal(false);
        const payload: uploadImageProps = {
            labTestId: activeItemId,
            patientTmrNumber: tmrNum,
            imageResult: logo,
            remark: remark || childRemark || '',
        };
        imageTestResultMutation.mutate(payload);
        // console.log('payload formdata', payload.imageResult);
        // console.log('file', displayImgs);
    };
    useEffect(() => {
        if (formData.testName !== '' && displayImgs.length > 0) {
            setButton(true);
        } else {
            setButton(false);
        }
    }, [displayImgs, formData.testName]);
    const closeRemark = () => {
        setOpenModal(false);
    };
    const handlesubmit = () => {
        setOpenModal(false);
        const dataToSend = {
            email: formData.testName,
            logo: logo,
        };

        setFormData({
            testName: '',
        });
        closeModal();
    };
    const handleChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
    ) => {
        const { name, value } = e.target;
        setFormData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const onImageChange = (e: any) => {
        e.preventDefault();
        const image_as_base64 = URL.createObjectURL(e.target.files[0]);
        const image_as_files = e.target.files[0];

        setDisplayImgs((prevImages) => [...prevImages, image_as_base64]);
        setImageFiles((prevFiles) => [...prevFiles, image_as_files]);
    };

    const openDeleteConfirmation = (index: number) => {
        setDeleteIndex(index);
        setOpenModal(true);
    };
    return (
        <div>
            <ToastContainer />
            <form onSubmit={handleOpenModal}>
                {' '}
                <Input
                    name="testName"
                    label="Test Name"
                    value={testNamed}
                    onChange={handleChange}
                    className={
                        'w-full border border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider'
                    }
                    disabled
                />
                <div className="relative w-full flex flex-col items-center justify-center my-12">
                    <div className="relative w-full flex flex-wrap items-center justify-center ">
                        {displayImgs.map((displayImg, index) => (
                            <div
                                key={index}
                                className="w-[150px] h-[150px] mr-4 py-5"
                            >
                                <img
                                    src={displayImg}
                                    alt=""
                                    className="w-full h-full object-cover rounded-[20px]"
                                />
                                {/* <button
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-2 cursor-pointer bg-white rounded-full"
                  onClick={() => openDeleteConfirmation(index)}
                >
                  <FaRegTrashCan size={20}  />
                </button> */}
                            </div>
                        ))}
                    </div>

                    <div className="w-full max-w-[551px] h-[150px] rounded-[20px] bg-[#FFDAD5] overflow-hidden">
                        {displayImgs.length === 0 ? (
                            <div className="w-full h-full mx-auto flex flex-col justify-center items-center px-3">
                                <img src={upload} alt="" />
                                <p className="text-center mt-2 text-[14px] text-[#534341]">
                                    Click to upload result
                                </p>
                            </div>
                        ) : (
                            <div className="w-full h-full mx-auto flex flex-col justify-center items-center px-3">
                                <img src={upload} alt="" />
                                <p className="text-center mt-2 text-[14px] text-[#534341]">
                                    Click to upload result
                                </p>
                            </div>
                        )}
                    </div>

                    <input
                        type="file"
                        accept=".png, .jpeg, .jpg"
                        name="uploads"
                        style={{
                            transform: 'translate(-50%, -50%)',
                        }}
                        className="bg-[#FFDAD5] w-[100%] h-[100%] top-[50%] left-[50%] z-[4] opacity-0 cursor-pointer absolute"
                        onChange={onImageChange}
                    />
                </div>
                <div className="flex justify-end space-x-6  mt-6">
                    <button
                        onClick={closeModal}
                        className="py-[10px] px-6 border-[1px] rounded-lg text-Tmred text-sm font-medium border-lightBrown100"
                    >
                        Close
                    </button>
                    <button
                        disabled={!button || imageTestResultMutation.isLoading}
                        className={` py-[10px] px-6 rounded-lg text-sm font-medium  ${
                            button && !imageTestResultMutation.isLoading
                                ? 'bg-Tmred   text-white  '
                                : 'bg-[#1D1B201F] text-[#999797]'
                        } `}
                    >
                        {imageTestResultMutation.isLoading
                            ? 'Adding test result...'
                            : 'Confirm Test'}
                    </button>
                </div>
            </form>
            {openModal && (
                <CloseRemark
                    closeModal={handleCloseRemark}
                    handleClose={closeRemark}
                    testNamed={testNamed}
                />
            )}
        </div>
    );
};

export default UploadImage;
