import React from 'react'
import DashboardLayout from '../Dashboard/DashboardLayout'
import { Helmet } from 'react-helmet'
import AdProfile from '../../../components/PharmacyComponent/TmrAds/AdProfile'


function AdsProfile() {
  return (
<DashboardLayout>
    <Helmet>
        <title>
            TMR | TMR Ads Profile
        </title>
    </Helmet>
    <div className="">
        <AdProfile/>
    </div>
</DashboardLayout>
  )
}

export default AdsProfile