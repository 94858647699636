import AddAdmin from '../pages/Pharmacy/Admin/AddAdmin';
import Admin from '../pages/Pharmacy/Admin/Admin';
import AdminProfiles from '../pages/Pharmacy/Admin/AdminProfiles';
import AppProcessing from '../pages/Pharmacy/ApplicationProcessing/AppProcessing';
import BillInvoices from '../pages/Pharmacy/Billing/BillInvoices';
import SendMoney from '../pages/Pharmacy/Billing/SendMoney';
import TransactionHistories from '../pages/Pharmacy/Billing/TransactionHistories';
import WithdrawMoney from '../pages/Pharmacy/Billing/WithdrawMoney';
import Dashboard from '../pages/Pharmacy/Dashboard/Dashboard';
import ForgotPassword from '../pages/Pharmacy/ForgotPassword/ForgotPassword';
import ResetPassword from '../pages/Pharmacy/ForgotPassword/ResetPassword';
import AddInventory from '../pages/Pharmacy/Inventory/AddInventory';
import EditInventory from '../pages/Pharmacy/Inventory/EditInventory';
import Inventory from '../pages/Pharmacy/Inventory/Inventory';
import Preview from '../pages/Pharmacy/Inventory/Preview';
import ProductDetails from '../pages/Pharmacy/Inventory/ProductDetails';
import RequestInventory from '../pages/Pharmacy/Inventory/RequestInventory';
import LoginForm from '../pages/Pharmacy/Login/LoginForm';
import MyTransactionHistories from '../pages/Pharmacy/MyWallet/MyTransactionHistories';
import MyWallet from '../pages/Pharmacy/MyWallet/MyWallet';
import Createorder from '../pages/Pharmacy/Order/Createorder';
import Order from '../pages/Pharmacy/Order/Order';
import OrderDetail from '../pages/Pharmacy/Order/OrderDetail';
import PreviewOrders from '../pages/Pharmacy/Order/PreviewOrders';
// import Withdraw from "../pages/Pharmacy/Order/Withdraw";
import PharmacyWallet from '../pages/Pharmacy/PharmacyWallet/PharmacyWallet';
import CreateAcct from '../pages/Pharmacy/Register/CreateAcct';
import ScreenTimeout from '../pages/Pharmacy/ScreenTimeout/ScreenTimeout';
import EditPersonalProfile from '../pages/Pharmacy/Settings/EditPersonalProfile';
import EditProfile from '../pages/Pharmacy/Settings/EditProfile';
import MyProfile from '../pages/Pharmacy/Settings/MyProfile';
import NonAdminSettings from '../pages/Pharmacy/Settings/NonAdminSettings';
import PharmacyPage from '../pages/Pharmacy/Settings/PharmacyPage';
import LockcodeSetup from '../pages/Pharmacy/SetupLockcode/LockcodeSetup';
import Subplan from '../pages/Pharmacy/Subscription/Subplan';
import OTP from '../pages/Pharmacy/SuperAdmin/OTP';
import PersonalInformation from '../pages/Pharmacy/SuperAdmin/PersonalInformation';
import ADS from '../pages/Pharmacy/TmrADS/ADS';
import AdsProfile from '../pages/Pharmacy/TmrADS/AdsProfile';
import CreateAds from '../pages/Pharmacy/TmrADS/CreateAds';
import PharmacyVoucher from '../pages/Pharmacy/Voucher/PharmacyVoucher/PharmacyVoucher';
import VoucherHistory from '../pages/Pharmacy/Voucher/VoucherHistory';
import VoucherPage from '../pages/Pharmacy/Voucher/VoucherPage';

const PharmacyRoute = [
    {
        path: 'auth/register',
        element: <CreateAcct />,
    },

    {
        path: 'auth/super-admin',
        element: <PersonalInformation />,
    },
    {
        path: 'auth/otp',
        element: <OTP />,
    },
    {
        path: 'auth/setup-lockcode',
        element: <LockcodeSetup />,
    },
    {
        path: 'auth/subscription-plan',
        element: <Subplan />,
    },
    {
        path: 'auth/application-processing',
        element: <AppProcessing />,
    },
    {
        path: 'screen-timeout',
        element: <ScreenTimeout />,
    },
    {
        path: 'auth/login',
        element: <LoginForm />,
    },
    {
        path: 'auth/forgot-password',
        element: <ForgotPassword />,
    },
    {
        path: 'auth/reset-password',
        element: <ResetPassword />,
    },
    {
        path: 'dashboard',
        element: <Dashboard />,
    },
    {
        path: 'inventory',
        element: <Inventory />,
    },
    {
        path: 'inventory/add-inventory',
        element: <AddInventory />,
    },
    {
        path: 'inventory/preview-inventory/:slug',
        element: <Preview />,
    },
    {
        path: 'inventory/product-details/:inventoryId',
        element: <ProductDetails />,
    },
    {
        path: 'inventory/edit-details/:inventoryId',
        element: <EditInventory />,
    },
    {
        path: 'inventory/request-inventory',
        element: <RequestInventory />,
    },

    {
        path: 'orders',
        element: <Order />,
    },

    {
        path: 'orders/details/:id',
        element: <OrderDetail />,
    },
    {
        path: 'orders/create-order',
        element: <Createorder />,
    },
    {
        path: 'orders/preview-order',
        element: <PreviewOrders />,
    },
    // {
    //     path:'orders/withdrawal',
    //     element: <Withdraw/>,
    // },
    {
        path: 'wallet/pharmacy-wallet',
        element: <PharmacyWallet />,
    },
    {
        path: 'pharmacy-wallet/wallet-transactions',
        element: <TransactionHistories />,
    },
    {
        path: 'billing/send-money',
        element: <SendMoney />,
    },
    {
        path: 'billing/withdraw-money',
        element: <WithdrawMoney />,
    },
    {
        path: 'wallet/my-wallet',
        element: <MyWallet />,
    },
    {
        path: 'my-wallet/my-wallet-transactions',
        element: <MyTransactionHistories />,
    },
    {
        path: 'settings/pharmacy-profile',
        element: <PharmacyPage />,
    },
    {
        path: 'settings/my-profile',
        element: <MyProfile />,
    },
    {
        path: 'settings/pharmacy/edit',
        element: <EditProfile />,
    },
    {
        path: 'settings/profile/edit',
        element: <EditPersonalProfile />,
    },
    {
        path: 'settings/my-settings',
        element: <NonAdminSettings />,
    },
    {
        path: 'admin',
        element: <Admin />,
    },

    {
        path: 'add-admin',
        element: <AddAdmin />,
    },
    {
        path: 'admin-profile/:id',
        element: <AdminProfiles />,
    },
    {
        path: 'voucher',
        element: <VoucherPage />,
    },
    {
        path: 'voucher/pharmacy-voucher',
        element: <PharmacyVoucher />,
    },
    {
        path: 'voucher/voucher-history/:walletType',
        element: <VoucherHistory />,
    },
    {
        path: 'billing/bill-invoice/:id',
        element: <BillInvoices />,
    },
    {
        path: 'tmr-ads',
        element: <ADS/>
      },
      {
        path: 'tmr-ads/create',
        element: <CreateAds />
      },
      
      {
        path: 'ads/:id',
        element: <AdsProfile/>
      },
];

export default PharmacyRoute;
