import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';
import hospitalService from '../../services/hospitalService';
import { Add, Receipt2 } from 'iconsax-react';
import RecentBills from './RecentBills';
import { useEffect, useState } from 'react';
import { hospitalRoutes } from '../../utils/routeNames';
import { standardAmountFormat } from '../../utils/helpers';
import { useHospitalAuthStore } from '../../store/hospital/auth';

const Bill = () => {
    const [outstandingBalanceAmount, setOutstandingBalanceAmount] =
        useState<number>(0);
    const [createdBillCount, setCreatedBillCount] = useState<number>(0);
    const [paidBillCount, setPaidBillCount] = useState<number>(0);
    const { hospital } = useHospitalAuthStore();
    const { data: outstandingBalanceData, isLoading: outstandLoading } =
        useQuery('outstanding', hospitalService.getOutstandingBalance);

    useEffect(() => {
        if (outstandingBalanceData) {
            setOutstandingBalanceAmount(
                outstandingBalanceData.outstandingAmount
            );
            setCreatedBillCount(outstandingBalanceData.billsCount);
            setPaidBillCount(outstandingBalanceData.paidBillsCount);
        }
    }, [outstandingBalanceData]);

    return (
        <div className="w-full flex flex-col md:px-8 py-4 px-2">
            <div className="flex justify-between items-center py-4">
                <h2 className="font-bold sm:text-[28px] text-[20px]">
                    Billing
                </h2>
                <Link
                    to={hospitalRoutes.createBill}
                    className={`w-fit px-4 h-[40px] flex justify-center gap-1 rounded-lg items-center bg-Tmred text-white hover:cursor-pointer`}
                >
                    <Add size="24" color="#ffffff" />
                    <span className=" tracking-wide">Create Bill</span>
                </Link>
            </div>
            <div className="flex flex-col lg:flex-row items-center gap-10 w-fit flex-wrap">
                <div className="bg-white rounded-3xl p-4 w-full h-[225px] md:w-[300px] flex flex-col gap-6">
                    <div className="flex items-center justify-between w-full">
                        <h2 className="lg:text-[20px] font-semibold text-md ">
                            No. of Created Bills
                        </h2>
                        <div className="bg-lightPink rounded-full p-2">
                            <Receipt2 size="24" color="#2c1512" />
                        </div>
                    </div>
                    <div className="text-2xl font-bold">{createdBillCount}</div>
                </div>
                <div className="bg-white rounded-3xl p-4 w-full h-[225px] md:w-[300px] flex flex-col gap-6">
                    <div className="flex items-center justify-between w-full">
                        <h2 className="lg:text-[20px] font-semibold text-md ">
                            No. of Paid Bills
                        </h2>
                        <div className="bg-lightPink rounded-full p-2">
                            <Receipt2 size="24" color="#2c1512" />
                        </div>
                    </div>

                    <div className="text-2xl font-bold">{paidBillCount}</div>
                </div>
                <div className="bg-white rounded-3xl p-4 w-full h-[225px] md:w-[300px] flex flex-col gap-6">
                    <div className="flex items-center justify-between w-full">
                        <h2 className="lg:text-[20px] font-semibold text-md ">
                            Outstanding Bill Bal.
                        </h2>
                        <div className="bg-lightPink rounded-full p-2">
                            <Receipt2 size="24" color="#2c1512" />
                        </div>
                    </div>

                    <div className="text-2xl font-bold">
                        {standardAmountFormat(
                            outstandingBalanceAmount,
                            hospital.currency
                        )}
                    </div>
                </div>
            </div>
            <RecentBills />
        </div>
    );
};

export default Bill;
