import React from 'react'
import DashboardLayout from '../Dashboard/DashboardLayout';
import {Helmet} from 'react-helmet'
import HWallet from '../../../components/Wallet/hospital/HWallet';

const HospitalWallet = () => {
    return (
        <DashboardLayout>
            <Helmet>
                <title>
                    TMR | Hospital Wallet
                </title>
            </Helmet>
            <div className="">
                <HWallet />
            </div>
        </DashboardLayout>
    )
}

export default HospitalWallet