import { useEffect, useState } from 'react';
import footXray from '../../../../Images/footXray.png';
import jawXray from '../../../../Images/jawXray.png';
import { VscAdd } from 'react-icons/vsc';
import PatientProfileModal from '../PatientModals/PatientProfileModal';
import UploadTestModal from '../PatientModals/UploadTestModal';
import { useQuery } from 'react-query';
import hospitalService from '../../../../services/hospitalService';
import { formatDateDmy } from '../../../../utils/helpers';

interface PatientProps {
    tmrNum?: any;
}

interface AllLabTest {
    tmrNumber: string;
    labTest: {
        id: number;
        patientTmrNumber: string;
        doctorName: string;
        specialty: string;
        labtest: {
            testName: string;
            group: string;
            type: string;
            amount: string;
        };
        createdAt: string;
    }[];
}
interface AllLabTestDetails {
    tmrNumber: string;
    lastId: string | number | null;
    labTest: {
        id: number;
        patientTmrNumber: string;
        doctorName: string;
        specialty: string;
        labTest: {
            testName: string;
            group: string;
            type: string;
            amount: string;
        };
        createdAt: string;
    } | null;
    labTestResult: {
        id: number;
        patientTmrNumber: string;
        labTestResult: {
            result: string;
            name?: string; // Name is optional based on your data
        }[];
        createdAt: string;
        remark: string;
    };
    imageLabTestResult: {
        id: number;
        patientTmrNumber: string;
        labTestId: number;
        imageLabTestResult: string[];
        createdAt: string;
        remark: string;
    };
    createdAt: string;
}
const useAllLabTestDetails = (
    tmrNumber: string,
    lastId: string | number | null
) => {
    return useQuery<AllLabTestDetails>(
        ['medicalReport', tmrNumber, lastId], // Unique query key
        async () => {
            // Fetch medical report details using HospitalService
            return await hospitalService.getAllLabTestDetails(
                tmrNumber,
                lastId
            );
        }
    );
};
const useAllLabTest = (tmrNumber: string) => {
    return useQuery<AllLabTest>(['labTest', tmrNumber], async () => {
        return await hospitalService.getAllLabTest(tmrNumber);
    });
};
const LabTestsTab: React.FC<PatientProps> = ({ tmrNum }) => {
    const [access, setAccess] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [selectedImageLabTest, setSelectedImageLabTest] = useState<
        | {
              id: number;
              patientTmrNumber: string;
              labTestId: number;
              imageLabTestResult: string[];
              createdAt: string;
              remark: string;
          }
        | undefined
    >(undefined);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [activeItemId, setActiveItemId] = useState<number | null>(null);
    const [labTestReportId, setLabTestReportId] = useState<
        string | number | null
    >(1);
    const {
        data: historyData,
        isError: historyError,
        isLoading: historyLoading,
        refetch: historyRefetch,
    } = useAllLabTest(tmrNum);
    const test = historyData?.labTest;
    const {
        data: reportDetails,
        isError: detailsError,
        isLoading: detailsLoading,
        refetch: refetchReportDetails,
    } = useAllLabTestDetails(tmrNum, labTestReportId);

    useEffect(() => {
        if (test !== null && test !== undefined) {
            let selectedTest = test.find(
                (testItem) => testItem.id === activeItemId
            );

            if (!selectedTest && test && test.length > 0) {
                setActiveItemId(test[0].id);
                setLabTestReportId(test[0].id);
            }
            if (reportDetails) {
                const selectedImageLabTestResult =
                    reportDetails?.imageLabTestResult;
                // reportDetails.imageLabTestResult?.find(
                //     (result) => result.labTestId === activeItemId
                // );
                const selectedLabTestResult = reportDetails?.labTestResult;
                // reportDetails?.labTestResult?.find(
                //     (result) => result.id === activeItemId
                // );

                setSelectedImageLabTest(selectedImageLabTestResult);
                if (selectedLabTestResult) {
                    // console.log(
                    //     'Lab Test Result Full-details:',
                    //     selectedLabTestResult
                    // );
                    // console.log(
                    //     'Lab Test Result details:',
                    //     selectedLabTestResult?.labTestResult
                    // );
                } else {
                    // console.log(
                    //     'Lab Test Result details: No labTestResult found for the selected lab test.'
                    // );
                }
            }
            // if (reportDetails) {
            //     const selectedImageLabTestResult =
            //         reportDetails.imageLabTestResult.filter(
            //             (result) => result.labTestId === activeItemId
            //         );

            //     if (selectedImageLabTestResult.length > 0) {
            //         console.log(
            //             'Lab Test Result details:',
            //             selectedImageLabTestResult
            //         );
            //     } else {
            //         console.log(
            //             'Lab Test Result details: No imageLabTestResult found for the selected lab test.'
            //         );
            //     }
            // }
        }
    }, [activeItemId, test, reportDetails]);

    useEffect(() => {
        // Fetch report details when medicalReportId changes
        if (labTestReportId) {
            refetchReportDetails();
        }
    }, [labTestReportId]);

    const selectTest = test?.find((testItem) => testItem.id === activeItemId);
    const handleTestDateClick = (id: number) => {
        setActiveItemId(id);
        setLabTestReportId(id);
        console.log(activeItemId);
        console.log(
            'labTest Result details:',
            reportDetails?.imageLabTestResult
        );
    };
    const handleOpenModal = () => {
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const handleUploadModal = () => {
        setOpenUploadModal(true);
        console.log('clicked');
    };
    const handleCloseUploadModal = () => {
        setOpenUploadModal(false);
    };

    if (historyLoading || detailsLoading) {
        return <div>Loading...</div>;
    }

    if (historyError) {
        return <div>An error occurred</div>;
    }

    return (
        <div className="bg-white p-4 max-w-[590px]">
            {test && test.length > 0 ? (
                <>
                    {/* {access ? ( */}
                    <div className="flex items-start justify-between space-x-5">
                        <div className="flex-1">
                            <div className="flex items-center justify-between mb-2">
                                <h2 className="text-[22px] font-normal text-lightBrown ">
                                    Lab Tests
                                </h2>
                                <button
                                    onClick={handleUploadModal}
                                    className="flex space-x-2"
                                >
                                    <VscAdd className="text-dark100 opacity-50" />
                                    <p className="text-xs font-medium text-dark100">
                                        Upload
                                    </p>
                                </button>
                            </div>
                            {/* Diagnoses content goes here */}
                            <div className="pb-5 border-b-[1px] border-brown100">
                                <div className="flex flex-col sm:flex-row justify-between mb-3">
                                    <div className="flex space-x-1 sm:space-x-2">
                                        <p className="text-[11px] font-medium text-dark100">
                                            Scanned:
                                        </p>
                                        <p className="text-xs font-medium text-lightBrown">
                                            {formatDateDmy(
                                                selectTest?.createdAt
                                            )}
                                        </p>
                                    </div>
                                    <div className="flex space-x-1 sm:space-x-2">
                                        <p className="text-[11px] font-medium text-dark100">
                                            Time:
                                        </p>
                                        <p className="text-xs font-medium text-lightBrown">
                                            {selectTest && selectTest.createdAt
                                                ? (() => {
                                                      const createdAtDate =
                                                          new Date(
                                                              selectTest?.createdAt ??
                                                                  ''
                                                          );
                                                      const hours =
                                                          createdAtDate.getHours();
                                                      const minutes =
                                                          createdAtDate.getMinutes();
                                                      const formattedHours =
                                                          hours % 12 === 0
                                                              ? 12
                                                              : hours % 12;
                                                      const amPm =
                                                          hours >= 12
                                                              ? 'pm'
                                                              : 'am';
                                                      return `${formattedHours}:${
                                                          minutes < 10
                                                              ? '0'
                                                              : ''
                                                      }${minutes}${amPm}`;
                                                  })()
                                                : null}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-col sm:flex-row justify-between">
                                    <div className="flex space-x-1 sm:space-x-2">
                                        <p className="text-[11px] font-medium text-dark100">
                                            Doctor:
                                        </p>
                                        <p className="text-xs font-medium text-lightBrown">
                                            {reportDetails?.labTest?.doctorName}
                                        </p>
                                    </div>
                                    <div className="flex space-x-1 sm:space-x-2">
                                        <p className="text-[11px] font-medium text-dark100">
                                            Specialty:
                                        </p>
                                        <p className="text-xs font-medium text-lightBrown">
                                            {reportDetails?.labTest?.specialty}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* {selectTest && selectTest.labtest && (
                                <div className="mt-5">
                                    <p className="text-[11px] font-medium text-dark100">
                                        Test:
                                    </p>
                                    <p className="text-xs font-medium text-lightBrown mt-2">
                                        .
                                    </p>
                                </div>
                            )} */}

                            <div className="mt-5">
                                <p className="text-[11px] font-medium text-dark100">
                                    Group Name:
                                </p>
                                <p className="text-xs font-medium text-lightBrown mt-2">
                                    {reportDetails?.labTest?.labTest?.group}
                                </p>
                            </div>
                            <div className="mt-5">
                                <p className="text-[11px] font-medium text-dark100">
                                    Test:
                                </p>
                                <p className="text-xs font-medium text-lightBrown mt-2">
                                    {reportDetails?.labTest?.labTest?.testName}
                                </p>
                            </div>
                            <div className="mt-5 flex space-x-24 items-center">
                                <div>
                                    <p className="text-[11px] font-medium text-dark100">
                                        Result Time:
                                    </p>
                                    <p className="text-xs font-medium text-lightBrown mt-2">
                                        .
                                    </p>
                                </div>
                                <div>
                                    <p className="text-[11px] font-medium text-dark100">
                                        Price:
                                    </p>
                                    <p className="text-xs font-medium text-lightBrown mt-2">
                                        {reportDetails?.labTest?.labTest?.amount
                                            ? reportDetails.labTest.labTest.amount.replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ','
                                              )
                                            : null}
                                    </p>
                                </div>
                            </div>
                            {reportDetails?.labTestResult?.labTestResult?.map(
                                (e, i) => (
                                    <div className="flex flex-wrap justify-start items-center space-x-20 mt-5 gap-2">
                                        <div>
                                            <p className="text-[11px] font-medium text-dark100">
                                                Test Name {i + 1} :
                                            </p>
                                            <p className="text-xs font-medium text-lightBrown mt-2">
                                                {e.name}
                                            </p>
                                        </div>
                                        <div>
                                            <p className="text-[11px] font-medium text-dark100">
                                                Test Result {i + 1} :
                                            </p>
                                            <p className="text-xs font-medium text-lightBrown mt-2">
                                                {e.result}
                                            </p>
                                        </div>
                                    </div>
                                )
                            )}
                            <div className="flex flex-wrap justify-start items-start mt-5 gap-2">
                                {selectedImageLabTest?.imageLabTestResult?.map(
                                    (imageUrl, index) => (
                                        <>
                                            {' '}
                                            <p className="text-[11px] font-medium text-dark100">
                                                Image Result:
                                            </p>
                                            <div
                                                key={index}
                                                className="w-[30%] mb-4 h-24"
                                            >
                                                <img
                                                    src={imageUrl}
                                                    alt={`Lab Test Result ${
                                                        index + 1
                                                    }`}
                                                    className="w-full h-full mb-2"
                                                />
                                            </div>
                                        </>
                                    )
                                )}
                            </div>
                            <div className="bg-lightPink rounded-lg p-3">
                                <p className="text-[11px] mb-2 font-medium text-dark100">
                                    Final Remark
                                </p>
                                <p className="text-xs  text-lightBrown mt-2 capitalize">
                                    {reportDetails?.labTestResult &&
                                        reportDetails?.labTestResult?.remark}
                                    {selectedImageLabTest &&
                                        selectedImageLabTest?.remark}
                                </p>
                            </div>
                        </div>
                        <div className="border-t mb-10"></div>
                        <div className="  px-2 ">
                            <button
                                onClick={handleOpenModal}
                                type="button"
                                className="text-xs font-medium text-[#C00010] px-6 py-[10px] rounded-lg border-[1px] border-lightBrown100"
                            >
                                New Test
                            </button>
                            <div className="text-center bg-lightGray200 px-2 rounded-lg mt-2 h-72 overflow-y-scroll scrollbar-hide">
                                <p className="mb-3 text-xs pt-1">History</p>
                                <ul>
                                    {test?.map((created) => {
                                        return (
                                            <li
                                                className={`p-1 my-2 text-[11px] rounded-md font-medium ${
                                                    activeItemId === created.id
                                                        ? 'bg-lightPink100 '
                                                        : ''
                                                } text-dark100`}
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                                key={created.id}
                                                onClick={() =>
                                                    handleTestDateClick(
                                                        created.id
                                                    )
                                                }
                                            >
                                                {formatDateDmy(
                                                    created.createdAt
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* // ) : (
                    //     <div className="flex justify-center items-center my-20 ">
                    //         <button
                    //             type="button"
                    //             className="bg-Tmred px-4 py-2 text-white text-base font-normal rounded-lg"
                    //         >
                    //             Request Data Access
                    //         </button>
                    //     </div>
                    // )} */}
                </>
            ) : (
                <div className="flex">
                    <div className="w-[100%] text-center text-gray-500 py-8">
                        No lab tests available
                    </div>
                    <div className="px-2">
                        <button
                            onClick={handleOpenModal}
                            type="button"
                            className="text-xs w-[6rem] font-medium text-[#C00010] px-4 py-[10px] rounded-lg border-[1px] border-lightBrown100"
                        >
                            New Test
                        </button>
                        <div className="text-center bg-lightGray200 px-2 rounded-lg mt-2 h-72 overflow-y-scroll scrollbar-hide">
                            <p className="mb-3 text-xs pt-1">History</p>
                            <ul>
                                {test?.map((created) => {
                                    return (
                                        <li
                                            className={`p-1 my-2 text-[11px] rounded-md font-medium ${
                                                activeItemId === created.id
                                                    ? 'bg-lightPink100 '
                                                    : ''
                                            } text-dark100`}
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            key={created.id}
                                            onClick={() =>
                                                handleTestDateClick(created.id)
                                            }
                                        >
                                            {formatDateDmy(created.createdAt)}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            )}

            {openModal && (
                <PatientProfileModal
                    closeModal={handleCloseModal}
                    tmrNum={tmrNum}
                    refetch={historyRefetch}
                />
            )}
            {openUploadModal && (
                <UploadTestModal
                    closeModal={handleCloseUploadModal}
                    activeItemId={activeItemId}
                    tmrNum={tmrNum}
                    testName={reportDetails?.labTest?.labTest?.testName ?? ''}
                />
            )}
        </div>
    );
};
export default LabTestsTab;
