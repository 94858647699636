import React, { Fragment, useEffect, useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import HospitalAuthHeader from '../../../reusable/HospitalAuthHeader';
import Input from '../../../reusable/Forms/Input';
import count1 from './../../../Images/Frame1.svg';

import ProfessionalInformation from './ProfessionalInformation';
import Layout from '../Layout';
import { useLocation, Navigate, useNavigate } from 'react-router-dom';

import { isTokenValid } from '../../../utils/helpers';
import BlockButton from '../../../reusable/BlockButton';
import PasswordInput from '../../../reusable/Forms/PasswordInput';
import {Helmet} from 'react-helmet'
// import Modal from '../../../reusable/ConsentModal';
import Modal from '../../../reusable/Modal';
import SelectElement from '../../../reusable/Forms/Select';
import { SelectChangeEvent } from '@mui/material';
import { useMutation } from 'react-query';

import { pharmacyRoutes } from '../../../utils/pharmacyRouteNames';
import ErrorState from '../../../reusable/ErrorState';
import upload from '../../../Images/upload.png';
import ButtomLogo from '../../../reusable/ButtomLogo';
import { usePharmacyAccountStore } from '../../../store/pharmacy/auth';
import { pharmacyService } from '../../../services/pharmacyService';

type InputProps = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    password: string;
    Gender:string;
    Salutation: string;
    title: string;
};

const PersonalInformation = () => {
    const [showProfessionalPage, setShowProfessionalPage] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const[disabled,setDisabled] = useState(true)
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState<InputProps>({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        password: '',
        Gender:'',
        Salutation: '',
        title: ''
    });

    const { formState, handleSubmit, control ,watch} = useForm<InputProps>();
    const [imageFile, setImageFile] = useState('');
    const [displayImg, setDisplayImg] = useState('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isAcceptModalVisible, setAcceptModalVisible] = useState(false);
    
    const handleSelectChange = (e: SelectChangeEvent) => {
        const { name, value } = e.target;
          setFormData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const pharmacyAccount = usePharmacyAccountStore((state) => state);
    const pharmacyAccountStore = usePharmacyAccountStore((state) => state);
    const mutation = useMutation((data: any) =>
        pharmacyService.createPharmacyUser(data)
    );

    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            let payload: any = mutation.data;
            
            pharmacyAccountStore.updateToken(payload.data.payload.token);
            
            navigate(pharmacyRoutes.otp, { state: { formData, email: formData.email } })
        }
    }, [mutation.isSuccess, mutation.data]);

    const onImageChange = (e: any) => {
        e.preventDefault();
        const image_as_base64 = URL.createObjectURL(e.target.files[0]);
        const image_as_files = e.target.files[0];
        setDisplayImg(image_as_base64);
        setImageFile(image_as_files);
    };

    const onSubmitForm: SubmitHandler<InputProps> = (data) => {
        setErrorMessage('');
        setFormData(data)
    
        setIsLoading(true);
        const pharmacyFormData = new FormData();
        pharmacyFormData.append('firstName', data.firstName);
        pharmacyFormData.append('lastName', data.lastName);
        pharmacyFormData.append('gender', data.Gender);
        pharmacyFormData.append('salutation', data.Salutation);
        pharmacyFormData.append('email', data.email);
        pharmacyFormData.append('phoneNumber', data.phoneNumber);
    
        if (imageFile) {
            pharmacyFormData.append('image', imageFile);
        }
        pharmacyFormData.append('password', data.password);
        pharmacyFormData.append('title', data.title);
        mutation.mutate({
            pharmacyFormData,
            token: pharmacyAccountStore.token,
        });
    };
    

    useEffect(() => {
        if (mutation.isError) {
            setIsLoading(false);
            let err: any = mutation?.error;
            // console.log(err);
            setErrorMessage(err.response.data.error.message);
        } else {
            setErrorMessage('');
        }
    }, [mutation.isError]);

    const onInvalid = (errors) => console.error(errors);

    const location = useLocation();
    const navigate = useNavigate();
    if (
        !isTokenValid(pharmacyAccount.token) ||
        !pharmacyAccount.pharmacy.name
    ) {
        return (
            <Navigate
                to="/pharmacy/auth/register"
                replace
                state={{ from: location }}
            />
        );
        // navigate('/pharmacy/auth/register');
    }

    const { isValid, isSubmitting, isDirty } = formState;

    const hideProfessionalPage = () => {
        setShowProfessionalPage(false);
    };

   
    
    
    const handleCheckboxChange = () => {
        setIsCheckboxChecked(!isCheckboxChecked);
        setDisabled(!disabled)
      };
    
      const handleModalSubmit = () => {
        
        setIsModalOpen(false);
      };

    const onClose=()=>{
        setAcceptModalVisible(true)
        // setIsModalOpen(false);
    }
    const handleAcceptConfirm = async () => {
       
        navigate(pharmacyRoutes.healthID);
        setAcceptModalVisible(false)
       
      };
    
      const handleAcceptCancel = () => {
        setAcceptModalVisible(false);
      };
    return (
        <Layout>
            <Helmet>
                <title>
                    TMR | Create SuperAdmin
                </title>
            </Helmet>
            <Fragment>
          <>
                {!showProfessionalPage && (
                    <>
                        <div className="flex items-center justify-center flex-col w-full  py-8 overflow-x-hidden ">
                            <div className="mx-auto flex flex-col items-center justify-center gap-4">
                                <HospitalAuthHeader
                                    logo={pharmacyAccount.pharmacy.logo}
                                    text={pharmacyAccount.pharmacy.tagline}
                                    title={pharmacyAccount.pharmacy.name}
                                />
                                <div className="flex items-center flex-col justify-center  border rounded-xl bg-[#FFf] py-[40px] px-[80px] mt-7 max-w-[592px]">
                                    <div className="flex items-start">
                                        <div>
                                            <h3 className="font-semibold text-[#201A19] text-center text-[32px] ">
                                            Super Admin Information
                                            </h3>
                                            <p className="font-normal text-[#534341] text-[14px] max-w-[432px] pt-4 text-center">
                                                Please provide your basic
                                                personal details for account
                                                creation.
                                            </p>
                                        </div>
                                        <div className="text-end">
                                            <img src={count1} alt="count1" />
                                        </div>
                                    </div>
                                    {errorMessage && <ErrorState message={errorMessage} />}
                                    <div className="py-5">
                                        <form
                                            onSubmit={handleSubmit(
                                                onSubmitForm,
                                                onInvalid
                                            )}
                                            className="w-full flex flex-col gap-4 sm:gap-4"
                                            noValidate
                                        >
                                            <div className="flex flex-row">
                                                <div className='w-full py-2'>
                                                    <Controller
                                                        name="Salutation"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <SelectElement
                                                                name="Salutation"
                                                                onChange={(e: SelectChangeEvent) => {
                                                                    field.onChange(e);
                                                                    handleSelectChange(e);
                                                                }}
                                                                value={field.value}
                                                                options={['Mr.', 'Mrs.', 'Miss', 'Dr.', 'Nurse']}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex items-center flex-col gap-3 w-full sm:flex-row">
                                                <div className="flex flex-col items-start sm:flex-1 ">
                                                    {/* <Input
                                                        label="First Name"
                                                        value={watch('firstName')}
                                                        {...register("firstName", { required: 'First name is required' })}
                                                    /> */}
                                                    <Controller
                                                        name="firstName"
                                                        rules={{
                                                            required:
                                                                'First name is required',
                                                        }}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Input
                                                                label="First Name"
                                                                {...field}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="flex flex-col items-start sm:flex-1 ">
                                                    <Controller
                                                        name="lastName"
                                                        rules={{
                                                            required:
                                                                'Last name is required',
                                                        }}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Input
                                                                label="Last Name"
                                                                {...field}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <Controller
                                                name="email"
                                                rules={{
                                                    required:
                                                        'Email is required',
                                                }}
                                                control={control}
                                                render={({ field }) => (
                                                    <Input
                                                        label="Email"
                                                        {...field}
                                                    />
                                                )}
                                            />
                                            <div className='w-full py-2'>
                                                <Controller
                                                    name="Gender"
                                                    rules={{ required: 'Gender is required' }}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <SelectElement
                                                            name="Gender"
                                                            onChange={(e: SelectChangeEvent) => {
                                                                field.onChange(e);
                                                                handleSelectChange(e);
                                                            }}
                                                            value={field.value}
                                                            options={['female', 'male']}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <Controller
                                                name="phoneNumber"
                                                rules={{
                                                    required:
                                                        'Phone number is required',
                                                }}
                                                control={control}
                                                render={({ field }) => (
                                                    <Input
                                                        label="Phone Number"
                                                        {...field}
                                                    />
                                                )}
                                            />
                                            <div className="coloredInput">
                                                <div className="flex flex-col items-start relative">
                                                    <Controller
                                                        name="password"
                                                        rules={{
                                                            required:
                                                                'Password is required',
                                                        }}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <PasswordInput
                                                                {...field}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex items-center sm:flex-row flex-col gap-2">
                                <div className="flex flex-col  max-w-[152px]">
                                    <p className="font-normal text-[#534341] text-[16px] text-left">
                                        Profile Image
                                    </p>
                                    <span className="font-normal py-6 text-[#534341] text-[14px] text-left">
                                        PNG or JPG <br />
                                        (max. 4MB)
                                    </span>
                                </div>
                                <div className="relative mb-5 w-max mx-auto">
                                    <div className="w-[150px] h-[150px] rounded-full bg-[#FFDAD5] overflow-hidden">
                                        {displayImg ? (
                                            <img
                                                src={displayImg}
                                                alt=""
                                                className="object-cover w-full h-full rounded-full max-w-[100%]"
                                            />
                                        ) : (
                                            <div className="w-full h-full mx-auto flex flex-col max-w-[134px] justify-center items-center px-3">
                                                <img src={upload} alt="" />
                                                <p className="text-center mt-2 text-[14px] text-[#534341]">
                                                    Click to upload or drag and
                                                    drop
                                                </p>
                                            </div>
                                        )}
                                    </div>

                                    <input
                                        type="file"
                                        accept=".png, .jpeg, .jpg"
                                        name="uploads"
                                        style={{
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                        className="bg-[#FFDAD5] w-[100%] h-[100%] top-[50%] left-[50%] z-[4] opacity-0 cursor-pointer absolute"
                                        onChange={onImageChange}
                                    />
                                </div>
                            </div>
                            <BlockButton
                                text="Create Account"
                                disabled={!isDirty || !isValid}
                                type="submit"
                                isLoading={isLoading}
                            />
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="ml-auto mt-10 w-full flex justify-end">
                                <ButtomLogo/>
                            </div>
                        </div>
                    </>
                )}
                {showProfessionalPage && (
                    <ProfessionalInformation
                        formData={formData}
                        showFirstComponent={hideProfessionalPage}
                        email={watch('email')}
                    />
                )}
                </>
                <Modal
                    isVisible={isModalOpen}
                    onClose={onClose}
                    onSave={handleModalSubmit}
                    buttonEnabled={isCheckboxChecked}
                    setButton={disabled}
                    saveText="Proceed"
                    closeText="Cancel"
                    showCloseButtonBorder={false}
                    allowCloseOnOuterClick ={false}
                >
                    <div className="bg-white md:p-3 p-2 rounded-[28px] mx-2 max-w-[438px]">
                            <h1 className="font-semibold text-[#201A19] text-2xl sm:text-[32px] mb-5">
                            Please read carefully
                            </h1>
                            <p className="text-[#534341] py-4 text-[12px] sm:text-[14px] font-medium max-w-[486px] w-full">
                            You are about to create a Super Admin account. Please be informed that the Super Admin account is very sensitive. Creating a Super Admin account will grant you access to almost every feature of the pharmacy portal including sending and receiving funds via the pharmacy wallet. Please be advised that you trust the individual who has permission as a Super Admin or perhaps the pharmacy owner may best be registered as the Super Admin. Again, please be reminded that TMR will not be responsible for the actions of a Super Admin.                            
                            </p>
                        
                            <div className='flex items-center mt-4'>
                            
                            
                            <input
                            type="checkbox"
                            checked={isCheckboxChecked}
                            onChange={handleCheckboxChange}
                        
                        />
                            <span className="ml-2 pl-2"> I acknowledge that I have read and understood the disclaimer provided above.</span>
                        
                            </div>
                    </div>
                </Modal>
                <Modal
        isVisible={isAcceptModalVisible}
        onClose={handleAcceptCancel}
        buttonEnabled={true}
        // isLoading={isAccepting}
        // loadingText="Updating..."
        setButton={()=>{}}
        onSave={handleAcceptConfirm}
        saveText="Cancel Registration"
        closeText={'Cancel'}
        showCloseButtonBorder={false}
      >
        <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2  lg:w-[482px]">
          <h2 className="font-semibold text-2xl mb-3">Cancel Registration</h2>
          <p className=" text-[14px] font-normal text-[#534341]">
          Are you sure you want to cancel this registration process ? 
                    </p>
            

          
        </div>
      </Modal>
            </Fragment>
        </Layout>
    );
};

export default PersonalInformation;
